import React, { history, useEffect, useState, useMemo } from "react";

import services from "../../../../services/services";
import { requestObject } from "../../../../utility/requestObject";
import { useLoading } from "react-hook-loading";
import Swal from "sweetalert2";
import $ from "jquery";
import Cropper from "react-cropper";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import UsNumberValidate from "../../../../utility/usNumberValidate";
import { loadingComponent } from "../../../../utility/Loading";
import Getstates from "../../../../utility/getstates";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
import { Dispatcher } from "../../../constant";
const FleetOwnerSettings = () => {

   var roles = localStorage.getItem("Userroles");
   const [states, setState] = useState([]);
   const [profileDetails, setProfileDetails] = useState("");
   const [profilePic, setProfilePic] = useState("");
   const [loading, setLoading] = useLoading("Please wait...");
   const [cropper, setCropper] = useState();
   const [image, setImage] = useState("/images/fleeto-icon.png");
   const [stateIdC, setStateIdC] = useState([]);
   const [itemName, setFieldName] = useState('');
   const {
      register,
      formState: { errors },
      handleSubmit
   } = useForm({
      criteriaMode: "all", mode: "onKeyup", defaultValues: { dotNumber: "123", name: "name", ctNumber: "1234", addressLine1: "ohio", phoneMobile: "123-456-7890", email: "text@mailnator.com" },
   });

   $(document).ready(function () {
   //    $('#dotNumber').keypress(function( e ) {
   //       if(e.which === 32){
   //           return false;
   //       }
   //   });
      $("#zipcode").keypress(function (e) {
         if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
            return false;
         }

      });
   });


   useEffect(() => {
      Getstates(setState);
      $('#phoneMobile').usPhoneFormat();
      IfitIsCustomer();
   }, []);
   const resetpassword = () => {
      var currentPassword = $("#currentPassword").val();
      var confirmPassword = $("#confirmPassword").val();
      var newPassword = $("#newPassword").val();
      if (confirmPassword.match(newPassword)) {

         var resetPwd =
         {

            "currentPassword": currentPassword,
            "newPassword": newPassword

         };
         $("#loadingforsubmit").show();
         //   setLoading(true);

         var requestOptions = requestObject.RequestHeader(resetPwd);
         services.getCustomer_details(requestOptions).then((res) => {
            // setLoading(false);
            $("#loadingforsubmit").hide();
            if (res.success) {
               Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: "",
                  text: res.message,

                  showConfirmButton: true

               }).then((result) => {
                  if (result.isConfirmed) {

                  }
               });

            } else {

               Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: "",
                  text: res.message,

                  showConfirmButton: true

               }).then((result) => {
                  if (result.isConfirmed) {

                  }
               });

            }

         });

      } else {


         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Password mismatch",

            showConfirmButton: true

         }).then((result) => {
            if (result.isConfirmed) {

            }
         });

      }



   }
   const IfitIsCustomer = () => {
      var customerListReq =
      {

         //   "id":""

      };



      var requestOptions = requestObject.RequestHeader(customerListReq);
      services.getAccountType(requestOptions).then((res) => {
         $("#loading").hide();
         sessionStorage.setItem("comapnyName", res.rows ? res.rows.name : "");
         $("#comapnyName").html(res.rows ? res.rows.name : "")
         $("#dotNumber").val(res.rows ? res.rows.dotNumber : "");
         $("#userId").val(res.rows ? res.rows.id : "");
         $("#ctNumber").val(res.rows ? res.rows.ctNumber : "");
         $("#name").val(res.rows ? res.rows.name : "");
         $("#email").val(res.rows ? res.rows.email : "");
         $("#phoneMobile").val(res.rows ? res.rows.phoneBusiness : "");
         $("#addressLine1").val(res.rows ? res.rows.addressLine1 : "");
         $("#addressLine2").val(res.rows ? res.rows.addressLine2 : "");
         $("#city").val(res.rows ? res.rows.city : "");
         $("#zipcode").val(res.rows ? res.rows.zip : "");
         setStateIdC(res.rows ? res.rows.fkStateId : "");
         // alert(res.rows.id);
         // sessionStorage.setItem("fleetCompanyLogo", res.customValues ? res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/fleeto-icon.png" : "/images/fleeto-icon.png");
         sessionStorage.setItem("companyId", res.rows.id);
         setProfileDetails(res.rows);
         setProfilePic(res.customValues ? res.customValues.fileByte ? "data:image/jpeg;base64," + res.customValues.fileByte : "/images/fleeto-icon.png" : "/images/fleeto-icon.png")
         setImage(res.customValues ? res.customValues.fileByte ? "data:image/jpeg;base64," + res.customValues.fileByte : "/images/fleeto-icon.png" : "/images/fleeto-icon.png");
         if(res.customValues)
         {

             try{
                 sessionStorage.setItem("fleetCompanyLogo",res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/fleeto-icon.png" );
                 $("#fleetCompanyLogo").attr("src",res.customValues ? res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/fleeto-icon.png" : "/images/fleeto-icon.png");
               //   $("#fleetCompanyLogo").attr("src","data:image/png;base64,"+res.customValues.fileByte);
             }
             catch(error)
             {
             

             }
            
         }else{
             try
             {
                 sessionStorage.setItem("fleetCompanyLogo", "/images/fleeto-icon.png" );
             }catch(error)
             {
             

             }
            
         }

         //  setcustomerDetails(res.rows);


      });
   }

   const updateProfileImage = () => {
      $("#loadingforsubmit").show();
      // setLoading(true);
      document.getElementById("btncloseImage").click();

      var requestObjectCustomer =
      {


         "id": sessionStorage.getItem("companyId"),
         "dotNumber": $("#dotNumber").val().trim(),
         "ctNumber": $("#ctNumber").val().trim(),
         "name": $("#name").val().trim(),
         "email": $("#email").val().trim(),
         "phoneBusiness": $("#phoneMobile").val().trim(),
         "addressLine1": $("#addressLine1").val().trim(),
         "addressLine2": $("#addressLine2").val().trim(),
         "city": $("#city").val().trim(),
         "stateId": $("#state").val(),
         "zip": $("#zipcode").val().trim(),

         "logByteString": cropper.getCroppedCanvas() ? cropper.getCroppedCanvas({  minWidth: 240,  minHeight: 240,  maxWidth: 350,  maxHeight: 350}).toDataURL('image/jpeg').replace("data:image/jpeg;base64,", "") : "",

         "logoName": cropper.getCroppedCanvas() ? parseInt(Math.random() * 1000000000) : "",

         "logoExtension": cropper.getCroppedCanvas() ? "jpeg" : ""



      }

      var requestOptions = requestObject.RequestHeader(requestObjectCustomer);
      services.updateFleetDetails(requestOptions).then((res) => {
         // setLoading(false);
         $("#loadingforsubmit").hide();
         if (res.success) {
            Swal.fire({
               position: 'center',
               icon: 'success',
               title: "",
               text: res.message,
               showConfirmButton: true

            }).then((result) => {
               
               //IfitIsCustomer();
               window.location.reload();
            });

         }
         else {

            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: res.message,
               showConfirmButton: true

            }).then((result) => {
               IfitIsCustomer();

            });

         }



      });


   }


   const updateProfile = () => {


      document.getElementById("profileModelClose").click();
      // setLoading(true);
      // alert($("#userId").val());
      $("#loadingforsubmit").show();
      var logByteString="";
      var logoName="";
      var logoExtension="";
      if(image==="/images/fleeto-icon.png")
      {
         
      }else{
         logByteString=image.replace("data:image/jpeg;base64,", "") ? image.replace("data:image/jpeg;base64,", "") : "";
         logoName=image.replace("data:image/jpeg;base64,", "") ? parseInt(Math.random() * 1000000000) : "";
         logoExtension=image.replace("data:image/jpeg;base64,", "") ? "jpeg" : "";
      }
      var customerListReq =
      {

         // "id":sessionStorage.getItem("userId"),
         "id": $("#userId").val(),
         "dotNumber": $("#dotNumber").val().trim(),
         "ctNumber": $("#ctNumber").val().trim(),
         "name": $("#name").val().trim(),
         "email": $("#email").val().trim(),
         "phoneBusiness": $("#phoneMobile").val().trim(),
         "addressLine1": $("#addressLine1").val().trim(),
         "addressLine2": $("#addressLine2").val().trim(),
         "city": $("#city").val().trim(),
         "stateId": $("#state").val(),
         "zip": $("#zipcode").val().trim(),
         // "logByteString": cropper.getCroppedCanvas() ? cropper.getCroppedCanvas({  minWidth: 240,  minHeight: 240,  maxWidth: 350,  maxHeight: 350}).toDataURL('image/jpeg').replace("data:image/jpeg;base64,", "") : "",

         // "logoName": cropper.getCroppedCanvas() ? parseInt(Math.random() * 1000000000) : "",

         // "logoExtension": cropper.getCroppedCanvas() ? "jpeg" : ""

         "logByteString":logByteString ,

         "logoName": logoName,

         "logoExtension": logoExtension

      };



      var requestOptions = requestObject.RequestHeader(customerListReq);
      services.updateFleetDetails(requestOptions).then((res) => {
         $("#loadingforsubmit").hide();
         // setLoading(false);
         if (res.success) {
            Swal.fire({
               position: 'center',
               icon: 'success',
               title: "",
               text: res.message,

               showConfirmButton: true

            }).then((result) => {
               if (result.isConfirmed) {
                 // IfitIsCustomer();
                    window.location.reload();

                  // $(".media-body").load(window.location.reload() + " .media-body");

               }
            });
         }
         else {
            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: res.message,

               showConfirmButton: true

            }).then((result) => {
               if (result.isConfirmed) {

               }
            });
         }

      });

   }

   const onChange = (e) => {
      const MIN_FILE_SIZE = 5120 // 5MB
    const fileSizeKiloBytes=Math.floor(e.target.files[0].size/1024); //
    if(fileSizeKiloBytes <= MIN_FILE_SIZE){
      e.preventDefault();
      let files;
      var ext = e.target.value.split('.').pop();
      var allowedExtensions = /(\jpg|\png|\jpeg)$/i;

      if (!allowedExtensions.exec(ext)) {

         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Invalid file type",
            showConfirmButton: true

         }).then((result) => {

            if (result.isConfirmed) {

               e.target.value = '';
               return false;
            }
         });

      } else {
         $("#DB_Image").hide();
         $('#selectedImage').show();
         $('#uploadImageDisable').prop('disabled', false);
         if (e.dataTransfer) {
            files = e.dataTransfer.files;
         } else if (e.target) {
            files = e.target.files;
         }
         const reader = new FileReader();
         reader.onload = () => {
            setImage(reader.result);
         };
         reader.readAsDataURL(files[0]);

      }
   } else {
      Swal.fire({
          position: 'center',
          icon: 'error',
          title: "",
          text: "Max file size is 5MB",
          showConfirmButton: true

      }).then((result) => {
      });
  }
   };
   $(document).on("click", "#EditImage", function () {
      $('#selectedImage').hide();
      $('#DB_Image').show();
      $('#uploadImageDisable').prop('disabled', true);
    });

   return (
      <>
         {loadingComponent}
         {LoadingComponentForSubmit}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block;" }}>
               <div class="row">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <h4 class="font-weight-bold my-4 text-uppercase">Company Profile</h4>
                  </div>
                  <div class="col-md-11 m-auto mb-2 grid-margin stretch-card d-md-flex">
                     <div class="card profile-wrap">
                        <div class="card-body p-0 " style={{ padding: "0px;" }}>
                           <div class="row">
                              <div class="col-lg-3 col-md-12 pr-0 border-right">
                                 <ul class="nav nav-pills nav-pills-vertical nav-pills-info border-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <li class="nav-item">
                                       <a class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="false">
                                          <i class="mr-2"> <img src="/images/icons/user.png" alt="" /></i>
                                          Company Info
                                       </a>
                                    </li>
                                    {/* <li class="nav-item">
                                          <a class="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                          <i class="mr-2"><img src="/images/icons/lock.png" alt=""/></i>
                                          Password
                                          </a>
                                       </li>
                                       <li class="nav-item">
                                          <a class="nav-link " id="v-pills-messages-tab" data-bs-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="true">
                                          <i class="mr-2"><img src="/images/icons/document.png" alt=""/></i>
                                          My Docs
                                          </a>
                                       </li> */}
                                 </ul>
                              </div>
                              <div class="col-lg-9 col-md-12 pl-0 ">
                                 <div class="tab-content tab-content-vertical" id="v-pills-tabContent">
                                    <div class="tab-pane fade active show" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                       <div class="media-body">
                                          <h5 class="mt-0">Company Information</h5>
                                          <div class="pro-info-wrap pb-5">
                                             <div class="pro-info-item">Company Logo</div>
                                             <div class="pro-info-item d-flex flex-column">
                                                <img src={profilePic ? profilePic : "/images/fleeto-icon.png"} class="avatar-prof mb-2" alt=""  />
                                                <small>Max File Size : 5MB</small>
                                                <small>Formats: JPG/PNG</small>
                                                {/* <small>Allowed file types: png, jpg, jpeg.</small> */}
                                                {/* <small>Ratio: 1:1 (width and height must be equal)</small> */}
                                                {/* <small>Allowed file types: png, jpg, jpeg.</small> */}
                                             </div>
                                             <div class="pro-info-item">
                                                <span class="edit-cust">
                                                   {roles.match(Dispatcher) ? "" : <a href="#" data-toggle="modal" data-target="#exampleModal" id="EditImage" data-backdrop="static" data-keyboard="false">
                                                      <img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />Edit</a>}
                                                </span>
                                             </div>
                                          </div>

                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Name</div>
                                             <div class="pro-info-item">{profileDetails.name ? profileDetails.name : "N/A"}</div>
                                             <span class="edit-cust">
                                                {roles.match(Dispatcher) ? "" : <a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />Edit</a>}
                                             </span>
                                          </div>

                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Address line1</div>
                                             <div class="pro-info-item">{profileDetails.addressLine1 ? profileDetails.addressLine1 : "N/A"}</div>
                                             {/* <div class="pro-info-item">
                                                   <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a>
                                                   </span>
                                                </div> */}
                                          </div>

                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Address line2</div>
                                             <div class="pro-info-item">{profileDetails.addressLine2 ? profileDetails.addressLine2 : "N/A"}</div>
                                             {/* <div class="pro-info-item">
                                                   <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a>
                                                   </span>
                                                </div> */}
                                          </div>



                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">City</div>
                                             <div class="pro-info-item">{profileDetails.city ? profileDetails.city : "N/A"}</div>
                                          </div>

                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">State</div>
                                             <div class="pro-info-item">{profileDetails.stateName ? profileDetails.stateName : "N/A"}</div>
                                          </div>

                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Zip code</div>
                                             <div class="pro-info-item">{profileDetails.zip ? profileDetails.zip : "N/A"}</div>
                                          </div>

                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Phone number</div>
                                             <div class="pro-info-item">{profileDetails.phoneBusiness ? profileDetails.phoneBusiness : "N/A"}</div>
                                             {/* <div class="pro-info-item">
                                                   <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a>
                                                   </span>
                                                </div> */}
                                          </div>

                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Email</div>
                                             <div class="pro-info-item">{profileDetails.email ? profileDetails.email : "N/A"}</div>
                                             {/* <div class="pro-info-item">
                                                   <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a>
                                                   </span>
                                                </div> */}
                                          </div>
                                          <div class="p-3 mt-2 mb-2" style={{ marginLeft: "0px" }}>
                                             <h4>ID Numbers</h4>
                                          </div>

                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item"> <span class="lableUpper">DOT</span> number</div>
                                             <div class="pro-info-item">{profileDetails.dotNumber ? profileDetails.dotNumber : "N/A"} </div>

                                          </div>
                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item"><span class="lableUpper">CT</span> number</div>
                                             <div class="pro-info-item">{profileDetails.ctNumber ? profileDetails.ctNumber : "N/A"}</div>

                                          </div>

                                       </div>
                                    </div>
                                    <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                       <div class="media-body">
                                          <h5 class="mt-0"> Change Password</h5>
                                          <div class="pro-info-wrap align-items-center">
                                             <div class="pro-info-item">Current Password</div>
                                             <div class="pro-info-item"><input type="password" class="form-control w-half" id="currentPassword" placeholder="" aria-describedby="emailHelp" /></div>

                                          </div>
                                          <div class="pro-info-wrap align-items-center">
                                             <div class="pro-info-item">New Password</div>
                                             <div class="pro-info-item"><input type="password" class="form-control w-half" id="newPassword" placeholder="" aria-describedby="emailHelp" /></div>

                                          </div>
                                          <div class="pro-info-wrap align-items-center">
                                             <div class="pro-info-item">Confirm Password</div>
                                             <div class="pro-info-item"><input type="password" class="form-control w-half" id="confirmPassword" placeholder="" aria-describedby="emailHelp" /></div>

                                          </div>
                                          <div class="modal-footer">
                                             <button type="button" class="btn btn-primary" onClick={() => resetpassword()}>Save changes</button>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="tab-pane fade " id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                       <div class="media-body">
                                          <h5 class="mt-0">My Documents</h5>
                                          <div class="my-doc-wrap">
                                             <div style={{ position: "relative" }}>

                                                <span class="text-left d-block">Driving Licence</span>
                                                <img src="/images/docexample.jpg" alt="" />
                                                <a href="#" data-toggle="modal" data-target="#exampleModaldoc">
                                                   <div class="doc-img">
                                                      <img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />
                                                   </div>
                                                </a>
                                                <div class="my-doc-title">
                                                   <span>Licence Number</span>
                                                   <b>86127876</b>
                                                   <a href="#" data-toggle="modal" data-target="#exampleModallic">
                                                      <div class="doc-img">
                                                         <img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />
                                                      </div>
                                                   </a>
                                                   <br />
                                                   <span>DL Expiry Date</span>
                                                   <b>20-07-2034</b>
                                                </div>
                                                {/* <!-- <div class="my-doc-title">

                                                   </div> --> */}
                                             </div>

                                             <div>
                                                <span class="text-left d-block">Driving Licence</span>
                                                <img src="/images/noimg.jpg" alt="" />
                                                <div class="my-doc-title">
                                                   <span>xxxxxxxxxxx</span>
                                                   <b>xxxxxxx</b>
                                                </div>

                                             </div>
                                          </div>

                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* <!-- Modal --> */}
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Image</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        {/* <div class="m-auto">
                              <div class="avtar-wrap"></div>
                           </div> */}
                            <input type="file" class="w-100 p-3 mb-3" onChange={onChange} accept="image/jpeg,image/png,image/jpeg" />

                        <div class="row mb-2" id="selectedImage">
                           <div class="col-12">

                              <div class="image-upload-plg">
           
                                 {/* <Cropper
                                    style={{ height:"320px", width:"320px", margin:"auto", marginTop:"10px" }}
                                    zoomTo={0.5}
                                    initialAspectRatio={4 / 4}
                                    preview=".img-preview"
                                    src={image}
                                    viewMode={3}
                                    minCropBoxHeight={10}
                                    minCropBoxWidth={10}
                                    background={false}
                                    responsive={true}
                                    autoCropArea={1}
                                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                    onInitialized={(instance) => {
                                       setCropper(instance);
                                    }}
                                    guides={true}
                                 /> */}
                                 <Cropper
                                    style={{ height: 300, width: "100%" }}
                                    aspectRatio={1}
                                    guides={false}
                                    dragMode={"move"}
                                    src={image}
                                    onInitialized={(instance) => {
                                       setCropper(instance);
                                    }}
                                 />
                              </div>


                           </div>

                           <div>
                              <div className="box" style={{ width: "240px", height: "240px", float: "right", display: "none" }}>
                                 <h1>Preview</h1>
                                 <div
                                    className="img-preview"

                                 />
                              </div>
                              {/* <div
                        className="box"
                        style={{ width: "50%", float: "right", height: "300px" }}
                     >
                        <h1>
                           <span>Crop</span>
                           <button style={{ float: "right" }} onClick={getCropData}>
                           Crop Image
                           </button>
                        </h1>
                        <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                     </div> */}
                           </div>
                        </div>
                        <div class="row mb-2" id="DB_Image">
                        <div className="m-auto mt-2">
                           <img src={image} style={{ width: "240px", height: "240px", marginTop:"20px" }} ></img>

                        </div>
                     </div>

                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" id="btncloseImage">Close</button>
                        <button type="button" class="btn btn-primary" id="uploadImageDisable" onClick={updateProfileImage}>Save changes</button>
                     </div>
                  </div>
               </div>
            </div>



            <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Info</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="profileModelClose">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <form onSubmit={handleSubmit(updateProfile)}>
                        <div class="modal-body">
                           <input type="hidden" id="userId" />


                           <div class="row">
                              <div class="col-md-12">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Name<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="name" aria-describedby="emailHelp" {...register('name', { required: true })} />
                                    {errors.name && errors.name.type === "required" && <span class="error">Name is required</span>}
                                 </div>
                              </div>

                           </div>
                           <div class="row">
                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Address line 1<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="addressLine1" maxLength="50" aria-describedby="emailHelp" {...register('addressLine1', {
                                       required: true
                                    })} />
                                    {errors.addressLine1 && errors.addressLine1.type === "required" && <span class="error">Address line 1 is required</span>}

                                 </div>
                              </div>

                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Address line 2</label>
                                    <input type="text" class="form-control custome-input" id="addressLine2" maxLength="50" defaultValue="Address line2" aria-describedby="emailHelp" />
                                    {/* {...register('addressLine2', {
                                       required: true
                                    })}
                                    {errors.addressLine2 && errors.addressLine2.type === "required" && <span class="error">Address line 2 is required</span>} */}

                                 </div>
                              </div>
                           </div>
                           <div class="row">
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">City <span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="city" defaultValue='city' minLength="2" maxLength="50" onChange={e => setFieldName(e.target.value)}    {...register("City", {
                                       required: "City is required.",
                                       maxLength: {
                                          value: 50,
                                          message: "City shouldn't exceed the max length 50 characters"
                                       },
                                       minLength: {
                                          value: 2,
                                          message: "City atleast 2 characters"
                                       },
                                       // pattern: {
                                       //    value: /^[a-zA-Z\s]*$/,
                                       //    message: "City is allow only character's"
                                       // }
                                    })} />
                                    <ErrorMessage
                                       errors={errors}
                                       name="City"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                 </div>
                              </div>
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleFormControlSelect1">State<span class="star">*</span></label>

                                    {/* <select id="inputState"   class="form-select  custome-select" name="state">
                                                <option selected value=' '>Select State</option>
                                                {
                                                    states.map((state, index) => (
                                                        <option value={state.id}>{state.name}</option>
                                                    ))
                                                }


                                          </select> */}
                                    <select class="form-control custome-select" id="state" defaultValue={"stateIdC"}  >

                                       <option value="">Select state</option>
                                       {
                                          states ? states.map((state, index) => (
                                             <option value={state.id} selected={state.id == stateIdC ? "slected" : ''}>{state.name}</option>
                                          )) : <option value=""></option>
                                       }
                                    </select>

                                 </div>
                              </div>
                           </div>
                           <div class="row">
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Zip Code <span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="zipcode" defaultValue="12345" maxLength="5" onChange={e => setFieldName(e.target.value)}    {...register("zip", {
                                       required: "Zip code is required",
                                       pattern: {
                                          value: /^[0-9]{5}(-[0-9]{1})?$/,
                                          message: "Zip code should be 5 digits"
                                       },
                                    })} />
                                    <ErrorMessage
                                       errors={errors}
                                       name="zip"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                 </div>
                              </div>
                           </div>
                           <div class="row">

                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Phone number<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="phoneMobile" aria-describedby="emailHelp" {...register('phoneMobile', { required: true, maxLength: 12, minLength: 12 })} />
                                    {errors.phoneMobile && errors.phoneMobile.type === "required" && <span class="error">Phone number is required</span>}
                                    {errors.phoneMobile && errors.phoneMobile.type === "maxLength" && <p class="error">Phone number should be 10 digits</p>}
                                    {errors.phoneMobile && errors.phoneMobile.type === "minLength" && <p class="error">Phone number should be 10 digits</p>}
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Email<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="email" aria-describedby="emailHelp" {...register('email', {
                                       required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                    })} readOnly />
                                    {errors.email && errors.email.type === "required" && <span class="error">Email is required</span>}
                                    {errors.email && errors.email.type === "pattern" && <span class="error">Invalid email address</span>}
                                 </div>
                              </div>
                           </div>

                           {/* <hr/> */}
                           {/* <div class="p-3 mt-2 mb-2" style={{marginLeft:"-15px"}}>
                                    <h4>ID Numbers</h4>
                                 </div> */}
                           <div class="row">
                              <div class="col-md-6">

                                 <div class="form-group">
                                    <label for="exampleInputEmail1"><span class="lableUpper">DOT</span> Number<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="dotNumber" maxLength="20" aria-describedby="emailHelp" {...register('dotNumber', { required: true })} />
                                    {errors.dotNumber && errors.dotNumber.type === "required" && <span class="error">DOT number is required</span>}
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1"><span class="lableUpper">CT</span> Number</label>
                                    <input type="text" class="form-control custome-input" id="ctNumber" maxLength="20" aria-describedby="emailHelp" />
                                    {/* {errors.ctNumber && errors.ctNumber.type === "required" && <span class="error">CT number is required</span>} */}
                                 </div>
                              </div>
                           </div>
                           {/* <hr/> */}
                        </div>
                        <div class="modal-footer">
                           <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                           <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>

            {/* <!-- doc edit --> */}
            <div class="modal fade" id="exampleModaldoc" tabindex="-1" aria-labelledby="exampleModalLabeldoc" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Photo</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <div class="m-auto">
                           <div class="doc-img-wrap">
                              hhhhhhhhhhhhhhh
                           </div>

                        </div>


                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                     </div>
                  </div>
               </div>
            </div>

            <div class="modal fade" id="exampleModallic" tabindex="-1" aria-labelledby="exampleModalLabeldlic" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Document</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <div class="m-auto">
                           <div class="doc-img-wrap">

                           </div>

                        </div>

                        <div class="row">

                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Licence Number</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                              </div>
                           </div>



                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Expiry Date</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                 <i> <img src="/images/icons/d" alt="" /></i>

                              </div>
                           </div>





                        </div>
                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
};

FleetOwnerSettings.propTypes = {};

FleetOwnerSettings.defaultProps = {};

export default FleetOwnerSettings;
