import React, { useEffect, useState } from "react";
import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import Pagination from "../../../utility/pagination";
import Tableheader from "../../../utility/tableheader";
import $ from "jquery";
import moment from 'moment'
import Search from "../../../utility/search";
import {useNavigate , useParams } from "react-router-dom";
import UpcomingSearch from "../fleet-owner/reminder_utility/upcoming_search";
import AtGarageSearch from "../fleet-owner/reminder_utility/atgarage_search";
import DueSearch from "../fleet-owner/reminder_utility/due_search";
import GarageReminders from "../fleet-owner/reminder_utility/allGarageReminder";
import CompletedSearch from "../fleet-owner/reminder_utility/completed_search";
const UpcomingOverdue = () =>
{


    const [sortingField, setSortingField] = useState("");
    const [sortingOrder, setSortingOrder] = useState("");
    // const ITEMS_PER_PAGE = 10;
    const navigate = useNavigate();
    const [dueList, setDue] = useState();
    const [dueSearchValue, setSearchValueDue] = useState("");
    const [ITEMS_PER_PAGE, setItemPerPage] = useState(25);
    const [ITEMS_PER_PAGE_DUE, setItemPerDuePage] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [duestatus, setduestatus] = useState(false);

    const [totalItemsDue, setTotalItemsDue] = useState();
    const [currentPageDue, setCurrentPageDue] = useState(1);
    const [totalpagesDue, settotalPagesDue] = useState();
    const [searchDue, setSearchDue] = useState("");
    // const [sortingDue, setSortingDue] = useState({ field: "", order: "" });
    // // const ITEMS_PER_PAGEAt = 25;
    const [displayPageDue, setdisplayPageDue] = useState([]);

    const thStyle = '';
    const inlineCss =
    {
        textAlign: "left"
    }
    const inlineWidthFix=
    {
        minWidth:"195px"
    }

    const inlineUo=
    {
        minWidth:"140px",
        paddingRight:"15px"
    }

    const inlineWidth90=
    {
        minWidth:"90px"
    }


    const headDue =
        [
            { name: "Customer Name", field: "customerName", sortable: false, inlineStyle: inlineCss, inlineStyle: inlineUo },
            { name: "Unit Number", field: "unitNumber", sortable: true, inlineStyle: inlineCss, inlineStyle: inlineUo },
            { name: "VIN Number", field: "vinNumber", sortable: true, inlineStyle: inlineCss, inlineStyle: inlineUo },
            // { name: "Category", field: "Document Category", sortable: true, inlineStyle: inlineCss },
            { name: "Inspection", field: "docSubCategory", sortable: true, inlineStyle: inlineCss },
            // { name: "Document Date", field: "documentDate", sortable: false, inlineStyle: inlineCss },
            { name: "Expiry Date", field: "expiryDate", sortable: false, inlineStyle: inlineCss },
            { name: "Actions" }
        ];

  
    const redirect=(page)=>
    {

        navigate(page);
        sessionStorage.setItem("naviationfrom","upcoimgOverdue");
    }
   




    useEffect(() => {
        dueListApi();
   
        $('#dueSlected').click(function() {
            setduestatus($(this).is(':checked'));
            setSearchValueDue($("#searchValue").val());
            GarageReminders("1", "1", ITEMS_PER_PAGE_DUE, $("#searchValue").val(), "", ITEMS_PER_PAGE_DUE, setTotalItemsDue, settotalPagesDue, setCurrentPageDue, setDue, setdisplayPageDue, sortingField, sortingOrder, "displayItems",$(this).is(':checked'));
            // GarageReminders("1", "1", ITEMS_PER_PAGE_DUE, $("#searchValue").val(), "", ITEMS_PER_PAGE_DUE, setTotalItemsDue, settotalPagesDue, setCurrentPageDue, setDue, setdisplayPageDue, "expiryDate", "desc", "displayItems",$(this).is(':checked'));
            // $('#textbox2').val($(this).is(':checked'));
        });

        // $('#display_pages_due_search').on('change', function () {
        //     setItemPerDuePage($("#display_pages_due_search").val());
        //     GarageReminders("1", "1", $("#display_pages_due_search").val(), "", "", $("#display_pages_due_search").val(), setTotalItemsDue, settotalPagesDue, setCurrentPageDue, setDue, setdisplayPageDue, sortingField, sortingOrder, "",duestatus);

        // });

      
    }, []);

    const displayPageNumbers =(e)=>
{

//   setitemperpage(e.target.value);
  setItemPerDuePage($("#display_pages_due_search").val());
  GarageReminders("1", "1", $("#display_pages_due_search").val(), $("#searchValue").val(), "", $("#display_pages_due_search").val(), setTotalItemsDue, settotalPagesDue, setCurrentPageDue, setDue, setdisplayPageDue, sortingField, sortingOrder, "",duestatus);
}


    const vehicleInfo = (id) => {

        localStorage.setItem("vehicleId",id);

       navigate("/fleetowner/AssignToGarrage/" + id);
    }


  

 


    const dueListApi = () => {



        var vechileReq =
        {
            "pageNumber": "1",
            "pageSize": ITEMS_PER_PAGE_DUE,
            "lisencePlateNumber": "",
            "vehicleTypeId": "",
            "overDue": duestatus,
            "sortBy": "",
            "sortDir": "",
            "isActive":true

        }

        var requestOptions = requestObject.RequestHeader(vechileReq);
        // services.remindersAll(requestOptions).then((res) => {
        services.listAllGarageReminders(requestOptions).then((res) => {

            setDue(res.rows);

            setTotalItemsDue(res.customValues ? res.customValues.totalNumberOfRecords : 0);

            settotalPagesDue(Math.ceil(res.customValues ? res.customValues.totalNumberOfPages : '' / ITEMS_PER_PAGE));
            setCurrentPageDue(res.customValues ? res.customValues.currentPageNumber : '');
            var displaypages=[];
            if(res.rows!==null)
            {

              setDue(res.rows);

              for(var i=1;i<=parseInt(Math.ceil(res.customValues.totalNumberOfRecords/ITEMS_PER_PAGE));i++)
              {

                displaypages.push(i*ITEMS_PER_PAGE);
              }
            }else{


              setDue("");
            }

            //setTotalItemsDue(res.customValues.totalNumberOfRecords);

             


                 

                    setdisplayPageDue(displaypages);


           // DueSearch("1", "1", ITEMS_PER_PAGE, "", "", ITEMS_PER_PAGE, setTotalItemsDue, settotalPagesDue, setCurrentPageDue, setDue, setdisplayPageDue, sortingField, sortingOrder, "displayItems");
        });

    }

  

    const handlePageChangeDue = (offset, triggerevent) => {
        GarageReminders("", offset, ITEMS_PER_PAGE, dueSearchValue, "", ITEMS_PER_PAGE, setTotalItemsDue, settotalPagesDue, setCurrentPageDue, setDue, setdisplayPageDue, sortingField, sortingOrder, "",duestatus);
    }

    const searchListDue = (dueSearchValue) => {
        setSearchValueDue(dueSearchValue);
        console.log("search="+dueSearchValue);
        console.log("dueselect="+duestatus);
        GarageReminders("1", "1", ITEMS_PER_PAGE_DUE, dueSearchValue, "", ITEMS_PER_PAGE_DUE, setTotalItemsDue, settotalPagesDue, setCurrentPageDue, setDue, setdisplayPageDue, sortingField, sortingOrder, "displayItems",duestatus);
    }

    const onSortingChangeDue = (field) => {
        var vehicleId = "";
        const order =
            field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

        setSortingField(field);
        setSortingOrder(order);
        GarageReminders("1", "1", ITEMS_PER_PAGE_DUE, dueSearchValue, vehicleId, ITEMS_PER_PAGE_DUE, setTotalItemsDue, settotalPagesDue, setCurrentPageDue, setDue, setdisplayPageDue, field, order, "displayItems",duestatus);

    };



  return(
  <>
    <div class="main-panel">
        <div class="content-wrapper" style={{display: "block;"}}>
         <div class="row">
              <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                <h4 class="font-weight-bold my-4 text-uppercase">Upcoming/Overdue</h4>
             </div>
         </div>

          <div class="row">
            <div class="col-lg-11 col-md-12 m-auto grid-margin stretch-card">
              <div class="card">
                <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                  <div class="position-relative align-self-start mb-1 mt-1">
                  <div class="d-flex">
                                                            <div className="search">
                                                            <Search placeholderName="Search by VIN Number"
                                                                onSearch={value => {
                                                                    searchListDue(value);
                                                                }} id="upcomingSearchValue" />
                                                            </div>


                                                               <div className="ml-5">
                                                               <input type="checkbox"
                                                                            defaultChecked={false}
                                                                            id="dueSlected"
                                                                             />
                                                               </div>
                                                                        <span style={{marginLeft:"10px",marginTop:"10px"}}>
                                                                  Overdue only
                                                                  </span>

                                                        </div>

                     <a  class="search-icon">
                     <img src="images/icons/search.svg" alt=""/>
                     </a>
                  </div>

                  <div class="d-flex align-self-start mb-1 mt-1">

                  </div>
               </div>
                <div class="card-body pt-0 pb-0">

                  <div class="row">
                    <div class="col-12">
                      <div class="table-responsive border-top border-bottom">



                      <table class="table table-hover">
                                                                        <tr>
                                                                            {headDue.map(({ name, field, sortable, thStyle, inlineStyle }) => (
                                                                                <th
                                                                                    key={name}
                                                                                    onClick={() =>
                                                                                        sortable ? onSortingChangeDue(field) : null
                                                                                    }
                                                                                    class={thStyle} style={inlineStyle}>
                                                                                    {name}

                                                                                    {sortingField && sortingField === field && (

                                                                                        sortingOrder === "asc"
                                                                                            ? "↑"
                                                                                            : "↓"
                                                                                    )}

                                                                                </th>
                                                                            ))}
                                                                        </tr>

                                                                        <tbody>

                                                                            {
                                                                                dueList ? dueList.map((data, index) => (
                                                                                    <tr >
                                                                                        {/* onClick={() => redirect("/vehicleDetails/"+data.fkVehicleId)} */}
                                                                                        <td class="text-capitalize" style={{  whiteSpace:"break-spaces", paddingRight:"10px" , lineHeight:"18px" }}>{data.customerName ? data.customerName : "--"}</td>
                                                                                        <td style={{  whiteSpace:"break-spaces", paddingRight:"10px" , lineHeight:"18px" }}>{data.unitNumber ? data.unitNumber : "--"}</td>
                                                                                        <td style={{  whiteSpace:"break-spaces", paddingRight:"10px" , lineHeight:"18px" }}>{data.vinNumber ? data.vinNumber : "--"} </td>
                                                                                        {/* <td>{data.title}</td> */}
                                                                                        {/* <td>{data.lisencePlateNumber}</td> */}
                                                                                        {/* <td>{data.documentSubCategoryName}</td> */}
                                                                                        {/* <td>{data.documentCategoryName ? data.documentCategoryName : "--"}</td> */}
                                                                                        {/* <td>{data.expiryDate}</td> */}
                                                                                        <td style={{  whiteSpace:"break-spaces", paddingRight:"10px" , lineHeight:"18px" }}>{data.documentSubCategoryName ? data.documentSubCategoryName : "--"}</td>
                                                                                        {/* <td>{data.documentDate ? data.documentDate : "--"}</td> */}
                                                                                        <td style={{  whiteSpace:"break-spaces", paddingRight:"15px" , lineHeight:"18px" }}>{data.expiryDate ? moment(data.expiryDate).format('MM-DD-YYYY'): "--"}</td>
                                                                                        <td style={{  whiteSpace:"break-spaces", paddingRight:"10px" , lineHeight:"18px", width:"30px" }}><a  style={{marginRight:"2px"}} onClick={() => redirect("/vehicleDetails/"+data.id)} class="btn btn-outline-primary text-uppercase">View</a></td>
                                                                                        {/* <td style={{ width:"65px", whiteSpace:"break-spaces", paddingRight:"10px" , lineHeight:"18px" }}><a onClick={() => redirect("/vehicleDetails/" + data.id)}  class="btn btn-outline-primary text-uppercase">View</a>  </td> */}
                                                                                        {/* <a onClick={() => vehicleInfo(data.id)} class="btn btn-primary text-uppercase">Resolve</a> */}
                                                                                    </tr>
                                                                                )) : ""
                                                                            }



                                                                        </tbody>
                                                                    </table>
                      </div>
                    </div>
                    {dueList !==null? "": <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                             </div>}
                  </div>
                  </div>

                  <div class="d-flex align-items-center justify-content-between p-3">
                   <div class="d-flex">
                    <div class="dropdown show mr-3 ">
                    <select class="form-control custome-select btn gray-mid-color dropdown-toggle" href="#" role="button" id="display_pages_due_search" onChange={e => displayPageNumbers(e)} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    {
                                                                        displayPageDue!=null?displayPageDue.map((data, index) => (
                                                                            <option value={data}>{data}</option>
                                                                        )):<option value='0'>0</option>
                                                                    }
                                                                </select>
                      </div>
                      <span class="d-flex align-items-center pagenation-mob">{currentPage * ITEMS_PER_PAGE_DUE <= totalItemsDue ? currentPage * ITEMS_PER_PAGE_DUE : totalItemsDue} out of {totalItemsDue} Records Displayed</span>
                                                        </div>
                                                        {/* <Pagination

                                                            total={totalItemsDue}
                                                            itemsPerPage={ITEMS_PER_PAGE_DUE}
                                                            currentPage={currentPageDue}
                                                            onPageChange={handlePageChangeDue}
                                                        /> */}
                                                                            <nav aria-label="Page navigation example">
                                                       {totalItemsDue>0?<Pagination

                                                            total={totalItemsDue}
                                                            itemsPerPage={ITEMS_PER_PAGE_DUE}
                                                            currentPage={currentPageDue}
                                                            onPageChange={handlePageChangeDue}
                                                        />:""}
                                                        </nav>

                </div>

                </div>

              </div>

            </div>
        </div>

      </div>

  </>
)};

UpcomingOverdue.propTypes = {};

UpcomingOverdue.defaultProps = {};

export default UpcomingOverdue;
