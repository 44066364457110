import React,{history,useEffect, useState, useMemo } from "react";
import services from "../../../services/services";
import $ from "jquery";
import { requestObject } from "../../../utility/requestObject";
const inspectionOrderList = (id,pageNumber,ITEMS_PER_PAGE,setInspectionList,setTotalInspectionList,settotalinspectionPages,setInspectionCurrentPage,setdisplayPageForInspection,inspectionType ) => 
{
   
   var inspectionRequest=
   {
             "accountId": "1",
             "vehicleId":id,
             "inspectionType":inspectionType,
             "pageNumber":pageNumber,
             "pageSize":ITEMS_PER_PAGE,
             "sortBy":"",
             "sortDir":"",
             "isActive":true
          
   };
  
   // const requestOptions = 
   // {
   //       method: 'POST',
   //       headers:{'Content-Type': 'application/json'},
   //       body: JSON.stringify(inspectionRequest)
   // };
 
   var requestOptions=requestObject.RequestHeader(inspectionRequest);
   // $("#loading").show();
   services.listInspectionsToBeApproved(requestOptions).then((res) => 
   {
       $("#loading").hide();
      if (res.success) {
         
      setInspectionList(res ? res.rows : "");
      setTotalInspectionList(res.customValues.totalNumberOfRecords);
      settotalinspectionPages(res.customValues.totalNumberOfRecords);
      setInspectionCurrentPage(res.customValues.currentPageNumber);
      var displaypages=[];
         
      for(var i=1;i<=parseInt(Math.ceil(res.customValues.totalNumberOfRecords/25));i++)
      {
         displaypages.push(i*25);
      }
    
      setdisplayPageForInspection(displaypages);
     }
     else{
      setInspectionList("");
     }
   });
      
}

export default inspectionOrderList;