import React, { history, useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import AddInspectionName from "../../../../utility/addInspectionName";
import removePdf from "../../../../utility/removePdf";
import { ExitToApp } from "@material-ui/icons";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const TractorpmTestEdit = () => {

   const { taskOrderId,fkAccountId, inspectionId, vid } = useParams();
   const [fromDate, setFromDate] = useState();
   const [inputList, setInputList] = useState([{}]);
   const [base64file, setBase64file] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const [fileName, setfileName] = useState("");
   const [inspectionDetails, setInspectionDetails] = useState("");
   const [tractorpminspDetails, settractorPMInspectionDetails] = useState("");
   const [documentList, setDocumentLists] = useState([]);
   const [testPressureSet, setTestPressure] = useState([]);
   const [inspectionTypeId, setinspectionTypeId] = useState("");
   const navigate = useNavigate();
   const [endDate, setEndDate] = useState(new Date());
   var documentLists = [];
   const [roles, setRoles] = useState("");
   const [statusInspection, setStatus] = useState("");
   const [verifiedDATE, setverifiedDate] = useState(new Date());
   const [expiryDate, setexpiryDate] = useState(new Date());
   useEffect(() => {
      $('#break_measures').hide();
      $('#threaddepth_measures').hide();
      $('#tractorpm_validation').hide();
      $('#tpmt_dateValidMsg').hide();
      $('#tpmt_customerValidMsg').hide();
      $('#tpmt_unitValidMsg').hide();
      $('#tpmt_mileageValidMsg').hide();
      $('#tpmt_invoiceValidMsg').hide();
      $('#breaklininglfValidMsg').hide();
      $('#breakliningrfValidMsg').hide();
      $('#breaklininglfrValidMsg').hide();
      $('#breakliningrfrValidMsg').hide();
      $('#breaklininglrrValidMsg').hide();
      $('#breakliningrrrValidMsg').hide();
      $('#treaddepthrfValidMsg').hide();
      $('#treaddepthrfpsiValidMsg').hide();
      $('#treaddepthlfValidMsg').hide();
      $('#treaddepthlfpsiValidMsg').hide();
      $('#treaddepthrfrValidMsg').hide();
      $('#treaddepthrfrpsiValidMsg').hide();
      $('#treaddepthlfrValidMsg').hide();
      $('#treaddepthlfrpsiValidMsg').hide();
      $('#treaddepthrrrValidMsg').hide();
      $('#treaddepthrrrpsiValidMsg').hide();
      $('#treaddepthlrrValidMsg').hide();     
      $('#treaddepthlrrpsiValidMsg').hide(); 
      setRoles(localStorage.getItem("Userroles"));
      // vehicle_details_api(id, setVehicleDetails, setCustomerId);
      getInpection(fkAccountId, inspectionId);
      setTimeout(() => {
         getInpection(fkAccountId, inspectionId);
      }, 1000)
         
      measurelining();
      treaddepths();
   }, []);

   function getInpection(fkAccountId, inspectionId) {
      var requestObjectForLeakage =
      {

         "id": inspectionId
      }

      var requestOptions = requestObject.RequestHeader(requestObjectForLeakage);

      services.getInspectionDetails(requestOptions).then((res) => {
         setInspectionDetails(res.rows);
         settractorPMInspectionDetails(res.rows.tractorPmInspection);
         console.log(res.rows.tractorPmInspection);
         setDocumentLists(res.rows.documentList);
         setinspectionTypeId(res.rows.inspectionType.id);
         $("#workOrderNumber").val(res.rows ? res.rows.workOrderNumber : "");
         $("#notes").val(res.rows ? res.rows.notes : "");
         $("#inspectedBy").val(res.rows ? res.rows.inspectedBy : "");
         setEndDate(new Date(res.rows ? res.rows.inspectedDate : ""));
         documentLists.push(res.rows.documentList);
         sessionStorage.setItem("documentList", JSON.stringify(res.rows.documentList));
         setStatus(res.rows.status);
         if (res.rows) 
         {
            if (res.rows.verifiedDate) 
            {
               setverifiedDate(new Date(res.rows ? res.rows.verifiedDate : ''));
            }
            if(res.rows.expiryDate)
            {
               setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
            }

            if (res.rows.tractorPmInspection) 
            {
               var tractorpm = res.rows.tractorPmInspection;
               if (tractorpm.breakSystem.measureBreakLining == 1) 
               {
                  $('#break_measures').show();
               }
               else {
                  $('#breaklininglf').val('');
                  $('#breakliningrf').val('');
                  $('#breaklininglfr').val('');
                  $('#breakliningrfr').val('');
                  $('#breaklininglrr').val('');
                  $('#breakliningrrr').val('');
                  $('#break_measures').hide();
               }

               if (tractorpm.tiresOrWheels.inspectTreadDepths == 1) 
               {
                  $('#threaddepth_measures').show();
               } else {
                  $('#treaddepthrf').val('');
                  $('#treaddepthrfpsi').val('');
                  $('#treaddepthlf').val('');
                  $('#treaddepthlfpsi').val('');
                  $('#treaddepthrfr').val('');
                  $('#treaddepthrfrpsi').val('');
                  $('#treaddepthlfr').val('');
                  $('#treaddepthlfrpsi').val('');
                  $('#treaddepthrrr').val('');
                  $('#treaddepthrrrpsi').val('');
                  $('#treaddepthlrr').val('');
                  $('#treaddepthlrrpsi').val('');
                  $('#threaddepth_measures').hide();
               }
            }
         }
      });
   }


   const redirect = (page) => {

      navigate(page);

   }

   const handleSubmit = () => {
      let form_is_valid = true;
      var lightingdevices = $("input[name='lightingdevices']:checked").val();
      if(lightingdevices == '' || lightingdevices == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectwindshield = $("input[name='inspectwindshield']:checked").val();
      if(inspectwindshield == '' || inspectwindshield == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectwiper = $("input[name='inspectwiper']:checked").val();   
      if(inspectwiper == '' || inspectwiper == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var washerfluid = $("input[name='washerfluid']:checked").val();
      
      var inspectbumper = $("input[name='inspectbumper']:checked").val();
      var inspectsteps = $("input[name='inspectsteps']:checked").val();
      var inspectmirrors = $("input[name='inspectmirrors']:checked").val();
      var inspectfueltank = $("input[name='inspectfueltank']:checked").val();
               
      var inspectemergencyequip = $("input[name='inspectemergencyequip']:checked").val();
      if(inspectemergencyequip == '' || inspectemergencyequip == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectwarnlight = $("input[name='inspectwarnlight']:checked").val();
      if(inspectwarnlight == '' || inspectwarnlight == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectabslight = $("input[name='inspectabslight']:checked").val();
      if(inspectabslight == '' || inspectabslight == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspecthorns = $("input[name='inspecthorns']:checked").val();
      if(inspecthorns == '' || inspecthorns == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
           
      var measurebreaklining = $("input[name='measurebreaklining']:checked").val();
      var inspectbreaks = $("input[name='inspectbreaks']:checked").val();
      if(inspectbreaks == '' || inspectbreaks == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectparkingbreak = $("input[name='inspectparkingbreak']:checked").val();
      if(inspectparkingbreak == '' || inspectparkingbreak == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectbreakdrum = $("input[name='inspectbreakdrum']:checked").val();
      if(inspectbreakdrum == '' || inspectbreakdrum == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectbreakhoses = $("input[name='inspectbreakhoses']:checked").val();
      if(inspectbreakhoses == '' || inspectbreakhoses == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectbreaktubing = $("input[name='inspectbreaktubing']:checked").val();
      if(inspectbreaktubing == '' || inspectbreaktubing == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectaircompressor = $("input[name='inspectaircompressor']:checked").val();
      if(inspectaircompressor == '' || inspectaircompressor == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectabssystem = $("input[name='inspectabssystem']:checked").val();
      if(inspectabssystem == '' || inspectabssystem == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectslackadjuster = $("input[name='inspectslackadjuster']:checked").val();
      if(inspectslackadjuster == '' || inspectslackadjuster == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
                  
      var inspecthoses = $("input[name='inspecthoses']:checked").val();
      var inspectbelt = $("input[name='inspectbelt']:checked").val();
      var inspectleaks = $("input[name='inspectleaks']:checked").val();
      if(inspectleaks == '' || inspectleaks == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectexhaustleaks = $("input[name='inspectexhaustleaks']:checked").val();
      if(inspectexhaustleaks == '' || inspectexhaustleaks == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectnoburning = $("input[name='inspectnoburning']:checked").val();
      if(inspectnoburning == '' || inspectnoburning == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
                                
      var inspectsteering = $("input[name='inspectsteering']:checked").val();
      if(inspectsteering == '' || inspectsteering == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectsteeringwheel = $("input[name='inspectsteeringwheel']:checked").val();
      if(inspectsteeringwheel == '' || inspectsteeringwheel == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectsteeringcolumn = $("input[name='inspectsteeringcolumn']:checked").val();
      if(inspectsteeringcolumn == '' || inspectsteeringcolumn == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectsteeringsystem = $("input[name='inspectsteeringsystem']:checked").val();
      if(inspectsteeringsystem == '' || inspectsteeringsystem == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectwheelseals = $("input[name='inspectwheelseals']:checked").val();
      var inspecttransmissionlube = $("input[name='inspecttransmissionlube']:checked").val();
      var inspectjoints = $("input[name='inspectjoints']:checked").val();
      var inspecthousinglube = $("input[name='inspecthousinglube']:checked").val();
      var inspectallseals = $("input[name='inspectallseals']:checked").val();
      
      var inspectframe = $("input[name='inspectframe']:checked").val();
      if(inspectframe == '' || inspectframe == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectspringhangers = $("input[name='inspectspringhangers']:checked").val();
      var inspectspringassembly = $("input[name='inspectspringassembly']:checked").val();
      if(inspectspringassembly == '' || inspectspringassembly == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspecttorque = $("input[name='inspecttorque']:checked").val();
      if(inspecttorque == '' || inspecttorque == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectframemembers = $("input[name='inspectframemembers']:checked").val();
      if(inspectframemembers == '' || inspectframemembers == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspecttireclearance = $("input[name='inspecttireclearance']:checked").val();
      if(inspecttireclearance == '' || inspecttireclearance == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
                  
      var inspectfifthwheel = $("input[name='inspectfifthwheel']:checked").val();
      if(inspectfifthwheel == '' || inspectfifthwheel == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectpintlehook = $("input[name='inspectpintlehook']:checked").val();
      if(inspectpintlehook == '' || inspectpintlehook == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectsafetydevices = $("input[name='inspectsafetydevices']:checked").val();
      if(inspectsafetydevices == '' || inspectsafetydevices == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectsaddlemounts = $("input[name='inspectsaddlemounts']:checked").val();
      if(inspectsaddlemounts == '' || inspectsaddlemounts == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
                                                                       
      var inspectwheelsforcracks = $("input[name='inspectwheelsforcracks']:checked").val();
      if(inspectwheelsforcracks == '' || inspectwheelsforcracks == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectbrokenlug = $("input[name='inspectbrokenlug']:checked").val();
      if(inspectbrokenlug == '' || inspectbrokenlug == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspectvalvestems = $("input[name='inspectvalvestems']:checked").val();
      if(inspectvalvestems == '' || inspectvalvestems == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var checktirepressure = $("input[name='checktirepressure']:checked").val();
      var inspectsteeringaxle = $("input[name='inspectsteeringaxle']:checked").val();
      if(inspectsteeringaxle == '' || inspectsteeringaxle == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspecttires = $("input[name='inspecttires']:checked").val();
      if(inspecttires == '' || inspecttires == undefined){
        $('#tractorpm_validation').show();
        form_is_valid = false;
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        return false
      }
      else {
        $('#tractorpm_validation').hide();
        form_is_valid = true;
      }
  
      var inspecttreaddepths = $("input[name='inspecttreaddepths']:checked").val();
      var inspectbatterybox = $("input[name='inspectbatterybox']:checked").val();
      var inspectbatteryconnections = $("input[name='inspectbatteryconnections']:checked").val();
  
      var changeoil = $("input[name='changeoil']:checked").val();
      var changeoilfilter = $("input[name='changeoilfilter']:checked").val();
      var inspectengineairfilter = $("input[name='inspectengineairfilter']:checked").val();
      var inspectfreshairfilter = $("input[name='inspectfreshairfilter']:checked").val();
  
      var lubeallpoints = $("input[name='lubeallpoints']:checked").val();
      var lube5thwheel = $("input[name='lube5thwheel']:checked").val();
      var checkfluidlevels = $("input[name='checkfluidlevels']:checked").val();
      var inspectallplugs = $("input[name='inspectallplugs']:checked").val();
      var changeservicenumber = $("input[name='changeservicenumber']:checked").val();
      
      
  //alert(lightingdevices);
  
  if(measurebreaklining != undefined){
    var breaklininglf = $('#breaklininglf').val();
      if(breaklininglf == '' || breaklininglf == undefined){
        $('#breaklininglfValidMsg').show();
        form_is_valid = false;
        $('#breaklininglf').focus();
        return false
      }
      else {
        $('#breaklininglfValidMsg').hide();
        form_is_valid = true;
      }
  
      var breakliningrf = $('#breakliningrf').val();
      if(breakliningrf == '' || breakliningrf == undefined){
        $('#breakliningrfValidMsg').show();
        form_is_valid = false;
        $('#breakliningrf').focus();
        return false
      }
      else {
        $('#breakliningrfValidMsg').hide();
        form_is_valid = true;
      }
  
      var breaklininglfr = $('#breaklininglfr').val();
      if(breaklininglfr == '' || breaklininglfr == undefined){
        $('#breaklininglfrValidMsg').show();
        form_is_valid = false;
        $('#breaklininglfr').focus();
        return false
      }
      else {
        $('#breaklininglfrValidMsg').hide();
        form_is_valid = true;
      }
  
      var breakliningrfr = $('#breakliningrfr').val();
      if(breakliningrfr == '' || breakliningrfr == undefined){
        $('#breakliningrfrValidMsg').show();
        form_is_valid = false;
        $('#breakliningrfr').focus();
        return false
      }
      else {
        $('#breakliningrfrValidMsg').hide();
        form_is_valid = true;
      }
  
      var breaklininglrr = $('#breaklininglrr').val();
      if(breaklininglrr == '' || breaklininglrr == undefined){
        $('#breaklininglrrValidMsg').show();
        form_is_valid = false;
        $('#breaklininglrr').focus();
        return false
      }
      else {
        $('#breaklininglrrValidMsg').hide();
        form_is_valid = true;
      }
  
      var breakliningrrr = $('#breakliningrrr').val();
      if(breakliningrrr == '' || breakliningrrr == undefined){
        $('#breakliningrrrValidMsg').show();
        form_is_valid = false;
        $('#breakliningrrr').focus();
        return false
      }
      else {
        $('#breakliningrrrValidMsg').hide();
        form_is_valid = true;
      }
  }
  
  if(inspecttreaddepths != undefined){
    var treaddepthrf = $('#treaddepthrf').val();
      if(treaddepthrf == '' || treaddepthrf == undefined){
        $('#treaddepthrfValidMsg').show();
        form_is_valid = false;
        $('#treaddepthrf').focus();
        return false
      }
      else {
        $('#treaddepthrfValidMsg').hide();
        form_is_valid = true;
      }
  
      var treaddepthrfpsi = $('#treaddepthrfpsi').val();
      if(treaddepthrfpsi == '' || treaddepthrfpsi == undefined){
        $('#treaddepthrfpsiValidMsg').show();
        form_is_valid = false;
        $('#treaddepthrfpsi').focus();
        return false
      }
      else {
        $('#treaddepthrfpsiValidMsg').hide();
        form_is_valid = true;
      }
  
      var treaddepthlf = $('#treaddepthlf').val();
      if(treaddepthlf == '' || treaddepthlf == undefined){
        $('#treaddepthlfValidMsg').show();
        form_is_valid = false;
        $('#treaddepthlf').focus();
        return false
      }
      else {
        $('#treaddepthlfValidMsg').hide();
        form_is_valid = true;
      }
  
      var treaddepthlfpsi = $('#treaddepthlfpsi').val();
      if(treaddepthlfpsi == '' || treaddepthlfpsi == undefined){
        $('#treaddepthlfpsiValidMsg').show();
        form_is_valid = false;
        $('#treaddepthlfpsi').focus();
        return false
      }
      else {
        $('#treaddepthlfpsiValidMsg').hide();
        form_is_valid = true;
      }
                     
      var treaddepthrfr = $('#treaddepthrfr').val();
      if(treaddepthrfr == '' || treaddepthrfr == undefined){
        $('#treaddepthrfrValidMsg').show();
        form_is_valid = false;
        $('#treaddepthrfr').focus();
        return false
      }
      else {
        $('#treaddepthrfrValidMsg').hide();
        form_is_valid = true;
      }
  
      var treaddepthrfrpsi = $('#treaddepthrfrpsi').val();
      if(treaddepthrfrpsi == '' || treaddepthrfrpsi == undefined){
        $('#treaddepthrfrpsiValidMsg').show();
        form_is_valid = false;
        $('#treaddepthrfrpsi').focus();
        return false
      }
      else {
        $('#treaddepthrfrpsiValidMsg').hide();
        form_is_valid = true;
      }
  
      var treaddepthlfr = $('#treaddepthlfr').val();
      if(treaddepthlfr == '' || treaddepthlfr == undefined){
        $('#treaddepthlfrValidMsg').show();
        form_is_valid = false;
        $('#treaddepthlfr').focus();
        return false
      }
      else {
        $('#treaddepthlfrValidMsg').hide();
        form_is_valid = true;
      }
  
      var treaddepthlfrpsi = $('#treaddepthlfrpsi').val();
      if(treaddepthlfrpsi == '' || treaddepthlfrpsi == undefined){
        $('#treaddepthlfrpsiValidMsg').show();
        form_is_valid = false;
        $('#treaddepthlfrpsi').focus();
        return false
      }
      else {
        $('#treaddepthlfrpsiValidMsg').hide();
        form_is_valid = true;
      }
      
      var treaddepthrrr = $('#treaddepthrrr').val();
      if(treaddepthrrr == '' || treaddepthrrr == undefined){
        $('#treaddepthrrrValidMsg').show();
        form_is_valid = false;
        $('#treaddepthrrr').focus();
        return false
      }
      else {
        $('#treaddepthrrrValidMsg').hide();
        form_is_valid = true;
      }
  
      var treaddepthrrrpsi = $('#treaddepthrrrpsi').val();
      if(treaddepthrrrpsi == '' || treaddepthrrrpsi == undefined){
        $('#treaddepthrrrpsiValidMsg').show();
        form_is_valid = false;
        $('#treaddepthrrrpsi').focus();
        return false
      }
      else {
        $('#treaddepthrrrpsiValidMsg').hide();
        form_is_valid = true;
      }
  
      var treaddepthlrr = $('#treaddepthlrr').val();
      if(treaddepthlrr == '' || treaddepthlrr == undefined){
        $('#treaddepthlrrValidMsg').show();
        form_is_valid = false;
        $('#treaddepthlrr').focus();
        return false
      }
      else {
        $('#treaddepthlrrValidMsg').hide();
        form_is_valid = true;
      }
  
      var treaddepthlrrpsi = $('#treaddepthlrrpsi').val();
      if(treaddepthlrrpsi == '' || treaddepthlrrpsi == undefined){
        $('#treaddepthlrrpsiValidMsg').show();
        form_is_valid = false;
        $('#treaddepthlrrpsi').focus();
        return false
      }
      else {
        $('#treaddepthlrrpsiValidMsg').hide();
        form_is_valid = true;
      }
  }
      
       // alert(form_is_valid);
      
  /*
      if(form_is_valid == false) {
  
        $('html,body').animate({ scrollTop: "600px" }, 1000);
        form_is_valid = false;
        alert(form_is_valid);
        $('#error_message').css("color", "#d33232").html("*Please select one option for each question");
      
      } else {
        $('#error_message').html('');
      }
      */
      // alert(form_is_valid);
      return form_is_valid;
  

   }

   const save = (status) => {
      // sessionStorage.setItem("verifiedBy",$("#verifiedBy").val());
      // console.log("results=="+JSON.stringify(inputList));
      try {
         for (var i = 0; i < inputList.length; i++) {
            // console.log("filebase64=="+inputList[i].filetobase64);
            var extension = "";
            var byteString = "";
            if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
               extension = "pdf";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
            } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
               extension = "jpg";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
               extension = "png";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/msword;base64,")) {
               extension = "word";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/msword;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:image/bmp;base64,")) {
               extension = "bmp";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/bmp;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/docx;base64,")) {
               extension = "docx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/docx;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/doc;base64,")) {
               extension = "docx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/doc;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/docx;base64,")) {
               extension = "docx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/docx;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/doc;base64,")) {
               extension = "docx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/doc;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,")) {
               extension = "docx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,")) {
               extension = "docm";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,")) {
               extension = "docx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,")) {
               extension = "dotx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,")) {
               extension = "dotx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,")) {
               extension = "dotm";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", "") : "";
            }
            else {

            }

            documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
         }
      } catch (exception) {
         // documentList.push();
         // Swal.fire({
         //     position: 'center',
         //     icon: 'error',
         //     title: "",
         //     text:"Please attach document",
         //     showConfirmButton: true

         //   }).then((result) =>
         //   {

         //         if(result.isConfirmed)
         //         {


         //         }
         //   });
         //   return false;
      }
      try {
         if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {

            var i;
            var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
            for (i = 0; i < storedArray.length; i++) {

               documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
            }

         } else {

            documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
         }

      } catch (ex) {

      }
      if ($("#inspectedBy").val() === "") {
         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Please enter Inspected by",
            showConfirmButton: true

         }).then((result) => {

            if (result.isConfirmed) {


            }
         });
         return false;
      }
      if (status == 'DRAFT') {
//alert($("input[name='inspecttreaddepths']:checked").val());

         var requestObjectForInsp =
         {
            "id": inspectionId,
            "expiryDate": $("#expiryDate").val(),
            "type": "Tractor Preventive Maintenance/Inspection",
            "inspectedBy": $("#inspectedBy").val(),
            "inspectedDate": $("#inspectedDate").val(),
            "verifiedBy": $("#verifiedBy").val(),
            "verifiedDate": $("#verifiedByBate").val(),
            "workOrderNumber": $("#workOrderNumber").val() != '' ? $("#workOrderNumber").val() : '',
            "notes": $("#notes").val() != '' ? $("#notes").val() : '',
            "status": status,
            "taskOrderId":taskOrderId,
            "inspectionType":
            {
               "id": inspectionTypeId,
               "name": "Tractor Preventive Maintenance/Inspection"
            },
            "result":
            {
               "approvedCertify": "0",
               "inspectionFailed": "0"
            },
            "dispositionOfVehicle":
            {
               "vehicleReturnedServices": "0",
               "vehicleWithdrawnFromService": "0",
               "vehicleSentToAnotherRepairFacility": "0"
            },
            "vehicleDetails":
            {
               "vinNumber": $("#vin").html(),
               "lisencePlateNumber": $("#lisencePlateNumber").html(),
               "id": vid,
               "unitNumber": $("#lisencePlateNumber").html()
            },
            "tractorPmInspection":
        {
          "tpmt_date" : $('#tpmt_date').val(),
          "tpmt_customer" : $('#tpmt_customer').val(),
          "tpmt_unit" : $('#tpmt_unit').val(),
          "tpmt_mileage" : $('#tpmt_mileage').val(),
          "tpmt_invoice" : $('#tpmt_invoice').val(),
          "lighting":
          {
            "allLightingDevices": $("input[name='lightingdevices']:checked").val() === "on" ? "1" : "0",
          },
          "windShield":
          {
            "inspectWindShield": $("input[name='inspectwindshield']:checked").val() === "on" ? "1" : "0",
            "inspectWiper": $("input[name='inspectwiper']:checked").val() === "on" ? "1" : "0",
            "inspectWasherFluid": $("input[name='washerfluid']:checked").val() === "on" ? "1" : "0",
          },
          "generalInspection":
          {
            "inspectBumpers": $("input[name='inspectbumper']:checked").val() === "on" ? "1" : "0",
            "inspectSteps": $("input[name='inspectsteps']:checked").val() === "on" ? "1" : "0",
            "inspectMirrors": $("input[name='inspectmirrors']:checked").val() === "on" ? "1" : "0",
            "inspectFuelTank": $("input[name='inspectfueltank']:checked").val() === "on" ? "1" : "0",
          },
          "inCab":
          {
            "inspectEmergencyEquip": $("input[name='inspectemergencyequip']:checked").val() === "on" ? "1" : "0",
            "inspectWarnLight": $("input[name='inspectwarnlight']:checked").val() === "on" ? "1" : "0",
            "inspectABSLight": $("input[name='inspectabslight']:checked").val() === "on" ? "1" : "0",
            "inspectHorns": $("input[name='inspecthorns']:checked").val() === "on" ? "1" : "0",
          },
          "breakSystem":
          {
            "measureBreakLining": $("input[name='measurebreaklining']:checked").val() === "on" ? "1" : "0",
            "inspectBreaks": $("input[name='inspectbreaks']:checked").val() === "on" ? "1" : "0",
            "inspectParkingBreak": $("input[name='inspectparkingbreak']:checked").val() === "on" ? "1" : "0",
            "inspectBreakDrum": $("input[name='inspectbreakdrum']:checked").val() === "on" ? "1" : "0",
            "inspectBreakHoses": $("input[name='inspectbreakhoses']:checked").val() === "on" ? "1" : "0",
            "inspectBreakTubing": $("input[name='inspectbreaktubing']:checked").val() === "on" ? "1" : "0",
            "inspectAirCompressor": $("input[name='inspectaircompressor']:checked").val() === "on" ? "1" : "0",
            "inspectABSSystem": $("input[name='inspectabssystem']:checked").val() === "on" ? "1" : "0",
            "inspectSlackaAjuster": $("input[name='inspectslackadjuster']:checked").val() === "on" ? "1" : "0",
            "breakLF" : $('#breaklininglf').val(),
            "breakRF" : $('#breakliningrf').val(),
            "breakLFR" : $('#breaklininglfr').val(),
            "breakRFR" : $('#breakliningrfr').val(),
            "breakLRR" : $('#breaklininglrr').val(),
            "breakRRR" : $('#breakliningrrr').val(),
          },
          "engine":
          {
            "inspectHoses": $("input[name='inspecthoses']:checked").val() === "on" ? "1" : "0",
            "inspectBelt": $("input[name='inspectbelt']:checked").val() === "on" ? "1" : "0",
          },
          "fuelSystem":
          {
            "inspectLeaks": $("input[name='inspectleaks']:checked").val() === "on" ? "1" : "0",
          },
          "exhaustSystem":
          {
            "inspectExhaustLeaks": $("input[name='inspectexhaustleaks']:checked").val() === "on" ? "1" : "0",
            "inspectNoBurning": $("input[name='inspectnoburning']:checked").val() === "on" ? "1" : "0",
          },
          "chassis":
          {
            "inspectSteering": $("input[name='inspectsteering']:checked").val() === "on" ? "1" : "0",
              "inspectSteeringWheel": $("input[name='inspectsteeringwheel']:checked").val() === "on" ? "1" : "0",
              "inspectEngineAirFilters": $("input[name='inspectengineairfilter']:checked").val() === "on" ? "1" : "0",
              "inspectFreshAirFilter": $("input[name='inspectfreshairfilter']:checked").val() === "on" ? "1" : 
              "0",
              "lubeAllPoints": $("input[name='lubeallpoints']:checked").val() === "on" ? "1" : 
              "0",
              "lube5thWheel": $("input[name='lube5thwheel']:checked").val() === "on" ? "1" : "0",
              "inspectSteeringColumn": $("input[name='inspectsteeringcolumn']:checked").val() === "on" ? "1" : "0",
              "inspectSteeringSystem": $("input[name='inspectsteeringsystem']:checked").val() === "on" ? "1" : "0",
              "inspectHubWheelSeals": $("input[name='inspectwheelseals']:checked").val() === "on" ? "1" : "0",
              "inspectTransmissionLube": $("input[name='inspecttransmissionlube']:checked").val() === "on" ? "1" : "0",
              "inspectShaftJoints": $("input[name='inspectjoints']:checked").val() === "on" ? "1" : "0",
              "inspectDifferentHousingLube": $("input[name='inspecthousinglube']:checked").val() === "on" ? "1" : "0",
              "inspectAllSealsForLeaks": $("input[name='inspectallseals']:checked").val() === "on" ? "1" : "0",
          },
          "suspension":
          {
            "inspectFrame": $("input[name='inspectframe']:checked").val() === "on" ? "1" : "0",
            "inspectSpringHangers": $("input[name='inspectspringhangers']:checked").val() === "on" ? "1" : 
            "0",
            "inspectSpringAssembly": $("input[name='inspectspringassembly']:checked").val() === "on" ? "1" : "0",
            "inspectTorque": $("input[name='inspecttorque']:checked").val() === "on" ? "1" : "0",
          },
          "frame":
          {
            "inspectFrameMembers": $("input[name='inspectframemembers']:checked").val() === "on" ? "1" : "0",
            "inspectTireClearance": $("input[name='inspecttireclearance']:checked").val() === "on" ? "1" : 
            "0",
          },
          "couplingDevices":
          {
            "inspectFifthWheel": $("input[name='inspectfifthwheel']:checked").val() === "on" ? "1" : "0",
            "inspectPintleHook": $("input[name='inspectpintlehook']:checked").val() === "on" ? "1" : 
            "0",
            "inspectSafetyDevices": $("input[name='inspectsafetydevices']:checked").val() === "on" ? "1" : 
            "0",
            "inspectSaddleMounts": $("input[name='inspectsaddlemounts']:checked").val() === "on" ? "1" : 
            "0",
          },
          "tiresOrWheels":
          {
            "inspectWheelsforCracks": $("input[name='inspectwheelsforcracks']:checked").val() === "on" ? "1" : "0",
            "inspectBrokenLug": $("input[name='inspectbrokenlug']:checked").val() === "on" ? "1" : 
            "0",
            "inspectValveStems": $("input[name='inspectvalvestems']:checked").val() === "on" ? "1" : 
            "0",
            "checkTirePressure": $("input[name='checktirepressure']:checked").val() === "on" ? "1" : 
            "0",
            "inspectSteeringAxle": $("input[name='inspectsteeringaxle']:checked").val() === "on" ? "1" : 
            "0",
            "inspectTires": $("input[name='inspecttires']:checked").val() === "on" ? "1" : 
            "0",
            "inspectTreadDepths": $("input[name='inspecttreaddepths']:checked").val() === "on" ? "1" : 
            "0",
            "treadDepthRF" : $('#treaddepthrf').val(),
            "treadDepthRFPSI" : $('#treaddepthrfpsi').val(),
            "treadDepthLF" : $('#treaddepthlf').val(),
            "treadDepthLFPSI" : $('#treaddepthlfpsi').val(),

            "treadDepthRFR" : $('#treaddepthrfr').val(),
            "treadDepthRFRPSI" : $('#treaddepthrfrpsi').val(),
            "treadDepthLFR" : $('#treaddepthlfr').val(),
            "treadDepthLFRPSI" : $('#treaddepthlfrpsi').val(),

            "treadDepthRRR" : $('#treaddepthrrr').val(),
            "treadDepthRRRPSI" : $('#treaddepthrrrpsi').val(),
            "treadDepthLRR" : $('#treaddepthlrr').val(),
            "treadDepthLRRPSI" : $('#treaddepthlrrpsi').val(),

          },
          "electricalSystem":
          {
            "inspectBatteryBox": $("input[name='inspectbatterybox']:checked").val() === "on" ? "1" : "0",
            "inspectBatteryConnections": $("input[name='inspectbatteryconnections']:checked").val() === "on" ? "1" : 
            "0",
          },   
          "service":
          {
            "changeOil": $("input[name='changeoil']:checked").val() === "on" ? "1" : 
              "0",
              "changeOilFilter": $("input[name='changeoilfilter']:checked").val() === "on" ? "1" : 
              "0",
              "changeFuelFilter": $("input[name='changefuelfilter']:checked").val() === "on" ? "1" : 
              "0",
              "checkAllFluidLevels": $("input[name='checkfluidlevels']:checked").val() === "on" ? "1" : 
              "0",
          },   
          "overall":
          {
            "inspectAllPlugs": $("input[name='inspectallplugs']:checked").val() === "on" ? "1" : "0",
            "changeServiceNumber": $("input[name='changeservicenumber']:checked").val() === "on" ? "1" : 
            "0",
          },  
          "notes":$("#tractorpmNotes").val() != '' ? $("#tractorpmNotes").val() : '',                                                                                
        },
            "documentList": documentLists

         };

         $("#loadingforsubmit").show();
         // setLoading(true);
         // const requestOptions =
         // {
         //   method: 'POST',
         //   headers: { 'Content-Type': 'application/json'
         //             },

         //       body: JSON.stringify(requestObject)
         // };
         var requestOptions = requestObject.RequestHeader(requestObjectForInsp);
         services.addInspection(requestOptions).then((res) => {
            // setLoading(false);
            $("#loadingforsubmit").hide();
            Swal.fire({
               position: 'center',
               icon: 'success',
               title: "",
               text: "Your changes have been successfully saved!",
               showConfirmButton: true

            }).then((result) => {

               if (result.isConfirmed) {
                  navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

               }
            });
         });
      } else {
         if (handleSubmit() == true) {


            var requestObjectForInsp =
            {
               "id": inspectionId,
               "expiryDate": $("#expiryDate").val(),
               "type": "Tractor Preventive Maintenance/Inspection",
               "inspectedBy": $("#inspectedBy").val(),
               "inspectedDate": $("#inspectedDate").val(),
               "verifiedBy": $("#verifiedBy").val(),
               "verifiedDate": $("#verifiedByBate").val(),
               "workOrderNumber": $("#workOrderNumber").val() != '' ? $("#workOrderNumber").val() : '',
               "notes": $("#notes").val() != '' ? $("#notes").val() : '',
               "status": status,
               "taskOrderId":taskOrderId,
               "inspectionType":
               {
                  "id": inspectionTypeId,
                  "name": "Tractor Preventive Maintenance/Inspection"
               },
               "result":
               {
                  "approvedCertify": "0",
                  "inspectionFailed": "0"
               },
               "dispositionOfVehicle":
               {
                  "vehicleReturnedServices": "0",
                  "vehicleWithdrawnFromService": "0",
                  "vehicleSentToAnotherRepairFacility": "0"
               },
               // "result": {
               //    "approvedCertify": $("input[name='approvedCertify']:checked").val() === "approvedCertify" ? "1" : "0",
               //    "inspectionFailed": $("input[name='approvedCertify']:checked").val() === "inspectionFailed" ? "1" : "0"
               // },
               // "dispositionOfVehicle": {
               //    "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
               //    "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
               //    "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
               // },
               "vehicleDetails":
               {
                  "vinNumber": $("#vin").html(),
                  "lisencePlateNumber": $("#lisencePlateNumber").html(),
                  "id": vid,
                  "unitNumber": $("#lisencePlateNumber").html()
               },
               "tractorPmInspection":
        {
          "tpmt_date" : $('#tpmt_date').val(),
          "tpmt_customer" : $('#tpmt_customer').val(),
          "tpmt_unit" : $('#tpmt_unit').val(),
          "tpmt_mileage" : $('#tpmt_mileage').val(),
          "tpmt_invoice" : $('#tpmt_invoice').val(),
          "lighting":
          {
            "allLightingDevices": $("input[name='lightingdevices']:checked").val() === "on" ? "1" : "0",
          },
          "windShield":
          {
            "inspectWindShield": $("input[name='inspectwindshield']:checked").val() === "on" ? "1" : "0",
            "inspectWiper": $("input[name='inspectwiper']:checked").val() === "on" ? "1" : "0",
            "inspectWasherFluid": $("input[name='washerfluid']:checked").val() === "on" ? "1" : "0",
          },
          "generalInspection":
          {
            "inspectBumpers": $("input[name='inspectbumper']:checked").val() === "on" ? "1" : "0",
            "inspectSteps": $("input[name='inspectsteps']:checked").val() === "on" ? "1" : "0",
            "inspectMirrors": $("input[name='inspectmirrors']:checked").val() === "on" ? "1" : "0",
            "inspectFuelTank": $("input[name='inspectfueltank']:checked").val() === "on" ? "1" : "0",
          },
          "inCab":
          {
            "inspectEmergencyEquip": $("input[name='inspectemergencyequip']:checked").val() === "on" ? "1" : "0",
            "inspectWarnLight": $("input[name='inspectwarnlight']:checked").val() === "on" ? "1" : "0",
            "inspectABSLight": $("input[name='inspectabslight']:checked").val() === "on" ? "1" : "0",
            "inspectHorns": $("input[name='inspecthorns']:checked").val() === "on" ? "1" : "0",
          },
          "breakSystem":
          {
            "measureBreakLining": $("input[name='measurebreaklining']:checked").val() === "on" ? "1" : "0",
            "inspectBreaks": $("input[name='inspectbreaks']:checked").val() === "on" ? "1" : "0",
            "inspectParkingBreak": $("input[name='inspectparkingbreak']:checked").val() === "on" ? "1" : "0",
            "inspectBreakDrum": $("input[name='inspectbreakdrum']:checked").val() === "on" ? "1" : "0",
            "inspectBreakHoses": $("input[name='inspectbreakhoses']:checked").val() === "on" ? "1" : "0",
            "inspectBreakTubing": $("input[name='inspectbreaktubing']:checked").val() === "on" ? "1" : "0",
            "inspectAirCompressor": $("input[name='inspectaircompressor']:checked").val() === "on" ? "1" : "0",
            "inspectABSSystem": $("input[name='inspectabssystem']:checked").val() === "on" ? "1" : "0",
            "inspectSlackaAjuster": $("input[name='inspectslackadjuster']:checked").val() === "on" ? "1" : "0",
            "breakLF" : $('#breaklininglf').val(),
            "breakRF" : $('#breakliningrf').val(),
            "breakLFR" : $('#breaklininglfr').val(),
            "breakRFR" : $('#breakliningrfr').val(),
            "breakLRR" : $('#breaklininglrr').val(),
            "breakRRR" : $('#breakliningrrr').val(),
          },
          "engine":
          {
            "inspectHoses": $("input[name='inspecthoses']:checked").val() === "on" ? "1" : "0",
            "inspectBelt": $("input[name='inspectbelt']:checked").val() === "on" ? "1" : "0",
          },
          "fuelSystem":
          {
            "inspectLeaks": $("input[name='inspectleaks']:checked").val() === "on" ? "1" : "0",
          },
          "exhaustSystem":
          {
            "inspectExhaustLeaks": $("input[name='inspectexhaustleaks']:checked").val() === "on" ? "1" : "0",
            "inspectNoBurning": $("input[name='inspectnoburning']:checked").val() === "on" ? "1" : "0",
          },
          "chassis":
          {
            "inspectSteering": $("input[name='inspectsteering']:checked").val() === "on" ? "1" : "0",
              "inspectSteeringWheel": $("input[name='inspectsteeringwheel']:checked").val() === "on" ? "1" : "0",
              "inspectEngineAirFilters": $("input[name='inspectengineairfilter']:checked").val() === "on" ? "1" : "0",
              "inspectFreshAirFilter": $("input[name='inspectfreshairfilter']:checked").val() === "on" ? "1" : 
              "0",
              "lubeAllPoints": $("input[name='lubeallpoints']:checked").val() === "on" ? "1" : 
              "0",
              "lube5thWheel": $("input[name='lube5thwheel']:checked").val() === "on" ? "1" : "0",
              "inspectSteeringColumn": $("input[name='inspectsteeringcolumn']:checked").val() === "on" ? "1" : "0",
              "inspectSteeringSystem": $("input[name='inspectsteeringsystem']:checked").val() === "on" ? "1" : "0",
              "inspectHubWheelSeals": $("input[name='inspectwheelseals']:checked").val() === "on" ? "1" : "0",
              "inspectTransmissionLube": $("input[name='inspecttransmissionlube']:checked").val() === "on" ? "1" : "0",
              "inspectShaftJoints": $("input[name='inspectjoints']:checked").val() === "on" ? "1" : "0",
              "inspectDifferentHousingLube": $("input[name='inspecthousinglube']:checked").val() === "on" ? "1" : "0",
              "inspectAllSealsForLeaks": $("input[name='inspectallseals']:checked").val() === "on" ? "1" : "0",
          },
          "suspension":
          {
            "inspectFrame": $("input[name='inspectframe']:checked").val() === "on" ? "1" : "0",
            "inspectSpringHangers": $("input[name='inspectspringhangers']:checked").val() === "on" ? "1" : 
            "0",
            "inspectSpringAssembly": $("input[name='inspectspringassembly']:checked").val() === "on" ? "1" : "0",
            "inspectTorque": $("input[name='inspecttorque']:checked").val() === "on" ? "1" : "0",
          },
          "frame":
          {
            "inspectFrameMembers": $("input[name='inspectframemembers']:checked").val() === "on" ? "1" : "0",
            "inspectTireClearance": $("input[name='inspecttireclearance']:checked").val() === "on" ? "1" : 
            "0",
          },
          "couplingDevices":
          {
            "inspectFifthWheel": $("input[name='inspectfifthwheel']:checked").val() === "on" ? "1" : "0",
            "inspectPintleHook": $("input[name='inspectpintlehook']:checked").val() === "on" ? "1" : 
            "0",
            "inspectSafetyDevices": $("input[name='inspectsafetydevices']:checked").val() === "on" ? "1" : 
            "0",
            "inspectSaddleMounts": $("input[name='inspectsaddlemounts']:checked").val() === "on" ? "1" : 
            "0",
          },
          "tiresOrWheels":
          {
            "inspectWheelsforCracks": $("input[name='inspectwheelsforcracks']:checked").val() === "on" ? "1" : "0",
            "inspectBrokenLug": $("input[name='inspectbrokenlug']:checked").val() === "on" ? "1" : 
            "0",
            "inspectValveStems": $("input[name='inspectvalvestems']:checked").val() === "on" ? "1" : 
            "0",
            "checkTirePressure": $("input[name='checktirepressure']:checked").val() === "on" ? "1" : 
            "0",
            "inspectSteeringAxle": $("input[name='inspectsteeringaxle']:checked").val() === "on" ? "1" : 
            "0",
            "inspectTires": $("input[name='inspecttires']:checked").val() === "on" ? "1" : 
            "0",
            "inspectTreadDepths": $("input[name='inspecttreaddepths']:checked").val() === "on" ? "1" : 
            "0",
            "treadDepthRF" : $('#treaddepthrf').val(),
            "treadDepthRFPSI" : $('#treaddepthrfpsi').val(),
            "treadDepthLF" : $('#treaddepthlf').val(),
            "treadDepthLFPSI" : $('#treaddepthlfpsi').val(),

            "treadDepthRFR" : $('#treaddepthrfr').val(),
            "treadDepthRFRPSI" : $('#treaddepthrfrpsi').val(),
            "treadDepthLFR" : $('#treaddepthlfr').val(),
            "treadDepthLFRPSI" : $('#treaddepthlfrpsi').val(),

            "treadDepthRRR" : $('#treaddepthrrr').val(),
            "treadDepthRRRPSI" : $('#treaddepthrrrpsi').val(),
            "treadDepthLRR" : $('#treaddepthlrr').val(),
            "treadDepthLRRPSI" : $('#treaddepthlrrpsi').val(),

          },
          "electricalSystem":
          {
            "inspectBatteryBox": $("input[name='inspectbatterybox']:checked").val() === "on" ? "1" : "0",
            "inspectBatteryConnections": $("input[name='inspectbatteryconnections']:checked").val() === "on" ? "1" : 
            "0",
          },   
          "service":
          {
            "changeOil": $("input[name='changeoil']:checked").val() === "on" ? "1" : 
              "0",
              "changeOilFilter": $("input[name='changeoilfilter']:checked").val() === "on" ? "1" : 
              "0",
              "changeFuelFilter": $("input[name='changefuelfilter']:checked").val() === "on" ? "1" : 
              "0",
              "checkAllFluidLevels": $("input[name='checkfluidlevels']:checked").val() === "on" ? "1" : 
              "0",
          },   
          "overall":
          {
            "inspectAllPlugs": $("input[name='inspectallplugs']:checked").val() === "on" ? "1" : "0",
            "changeServiceNumber": $("input[name='changeservicenumber']:checked").val() === "on" ? "1" : 
            "0",
          },  
          "notes":$("#tractorpmNotes").val() != '' ? $("#tractorpmNotes").val() : '',                                                                                
        },
               "documentList": documentLists

            };

            var requestOptions = requestObject.RequestHeader(requestObjectForInsp);
            // setLoading(true);

            if (statusInspection == "DRAFT") {
               // setLoading(true);
               $("#loadingforsubmit").show();
               services.addInspection(requestOptions).then((res) => {
                  // setLoading(false);
                  $("#loadingforsubmit").hide();
                  // alert(res.success);
                  Swal.fire({
                     position: 'center',
                     icon: 'success',
                     title: "",
                     text: "Your changes have been successfully saved!",
                     showConfirmButton: true

                  }).then((result) => {

                     if (result.isConfirmed) {
                        navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));
                     }
                  });
               });
            } else {

               if ($('input:radio[name="returnedservice"]:checked').val() === "undefined" || $('input:radio[name="returnedservice"]:checked').val() === undefined) {
                  Swal.fire({
                     position: 'center',
                     icon: 'error',
                     title: "",
                     text: "Please select disposition of tank",
                     showConfirmButton: true

                  }).then((result) => {

                     if (result.isConfirmed) {

                        // alert("hhhhd")
                     }
                  });
                  return false;
               }
               if ($('input:radio[name="approvedCertify"]:checked').val() === "" || $('input:radio[name="approvedCertify"]:checked').val() === undefined) {
                  Swal.fire({
                     position: 'center',
                     icon: 'error',
                     title: "",
                     text: "Please select inspection result",
                     showConfirmButton: true

                  }).then((result) => {

                     if (result.isConfirmed) {


                     }
                  });
                  return false;
               }
               if ($("input[name='approvedCertify']:checked").val() === "approvedCertify") {

               } else {

                  status = "Rejected";
               }

               var requestObjectInspection =
               {
                  "id": inspectionId,
                  "taskOrderId":taskOrderId,
                  "expiryDate": $("#expiryDate").val(),
                  "verifiedBy": $("#verifiedBy").val(),
                  "verifiedDate": $("#verifiedByBate").val(),
                  
                  "status": status,

                  "result": {
                     "approvedCertify": $("input[name='approvedCertify']:checked").val() === "approvedCertify" ? "1" : "0",
                     "inspectionFailed": $("input[name='approvedCertify']:checked").val() === "inspectionFailed" ? "1" : "0"
                  },
                  "dispositionOfVehicle": {
                     "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
                     "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
                     "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
                  }

               };
               // setLoading(true);
               $("#loadingforsubmit").show();
               services.addInspection(requestOptions).then((res) => {
                  // setLoading(false);
                  if (res.success) {

                     //certify inspection
                     var requestOptions = requestObject.RequestHeader(requestObjectInspection);
                     services.certifyInspection(requestOptions).then((res) => {
                        // setLoading(false);
                        $("#loadingforsubmit").hide();
                        Swal.fire({
                           position: 'center',
                           icon: 'success',
                           title: "",
                           text: res.message,
                           showConfirmButton: true

                        }).then((result) => {

                           if (result.isConfirmed) {
                              navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

                           }
                        });
                     });
                  } else {
                     Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: "",
                        text: "Your changes have been successfully saved!",
                        showConfirmButton: true

                     }).then((result) => {
                        if (result.isConfirmed) {
                           navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));
                        }
                     });
                  }
               });
            }
         }
      }

   }

    const measurelining = () => {
      var measurebreaklining = $("input[name='measurebreaklining']:checked").val();
      //alert(measurebreaklining);
      if(measurebreaklining === 'on'){
         $('#break_measures').show();
      } else {
        $('#breaklininglf').val('');
        $('#breakliningrf').val('');
        $('#breaklininglfr').val('');
        $('#breakliningrfr').val('');
        $('#breaklininglrr').val('');
        $('#breakliningrrr').val('');
        $('#break_measures').hide();
      }
    }
  
    const treaddepths = () => {
      var inspecttreaddepths = $("input[name='inspecttreaddepths']:checked").val();
      //alert(inspecttreaddepths);
      if(inspecttreaddepths === 'on'){
         $('#threaddepth_measures').show();
      }
      else{
        $('#treaddepthrf').val('');
        $('#treaddepthrfpsi').val('');
        $('#treaddepthlf').val('');
        $('#treaddepthlfpsi').val('');
        $('#treaddepthrfr').val('');
        $('#treaddepthrfrpsi').val('');
        $('#treaddepthlfr').val('');
        $('#treaddepthlfrpsi').val('');
        $('#treaddepthrrr').val('');
        $('#treaddepthrrrpsi').val('');
        $('#treaddepthlrr').val('');
        $('#treaddepthlrrpsi').val('');
        $('#threaddepth_measures').hide();
      }
      
    }

   return (
      <>
      {LoadingComponentForSubmit}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block" }}>
            <div class="row mb-0">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <div class="d-flex justify-content-between align-items-center my-4">
                     <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                     </div>
                  </div>
               </div>
               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                     <HeaderBlock />
                  </div>
               </div>

               

               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                     <div class="card">

                        <div class="row mb-2">
                           <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                              <VehicleBasicInfo customerId={vid} />
                           </div>
                        </div>
                        <div class="divider"></div>

                        <div class="row p-3">
                           <div class="col-lg-12">
                              <h4 class="mb-4 form-inspection-title text-center">Tractor Preventive Maintenance/Inspection</h4>
                              <p id="tractorpm_validation" class="error">*Please select all mandate inspections</p>
                              {/*<p id="leakage-check-type-message" class="error"></p>
                              <p id="unitDesignPressure-message" class="error"></p>
                              <p id="testPressure-message" class="error"></p>
   <p id="error_message" class="center error" ></p> */}
                           </div>
                           <div class="col-lg-12"><WorkOrderNumberDiv /></div><br />
                           
                            <div class="px-3">
                                <div class="inspection-wrap">
                                  <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Lighting</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mb-2">
                                            <div class="d-flex align-items-center">
                                             
                                             {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.lighting != null){
                                                         if (inspectionDetails.tractorPmInspection.lighting.allLightingDevices == '1') {
                                                            return (<><input type="checkbox" name="lightingdevices" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="lightingdevices" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="lightingdevices" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>All lighting devices and reflectors shall be operable <span class="star">*</span></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg  mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Windshield</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.windShield != null){
                                                         if (inspectionDetails.tractorPmInspection.windShield.inspectWindShield == '1') {
                                                            return (<><input type="checkbox" name="inspectwindshield" class="mr-2" defaultChecked={true} style={{minHeight:20, maxHeight:20,}} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectwindshield" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectwindshield" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} /></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Inspect windshield (no crack, discoloration or vision reducing matter allowed) <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.windShield != null){
                                                         if (inspectionDetails.tractorPmInspection.windShield.inspectWiper == '1') {
                                                            return (<><input type="checkbox" name="inspectwiper" class="mr-2" defaultChecked={true} style={{minHeight:"20px"}}/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectwiper" class="mr-2" defaultChecked={false} style={{minHeight:"20px"}}/></>)
                                                         }
                                                      }
                                                      else {
                                                         return (<><input type="checkbox" name="inspectwiper" class="mr-2" defaultChecked={false} style={{minHeight:"20px"}}/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Inspect wipers, replace as needed <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.windShield != null){
                                                         if (inspectionDetails.tractorPmInspection.windShield.inspectWasherFluid == '1') {
                                                            return (<><input type="checkbox" name="washerfluid" class="mr-2" defaultChecked={true} style={{minHeight:"20px"}}/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="washerfluid" class="mr-2" defaultChecked={false} style={{minHeight:"20px"}}/></>)
                                                         }
                                                      }
                                                      else{
                                                         return (<><input type="checkbox" name="washerfluid" class="mr-2" defaultChecked={false} style={{minHeight:"20px"}}/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Check washer fluid level</span>
                                            </div>
                                        </div> 
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>General Inspection</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.generalInspection != null){
                                                         if (inspectionDetails.tractorPmInspection.generalInspection.inspectBumpers == '1') {
                                                            return (<><input type="checkbox" name="inspectbumper" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectbumper" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                            return (<><input type="checkbox" name="inspectbumper" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                   }
                                                   
                                                })()}
                                                <span>Inspect grille, bumpers, doors and door glass</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.generalInspection != null){
                                                         if (inspectionDetails.tractorPmInspection.generalInspection.inspectSteps == '1') {
                                                            return (<><input type="checkbox" name="inspectsteps" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectsteps" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                            return (<><input type="checkbox" name="inspectsteps" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                   }
                                                   
                                                })()}
                                                <span>Inspect steps</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.generalInspection != null){
                                                         if (inspectionDetails.tractorPmInspection.generalInspection.inspectMirrors == '1') {
                                                            return (<><input type="checkbox" name="inspectmirrors" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectmirrors" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                            return (<><input type="checkbox" name="inspectmirrors" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                   }
                                                   
                                                })()}
                                                <span>Inspect mirrors and brackets</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.generalInspection != null){
                                                         if (inspectionDetails.tractorPmInspection.generalInspection.inspectFuelTank == '1') {
                                                            return (<><input type="checkbox" name="inspectfueltank" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectfueltank" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                            return (<><input type="checkbox" name="inspectfueltank" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                   }
                                                   
                                                })()}
                                                <span>Inspect fuel tank</span>
                                            </div>
                                        </div>
                                    </div> 

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>In-cab</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.inCab != null){
                                                         if (inspectionDetails.tractorPmInspection.inCab.inspectEmergencyEquip == '1') {
                                                            return (<><input type="checkbox" name="inspectemergencyequip" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectemergencyequip" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                            return (<><input type="checkbox" name="inspectemergencyequip" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                   }
                                                })()}
                                                <span>Inspect all emergency equipment <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.inCab != null){
                                                         if (inspectionDetails.tractorPmInspection.inCab.inspectWarnLight == '1') {
                                                            return (<><input type="checkbox" name="inspectwarnlight" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectwarnlight" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectwarnlight" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect warn lights/buzzer <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.inCab != null){
                                                         if (inspectionDetails.tractorPmInspection.inCab.inspectABSLight == '1') {
                                                            return (<><input type="checkbox" name="inspectabslight" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectabslight" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectabslight" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect ABS dash light self-test <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.inCab != null){
                                                         if (inspectionDetails.tractorPmInspection.inCab.inspectHorns == '1') {
                                                            return (<><input type="checkbox" name="inspecthorns" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspecthorns" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspecthorns" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect all horns <span class="star">*</span></span>
                                            </div>
                                        </div>
                                    </div> 

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Brake System</h5>
                                            </div>
                                        </div>
                                       
                                       <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.breakSystem.inspectBreaks == '1') {
                                                            return (<><input type="checkbox" name="inspectbreaks" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectbreaks" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectbreaks" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect/Service brakes <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.breakSystem.inspectParkingBreak == '1') {
                                                            return (<><input type="checkbox" name="inspectparkingbreak" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectparkingbreak" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectparkingbreak" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect Parking brake system <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.breakSystem.inspectBreakDrum == '1') {
                                                            return (<><input type="checkbox" name="inspectbreakdrum" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectbreakdrum" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectbreakdrum" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect brake drums/rotors <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.breakSystem.inspectBreakHoses == '1') {
                                                            return (<><input type="checkbox" name="inspectbreakhoses" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectbreakhoses" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectbreakhoses" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect brake hoses <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.breakSystem.inspectBreakTubing == '1') {
                                                            return (<><input type="checkbox" name="inspectbreaktubing" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectbreaktubing" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectbreaktubing" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect brake tubing <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.breakSystem.inspectAirCompressor == '1') {
                                                            return (<><input type="checkbox" name="inspectaircompressor" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectaircompressor" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectaircompressor" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect air compressor <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.breakSystem.inspectABSSystem == '1') {
                                                            return (<><input type="checkbox" name="inspectabssystem" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectabssystem" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectabssystem" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect ABS system <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.breakSystem.inspectSlackaAjuster == '1') {
                                                            return (<><input type="checkbox" name="inspectslackadjuster" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectslackadjuster" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectslackadjuster" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect slack adjuster operation <span class="star">*</span></span>
                                            </div>
                                        </div> 
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.breakSystem.measureBreakLining == '1') {
                                                            return (<><input type="checkbox" name="measurebreaklining" class="mr-2" defaultChecked={true} onClick={measurelining} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="measurebreaklining" class="mr-2" defaultChecked={false} onClick={measurelining} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="measurebreaklining" class="mr-2" defaultChecked={false} onClick={measurelining} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Measure and record brake lining</span>
                                            </div>
                                        </div>
                                        <div class="table-3 mb-4 px-3" id="break_measures">
                                        <table class="table rt-border-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ borderRight: "1px solid #ccc" }}>Break Lining Measurements</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr class="border-bottom">
                                                    <td>
                                                        <div class="row align-items-center justify-content-between px-2">
                                                           <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">LF</span>
                                                                    <div>
                                                                    {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                                     return (<><input type="input" class="form-control" id="breaklininglf" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.tractorPmInspection.breakSystem.breakLF}/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="breaklininglf" aria-describedby="emailHelp" style={{ width: "100px" }} /></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    
                                                                    <div id="breaklininglfValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                </div>
                                                                <span style={{margin:"10px 10px"}}>%</span>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">RF</span>
                                                                    <div>
                                                                       {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                                     return (<><input type="input" class="form-control" id="breakliningrf" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.tractorPmInspection.breakSystem.breakRF}/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="breakliningrf" aria-describedby="emailHelp" style={{ width: "100px" }} /></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    <div id="breakliningrfValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                  </div>
                                                                  <span style={{margin:"10px 10px"}}>%</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">LFR</span>
                                                                    <div>
                                                                          {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                                     return (<><input type="input" class="form-control" id="breaklininglfr" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.tractorPmInspection.breakSystem.breakLFR}/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="breaklininglfr" aria-describedby="emailHelp" style={{ width: "100px" }} /></>)
                                                                  }
                                                               }
                                                            })()}
                                                            
                                                                    <div id="breaklininglfrValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                  </div>
                                                                  <span style={{margin:"10px 10px"}}>%</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">RFR</span>
                                                                    <div>
                                                                             {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                                     return (<><input type="input" class="form-control" id="breakliningrfr" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.tractorPmInspection.breakSystem.breakRFR}/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="breakliningrfr" aria-describedby="emailHelp" style={{ width: "100px" }} /></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    
                                                                    <div id="breakliningrfrValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>%</span>
                                                                </div>

                                                            </div>
                                                           <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">LRR</span>
                                                                  <div>
                                                                              {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                                     return (<><input type="input" class="form-control" id="breaklininglrr" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.tractorPmInspection.breakSystem.breakLRR}/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="breaklininglrr" aria-describedby="emailHelp" style={{ width: "100px" }} /></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    <div id="breaklininglrrValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>%</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60" >RRR</span>
                                                                    <div>
                                                                                {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                                     return (<><input type="input" class="form-control" id="breakliningrrr" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.tractorPmInspection.breakSystem.breakRRR}/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="breakliningrrr" aria-describedby="emailHelp" style={{ width: "100px" }} /></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    <div id="breakliningrrrValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>%</span>
                                                                    
                                                                </div>

                                                            </div> 
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Engine</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.engine != null){
                                                         if (inspectionDetails.tractorPmInspection.engine.inspectHoses == '1') {
                                                            return (<><input type="checkbox" name="inspecthoses" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspecthoses" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspecthoses" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect hoses/clamps</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.engine != null){
                                                         if (inspectionDetails.tractorPmInspection.engine.inspectBelt == '1') {
                                                            return (<><input type="checkbox" name="inspectbelt" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectbelt" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectbelt" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect belts</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Fuel System</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.fuelSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.fuelSystem.inspectLeaks == '1') {
                                                            return (<><input type="checkbox" name="inspectleaks" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectleaks" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectleaks" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect for leaks <span class="star">*</span></span>
                                            </div>
                                        </div>
                                    </div> 

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-2">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Exhaust System</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex ">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.exhaustSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.exhaustSystem.inspectExhaustLeaks == '1') {
                                                            return (<><input type="checkbox" name="inspectexhaustleaks" class="mr-2" defaultChecked={true} style={{minHeight:20, maxHeight:20,}} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectexhaustleaks" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectexhaustleaks" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect exhaust for leaks (no leak allowed below driver compartment) <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-8 mb-2">
                                            <div class="d-flex ">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.exhaustSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.exhaustSystem.inspectNoBurning == '1') {
                                                            return (<><input type="checkbox" name="inspectnoburning" class="mr-2" defaultChecked={true} style={{minHeight:20, maxHeight:20,}} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectnoburning" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectnoburning" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect to ensure no part of system located to cause burning, charring or damage to wiring, fuel supply or any combustible part of vehicle <span class="star">*</span></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Chassis</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectSteering == '1') {
                                                            return (<><input type="checkbox" name="inspectsteering" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectsteering" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectsteering" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect steering mechanism <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectSteeringWheel == '1') {
                                                            return (<><input type="checkbox" name="inspectsteeringwheel" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectsteeringwheel" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectsteeringwheel" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect steering wheel free play <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectEngineAirFilters == '1') {
                                                            return (<><input type="checkbox" name="inspectengineairfilter" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectengineairfilter" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectengineairfilter" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect engine air filters</span>
                                            </div>
                                        </div><div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectFreshAirFilter == '1') {
                                                            return (<><input type="checkbox" name="inspectfreshairfilter" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectfreshairfilter" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      }
                                                      else {
                                                         return (<><input type="checkbox" name="inspectfreshairfilter" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect fresh air filters</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.lubeAllPoints == '1') {
                                                            return (<><input type="checkbox" name="lubeallpoints" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="lubeallpoints" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="lubeallpoints" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Lube all points</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.lube5thWheel == '1') {
                                                            return (<><input type="checkbox" name="lube5thwheel" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="lube5thwheel" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="lube5thwheel" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Lube 5th wheel</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectSteeringColumn == '1') {
                                                            return (<><input type="checkbox" name="inspectsteeringcolumn" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectsteeringcolumn" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectsteeringcolumn" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect steering column <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectSteeringSystem == '1') {
                                                            return (<><input type="checkbox" name="inspectsteeringsystem" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectsteeringsystem" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectsteeringsystem" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect steering system <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectHubWheelSeals == '1') {
                                                            return (<><input type="checkbox" name="inspectwheelseals" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectwheelseals" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectwheelseals" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect hub and wheel seals</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectTransmissionLube == '1') {
                                                            return (<><input type="checkbox" name="inspecttransmissionlube" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspecttransmissionlube" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspecttransmissionlube" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect transmission lube</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectShaftJoints == '1') {
                                                            return (<><input type="checkbox" name="inspectjoints" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectjoints" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectjoints" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect shaft & joints</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectDifferentHousingLube == '1') {
                                                            return (<><input type="checkbox" name="inspecthousinglube" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspecthousinglube" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspecthousinglube" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect differential housing and lube</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectAllSealsForLeaks == '1') {
                                                            return (<><input type="checkbox" name="inspectallseals" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectallseals" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectallseals" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect all seals for leaks</span>
                                            </div>
                                        </div>
                                    </div>
    
    
                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Suspension</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.suspension != null){
                                                         if (inspectionDetails.tractorPmInspection.suspension.inspectFrame == '1') {
                                                            return (<><input type="checkbox" name="inspectframe" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectframe" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectframe" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect frame cross members <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.suspension != null){
                                                         if (inspectionDetails.tractorPmInspection.suspension.inspectSpringHangers == '1') {
                                                            return (<><input type="checkbox" name="inspectspringhangers" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectspringhangers" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectspringhangers" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect spring hangers</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.suspension != null){
                                                         if (inspectionDetails.tractorPmInspection.suspension.inspectSpringAssembly == '1') {
                                                            return (<><input type="checkbox" name="inspectspringassembly" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectspringassembly" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectspringassembly" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect spring assembly <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.suspension != null){
                                                         if (inspectionDetails.tractorPmInspection.suspension.inspectTorque == '1') {
                                                            return (<><input type="checkbox" name="inspecttorque" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspecttorque" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspecttorque" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect torque, radius or tracking components <span class="star">*</span></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Frame</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.frame != null){
                                                         if (inspectionDetails.tractorPmInspection.frame.inspectFrameMembers == '1') {
                                                            return (<><input type="checkbox" name="inspectframemembers" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectframemembers" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectframemembers" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect frame members <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.frame != null){
                                                         if (inspectionDetails.tractorPmInspection.frame.inspectTireClearance == '1') {
                                                            return (<><input type="checkbox" name="inspecttireclearance" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspecttireclearance" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspecttireclearance" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect tire and wheel clearance <span class="star">*</span></span>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Coupling Devices</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.couplingDevices != null){
                                                         if (inspectionDetails.tractorPmInspection.couplingDevices.inspectFifthWheel == '1') {
                                                            return (<><input type="checkbox" name="inspectfifthwheel" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectfifthwheel" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectfifthwheel" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect fifth wheel <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.couplingDevices != null){
                                                         if (inspectionDetails.tractorPmInspection.couplingDevices.inspectPintleHook == '1') {
                                                            return (<><input type="checkbox" name="inspectpintlehook" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectpintlehook" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectpintlehook" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect pintle hook <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.couplingDevices != null){
                                                         if (inspectionDetails.tractorPmInspection.couplingDevices.inspectSafetyDevices == '1') {
                                                            return (<><input type="checkbox" name="inspectsafetydevices" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectsafetydevices" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectsafetydevices" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect safety devices <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.couplingDevices != null){
                                                         if (inspectionDetails.tractorPmInspection.couplingDevices.inspectSaddleMounts == '1') {
                                                            return (<><input type="checkbox" name="inspectsaddlemounts" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectsaddlemounts" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectsaddlemounts" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect saddle mounts <span class="star">*</span></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Tires/Wheels</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                         if (inspectionDetails.tractorPmInspection.tiresOrWheels.inspectWheelsforCracks == '1') {
                                                            return (<><input type="checkbox" name="inspectwheelsforcracks" class="mr-2" defaultChecked={true}/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectwheelsforcracks" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectwheelsforcracks" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect wheels for cracks/welds <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                         if (inspectionDetails.tractorPmInspection.tiresOrWheels.inspectBrokenLug == '1') {
                                                            return (<><input type="checkbox" name="inspectbrokenlug" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectbrokenlug" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectbrokenlug" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect for loose/broken lug <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                         if (inspectionDetails.tractorPmInspection.tiresOrWheels.inspectValveStems == '1') {
                                                            return (<><input type="checkbox" name="inspectvalvestems" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectvalvestems" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectvalvestems" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect valve stems/caps <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex ">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                         if (inspectionDetails.tractorPmInspection.tiresOrWheels.checkTirePressure == '1') {
                                                            return (<><input type="checkbox" name="checktirepressure" class="mr-2" defaultChecked={true} style={{minHeight:20, maxHeight:20,}} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="checktirepressure" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="checktirepressure" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Check tire pressure<br/>-wide base tires @100PSI<br/>-steers @105PSI<br/>-duals @110PSI</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                         if (inspectionDetails.tractorPmInspection.tiresOrWheels.inspectSteeringAxle == '1') {
                                                            return (<><input type="checkbox" name="inspectsteeringaxle" class="mr-2" defaultChecked={true} style={{minHeight:20, maxHeight:20,}} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectsteeringaxle" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}}/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectsteeringaxle" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect tires on any steering axle of power unit <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                         if (inspectionDetails.tractorPmInspection.tiresOrWheels.inspectTires == '1') {
                                                            return (<><input type="checkbox" name="inspecttires" class="mr-2" defaultChecked={true}/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspecttires" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspecttires" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect all tires <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                         if (inspectionDetails.tractorPmInspection.tiresOrWheels.inspectTreadDepths == '1') {
                                                            return (<><input type="checkbox" name="inspecttreaddepths" class="mr-2" defaultChecked={true} onClick={treaddepths} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspecttreaddepths" class="mr-2" defaultChecked={false} onClick={treaddepths} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspecttreaddepths" class="mr-2" defaultChecked={false} onClick={treaddepths} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Measure all tread depths</span>
                                            </div>
                                        </div>
                                        <div class="table-3 mb-4 px-3">
                                        <table class="table rt-border-table" id="threaddepth_measures">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ borderRight: "1px solid #ccc" }}>Thread Depths Measurements</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr class="border-bottom">
                                                    <td>
                                                        <div class="row align-items-center justify-content-between px-3">
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                         <span class="mr-3 mm-60">RF</span>
                                                                        <div>
                                                                         {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthrf" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthRF}/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthrf" aria-describedby="emailHelp" style={{ width: "100px" }} /></>)
                                                                  }
                                                               }
                                                            })()}

                                                                          <div id="treaddepthrfValidMsg" class="error">Required Field</div>
                                                                        </div>
                                                                    </div>
                                                                    <span style={{margin:"10px 10px"}}>/32</span>
                                                                    
                                                                    <div>
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                    {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthrfpsi" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthRFPSI}/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthrfpsi" aria-describedby="emailHelp" style={{ width: "100px" }} /></>)
                                                                  }
                                                               }
                                                            })()}
                                                            <span style={{margin:"10px 10px"}}>PSI</span>
                                                                      </div>
                                                                    <div id="treaddepthrfpsiValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                   
                                                                   
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">LF</span>
                                                                    <div>
                                                                       {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthlf" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthLF}/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthlf" aria-describedby="emailHelp" style={{ width: "100px" }} /></>)
                                                                  }
                                                               }
                                                            })()}
                                                                      <div id="treaddepthlfValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>/32</span>
                                                                  <div>
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                       {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthlfpsi" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthLFPSI}/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthlfpsi" aria-describedby="emailHelp" style={{ width: "100px" }} /></>)
                                                                  }
                                                               }
                                                            })()}
                                                                      <span style={{margin:"10px 10px"}}>PSI</span>
                                                                    </div>
                                                                    <div id="treaddepthlfpsiValidMsg" class="error">Required Field</div>
                                                                  </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">RFR</span>
                                                                    <div>
                                                                       {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthrfr" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthRFR}/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthrfr" aria-describedby="emailHelp" style={{ width: "100px" }} /></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    <div id="treaddepthrfrValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                </div>
                                                                    <span style={{margin:"10px 10px"}}>/32</span>
                                                                <div>
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                        {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthrfrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthRFRPSI}/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthrfrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} /></>)
                                                                  }
                                                               }
                                                            })()}
                                                                      <span style={{margin:"10px 10px"}}>PSI</span>
                                                                    </div>
                                                                      <div id="treaddepthrfrpsiValidMsg" class="error">Required Field</div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">LFR</span>
                                                                    <div>
                                                                          {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthlfr" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthLFR}/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthlfr" aria-describedby="emailHelp" style={{ width: "100px" }} /></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    
                                                                    <div id="treaddepthlfrValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>/32</span>
                                                                  <div>
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                          {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthlfrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthLFRPSI}/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthlfrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} /></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    <span style={{margin:"10px 10px"}}>PSI</span>
                                                                    </div>
                                                                    <div id="treaddepthlfrpsiValidMsg" class="error">Required Field</div>
                                                                  </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">RRR</span>
                                                                    <div>
                                                                          {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthrrr" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthRRR}/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthrrr" aria-describedby="emailHelp" style={{ width: "100px" }} /></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    
                                                                    <div id="treaddepthrrrValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>/32</span>
                                                                  <div>
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                          {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthrrrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthRRRPSI}/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthrrrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} /></>)
                                                                  }
                                                               }
                                                            })()}
                                                                      <span style={{margin:"10px 10px"}}>PSI</span>
                                                                    </div>
                                                                    <div id="treaddepthrrrpsiValidMsg" class="error">Required Field</div>
                                                                  </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">LRR</span>
                                                                    <div>
                                                                          {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthlrr" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthLRR}/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthlrr" aria-describedby="emailHelp" style={{ width: "100px" }} /></>)
                                                                  }
                                                               }
                                                            })()}
                                                                  
                                                                    <div id="treaddepthlrrValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>/32</span>
                                                                  <div>
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                          {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthlrrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthLRRPSI}/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthlrrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} /></>)
                                                                  }
                                                               }
                                                            })()}
                                                                      <span style={{margin:"10px 10px"}}>PSI</span>
                                                                    </div>
                                                                    <div id="treaddepthlrrpsiValidMsg" class="error">Required Field</div>
                                                                  </div>
                                                                </div>

                                                            </div> 
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Electrical System</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if(inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.electricalSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.electricalSystem.inspectBatteryBox == '1') {
                                                            return (<><input type="checkbox" name="inspectbatterybox" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectbatterybox" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectbatterybox" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect battery box hold-downs</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.electricalSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.electricalSystem.inspectBatteryConnections == '1') {
                                                            return (<><input type="checkbox" name="inspectbatteryconnections" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectbatteryconnections" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectbatteryconnections" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect battery connections</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Service</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.service != null){
                                                         if (inspectionDetails.tractorPmInspection.service.changeOil == '1') {
                                                            return (<><input type="checkbox" name="changeoil" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="changeoil" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="changeoil" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Change oil</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.service != null){
                                                         if (inspectionDetails.tractorPmInspection.service.changeOilFilter == '1') {
                                                            return (<><input type="checkbox" name="changeoilfilter" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="changeoilfilter" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="changeoilfilter" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Change oil filter</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.service != null){
                                                         if (inspectionDetails.tractorPmInspection.service.changeFuelFilter == '1') {
                                                            return (<><input type="checkbox" name="changefuelfilter" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="changefuelfilter" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="changefuelfilter" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Change fuel filters as needed</span>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.service != null){
                                                         if (inspectionDetails.tractorPmInspection.service.checkAllFluidLevels == '1') {
                                                            return (<><input type="checkbox" name="checkfluidlevels" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="checkfluidlevels" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="checkfluidlevels" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Check all fluid levels</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Overall</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.overall != null){
                                                         if (inspectionDetails.tractorPmInspection.overall.inspectAllPlugs == '1') {
                                                            return (<><input type="checkbox" name="inspectallplugs" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectallplugs" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectallplugs" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect all plugs for tightness</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.overall != null){
                                                         if (inspectionDetails.tractorPmInspection.overall.changeServiceNumber == '1') {
                                                            return (<><input type="checkbox" name="changeservicenumber" class="mr-2" defaultChecked={true} /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="changeservicenumber" class="mr-2" defaultChecked={false} /></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="changeservicenumber" class="mr-2" defaultChecked={false} /></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Change service numbers</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Notes</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-8 mb-2">
                                            <div class="d-flex align-items-center">

                                            {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.notes != null){
                                                                     return (<><textarea class="form-control my-3" name="tractorpmNotes" id="tractorpmNotes" rows="3" maxLength="300" style={{minHeight:"180px"}}>{inspectionDetails.tractorPmInspection.notes}</textarea></>)
                                                                  } else {
                                                                     return (<><textarea class="form-control my-3" name="tractorpmNotes" id="tractorpmNotes" rows="3" maxLength="300" style={{minHeight:"180px"}}></textarea></>)
                                                                  }
                                                               }
                                                            })()}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="divider2"></div>
                        <div class="form-footer-comon-wrap">
                           <div class="row documents p-3">
                              <div class="col-lg-12">
                                 {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                 <div class="row">
                                    {
                                       documentList ? documentList.map((data, index) => (
                                          <div class="col-md-12" style={{marginTop:"-18px"}}>
                                             <div class="align-items-center">
                                                <img src="images/icons/pdf-icon.svg" alt="" />
                                                
                                                   {/* <b>{data.title}.{data.extension}</b> */}
                                                   <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                      <img src="" class="mr-2" />
                                                      <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                      <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100"> {(() => {
                                                            if (data.extension == 'pdf') {
                                                               return (
                                                                  <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                               )
                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                               return (
                                                                  <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                               )
                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                               return (
                                                                  <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                               )
                                                            } else {
                                                               return (
                                                                  <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                               )
                                                            }
                                                         })()}
                                                         {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                         {data.name ? data.name : "--"}  </a></p></span>
                                                      <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" /> </div>
                                               
                                                {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                             </div>
                                          </div>


                                       )) : ""

                                    }
                                 </div>
                              </div><br />

                           </div>




                        </div>
                        {/* <div class="divider2"></div> */}
                        <div class="row p-3">
                           <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                        </div>

                        <AddInspectionName setEndDate={setEndDate} endDate={endDate} />

                        {statusInspection == "DRAFT" ? "" : <div class="divider"></div>}
                        {(() => {
                           if (statusInspection == "DRAFT") {
                              <></>
                           } else {
                              if (!roles.match("Technician")) {
                                 if (inspectionDetails.status == 'approved' || inspectionDetails.status == 'approve' || inspectionDetails.status == 'APPROVED' || inspectionDetails.status == 'Rejected' || inspectionDetails.status == 'REJECTED') {
                                    return (
                                       <>
                                          <div class="result-didspos">
                                             <div class="p-4">
                                                <span>Result</span>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Inspection Failed</b>
                                                </div>
                                             </div>
                                             <div class="p-4">
                                                <span>Disposition of Tank</span>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : ""}
                                                   <b class="mb-0 ml-2">Tank Returned to Service</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                                </div>
                                             </div>
                                          </div>
                                          <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection} customerId={fkAccountId}/>
                                       </>
                                    )
                                 } else {
                                    return (
                                       <><div class="result-didspos ">
                                          <div class="p-4">
                                             <span>Result</span>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                             </div>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Inspection Failed</b>
                                             </div>
                                          </div>
                                          <div class="p-4">
                                             <span>Disposition of Tank</span>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Tank returned to service</b>
                                             </div>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                             </div>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                             </div>
                                          </div>
                                       </div>
                                       <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} customerId={fkAccountId}/>
                                          {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} customerId={fkAccountId}/> */}


                                       </>
                                    )
                                 }
                              }
                           }
                        })()}

                        <div class="divider2"></div>

                        <div class="row p-3 pr-4 d-flex justify-content-end" >
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
                           {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a> */}
                           {statusInspection == "DRAFT"?<button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("DRAFT")}>Save as Draft</button>:""}

                           {(() => {
                              if (statusInspection == "DRAFT") {
                                 return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("APPROVED PENDING")}>Save</button></>)
                              } else {
                                 if (roles.match("Manager") ||roles.match("Supervisor")|| roles.match("Owner") || roles.match("Inspector")) {
                                    return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("approved")}>Save</button></>)
                                 }
                                 else {
                                    return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("APPROVED PENDING")}>Save</button></>)
                                 }
                              }
                           })()}

                        </div>
                     </div>
                  </div>
               </div>

            </div>



         </div>
      </>
   )
};

TractorpmTestEdit.propTypes = {};

TractorpmTestEdit.defaultProps = {};

export default TractorpmTestEdit;
