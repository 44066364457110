import React, { history, useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const TractorpmTestRead = () => {


   const [fromDate, setFromDate] = useState();
   const { fkAccountId, inspectionId, vid } = useParams();
   const [inputList, setInputList] = useState([{}]);
   const [base64file, setBase64file] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const [fileName, setfileName] = useState("");
   const [inspectionDetails, setInspectionDetails] = useState("");
   const [documentList, setDocumentLists] = useState([]);
   const navigate = useNavigate();
   const [endDate, setEndDate] = useState(new Date());
   const [verifiedDATE, setverifiedDate] = useState(new Date());
   const [expiryDate, setexpiryDate] = useState(new Date());
   const [roles, setRoles] = useState("");
   const [statusInspection, setStatus] = useState("");
   useEffect(() => {
     setRoles(localStorage.getItem("Userroles"));
      // vehicle_details_api(id, setVehicleDetails, setCustomerId);
      getInpection(fkAccountId, inspectionId)
   }, []);

   function getInpection(fkAccountId, inspectionId) {
      var requestObjectForLeakage =
      {

         "id": inspectionId
      }

      var requestOptions = requestObject.RequestHeader(requestObjectForLeakage);

      services.getInspectionDetails(requestOptions).then((res) => {
         console.log("  " + JSON.stringify(res));
         setInspectionDetails(res.rows);
         // $('input[name="Pintle_repaired"][value="' + Pintle + '"]').trigger("click").prop('checked', true);
         setDocumentLists(res.rows.documentList?res.rows.documentList:"");
         $("#workOrderNumber").val(res.rows ? res.rows.workOrderNumber : "");
         // sessionStorage.setItem("verifiedBy",res.rows ? res.rows.verifiedBy : "");
         setEndDate(new Date(res.rows?res.rows.inspectedDate:""));
         setStatus(res.rows.status);
         if(res.rows)
         {
            if(res.rows.verifiedDate)
            {
               setverifiedDate(new Date(res.rows?res.rows.verifiedDate:''));
            }
     
            if(res.rows.expiryDate)
            {
               setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
            }

            if (res.rows.tractorPmInspection) 
            {
               var tractorpm = res.rows.tractorPmInspection;
               if (tractorpm.breakSystem.measureBreakLining == 1) 
               {
                  $('#break_measures').show();
               }
               else {
                  $('#breaklininglf').val('');
                  $('#breakliningrf').val('');
                  $('#breaklininglfr').val('');
                  $('#breakliningrfr').val('');
                  $('#breaklininglrr').val('');
                  $('#breakliningrrr').val('');
                  $('#break_measures').hide();
               }

               if (tractorpm.tiresOrWheels.inspectTreadDepths == 1) 
               {
                  $('#threaddepth_measures').show();
               } else {
                  $('#treaddepthrf').val('');
                  $('#treaddepthrfpsi').val('');
                  $('#treaddepthlf').val('');
                  $('#treaddepthlfpsi').val('');
                  $('#treaddepthrfr').val('');
                  $('#treaddepthrfrpsi').val('');
                  $('#treaddepthlfr').val('');
                  $('#treaddepthlfrpsi').val('');
                  $('#treaddepthrrr').val('');
                  $('#treaddepthrrrpsi').val('');
                  $('#treaddepthlrr').val('');
                  $('#treaddepthlrrpsi').val('');
                  $('#threaddepth_measures').hide();
               }
            }
         }
      });

     // setEndDate(inspectionDetails.verifiedDate);
   }

   const redirect=(page)=>
   {

      navigate(page);

   }

   const save=(status)=>
   {

   // console.log("results=="+JSON.stringify(inputList));
   if($("#inspectedBy").val()==="")
   {
     Swal.fire({
         position: 'center',
         icon: 'error',
         title: "",
         text:"Please enter Inspected by",
         showConfirmButton: true

       }).then((result) =>
       {

             if(result.isConfirmed)
             {


             }
       });
       return false;
   }
   if( $('input:radio[name="returnedservice"]:checked').val()==="undefined"||$('input:radio[name="returnedservice"]:checked').val()===undefined)
   {
     Swal.fire({
         position: 'center',
         icon: 'error',
         title: "",
         text:"Please select disposition of tank",
         showConfirmButton: true

       }).then((result) =>
       {

             if(result.isConfirmed)
             {


             }
       });
       return false;
   }
   if( $('input:radio[name="approvedCertify"]:checked').val()===""|| $('input:radio[name="approvedCertify"]:checked').val()===undefined)
   {
     Swal.fire({
         position: 'center',
         icon: 'error',
         title: "",
         text:"Please select inspection result",
         showConfirmButton: true

       }).then((result) =>
       {

             if(result.isConfirmed)
             {


             }
       });
       return false;
   }
   if($("input[name='approvedCertify']:checked").val()==="approvedCertify")
   {

   }else{

    status="Rejected";
   }
         var requestObjectInspection=
         {
           "id": inspectionId,
           "expiryDate": $("#expiryDate").val(),
           "verifiedBy": $("#verifiedBy").val(),
           "verifiedDate": $("#verifiedByBate").val(),
           "status": status,

           "result": {
            "approvedCertify":$("input[name='approvedCertify']:checked").val()==="approvedCertify"?"1":"0",
            "inspectionFailed": $("input[name='approvedCertify']:checked").val()==="inspectionFailed"?"1":"0"
          },
          "dispositionOfVehicle": {
            "vehicleReturnedServices":$("input[name='returnedservice']:checked").val()==="returnedservice"?"1":"0",
            "vehicleWithdrawnFromService":$("input[name='returnedservice']:checked").val()==="withdrawnservice"?"1":"0",
            "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val()==="repairfacility"?"1":"0"
          }


       };

       $("#loadingforsubmit").show();
      //  setLoading(true);
       // const requestOptions =
       // {
       //   method: 'POST',
       //   headers: { 'Content-Type': 'application/json'
       //             },

       //       body: JSON.stringify(requestObject)
       // };
       var requestOptions=requestObject.RequestHeader(requestObjectInspection);
       services.certifyInspection(requestOptions).then((res) =>
       {
         $("#loadingforsubmit").hide();
         Swal.fire({
           position: 'center',
           icon: 'success',
           title: "",
           text:res.message,
           showConfirmButton: true

         }).then((result) =>
         {

               if(result.isConfirmed)
               {
                 navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));

               }
         });
       });

   }


   return (
      <>
      {LoadingComponentForSubmit}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block" }}>
            <div class="row mb-0">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <div class="d-flex justify-content-between align-items-center my-4">
                     <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                     </div>
                  </div>
               </div>
               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                  <HeaderBlock cids={fkAccountId} />
                  </div>
               </div>

               

               <div class="row mb-2">
               <div class="col-lg-11 col-md-12 m-auto ">
                     <div class="card">

                       <div class="row mb-2">
                              <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                                    <VehicleBasicInfo customerId={vid} />
                              </div>
                        </div>
                        <div class="divider"></div>

                        <div class="row p-3">
                           <div class="col-lg-12">
                              <h4 class="mb-4 form-inspection-title text-center">Tractor Preventive Maintenance/Inspection</h4>
                              {/*<p id="leakage-check-type-message" class="error"></p>
                              <p id="unitDesignPressure-message" class="error"></p>
                              <p id="testPressure-message" class="error"></p>
   <p id="error_message" class="center error" ></p> */}
                           </div>
                           <div class="col-lg-12"><WorkOrderNumberDiv /></div><br />
                           
                            <div class="px-3">
                                <div class="inspection-wrap">
                                  <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Lighting</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mb-2">
                                            <div class="d-flex align-items-center">
                                             
                                             {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.lighting != null){
                                                         if (inspectionDetails.tractorPmInspection.lighting.allLightingDevices == '1') {
                                                            return (<><input type="checkbox" name="lightingdevices" class="mr-2" defaultChecked={true} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="lightingdevices" class="mr-2" defaultChecked={false} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="lightingdevices" class="mr-2" defaultChecked={false} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>All lighting devices and reflectors shall be operable</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Windshield</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.windShield != null){
                                                         if (inspectionDetails.tractorPmInspection.windShield.inspectWindShield == '1') {
                                                            return (<><input type="checkbox" name="inspectwindshield" class="mr-2" defaultChecked={true} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectwindshield" class="mr-2" defaultChecked={false} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectwindshield" class="mr-2" defaultChecked={false} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Inspect windshield (no crack, discoloration or vision reducing matter allowed)</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.windShield != null){
                                                         if (inspectionDetails.tractorPmInspection.windShield.inspectWiper == '1') {
                                                            return (<><input type="checkbox" name="inspectwiper" class="mr-2" defaultChecked={true} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectwiper" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         }
                                                      }
                                                      else {
                                                         return (<><input type="checkbox" name="inspectwiper" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Inspect wipers, replace as needed</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.windShield != null){
                                                         if (inspectionDetails.tractorPmInspection.windShield.inspectWasherFluid == '1') {
                                                            return (<><input type="checkbox" name="washerfluid" class="mr-2" defaultChecked={true} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="washerfluid" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         }
                                                      }
                                                      else{
                                                         return (<><input type="checkbox" name="washerfluid" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Check washer fluid level</span>
                                            </div>
                                        </div> 
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>General Inspection</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.generalInspection != null){
                                                         if (inspectionDetails.tractorPmInspection.generalInspection.inspectBumpers == '1') {
                                                            return (<><input type="checkbox" name="inspectbumper" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectbumper" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                            return (<><input type="checkbox" name="inspectbumper" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                   }
                                                   
                                                })()}
                                                <span>Inspect grille, bumpers, doors and door glass</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.generalInspection != null){
                                                         if (inspectionDetails.tractorPmInspection.generalInspection.inspectSteps == '1') {
                                                            return (<><input type="checkbox" name="inspectsteps" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectsteps" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                            return (<><input type="checkbox" name="inspectsteps" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                   }
                                                   
                                                })()}
                                                <span>Inspect steps</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.generalInspection != null){
                                                         if (inspectionDetails.tractorPmInspection.generalInspection.inspectMirrors == '1') {
                                                            return (<><input type="checkbox" name="inspectmirrors" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectmirrors" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                            return (<><input type="checkbox" name="inspectmirrors" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                   }
                                                   
                                                })()}
                                                <span>Inspect mirrors and brackets</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.generalInspection != null){
                                                         if (inspectionDetails.tractorPmInspection.generalInspection.inspectFuelTank == '1') {
                                                            return (<><input type="checkbox" name="inspectfueltank" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectfueltank" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                            return (<><input type="checkbox" name="inspectfueltank" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                   }
                                                   
                                                })()}
                                                <span>Inspect fuel tank</span>
                                            </div>
                                        </div>
                                    </div> 

                        

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>In-cab</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.inCab != null){
                                                         if (inspectionDetails.tractorPmInspection.inCab.inspectEmergencyEquip == '1') {
                                                            return (<><input type="checkbox" name="inspectemergencyequip" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectemergencyequip" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                            return (<><input type="checkbox" name="inspectemergencyequip" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                   }
                                                })()}
                                                <span>Inspect all emergency equipment</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.inCab != null){
                                                         if (inspectionDetails.tractorPmInspection.inCab.inspectWarnLight == '1') {
                                                            return (<><input type="checkbox" name="inspectwarnlight" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectwarnlight" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectwarnlight" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect warn lights/buzzer</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.inCab != null){
                                                         if (inspectionDetails.tractorPmInspection.inCab.inspectABSLight == '1') {
                                                            return (<><input type="checkbox" name="inspectabslight" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectabslight" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectabslight" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect ABS dash light self-test</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.inCab != null){
                                                         if (inspectionDetails.tractorPmInspection.inCab.inspectHorns == '1') {
                                                            return (<><input type="checkbox" name="inspecthorns" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspecthorns" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspecthorns" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect all horns</span>
                                            </div>
                                        </div>
                                    </div> 

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Brake System</h5>
                                            </div>
                                        </div>
                                       <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.breakSystem.measureBreakLining == '1') {
                                                            return (<><input type="checkbox" name="measurebreaklining" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="measurebreaklining" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="measurebreaklining" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Measure and record brake lining</span>
                                            </div>
                                        </div>
                                       <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.breakSystem.inspectBreaks == '1') {
                                                            return (<><input type="checkbox" name="inspectbreaks" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectbreaks" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectbreaks" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect/Service brakes</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.breakSystem.inspectParkingBreak == '1') {
                                                            return (<><input type="checkbox" name="inspectparkingbreak" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectparkingbreak" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectparkingbreak" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect Parking brake system</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.breakSystem.inspectBreakDrum == '1') {
                                                            return (<><input type="checkbox" name="inspectbreakdrum" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectbreakdrum" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectbreakdrum" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect brake drums/rotors</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.breakSystem.inspectBreakHoses == '1') {
                                                            return (<><input type="checkbox" name="inspectbreakhoses" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectbreakhoses" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectbreakhoses" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect brake hoses</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.breakSystem.inspectBreakTubing == '1') {
                                                            return (<><input type="checkbox" name="inspectbreaktubing" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectbreaktubing" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectbreaktubing" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect brake tubing</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.breakSystem.inspectAirCompressor == '1') {
                                                            return (<><input type="checkbox" name="inspectaircompressor" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectaircompressor" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectaircompressor" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect air compressor</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.breakSystem.inspectABSSystem == '1') {
                                                            return (<><input type="checkbox" name="inspectabssystem" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectabssystem" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectabssystem" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect ABS system</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.breakSystem.inspectSlackaAjuster == '1') {
                                                            return (<><input type="checkbox" name="inspectslackadjuster" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectslackadjuster" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectslackadjuster" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect slack adjuster operation</span>
                                            </div>
                                        </div> 
                                        <div class="table-3 mb-4 px-3" id="break_measures">
                                        <table class="table rt-border-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ borderRight: "1px solid #ccc" }}>Break Lining Measurements</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr class="border-bottom">
                                                    <td>
                                                        <div class="row align-items-center justify-content-between px-2">
                                                           <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">LF</span>
                                                                    <div>
                                                                    {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                                     return (<><input type="input" class="form-control" id="breaklininglf" aria-describedby="emailHelp" style={{ width: "100px" }} value={inspectionDetails.tractorPmInspection.breakSystem.breakLF} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="breaklininglf" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    </div>
                                                                </div>
                                                                <span style={{margin:"10px 10px"}}>%</span>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">RF</span>
                                                                    <div>
                                                                       {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                                     return (<><input type="input" class="form-control" id="breakliningrf" aria-describedby="emailHelp" style={{ width: "100px" }} value={inspectionDetails.tractorPmInspection.breakSystem.breakRF} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="breakliningrf" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    </div>
                                                                  </div>
                                                                  <span style={{margin:"10px 10px"}}>%</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">LFR</span>
                                                                    <div>
                                                                          {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                                     return (<><input type="input" class="form-control" id="breaklininglfr" aria-describedby="emailHelp" style={{ width: "100px" }} value={inspectionDetails.tractorPmInspection.breakSystem.breakLFR} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="breaklininglfr" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    </div>
                                                                  </div>
                                                                  <span style={{margin:"10px 10px"}}>%</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">RFR</span>
                                                                    <div>
                                                                             {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                                     return (<><input type="input" class="form-control" id="breakliningrfr" aria-describedby="emailHelp" style={{ width: "100px" }} value={inspectionDetails.tractorPmInspection.breakSystem.breakRFR} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="breakliningrfr" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>%</span>
                                                                </div>

                                                            </div>
                                                           <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">LRR</span>
                                                                  <div>
                                                                              {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                                     return (<><input type="input" class="form-control" id="breaklininglrr" aria-describedby="emailHelp" style={{ width: "100px" }} value={inspectionDetails.tractorPmInspection.breakSystem.breakLRR} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="breaklininglrr" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>%</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60" >RRR</span>
                                                                    <div>
                                                                                {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.breakSystem != null){
                                                                     return (<><input type="input" class="form-control" id="breakliningrrr" aria-describedby="emailHelp" style={{ width: "100px" }} value={inspectionDetails.tractorPmInspection.breakSystem.breakRRR} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="breakliningrrr" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>%</span>
                                                                    
                                                                </div>

                                                            </div> 
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Engine</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.engine != null){
                                                         if (inspectionDetails.tractorPmInspection.engine.inspectHoses == '1') {
                                                            return (<><input type="checkbox" name="inspecthoses" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspecthoses" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspecthoses" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect hoses/clamps</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.engine != null){
                                                         if (inspectionDetails.tractorPmInspection.engine.inspectBelt == '1') {
                                                            return (<><input type="checkbox" name="inspectbelt" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectbelt" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectbelt" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect belts</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Fuel System</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.fuelSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.fuelSystem.inspectLeaks == '1') {
                                                            return (<><input type="checkbox" name="inspectleaks" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectleaks" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectleaks" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect for leaks</span>
                                            </div>
                                        </div>
                                    </div> 

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-2">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Exhaust System</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-start">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.exhaustSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.exhaustSystem.inspectExhaustLeaks == '1') {
                                                            return (<><input type="checkbox" name="inspectexhaustleaks" class="mr-2" defaultChecked={true} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectexhaustleaks" class="mr-2" defaultChecked={false} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectexhaustleaks" class="mr-2" defaultChecked={false} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect exhaust for leaks (no leak allowed below driver compartment)</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-8 mb-2">
                                            <div class="d-flex align-items-start">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.exhaustSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.exhaustSystem.inspectNoBurning == '1') {
                                                            return (<><input type="checkbox" name="inspectnoburning" class="mr-2" defaultChecked={true} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectnoburning" class="mr-2" defaultChecked={false} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectnoburning" class="mr-2" defaultChecked={false} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect to ensure no part of system located to cause burning, charring or damage to wiring, fuel supply or any combustible part of vehicle</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Chassis</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectSteering == '1') {
                                                            return (<><input type="checkbox" name="inspectsteering" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectsteering" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectsteering" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect steering mechanism</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectSteeringWheel == '1') {
                                                            return (<><input type="checkbox" name="inspectsteeringwheel" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectsteeringwheel" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectsteeringwheel" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect steering wheel free play</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectEngineAirFilters == '1') {
                                                            return (<><input type="checkbox" name="inspectengineairfilter" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectengineairfilter" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectengineairfilter" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect engine air filters</span>
                                            </div>
                                        </div><div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectFreshAirFilter == '1') {
                                                            return (<><input type="checkbox" name="inspectfreshairfilter" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectfreshairfilter" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      }
                                                      else {
                                                         return (<><input type="checkbox" name="inspectfreshairfilter" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect fresh air filters</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.lubeAllPoints == '1') {
                                                            return (<><input type="checkbox" name="lubeallpoints" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="lubeallpoints" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="lubeallpoints" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Lube all points</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.lube5thWheel == '1') {
                                                            return (<><input type="checkbox" name="lube5thwheel" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="lube5thwheel" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="lube5thwheel" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Lube 5th wheel</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectSteeringColumn == '1') {
                                                            return (<><input type="checkbox" name="inspectsteeringcolumn" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectsteeringcolumn" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectsteeringcolumn" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect steering column</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectSteeringSystem == '1') {
                                                            return (<><input type="checkbox" name="inspectsteeringsystem" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectsteeringsystem" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectsteeringsystem" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect steering system</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectHubWheelSeals == '1') {
                                                            return (<><input type="checkbox" name="inspectwheelseals" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectwheelseals" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectwheelseals" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect hub and wheel seals</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectTransmissionLube == '1') {
                                                            return (<><input type="checkbox" name="inspecttransmissionlube" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspecttransmissionlube" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspecttransmissionlube" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect transmission lube</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectShaftJoints == '1') {
                                                            return (<><input type="checkbox" name="inspectjoints" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectjoints" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectjoints" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect shaft & joints</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectDifferentHousingLube == '1') {
                                                            return (<><input type="checkbox" name="inspecthousinglube" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspecthousinglube" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspecthousinglube" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect differential housing and lube</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.chassis != null){
                                                         if (inspectionDetails.tractorPmInspection.chassis.inspectAllSealsForLeaks == '1') {
                                                            return (<><input type="checkbox" name="inspectallseals" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectallseals" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectallseals" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect all seals for leaks</span>
                                            </div>
                                        </div>
                                    </div>
    
    
                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Suspension</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.suspension != null){
                                                         if (inspectionDetails.tractorPmInspection.suspension.inspectFrame == '1') {
                                                            return (<><input type="checkbox" name="inspectframe" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectframe" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectframe" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect frame cross members</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.suspension != null){
                                                         if (inspectionDetails.tractorPmInspection.suspension.inspectSpringHangers == '1') {
                                                            return (<><input type="checkbox" name="inspectspringhangers" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectspringhangers" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectspringhangers" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect spring hangers</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.suspension != null){
                                                         if (inspectionDetails.tractorPmInspection.suspension.inspectSpringAssembly == '1') {
                                                            return (<><input type="checkbox" name="inspectspringassembly" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectspringassembly" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectspringassembly" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect spring assembly</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.suspension != null){
                                                         if (inspectionDetails.tractorPmInspection.suspension.inspectTorque == '1') {
                                                            return (<><input type="checkbox" name="inspecttorque" class="mr-2" defaultChecked={true} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspecttorque" class="mr-2" defaultChecked={false} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspecttorque" class="mr-2" defaultChecked={false} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect torque, radius or tracking components</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Frame</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.frame != null){
                                                         if (inspectionDetails.tractorPmInspection.frame.inspectFrameMembers == '1') {
                                                            return (<><input type="checkbox" name="inspectframemembers" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectframemembers" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectframemembers" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect frame members</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.frame != null){
                                                         if (inspectionDetails.tractorPmInspection.frame.inspectTireClearance == '1') {
                                                            return (<><input type="checkbox" name="inspecttireclearance" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspecttireclearance" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspecttireclearance" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect tire and wheel clearance</span>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Coupling Devices</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.couplingDevices != null){
                                                         if (inspectionDetails.tractorPmInspection.couplingDevices.inspectFifthWheel == '1') {
                                                            return (<><input type="checkbox" name="inspectfifthwheel" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectfifthwheel" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectfifthwheel" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect fifth wheel</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.couplingDevices != null){
                                                         if (inspectionDetails.tractorPmInspection.couplingDevices.inspectPintleHook == '1') {
                                                            return (<><input type="checkbox" name="inspectpintlehook" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectpintlehook" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectpintlehook" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect pintle hook</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.couplingDevices != null){
                                                         if (inspectionDetails.tractorPmInspection.couplingDevices.inspectSafetyDevices == '1') {
                                                            return (<><input type="checkbox" name="inspectsafetydevices" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectsafetydevices" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectsafetydevices" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect safety devices</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.couplingDevices != null){
                                                         if (inspectionDetails.tractorPmInspection.couplingDevices.inspectSaddleMounts == '1') {
                                                            return (<><input type="checkbox" name="inspectsaddlemounts" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectsaddlemounts" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectsaddlemounts" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect saddle mounts</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Tires/Wheels</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                         if (inspectionDetails.tractorPmInspection.tiresOrWheels.inspectWheelsforCracks == '1') {
                                                            return (<><input type="checkbox" name="inspectwheelsforcracks" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectwheelsforcracks" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectwheelsforcracks" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect wheels for cracks/welds</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                         if (inspectionDetails.tractorPmInspection.tiresOrWheels.inspectBrokenLug == '1') {
                                                            return (<><input type="checkbox" name="inspectbrokenlug" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectbrokenlug" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectbrokenlug" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect for loose/broken lug</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                         if (inspectionDetails.tractorPmInspection.tiresOrWheels.inspectValveStems == '1') {
                                                            return (<><input type="checkbox" name="inspectvalvestems" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectvalvestems" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectvalvestems" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect valve stems/caps</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                         if (inspectionDetails.tractorPmInspection.tiresOrWheels.checkTirePressure == '1') {
                                                            return (<><input type="checkbox" name="checktirepressure" class="mr-2" defaultChecked={true} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="checktirepressure" class="mr-2" defaultChecked={false} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="checktirepressure" class="mr-2" defaultChecked={false} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Check tire pressure<br/>-wide base tires @100PSI<br/>-steers @105PSI<br/>-duals @110PSI</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                         if (inspectionDetails.tractorPmInspection.tiresOrWheels.inspectSteeringAxle == '1') {
                                                            return (<><input type="checkbox" name="inspectsteeringaxle" class="mr-2" defaultChecked={true} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectsteeringaxle" class="mr-2" defaultChecked={false} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectsteeringaxle" class="mr-2" defaultChecked={false} disabled style={{minHeight:20, maxHeight:20,}}/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect tires on any steering axle of power unit</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                         if (inspectionDetails.tractorPmInspection.tiresOrWheels.inspectTires == '1') {
                                                            return (<><input type="checkbox" name="inspecttires" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspecttires" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspecttires" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect all tires</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                         if (inspectionDetails.tractorPmInspection.tiresOrWheels.inspectTreadDepths == '1') {
                                                            return (<><input type="checkbox" name="inspecttreaddepths" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspecttreaddepths" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspecttreaddepths" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Measure all tread depths</span>
                                            </div>
                                        </div>
                                        <div class="table-3 mb-4 px-3" id="threaddepth_measures">
                                        <table class="table rt-border-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ borderRight: "1px solid #ccc" }}>Thread Depths Measurements</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr class="border-bottom">
                                                    <td>
                                                        <div class="row align-items-center justify-content-between px-3">
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                         <span class="mr-3 mm-60">RF</span>
                                                                        <div>
                                                                         {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthrf" aria-describedby="emailHelp" style={{ width: "100px" }} value={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthRF} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthrf" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                                        </div>
                                                                    </div>
                                                                    <span style={{margin:"10px 10px"}}>/32</span>
                                                                    
                                                                    <div>
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                    {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthrfpsi" aria-describedby="emailHelp" style={{ width: "100px" }} value={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthRFPSI} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthrfpsi" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                            <span style={{margin:"10px 10px"}}>PSI</span>
                                                                      </div>
                                                                    </div>
                                                                   
                                                                   
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">LF</span>
                                                                    <div>
                                                                       {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthlf" aria-describedby="emailHelp" style={{ width: "100px" }} value={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthLF} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthlf" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>/32</span>
                                                                  <div>
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                       {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthlfpsi" aria-describedby="emailHelp" style={{ width: "100px" }} value={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthLFPSI} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthlfpsi" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                                      <span style={{margin:"10px 10px"}}>PSI</span>
                                                                    </div>
                                                                    
                                                                  </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">RFR</span>
                                                                    <div>
                                                                       {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthrfr" aria-describedby="emailHelp" style={{ width: "100px" }} value={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthRFR} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthrfr" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    </div>
                                                                </div>
                                                                    <span style={{margin:"10px 10px"}}>/32</span>
                                                                <div>
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                        {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthrfrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} value={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthRFRPSI} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthrfrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                                      <span style={{margin:"10px 10px"}}>PSI</span>
                                                                    </div>
                                                                      
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">LFR</span>
                                                                    <div>
                                                                          {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthlfr" aria-describedby="emailHelp" style={{ width: "100px" }} value={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthLFR} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthlfr" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>/32</span>
                                                                  <div>
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                          {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthlfrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} value={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthLFRPSI} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthlfrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    <span style={{margin:"10px 10px"}}>PSI</span>
                                                                    </div>
                                                                    
                                                                  </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">RRR</span>
                                                                    <div>
                                                                          {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthrrr" aria-describedby="emailHelp" style={{ width: "100px" }} value={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthRRR} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthrrr" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>/32</span>
                                                                  <div>
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                          {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthrrrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} value={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthRRRPSI} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthrrrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                                      <span style={{margin:"10px 10px"}}>PSI</span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">LRR</span>
                                                                    <div>
                                                                          {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthlrr" aria-describedby="emailHelp" style={{ width: "100px" }} value={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthLRR} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthlrr" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>/32</span>
                                                                  <div>
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                          {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.tiresOrWheels != null){
                                                                     return (<><input type="input" class="form-control" id="treaddepthlrrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} value={inspectionDetails.tractorPmInspection.tiresOrWheels.treadDepthLRRPSI} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="treaddepthlrrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                                      <span style={{margin:"10px 10px"}}>PSI</span>
                                                                    </div>
                                                                  </div>
                                                                </div>

                                                            </div> 
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Electrical System</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if(inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.electricalSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.electricalSystem.inspectBatteryBox == '1') {
                                                            return (<><input type="checkbox" name="inspectbatterybox" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectbatterybox" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectbatterybox" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect battery box hold-downs</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.electricalSystem != null){
                                                         if (inspectionDetails.tractorPmInspection.electricalSystem.inspectBatteryConnections == '1') {
                                                            return (<><input type="checkbox" name="inspectbatteryconnections" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectbatteryconnections" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectbatteryconnections" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect battery connections</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Service</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.service != null){
                                                         if (inspectionDetails.tractorPmInspection.service.changeOil == '1') {
                                                            return (<><input type="checkbox" name="changeoil" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="changeoil" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="changeoil" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Change oil</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.service != null){
                                                         if (inspectionDetails.tractorPmInspection.service.changeOilFilter == '1') {
                                                            return (<><input type="checkbox" name="changeoilfilter" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="changeoilfilter" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="changeoilfilter" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Change oil filter</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.service != null){
                                                         if (inspectionDetails.tractorPmInspection.service.changeFuelFilter == '1') {
                                                            return (<><input type="checkbox" name="changefuelfilter" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="changefuelfilter" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="changefuelfilter" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Change fuel filters as needed</span>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.service != null){
                                                         if (inspectionDetails.tractorPmInspection.service.checkAllFluidLevels == '1') {
                                                            return (<><input type="checkbox" name="checkfluidlevels" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="checkfluidlevels" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="checkfluidlevels" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Check all fluid levels</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Overall</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.overall != null){
                                                         if (inspectionDetails.tractorPmInspection.overall.inspectAllPlugs == '1') {
                                                            return (<><input type="checkbox" name="inspectallplugs" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectallplugs" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectallplugs" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect all plugs for tightness</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.tractorPmInspection) {
                                                      if(inspectionDetails.tractorPmInspection.overall != null){
                                                         if (inspectionDetails.tractorPmInspection.overall.changeServiceNumber == '1') {
                                                            return (<><input type="checkbox" name="changeservicenumber" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="changeservicenumber" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="changeservicenumber" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Change service numbers</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Notes</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                               if (inspectionDetails.tractorPmInspection) {
                                                                  if(inspectionDetails.tractorPmInspection.notes != null){
                                                                     return (<><textarea class="form-control my-3" name="tractorpmNotes" id="tractorpmNotes" rows="3" maxLength="300" style={{minHeight:"180px"}} disabled>{inspectionDetails.tractorPmInspection.notes}</textarea></>)
                                                                  } else {
                                                                     return (<><textarea class="form-control my-3" name="tractorpmNotes" id="tractorpmNotes" rows="3" maxLength="300" style={{minHeight:"180px"}} disabled></textarea></>)
                                                                  }
                                                               }
                                                            })()}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="divider2"></div>
                        <div class="form-footer-comon-wrap">
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                    {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                    <div class="row">
                                        {
                                            documentList ? documentList.map((data, index) => (
                                                <div class="col-md-12">
                                                    <div class="d-flex align-items-center">
                                                        <img src="images/icons/pdf-icon.svg" alt="" />
                                                        <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100">
                                                            {/* <b>{data.title}.{data.extension}</b> */}
                                                                <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                    <img src="" class="mr-2" />
                                                                    <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                        {(() => {
                                                                            if (data.extension == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                        {data.name ? data.name : "--"} </p></span>
                                                                    {/* <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" />  */}
                                                                    </div>
                                                        </a>
                                                        {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                                    </div>
                                                </div>


                                            )) : ""

                                        }
                                    </div>
                                </div><br />

                                </div>
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                      <h6 class="mb-4">Additional notes: </h6>
                                      {inspectionDetails?inspectionDetails.notes:''}
                                   </div>
                                   </div>
                                <div class="divider2"></div>
                                <div class="row performby p-4">
                                   <div class="col-lg-12">
                                      <div class="row">
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Test Performed By:</label>
                                               <b>{inspectionDetails.inspectedBy}</b>
                                            </div>
                                         </div>
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Date</label>
                                               <b>{inspectionDetails.inspectedDate}</b>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>

                                <div class="divider"></div>
                                {(() => {
                                   if(!roles.match("Technician")){
        if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED') {
          return (
            <>
            <div class="result-didspos">
                                   <div class="p-4">
                                      <span>Result</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Inspection Failed</b>
                                      </div>
                                   </div>
                                   <div class="p-4">
                                      <span>Disposition of Tank</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:""}
                                         <b class="mb-0 ml-2">Tank Returned to Service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                      </div>
                                   </div>
                                </div>
                                <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection}  customerId={fkAccountId}/>
                                {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} status={"readOnly"} /> */}
            </>
          )
        } else {
          return (
            <><div class="result-didspos ">
            <div class="p-4">
               <span>Result</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Approve &amp; Certify</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Inspection Failed</b>
               </div>
            </div>
            <div class="p-4">
               <span>Disposition of Tank</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"/>:""}
                  <b class="mb-0 ml-2">Tank returned to service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank withdrawn from service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank sent to another repair facility</b>
               </div>
            </div>
         </div>
         <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate}   customerId={fkAccountId}/>
         {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} /> */}
         {/* <div class="divider"></div>
         <div class="row verifyby p-4">
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Verified By</label>
                  <input type="text" class="form-control custome-input" id="verifiedBy" value={inspectionDetails.verifiedBy} aria-describedby="emailHelp" placeholder="Enter Name"/>
               </div>
            </div>
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Date</label>
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="verifiedByBate" />

               </div>
            </div>
         </div> */}

         </>
          )
        }
      }
      })()}
                             </div>

                        <div class="divider"></div>
                        {(() =>
                            {
                                 if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED')
                                 {
                                 }
                                 else
                                 {
                                   return(<> <div class="row p-3 pr-4 d-flex justify-content-end">
                                   <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-secondary mr-1 ">Cancel</a>
                                       {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a>
                                        */}
                                        {roles.match("Technician")?"":<a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a>}
                                   {roles.match("Technician")?<a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-primary mr-1">Back</a>:""}
                                   </div></>)
                                 }
                           })()}
                     </div>
                  </div>
               </div>

            </div>



         </div>
      </>
   )
};

TractorpmTestRead.propTypes = {};

TractorpmTestRead.defaultProps = {};

export default TractorpmTestRead;
