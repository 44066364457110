import React, { history, useEffect, useState, useMemo, useCallback } from "react";
import $, { parseJSON } from 'jquery';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import services from "../../../../services/services";
import "cropperjs/dist/cropper.css";
import Getstates from "../../../../utility/getstates";
import getCroppedImg from "../../../../utility/cropImage";
import { useLoading } from 'react-hook-loading';
import Swal from "sweetalert2";
import GetTruckType from "../../../../utility/getTruckTypes";
import { requestObject } from "../../../../utility/requestObject";
import Cropper from "react-cropper";
import UsNumberValidate from "../../../../utility/usNumberValidate";
import UploadImage from "../../../../utility/uploadImage";
import AdddocsDispatcher from "../../../../utility/AdddocsDispatcher";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const FleetAddDispatcher = () => {

    // const { id } = useParams();
    const navigate = useNavigate();

    const dummyImge = window.location.origin + "/images/avatar.png";
    const [imgsrc, setImageSrc] = useState()
    const [image, setImage] = useState()
    const [states, setState] = useState([])
    const [roles, setListRoles] = useState([])
    const [cropper, setCropper] = useState()
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [rotation, setRotation] = useState(0)
    const [cropData, setCropData] = useState("")
    const [itemName, setFieldName] = useState('')
    const [loading, setLoading] = useLoading("Please wait...");
    const [croppedImage, setCroppedImage] = useState(null);

    const [inputList, setInputList] = useState([{}]);
    const [base64file, setBase64file] = useState('');
    const [fileName, setfileName] = useState("");

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onKeyup"
    });

    $(document).ready(function () {

        $('#email').keypress(function( e ) {
            if(e.which === 32){
                return false;
            }
        });

        $("#deskNumber,#ext,#zipcode").keypress(function (e) {
            if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
                //   $("#errmsg").html("Digits Only").show().fadeOut("slow");
                return false;
            }
        });
    });

    useEffect(() => {
        sessionStorage.setItem("naviationfrom", "staff");
        Getstates(setState);
        listRoles();
        $('#mobileNumber').usPhoneFormat();
        $('#deskNumber').usPhoneFormat();
    }, []);

    const listRoles = () => {
        var listRolesReq =
        {

        };

        var requestOptions = requestObject.RequestHeader(listRolesReq);
        services.listRoles(requestOptions).then((res) => {
            console.log("@@@@@listRoles list@@@@@@" + res.rows);
            setListRoles(res.rows);
        });
    }

    //   const onChange = (e) => {
    //     e.preventDefault();
    //     let files;
    //     if (e.dataTransfer) {
    //       files = e.dataTransfer.files;
    //     } else if (e.target) {
    //       files = e.target.files;
    //     }
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //       setImage(reader.result);
    //     };
    //     reader.readAsDataURL(files[0]);
    //   };
    const onChange = (e) => {
        const MIN_FILE_SIZE = 5120 // 5MB
        const fileSizeKiloBytes = Math.floor(e.target.files[0].size / 1024); //

        if (fileSizeKiloBytes <= MIN_FILE_SIZE) {
            e.preventDefault();
            let files;
            var ext = e.target.value.split('.').pop();
            var allowedExtensions = /(\jpg|\png|\jpeg)$/i;

            if (!allowedExtensions.exec(ext)) {

                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "",
                    text: "Invalid file type",
                    showConfirmButton: true

                }).then((result) => {

                    if (result.isConfirmed) {

                        e.target.value = '';
                        return false;
                    }
                });

            } else {
                if (e.dataTransfer) {
                    files = e.dataTransfer.files;
                } else if (e.target) {
                    files = e.target.files;
                }
                const reader = new FileReader();
                reader.onload = () => {
                    setImage(reader.result);
                };
                reader.readAsDataURL(files[0]);

            }
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Max file size is 5MB",
                showConfirmButton: true

            }).then((result) => {
            });
        }
    };
    function handleChange(event) {
        setImageSrc(URL.createObjectURL(event.target.files[0]))

    }
    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas({  minWidth: 240,  minHeight: 240,  maxWidth: 350,  maxHeight: 350}).toDataURL('image/jpeg'));
            document.getElementById("btnclose").click();
        }
    };
    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imgsrc,
                croppedAreaPixels,
                rotation
            )

            setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        showCroppedImage()
    }, [])

    const submitValue = () => {

        var documentList = [];
        try {
            for (var i = 0; i < inputList.length; i++) {
                // console.log("filebase64=="+inputList[i].filetobase64);
                var extension = "";
                var byteString = "";
                if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
                    extension = "pdf";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
                } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
                    extension = "jpg";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
                    extension = "png";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
                }
                //    else if(inputList[i].filetobase64.match("data:application/msword;base64,"))
                //    {
                //        extension="word";
                //        byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/msword;base64,", ""):"";
                //    }
                else {

                }


                documentList.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "extension": extension });
            }
        } catch (exception) {
            documentList.push();

        }


        var vechileReq =
        {
            "firstName": $("#firstName").val() != '' ? $("#firstName").val().trim() : '',
            "lastName": $("#lastName").val() != '' ? $("#lastName").val().trim() : '',
            "email": $("#email").val() != '' ? $("#email").val().trim() : '',
            "deskNumber": $("#deskNumber").val() != '' ? $("#deskNumber").val().trim() : '',
            "extnNo": $("#ext").val() != '' ? $("#ext").val().trim() : '',
            "phoneNumber": $("#mobileNumber").val() != '' ? $("#mobileNumber").val().trim() : '',
            "addressLine1": $("#address1").val() != '' ? $("#address1").val().trim() : '',
            "addressLine2": $("#address2").val() != '' ? $("#address2").val().trim() : '',
            "city": $("#city").val() != '' ? $("#city").val().trim() : '',
            "securityRoleId": $("#role").val() != '' ? $("#role").val() : '',
            "securityRoleName": $("#role option:selected").text() != '' ? $("#role option:selected").text() : '',
            "fkStateId": $("#state").val() != '' ? $("#state").val() : '',
            "zip": $("#zipcode").val() != '' ? $("#zipcode").val().trim() : '',

            "imageName": cropper.getCroppedCanvas() ? parseInt(Math.random() * 1000000000) : "",
            "imageExtension": cropper.getCroppedCanvas() ? "jpeg" : "",
            "imageByteString": cropData.replace("data:image/jpeg;base64,", ""),
            // "imageName":"",
            // "imageExtension":"",
            // "imageByteString":"",

            "documents": documentList
        }
        // setLoading(true);
        $("#loadingforsubmit").show();
        var requestOptions = requestObject.RequestHeader(vechileReq);
        services.addUser(requestOptions).then((res) => {
            // setLoading(false);
            $("#loadingforsubmit").hide();
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: "",
                text: res.message,
                showConfirmButton: true
            }).then((result) => {

                if (result.isConfirmed) {

                    navigate("/fleetowner/employees");
                }
            });

        });
        // }

    }
    const redirect = (page) => {

        navigate(page);

    }
    return (
        <>
            {LoadingComponentForSubmit}
            <div class="main-panel">
                <div class="content-wrapper" style={{ display: "block" }}>
                    <form onSubmit={handleSubmit(submitValue)}>
                        <div class="col-lg-9 col-md-12 m-auto ">
                            <div class="row">
                                <div class="col-md-12 m-auto mb-2 ">
                                    <h4 class="font-weight-bold my-4 text-uppercase">Add Employee</h4>

                                    <div class="card mb-2 px-4">
                                        <div class="col-md-12 pt-4 pr-4 px-0 mb-2">
                                            <h3>Basic Information</h3>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <label>First Name<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" maxLength="20" minLength="2" id="firstName" {...register('firstName', { required: true })} />
                                                {errors.firstName && errors.firstName.type === "required" && <span class="error">First name is required</span>}
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label>Last Name<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" maxLength="20" minLength="2" id="lastName" {...register('lastName', { required: true })} />
                                                {errors.lastName && errors.lastName.type === "required" && <span class="error">Last name is required</span>}
                                            </div>

                                        </div>


                                        <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <label>Address line 1<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" maxLength="50" id="address1" {...register('address1', { required: true })} />
                                                {errors.address1 && errors.address1.type === "required" && <span class="error">Address is required</span>}
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label>Address line 2</label>
                                                <input type="text" class="form-control custome-input" maxLength="50" id="address2" />
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label>City<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" id="city" {...register('city', { required: true, minLength: "2" })} maxLength="50" minLength="2" />
                                                {errors.city && errors.city.type === "required" && <span class="error">City is required</span>}
                                                {errors.city && errors.city.type === "minLenght" && <span class="error">City atleast 2 characters</span>}
                                                {/* {errors.city && errors.city.type === "pattern" && <span class="error">City is allow only character's</span>} */}
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label>State<span class="star">*</span></label>
                                                <select class="form-control custome-select" id="state" {...register('state', { required: true })}>
                                                    <option value='' >Select</option>
                                                    {
                                                        states ? states.map((state, index) => (
                                                            <option value={state.id}>{state.name}</option>
                                                        )) : <option value=""></option>
                                                    }
                                                </select>
                                                {errors.state && errors.state.type === "required" && <span class="error">State is required</span>}
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label>Zip code<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" id="zipcode" maxLength="5" {...register('zipcode', { required: true, pattern: /^[0-9]{5}(-[0-9]{1})?$/ })} maxLength="5" />
                                                {errors.zipcode && errors.zipcode.type === "required" && <span class="error">Zip code is required</span>}
                                                {errors.zipcode && errors.zipcode.type === "pattern" && <span class="error">Zip code should be 5 digits</span>}
                                                {/* {errors.zipcode && errors.zipcode.type === "maxLength" && <span class="error">Zipcode should max 6 digits</span>} */}
                                            </div>


                                        </div>

                                        <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <div class="row g-2">
                                                    <div class="col-8">
                                                        <label>Desk Number<span class="star">*</span></label>
                                                        <input type="text" class="form-control custome-input" id="deskNumber" {...register('deskNumber', { required: true, maxLength: 12, minLength: 12 })} />
                                                        {errors.deskNumber && errors.deskNumber.type === "required" && <span class="error">Desk number is required</span>}
                                                        {errors.deskNumber && errors.deskNumber.type === "maxLength" && <p class="error">Desk number should be 10 digits</p>}
                                                        {errors.deskNumber && errors.deskNumber.type === "minLength" && <p class="error">Desk number should be 10 digits</p>}
                                                    </div>
                                                    <div class="col-4">
                                                        <label style={{ fontSize: "10px" }}>Extn no</label>
                                                        <input type="text" class="form-control custome-input" id="ext" />
                                                        {/* {...register('ext', { required: true})} */}
                                                        {/* {errors.ext && errors.ext.type === "required" && <span class="error">Ext is required</span>} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label>Phone Number</label>
                                                <input type="text" class="form-control custome-input" id="mobileNumber" {...register('mobileNumber', { maxLength: 12, minLength: 12 })} />
                                                {/* {errors.mobileNumber && errors.mobileNumber.type === "required" && <span class="error">Mobile number is required</span>} */}
                                                {errors.mobileNumber && errors.mobileNumber.type === "maxLength" && <p class="error">Phone number should be 10 digits</p>}
                                                {errors.mobileNumber && errors.mobileNumber.type === "minLength" && <p class="error">Phone number should be 10 digits</p>}
                                            </div>

                                            <div class="col-md-6 mb-3">
                                                <label>Email<span class="star">*</span></label>
                                                <input type="text" class="form-control custome-input" id="email" {...register('email', {
                                                    required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                                })} />
                                                {errors.email && errors.email.type === "required" && <span class="error">Email is required</span>}
                                                {errors.email && errors.email.type === "pattern" && <span class="error">Invalid email address</span>}
                                            </div>


                                        </div>

                                        <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <label>Role<span class="star">*</span></label>
                                                <select class="form-control custome-select" id="role" {...register('role', { required: true })}>
                                                    <option value='' selected>Select</option>
                                                    {/* <option value='2'>Manager</option>
                                                <option value='4' >Dispatcher</option>
                                                <option value='3' >Accounts</option>
                                                <option value='4' >Other</option> */}
                                                    {
                                                        roles ? roles.sort((a, b) => a.name.localeCompare(b.name)).map((role, index) => (
                                                            <option value={role.id}>{role.name}</option>
                                                        )) : <option value=""></option>
                                                    }

                                                </select>
                                                {errors.role && errors.role.type === "required" && <span class="error">Role is required</span>}
                                            </div>
                                        </div>

                                    </div>

                                    <div class="card mb-2">
                                        <div class="card-body pt-0 pb-2 p-3">
                                            <div class="row d-flex align-items-center">

                                                <div class="col-md-2">

                                                    <b>Picture</b>

                                                </div>
                                                <div class="col-md-3">
                                                    <div class="avatar">
                                                        <img src={cropData ? cropData : dummyImge} />
                                                    </div>
                                                </div>

                                                <div class="col-md-4"><a href="" class="btn btn-outline-dark btn-fw" data-toggle="modal" data-target="#exampleModal" data-backdrop="static" data-keyboard="false">
                                                    Upload Photo</a>
                                                </div>

                                                <div class="col-md-3 text-left">
                                                    <div class="instruction">
                                                        <span>Instruction</span>
                                                        <p>Max File Size : 5MB</p>
                                                        {/* <p>Formats: JPG/PNG/BMP</p> */}
                                                        <p>Formats: JPG/PNG</p>
                                                        <p>Ratio: 1:1 (width and height must be equal)</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card mb-2 p-3">
                                        <h3 class="mb-4 pl-1">IDs & Other Documents</h3>
                                        <div class="col-md-6 text-left">
                                            <div class="instruction">
                                                <span>Instruction</span>
                                                <p>Max File Size : 50MB</p>
                                                {/* <p>Formats: JPG/PNG/BMP</p> */}
                                                <p>Formats: JPG/PNG/PDF</p>
                                                <p>Ratio: 1:1 (width and height must be equal)</p>
                                            </div>
                                        </div>
                                        <AdddocsDispatcher inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                                        {/* <table>
                                        <tbody>
                                            <tr>
                                                <th style={{width: "80%"}} class="p-2">Document Title</th>
                                                <th style={{width: "20%"}} class="p-2">Options</th>
                                            </tr>
                                            <tr>
                                                <td class="p-2">
                                                    <div class="form-group m-0">
                                                        <input type="email" class="form-control custome-input" placeholder="Document title 1"/>
                                                    </div>
                                                </td>
                                                <td class="p-2">
                                                    <div class="form-group m-0 d-flex">
                                                        <img src="/images/icons/pdf-icon.svg" class="pr-2"/>
                                                        <a href="#" class="mr-2"><img src="/images/icons/remove-minus.svg" alt="upload"/></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="p-2">
                                                    <div class="form-group m-0">
                                                        <input type="email" class="form-control custome-input" placeholder="Document title 1"/>
                                                    </div>
                                                </td>
                                                <td class="p-2">
                                                    <div class="form-group m-0 d-flex">
                                                        <a href="#" class="mr-2"><img src="/images/icons/upload.svg" alt=""/></a>
                                                        <a href="#"><img src="/images/icons/add-plus.svg" alt="upload"/></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                                    </div>

                                    <div class="card">
                                        <div class="p-3">
                                            <button type="submit" class="btn btn-primary mw-120 mr-2">SUBMIT</button>
                                            <a onClick={() => redirect("/fleetowner/employees")} class="btn gray-mid-color mw-120">CANCEL</a>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </form>





                </div>
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Upload Pic</h5>
                                <button type="button" class="close" data-dismiss="modal" id="btnclose" aria-label="Close">
                                    <span>×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row mb-2">
                                    <div class="col-12">

                                        <div class="image-upload-plg">
                                            <input type="file" class="w-100 p-3 mb-3" onChange={onChange} accept="image/png, image/jpg, image/jpeg" />



                                            {/* <Cropper
                                 style={{ height: 320, width: "100%" }}
                                 zoomTo={0.5}
                                 initialAspectRatio={4 / 4}
                                 preview=".img-preview"
                                 src={image}
                                 viewMode={3}

                                 minCropBoxHeight={10}
                                 minCropBoxWidth={10}
                                 background={false}
                                 responsive={true}
                                 autoCropArea={1}
                                 checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                 onInitialized={(instance) => {
                                    setCropper(instance);
                                 }}
                                 guides={true}
                              /> */}

                                            <Cropper
                                                style={{ height: 300, width: "100%" }}
                                                aspectRatio={1}
                                                guides={false}
                                                dragMode={"move"}
                                                src={image}
                                                onInitialized={(instance) => {
                                                    setCropper(instance);
                                                }}
                                            />

                                        </div>


                                    </div>

                                    <div>
                                        <div className="box" style={{ width: "320px", float: "right", display: "none" }}>
                                            <h1>Preview</h1>
                                            <div
                                                className="img-preview"

                                            />
                                        </div>
                                        {/* <div
                        className="box"
                        style={{ width: "50%", float: "right", height: "300px" }}
                     >
                        <h1>
                           <span>Crop</span>
                           <button style={{ float: "right" }} onClick={getCropData}>
                           Crop Image
                           </button>
                        </h1>
                        <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                     </div> */}
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal" id="btnclose">Close</button>
                                <button type="button" class="btn btn-primary" onClick={getCropData}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};

FleetAddDispatcher.propTypes = {};

FleetAddDispatcher.defaultProps = {};

export default FleetAddDispatcher;
