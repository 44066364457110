import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import VehicleBasicInfo from "../../../utility/vehicleBasicInfo";
import HeaderBlock from "../vehicle/VehicleHeaderBlock";
// import LeakageTest from "./Leakage-Test/Leakage-Testbp";
import LeakageTest from "./Leakage-Test/Leakage-Test";
import PressureTest from "./pressure-test/pressure-test";
import TractorpmTest from "./Tractor-PM-Test/Tractorpm-Test";
import DriverVehicleTest from "./Driver-Vehicle-Inspection/Driver-Vehicle";
import ServiceCheckListTest from "./Service-Check-List/Service-Check-List";

import services from "../../../services/services";
import $ from "jquery";
import { requestObject } from "../../../utility/requestObject";
import CheckAuthentication from "../../../utility/checkAuthentication";
import CarrierEquipment from "./carrier-equipment/carrier-equipment";
import InternalVisual from "./Internal-visual/Internal-visual";
import WetTestCertification from "./wet-test-certification/WET-test-certification";
import DOT from "./DOT-cargo/DOT-cargo";
import CargoTankThicknessTest from "./cargo-tank-thickness-test/cargo-tank-thickness-test";
import GasolineDeliveryTankPressureVacuumTest from "./gasoline-delivery tank-pressure-vacuum-test/gasoline-delivery tank-pressure-vacuum-test";

import AnnualVehicle1 from "./annualvehicle/inspection-annualvehicle-1";
import ExternalVisualSectionOne from "./external-visual/external-visual-section-one";
import ExternalVisualSectionTwo from "./external-visual/external-visual-section-two";
import ExternalVisualSectionThree from "./external-visual/external-visual-section-three";
import ExternalVisualSectionFour from "./external-visual/external-visual-section-four";
import ExternalVisualSectionFive from "./external-visual/external-visual-section-five";
import ExternalVisualSix from "./external-visual/external-visual";
import AnnualVehicle2 from "./annualvehicle/inspection-annualvehicle-2";
import AnnualVehicle3 from "./annualvehicle/inspection-annualvehicle-3";
import AnnualVehicle4 from "./annualvehicle/inspection-annualvehicle-4";
import AnnualVehicle5 from "./annualvehicle/inspection-annualvehicle-5";
import InspectionVaporTightnessCertificate1 from "./inspection-vapor-tightness-certificate-1";
import InspectionVaporTightnessCertificate2 from "./inspection-vapor-tightness-certificate-2";
import InspectionVaporTightnessCertification3 from "./inspection-vapor-tightness-certificate-3";
import InspectionVaporTightnessCertification4 from "./inspection-vapor-tightness-certificate-4";
import { loadingComponent } from "../../../utility/Loading";
import LeakageTestMethod27 from "./Leakage-Test-Method-27/Leakage-Test-Method-27";
const AddInspection = () => {
  const { taskOrderId, cid, vid } = useParams();
  const navigate = useNavigate();
  const [inspectionType, setInspectionType] = useState();
  const [inspectionTypes, setInspectionTypes] = useState();
  const [inspectionSteps, setInspectionSteps] = useState("section1");
  const [inspectionAnnualSteps, setInspectionAnnualSteps] = useState("annualVehicle1");
  const [inspectionVaporSteps, setInspectionVaporSteps] = useState("certificate1");
  const [itemName, setFieldName] = useState('');
  var vehicleType = "";
  var fromtaskorder = "";
  useEffect(() => {
    // alert(sessionStorage.getItem("tankerSubTypeName"));
    // setInspectionAnnualSteps("annualVehicle2");
    sessionStorage.setItem("naviationfrom", "addinspection");
    fromtaskorder = sessionStorage.getItem("taskOrderfromInspection");

    if (fromtaskorder !== null) {
      setInspectionTypes(fromtaskorder);
      $('#inspectionType').trigger('change');
      $('#inspectionType').find('select').trigger('change');
    }

    // if(fromtaskorder!==null)
    // {

    //   setInspectionTypes(fromtaskorder);
    //   $('#inspectionType').trigger('change');
    //   $('#inspectionType').find('select').trigger('change');
    //   switch(fromtaskorder)
    //   {
    //     case "Leakage Test":
    //       sessionStorage.setItem("selectedinspectionId","1");
    //       sessionStorage.setItem("selectedinspectionType",fromtaskorder);
    //       break;

    //     case "Internal Visual Inspection":
    //       sessionStorage.setItem("selectedinspectionId","2");
    //       sessionStorage.setItem("selectedinspectionType",fromtaskorder);
    //       break;
    //     case "WET Test Certification":
    //       sessionStorage.setItem("selectedinspectionId","3");
    //       sessionStorage.setItem("selectedinspectionType",fromtaskorder);
    //       break;
    //     case "Cargo Tank Thickness Test":
    //       sessionStorage.setItem("selectedinspectionId","4");
    //       sessionStorage.setItem("selectedinspectionType",fromtaskorder);
    //       break;
    //      case "Ohio EPA":
    //       sessionStorage.setItem("selectedinspectionId","5");
    //       sessionStorage.setItem("selectedinspectionType",fromtaskorder);
    //       break;

    //     case "Pressure Test/Retest Inspection":
    //       sessionStorage.setItem("selectedinspectionId","6");
    //       sessionStorage.setItem("selectedinspectionType",fromtaskorder);
    //       break;
    //     case "Annual Vehicle Inspection":
    //       sessionStorage.setItem("selectedinspectionId","7");
    //       sessionStorage.setItem("selectedinspectionType",fromtaskorder);
    //       break;
    //     case "External/Visual Inspection":
    //       sessionStorage.setItem("selectedinspectionId","8");
    //       sessionStorage.setItem("selectedinspectionType",fromtaskorder);
    //       break;

    //     //  ============== For QA ============
    //     // case "Leakage Test (Method 27)":
    //     //   sessionStorage.setItem("selectedinspectionId","9");
    //     //   sessionStorage.setItem("selectedinspectionType",fromtaskorder);
    //     //   break;

    //     //  ============== For Dev ============
    //     case "Leakage Test (Method 27)":
    //         sessionStorage.setItem("selectedinspectionId","11");
    //         sessionStorage.setItem("selectedinspectionType",fromtaskorder);
    //         break;

    //   }

    // }
    // sessionStorage.removeItem("taskOrderfromInspection");
    getInspectionType();
    if (CheckAuthentication) {
      // localStorage.setItem("customerId", cid);
      // localStorage.setItem("vehicleId", vid);


    }
    else {
      sessionStorage.clear();
      localStorage.clear();
      //navigate("/login");
      window.location.replace("login");
    }
    $(document).ready(function () {
      console.log("ready!");
    });

    ///inspection type check///////////////////////////////
    $('#inspectionType').on('change', function () {
      // alert(inspectionTypes);
      const res = this.value.replace(/ /g, '');

      if (res.match("External/Visual Inspection")) {

        setInspectionTypes("External Visual Inspection");
      }
      else {
        setInspectionTypes(this.value.toString().trim());
      }

    });

  }, []);
  const redirect = (page) => {

    navigate(page);

  }

  const getInspectionType = () => {


    var vehicle_request =
    {

    };

    //   const requestOptions =
    //   {
    //         method: 'POST',
    //         headers:{'Content-Type': 'application/json'},
    //         body: JSON.stringify(vehicle_request)
    //   };

    var requestOptions = requestObject.RequestHeader(vehicle_request);
    //  vehicleType=document.getElementById("vehicleType").innerHTML;

    services.getInspectionTypeS(requestOptions).then((res) => {
      console.log("results=" + JSON.stringify(res.rows));

      setInspectionType(res.rows);

      for (let j = 0; j < res.rows.length; j++) {

        if (fromtaskorder !== null) {
          // setInspectionTypes(fromtaskorder);
          // $('#inspectionType').trigger('change');
          // $('#inspectionType').find('select').trigger('change');
          switch (fromtaskorder) {
            case "Leakage Test":
              if (res.rows[j].name == 'Leakage Test') {
                sessionStorage.setItem("selectedinspectionId", res.rows[j].id);
              }
              sessionStorage.setItem("selectedinspectionType", fromtaskorder);
              break;

            case "Tractor PM/Inspection":
                if (res.rows[j].name == 'Tractor PM/Inspection') {
                  sessionStorage.setItem("selectedinspectionId", res.rows[j].id);
                }
                sessionStorage.setItem("selectedinspectionType", fromtaskorder);
                break;

            case "Tractor Preventive Maintenance/Inspection":
                if (res.rows[j].name == 'Tractor PM/Inspection' || res.rows[j].name == 'Tractor Preventive Maintenance/Inspection') {
                  sessionStorage.setItem("selectedinspectionId", res.rows[j].id);
                }
                sessionStorage.setItem("selectedinspectionType", fromtaskorder);
                break;

            case "Driver Vehicle Inspection":
                  if (res.rows[j].name == 'Driver Vehicle Inspection') {
                    sessionStorage.setItem("selectedinspectionId", res.rows[j].id);
                  }
                  sessionStorage.setItem("selectedinspectionType", fromtaskorder);
                  break;

            case "Service Check List":
                    if (res.rows[j].name == 'Service Check List') {
                      sessionStorage.setItem("selectedinspectionId", res.rows[j].id);
                    }
                    sessionStorage.setItem("selectedinspectionType", fromtaskorder);
                    break;

            case "Internal Visual Inspection":
              if (res.rows[j].name == 'Internal Visual Inspection') {
                sessionStorage.setItem("selectedinspectionId", res.rows[j].id);
              }
              // sessionStorage.setItem("selectedinspectionId","2");
              sessionStorage.setItem("selectedinspectionType", fromtaskorder);
              break;
            case "WET Test Certification":
              if (res.rows[j].name == 'WET Test Certification') {
                sessionStorage.setItem("selectedinspectionId", res.rows[j].id);
              }
              // sessionStorage.setItem("selectedinspectionId","3");
              sessionStorage.setItem("selectedinspectionType", fromtaskorder);
              break;
            case "Cargo Tank Thickness Test":
              // sessionStorage.setItem("selectedinspectionId","4");
              if (res.rows[j].name == 'Cargo Tank Thickness Test') {
                sessionStorage.setItem("selectedinspectionId", res.rows[j].id);
              }
              sessionStorage.setItem("selectedinspectionType", fromtaskorder);
              break;
            case "Ohio EPA":
              // sessionStorage.setItem("selectedinspectionId","5");
              if (res.rows[j].name == 'Ohio EPA') {
                sessionStorage.setItem("selectedinspectionId", res.rows[j].id);
              }
              sessionStorage.setItem("selectedinspectionType", fromtaskorder);
              break;

            case "Pressure Test/Retest Inspection":
              // sessionStorage.setItem("selectedinspectionId","6");
              if (res.rows[j].name == 'Pressure Test/Retest Inspection') {
                sessionStorage.setItem("selectedinspectionId", res.rows[j].id);
              }
              sessionStorage.setItem("selectedinspectionType", fromtaskorder);
              break;
            case "Annual Vehicle Inspection":
              // sessionStorage.setItem("selectedinspectionId","7");
              if (res.rows[j].name == 'Annual Vehicle Inspection') {
                sessionStorage.setItem("selectedinspectionId", res.rows[j].id);
              }
              sessionStorage.setItem("selectedinspectionType", fromtaskorder);
              break;
            case "External/Visual Inspection":
              if (res.rows[j].name == 'External/Visual Inspection') {
                sessionStorage.setItem("selectedinspectionId", res.rows[j].id);
              }
              // sessionStorage.setItem("selectedinspectionId","8");
              sessionStorage.setItem("selectedinspectionType", fromtaskorder);
              break;

            //  ============== For QA ============
            // case "Leakage Test (Method 27)":
            //   sessionStorage.setItem("selectedinspectionId","9");
            //   sessionStorage.setItem("selectedinspectionType",fromtaskorder);
            //   break;

            //  ============== For Dev ============
            case "Leakage Test (Method 27)":
              // sessionStorage.setItem("selectedinspectionId","11");
              if (res.rows[j].name == 'Leakage Test (Method 27)') {
                sessionStorage.setItem("selectedinspectionId", res.rows[j].id);
              }
              sessionStorage.setItem("selectedinspectionType", fromtaskorder);
              break;

          }

        }



      }

      res.rows.length ? res.rows.map((data, index) => (
        data.name != 'TO Created' && data.name != 'To Be Approved' ?
          <option value={data.id} >{data.name}</option> : ''
      )) : <option value=""></option>




    });
  }


  $(document).on("click", "#certificate1", function () {
    setInspectionVaporSteps("certificate1");

  });

  $(document).on("click", "#certificate2", function () {
    setInspectionVaporSteps("certificate2");
  });

  $(document).on("click", "#certificate3", function () {
    setInspectionVaporSteps("certificate3");
  });

  $(document).on("click", "#certificate4", function () {
    setInspectionVaporSteps("certificate4");
  });

  $(document).on("click", "#section1", function () {
    setInspectionSteps("section1");
  });

  $(document).on("click", "#section2", function () {
    setInspectionSteps("section2");
  });

  $(document).on("click", "#section3", function () {
    setInspectionSteps("section3");
  });

  $(document).on("click", "#section4", function () {
    setInspectionSteps("section4");
  });


  $(document).on("click", "#section5", function () {
    setInspectionSteps("section5");
  });

  $(document).on("click", "#section6", function () {
    setInspectionSteps("section6");
  });

  // $('#section1').on('click', function()
  // {

  //   setInspectionSteps("section1");

  // });

  // $('#section2').on('click', function()
  // {


  //    setInspectionSteps("section2");

  // });
  // $('#section3').on('click', function()
  // {

  //   setInspectionSteps("section3");

  // });
  // $('#section4').on('click', function()
  // {

  //   setInspectionSteps("section4");

  // });

  // $('#section5').on('click', function()
  // {


  //   setInspectionSteps("section5");

  // });
  // $('#section6').on('click', function()
  // {

  //    setInspectionSteps("section6");

  // });

  //Annual Inspection

  $(document).on("click", "#annualVehicle1", function () {
    setInspectionAnnualSteps("annualVehicle1");
  });

  $(document).on("keyup", "#annualVehicle1", function () {
    setInspectionAnnualSteps("annualVehicle1");
  });

  // $('#annualVehicle1').on('click', function()
  // {
  //   alert("annualVehicle1");
  //   setInspectionAnnualSteps("annualVehicle1");

  // });

  $(document).on("click", "#annualVehicle2", function () {
  
    setInspectionAnnualSteps("annualVehicle2");
   
  });

  // $('#annualVehicle2').on('click', function()
  // {
  //   alert("annualVehicle2");
  //   setInspectionAnnualSteps("annualVehicle2");

  // });
  $(document).on("click", "#annualVehicle3", function () {
    setInspectionAnnualSteps("annualVehicle3");
  });
  // $('#annualVehicle3').on('click', function()
  // {
  //   alert("annualVehicle3");
  //   setInspectionAnnualSteps("annualVehicle3");

  // });
  $(document).on("click", "#annualVehicle4", function () {
    setInspectionAnnualSteps("annualVehicle4");
  });
  // $('#annualVehicle4').on('click', function()
  // {
  //   alert("annualVehicle4");
  //   setInspectionAnnualSteps("annualVehicle4");

  // });

  $(document).on("click", "#annualVehicle5", function () {
    setInspectionAnnualSteps("annualVehicle5");
  });
  const selectedinspectionType = (e) => {

    setInspectionTypes(e.target.selectedOptions[0].text);
    sessionStorage.setItem("selectedinspectionId", e.target.value);
    sessionStorage.setItem("selectedinspectionType", e.target.selectedOptions[0].text);
  }
  // $('#annualVehicle5').on('click', function()
  // {
  //   alert("annualVehicle5");
  //   setInspectionAnnualSteps("annualVehicle5");

  // });

  // $('#vapourTest').on('click', function()
  // {

  //   setInspectionVapourSteps("vapour2");

  // });


  return (
    <>
      {loadingComponent}
      <div class="main-panel">
        <div class="content-wrapper" style={{ display: "block" }}>
          <div class="row mb-0">
            <div class="col-lg-11 col-md-12 m-auto mb-0 ">
              <div class="d-flex justify-content-between align-items-center my-4">
                <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-11 col-md-12 m-auto ">
              <HeaderBlock />
            </div>
          </div>
          {/* <div class="row mb-4">
                     <div class="col-lg-11 col-md-12 m-auto ">
                        <div class="card p-3">
                           <div class="banner-owner">
                              <div class="owner-logo">
                                 <img src="images/transport-logo.jpg" alt=""/>
                                 <div class="owner-name">
                                    <h4>ABCD Transport Ltd.</h4>

                                 </div>
                              </div>
                              <div class="banner-contact-info bg-white d-flex align-items-center flex-column flex-sm-row">
                                 <div class="row">
                                    <div class="col-md-4">
                                       <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                          <img src="images/icons/map-icon.svg" alt="" class="mr-2"/>
                                          <div style={{minWidth: "240px"}}>
                                             <span>Baltimore, MD - 21216</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-md-4">
                                       <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                          <img src="images/icons/phone-icon.svg" alt="" class="mr-2"/>
                                          <span>(617) 399 8424</span>
                                       </div>
                                    </div>
                                    <div class="col-md-4">
                                       <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                          <img src="images/icons/message-icon.svg" alt="" class="mr-2"/>
                                          <span>info@abcdtransport.com</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div> */}

          {/* <div class="row mb-2">
            <div class="col-lg-11 col-md-12 m-auto mb-2 ">
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="font-weight-bold">Vehicle Inspection</h4>
                <a  onClick={() => redirect("/vehicleDetails/"+localStorage.getItem("vehicleId"))} class="btn btn-white"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
              </div>
            </div>
          </div> */}

          <div class="row mb-2">
            <div class="col-lg-11 col-md-12 m-auto mb-2 ">
              <VehicleBasicInfo customerId={vid} />
            </div>
          </div>
          {/* <div class="row mb-2">
                              <div class="col-lg-12">
                                  <div class="wo d-flex align-items-center p-3">
                                      <img src="images/icons/workorder-icon.svg" alt=""/>
                                      <span class="mr-2 ml-2">Workd order Number : </span>
                                      <input type="input" placeholder="Enter W/O"/>
                                  </div>
                              </div>
                              <div class="col-lg-12 col-md-12 m-auto grid-margin stretch-card ">

                                  <div class="header-top-bg p-3 ">
                                      <h4>Vehicle Information</h4>
                                      <div class="info-labl">
                                          <img src="images/icons/vehicle-icon.svg" alt=""/>
                                          |
                                          <span>ERS 4343</span>
                                      </div>
                                  </div>

                               </div>

                               <div class="col-md-4">
                                  <div class="p-3 d-flex flex-column">
                                      <label for="" class="opacity-7">Vehicle VIN</label>
                                      <b>IPMS44324T1017489</b>
                                  </div>
                              </div>

                              <div class="col-md-4">
                               <div class="p-3 d-flex flex-column">
                                   <label for="" class="opacity-7">Serial Number</label>
                                   <b>IPMS44324T1017489</b>
                               </div>
                           </div>

                               <div class="col-md-4">
                                   <div class="p-3 d-flex flex-column">
                                       <label for="" class="opacity-7">Licence Plate Number</label>
                                       <b>ERS 8547</b>
                                   </div>
                               </div>

                               <div class="col-md-4">
                                  <div class="p-3 d-flex flex-column">
                                      <label for="" class="opacity-7">Vehicle Type</label>
                                      <b>Trailer</b>
                                  </div>
                              </div>



                              <div class="col-md-4">
                                  <div class="p-3 d-flex flex-column">
                                      <label for="" class="opacity-7">Make</label>
                                      <b>POLAR</b>
                                  </div>
                              </div>

                              <div class="col-md-4">
                                  <div class="p-3 d-flex flex-column">
                                      <label for="" class="opacity-7">Model</label>
                                      <b>F150</b>
                                  </div>
                              </div>

                              <div class="col-md-4">
                                  <div class="p-3 d-flex flex-column">
                                      <label for="" class="opacity-7">Year</label>
                                      <b>1998</b>
                                  </div>
                              </div>



                          </div> */}

          <div class="row mb-2 ">
            <div class="col-lg-11 m-auto">
              <div class="white-color px-3 py-3">
                <div class="col-md-5 px-0">
                  <h4 class="mb-4">Inspection Type</h4>
                  <div class="form-group mb-0">
                    <select class="form-control" id="inspectionType" onChange={e => selectedinspectionType(e)}  disabled>


                      <option value="">Select inspection type</option>
                      {
                        inspectionType ? inspectionType.map((inspection, index) => 
                        {

                         
                            return (<option value={inspection.id} selected={inspection.name == inspectionTypes ? "selected" : ""}>{inspection.name}</option>)
                          




                        }) : <option value=""></option>
                      }

                    </select>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-lg-11 m-auto">
            {inspectionTypes == "Tractor PM Inspection" || inspectionTypes == "Tractor PM Test" || inspectionTypes == "Tractor PM/Inspection" || inspectionTypes == "Tractor Preventive Maintenance/Inspection" ? <div class="white-color p-3"><TractorpmTest /></div> : ""}

            {inspectionTypes == "Driver Vehicle Inspection" ? <div class="white-color p-3"><DriverVehicleTest /></div> : ""}
            {inspectionTypes == "Service Check List" ? <div class="white-color p-3"><ServiceCheckListTest /></div> : ""}

              {inspectionTypes == "Leakage Inspection" || inspectionTypes == "Leakage Test" ? <div class="white-color p-3"><LeakageTest /></div> : ""}
              {inspectionTypes == "Leakage Test (Method 27)" ? <div class="white-color p-3"><LeakageTestMethod27 /></div> : ""}
              {inspectionTypes == "Annual Vehicle Inspection" ? <div class="white-color p-3">
                {(() => {
                  if (inspectionAnnualSteps === "annualVehicle1") {
                    return <> <AnnualVehicle1 /></>
                    // return<><AnnualVehicle3/></>
                  }
                  else if (inspectionAnnualSteps === "annualVehicle2") {
                    return <><AnnualVehicle2 /></>
                  }
                  else if (inspectionAnnualSteps === "annualVehicle3") {

                    return <><AnnualVehicle3 /></>
                  }
                  else if (inspectionAnnualSteps === "annualVehicle4") {
                    return <><AnnualVehicle4 /></>
                  }
                  else if (inspectionAnnualSteps === "annualVehicle5") {
                    return <><AnnualVehicle5 /></>
                  }
                  else {

                  }

                })()
                }
              </div> : ""}
              {inspectionTypes == "Pressure test - Retest Inspection" || inspectionTypes == "Pressure Test/Retest Inspection" ? <div class="white-color p-3"><PressureTest /></div> : ""}
              {inspectionTypes == "Carrier Equipment Inspection" ? <div class="white-color p-3"><CarrierEquipment></CarrierEquipment></div> : ""}
              {inspectionTypes == "Internal Visual Inspection" ? <div class="white-color p-3"><InternalVisual></InternalVisual></div> : ""}
              {inspectionTypes == "External Visual Inspection" || inspectionTypes == "External/Visual Inspection" ? <div class="white-color p-3">
                {(() => {
                  if (inspectionSteps == "section1") {
                    return <> <ExternalVisualSectionOne /></>
                  }
                  else if (inspectionSteps == "section2") {
                    return <><ExternalVisualSectionTwo /></>
                  }
                  else if (inspectionSteps === "section3") {
                    return <><ExternalVisualSectionThree /></>
                  }
                  else if (inspectionSteps === "section4") {
                    return <><ExternalVisualSectionFour /></>
                  }
                  else if (inspectionSteps === "section5") {
                    return <><ExternalVisualSectionFive /></>
                  }
                  else if (inspectionSteps === "section6") {
                    return <><ExternalVisualSix /></>
                  }
                  else {



                  }

                })()
                }
              </div> : ""}
              {inspectionTypes == "Vapor Tightness Certification" ? <div class="white-color p-3">
                {(() => {
                  if (inspectionVaporSteps == "certificate1") {
                    return <> <InspectionVaporTightnessCertificate1 /></>
                  }
                  else if (inspectionVaporSteps == "certificate2") {
                    return <><InspectionVaporTightnessCertificate2 /></>
                  }
                  else if (inspectionVaporSteps == "certificate3") {
                    return <><InspectionVaporTightnessCertification3 /></>
                  }
                  else if (inspectionVaporSteps == "certificate4") {
                    return <><InspectionVaporTightnessCertification4 /></>
                  }
                  else {

                  }

                })()
                }
              </div> : ""}
              {inspectionTypes == "WET Test Certification" ? <div class="white-color p-3"><WetTestCertification /></div> : ""}
              {inspectionTypes == "Cargo Tank Thickness Test" ? <div class="white-color p-3"><CargoTankThicknessTest /></div> : ""}
              {inspectionTypes == "DOT Cargo Tank" || inspectionTypes == "DOT cargo tank-EPA-Pressure -Vacuum Test" || inspectionTypes == "Ohio EPA" ? <div class="white-color p-3"><DOT></DOT></div> : ""}
              {inspectionTypes == "Gassoline Delivery Tank - Pressure Vaccum Test" ? <div class="white-color p-3"><GasolineDeliveryTankPressureVacuumTest /></div> : ""}



            </div>

            {/* <div class="col-lg-12">
                                    <h4 class="mb-2 form-inspection-title text-center">Leakage Test</h4>
                                </div>

                                 <div class="col-lg-4 mb-2">
                                    <div class="d-flex align-items-center">
                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" class="mr-2"/>
                                       <span>Hydrostatic</span>
                                    </div>
                                </div>

                                <div class="col-lg-4 mb-2">
                                 <div class="d-flex align-items-center">
                                     <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" class="mr-2"/>
                                    <span>Pneumatics</span>
                                 </div>
                             </div>


                                <div class="col-lg-12">
                                    <div class="inspection-wrap">

                                       <div class="table-2 mb-4">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Unit Design Pressure</th>
                                                    <th scope="col">Yes</th>
                                                    <th scope="col">No</th>
                                                  </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td>Close all valves and all tank with manhole cover open. Inspect all surface r leaks inspect all top and bottom component installation below water line for leaks: cleanouts-welds-vents valve sumps. Are they any leaks?</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                       </tr>

                                                       <tr>
                                                        <td>Close all valves and all tank with manhole cover open. Inspect all surface r leaks inspect all top and bottom component installation below water line for leaks: cleanouts-welds-vents valve sumps. Are they any leaks?</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                       </tr>

                                                       <tr>
                                                        <td>Close all valves and all tank with manhole cover open. Inspect all surface r leaks inspect all top and bottom component installation below water line for leaks: cleanouts-welds-vents valve sumps. Are they any leaks?</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                       </tr>


                                                 </tbody>
                                              </table>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="inspection-wrap">

                                       <div class="table-2 mb-4">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Unit Design Pressure</th>
                                                    <th scope="col">Yes</th>
                                                    <th scope="col">No</th>
                                                  </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td>Close all valves and all tank with manhole cover open. Inspect all surface r leaks inspect all top and bottom component installation below water line for leaks: cleanouts-welds-vents valve sumps. Are they any leaks?</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                       </tr>




                                                 </tbody>
                                              </table>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="inspection-wrap">

                                       <div class="table-2 mb-4">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Inspection for valve Leakage</th>
                                                    <th scope="col">Yes</th>
                                                    <th scope="col">No</th>
                                                  </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td>Close all valves and all tank with manhole cover open. Inspect all surface r leaks inspect all top and bottom component installation below water line for leaks: cleanouts-welds-vents valve sumps. Are they any leaks?</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                       </tr>

                                                       <tr>
                                                        <td>Close all valves and all tank with manhole cover open. Inspect all surface r leaks inspect all top and bottom component installation below water line for leaks: cleanouts-welds-vents valve sumps. Are they any leaks?</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                       </tr>

                                                       <tr>
                                                        <td>Close all valves and all tank with manhole cover open. Inspect all surface r leaks inspect all top and bottom component installation below water line for leaks: cleanouts-welds-vents valve sumps. Are they any leaks?</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                       </tr>

                                                       <tr>
                                                        <td>Close all valves and all tank with manhole cover open. Inspect all surface r leaks inspect all top and bottom component installation below water line for leaks: cleanouts-welds-vents valve sumps. Are they any leaks?</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                       </tr>

                                                       <tr>
                                                        <td>Close all valves and all tank with manhole cover open. Inspect all surface r leaks inspect all top and bottom component installation below water line for leaks: cleanouts-welds-vents valve sumps. Are they any leaks?</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                       </tr>

                                                       <tr>
                                                        <td>Any condition that might render tank unsafe for transportation service                                                        </td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                       </tr>

                                                       <tr>
                                                        <td>"Tank marking: Date (month/Year) and service symbol after all defects are corrected. Did you update markings on tank                                                        </td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input"/></td>
                                                       </tr>


                                                 </tbody>
                                              </table>
                                        </div>

                                    </div>
                                </div> */}
          </div>
          {/* <div class="divider"></div>
                            <div class="row p-3">
                                <div class="col-lg-12">
                                    <h4 class="mb-4">Additional Documents</h4>
                                    <div class="note"> <img src="images/icons/note-icon.png" alt="" class="mr-2"/> <small>Support Files : JPG / PDF / Word / BMP / PNG</small></div>
                                    <div class="table-1">
                                        <table>
                                            <tr>
                                              <th>Document title</th>
                                              <th>Upload</th>
                                              <th>Action</th>
                                            </tr>
                                            <tr class="mb-2 pb-2">
                                              <td class="pr-4 "><input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter Doc Name" aria-describedby="emailHelp" class="mr-2"/></td>
                                              <td>  <label class="mb-0"> <img src="images/icons/pdf-icon.svg" alt=""/> <input type="file" size="60" /> </label> </td>
                                              <td><a href="#"><img src="images/icons/remove-minus.svg" alt="upload"/></a></td>
                                            </tr>
                                            <tr class="mb-2 pb-2">
                                                <td class="pr-4"><input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter Doc Name" aria-describedby="emailHelp" class="mr-2"/></td>
                                                <td>  <label class="mb-0"> <img src="images/icons/upload.svg" alt=""/> <input type="file" size="60" /> </label> </td>
                                                <td><a href="#"><img src="images/icons/add-plus.svg" alt="upload"/></a></td>
                                              </tr>
                                          </table>
                                    </div>
                                </div>
                            </div> */}

          {/* <div class="divider"></div>
                            <div class="row p-3">
                                <div class="col-lg-12">
                                   <div class="d-flex align-items-center mb-3">
                                    <input type="checkbox" aria-label="Checkbox for following text input" class="mr-2"/>
                                        <small>Have any service been performed</small>
                                   </div>
                                   <div class="form-group">
                                    <label for="exampleInputPassword1"><b>Add a written Question</b></label>
                                    <textarea class="form-control mb-2" id="exampleFormControlTextarea1" rows="3"></textarea>
                                    <a href="#" type="button" class="btn btn-success">Submit</a>
                                  </div>

                                  <div class="qst mt-5">
                                    <div class="qst-item mb-4">
                                        <span>Q1</span> <span>Would you like us to repaire a trailer engine?</span> <span class="btn-label-orange">Waiting</span>
                                    </div>
                                    <div class="qst-item mb-3">
                                        <span>Q2</span> <span>Would you like us to repaire a trailer engine?</span> <span class="btn-label-green">Approved</span>
                                    </div>
                                  </div>

                                </div>
                            </div> */}

          {/* <div class="divider"></div>
                            <div class="row p-3">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Test Performed by</label>
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name"/>
                                      </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Date</label>
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Date"/>
                                      </div>
                                </div>
                            </div> */}

          {/* <div class="divider"></div>
                            <div class="row p-3 pr-4 d-flex justify-content-end">
                                <a href="#" class="btn btn-secondary mr-1 ">Cancel</a>
                                <a href="#" class="btn btn-primary mr-1">Save as Draft </a>
                                <a href="#" class="btn btn-primary mr-1">Save</a>
                            </div> */}
        </div>




      </div>
    </>
  )
};

AddInspection.propTypes = {};

AddInspection.defaultProps = {};

export default AddInspection;
