import React,{history,useEffect,forwardRef,useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate ,useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import  $ from "jquery";
import {useLoading} from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import AddInspectionName from "../../../../utility/addInspectionName";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const GasolineDeliveryTankPressureVacuumTest = () => {
  const { taskOrderId,cid, vid } = useParams();
    const [inputList, setInputList] = useState([{ }]);
    const [base64file, setBase64file] = useState('');
    const [loading, setLoading] = useLoading("Please wait...");
    const [ fileName, setfileName ] = useState("");
    const navigate = useNavigate();
    const [endDate, setEndDate] = useState(new Date());

  
    $(document).ready(function () {

      $("#pressurestartingTime").attr("readonly", "readonly");
      $("#pressurestartingTime2").attr("readonly", "readonly");
      $("#pressurestartingTime3").attr("readonly", "readonly");
      $("#pressurefinishTime1").attr("readonly", "readonly");
      $("#pressurefinishTime2").attr("readonly", "readonly");
      $("#pressurefinishTime3").attr("readonly", "readonly");

      $("#vaccumeTeststartingTime").attr("readonly", "readonly");
      $("#vaccumeTeststartingTime2").attr("readonly", "readonly");
      $("#vaccumeTeststartingTime3").attr("readonly", "readonly");
      $("#vaccumeTestfinishTime").attr("readonly", "readonly");
      $("#vaccumeTestfinishTime2").attr("readonly", "readonly");
      $("#vaccumeTestfinishTime3").attr("readonly", "readonly");

      $("#internalVaporValueTeststartingTime").attr("readonly", "readonly");
      $("#internalVaporValueTeststartingTime2").attr("readonly", "readonly");
      $("#internalVaporValueTeststartingTime3").attr("readonly", "readonly");
      $("#internalVaporValueTestfinishTime").attr("readonly", "readonly");
      $("#internalVaporValueTestfinishTime2").attr("readonly", "readonly");
      $("#internalVaporValueTestfinishTime3").attr("readonly", "readonly");


      $("#pressureinitialReading1,#pressureinitialReading2,#pressureinitialReading3,#pressuresfinalReading1,#pressuresfinalReading2,#pressuresfinalReading3,#vaccumeTestinitialReading1,#vaccumeTestinitialReading2,#vaccumeTestinitialReading3,#vaccumeTestfinalReading1,#vaccumeTestfinalReading2,#vaccumeTestfinalReading3,#internalVaporinitialReading,#internalVaporinitialReading2,#internalVaporinitialReading3,#internalVaporValueTeststartingTime,#internalVaporValueTestfinalReading2,#internalVaporValueTestfinalReading3,#internalVaporValueTestfinalReading").keypress(function (e) {
         if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
         //   $("#errmsg").html("Digits Only").show().fadeOut("slow");
                  return false;
       }
      });
   });

   const validDateInput = function (e) {
    e.target.value = "";
  };

    const [pressureTest1startTime, setPressureTest1startTime] = useState(null);
    const [pressureTest1finishTime, setPressureTest1finishTime] = useState(null);
    const [pressureTest2startTime, setPressureTest2startTime] = useState(null);
    const [pressureTest2finishTime, setPressureTest2finishTime] = useState(null);
    const [pressureTest3startTime, setPressureTest3startTime] = useState(null);
    const [pressureTest3finishTime, setPressureTest3finishTime] = useState(null);

    const [vaccumeTest1startTime, setVaccumeTest1startTime] = useState(null);
    const [vaccumeTest1finishTime, setVaccumeTest1finishTime] = useState(null);
    const [vaccumeTest2startTime, setVaccumeTest2startTime] = useState(null);
    const [vaccumeTest2finishTime, setVaccumeTest2finishTime] = useState(null);
    const [vaccumeTest3startTime, setVaccumeTest3startTime] = useState(null);
    const [vaccumeTest3finishTime, setVaccumeTest3finishTime] = useState(null);

    const [vaporValveTeststartTime, setVaporValveTeststartTime] = useState(null);
    const [vaporValveTestfinishTime, setVaporValveTestfinishTime] = useState(null);
    const [vaporValveTest2startTime, setVaporValveTest2startTime] = useState(null);
    const [vaporValveTest2finishTime, setVaporValveTest2finishTime] = useState(null);
    const [vaporValveTest3startTime, setVaporValveTest3startTime] = useState(null);
    const [vaporValveTest3finishTime, setVaporValveTest3finishTime] = useState(null);

    const redirect=(page)=>
    {
  
       navigate(page);
    
    }

    const save=(status)=>
    {
  
    // console.log("results=="+JSON.stringify(inputList));
      
     var documentList=[];
  
     try
     {
       for(var i=0;i<inputList.length;i++)
       {
           // console.log("filebase64=="+inputList[i].filetobase64);
           var extension="";
           var byteString="";
           if(inputList[i].filetobase64.match("data:application/pdf;base64,"))
           {
               extension="pdf";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/pdf;base64,", ""):"";
           }else if(inputList[i].filetobase64.match("data:image/jpeg;base64,"))
           {
               extension="jpg";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/jpeg;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:image/png;base64,"))
           {
               extension="png";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/png;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/msword;base64,"))
           {
               extension="word";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/msword;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:image/bmp;base64,"))
           {
               extension="bmp";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/bmp;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
           {
               extension="docm";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
           {
               extension="dotx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
           {
               extension="dotx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,"))
           {
               extension="dotm";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", ""):"";
           }
           else{
              
           }


             documentList.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
       }
     }catch(exception)
     {
      //  Swal.fire({  
      //      position: 'center',  
      //      icon: 'error',  
      //      title: "",  
      //      text:"Please attach document",
      //      showConfirmButton: true 
           
      //    }).then((result) => 
      //    {
               
      //          if(result.isConfirmed) 
      //          {
                 
               
      //          }
      //    });
      //    return false;
      documentList.push();
     }
     if($("#inspectedBy").val()==="")
     {
       Swal.fire({  
           position: 'center',  
           icon: 'error',  
           title: "",  
           text:"Please enter inspectedBy",
           showConfirmButton: true 
           
         }).then((result) => 
         {
               
               if(result.isConfirmed) 
               {
                 
               
               }
         });
         return false;
     }
          
          var requestObjectInspection=
          {
            
            "type":"Gassoline Delivery Tank - Pressure Vaccum Test",
            "inspectedBy":  $("#inspectedBy").val(),
            "inspectedDate":$("#inspectedDate").val(),
            "verifiedBy": $("#verifiedBy").val(),
            "verifiedDate": $("#verifiedBy").val(),
            "workOrderNumber":$("#workOrderNumber").val(),
            "notes":$("#notes").val(),
            "status": status,
            "taskOrderId":taskOrderId,
            "inspectionType": {
              "id": sessionStorage.getItem("selectedinspectionId"),
              "name":sessionStorage.getItem("selectedinspectionType")
            },
            "result":
            {
              "approvedCertify": "0",
              "inspectionFailed": "0"
            },
            "dispositionOfVehicle":
            {
              "vehicleReturnedServices": "0",
              "vehicleWithdrawnFromService": "0",
              "vehicleSentToAnotherRepairFacility": "0"
            },
            "vehicleDetails":
            {
                "vinNumber": $("#vin").html(),
                "lisencePlateNumber": $("#lisencePlateNumber").html(),
                "id":localStorage.getItem("vehicleId"),
                "unitNumber": $("#lisencePlateNumber").html()
            },
            "gasolineDeliveryTankPressureVacuumTest":
            {
              "pressureTestResults":
              {
                "initialReading": [$("#pressureinitialReading1").val(),$("#pressureinitialReading2").val(),$("#pressureinitialReading3").val()],
                "startingTime": [$("#pressurestartingTime").val(),$("#pressurestartingTime2").val(),$("#pressurestartingTime3").val()],
               //"startingTime": [pressureTest1startTime,pressureTest2startTime,pressureTest3startTime],
                "finalReading":[$("#pressuresfinalReading1").val(),$("#pressuresfinalReading2").val(),$("#pressuresfinalReading3").val()],
                //"finishTime":[pressureTest1finishTime,pressureTest2finishTime,pressureTest3finishTime],
                "finishTime":[$("#pressurefinishTime1").val(),$("#pressurefinishTime2").val(),$("#pressurefinishTime3").val()],
                "reapirsMade":[$("#pressurereapirsMade").val(),$("#pressurereapirsMade2").val(),$("#pressurereapirsMade3").val()]
                },
              
                "vaccumeTestResults":{
                "initialReading": [$("#vaccumeTestinitialReading1").val(),$("#vaccumeTestinitialReading2").val(),$("#vaccumeTestinitialReading3").val()],
                 // "startingTime": [vaccumeTest1startTime,vaccumeTest2startTime,vaccumeTest3startTime],
                   "startingTime": [$("#vaccumeTeststartingTime").val(),$("#vaccumeTeststartingTime2").val(),$("#vaccumeTeststartingTime3").val()],
                  "finalReading":[$("#vaccumeTestfinalReading1").val(),$("#vaccumeTestfinalReading2").val(),$("#vaccumeTestfinalReading3").val()],
                  //"finishTime":[vaccumeTest1finishTime,vaccumeTest2finishTime,vaccumeTest3finishTime],
                   "finishTime":[$("#vaccumeTestfinishTime").val(),$("#vaccumeTestfinishTime2").val(),$("#vaccumeTestfinishTime3").val()],
                  "reapirsMade":[$("#vaccumeTestreapirsMade1").val(),$("#vaccumeTestreapirsMade2").val(),$("#vaccumeTestreapirsMade3").val()]
                },

                "internalVaporValueTest":{
                "initialReading": [$("#internalVaporinitialReading").val(),$("#internalVaporinitialReading2").val(),$("#internalVaporinitialReading3").val()],
                 // "startingTime": [vaporValveTeststartTime,vaporValveTest2startTime,vaporValveTest3startTime],
                  "startingTime": [$("#internalVaporValueTeststartingTime").val(),$("#internalVaporValueTeststartingTime2").val(),$("#internalVaporValueTeststartingTime3").val()],
                  "finalReading":[$("#internalVaporValueTestfinalReading").val(),$("#internalVaporValueTestfinalReading2").val(),$("#internalVaporValueTestfinalReading3").val()],
                  //"finishTime":[vaporValveTestfinishTime,vaporValveTest2finishTime,vaporValveTest3finishTime],
                   "finishTime":[$("#internalVaporValueTestfinishTime").val(),$("#internalVaporValueTestfinishTime2").val(),$("#internalVaporValueTestfinishTime3").val()],
                  "reapirsMade":[$("#internalVaporValueTestreapirsMade").val(),$("#internalVaporValueTestreapirsMade2").val(),$("#internalVaporValueTestreapirsMade3").val()]
                },
                },  
            "documentList": documentList
          
        }; 
  
        $("#loadingforsubmit").show();
        // setLoading(true);
        // const requestOptions = 
        // {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json'
        //             },
                    
        //       body: JSON.stringify(requestObject)
        // };
        var requestOptions=requestObject.RequestHeader(requestObjectInspection);
        services.addInspection(requestOptions).then((res) => 
        {
          // setLoading(false);
          $("#loadingforsubmit").hide();
          Swal.fire({  
            position: 'center',  
            icon: 'success',  
            title: "",  
            text:res.message,
            showConfirmButton: true 
            
          }).then((result) => 
          {
                
                if(result.isConfirmed) 
                {
                  navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));
                
                }
          });
        });
  
    }
  return(
  <>
     
                {LoadingComponentForSubmit}
                  <div class="row mb-2">
                     <div class="col-lg-12 col-md-12 m-auto ">
                        <div class="card">

                           
                          
                            {/* <div class="divider"></div> */}
                            <div class="row px-3">
                                <div class="w-100">
                                    <h4 class="form-inspection-title text-center">Gasoline Delivery tank - Pressure Vacuum test results</h4>
                                </div>
                                <div class="col-lg-12 px-0"><WorkOrderNumberDiv/></div> 
                                <div class="">
                                    <div class="inspection-wrap">

                                        <div class="table-4 mb-4">
                                            <div class="p-4 text-center">
                                                <h5 class="text-uppercase" style={{textDecoration: "underline"}}>Pressure Test Results</h5>
                                            </div>
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Run</th>
                                                    <th scope="col">Initial Reading(Inches of water) </th>
                                                    <th scope="col">Starting Time</th>
                                                    <th scope="col">Final Reading(Inches of water) </th>
                                                    <th scope="col">Finish Time</th>
                                                    <th scope="col">Repairs Made</th>
                                                   
                                                  </tr>
                                                </thead>
                                                <tbody>

                                                
                                                  <tr>
                                                    <td>1.</td>
                                                    <td><input type="input" class="form-control" id="pressureinitialReading1" aria-describedby="emailHelp"/></td>
                                                    <td>
                                                    <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}     
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm" selected={pressureTest1startTime} onChange={(date) => setPressureTest1startTime(date)}  id="pressurestartingTime"  class="form-control nt_datepic"/>
                                                     
                                                      </td>
                                                    <td><input type="input" class="form-control" id="pressuresfinalReading1" aria-describedby="emailHelp"/></td>
                                                    <td>

                                             
                                                    <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm" selected={pressureTest1finishTime} onChange={(date) => setPressureTest1finishTime(date)} id="pressurefinishTime1"   class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                      {/* <input type="input" class="form-control" id="pressurefinishTime1" aria-describedby="emailHelp"/>
                                                       */}
                                                      </td>
                                                    <td><input type="input" class="form-control" id="pressurereapirsMade" aria-describedby="emailHelp"/></td>
                                                </tr>

                                                <tr>
                                                    <td>2.</td>
                                                    <td><input type="input" class="form-control" id="pressureinitialReading2" aria-describedby="emailHelp"/></td>
                                                    <td>
                                                    <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm" selected={pressureTest2startTime} onChange={(date) => setPressureTest2startTime(date)} id="pressurestartingTime2" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                      {/* <input type="input" class="form-control" id="pressurestartingTime2" aria-describedby="emailHelp"/> */}
                                                      </td>
                                                    <td><input type="input" class="form-control" id="pressuresfinalReading2" aria-describedby="emailHelp"/></td>
                                                    <td>
                                                    <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm" selected={pressureTest2finishTime} onChange={(date) => setPressureTest2finishTime(date)} id="pressurefinishTime2" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                      {/* <input type="input" class="form-control" id="pressurefinishTime2" aria-describedby="emailHelp"/> */}
                                                      </td>
                                                    <td><input type="input" class="form-control" id="pressurereapirsMade2" aria-describedby="emailHelp"/></td>
                                                </tr>

                                                <tr>
                                                    <td>3.</td>
                                                    <td><input type="input" class="form-control" id="pressureinitialReading3" aria-describedby="emailHelp"/></td>
                                                    <td>
                                                    <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm" selected={pressureTest3startTime} onChange={(date) => setPressureTest3startTime(date)}  type="text" id="pressurestartingTime3" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                      {/* <input type="input" class="form-control" id="pressurestartingTime3" aria-describedby="emailHelp"/> */}
                                                      </td>
                                                    <td><input type="input" class="form-control" id="pressuresfinalReading3" aria-describedby="emailHelp"/></td>
                                                    <td>
                                                    <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm" selected={pressureTest3finishTime} onChange={(date) => setPressureTest3finishTime(date)} id="pressurefinishTime3" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                      {/* <input type="input" class="form-control" id="pressurefinishTime3" aria-describedby="emailHelp"/> */}
                                                      </td>
                                                    <td><input type="input" class="form-control" id="pressurereapirsMade3" aria-describedby="emailHelp"/></td>
                                                </tr>

                                                 
                                                </tbody>
                                              </table>
                                        </div>

                                        <div class="table-4 mb-4">
                                            <div class="p-4 text-center">
                                                <h5 class="text-uppercase" style={{textDecoration: "underline"}}>Vacuum Test Results</h5>
                                            </div>
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Run</th>
                                                    <th scope="col">Initial Reading(Inches of water) </th>
                                                    <th scope="col">Starting Time</th>
                                                    <th scope="col">Final Reading(Inches of water) </th>
                                                    <th scope="col">Finish Time</th>
                                                    <th scope="col">Repairs Made</th>
                                                   
                                                  </tr>
                                                </thead>
                                                <tbody>

                                                
                                                  <tr>
                                                    <td>1.</td>
                                                    <td><input type="input" class="form-control" id="vaccumeTestinitialReading1" aria-describedby="emailHelp"/></td>
                                                    <td>
                                                    <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm" selected={vaccumeTest1startTime} onChange={(date) => setVaccumeTest1startTime(date)} type="text" id="vaccumeTeststartingTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                      {/* <input type="input" class="form-control" id="vaccumeTeststartingTime" aria-describedby="emailHelp"/> */}
                                                      </td>
                                                    <td><input type="input" class="form-control" id="vaccumeTestfinalReading1" aria-describedby="emailHelp"/></td>
                                                    <td>
                                                    <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm" selected={vaccumeTest1finishTime} onChange={(date) => setVaccumeTest1finishTime(date)} type="text" id="vaccumeTestfinishTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                      {/* <input type="input" class="form-control" id="vaccumeTestfinishTime" aria-describedby="emailHelp"/> */}
                                                      
                                                      </td>
                                                    <td><input type="input" class="form-control" id="vaccumeTestreapirsMade1" aria-describedby="emailHelp"/></td>
                                                </tr>

                                                <tr>
                                                    <td>2.</td>
                                                    <td><input type="input" class="form-control" id="vaccumeTestinitialReading2" aria-describedby="emailHelp"/></td>
                                                    <td>
                                                    <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm" selected={vaccumeTest2startTime} onChange={(date) => setVaccumeTest2startTime(date)} type="text" id="vaccumeTeststartingTime2" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                      {/* <input type="input" class="form-control" id="vaccumeTeststartingTime2" aria-describedby="emailHelp"/> */}
                                                      
                                                      </td>
                                                    <td><input type="input" class="form-control" id="vaccumeTestfinalReading2" aria-describedby="emailHelp"/></td>
                                                    <td>
                                                    <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm" selected={vaccumeTest2finishTime} onChange={(date) => setVaccumeTest2finishTime(date)} type="text" id="vaccumeTestfinishTime2" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                      {/* <input type="input" class="form-control" id="vaccumeTestfinishTime2" aria-describedby="emailHelp"/> */}
                                                      </td>
                                                    <td><input type="input" class="form-control" id="vaccumeTestreapirsMade2" aria-describedby="emailHelp"/></td>
                                                </tr>

                                                <tr>
                                                    <td>3.</td>
                                                    <td><input type="input" class="form-control" id="vaccumeTestinitialReading3" aria-describedby="emailHelp"/></td>
                                                    <td>
                                                    <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm" selected={vaccumeTest3startTime} onChange={(date) => setVaccumeTest3startTime(date)} type="text" id="vaccumeTeststartingTime3" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                      {/* <input type="input" class="form-control" id="vaccumeTeststartingTime3" aria-describedby="emailHelp"/> */}
                                                      </td>
                                                    <td><input type="input" class="form-control" id="vaccumeTestfinalReading3" aria-describedby="emailHelp"/></td>
                                                    <td>
                                                    <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm" selected={vaccumeTest3finishTime} onChange={(date) => setVaccumeTest3finishTime(date)} type="text" id="vaccumeTestfinishTime3" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                      {/* <input type="input" class="form-control" id="vaccumeTestfinishTime3" aria-describedby="emailHelp"/> */}
                                                      </td>
                                                    <td><input type="input" class="form-control" id="vaccumeTestreapirsMade3" aria-describedby="emailHelp"/></td>
                                                </tr>

                                                 
                                                </tbody>
                                              </table>
                                        </div>

                                        <div class="table-4 mb-4">
                                            <div class="p-4 text-center">
                                                <h5 class="text-uppercase" style={{textDecoration: "underline"}}>Internal vapor valve Test</h5>
                                            </div>
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Run</th>
                                                    <th scope="col">Initial Reading(Inches of water) </th>
                                                    <th scope="col">Starting Time</th>
                                                    <th scope="col">Final Reading(Inches of water) </th>
                                                    <th scope="col">Finish Time</th>
                                                    <th scope="col">Repairs Made</th>
                                                   
                                                  </tr>
                                                </thead>
                                                <tbody>

                                                  <tr>
                                                      <td>1.</td>
                                                      <td><input type="input" class="form-control" id="internalVaporinitialReading" aria-describedby="emailHelp"/></td>
                                                      <td>
                                                      <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm" selected={vaporValveTeststartTime} onChange={(date) => setVaporValveTeststartTime(date)} type="text" id="internalVaporValueTeststartingTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                        {/* <input type="input" class="form-control" id="internalVaporValueTeststartingTime" aria-describedby="emailHelp"/> */}
                                                        </td>
                                                      <td><input type="input" class="form-control" id="internalVaporValueTestfinalReading" aria-describedby="emailHelp"/></td>
                                                      <td>
                                                      <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm" selected={vaporValveTestfinishTime} onChange={(date) => setVaporValveTestfinishTime(date)} type="text" id="internalVaporValueTestfinishTime" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                        {/* <input type="input" class="form-control" id="internalVaporValueTestfinishTime" aria-describedby="emailHelp"/> */}
                                                        </td>
                                                      <td><input type="input" class="form-control" id="internalVaporValueTestreapirsMade" aria-describedby="emailHelp"/></td>
                                                  </tr>

                                                  <tr>
                                                    <td>2.</td>
                                                    <td><input type="input" class="form-control" id="internalVaporinitialReading2" aria-describedby="emailHelp"/></td>
                                                    <td>
                                                    <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm" selected={vaporValveTest2startTime} onChange={(date) => setVaporValveTest2startTime(date)} type="text" id="internalVaporValueTeststartingTime2" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                      {/* <input type="input" class="form-control" id="internalVaporValueTeststartingTime2" aria-describedby="emailHelp"/> */}
                                                      </td>
                                                    <td><input type="input" class="form-control" id="internalVaporValueTestfinalReading2" aria-describedby="emailHelp"/></td>
                                                    <td>
                                                    <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm" selected={vaporValveTest2finishTime} onChange={(date) => setVaporValveTest2finishTime(date)} type="text" id="internalVaporValueTestfinishTime2" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                      {/* <input type="input" class="form-control" id="internalVaporValueTestfinishTime2" aria-describedby="emailHelp"/> */}
                                                      </td>
                                                    <td><input type="input" class="form-control" id="internalVaporValueTestreapirsMade2" aria-describedby="emailHelp"/></td>
                                                </tr>

                                                <tr>
                                                    <td>3.</td>
                                                    <td><input type="input" class="form-control" id="internalVaporinitialReading3" aria-describedby="emailHelp"/></td>
                                                    <td>
                                                    <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm" selected={vaporValveTest3startTime} onChange={(date) => setVaporValveTest3startTime(date)} type="text" id="internalVaporValueTeststartingTime3" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                      {/* <input type="input" class="form-control" id="internalVaporValueTeststartingTime3" aria-describedby="emailHelp"/> */}
                                                      </td>
                                                    <td><input type="input" class="form-control" id="internalVaporValueTestfinalReading3" aria-describedby="emailHelp"/></td>
                                                    <td>
                                                    <DatePicker showTimeSelect
                                                      showTimeSelectOnly
                                                      timeFormat="HH:mm"
                                                      timeIntervals={15}
                                                      timeCaption="Time"
                                                      dateFormat="HH:mm" selected={vaporValveTest3finishTime} onChange={(date) => setVaporValveTest3finishTime(date)} type="text" id="internalVaporValueTestfinishTime3" class="form-contro nt_datepic" placeholder="Select Date" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                      {/* <input type="input" class="form-control" id="internalVaporValueTestfinishTime3" aria-describedby="emailHelp"/> */}
                                                      </td>
                                                    <td><input type="input" class="form-control" id="internalVaporValueTestreapirsMade3" aria-describedby="emailHelp"/></td>
                                                </tr>

                                              

                                                 
                                                </tbody>
                                              </table>
                                        </div>



                                    </div>
                                </div>
                            </div>
                            <div class="divider2"></div>
                            <div class="row py-3">
                            <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName}/>
                            </div>                      
                            <AddInspectionName setEndDate={setEndDate} endDate={endDate} />
                            {/* <div class="divider"></div>
                            <div class="row p-3">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Test Performed by <span class="star">*</span></label>
                                        <input type="text" class="form-control" id="inspectedBy" aria-describedby="emailHelp" placeholder="Enter Name"/>
                                      </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Date <span class="star">*</span></label>
                                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="inspectedDate" />
                                      </div>
                                </div>
                            </div> */}

                            <div class="divider2"></div>
                            <div class="row p-3 pr-4 d-flex justify-content-end" style={{marginBottom:"-20px"}}>
                                <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-secondary mr-1 ">Cancel</a>
                                <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a>
                                <a href="#" class="btn btn-primary mr-1" onClick={() => save("APPROVED PENDING")}>Save</a>
                            </div>
                      
                           </div>
                        </div>
                  </div>
                 
              
  </>
)};

GasolineDeliveryTankPressureVacuumTest.propTypes = {};

GasolineDeliveryTankPressureVacuumTest.defaultProps = {};

export default GasolineDeliveryTankPressureVacuumTest;
