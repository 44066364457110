
import React, { history, useEffect, useState, useMemo } from "react";
import Tableheader from "../../../../utility/tableheader";
import Pagination from "../../../../utility/pagination";
import Search from "../../../../utility/search";
import services from "../../../../services/services";
import LoadSpinner from "../../../../utility/LoadSpinner/LoadSpinner";
import { useLoading } from 'react-hook-loading';
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import $ from 'jquery';
import getcustomerLists from "../../customer/customer_api";
import listVendorsApi from "../../customer/vendor_list";
import { requestObject } from "../../../../utility/requestObject";
//import { useKeycloak } from '@react-keycloak/web';
//import Keycloak from 'keycloak-js';
import Keycloak from "keycloak-js";
import checkAuthentication from "../../../../utility/checkAuthentication";
import { loadingComponent } from "../../../../utility/Loading";
import AddGarage from "../addGarage";
const Garagelist = () => {
  const inlineCss = {
    textAlign: "left"
  }
  const [roles, setRoles] = useState("");
  //const { keycloak } = useKeycloak();
  const [customerList, setCustomerList] = useState([]);
  const navigate = useNavigate();
  const [totalRecors, setTotalRecord] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalpages, settotalPages] = useState();
  const [customerName, setCustomerName] = useState("");
  const [displayPage, setdisplayPage] = useState([]);
  const [ITEMS_PER_PAGE, setitemperpage] = useState(25);
  const [loading, setLoading] = useLoading("Please wait...");
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [inactiveStatus, setInactiveStatus] = useState(true);
  const [listVendors, setListVendors] = useState([]);
  const headers = [
    { name: "DOT Number", field: "dotNumber", sortable: true, thStyle: 'width="40%"', inlineStyle: inlineCss },
    { name: "Business Name", field: "name", sortable: true, inlineStyle: inlineCss },
    { name: "Address", field: "Address", sortable: false, inlineStyle: inlineCss },
    { name: "Email", field: "Email", sortable: false, inlineStyle: inlineCss },
    { name: "Actions" }
  ];
  const onSortingChanges = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

    setSortingField(field);
    setSortingOrder(order);
    listVendorsApi(currentPage, ITEMS_PER_PAGE, customerName, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setListVendors, setTotalRecord, setdisplayPage, field ? field : "", order, "", inactiveStatus);

  };
  var fkAccountId = 1;
  useEffect(() => {
    setRoles(localStorage.getItem("Userroles"));
    if (checkAuthentication) {
      listVendorsApi("1", ITEMS_PER_PAGE, "", ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setListVendors, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "diplayItems", inactiveStatus);
      // $('#display_pages_in_items').on('change', function() {

      //   setitemperpage(this.value);

      //   getcustomerLists("1",ITEMS_PER_PAGE,customerName,this.value*ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setCustomerList,setTotalRecord,setdisplayPage,sortingField,sortingOrder,"");
      // });
      // listVendorsApi(currentPage,ITEMS_PER_PAGE)
    }
    else {
      sessionStorage.clear();
      localStorage.clear();
      //navigate("/login");
      window.location.replace("login");
    }

    // $(document).on("click", "#inactive", function () {
    //   var inactiveCustomer = "";
    //   if ($('input[name="inactive"]').is(':checked')) {
    //     setInactiveStatus(false);
    //     inactiveCustomer = "false";
    //   } else {
    //     setInactiveStatus(true);
    //     inactiveCustomer = "true";
    //   }
    //   alert(customerName)
    //   setCustomerName(customerName);
    //   getcustomerLists("1", ITEMS_PER_PAGE, customerName, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setCustomerList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "diplayItems", inactiveCustomer);
    // });

  }, []);

//   const listVendorsApi=(pageNumber,ITEMS_PER_PAGE,customerName,pageSize,setTotalItems,settotalPages,setCurrentPage,setCustomerList,setTotalRecord,setdisplayPage,sortBy,sortDir,displayItems,activeStatus)=>
// {
//     var listVendorsReq=
//     {
      
//       "pageNumber" :currentPage,
//       "pageSize"  :ITEMS_PER_PAGE ,
//       "isActive" : true

//     };

//  var requestOptions=requestObject.RequestHeader(listVendorsReq);
//  services.listVendors(requestOptions).then((res) => 
//  {
//      console.log("@@@@@vendors list@@@@@@"+res.rows);
//     setListVendors(res.rows);
//  });
// }

  var activestatus="false";
  const activeInctive = (event) => {

      if(event.target.checked)
      {

      }else{
          activestatus="true";
      }
      listVendorsApi("1", ITEMS_PER_PAGE, customerName, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setListVendors, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "diplayItems", activestatus);

      setInactiveStatus(activestatus);
  }


  const redirect = (page) => {

    navigate(page);

  }
  // const getcustomerLists=(pageNumber,ITEMS_PER_PAGE,customerName,pageSize)=>
  // {
  //   
  //     var customerListReq=
  //     {
  //
  //       "customerName":customerName,
  //       "pageNumber":pageNumber,
  //       "pageSize":pageSize,
  //       "sortBy": "",
  //       "sortDir":""
  //     };


  //   console.log("results="+JSON.stringify(customerListReq));

  //   const requestOptions =
  //   {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json'
  //               },

  //       body: JSON.stringify(customerListReq)
  //   };

  //   services.listofcustomers(requestOptions).then((res) =>
  //   {
  //    // setLoading(false);
  //     setIsLoaded(currentIsLoaded => !currentIsLoaded);
  //     if(res.success)
  //     {
  //       setTotalItems(res.customValues.totalNumberOfRecords);

  //       settotalPages(res.customValues.totalNumberOfRecords);

  //       setCurrentPage(res.customValues.currentPageNumber);
  //       setCustomerList(res.rows);
  //       setTotalRecord(res.customValues.totalNumberOfRecords);
  //       var displaypages=[];
  //       for(var i=1;i<=parseInt(Math.ceil(res.customValues.totalNumberOfRecords/ITEMS_PER_PAGE));i++)
  //       {
  //         displaypages.push(i);
  //       }

  //       setdisplayPage(displaypages);


  //     }else{

  //       //alert(res.message);

  //     }

  //   })

  // }
  const searchList = (customerName) => {
    setCustomerName(customerName);
    listVendorsApi("1", ITEMS_PER_PAGE, customerName, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setListVendors, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "diplayItems", inactiveStatus);

  }
  const handlePageChange = (offset, triggerevent) => {

    listVendorsApi(offset, ITEMS_PER_PAGE, customerName, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setListVendors, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "", inactiveStatus);

  };
  const displayPageNumbers = (e) => {

    setitemperpage(e.target.value);

    listVendorsApi("1", ITEMS_PER_PAGE, customerName, e.target.value, setTotalItems, settotalPages, setCurrentPage, setListVendors, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "", inactiveStatus);
  }

  const addNewGarage = () => {

    $("#firstName").val("");
    $("#email").val("");
    $("#deskNumber").val("");
    $("#ext").val("");
    $("#mobileNumber").val("");
    $("#address1").val("");
    $("#address2").val("");
    $("#city").val("");
    $("#state").val("");
    $("#zipcode").val("");
    $("#dotNumber").val("");
    $("#ctnumber").val("");
}

  return (
    <>
      {loadingComponent}
      <div class="main-panel">
        <div class="content-wrapper" style={{ display: "block;" }}>
          <div class="row">
            <div class="col-lg-11 col-md-12 m-auto mb-2 ">
              <h4 class="font-weight-bold my-4 text-uppercase">Vendors</h4>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-11 col-md-12 m-auto grid-margin stretch-card">
              <div class="card">
                <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                  {/* <div class="search position-relative align-self-start mb-1 mt-1"> */}
                  <div class="d-flex justify-content-start">
                    {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search by name / Company" class="pl-5"/> */}
                    <div class="search position-relative">
                      <Search
                        placeholderName="Search by business name"
                        onSearch={value => {
                          searchList(value);
                        }}

                      />
                      <a href="#" class="search-icon">
                        <img src="/images/icons/search.svg" alt="" />
                      </a>
                    </div>
                    {roles.match("Driver") || roles.match("Dispatcher") ? "" : <> <input type="checkbox"
                
                      onChange={(e) => activeInctive(e) }
                      id="inactive"
                      name="inactive"
                      style={{ marginLeft: "21px", marginTop: "4px" }} />
                    <span style={{ marginLeft: "10px", marginTop: "15px" }}>
                    Inactive records only
                    </span></>}
                   
                  </div>
                  <div class="d-flex align-self-start mb-1 mt-1">
                    {roles.match("Driver") || roles.match("Dispatcher") ? "" :  <a class="btn btn-success" data-toggle="modal" data-target="#addGarrage"
                                                                onClick={() =>
                                                                    addNewGarage()
                                                                }>Add Vendor</a>}
                  </div>
                </div>
                <div class="card-body pt-0 pb-0">

                  <div class="row">
                    <div class="col-12">
                      <div class="table-responsive border-top border-bottom">
                        <table class="table custome-table-mn">
                          <thead>
                            <tr>
                              {headers.map(({ name, field, sortable, thStyle, inlineStyle }) => (
                                <th
                                  key={name}
                                  onClick={() =>
                                    sortable ? onSortingChanges(field) : null
                                  }
                                  class={thStyle} style={inlineStyle}>
                                  {name}

                                  {sortingField && sortingField === field && (

                                    sortingOrder === "asc"
                                      ? "↑"
                                      : "↓"
                                  )}

                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {
                              listVendors ? listVendors.map((data, index) => (
                                <tr>
                                  <td class="text-capitalize">{data.dotNumber ? data.dotNumber : "--"}</td>
                                  <td class="text-capitalize">{data.name ? data.name : "--"} </td>
                                  <td style={{ maxWidth: "100px", whiteSpace: "break-spaces", lineHeight: "20px" }}> <span>{data ? data.addressLine1 : ""}{data ? data.addressLine1 ? "," : "" : ""}<span>&nbsp;
                                    </span>{data ? data.addressLine2 : ""}{data ? data.addressLine2 ? "," : "" : ""}<span>
                                        &nbsp;</span><br />{data ? data.city : ""}{data ? data.city ? "," : "" : ""}<span> <span>
                                          {/* {data ? data.stateName : ""}{data ? data.stateName ? "," : "" : ""} */}
                                          </span>
                                        &nbsp;</span>{data ? data.zipCode : ""}{data ? data.zipCode ? "." : "" : ""}</span></td>
                                  <td style={{ maxWidth: "200px", whiteSpace: "break-spaces", lineHeight: "20px" }}>{data.email ? data.email : "--"}</td>
                                  <td style={{ "width": "50px" }}><a onClick={() => redirect("/fleetowner/garageDetails/" + data.id + "/")} class="btn btn-outline-primary text-uppercase">View</a></td>
                                </tr>
                              )) : ""

                            }

                          </tbody>
                        </table>

                      </div>
                    </div>
                    {listVendors ? listVendors.map((data, index) => { }) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                    </div>}
                  </div>
                </div>

                <div class="d-flex align-items-center justify-content-between p-3">
                  <div class="d-flex">
                    <div class="dropdown show mr-3 ">
                      <select class="form-control custome-select btn gray-mid-color dropdown-toggle" href="#" role="button" id="display_pages_in_items" onChange={e => displayPageNumbers(e)} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {

                          displayPage.map((data, index) => (
                            <option value={data}>{data}</option>
                          ))
                        }
                      </select>
                      {/* <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                         {ITEMS_PER_PAGE}
                        </a>

                        <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                          {
                            displayPage.map((data, index) => (

                            <a class="dropdown-item" href="#">{data}</a>

                            ))
                            }
                        </div> */}
                    </div>
                    <span class="d-flex align-items-center pagenation-mob">{currentPage * ITEMS_PER_PAGE <= totalRecors ? currentPage * ITEMS_PER_PAGE : totalRecors} out of {totalRecors} Records Displayed</span>
                  </div>

                  <Pagination
                    total={totalRecors}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    //onPageChange={page => setCurrentPage(page)}
                    onPageChange={handlePageChange}
                  />
                  {/* <ul class="pagination mb-0">
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                              <span class="sr-only">Previous</span>
                            </a>
                          </li>
                          <li class="page-item"><a class="page-link" href="#">1</a></li>
                          <li class="page-item"><a class="page-link" href="#">2</a></li>
                          <li class="page-item"><a class="page-link" href="#">3</a></li>
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                              <span class="sr-only">Next</span>
                            </a>
                          </li>
                        </ul> */}

                </div>

              </div>

            </div>

          </div>
        </div>

      </div>
      <AddGarage />
    </>
  )
};

Garagelist.propTypes = {};

Garagelist.defaultProps = {};

export default Garagelist;
