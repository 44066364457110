import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import AddInspectionName from "../../../../utility/addInspectionName";
import Adddocs from "../../../../utility/Adddocs";
import removePdf from "../../../../utility/removePdf";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const InternalVisualEdit = () => {
   var totalGallons = 0;
   var jsonStr = [];
   const { taskOrderId, fkAccountId, inspectionId, vid } = useParams();
   const [inputList, setInputList] = useState([{}]);
   const [base64file, setBase64file] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const [fileName, setfileName] = useState("");
   const [inspectionDetails, setInspectionDetails] = useState("");
   const [documentList, setDocumentLists] = useState([]);
   const [inspectionTypeId, setinspectionTypeId] = useState("");
   const navigate = useNavigate();
   const [endDate, setEndDate] = useState(new Date());
   const [compartmentsLen, setcompartmentLength] = useState();
   const [compartments, setcompartments] = useState();
   var documentLists = [];
   const [roles, setRoles] = useState("");
   const [statusInspection, setStatus] = useState("");
   const [verifiedDATE, setverifiedDate] = useState(new Date());

   const [expiryDate, setexpiryDate] = useState(new Date());
   useEffect(() => {
      setRoles(localStorage.getItem("Userroles"));

      // vehicle_details_api(id, setVehicleDetails, setCustomerId); 
      getInpection(fkAccountId, inspectionId)
   }, []);
   var workingLen = [];
   function getInpection(fkAccountId, inspectionId) {
      var requestObjectForWet =
      {

         "id": inspectionId
      }

      var requestOptions = requestObject.RequestHeader(requestObjectForWet);

      services.getInspectionDetails(requestOptions).then((res) => {

         setInspectionDetails(res.rows);
         setStatus(res.rows.status);

         if (res.rows) {
            if (res.rows.verifiedDate) {
               setverifiedDate(new Date(res.rows ? res.rows.verifiedDate : ''));
            }
         }

         setDocumentLists(res.rows.documentList);
         setinspectionTypeId(res.rows.inspectionType.id);
         setcompartments(res.rows.internalVisualInspection ? res.rows.internalVisualInspection.compartmentList : "");
         sessionStorage.setItem("documentList", JSON.stringify(res.rows.documentList));
         setcompartmentLength(res.rows.internalVisualInspection ? res.rows.internalVisualInspection.compartmentList.length : "");
         if (res.rows.internalVisualInspection ? res.rows.internalVisualInspection.compartmentList : "" !== "") {
            for (var i = 0; i < res.rows.internalVisualInspection.compartmentList.length; i++) {
               if (res.rows.internalVisualInspection.compartmentList[i].working == "1") {
                  workingLen.push(res.rows.internalVisualInspection.compartmentList[i].working);
                  totalGallons += parseFloat(res.rows.internalVisualInspection.compartmentList[i].safeFillAmountInGals);
               }


            }
            $("#allValues").val(totalGallons);
         }
         if (res.rows.internalVisualInspection.compartmentList.length == workingLen.length) {
            $('#checkAll').prop('checked', true);
         }
         documentLists.push(res.rows.documentList);
         $("#workOrderNumber").val(res.rows ? res.rows.workOrderNumber : "");
         $("#notes").val(res.rows ? res.rows.notes : "");
         $("#inspectedBy").val(res.rows ? res.rows.inspectedBy : "");
         setEndDate(new Date(res.rows ? res.rows.inspectedDate : ""));
         if (res.rows) {

            if (res.rows.expiryDate) {
               setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
            }
         }
      });

   }
   var sum = 0;
   $('#checkAll').click(function () {
      var checked = $(this).is(":checked");
      if (checked) {
         sum = 0;
         for (var i = 0; i < compartmentsLen; i++) {
            $('input[name=sec' + i + 'Distortion').prop('checked', this.checked);
            var comp = $("#comp" + i + "gallons").val();
            sum += parseFloat(comp);


         }

         $("#allValues").val(sum);
      }
      else {
         sum = 0;
         for (var i = 0; i < compartmentsLen; i++) {
            $('input[name=sec' + i + 'Distortion').prop('checked', checked);

         }
         $("#allValues").val("");
      }

   });
   const NumericOnly = (e) => { //angka only
      const reg = /^[0-9\b]+$/
      let preval = e.target.value
      if (e.target.value === '' || reg.test(e.target.value)) return true
      else e.target.value = preval.substring(0, (preval.length - 1))
   }
   const handleChange = (e, index) => {

      let isChecked = e.target.checked;
      $('#checkAll').prop('checked', false);

      if (isChecked) {
         $('input[name=sec' + index + 'Distortion').prop('checked', isChecked);
         var comp = $("#comp" + index + "gallons").val();
         sum += parseFloat(comp);
         $("#allValues").val(Math.abs(sum));
      } else {

         var comp = $("#comp" + index + "gallons").val();
         sum -= parseFloat(comp);
         $("#allValues").val(Math.abs(sum));
      }



   }
   const textChange = (e, index) => {

      sum = 0;
      for (var i = 0; i < compartments.length; i++) {

         var comp = $("#comp" + i + "gallons").val();
         sum += parseFloat(comp);


      }
      if (isNaN(Math.abs(sum))) {
         $("#allValues").val("")
      } else {
         $("#allValues").val(Math.abs(sum))
      }



   }
   const redirect = (page) => {

      navigate(page);

   }
   const save = (status) => {

      // sessionStorage.setItem("verifiedBy",$("#verifiedBy").val());

      for (var i = 0; i < compartments.length; i++) {

         jsonStr.push({
            "working": $("input[name=sec" + (i) + "Distortion]:checked").val() === "on" ? "1" : "0",
            "safeFillAmountInGals": $("#comp" + (i) + "gallons").val() ? $("#comp" + (i) + "gallons").val() : "0",
         })
      }

      try {
         for (var i = 0; i < inputList.length; i++) {
            // console.log("filebase64=="+inputList[i].filetobase64);
            var extension = "";
            var byteString = "";
            if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
               extension = "pdf";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
            } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
               extension = "jpg";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
               extension = "png";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/msword;base64,")) {
               extension = "word";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/msword;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:image/bmp;base64,")) {
               extension = "bmp";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/bmp;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/docx;base64,")) {
               extension = "docx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/docx;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/doc;base64,")) {
               extension = "docx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/doc;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/docx;base64,")) {
               extension = "docx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/docx;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/doc;base64,")) {
               extension = "docx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/doc;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,")) {
               extension = "docx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,")) {
               extension = "docm";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,")) {
               extension = "docx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,")) {
               extension = "dotx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,")) {
               extension = "dotx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,")) {
               extension = "dotm";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", "") : "";
            }
            else {

            }


            documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
         }
      } catch (exception) {
         // documentList.push();
         // Swal.fire({  
         //     position: 'center',  
         //     icon: 'error',  
         //     title: "",  
         //     text:"Please attach document",
         //     showConfirmButton: true 

         //   }).then((result) => 
         //   {

         //         if(result.isConfirmed) 
         //         {


         //         }
         //   });
         //   return false;
      }
      // console.log("results=="+JSON.stringify(inputList));

      try {
         if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {

            var i;
            var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
            for (i = 0; i < storedArray.length; i++) {

               documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
            }

         } else {

            documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
         }

      } catch (ex) {

      }
      if ($("#inspectedBy").val() === "") {
         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Please enter Inspected by",
            showConfirmButton: true

         }).then((result) => {

            if (result.isConfirmed) {


            }
         });
         return false;
      }

      var requestObjectInspection =
      {
         "id": inspectionId,
         "expiryDate": $("#expiryDate").val(),
         "type": "Internal Visual Inspection",
         "workOrderNumber": $("#workOrderNumber").val(),
         "notes": $("#notes").val(),
         "inspectedBy": $("#inspectedBy").val(),
         "inspectedDate": $("#inspectedDate").val(),
         "verifiedBy": $("#verifiedBy").val(),
         "verifiedDate": $("#verifiedByBate").val(),
         "status": status,
         "taskOrderId": taskOrderId,
         "inspectionType": {
            "id": inspectionTypeId,
            "name": "Internal Visual Inspection"
         },
         "result":
         {
            "approvedCertify": "0",
            "inspectionFailed": "0"
         },
         "dispositionOfVehicle":
         {
            "vehicleReturnedServices": "0",
            "vehicleWithdrawnFromService": "0",
            "vehicleSentToAnotherRepairFacility": "0"
         },
         // "result": {
         //    "approvedCertify": $("input[name='approvedCertify']:checked").val() === "approvedCertify" ? "1" : "0",
         //    "inspectionFailed": $("input[name='approvedCertify']:checked").val() === "inspectionFailed" ? "1" : "0"
         // },
         // "dispositionOfVehicle": {
         //    "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
         //    "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
         //    "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
         // },
         "vehicleDetails":
         {
            "vinNumber": $("#vin").html(),
            "lisencePlateNumber": $("#lisencePlateNumber").html(),
            "id": localStorage.getItem("vehicleId"),
            "unitNumber": $("#lisencePlateNumber").html()
         },
         "internalVisualInspection": {
            "section1": {
               "corroded": $("input[name='sec1corroded']:checked").val() === "1" ? "1" : "0",
               "dents": $("input[name='sec1Dents']:checked").val() === "1" ? "1" : "0",
               "distortions": $("input[name='secinternalDistortion']:checked").val() === "1" ? "1" : "0",
               "defectsInWelds": $("input[name='sec1Welds']:checked").val() === "1" ? "1" : "0",
               "leaksOfAnyKind": $("input[name='sec1Kind']:checked").val() === "1" ? "1" : "0",
               "defectInBaffels": $("input[name='sec1baffles']:checked").val() === "1" ? "1" : "0",
               "defectInInterios": $("input[name='sec1emergency']:checked").val() === "1" ? "1" : "0",
               "defectInLining": $("input[name='sec1lining']:checked").val() === "1" ? "1" : "0",
               "defectIndiptube": $("input[name='sec1tube']:checked").val() === "1" ? "1" : "0",
               "defectInCleanoutPorts": $("input[name='sec1nozzles']:checked").val() === "1" ? "1" : "0",
               "anyConditionThatmightRender": $("input[name='sec1unsafe']:checked").val() === "1" ? "1" : "0",
               "transportationService": $("input[name='sec1service']:checked").val() === "1" ? "1" : "0"
            },
            "section2": {
               "corroded": $("input[name='sec2corroded']:checked").val() === "1" ? "1" : "0",
               "dents": $("input[name='sec2Dents']:checked").val() === "1" ? "1" : "0",
               "distortions": $("input[name='secinternalDistortion2']:checked").val() === "1" ? "1" : "0",
               "defectsInWelds": $("input[name='sec2Welds']:checked").val() === "1" ? "1" : "0",
               "leaksOfAnyKind": $("input[name='sec2kind']:checked").val() === "1" ? "1" : "0",
               "anyConditionThatmightRender": $("input[name='sec2service']:checked").val() === "1" ? "1" : "0",
               "tankMarking": $("input[name='sec2tank']:checked").val() === "1" ? "1" : "0"
               // "notes": $("input[name='notes']").val()!=""?$("input[name='notes']").val():""
            },
            "compartmentList": jsonStr,
         },

         "documentList": documentLists


      };
      var requestOptionsObject = requestObject.RequestHeader(requestObjectInspection);

      //  setLoading(true);
      if (statusInspection == "DRAFT" || status == "DRAFT") {
         // setLoading(true);
         $("#loadingforsubmit").show();
         services.addInspection(requestOptionsObject).then((res) => {
            // setLoading(false);
            $("#loadingforsubmit").hide();
            Swal.fire({
               position: 'center',
               icon: 'success',
               title: "",
               text: "Your changes have been successfully saved!",
               showConfirmButton: true

            }).then((result) => {

               if (result.isConfirmed) {
                  navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));
               }
            });
         });
      } else {

         if ($('input:radio[name="returnedservice"]:checked').val() === "undefined" || $('input:radio[name="returnedservice"]:checked').val() === undefined) {
            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: "Please select disposition of tank",
               showConfirmButton: true

            }).then((result) => {

               if (result.isConfirmed) {

               }
            });
            return false;
         }
         if ($('input:radio[name="approvedCertify"]:checked').val() === "" || $('input:radio[name="approvedCertify"]:checked').val() === undefined) {
            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: "Please select inspection result",
               showConfirmButton: true

            }).then((result) => {

               if (result.isConfirmed) {


               }
            });
            return false;
         }
         if ($("input[name='approvedCertify']:checked").val() === "approvedCertify") {

         } else {

            status = "Rejected";
         }

         var requestObjectInspection =
         {
            "id": inspectionId,
            "expiryDate": $("#expiryDate").val(),
            "verifiedBy": $("#verifiedBy").val(),
            "verifiedDate": $("#verifiedByBate").val(),
            "status": status,
            "taskOrderId": taskOrderId,
            "result": {
               "approvedCertify": $("input[name='approvedCertify']:checked").val() === "approvedCertify" ? "1" : "0",
               "inspectionFailed": $("input[name='approvedCertify']:checked").val() === "inspectionFailed" ? "1" : "0"
            },
            "dispositionOfVehicle": {
               "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
               "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
               "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
            }

         };
         // setLoading(true);
         $("#loadingforsubmit").show();
         services.addInspection(requestOptionsObject).then((res) => {
            // setLoading(false);
            if (res.success) {

               //certify inspection
               var requestOptionsCertify = requestObject.RequestHeader(requestObjectInspection);
               services.certifyInspection(requestOptionsCertify).then((res) => {
                  // setLoading(false);
                  $("#loadingforsubmit").hide();
                  Swal.fire({
                     position: 'center',
                     icon: 'success',
                     title: "",
                     text: res.message,
                     showConfirmButton: true

                  }).then((result) => {

                     if (result.isConfirmed) {
                        navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

                     }
                  });
               });
            } else {
               Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: "",
                  text: "Your changes have been successfully saved!",
                  showConfirmButton: true

               }).then((result) => {

                  if (result.isConfirmed) {
                     navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));
                  }
               });

            }
         });



      }

   }

   return (
      <>
         {LoadingComponentForSubmit}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block" }}>
               <div class="row mb-0">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <div class="d-flex justify-content-between align-items-center my-4">
                     <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                     </div>
                  </div>
               </div>
               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                     <HeaderBlock cids={fkAccountId} />
                  </div>
               </div>



               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                     <div class="card">

                        <div class="row mb-2">
                           <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                              <VehicleBasicInfo customerId={vid} />
                           </div>
                        </div>


                        <div class="divider"></div>
                        <div class="row p-3">
                           <div class="col-lg-12">
                              <h4 class="mb-4 form-inspection-title text-center">Internal Visual Inspection Report</h4>
                           </div>
                           <div class="col-lg-12"><WorkOrderNumberDiv /></div>
                           <div class="w-100">
                              <div class="inspection-wrap">

                                 <div class="table-2 mb-4">
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col" style={{ "width": "50%" }}>Compartment Tested/Inspected </th>
                                             <th scope="col" style={{ "width": "40%" }}>Safe fill amount(GALS)</th>
                                             <th scope="col"><input type="checkbox" placeholder="" onChange={NumericOnly} id="checkAll" /><span class="mx-2 mt-2">ALL</span></th>
                                          </tr>
                                       </thead>
                                       <tbody>

                                          {
                                             compartments ? compartments.map((data, index) => (
                                                <tr>
                                                   <td>
                                                      <div class="row align-items-center">
                                                         <div class="col-md-4">Compartment #{index + 1} </div>

                                                      </div>
                                                   </td>
                                                   <td>

                                                      <div class="col-md-4"><input type="text" class="form-control" id={"comp" + index + "gallons"} aria-describedby="emailHelp" placeholder="Capacity NNNN Gallons" defaultValue={data.safeFillAmountInGals} onChange={e => textChange(e, index)} /></div>

                                                   </td>
                                                   {(() => {
                                                      if (data.working == "1") {

                                                         return (<><td><input type="checkbox" aria-label="Radio button for following text input" name={"sec" + (index) + "Distortion"} defaultChecked={true} onChange={e => handleChange(e, index)} /></td></>)
                                                      } else {
                                                         return (<><td><input type="checkbox" aria-label="Radio button for following text input" name={"sec" + (index) + "Distortion"} defaultChecked={false} onChange={e => handleChange(e, index)} /></td></>)
                                                      }
                                                   })()}

                                                </tr>
                                             )) : ""

                                          }

                                          <td>Total in Gallons</td> <td><div class="col-md-4"><input type="text" class="form-control" id="allValues" disabled /></div></td>

                                       </tbody>
                                    </table>
                                 </div>

                              </div>
                           </div>

                           <div class="col-lg-6">
                              <div class="inspection-wrap">

                                 <div class="table-2 mb-4">
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col">Section 1 Tank Shell/Heads are there any?</th>
                                             <th scope="col">Yes</th>
                                             <th scope="col">No</th>
                                          </tr>
                                       </thead>
                                       <tbody>

                                          <tr>
                                             <td>Corroded or abraded areas</td>
                                             {(() => {
                                                if (inspectionDetails.internalVisualInspection) {
                                                   if (inspectionDetails.internalVisualInspection.section1.corroded == '1') {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1corroded" value="1" defaultChecked={true} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1corroded" value="0" defaultChecked={false} /></td></>);
                                                   } else {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1corroded" value="1" defaultChecked={false} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1corroded" value="0" defaultChecked={true} /></td></>);
                                                   }
                                                } else {

                                                }

                                             })()}
                                          </tr>

                                          <tr>
                                             <td>Dents</td>
                                             {(() => {
                                                if (inspectionDetails.internalVisualInspection) {
                                                   if (inspectionDetails.internalVisualInspection.section1.dents == '1') {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1Dents" value="1" defaultChecked={true} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1Dents" value="0" /></td></>);
                                                   } else {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1Dents" value="1" /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1Dents" value="0" defaultChecked={true} /></td></>);
                                                   }
                                                } else {

                                                }

                                             })()}

                                          </tr>

                                          <tr>
                                             <td>Distortions</td>
                                             {(() => {
                                                if (inspectionDetails.internalVisualInspection) {
                                                   if (inspectionDetails.internalVisualInspection.section1.distortions == '1') {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="secinternalDistortion" value="1" defaultChecked={true} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="secinternalDistortion" value="0" /></td></>);
                                                   } else {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="secinternalDistortion" value="1" /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="secinternalDistortion" value="0" defaultChecked={true} /></td></>);
                                                   }
                                                } else {

                                                }

                                             })()}

                                          </tr>

                                          <tr>
                                             <td>Defects in Welds</td>
                                             {(() => {
                                                if (inspectionDetails.internalVisualInspection) {
                                                   if (inspectionDetails.internalVisualInspection.section1.defectsInWelds == '1') {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1Welds" value="1" defaultChecked={true} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1Welds" value="0" /></td></>);
                                                   } else {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1Welds" value="1" /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1Welds" value="0" defaultChecked={true} /></td></>);
                                                   }
                                                } else {

                                                }

                                             })()}

                                          </tr>

                                          <tr>
                                             <td>Leaks of any kind</td>
                                             {(() => {
                                                if (inspectionDetails.internalVisualInspection) {
                                                   if (inspectionDetails.internalVisualInspection.section1.leaksOfAnyKind == '1') {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1Kind" value="1" defaultChecked={true} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1Kind" value="0" /></td></>);
                                                   } else {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1Kind" value="1" /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1Kind" value="0" defaultChecked={true} /></td></>);
                                                   }
                                                } else {

                                                }

                                             })()}

                                          </tr>

                                          <tr>
                                             <td>Defects in baffles</td>
                                             {(() => {
                                                if (inspectionDetails.internalVisualInspection) {
                                                   if (inspectionDetails.internalVisualInspection.section1.defectInBaffels == '1') {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1baffles" value="1" defaultChecked={true} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1baffles" value="0" /></td></>);
                                                   } else {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1baffles" value="1" /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1baffles" value="0" defaultChecked={true} /></td></>);
                                                   }
                                                } else {

                                                }

                                             })()}

                                          </tr>

                                          <tr>
                                             <td>Defects in interior emergency valves</td>
                                             {(() => {
                                                if (inspectionDetails.internalVisualInspection) {
                                                   if (inspectionDetails.internalVisualInspection.section1.defectInInterios == '1') {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1emergency" value="1" defaultChecked={true} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1emergency" value="0" /></td></>);
                                                   } else {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1emergency" value="1" /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1emergency" value="0" defaultChecked={true} /></td></>);
                                                   }
                                                } else {

                                                }

                                             })()}

                                          </tr>

                                          <tr>
                                             <td>Defects in lining</td>
                                             {(() => {
                                                if (inspectionDetails.internalVisualInspection) {
                                                   if (inspectionDetails.internalVisualInspection.section1.defectInLining == '1') {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1lining" value="1" defaultChecked={true} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1lining" value="0" /></td></>);
                                                   } else {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1lining" value="1" /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1lining" value="0" defaultChecked={true} /></td></>);
                                                   }
                                                } else {

                                                }

                                             })()}

                                          </tr>

                                          <tr>
                                             <td>Defects in dip tube</td>
                                             {(() => {
                                                if (inspectionDetails.internalVisualInspection) {
                                                   if (inspectionDetails.internalVisualInspection.section1.defectIndiptube == '1') {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1tube" value="1" defaultChecked={true} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1tube" value="0" /></td></>);
                                                   } else {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1tube" value="1" /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1tube" value="0" defaultChecked={true} /></td></>);
                                                   }
                                                } else {

                                                }

                                             })()}

                                          </tr>

                                          <tr>
                                             <td>Defects in cleanout ports/nozzles</td>
                                             {(() => {
                                                if (inspectionDetails.internalVisualInspection) {
                                                   if (inspectionDetails.internalVisualInspection.section1.defectInCleanoutPorts == '1') {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1nozzles" value="1" defaultChecked={true} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1nozzles" value="0" /></td></>);
                                                   } else {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1nozzles" value="1" /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1nozzles" value="0" defaultChecked={true} /></td></>);
                                                   }
                                                } else {

                                                }

                                             })()}

                                          </tr>

                                          <tr>
                                             <td>Any condition that might render tank unsafe for transportation service</td>
                                             {(() => {
                                                if (inspectionDetails.internalVisualInspection) {
                                                   if (inspectionDetails.internalVisualInspection.section1.anyConditionThatmightRender == '1') {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1unsafe" value="1" defaultChecked={true} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1unsafe" value="0" /></td></>);
                                                   } else {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1unsafe" value="1" /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec1unsafe" value="0" defaultChecked={true} /></td></>);
                                                   }
                                                } else {

                                                }

                                             })()}

                                          </tr>

                                          {/* <tr>
                                                        <td>transportation service</td>
                                                        { (() => 
                                                         {
                                                            if(inspectionDetails.internalVisualInspection)
                                                            {
                                                               if(inspectionDetails.internalVisualInspection.section2.corroded=='1')
                                                               {
                                                                  return(<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1service" value="1" defaultChecked={true} /></td><td class="text-center">  
                                                                  <input type="radio" aria-label="Radio button for following text input" name="sec1service" value="0"/></td></>);
                                                               }else{
                                                                  return(<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec1service" value="1"/></td><td class="text-center">  
                                                                  <input type="radio" aria-label="Radio button for following text input" name="sec1service" value="0" defaultChecked={true}  /></td></>);
                                                               }
                                                            }else{

                                                            }
                                                       
                                                         })()}
                                                        
                                                       </tr> */}
                                       </tbody>
                                    </table>
                                 </div>

                              </div>
                           </div>

                           <div class="col-lg-6">
                              <div class="inspection-wrap">

                                 <div class="table-2 mb-4">
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col">Section 2 Tank Shell/Head areas covered by the UC plate, are there any?</th>
                                             <th scope="col">Yes</th>
                                             <th scope="col">No</th>
                                          </tr>
                                       </thead>
                                       <tbody>

                                          <tr>
                                             <td>Corroded or abraded areas</td>
                                             {(() => {
                                                if (inspectionDetails.internalVisualInspection) {
                                                   if (inspectionDetails.internalVisualInspection.section2.corroded == '1') {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec2corroded" value="1" defaultChecked={true} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec2corroded" value="0" /></td></>);
                                                   } else {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec2corroded" value="1" /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec2corroded" value="0" defaultChecked={true} /></td></>);
                                                   }
                                                } else {

                                                }

                                             })()}

                                          </tr>

                                          <tr>
                                             <td>Dents</td>
                                             {(() => {
                                                if (inspectionDetails.internalVisualInspection) {
                                                   if (inspectionDetails.internalVisualInspection.section2.dents == '1') {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec2Dents" value="1" defaultChecked={true} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec2Dents" value="0" /></td></>);
                                                   } else {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec2Dents" value="1" /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec2Dents" value="0" defaultChecked={true} /></td></>);
                                                   }
                                                } else {

                                                }

                                             })()}

                                          </tr>

                                          <tr>
                                             <td>Distortions</td>
                                             {(() => {
                                                if (inspectionDetails.internalVisualInspection) {
                                                   if (inspectionDetails.internalVisualInspection.section2.distortions == '1') {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="secinternalDistortion2" value="1" defaultChecked={true} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="secinternalDistortion2" value="0" /></td></>);
                                                   } else {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="secinternalDistortion2" value="1" /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="secinternalDistortion2" value="0" defaultChecked={true} /></td></>);
                                                   }
                                                } else {

                                                }

                                             })()}

                                          </tr>

                                          <tr>
                                             <td>Defects in Welds</td>
                                             {(() => {
                                                if (inspectionDetails.internalVisualInspection) {
                                                   if (inspectionDetails.internalVisualInspection.section2.defectsInWelds == '1') {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec2Welds" value="1" defaultChecked={true} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec2Welds" value="0" /></td></>);
                                                   } else {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec2Welds" value="1" /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec2Welds" value="0" defaultChecked={true} /></td></>);
                                                   }
                                                } else {

                                                }

                                             })()}

                                          </tr>

                                          <tr>
                                             <td>Leak of any kind</td>
                                             {(() => {
                                                if (inspectionDetails.internalVisualInspection) {
                                                   if (inspectionDetails.internalVisualInspection.section2.leaksOfAnyKind == '1') {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec2kind" value="1" defaultChecked={true} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec2kind" value="0" /></td></>);
                                                   } else {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec2kind" value="1" /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec2kind" value="0" defaultChecked={true} /></td></>);
                                                   }
                                                } else {

                                                }

                                             })()}

                                          </tr>

                                          <tr>
                                             <td>Any condition that might render tank unsafe for transportation service   </td>
                                             {(() => {
                                                if (inspectionDetails.internalVisualInspection) {
                                                   if (inspectionDetails.internalVisualInspection.section2.anyConditionThatmightRender == '1') {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec2service" value="1" defaultChecked={true} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec2service" value="0" /></td></>);
                                                   } else {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec2service" value="1" /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec2service" value="0" defaultChecked={true} /></td></>);
                                                   }
                                                } else {

                                                }

                                             })()}

                                          </tr>

                                          <tr>
                                             <td>"Tank marking: Date (month/Year) and service symbol after all defects are corrected. Did you update markings on tank                                                        </td>
                                             {(() => {
                                                if (inspectionDetails.internalVisualInspection) {
                                                   if (inspectionDetails.internalVisualInspection.section2.tankMarking == '1') {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec2tank" value="1" defaultChecked={true} /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec2tank" value="0" /></td></>);
                                                   } else {
                                                      return (<><td class="text-center"><input type="radio" aria-label="Radio button for following text input" name="sec2tank" value="1" /></td><td class="text-center">
                                                         <input type="radio" aria-label="Radio button for following text input" name="sec2tank" value="0" defaultChecked={true} /></td></>);
                                                   }
                                                } else {

                                                }

                                             })()}


                                          </tr>


                                       </tbody>
                                    </table>
                                 </div>

                              </div>
                           </div>
                        </div>
                        <div class="divider"></div>

                        <div class="form-footer-comon-wrap">
                           <div class="row documents p-3">
                              <div class="col-lg-12">
                                 {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                 <div class="row">
                                    {
                                       documentList ? documentList.map((data, index) => (
                                          <div class="col-md-12" style={{ marginTop: "-18px" }}>
                                             <div class="align-items-center">
                                                <img src="images/icons/pdf-icon.svg" alt="" />

                                                {/* <b>{data.title}.{data.extension}</b> */}
                                                <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                   <img src="" class="mr-2" />
                                                   <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                      <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100"> {(() => {
                                                         if (data.extension == 'pdf') {
                                                            return (
                                                               <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                            )
                                                         } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                            return (
                                                               <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                            )
                                                         } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                            return (
                                                               <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                            )
                                                         } else {
                                                            return (
                                                               <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                            )
                                                         }
                                                      })()}
                                                         {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                         {data.name ? data.name : "--"}  </a></p></span>
                                                   <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" /> </div>

                                                {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                             </div>
                                          </div>


                                       )) : ""

                                    }
                                 </div>
                              </div><br />

                           </div>
                           <div class="row p-3">
                              <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                           </div>

                           <div class="divider"></div>

                        </div>
                        <AddInspectionName setEndDate={setEndDate} endDate={endDate} />
                        {statusInspection == "DRAFT" ? "" : <div class="divider"></div>}
                        {(() => {
                           if (statusInspection == "DRAFT") {
                              <></>
                           } else {
                              if (!roles.match("Technician")) {
                                 if (inspectionDetails.status == 'approved' || inspectionDetails.status == 'approve' || inspectionDetails.status == 'APPROVED' || inspectionDetails.status == 'Rejected' || inspectionDetails.status == 'REJECTED') {
                                    return (
                                       <>
                                          <div class="result-didspos">
                                             <div class="p-4">
                                                <span>Result</span>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Inspection Failed</b>
                                                </div>
                                             </div>
                                             <div class="p-4">
                                                <span>Disposition of Tank</span>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : ""}
                                                   <b class="mb-0 ml-2">Tank Returned to Service</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                                </div>
                                             </div>
                                          </div>
                                          <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection} customerId={fkAccountId} />
                                          {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} status={"readOnly"} customerId={fkAccountId}/> */}
                                       </>
                                    )
                                 } else {
                                    return (
                                       <><div class="result-didspos ">
                                          <div class="p-4">
                                             <span>Result</span>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                             </div>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Inspection Failed</b>
                                             </div>
                                          </div>
                                          <div class="p-4">
                                             <span>Disposition of Tank</span>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Tank returned to service</b>
                                             </div>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                             </div>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                             </div>
                                          </div>
                                       </div>
                                          <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} customerId={fkAccountId} />
                                          {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} customerId={fkAccountId}/> */}


                                       </>
                                    )
                                 }
                              }
                           }
                        })()}
                        <div class="divider"></div>
                        <div class="row p-3 pr-4 d-flex justify-content-end" >
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
                           {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a> */}
                           {statusInspection == "DRAFT" ? <button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("DRAFT")}>Save as Draft</button> : ""}


                           {(() => {
                              if (statusInspection == "DRAFT") {
                                 return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("APPROVED PENDING")}>Save</button></>)
                              } else {
                                 if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
                                    return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("approved")}>Save</button></>)
                                 }
                                 else {
                                    return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("APPROVED PENDING")}>Save</button></>)
                                 }
                              }
                           })()}
                        </div>
                     </div>
                  </div>
               </div>

            </div>


         </div>
      </>
   )
};

InternalVisualEdit.propTypes = {};

InternalVisualEdit.defaultProps = {};

export default InternalVisualEdit;
