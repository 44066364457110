import React,{useEffect, useState, useMemo } from "react";
import DatePicker from "react-datepicker";
import  $ from "jquery";
import moment from "moment";
const AddInspectionName = ({setEndDate, endDate}) => 
{
    
     
  
      useEffect(() => {
            $("#inspectedBy").val(sessionStorage.getItem("customerName"));
            
         }, []);

      return(<> 
      

                  <div class="divider2"></div>
                     <div class="row p-3">
                        <div class="col-lg-6">
                           <div class="form-group">
                              <label for="exampleInputEmail1">Test performed by<span class="star">*</span></label>
                              <input type="text" class="form-control" id="inspectedBy" aria-describedby="emailHelp" placeholder="Enter Name" readOnly/>
                           </div>
                        </div>
                        <div class="col-lg-6">
                           <div class="form-group">
                              <label for="exampleInputEmail1">Date<span class="star">*</span></label>
                              <DatePicker  selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="inspectedDate" />
                           </div>
                        </div>
                      
      

                     </div>
      </>)
}

AddInspectionName.propTypes = {};

AddInspectionName.defaultProps = {};

export default AddInspectionName;