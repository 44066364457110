import React, { useEffect, useState, useMemo } from "react";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { requestObject } from "./requestObject";
import services from "../services/services";
import ListOfContacts from "../modules/components/customer/CustomerContactList";
import moment from "moment";
const VerifiedBy = ({ setEndDate, endDate,setExpiryDate,expiryDATE,status,statusInspection, customerId }) => {
   const [CustomerContactList, setCustomerContactList] = useState([]);

   useEffect(() => {
      // $("#verifiedBy").val(sessionStorage.getItem("loggedinUser"));
      // $("#verifiedBy").val(sessionStorage.getItem("verifiedBy"));
      $("#verifiedBy").val(sessionStorage.getItem("customerName"));
      // ListOfContacts(customerId, setCustomerContactList);
      // getContactList();
     

   }, []);
   if (status == "readOnly") {
      $("#verifiedBy").attr('disabled', 'disabled');
      $("#verifiedByBate").attr('true', 'true');
      status = true;

   } else {
      status = false;
   }

   // const getContactList = () => {

   //    var contactRequest =
   //    {
   //       "fkCustomerId": customerId,
   //       "firstName": "",
   //       "lastName": "",
   //       "pageNumber": 1,
   //       "pageSize": 25,
   //       "sortBy": "",
   //       "isActive": "true",
   //       "fkContactRoleId": "",
   //       "sortDir": "",
   //       "isPrimaryAndAccountContactsOnly": "false"
   //    };

   //    var requestOptions = requestObject.RequestHeader(contactRequest);
   //    services.getContactList(requestOptions).then((res) => {
   //       setCustomerContactList(res.rows);
   //    });

   // }

   return (<>
      <div class="divider"></div>
      <div class="row verifyby p-4">
         <div class="col-4">
            <div class="form-group">
               <label for="exampleInputEmail1">Verified By</label>
              {/* {
                  (() => {
                     if (sessionStorage.getItem("verifiedBy") != null) {
                        return (
                           <input type="text" class="form-control custome-input" id="verifiedBy" aria-describedby="emailHelp" placeholder="Enter Name" readOnly/>
                        )
                     }else {
                        return (
                           <select class="form-control custome-select" id="verifiedBy">
                           <option value='' selected>Select</option>
                           {
                             
                              CustomerContactList ? CustomerContactList.map((data, index) => (
                                 <option value={data.firstName}>{data.firstName}</option>
                              )) : <option value=""></option>
                           }
         
                        </select> 
                        )
                     }
                  })()}   */}
                  
                                         <input type="text" class="form-control custome-input" id="verifiedBy" aria-describedby="emailHelp" placeholder="Enter Name" readOnly/>

                {/* <select class="form-control custome-select" id="verifiedBy">
                   <option value='' selected>Select</option>
                   {
                      // CustomerContactList.length > 0 ? CustomerContactList.map((data, index) => (
                      CustomerContactList ? CustomerContactList.map((data, index) => (
                         <option value={data.firstName}>{data.firstName}</option>
                      )) : <option value=""></option>
                   }

               </select> */}
            </div>
         </div>
         <div class="col-2">
            <div class="form-group">
               <label for="exampleInputEmail1">Start date</label>
               <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="verifiedByBate" readOnly={status} />
               {/* <input type="text" class="form-control custome-input" id="verifiedByBate" aria-describedby="emailHelp" placeholder="MM/DD/YYYY"/> */}
            </div>
            
         </div>
         {statusInspection == "Rejected"?"":
         <><div class="d-flex align-items-center">
         <div class="form-group">
               <label for="exampleInputEmail1"></label>
               <span className="d-flex align-items-center" style={{marginLeft:"-15px"}}>To</span>
               </div>
         </div>
         
         <div class="col-2">
            <div class="form-group">
               <label for="exampleInputEmail1">Expiry Date</label>
               <DatePicker  minDate={moment(endDate).toDate()} selected={expiryDATE} onChange={(date) => setExpiryDate(date)} class="form-control" id="expiryDate" readOnly={status} />
               {/* <input type="text" class="form-control custome-input" id="verifiedByBate" aria-describedby="emailHelp" placeholder="MM/DD/YYYY"/> */}
            </div>
         </div></>}
      </div>
   </>)
}

VerifiedBy.propTypes = {};

VerifiedBy.defaultProps = {};

export default VerifiedBy;