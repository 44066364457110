import React, { history, useEffect, useState, useMemo } from "react";
import { requestObject } from "../../../utility/requestObject";
import services from "../../../services/services";
import { useNavigate, useParams } from "react-router-dom";
import $ from "jquery";
import moment from 'moment'
import CheckAuthentication from "../../../utility/checkAuthentication";
import { listTaskOrders } from "../customer/CustomerContactList";
import { Technician,Inspector, Manager,Owner } from "../../constant";
import { Completed } from "../../constant";
import { getInspectionType } from "../customer/CustomerContactList";
import getcustomerLists from "../customer/customer_api";
import Pagination from "../../../utility/pagination";
import inspectionOrderList from "../inspection/inspectionOrderList";
const GarageDashboard = () => {
   const [roles, setRoles] = useState("");
   const [dashboardAggregationData, setdashBoardAgreggation] = useState("");
   const [totalpages1, settotalPages1] = useState();
   const [totalItems1, setTotalItems1] = useState(0);
   const [customerName, setCustomerName] = useState("");
   const [ITEMS_PER_PAGE, setitemperpage] = useState(10);
   const [ITEMS_PER_PAGE1, setitemperpage1] = useState(10);
   const [sortingField, setSortingField] = useState("");
   const [totalItems, setTotalItems] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const [displayPage1, setdisplayPage1] = useState([]);
   const [totalpages, settotalPages] = useState();
   const [displayPage, setdisplayPage] = useState([]);
   const [TotalTaskOrder, setTotalTaskOrder] = useState([]);
   const [currentPage1, setCurrentPage1] = useState(1);
   const [customerList, setCustomerList] = useState([]);
   const [sortingOrder, setSortingOrder] = useState("");
   const [DashboardAggregation, setgetDashboardAggregation] = useState([]);
   const listOfInspectionTypes = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
   const [totalRecors1, setTotalRecord1] = useState([]);
   const [upcomingOverdueList, setUpcomingOverdue] = useState([]);
   const [inspectionTypes, setInspectionTypes] = useState();
   const [inspectioname, setInspectionName] = useState("");
   const [totalInspectionList, setTotalInspectionList] = useState(0);
   const inlineFw =
   {
      width: "30%"
   }

   const inlineTw =
   {
      width: "20%"
   }

   const handlePageChange = (offset) => {
      setCurrentPage(offset);
      listTaskOrders(offset, ITEMS_PER_PAGE, setTotalTaskOrder, setTotalTaskList, setdisplayPageForInspection);


   }
   // const [currentDocsPage, setCurrentDocsPage] = useState(5);
   // const [totaldocspages, settotalDocsPages] = useState();
   const [totalRecors, setTotalRecord] = useState([]);
   // const [totalDocsItems, setTotalDocsItems] = useState(0);

   const [ITEMS_PER_PAGE_DOCS, setitemperdocspage] = useState(25);

   const [inspectionLists, setInspectionList] = useState("");
   const [customerCount, setCustomer] = useState();
   const [vehicleCount, setVehicle] = useState();
   const [documentCount, setDocument] = useState();
   const [displayPages, setdisplayPageForInspection] = useState([]);
   const [TaskList, setTotalTaskList] = useState([]);
   const navigate = useNavigate();
   let navigation = useNavigate();
   const [totalInspectionPages, settotalinspectionPages] = useState(0);
   const [inspectioCurrentPage, setInspectionCurrentPage] = useState(0);

   ////////////inspection details////////////////////////////////////
   const inspection_details = (taskOrderId, inspectionTypeNname, inspectionStatus, fkAccountId, id, vid, cid) => {

      fkAccountId = cid;
      localStorage.setItem("customerId", cid);
      localStorage.setItem("vehicleId", vid);
      sessionStorage.setItem("naviationfrom", "addinspection");
      if (inspectionTypeNname.match("Cargo Tank Thickness Test")) {

         // if (inspectionStatus === "DRAFT") {
         //    navigation("/cargoTankThicknessTestEdit/" + fkAccountId + '/' + id + '/' + vid);
         // } else {
         //    navigation("/cargoTankThicknessTestRead/" + fkAccountId + '/' + id + '/' + vid);
         // }

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/cargoTankThicknessTestRead/" + fkAccountId + '/' + id + '/' + vid)
            )
         } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

            return (
               navigation("/cargoTankThicknessTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }
         else if (inspectionStatus == null) {

            addInspection(taskOrderId, inspectionTypeNname, vid, cid);
         }
         else if (roles.match("Technician")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/cargoTankThicknessTestRead/" + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/cargoTankThicknessTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else {
            return (
               navigation("/cargoTankThicknessTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }


      } else if (inspectionTypeNname.match("WET Test Certification")) {

         // if (inspectionStatus === "DRAFT") {
         //    navigation("/wetTestCertificationEdit/" + fkAccountId + '/' + id + '/' + vid);
         // } else {
         //    navigation("/wetTestCertificationRead/" + fkAccountId + '/' + id + '/' + vid);
         // }

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/wetTestCertificationRead/" + fkAccountId + '/' + id + '/' + vid)
            )
         } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

            return (
               navigation("/wetTestCertificationEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }
         else if (inspectionStatus == null) {

            addInspection(taskOrderId, inspectionTypeNname, vid, cid);
         }

         else if (roles.match("Technician")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/wetTestCertificationRead/" + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/wetTestCertificationEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else {
            return (
               navigation("/wetTestCertificationEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }

      } else if (inspectionTypeNname == "Tractor PM Test" || inspectionTypeNname ==  "Tractor PM/Inspection" || inspectionTypeNname ==  "Tractor Preventive Maintenance/Inspection") {

         // if (inspectionStatus === "DRAFT") {
         //    navigation("/tractorpmTestEdit/" + fkAccountId + '/' + id + '/' + vid);
         // } else {
         //    navigation("/tractorpmTestRead/" + fkAccountId + '/' + id + '/' + vid);
         // }

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/tractorpmTestRead/" + fkAccountId + '/' + id + '/' + vid)
            )
         } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

            return (
               navigation("/tractorpmTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }
         else if (roles.match("Technician")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/tractorpmTestRead/" + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/tractorpmTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else {
            return (
               navigation("/tractorpmTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }

      } else if (inspectionTypeNname == "Service Check List") {

         // if (inspectionStatus === "DRAFT") {
         //    navigation("/serviceCheckListEdit/" + fkAccountId + '/' + id + '/' + vid);
         // } else {
         //    navigation("/serviceCheckListRead/" + fkAccountId + '/' + id + '/' + vid);
         // }

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/serviceCheckListRead/" + fkAccountId + '/' + id + '/' + vid)
            )
         } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

            return (
               navigation("/serviceCheckListEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }
         else if (roles.match("Technician")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/serviceCheckListRead/" + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/serviceCheckListEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else {
            return (
               navigation("/serviceCheckListEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }

      } else if (inspectionTypeNname == "Driver Vehicle Inspection") {

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/driverVehicleTestRead/" + fkAccountId + '/' + id + '/' + vid)
            )
         } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

            return (
               navigation("/driverVehicleTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }
         else if (roles.match("Technician")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/driverVehicleTestRead/" + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/driverVehicleTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else {
            return (
               navigation("/driverVehicleTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }

      } else if (inspectionTypeNname == "Leakage Test") {

         // if (inspectionStatus === "DRAFT") {
         //    navigation("/leakageTestEdit/" + fkAccountId + '/' + id + '/' + vid);
         // } else {
         //    navigation("/leakageTestRead/" + fkAccountId + '/' + id + '/' + vid);
         // }

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/leakageTestRead/" + fkAccountId + '/' + id + '/' + vid)
            )
         } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

            return (
               navigation("/leakageTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }
         else if (roles.match("Technician")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/leakageTestRead/" + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/leakageTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else {
            return (
               navigation("/leakageTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }

      } else if (inspectionTypeNname == "Leakage Test (Method 27)") {

         // if (inspectionStatus === "DRAFT") {
         //    navigation("/leakageTestEdit/" + fkAccountId + '/' + id + '/' + vid);
         // } else {
         //    navigation("/leakageTestRead/" + fkAccountId + '/' + id + '/' + vid);
         // }

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/leakageTestMethod27Read/" + fkAccountId + '/' + id + '/' + vid)
            )
         } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

            return (
               navigation("/leakageTestMethod27Edit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }
         else if (roles.match("Technician")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/leakageTestMethod27Read/" + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/leakageTestMethod27Edit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else {
            return (
               navigation("/leakageTestMethod27Edit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }

      }
      else if (inspectionTypeNname.match("DOT Cargo Tank Test") || inspectionTypeNname.match("DOT Cargo Tank") || inspectionTypeNname.match("DOT Cargo") || inspectionTypeNname.match("Ohio EPA")) {
         // if (inspectionStatus === "DRAFT") {
         //    navigation("/dotCargoEdit/" + fkAccountId + '/' + id + '/' + vid);
         // } else {
         //    navigation("/dotCargoRead/" + fkAccountId + '/' + id + '/' + vid);
         // }

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/dotCargoRead/" + fkAccountId + '/' + id + '/' + vid)
            )
         } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

            return (
               navigation("/dotCargoEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }

         else if (roles.match("Technician")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/dotCargoRead/" + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/dotCargoEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else {
            return (
               navigation("/dotCargoEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }


      }
      else if (inspectionTypeNname.match("Internal Visual Inspection") || inspectionTypeNname.match("Internal Visual")) {

         // if (inspectionStatus === "DRAFT") {
         //    navigation("/internalVisualEdit/" + fkAccountId + '/' + id + '/' + vid);
         // } else {
         //    navigation("/internalVisualRead/" + fkAccountId + '/' + id + '/' + vid);
         // }

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/internalVisualRead/" + fkAccountId + '/' + id + '/' + vid)
            )
         } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

            return (
               navigation("/internalVisualEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }
         else if (roles.match("Technician")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/internalVisualRead/" + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/internalVisualEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else {
            return (
               navigation("/internalVisualEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }

      }
      else if (inspectionTypeNname.match("Pressure test - Retest Inspection") || inspectionTypeNname.match("Pressure Vaccum test - Certificate application") || inspectionTypeNname.match("Pressure Test/Retest Inspection")) {

         // if (inspectionStatus === "DRAFT") {
         //    navigation("/pressureTestEdit/" + fkAccountId + '/' + id + '/' + vid);
         // }
         // else {
         //    navigation("/pressureTestRead/" + fkAccountId + '/' + id + '/' + vid);
         // }

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/pressureTestRead/" + fkAccountId + '/' + id + '/' + vid)
            )
         } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

            return (
               navigation("/pressureTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }
         else if (roles.match("Technician")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/pressureTestRead/" + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/pressureTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else {
            return (
               navigation("/pressureTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }

      }
      else if (inspectionTypeNname.match("Carrier Equipment Inspection")) {
         if (inspectionStatus === "DRAFT") {
            navigation("/carrierEquipmentEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid);
         }
         else {
            navigation("/carrierEquipmentRead/" + fkAccountId + '/' + id + '/' + vid);
         }
      }
      else if (inspectionTypeNname.match("Annual Vehicle Inspection")) {
         // if (inspectionStatus === "DRAFT") {
         //    navigation("/annualVehicle1Edit/" + fkAccountId + '/' + id + '/' + vid);
         // }
         // else {
         //    navigation("/annualVehicleRead/" + fkAccountId + '/' + id + '/' + vid);
         // }
         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/annualVehicleRead/" + fkAccountId + '/' + id + '/' + vid)
            )
         } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

            return (
               navigation("/annualVehicle1Edit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }
         else if (roles.match("Technician")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/annualVehicleRead/" + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/annualVehicle1Edit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else {
            return (
               navigation("/annualVehicle1Edit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }

      }
      else if (inspectionTypeNname.match("External Visual Inspection") || inspectionTypeNname.match("External/Visual Inspection")) {
         // if (inspectionStatus === "DRAFT") {
         //    navigation("/editExternalVisual/" + fkAccountId + '/' + id + '/' + vid);
         // }
         // else {
         //    navigation("/externalVisualRead/" + fkAccountId + '/' + id + '/' + vid);
         // }

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/externalVisualRead/" + fkAccountId + '/' + id + '/' + vid)
            )
         } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

            return (
               navigation("/editExternalVisual/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }
         else if (roles.match("Technician")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/externalVisualRead/" + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/editExternalVisual/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else {
            return (
               navigation("/editExternalVisual/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }

      } else if (inspectionTypeNname.match("Gassoline Delivery Tank - Pressure Vaccum Test")) {
         if (inspectionStatus === "DRAFT") {
            navigation("/gasolineDeliveryTankPressureVacuumTestEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/gasolineDeliveryTankPressureVacuumTestRead/" + fkAccountId + '/' + id + '/' + vid);
         }
         //  navigation("/gasolineDeliveryTankPressureVacuumTestRead/" + fkAccountId + '/' + id + '/' + vid);
      } else if (inspectionTypeNname.match("Vapor Tightness Certification")) {

         // if (inspectionStatus === "DRAFT") {
         //    navigation("/inspectionVaporTightnessCertificateEdit/" + fkAccountId + '/' + id + '/' + vid);
         // } else {
         //    navigation("/inspectionVaporTightnessCertificateRead/" + fkAccountId + '/' + id + '/' + vid);
         // }
         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/inspectionVaporTightnessCertificateRead/" + fkAccountId + '/' + id + '/' + vid)
            )
         } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

            return (
               navigation("/inspectionVaporTightnessCertificateEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }
         else if (roles.match("Technician")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/inspectionVaporTightnessCertificateRead/" + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
               return (
                  navigation("/inspectionVaporTightnessCertificateEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
               )
            }
            else if (inspectionStatus == null) {

               addInspection(taskOrderId, inspectionTypeNname, vid, cid);
            }
         } else {
            return (
               navigation("/inspectionVaporTightnessCertificateEdit/" + taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }
      }


   }

   // const inspection_details = (taskOrderId,inspectionTypeNname, inspectionStatus, fkAccountId, id, vid, cid) => {
   //    localStorage.setItem("customerId", cid);
   //    localStorage.setItem("vehicleId", vid);
   //    sessionStorage.setItem("naviationfrom", "addinspection");
   //    if (inspectionTypeNname.match("Cargo Tank Thickness Test")) {

   //       // if (inspectionStatus === "DRAFT") {
   //       //    navigation("/cargoTankThicknessTestEdit/" + fkAccountId + '/' + id + '/' + vid);
   //       // } else {
   //       //    navigation("/cargoTankThicknessTestRead/" + fkAccountId + '/' + id + '/' + vid);
   //       // }

   //       if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
   //          return (
   //             navigation("/cargoTankThicknessTestRead/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

   //          return (
   //             navigation("/cargoTankThicknessTestEdit/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }
   //       else if (inspectionStatus == null) {

   //          addInspection(inspectionTypeNname, vid, cid);
   //       }
   //       else if (roles.match("Technician")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/cargoTankThicknessTestRead/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/cargoTankThicknessTestEdit/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else {
   //          return (
   //             navigation("/cargoTankThicknessTestEdit/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }


   //    } else if (inspectionTypeNname.match("WET Test Certification")) {

   //       // if (inspectionStatus === "DRAFT") {
   //       //    navigation("/wetTestCertificationEdit/" + fkAccountId + '/' + id + '/' + vid);
   //       // } else {
   //       //    navigation("/wetTestCertificationRead/" + fkAccountId + '/' + id + '/' + vid);
   //       // }

   //       if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
   //          return (
   //             navigation("/wetTestCertificationRead/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

   //          return (
   //             navigation("/wetTestCertificationEdit/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }
   //       else if (inspectionStatus == null) {

   //          addInspection(inspectionTypeNname, vid, cid);
   //       }

   //       else if (roles.match("Technician")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/wetTestCertificationRead/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/wetTestCertificationEdit/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else {
   //          return (
   //             navigation("/wetTestCertificationEdit/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }

   //    } else if (inspectionTypeNname.match("Leakage Test")) {

   //       // if (inspectionStatus === "DRAFT") {
   //       //    navigation("/leakageTestEdit/" + fkAccountId + '/' + id + '/' + vid);
   //       // } else {
   //       //    navigation("/leakageTestRead/" + fkAccountId + '/' + id + '/' + vid);
   //       // }

   //       if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
   //          return (
   //             navigation("/leakageTestRead/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

   //          return (
   //             navigation("/leakageTestEdit/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }
   //       else if (roles.match("Technician")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/leakageTestRead/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/leakageTestEdit/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else {
   //          return (
   //             navigation("/leakageTestEdit/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }

   //    }
   //    else if (inspectionTypeNname.match("Leakage Test (Method 27)")) {

   //       if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
   //          return (
   //             navigation("/leakageTestMethod27Read/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

   //          return (
   //             navigation("/leakageTestMethod27Edit/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }
   //       else if (roles.match("Technician")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/leakageTestMethod27Read/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/leakageTestMethod27Edit/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else {
   //          return (
   //             navigation("/leakageTestMethod27Edit/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }

   //    }
   //    else if (inspectionTypeNname.match("DOT Cargo Tank Test") || inspectionTypeNname.match("DOT Cargo Tank") || inspectionTypeNname.match("DOT Cargo") || inspectionTypeNname.match("Ohio EPA")) {
   //       // if (inspectionStatus === "DRAFT") {
   //       //    navigation("/dotCargoEdit/" + fkAccountId + '/' + id + '/' + vid);
   //       // } else {
   //       //    navigation("/dotCargoRead/" + fkAccountId + '/' + id + '/' + vid);
   //       // }

   //       if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
   //          return (
   //             navigation("/dotCargoRead/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

   //          return (
   //             navigation("/dotCargoEdit/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }

   //       else if (roles.match("Technician")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/dotCargoRead/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/dotCargoEdit/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else {
   //          return (
   //             navigation("/dotCargoEdit/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }


   //    }
   //    else if (inspectionTypeNname.match("Internal Visual Inspection") || inspectionTypeNname.match("Internal Visual")) {

   //       // if (inspectionStatus === "DRAFT") {
   //       //    navigation("/internalVisualEdit/" + fkAccountId + '/' + id + '/' + vid);
   //       // } else {
   //       //    navigation("/internalVisualRead/" + fkAccountId + '/' + id + '/' + vid);
   //       // }

   //       if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
   //          return (
   //             navigation("/internalVisualRead/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

   //          return (
   //             navigation("/internalVisualEdit/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }
   //       else if (roles.match("Technician")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/internalVisualRead/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/internalVisualEdit/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else {
   //          return (
   //             navigation("/internalVisualEdit/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }

   //    }
   //    else if (inspectionTypeNname.match("Pressure test - Retest Inspection") || inspectionTypeNname.match("Pressure Vaccum test - Certificate application") || inspectionTypeNname.match("Pressure Test/Retest Inspection")) {

   //       // if (inspectionStatus === "DRAFT") {
   //       //    navigation("/pressureTestEdit/" + fkAccountId + '/' + id + '/' + vid);
   //       // }
   //       // else {
   //       //    navigation("/pressureTestRead/" + fkAccountId + '/' + id + '/' + vid);
   //       // }

   //       if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
   //          return (
   //             navigation("/pressureTestRead/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

   //          return (
   //             navigation("/pressureTestEdit/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }
   //       else if (roles.match("Technician")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/pressureTestRead/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/pressureTestEdit/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else {
   //          return (
   //             navigation("/pressureTestEdit/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }

   //    }
   //    else if (inspectionTypeNname.match("Carrier Equipment Inspection")) {
   //       if (inspectionStatus === "DRAFT") {
   //          navigation("/carrierEquipmentEdit/" + fkAccountId + '/' + id + '/' + vid);
   //       }
   //       else {
   //          navigation("/carrierEquipmentRead/" + fkAccountId + '/' + id + '/' + vid);
   //       }
   //    }
   //    else if (inspectionTypeNname.match("Annual Vehicle Inspection")) {
   //       // if (inspectionStatus === "DRAFT") {
   //       //    navigation("/annualVehicle1Edit/" + fkAccountId + '/' + id + '/' + vid);
   //       // }
   //       // else {
   //       //    navigation("/annualVehicleRead/" + fkAccountId + '/' + id + '/' + vid);
   //       // }
   //       if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
   //          return (
   //             navigation("/annualVehicleRead/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

   //          return (
   //             navigation("/annualVehicle1Edit/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }
   //       else if (roles.match("Technician")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/annualVehicleRead/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/annualVehicle1Edit/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else {
   //          return (
   //             navigation("/annualVehicle1Edit/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }

   //    }
   //    else if (inspectionTypeNname.match("External Visual Inspection") || inspectionTypeNname.match("External/Visual Inspection")) {
   //       // if (inspectionStatus === "DRAFT") {
   //       //    navigation("/editExternalVisual/" + fkAccountId + '/' + id + '/' + vid);
   //       // }
   //       // else {
   //       //    navigation("/externalVisualRead/" + fkAccountId + '/' + id + '/' + vid);
   //       // }

   //       if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
   //          return (
   //             navigation("/externalVisualRead/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

   //          return (
   //             navigation("/editExternalVisual/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }
   //       else if (roles.match("Technician")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/externalVisualRead/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/editExternalVisual/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else {
   //          return (
   //             navigation("/editExternalVisual/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }

   //    } else if (inspectionTypeNname.match("Gassoline Delivery Tank - Pressure Vaccum Test")) {
   //       if (inspectionStatus === "DRAFT") {
   //          navigation("/gasolineDeliveryTankPressureVacuumTestEdit/" + fkAccountId + '/' + id + '/' + vid);
   //       } else {
   //          navigation("/gasolineDeliveryTankPressureVacuumTestRead/" + fkAccountId + '/' + id + '/' + vid);
   //       }
   //       //  navigation("/gasolineDeliveryTankPressureVacuumTestRead/" + fkAccountId + '/' + id + '/' + vid);
   //    } else if (inspectionTypeNname.match("Vapor Tightness Certification")) {

   //       // if (inspectionStatus === "DRAFT") {
   //       //    navigation("/inspectionVaporTightnessCertificateEdit/" + fkAccountId + '/' + id + '/' + vid);
   //       // } else {
   //       //    navigation("/inspectionVaporTightnessCertificateRead/" + fkAccountId + '/' + id + '/' + vid);
   //       // }
   //       if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
   //          return (
   //             navigation("/inspectionVaporTightnessCertificateRead/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       } else if (inspectionStatus == "DRAFT" || inspectionStatus == "draft") {

   //          return (
   //             navigation("/inspectionVaporTightnessCertificateEdit/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }
   //       else if (roles.match("Technician")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/inspectionVaporTightnessCertificateRead/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else if (roles.match("Manager") || roles.match("Owner") || roles.match("Inspector")) {
   //          if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
   //             return (
   //                navigation("/inspectionVaporTightnessCertificateEdit/" + fkAccountId + '/' + id + '/' + vid)
   //             )
   //          }
   //          else if (inspectionStatus == null) {

   //             addInspection(inspectionTypeNname, vid, cid);
   //          }
   //       } else {
   //          return (
   //             navigation("/inspectionVaporTightnessCertificateEdit/" + fkAccountId + '/' + id + '/' + vid)
   //          )
   //       }
   //    }


   // }
   const scrollingElement = (document.scrollingElement || document.body);
   const inlineEw =
   {
      textAlign: "left",
      width: "10%",
      paddingRight: "10px"
   }
   const headers =
      [
         { name: "Task order name", field: "Task order name", sortable: true, inlineStyle: inlineFw },
         { name: "Customer name", field: "Customer name", sortable: true, inlineStyle: inlineTw },
         { name: "Unit Number", field: "Unit number", sortable: true, inlineStyle: inlineEw },
         { name: "VIN Number", field: "VIN Number", sortable: true, inlineStyle: inlineEw },
         { name: "Assigned date", field: "Assigned date", sortable: true, inlineStyle: inlineEw },
         { name: "Status", inlineStyle: inlineEw },
         { name: "Actions", inlineStyle: inlineEw }
      ];
   const inlineCss =
   {
      textAlign: "left"
   }
   const inlineCssTd = {
      whiteSpace: "breakSpaces",
      maxWidth: "150px"
   }
   const onSortingChangeDocument = (field) => {

      const order =
         field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

      setSortingField(field);
      setSortingOrder(order);
      //  veh_docs(id, currentPage, ITEMS_PER_PAGE_DOCS, "", field, sortingOrder, "");

   };
   const Inspectionheaders =
      [
         { name: "Customer Name", field: "Customer Name", sortable: false, inlineStyle: inlineCss, inlineStyle: inlineCssTd },
         { name: "Unit Number", field: "Unit number", sortable: false, inlineStyle: inlineCss, inlineStyle: inlineCssTd },
         { name: "VIN Number", field: "VIN Number", sortable: false, inlineStyle: inlineCss, inlineStyle: inlineCssTd },
         { name: "Inspection", field: "Inspection Title", sortable: false, inlineStyle: inlineCss, inlineStyle: inlineCssTd },

         { name: "Inspected On", field: "Inspected On", sortable: true, inlineStyle: inlineCss },
         // { name: "Inspected By", field: "Inspected By", sortable: true, inlineStyle: inlineCss },
         { name: "Status", field: "Status", sortable: true, inlineStyle: inlineCss },
         { name: "Actions" }
      ];

   const addInspection = (taskOrderId, inspectionName, vehicleId, customerId) => {

      localStorage.setItem("customerId", customerId);
      localStorage.setItem("vehicleId", vehicleId);
      sessionStorage.setItem("taskOrderfromInspection", inspectionName);

      navigation("/addInspection/" + taskOrderId + '/' + customerId + '/' + vehicleId);

   }
   const scrollSmoothToBottom = () => {
      // alert("333");
      // $(scrollingElement).animate({
      //    scrollTop: document.body.scrollHeight,
      // }, 500);
      // window.scrollTo(0,9999);
      $('html, body').animate({
         scrollTop: $("#myDiv").offset().top
      }, 500);
      //     var myDiv = document.getElementById("myDiv");
      // myDiv.scrollTop = myDiv.scrollHeight;
   }

   /////////////////vehicle pagination////////////////////////////
   const handlePageChangeForInspection = (offset) => {

      inspectionOrderList("1", offset, ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, inspectioname);

   }

   useEffect(() => {
      setRoles(localStorage.getItem("Userroles"));

      if (CheckAuthentication) {

         DocumentCount();
         IfitIsCustomer();
         accountType();


         FleetOwnerDashboardAgression();

         if (localStorage.getItem("Userroles").match(Technician)) {



         }
         else if (localStorage.getItem("Userroles").match(Inspector)) {
            setInspectionName("");
            //  veh_docs(id, "1", ITEMS_PER_PAGE_DOCS, sortingField, sortingOrder, "diplayItems");
            //vehicle_details_api(id, setVehicleDetails, setCustomerId);
            inspectionOrderList("1", "1", ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, "");
         }
         else {

            getTopTenReminders();
            UpcomingOverdueList();
         }

         listTaskOrders("1", "10", setTotalTaskOrder, setTotalTaskList, setdisplayPageForInspection)

      }
      else {
         sessionStorage.clear();
         localStorage.clear();
         //navigate("/login");
         window.location.replace("login");
      }
      sessionStorage.removeItem("vehicleTypeName");
      sessionStorage.removeItem("tankerSubTypeName");
      sessionStorage.removeItem("naviationfromVehicleDetails");
   }, [])

   const UpcomingOverdueList = () => {
      var vechileReq =
      {

         "pageNumber": "1",
         "pageSize": 10,
         "lisencePlateNumber": "",
         "vehicleTypeId": "",
         "overDue": false,
         "sortBy": "",
         "sortDir": ""

      }

      var requestOptions = requestObject.RequestHeader(vechileReq);
      // services.remindersAll(requestOptions).then((res) => {
      services.listAllGarageReminders(requestOptions).then((res) => {
         // console.log("Garage upcomingOverduw"+JSON.stringify(res.rows));
         setUpcomingOverdue(res.rows);

      });

   }

   // const listTaskOrders=()=>{
   //     var listoftask=
   //      {

   //          "pageNumber": "1",
   //          "pageSize": 10,
   //           "sortBy": "",
   //          "sortDir": ""

   //      }

   //      var requestOptions = requestObject.RequestHeader(listoftask);
   //      services.listTaskOrder(requestOptions).then((res) =>
   //      {

   //          setTotalTaskOrder(res.customValues?res.customValues.totalNumberOfRecords:0);

   //      });

   //  }

   const FleetOwnerDashboardAgression = () => {
      var vechileReq =
      {

      }

      var requestOptions = requestObject.RequestHeader(vechileReq);
      services.getFleetDashBoardAggregations(requestOptions).then((res) => {


         setgetDashboardAggregation(res.customValues);


      });

   }

   const getTopTenReminders = () => {
      var vechileReq =
      {



      }

      var requestOptions = requestObject.RequestHeader(vechileReq);
      services.getTopTenReminders(requestOptions).then((res) => {

         if (res.success) {

            setTotalItems(res.customValues.totalNumberOfRecords);

            settotalPages(res.customValues.totalNumberOfRecords);

            setCurrentPage(res.customValues.currentPageNumber);
            if (res.rows.length > 0) {

               setdashBoardAgreggation(res.rows);
            } else {


               setdashBoardAgreggation("");
            }

            setTotalRecord(res.customValues.totalNumberOfRecords);

            var displaypages = [];


            for (var i = 1; i <= parseInt(Math.ceil(res.customValues.totalNumberOfRecords / ITEMS_PER_PAGE)); i++) {

               displaypages.push(i * ITEMS_PER_PAGE);
            }

            setdisplayPage(displaypages);

         }



      });

   }

   const accountType = () => {
      var customerListReq =
      {



      };



      var requestOptions = requestObject.RequestHeader(customerListReq);
      services.getAccountType(requestOptions).then((res) => {
         // console.log("account details3333"+JSON.stringify(res))

         //    if(res.rows.accountTypeName=='garage'){
         //        navigate("/garage/dashboard")
         //    }if(res.rows.accountTypeName=='fleet'){
         //     navigate("/fleetowner/dashboard")
         //   }
         try {
            sessionStorage.setItem("comapnyName", res.rows ? res.rows.name : "");
            $("#comapnyName").html(res.rows ? res.rows.name : "");
            sessionStorage.setItem("companyName", res.customValues ? res.customValues.name : "");
            sessionStorage.setItem("cmpnylogo", res.customValues ? res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/icons/dotstow-icon.png" : "/images/icons/dotstow-icon.png");
            $("#cmpnylogo").attr("src", res.customValues ? res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/icons/dotstow-icon.png" : "/images/icons/dotstow-icon.png");
            sessionStorage.setItem("accountGarageId", res.rows ? res.rows.id : "");
         } catch (exception) {

         }




      });
   }
   const redirect = (page) => {
      navigate(page);

   }
   const customers = () => {
      navigate('/customer');
   }
   const vehicles = () => {
      navigate('/vehicles');
   }
   const documents = () => {
      navigate('/documents');
   }
   const IfitIsCustomer = () => {
      var customerListReq =
      {



      };



      var requestOptions = requestObject.RequestHeader(customerListReq);
      services.getUserDetails(requestOptions).then((res) => {


         try {
            sessionStorage.setItem("loggedinUser", res.rows ? res.rows.firstName : "" + " " + res.rows ? res.rows.lastName : "");
            sessionStorage.setItem("userId", res.rows ? res.rows.id : "");
            sessionStorage.setItem("userProfilePic", res.customValues ? res.customValues.userImageFileByte ? "data:image/png;base64," + res.customValues.userImageFileByte : "/images/avatar.png" : "/images/avatar.png");
            sessionStorage.setItem("customerName", res.rows ? res.rows.firstName + " " + res.rows.lastName : "");
         } catch (error) {


         }

         // $("#posterURL").attr("src",res.customValues?res.customValues.userImageFileByte?"data:image/png;base64,"+res.customValues.userImageFileByte:"/images/transport-logo.jpg":"/images/transport-logo.jpg");

         // setNavImagesrc(res.customValues?res.customValues.userImageFileByte?"data:image/png;base64,"+res.customValues.userImageFileByte:"/images/transport-logo.jpg":"/images/transport-logo.jpg") $("#profileDropdown").attr("src",res.customValues?res.customValues.fileByte?"data:image/png;base64,"+res.customValues.fileByte:"/images/transport-logo.jpg":"/images/transport-logo.jpg");
         if (res.customValues) {
            $("#profileDropdown").attr("src", res.customValues.userImageFileByte ? "data:image/png;base64," + res.customValues.userImageFileByte : "/images/avatar.png");
         } else {
            $("#profileDropdown").attr("src", "/images/avatar.png");
         }
         $("#customerNameH").html(res.rows ? res.rows.firstName + " " + res.rows.lastName : "");
         // setcustomerDetails(res.rows);


      });
   }
   const addTaskOrder = (cname, vin) => {
      if (cname !== "" && vin !== "") {
         searchCustomerList(cname)

      } else {
         // searchCustomerList()
      }


      $("#vehicleData").hide();
      $("#taskOrderForm").hide();
      $(".error").hide();
      $("#inspectionerror").hide();
      $("#searchValue").val(cname);
      document.getElementById("searchValue").value = cname;
      getInspectionType(setInspectionTypes);

   }
   const DocumentCount = () => {
      var customerListReq =
      {

         "": "",

      };



      var requestOptions = requestObject.RequestHeader(customerListReq);
      services.getGarageDashBoardAggregations(requestOptions).then((res) => {

         try {


            $("#INSPECTION_ORDER_COUNT").text(res.customValues.customValues ? res.customValues.customValues.INSPECTION_ORDER_COUNT : "");
            $("#upcomingoverdue").text(res.customValues.customValues ? res.customValues.customValues.DUE_AND_UPCOMING_INSPECTION_COUNT : "");
            $("#INSPECTION_COUNT").text(res.customValues.customValues ? res.customValues.customValues.INSPECTION_COUNT : "");
            $("#VEHICLE_COUNT").text(res.customValues.customValues ? res.customValues.customValues.VEHICLE_COUNT : "");
            $("#DOCUMENT_COUNT").text(res.customValues.customValues ? res.customValues.customValues.DOCUMENT_COUNT : "");
            $("#CUSTOMER_COUNT").text(res.customValues.customValues ? res.customValues.customValues.CUSTOMER_COUNT : "");
            $("#TASK_ORDER_COUNT").text(res.customValues.customValues ? res.customValues.customValues.TASK_ORDER_COUNT : "");
         } catch (error) {
            $("#INSPECTION_ORDER_COUNT").text("0");
            $("#upcomingoverdue").text("0");
            $("#INSPECTION_COUNT").text("0");
            $("#VEHICLE_COUNT").text("0");
            $("#DOCUMENT_COUNT").text("0");
            $("#CUSTOMER_COUNT").text("0");
            $("#TASK_ORDER_COUNT").text("0");
         }

      });
   }

   const searchCustomerList = (customerName) => {
      if (customerName !== "") {
         setCustomerName(customerName);
      } else {
         setCustomerName();
      }

      getcustomerLists("1", ITEMS_PER_PAGE, customerName, ITEMS_PER_PAGE, setTotalItems1, settotalPages1, setCurrentPage1, setCustomerList, setTotalRecord1, setdisplayPage1, "", sortingField, sortingOrder);
   }
   return (
      <>
         <div class="main-panel">
            <div class="content-wrapper">
               <div class="row">
                  <div class="col-md-12 grid-margin">
                     <div class="row">
                        <div class="col-12 col-xl-8 mb-xl-0">
                           {/* <h3 class="font-weight-bold">{sessionStorage.getItem("customerName")}</h3> */}
                           {/* <h3 class="font-weight-bold">Welcome Princeton Garage</h3> */}
                           {/* <h6 class="font-weight-normal mb-0"><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry</span>
                                    </h6> */}
                        </div>
                     </div>
                  </div>
               </div>

               <div class="row mb-4">
                  <div class="col-lg-12">
                     <div class="dash-grid">
                        {roles.match(Technician) || roles.match(Inspector) ? "" : <a onClick={() => redirect("/inspectionOrders/1")} class="db-hvr">
                           <div class="grid-item-db" style={{ backgroundColor: "#FACE7F" }}>
                              <div class="grid-db-icon"><img src="/images/icons/db/db-01.png" alt="" /></div>
                              <div class="grid-bottom-db">
                                 <span class="count-db" id="INSPECTION_ORDER_COUNT"></span>
                                 <span class="count-label" >Inspection Orders</span>
                              </div>
                           </div>
                        </a>}
                        {roles.match(Technician) ? "" : <a onClick={() => redirect("/inspectionOrderList/1")} class="db-hvr">
                           <div class="grid-item-db" style={{ backgroundColor: "#9FC088" }}>
                              <div class="grid-db-icon"><img src="/images/icons/db/db-02.png" alt="" /></div>
                              <div class="grid-bottom-db">
                                 <span class="count-db" id="INSPECTION_COUNT" ></span>
                                 <span class="count-label">To be Approved</span>
                              </div>
                           </div>
                        </a>}
                        {roles.match(Technician) || roles.match(Inspector) ? "" : <a class="db-hvr" onClick={() => scrollSmoothToBottom()}>
                           <div class="grid-item-db" style={{ backgroundColor: "#E4CDA7" }} >
                              <div class="grid-db-icon"><img src="/images/icons/db/db-03.png" alt="" /></div>
                              <div class="grid-bottom-db">
                                 <span class="count-db" id="upcomingoverdue"></span>
                                 <span class="count-label">Upcoming / Overdue</span>
                              </div>

                           </div>
                        </a>}
                        <a onClick={() => redirect("/taskOrder/customerId/vehicleId/customerName/vehicleVIN/")} class="db-hvr">
                           <div class="grid-item-db" style={{ backgroundColor: "#D8B6A4" }}>
                              <div class="grid-db-icon"><img src="/images/icons/db/db-07.png" alt="" /></div>
                              <div class="grid-bottom-db">
                                 <span class="count-db" id="TASK_ORDER_COUNT"></span>
                                 {/* <span class="count-db" id="TASK_ORDER_COUNT">{TotalTaskOrder}</span> */}
                                 {/* <span class="count-label">Task Orders</span> */}
                                 <span class="count-label">Open Task Orders</span>
                              </div>
                              <div id="myDiv"></div>
                           </div>
                        </a>
                        <a onClick={() => redirect("/customer")} class="db-hvr">
                           <div class="grid-item-db" style={{ backgroundColor: "#FE8F8F" }}>
                              <div class="grid-db-icon"><img src="/images/icons/db/db-04.png" alt="" /></div>
                              <div class="grid-bottom-db">
                                 <span class="count-db" id="CUSTOMER_COUNT"></span>
                                 <span class="count-label">Customers</span>
                              </div>
                           </div>
                        </a>
                        <a onClick={() => redirect("/vehicles")} class="db-hvr">
                           <div class="grid-item-db" style={{ backgroundColor: "#90AACB" }}>
                              <div class="grid-db-icon"><img src="/images/icons/db/db-05.png" alt="" /></div>
                              <div class="grid-bottom-db">
                                 <span class="count-db" id="VEHICLE_COUNT"></span>
                                 <span class="count-label">Vehicles</span>
                              </div>
                           </div>
                        </a>
                        {/* <a  onClick={() => redirect("/documents")} class="db-hvr">
                                    <div class="grid-item-db" style={{backgroundColor: "#D8B6A4"}}>
                                        <div class="grid-db-icon"><img src="/images/icons/db/db-06.png" alt=""/></div>
                                        <div class="grid-bottom-db">
                                            <span class="count-db" id="DOCUMENT_COUNT"></span>
                                            <span class="count-label">Documents</span>
                                        </div>
                                        <div id="myDiv"></div>
                                    </div>
                                </a> */}

                     </div>
                  </div>

               </div>

               {roles.match(Technician) || roles.match(Inspector) ? "" : <div class="row mb-4" id="upcomingoverdue">
                  <div class="col-md-12 grid-margin stretch-card mb-0">
                     <div class="card">
                        <div class="card-body">
                           <div class="d-flex justify-content-between align-items-center mb-3">
                              <p class="card-title mb-0 text-uppercase">Upcoming / Overdue</p>
                              <a onClick={() => redirect("/upcomingOverdue")} class="btn btn-outline-primary text-uppercase">View all</a>
                              {/* <span class="fleet-dash-count">4</span> */}
                              {/* <a class="btn btn-outline-primary text-uppercase">View ALL</a> */}
                           </div>

                           <div class="table-responsive border-top border-bottom">
                              <table class="table">
                                 <thead>
                                    <tr>
                                       <th>Customer Name</th>
                                       <th>Unit Number</th>
                                       <th>VIN Number</th>
                                       <th>Inspection</th>
                                       <th>Expiry Date</th>
                                       <th class="text-center">Actions</th>

                                       {/* <th width="45">View</th> */}
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {
                                       upcomingOverdueList ? upcomingOverdueList.map((data, index) => (
                                          <tr>
                                             <td class="text-capitalize" style={{ width: "15%", whiteSpace: "break-spaces", lineHeight: "18px" }}>{data.customerName ? data.customerName : "--"}</td>
                                             <td style={{ width: "15%", whiteSpace: "break-spaces", lineHeight: "18px" }}>{data.unitNumber ? data.unitNumber : "--"}</td>
                                             <td style={{ width: "20%", whiteSpace: "break-spaces", lineHeight: "18px" }}>{data.vinNumber ? data.vinNumber : "--"} </td>
                                             <td style={{ width: "50%", whiteSpace: "break-spaces", lineHeight: "18px" }}>{data.documentSubCategoryName ? data.documentSubCategoryName : "--"}</td>
                                             <td style={{ width: "100px", whiteSpace: "break-spaces", lineHeight: "18px" }}>{data.expiryDate ? moment(data.expiryDate).format('MM-DD-YYYY') : "--"}</td>
                                             <td style={{ width: "100px", whiteSpace: "break-spaces", lineHeight: "18px" }} class="text-center"><a style={{ marginRight: "20px" }} onClick={() => redirect("/vehicleDetails/" + data.id)} class="btn btn-outline-primary text-uppercase">View</a></td>

                                          </tr>
                                       )) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                       </div>
                                    }
                                    {/* <tr>
                                                    <td>ERS 8755 </td>
                                                    <td>IPMS44324T1017489 </td>
                                                    <td>Leakage Test</td>
                                                    <td>07-23-2021</td>
                                                    <td><a class="btn btn-outline-primary text-uppercase">View</a></td>
                                                </tr> */}


                                 </tbody>
                              </table>
                           </div>

                        </div>
                     </div>

                  </div>

               </div>}


               {roles.match(Technician) ?
                  <div className="card" style={{ display: "block;" }}>
                     <div class="card-body pt-0 pb-3">
                        <div class="row">
                           <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                              <div className="d-flex justify-content-between align-items-center">
                                 <h4 class="font-weight-bold my-4 text-uppercase">Task order</h4>
                                 <a onClick={() => redirect("/taskOrder/customerId/vehicleId/customerName/vehicleVIN/")} class="btn btn-outline-primary text-uppercase">View all</a>
                              </div>
                           </div>
                        </div>



                        <div class="row">
                           <div class="col-12">
                              <div class="table-responsive  border-bottom">



                                 <table class="table table-hover">
                                    <tr>
                                       {headers.map(({ name, field, sortable, thStyle, inlineStyle }) => (
                                          <th
                                             key={name}
                                             onClick={() =>
                                                sortable ? "" : null
                                             }
                                             class={thStyle} style={inlineStyle}>
                                             {name}

                                             {sortingField && sortingField === field && (

                                                sortingOrder === "asc"
                                                   ? "↓"
                                                   : "↑"
                                             )}

                                          </th>
                                       ))}
                                    </tr>

                                    <tbody>
                                       {
                                          TaskList.length > 0 ? TaskList.map((data, index) => (
                                             <tr>
                                                <td style={{ width: "180px", maxWidth: "180px" }}>

                                                   <span className="text-capitalize">{data.name ? data.name : "--"}</span>


                                                   <div class="mt-4">
                                                      <lable style={{ marginLeft: "0px", fontWeight: "600" }}>Inspections to perform :</lable>
                                                      {data.inspectionData.map((data1, index) => {
                                                         return <ul class="px-1 task-list-ord">
                                                            <li>
                                                               {/* <a href={"/addInspection/" + data.customerId + "/" + data.vehicleId} onClick={() => addInspection(data.inspectionTypeData[s.toString()])}>{data.inspectionTypeData[s.toString()]}</a>  */}



                                                               {(() => {
                                                                  localStorage.setItem("customerId", data.customerId);
                                                                  localStorage.setItem("vehicleId", data.vehicleId);
                                                                  return <a onClick={() => inspection_details(data.id, data1.name, data1.inspectionStatus, "1", data1.id, data.vehicleId, data.customerId)} href=""  >{data1.name}
                                                                     {(() => {

                                                                        if (data1.inspectionStatus == 'approved' || data1.inspectionStatus === 'Reject' || data1.inspectionStatus === 'approve' || data1.inspectionStatus === 'Rejected' || data1.inspectionStatus == 'APPROVED') {

                                                                        } else if (data1.inspectionStatus == "DRAFT") {


                                                                        }
                                                                        else if (roles.match("Technician")) {
                                                                           if (data1.inspectionStatus === 'APPROVED PENDING' || data1.inspectionStatus === 'approve pending' || data1.inspectionStatus == 'APPROVE PENDING') {

                                                                           }
                                                                        } else if (roles.match("Manager") || roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
                                                                           if (data1.inspectionStatus === 'APPROVED PENDING' || data1.inspectionStatus === 'approve pending' || data1.inspectionStatus == 'APPROVE PENDING') {

                                                                           }
                                                                        } else {

                                                                        }
                                                                     })()}

                                                                  </a>



                                                               })()}

                                                               {(() => {
                                                                  if (data1.inspectionStatus == 'approve pending' || data1.inspectionStatus == "Approved pending" || data1.inspectionStatus == "APPROVED PENDING") {
                                                                     return (
                                                                        <span class=" approve-pending mx-2 text-capitalize">To Be Approved</span>
                                                                        // <span class="status-txt approve-pending">{inspection.status ? inspection.status : "--"}</span>
                                                                     )
                                                                  } else if (data1.inspectionStatus == 'Rejected') {
                                                                     return (
                                                                        <span class=" reject mx-2 text-capitalize">Reject</span>
                                                                     )
                                                                  } else if (data1.inspectionStatus == 'draft' || data1.inspectionStatus == 'DRAFT') {
                                                                     return (
                                                                        <span class=" draft mx-2 text-capitalize">Draft</span>
                                                                     )
                                                                  } else if (data1.inspectionStatus == 'approved' || data1.inspectionStatus == 'approve' || data1.inspectionStatus == 'APPROVED') {
                                                                     return (
                                                                        <span class=" approved mx-2 text-capitalize">Approved</span>
                                                                     )
                                                                  } else {
                                                                     return (
                                                                        <><span class=" mx-2 text-capitalize">{data1.inspectionStatus ? data1.inspectionStatus : ""}</span></>
                                                                     )
                                                                  }
                                                               })()}

                                                               {/* <small style={{marginLeft:"10px",color:"red"}} className="text-uppercase">{data1.inspectionStatus}</small> */}
                                                            </li>
                                                         </ul>
                                                      })}

                                                      <span class="mx-2 note mb-1"> <b className="red-txt mr-2">Note:</b>  {data.notes ? data.notes : ""}</span>
                                                   </div>
                                                </td>
                                                <td class="to-td text-capitalize" style={{ paddingRight: "10px", width: "90px", whiteSpace: "break-spaces", lineHeight: "18px" }}><span>{data.customerName ? data.customerName : "--"}</span></td>
                                                <td class="to-td text-capitalize pr-3">    <span>{data.unitNumber ? data.unitNumber : "--"}</span></td>
                                                <td class="to-td" style={{ paddingRight: "10px" }}>   <span>{data.vinnumber ? data.vinnumber : "--"}</span></td>
                                                <td class="to-td">  <span>{data.assignedDate ? moment(data.assignedDate).format('MM-DD-YYYY') : "--"}</span></td>
                                                <td class="to-td"> <span className="break-ws">{data.statusName ? data.statusName : "--"}</span>
                                                   {/* <div className="mt-4">

                        <ul className="px-1 task-list-ord mt-4">
                          <li> <b className="green-txt">Approved</b></li>
                          <li><b className="red-txt">Reject</b></li>
                        </ul>
                      </div> */}
                                                </td>
                                                {roles.match(Technician) ? "" : data.statusName === "completed" || data.statusName === "Completed" ? "" : <td class="to-td" style={{ maxWidth: "40px" }}> <span class="ml-auto"> <a class="btn btn-outline-primary text-uppercase" data-toggle="modal" data-target="#updateModal" >Edit</a></span></td>}


                                             </tr>
                                          )) : ""

                                       }




                                    </tbody>
                                 </table>
                              </div>
                           </div>
                           {TaskList.length > 0 ? "" : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                           </div>}
                        </div>
                     </div>
                  </div> : ""}


               {roles.match(Inspector) ?
                  <div className="card" style={{ display: "block;" }}>
                     <div class="card-body pt-0 pb-b">
                        <div class="row">
                           <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                              <div className="d-flex justify-content-between align-items-center">
                                 <h4 class="font-weight-bold my-4 text-uppercase">Inspections to be approved</h4>
                                 {/* <h4 class="font-weight-bold my-4 text-capitalize">Inspections to be approved</h4> */}
                                 <a onClick={() => redirect("/inspectionOrderList/1")} class="btn btn-outline-primary text-uppercase">View all</a>
                              </div>
                           </div>
                        </div>

                        <div class="row">
                           <div class="col-lg-12 col-md-12 m-auto grid-margin stretch-card">
                              <div class="card">
                                 <div class="row">
                                    <div class="col-12">
                                       <div class="table-responsive border-top  border-bottom ">



                                          <table class="table">
                                             <thead>
                                                <tr >
                                                   {Inspectionheaders.map(({ name, field, sortable, thStyle, inlineStyle }) => (
                                                      <th
                                                         key={name}
                                                         onClick={() =>
                                                            sortable ? onSortingChangeDocument(field) : null
                                                         }
                                                         class={thStyle} style={inlineStyle}>
                                                         {name}

                                                         {sortingField && sortingField === field && (

                                                            sortingOrder === "asc"
                                                               ? "↓"
                                                               : "↑"
                                                         )}

                                                      </th>
                                                   ))}
                                                </tr>
                                             </thead>
                                             <tbody>


                                                {
                                                   inspectionLists ? inspectionLists.map((inspection, index) => (
                                                      <tr className="inspectionorderlist text-capitalize">
                                                         <td>{inspection.customerName ? inspection.customerName : "--"}</td>
                                                         <td>{inspection.vehicleDetails ? inspection.vehicleDetails.unitNumber : "--"}</td>
                                                         <td>{inspection.vehicleDetails ? inspection.vehicleDetails.vinNumber : "--"}</td>
                                                         <td style={{ maxWidth: "150px", whiteSpace: "break-spaces", lineHeight: "20px" }}>{inspection.inspectionType ? inspection.inspectionType.name : "--"}</td>

                                                         <td>{inspection.inspectedDate ? moment(inspection.inspectedDate).format('MM-DD-YYYY') : "--"}</td>
                                                         {/* <td>{inspection.inspectedBy ? inspection.inspectedBy : "--"}</td> */}
                                                         <td>
                                                            {(() => {
                                                               if (inspection.status == 'approve pending' || inspection.status == "Approved pending" || inspection.status == "APPROVED PENDING") {
                                                                  return (
                                                                     <span class=" status-txt approve-pending">To Be Approved</span>
                                                                  )
                                                               } else if (inspection.status == 'Rejected') {
                                                                  return (
                                                                     <span class=" status-txt reject">{inspection.status ? inspection.status : "--"}</span>
                                                                  )
                                                               } else if (inspection.status == 'draft') {
                                                                  return (
                                                                     <span class=" status-txt draft">{inspection.status ? inspection.status : "--"}</span>
                                                                  )
                                                               } else if (inspection.status == 'approved' || inspection.status == 'approve' || inspection.status == 'APPROVED') {
                                                                  return (
                                                                     <span class=" status-txt approved">{inspection.status ? inspection.status : "--"}</span>
                                                                  )
                                                               } else {
                                                                  return (
                                                                     <>{inspection.status ? inspection.status : "--"}</>
                                                                  )
                                                               }
                                                            })()}
                                                            {/* {inspection.status ? inspection.status : "--"} */}
                                                         </td>
                                                         <td style={{ width: "50px" }}><a onClick={() => inspection_details(inspection.taskOrderId,inspection.inspectionType.name, inspection.status, inspection.fkAccountId, inspection.id,inspection.vehicleDetails.id, inspection.fkCustomerId )} class="btn btn-outline-primary text-uppercase">
                                                            {/* {(() => {
                                                               if (inspection.status == 'approved' || inspection.status === 'Reject' || inspection.status === 'approve' || inspection.status === 'APPROVED PENDING' || inspection.status === 'approve pending' || inspection.status === 'Rejected' || inspection.status == 'APPROVE PENDING' || inspection.status == 'APPROVED') {
                                                                  return (
                                                                     <>View</>
                                                                  )
                                                               } else {
                                                                  return (
                                                                     <>Edit</>
                                                                  )
                                                               }
                                                            })()} */}


{(() => {

// if (inspection.status == 'approved' || inspection.status === 'Reject' || inspection.status === 'approve' || inspection.status === 'Rejected' || inspection.status == 'APPROVED') {
// return (
//    <>View</>
// )
// }else if(inspection.status=="DRAFT"){

//    return (
//       <>Edit</>
//    )
// }
 if(roles.match(Technician)){
if (inspection.status === 'APPROVED PENDING' || inspection.status === 'approve pending' || inspection.status == 'APPROVE PENDING') {
return (
   <>View</>
)
}
}else if(roles.match(Manager)|| roles.match(Owner) || roles.match(Inspector)){
if ( inspection.status === 'APPROVED PENDING' || inspection.status === 'approve pending' || inspection.status == 'APPROVE PENDING' ) {
return (
   <>Edit</>
)
}
} else {
return (
   <>View</>
)
}
})()}


                                                         </a>
                                                         </td>
                                                      </tr>
                                                   )) : ""
                                                }



                                                {/* <tr>
                                                                            <td>External Visual Inspection</td>
                                                                            <td>External Visual Inspection </td>
                                                                            <td>05-08-2020</td>
                                                                            <td>James</td>
                                                                            <td><span class=" status-txt approve-pending ">Approve Pending</span></td>
                                                                            <td><a href="#" class="btn btn-outline-primary text-uppercase">View</a></td>
                                                                        </tr> */}


                                             </tbody>
                                          </table>
                                       </div>
                                       {inspectionLists ? "" : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span></div>}
                                    </div>

                                 </div>
                              </div>

                           </div>

                        </div>
                     </div>
                  </div> : ""}


            </div>
         </div>
      </>
   )
};

GarageDashboard.propTypes = {};

GarageDashboard.defaultProps = {};

export default GarageDashboard;
