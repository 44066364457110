import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import CheckAuthentication from "../../../../utility/checkAuthentication";
import AddInspectionName from "../../../../utility/addInspectionName";
import Adddocs from "../../../../utility/Adddocs";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";

const ServiceCheckListTest = () => {
  const { taskOrderId,id, vid } = useParams();
  const [fileName, setfileName] = useState("")
  const [endDate, setEndDate] = useState(new Date());
  var customerid = localStorage.getItem("customerId");
  const [noOfRows, setNoOfRows] = useState(1);
  const [loading, setLoading] = useLoading("Please wait...");
  const [inputList, setInputList] = useState([{}]);
  const navigate = useNavigate();
  const [base64file, setBase64file] = useState('');
  const [fromDate, setFromDate] = useState();
   const [toDate, setToDate] = useState();
  var fields = [];
  var documentList = [];
  useEffect(() => {
    $('#serchklist_dateValidMsg').hide();
    $('#serchklist_customerValidMsg').hide();
    $('#serchklist_unitValidMsg').hide();
    $('#serchklist_invoiceValidMsg').hide();
    $('#blrDsFrontValidMsg').hide();
    $('#blrDsRearValidMsg').hide();
    $('#blrPsFrontValidMsg').hide();
    $('#blrPsRearValidMsg').hide();
    $('#bmpaDsFrontValidMsg').hide();
    $('#bmpaDsRearValidMsg').hide();
    $('#bmpaPsFrontValidMsg').hide();
    $('#bmpaPsRearValidMsg').hide();
    $('#bmaaDsFrontValidMsg').hide();
    $('#bmaaDsRearValidMsg').hide();
    $('#bmaaPsFrontValidMsg').hide();
    $('#bmaaPsRearValidMsg').hide();                                     
  }, []);

  const redirect = (page) => {

    navigate(page);

  }
  const handleSubmit = () => {

    let form_is_valid = true;

    var blrDsFront = $('#blrDsFront').val();
    if(blrDsFront == '' || blrDsFront == undefined){
      $('#blrDsFrontValidMsg').show();
      form_is_valid = false;
      $('#blrDsFront').focus();
      return false
    }
    else {
      $('#blrDsFrontValidMsg').hide();
      form_is_valid = true;
    }

    var blrDsRear = $('#blrDsRear').val();
    if(blrDsRear == '' || blrDsRear == undefined){
      $('#blrDsRearValidMsg').show();
      form_is_valid = false;
      $('#blrDsRear').focus();
      return false
    }
    else {
      $('#blrDsRearValidMsg').hide();
      form_is_valid = true;
    }

    var blrPsFront = $('#blrPsFront').val();
    if(blrPsFront == '' || blrPsFront == undefined){
      $('#blrPsFrontValidMsg').show();
      form_is_valid = false;
      $('#blrPsFront').focus();
      return false
    }
    else {
      $('#blrPsFrontValidMsg').hide();
      form_is_valid = true;
    }

    var blrPsRear = $('#blrPsRear').val();
    if(blrPsRear == '' || blrPsRear == undefined){
      $('#blrPsRearValidMsg').show();
      form_is_valid = false;
      $('#blrPsRear').focus();
      return false
    }
    else {
      $('#blrPsRearValidMsg').hide();
      form_is_valid = true;
    }

    var bmpaDsFront = $('#bmpaDsFront').val();
    if(bmpaDsFront == '' || bmpaDsFront == undefined){
      $('#bmpaDsFrontValidMsg').show();
      form_is_valid = false;
      $('#bmpaDsFront').focus();
      return false
    }
    else {
      $('#bmpaDsFrontValidMsg').hide();
      form_is_valid = true;
    }

    var bmpaDsRear = $('#bmpaDsRear').val();
    if(bmpaDsRear == '' || bmpaDsRear == undefined){
      $('#bmpaDsRearValidMsg').show();
      form_is_valid = false;
      $('#bmpaDsRear').focus();
      return false
    }
    else {
      $('#bmpaDsRearValidMsg').hide();
      form_is_valid = true;
    }

    var bmpaPsFront = $('#bmpaPsFront').val();
    if(bmpaPsFront == '' || bmpaPsFront == undefined){
      $('#bmpaPsFrontValidMsg').show();
      form_is_valid = false;
      $('#bmpaPsFront').focus();
      return false
    }
    else {
      $('#bmpaPsFrontValidMsg').hide();
      form_is_valid = true;
    }

    var bmpaPsRear = $('#bmpaPsRear').val();
    if(bmpaPsRear == '' || bmpaPsRear == undefined){
      $('#bmpaPsRearValidMsg').show();
      form_is_valid = false;
      $('#bmpaPsRear').focus();
      return false
    }
    else {
      $('#bmpaPsRearValidMsg').hide();
      form_is_valid = true;
    }

    var bmaaDsFront = $('#bmaaDsFront').val();
    if(bmaaDsFront == '' || bmaaDsFront == undefined){
      $('#bmaaDsFrontValidMsg').show();
      form_is_valid = false;
      $('#bmaaDsFront').focus();
      return false
    }
    else {
      $('#bmaaDsFrontValidMsg').hide();
      form_is_valid = true;
    }

    var bmaaDsRear = $('#bmaaDsRear').val();
    if(bmaaDsRear == '' || bmaaDsRear == undefined){
      $('#bmaaDsRearValidMsg').show();
      form_is_valid = false;
      $('#bmaaDsRear').focus();
      return false
    }
    else {
      $('#bmaaDsRearValidMsg').hide();
      form_is_valid = true;
    }

    var bmaaPsFront = $('#bmaaPsFront').val();
    if(bmaaPsFront == '' || bmaaPsFront == undefined){
      $('#bmaaPsFrontValidMsg').show();
      form_is_valid = false;
      $('#bmaaPsFront').focus();
      return false
    }
    else {
      $('#bmaaPsFrontValidMsg').hide();
      form_is_valid = true;
    }
           
    var bmaaPsRear = $('#bmaaPsRear').val();
    if(bmaaPsRear == '' || bmaaPsRear == undefined){
      $('#bmaaPsRearValidMsg').show();
      form_is_valid = false;
      $('#bmaaPsRear').focus();
      return false
    }
    else {
      $('#bmaaPsRearValidMsg').hide();
      form_is_valid = true;
    }                                      
                                                                              
    return form_is_valid;

  }


  const save = (status) => {

    try {
      for (var i = 0; i < inputList.length; i++) {
        // console.log("filebase64=="+inputList[i].filetobase64);
        var extension = "";
        var byteString = "";
        console.log("results==" + inputList[i].filetobase64);
        if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
          extension = "pdf";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
        } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
          extension = "jpg";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
        }
        else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
          extension = "png";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
        }
       
        else {

        }


        documentList.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
      }
    } catch (exception) {
      // Swal.fire({
      //   position: 'center',
      //   icon: 'error',
      //   title: "",
      //   text: "Please attach document",
      //   showConfirmButton: true

      // }).then((result) => {

      //   if (result.isConfirmed) {


      //   }
      // });
      // return false;
    }

    // console.log("results=="+JSON.stringify(inputList));
    if (status == 'DRAFT') {


      if ($("#inspectedBy").val() === "") {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "",
          text: "Please enter inspectedBy",
          showConfirmButton: true

        }).then((result) => {

          if (result.isConfirmed) {


          }
        });
        return false;
      }

      var requestObjectForInsp =
      {
        "type": "Service Check List",
        "workOrderNumber": $("#workOrderNumber").val() != '' ? $("#workOrderNumber").val() : '',
        "notes": $("#notes").val() != '' ? $("#notes").val() : '',
        "inspectedBy": $("#inspectedBy").val(),
        "inspectedDate": $("#inspectedDate").val(),
        "status": status,
        "taskOrderId": taskOrderId,
        "verifiedBy": $("#verifiedBy").val(),
        "verifiedDate": $("#verifiedBy").val(),
        "inspectionType": {
          "id": sessionStorage.getItem("selectedinspectionId"),
          "name": sessionStorage.getItem("selectedinspectionType")
        },
        "result": {
            "approvedCertify": "0",
            "inspectionFailed": "0"
        },
        "dispositionOfVehicle": {
            "vehicleReturnedServices": "0",
            "vehicleWithdrawnFromService": "0",
            "vehicleSentToAnotherRepairFacility": "0"
        },
        "vehicleDetails": {
            "vinNumber": $("#vin").html(),
            "lisencePlateNumber": $("#lisencePlateNumber").html(),
            "id": vid,
            "unitNumber": $("#lisencePlateNumber").html()
        },
  "serviceCheckList": {
      "serviceDecal":$("input[name='serviceDecal']:checked").val() === "1" ? "1" : "0",
    "upperCouplerDetails": {
      "sideTorCracks": $("input[name='sideTorCracks']:checked").val() === "1" ? "1" : "0",
      "pin": $("input[name='pin']:checked").val() === "1" ? "1" : "0",
      "torLooseHardware": $("input[name='torLooseHardware']:checked").val() === "1" ? "1" : "0",
    },
    "emergencyShutoffDetails": {
      "emergencyShutOffSystem": $("input[name='emergencyShutOffSystem']:checked").val() === "1" ? "1" : "0",
      "emergencyShutOffDecals": $("input[name='emergencyShutOffDecals']:checked").val() === "1" ? "1" : "0",
    },
    "lightsDetails": {
      "allTorNormalOperation": $("input[name='allTorNormalOperation']:checked").val() === "1" ? "1" : "0",
      "workLights": $("input[name='workLights']:checked").val() === "1" ? "1" : "0",
      "scully": $("input[name='scully']:checked").val() === "1" ? "1" : "0",
    },
    "reflectiveTypeDetails": {
      "mustHaveEachSide": $("input[name='mustHaveEachSide']:checked").val() === "1" ? "1" : "0",
      "silverOnRearTop": $("input[name='silverOnRearTop']:checked").val() === "1" ? "1" : "0",
      "rearBumber": $("input[name='rearBumber']:checked").val() === "1" ? "1" : "0",
    },
    "placardsDetails": {
      "tarBeingTraded": $("input[name='tarBeingTraded']:checked").val() === "1" ? "1" : "0",
    },
    "overTillProtectionDetails": {
      "forProperOperation": $("input[name='forProperOperation']:checked").val() === "1" ? "1" : "0",
    },
    "breakInterLockValvesDetails": {
      "lubriCationAsNeeded": $("input[name='lubriCationAsNeeded']:checked").val() === "1" ? "1" : "0",
    },
    "lubricantFollowingItemDetails": {
      "doorHinges": $("input[name='doorHinges']:checked").val() === "1" ? "1" : "0",
      "lockoutBar": $("input[name='lockoutBar']:checked").val() === "1" ? "1" : "0",
    },
    "breaksAndBreakComponentsDetails": {
      "shoesForWear": $("input[name='shoesForWear']:checked").val() === "1" ? "1" : "0",
      "scamsAndBushings": $("input[name='scamsAndBushings']:checked").val() === "1" ? "1" : "0",
      "wheelSeals": $("input[name='wheelSeals']:checked").val() === "1" ? "1" : "0",
      "drums": $("input[name='drums']:checked").val() === "1" ? "1" : "0",
    },
    "airBreakChambersDetails": {
      "cracks": $("input[name='cracks']:checked").val() === "1" ? "1" : "0",
      "springs": $("input[name='springs']:checked").val() === "1" ? "1" : "0",
    },
    "greaseDetails": {
      "allGreaseFittings": $("input[name='allGreaseFittings']:checked").val() === "1" ? "1" : "0",
      "loadingHeads": $("input[name='loadingHeads']:checked").val() === "1" ? "1" : "0",
      "cableConduit": $("input[name='cableConduit']:checked").val() === "1" ? "1" : "0",
    },
    "suspensionTorNonAirRideDetails": {
      "inspectAllSprings": $("input[name='inspectAllSprings']:checked").val() === "1" ? "1" : "0",
    },
    "torqueArmsDetails": {
      "bushings": $("input[name='bushings']:checked").val() === "1" ? "1" : "0",
      "torWear": $("input[name='torWear']:checked").val() === "1" ? "1" : "0",
      "torOverallWear": $("input[name='torOverallWear']:checked").val() === "1" ? "1" : "0",
    },
    "equalizerDetails": {
      "inspectBushings": $("input[name='inspectBushings']:checked").val() === "1" ? "1" : "0",
      "inspectTorOverallWear": $("input[name='inspectTorOverallWear']:checked").val() === "1" ? "1" : "0",
    },
    "suspensionTorAirRideDetails": {
      "airBags": $("input[name='airBags']:checked").val() === "1" ? "1" : "0",
      "shocks": $("input[name='shocks']:checked").val() === "1" ? "1" : "0",
      "swingArmBushings": $("input[name='swingArmBushings']:checked").val() === "1" ? "1" : "0",
    },
    "safetyValvesDetails": {
      "fusiblePlug": $("input[name='fusiblePlug']:checked").val() === "1" ? "1" : "0",
      "adjustCableAsNeeded": $("input[name='adjustCableAsNeeded']:checked").val() === "1" ? "1" : "0",
      "airOperatedValves": $("input[name='airOperatedValves']:checked").val() === "1" ? "1" : "0",
    },
    "hubCapsDetails": {
      "oilLevl": $("input[name='oilLevl']:checked").val() === "1" ? "1" : "0",
      "metalShavings": $("input[name='metalShavings']:checked").val() === "1" ? "1" : "0",
    },
    "slackAdjestersDetails": {
      "pawForRockwell": $("input[name='pawForRockwell']:checked").val() === "1" ? "1" : "0",
      "adjustmentPointer": $("input[name='adjustmentPointer']:checked").val() === "1" ? "1" : "0",
      "nutForClevis": $("input[name='nutForClevis']:checked").val() === "1" ? "1" : "0",
    },
    "tankDataPlatesDetails": {
      "verifyVin": $("input[name='verifyVin']:checked").val() === "1" ? "1" : "0",
      "dataPlatesLegible": $("input[name='dataPlatesLegible']:checked").val() === "1" ? "1" : "0",
    },
    "breakLiningRemainingDetails": {
      "blrDsFront": $('#blrDsFront').val(),
      "blrDsRear": $('#blrDsRear').val(),
      "blrPsFront": $('#blrPsFront').val(),
      "blrPsRear": $('#blrPsRear').val(),
    },
    "breakMeasurementPriorAdjustmentDetails": {
      "bmpaDsFront": $('#bmpaDsFront').val(),
      "bmpaDsRear": $('#bmpaDsRear').val(),
      "bmpaPsFront": $('#bmpaPsFront').val(),
      "bmpaPsRear": $('#bmpaPsRear').val(),
    },
    "breakMeasurementAfterAdjustmentDetails": {
      "bmaaDsFront": $('#bmaaDsFront').val(),
      "bmaaDsRear": $('#bmaaDsRear').val(),
      "bmaaPsFront": $('#bmaaPsFront').val(),
      "bmaaPsRear": $('#bmaaPsRear').val(),
    },
    "externalInspectionRemarks": $("#externalInspectionRemarks").val() != '' ? $("#externalInspectionRemarks").val() : '',
  },
        "documentList": documentList
      }

      $("#loadingforsubmit").show();
      // setLoading(true);
      //   const requestOptions = 
      //   {
      //      method: 'POST',
      //      headers: { 'Content-Type': 'application/json'
      //               },

      //         body: JSON.stringify(requestObject)
      //   };
      var requestOptions = requestObject.RequestHeader(requestObjectForInsp);
      services.addInspection(requestOptions).then((res) => {
        // setLoading(false);
        $("#loadingforsubmit").hide();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: "",
          text: res.message,
          showConfirmButton: true

        }).then((result) => {

          if (result.isConfirmed) {
            navigate("/vehicleDetails/" + vid);

          }
        });
      });

    } else {
      //alert(handleSubmit());
      if (handleSubmit() == true) {



        if ($("#inspectedBy").val() === "") {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Please enter inspectedBy",
            showConfirmButton: true

          }).then((result) => {

            if (result.isConfirmed) {


            }
          });
          return false;
        }

        var requestObjectForInsp =
        {

          "type": sessionStorage.getItem("selectedinspectionType"),
          "workOrderNumber": $("#workOrderNumber").val() != '' ? $("#workOrderNumber").val() : '',
        "notes": $("#notes").val() != '' ? $("#notes").val() : '',
        "inspectedBy": $("#inspectedBy").val(),
        "inspectedDate": $("#inspectedDate").val(),
        "status": status,
        "taskOrderId": taskOrderId,
        "verifiedBy": $("#verifiedBy").val(),
        "verifiedDate": $("#verifiedBy").val(),
        "inspectionType": {
          "id": sessionStorage.getItem("selectedinspectionId"),
          "name": sessionStorage.getItem("selectedinspectionType")
        },
        "result": {
            "approvedCertify": "0",
            "inspectionFailed": "0"
        },
        "dispositionOfVehicle": {
            "vehicleReturnedServices": "0",
            "vehicleWithdrawnFromService": "0",
            "vehicleSentToAnotherRepairFacility": "0"
        },
        "vehicleDetails": {
            "vinNumber": $("#vin").html(),
            "lisencePlateNumber": $("#lisencePlateNumber").html(),
            "id": vid,
            "unitNumber": $("#lisencePlateNumber").html()
        },
        "serviceCheckList": {
            "serviceDecal":$("input[name='serviceDecal']:checked").val() === "1" ? "1" : "0",
          "upperCouplerDetails": {
            "sideTorCracks": $("input[name='sideTorCracks']:checked").val() === "1" ? "1" : "0",
            "pin": $("input[name='pin']:checked").val() === "1" ? "1" : "0",
            "torLooseHardware": $("input[name='torLooseHardware']:checked").val() === "1" ? "1" : "0",
          },
          "emergencyShutoffDetails": {
            "emergencyShutOffSystem": $("input[name='emergencyShutOffSystem']:checked").val() === "1" ? "1" : "0",
            "emergencyShutOffDecals": $("input[name='emergencyShutOffDecals']:checked").val() === "1" ? "1" : "0",
          },
          "lightsDetails": {
            "allTorNormalOperation": $("input[name='allTorNormalOperation']:checked").val() === "1" ? "1" : "0",
            "workLights": $("input[name='workLights']:checked").val() === "1" ? "1" : "0",
            "scully": $("input[name='scully']:checked").val() === "1" ? "1" : "0",
          },
          "reflectiveTypeDetails": {
            "mustHaveEachSide": $("input[name='mustHaveEachSide']:checked").val() === "1" ? "1" : "0",
            "silverOnRearTop": $("input[name='silverOnRearTop']:checked").val() === "1" ? "1" : "0",
            "rearBumber": $("input[name='rearBumber']:checked").val() === "1" ? "1" : "0",
          },
          "placardsDetails": {
            "tarBeingTraded": $("input[name='tarBeingTraded']:checked").val() === "1" ? "1" : "0",
          },
          "overTillProtectionDetails": {
            "forProperOperation": $("input[name='forProperOperation']:checked").val() === "1" ? "1" : "0",
          },
          "breakInterLockValvesDetails": {
            "lubriCationAsNeeded": $("input[name='lubriCationAsNeeded']:checked").val() === "1" ? "1" : "0",
          },
          "lubricantFollowingItemDetails": {
            "doorHinges": $("input[name='doorHinges']:checked").val() === "1" ? "1" : "0",
            "lockoutBar": $("input[name='lockoutBar']:checked").val() === "1" ? "1" : "0",
          },
          "breaksAndBreakComponentsDetails": {
            "shoesForWear": $("input[name='shoesForWear']:checked").val() === "1" ? "1" : "0",
            "scamsAndBushings": $("input[name='scamsAndBushings']:checked").val() === "1" ? "1" : "0",
            "wheelSeals": $("input[name='wheelSeals']:checked").val() === "1" ? "1" : "0",
            "drums": $("input[name='drums']:checked").val() === "1" ? "1" : "0",
          },
          "airBreakChambersDetails": {
            "cracks": $("input[name='cracks']:checked").val() === "1" ? "1" : "0",
            "springs": $("input[name='springs']:checked").val() === "1" ? "1" : "0",
          },
          "greaseDetails": {
            "allGreaseFittings": $("input[name='allGreaseFittings']:checked").val() === "1" ? "1" : "0",
            "loadingHeads": $("input[name='loadingHeads']:checked").val() === "1" ? "1" : "0",
            "cableConduit": $("input[name='cableConduit']:checked").val() === "1" ? "1" : "0",
          },
          "suspensionTorNonAirRideDetails": {
            "inspectAllSprings": $("input[name='inspectAllSprings']:checked").val() === "1" ? "1" : "0",
          },
          "torqueArmsDetails": {
            "bushings": $("input[name='bushings']:checked").val() === "1" ? "1" : "0",
            "torWear": $("input[name='torWear']:checked").val() === "1" ? "1" : "0",
            "torOverallWear": $("input[name='torOverallWear']:checked").val() === "1" ? "1" : "0",
          },
          "equalizerDetails": {
            "inspectBushings": $("input[name='inspectBushings']:checked").val() === "1" ? "1" : "0",
            "inspectTorOverallWear": $("input[name='inspectTorOverallWear']:checked").val() === "1" ? "1" : "0",
          },
          "suspensionTorAirRideDetails": {
            "airBags": $("input[name='airBags']:checked").val() === "1" ? "1" : "0",
            "shocks": $("input[name='shocks']:checked").val() === "1" ? "1" : "0",
            "swingArmBushings": $("input[name='swingArmBushings']:checked").val() === "1" ? "1" : "0",
          },
          "safetyValvesDetails": {
            "fusiblePlug": $("input[name='fusiblePlug']:checked").val() === "1" ? "1" : "0",
            "adjustCableAsNeeded": $("input[name='adjustCableAsNeeded']:checked").val() === "1" ? "1" : "0",
            "airOperatedValves": $("input[name='airOperatedValves']:checked").val() === "1" ? "1" : "0",
          },
          "hubCapsDetails": {
            "oilLevl": $("input[name='oilLevl']:checked").val() === "1" ? "1" : "0",
            "metalShavings": $("input[name='metalShavings']:checked").val() === "1" ? "1" : "0",
          },
          "slackAdjestersDetails": {
            "pawForRockwell": $("input[name='pawForRockwell']:checked").val() === "1" ? "1" : "0",
            "adjustmentPointer": $("input[name='adjustmentPointer']:checked").val() === "1" ? "1" : "0",
            "nutForClevis": $("input[name='nutForClevis']:checked").val() === "1" ? "1" : "0",
          },
          "tankDataPlatesDetails": {
            "verifyVin": $("input[name='verifyVin']:checked").val() === "1" ? "1" : "0",
            "dataPlatesLegible": $("input[name='dataPlatesLegible']:checked").val() === "1" ? "1" : "0",
          },
          "breakLiningRemainingDetails": {
            "blrDsFront": $('#blrDsFront').val(),
            "blrDsRear": $('#blrDsRear').val(),
            "blrPsFront": $('#blrPsFront').val(),
            "blrPsRear": $('#blrPsRear').val(),
          },
          "breakMeasurementPriorAdjustmentDetails": {
            "bmpaDsFront": $('#bmpaDsFront').val(),
            "bmpaDsRear": $('#bmpaDsRear').val(),
            "bmpaPsFront": $('#bmpaPsFront').val(),
            "bmpaPsRear": $('#bmpaPsRear').val(),
          },
          "breakMeasurementAfterAdjustmentDetails": {
            "bmaaDsFront": $('#bmaaDsFront').val(),
            "bmaaDsRear": $('#bmaaDsRear').val(),
            "bmaaPsFront": $('#bmaaPsFront').val(),
            "bmaaPsRear": $('#bmaaPsRear').val(),
          },
          "externalInspectionRemarks": $("#externalInspectionRemarks").val() != '' ? $("#externalInspectionRemarks").val() : '',
        },
        "documentList": documentList

        };

        console.log(requestObjectForInsp);
        //return false;

        $("#loadingforsubmit").show();
        // setLoading(true);
        //   const requestOptions = 
        //   {
        //      method: 'POST',
        //      headers: { 'Content-Type': 'application/json'
        //               },

        //         body: JSON.stringify(requestObject)
        //   };
        var requestOptions = requestObject.RequestHeader(requestObjectForInsp);
        services.addInspection(requestOptions).then((res) => {
          // setLoading(false);
          $("#loadingforsubmit").hide();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: "",
            text: res.message,
            showConfirmButton: true

          }).then((result) => {

            if (result.isConfirmed) {
              navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

            }
          });
        });
      }
    }


  }

  const resetAllValues = () => {
    setFromDate('');
  }

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, {}]);
  };
  const fileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(null, reader.result)
    }
    reader.onerror = function (error) {
      cb(error, null)
    }
  }
  const onUploadFileChange = (event, index) => {


    var target = event.target;
    if (target.files < 1 || !target.validity.valid) {
      return
    }
    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        const { name, value } = event.target;
        const list = [...inputList];
        list[index][name] = result;
        setBase64file(result);
        // setFile(result)
        setfileName(target.files[0]);
        // setFileName(target.files[0])
      }
    })
  }

  return (
    <>
    {LoadingComponentForSubmit}
    <div class="row">
                <div class="col-lg-12 col-md-12 m-auto ">
                    <div class="card">
                        <div class="row">
                            <div class="w-100 px-3">
                                <h4 class="mb-2 form-inspection-title text-center">Service Check List</h4>
                              {/* <div>
                              <p id="leakage-check-type-message" class="error"></p>
                              <p id="unitDesignPressure-message" class="error"></p> 
                              <p id="testPressure-message" class="error"></p>
                              <p id="error_message" class="center"></p>
                              </div> */}
                            </div>
                            <div class="col-lg-12 mx-0"><WorkOrderNumberDiv /></div>
                            

                            
                            
                            <div class="px-3">
                                <div class="inspection-wrap">
                                  <div class="row mb-3 mt-3">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Upper Coupler</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="sideTorCracks" value="1" class="mr-2" />
                                                <span>Inspect side for cracks</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="pin" value="1" class="mr-2" />
                                                <span>Inspect pin (wear or bent)</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="torLooseHardware" value="1" class="mr-2" />
                                                <span>Inspect for loose hardware</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Emergency Shut-off</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex">
                                                <input type="checkbox" name="emergencyShutOffSystem" value="1" class="mr-2" style={{minHeight:20, maxHeight:20,}}/>
                                                <span>Check operation of emergency shut-off system</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex ">
                                                <input type="checkbox" name="emergencyShutOffDecals" value="1" class="mr-2" style={{minHeight:20, maxHeight:20,}}/>
                                                <span>Check “Emergency Shut-off Decals” (replace if needed)</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg ">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Lights</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="allTorNormalOperation" value="1" class="mr-2" />
                                                <span>Check all for normal operation</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="workLights" value="1" class="mr-2" />
                                                <span>Work lights</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="scully" value="1" class="mr-2" />
                                                <span>Scully</span>
                                            </div>
                                        </div>
                                    </div>
             
                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Reflective Tape</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="mustHaveEachSide" value="1" class="mr-2" style={{minHeight:"20px"}} />
                                                <span>Must have at least 50% on each side</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-start">
                                                <input type="checkbox" name="silverOnRearTop" value="1" class="mr-2" style={{minHeight:20, maxHeight:20,}}/>
                                                <span>4 pcs (11 ½ “) silver, on rear top right and left side</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="rearBumber" value="1" class="mr-2" style={{minHeight:"20px"}} />
                                                <span>Full length of rear bumper</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Placards</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="tarBeingTraded" value="1" class="mr-2" />
                                                <span>Inspect for being faded or torn</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Overfill protection</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="forProperOperation" value="1" class="mr-2" />
                                                <span>Inspect system for proper operation</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Inspect operation of all brake interlock valves</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="lubriCationAsNeeded" value="1" class="mr-2" />
                                                <span>Apply lubrication as needed</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Lubricate following items</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="doorHinges" value="1" class="mr-2" style={{minHeight:"20px"}} />
                                                <span>Door hinges and latches</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-start">
                                                <input type="checkbox" name="lockoutBar" value="1" class="mr-2" style={{minHeight:"20px"}} />
                                                <span>Lockout bar pivot points and slide pin</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Check brakes and brake components</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="shoesForWear" value="1" class="mr-2" />
                                                <span>Inspect shoes for wear and cracks</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="scamsAndBushings" value="1" class="mr-2" />
                                                <span>Inspect S-Cams and bushings</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="wheelSeals" value="1" class="mr-2" />
                                                <span>Inspect wheel seals</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="drums" value="1" class="mr-2" />
                                                <span>Inspect drums</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Inspect Air brake chambers</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-start">
                                                <input type="checkbox" name="cracks" value="1" class="mr-2" style={{minHeight:"20px"}} />
                                                <span>Inspect for cracks in mounting brackets</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="springs" value="1" class="mr-2" style={{minHeight:"20px"}} />
                                                <span>Inspect springs (any broken?)</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Brake lining remaining</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mb-2">
                                            <div class="d-flex align-items-center">
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">DS Front <span class="star">*</span></lable>
                                                                
                                                            <div>
                                                            <input type="input" class="form-control" id="blrDsFront" aria-describedby="emailHelp" style={{ width: "100px" }} />

                                                                <div id="blrDsFrontValidMsg" class="error">Required Field</div>
                                                            </div>
                                                        </div>
                                                        <span style={{margin:"10px 10px"}}>%</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">DS Rear <span class="star">*</span></lable>
                                                                
                                                            <div>
                                                            <input type="input" class="form-control" id="blrDsRear" aria-describedby="emailHelp" style={{ width: "100px" }} />

                                                                <div id="blrDsRearValidMsg" class="error">Required Field</div>
                                                            </div>
                                                        </div>
                                                        <span style={{margin:"10px 10px"}}>%</span>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">PS Front <span class="star">*</span></lable>
                                                                
                                                            <div>
                                                            <input type="input" class="form-control" id="blrPsFront" aria-describedby="emailHelp" style={{ width: "100px" }} />

                                                                <div id="blrPsFrontValidMsg" class="error">Required Field</div>
                                                            </div>
                                                        </div>
                                                        <span style={{margin:"10px 10px"}}>%</span>
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">PS Rear <span class="star">*</span></lable>
                                                                
                                                            <div>
                                                            <input type="input" class="form-control" id="blrPsRear" aria-describedby="emailHelp" style={{ width: "100px" }} />

                                                                <div id="blrPsRearValidMsg" class="error">Required Field</div>
                                                            </div>
                                                        </div>
                                                        <span style={{margin:"10px 10px"}}>%</span>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Brake measurement prior to adjustment</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mb-2">
                                            <div class="d-flex align-items-center">
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">DS Front <span class="star">*</span></lable>
                                                            <div>
                                                            <input type="input" class="form-control" id="bmpaDsFront" aria-describedby="emailHelp" style={{ width: "100px" }} />

                                                                <div id="bmpaDsFrontValidMsg" class="error">Required Field</div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">DS Rear <span class="star">*</span></lable>
                                                                
                                                            <div>
                                                            <input type="input" class="form-control" id="bmpaDsRear" aria-describedby="emailHelp" style={{ width: "100px" }} />

                                                                <div id="bmpaDsRearValidMsg" class="error">Required Field</div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">PS Front <span class="star">*</span></lable>
                                                                
                                                            <div>
                                                            <input type="input" class="form-control" id="bmpaPsFront" aria-describedby="emailHelp" style={{ width: "100px" }} />

                                                                <div id="bmpaPsFrontValidMsg" class="error">Required Field</div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">PS Rear <span class="star">*</span></lable>
                                                                
                                                            <div>
                                                            <input type="input" class="form-control" id="bmpaPsRear" aria-describedby="emailHelp" style={{ width: "100px" }} />

                                                                <div id="bmpaPsRearValidMsg" class="error">Required Field</div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Brake measurement after adjustment</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mb-2">
                                            <div class="d-flex align-items-center">
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                            <lable class="mr-2">DS Front <span class="star">*</span></lable>
                                                            <div>
                                                            <input type="input" class="form-control" id="bmaaDsFront" aria-describedby="emailHelp" style={{ width: "100px" }} />

                                                                <div id="bmaaDsFrontValidMsg" class="error">Required Field</div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">DS Rear <span class="star">*</span></lable>
                                                            <div>
                                                            <input type="input" class="form-control" id="bmaaDsRear" aria-describedby="emailHelp" style={{ width: "100px" }} />

                                                                <div id="bmaaDsRearValidMsg" class="error">Required Field</div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">PS Front <span class="star">*</span></lable>
                                                            <div>
                                                            <input type="input" class="form-control" id="bmaaPsFront" aria-describedby="emailHelp" style={{ width: "100px" }} />

                                                                <div id="bmaaPsFrontValidMsg" class="error">Required Field</div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">PS Rear <span class="star">*</span></lable>
                                                            <div>
                                                            <input type="input" class="form-control" id="bmaaPsRear" aria-describedby="emailHelp" style={{ width: "100px" }} />

                                                                <div id="bmaaPsRearValidMsg" class="error">Required Field</div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Grease</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="allGreaseFittings" value="1" class="mr-2" />
                                                <span>Apply grease to all grease fittings</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="cableConduit" value="1" class="mr-2" />
                                                <span>Cable conduit</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="loadingHeads" value="1" class="mr-2" />
                                                <span>Loading heads</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Inspect suspension for non-air ride</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-start">
                                                <input type="checkbox" name="inspectAllSprings" value="1" class="mr-2" style={{minHeight:"20px"}} />
                                                <span>Inspect all springs for cracks and movement</span>
                                            </div>
                                        </div>                                        
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Torque Arms</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="bushings" value="1" class="mr-2" />
                                                <span>Inspect bushings</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="torWear" value="1" class="mr-2" />
                                                <span>Inspect for wear on bolt holes</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="torOverallWear" value="1" class="mr-2" />
                                                <span>Inspect for overall wear</span>
                                            </div>
                                        </div>                                        
                                    </div>


                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Equalizer</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectBushings" value="1" class="mr-2" />
                                                <span>Inspect bushings</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectTorOverallWear" value="1" class="mr-2" />
                                                <span>Inspect for overall wear</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Inspect suspension for air ride</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="airBags" value="1" class="mr-2" />
                                                <span>Air bags and mounts</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="shocks" value="1" class="mr-2" />
                                                <span>Shocks</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="swingArmBushings" value="1" class="mr-2" />
                                                <span>Swing arm bushings</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-2">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Inspect safety valves</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-start">
                                                <input type="checkbox" name="fusiblePlug" value="1" class="mr-2" style={{minHeight:20, maxHeight:20,}}/>
                                                <span>Check for fusible plug at valves</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-start">
                                                <input type="checkbox" name="adjustCableAsNeeded" value="1" class="mr-2" style={{minHeight:20, maxHeight:20,}}/>
                                                <span>Check operation and adjust cable as needed</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-start">
                                                <input type="checkbox" name="airOperatedValves" value="1" class="mr-2" style={{minHeight:20, maxHeight:20,}}/>
                                                <span>Check operation of air operated valves</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Inspect Hub Caps</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="oilLevl" value="1" class="mr-2" />
                                                <span>Check oil level (add if needed)</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="metalShavings" value="1" class="mr-2" />
                                                <span>Look for metal shavings</span>
                                            </div>
                                        </div>
                                    </div> 

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Inspect slack adjusters</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="pawForRockwell" value="1" class="mr-2" />
                                                <span>Check paw for Rockwell adjusters</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="adjustmentPointer" value="1" class="mr-2" />
                                                <span>Check adjustment pointer</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="nutForClevis" value="1" class="mr-2" />
                                                <span>Check nut for clevis assembly</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Inspect tank data plates</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="verifyVin" value="1" class="mr-2" />
                                                <span>Verify the VIN # is accurate</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="dataPlatesLegible" value="1" class="mr-2" />
                                                <span>Are the data plates legible</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>External Inspection (document all defects and action taken)</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-8 mb-2">
                                            <div class="d-flex align-items-center">
                                            <textarea class="form-control my-3" name="externalInspectionRemarks" id="externalInspectionRemarks" rows="3" maxLength="300" style={{minHeight:"180px"}} ></textarea>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="divider2"></div>
                        <div class="row py-3">
                            <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                        </div>
                        <div class="divider2"></div>
                        <div class="row py-3">
                                <div class="row mb-2 mt-2">
                                    <div class="col-lg-12">
                                        <div class="d-flex align-items-center ml-4">
                                            <input type="checkbox" name="serviceDecal" value="1" class="mr-2" />
                                            <span>Service decal has been changed. (Service decal not to be changed until work is complete)</span>
                                        </div>
                                    </div>                                  
                                </div>
                            </div>

                        <AddInspectionName setEndDate={setEndDate} endDate={endDate} />

                        <div class="divider2"></div>
                        <div class="row p-3 pr-4 d-flex justify-content-end" style={{ marginBottom: "-20px" }}>
                            <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
                            <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a>
                            <a href="#" class="btn btn-primary mr-1" onClick={() => save("APPROVED PENDING")}>Save</a>
                        </div>
                    </div>
                </div>
            </div>

    </>
  )
};

ServiceCheckListTest.propTypes = {};

ServiceCheckListTest.defaultProps = {};

export default ServiceCheckListTest;
