import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import CheckAuthentication from "../../../../utility/checkAuthentication";
import AddInspectionName from "../../../../utility/addInspectionName";
import Adddocs from "../../../../utility/Adddocs";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";

const DriverVehicleTest = () => {
  const { taskOrderId,id, vid } = useParams();
  const [fileName, setfileName] = useState("")
  const [endDate, setEndDate] = useState(new Date());
  var customerid = localStorage.getItem("customerId");
  const [noOfRows, setNoOfRows] = useState(1);
  const [loading, setLoading] = useLoading("Please wait...");
  const [inputList, setInputList] = useState([{}]);
  const navigate = useNavigate();
  const [base64file, setBase64file] = useState('');
  const [fromDate, setFromDate] = useState();
   const [toDate, setToDate] = useState();
  var fields = [];
  var documentList = [];
  useEffect(() => {
    $('#driver_sigdateValidMsg').hide();
    $('#driver_signature1ValidMsg').hide();
    $('#mechanic_sigdateValidMsg').hide();
    $('#mechanic_signatureValidMsg').hide();
    $('#driver_signatureValidMsg').hide();
    $('#driverinsp_trucknoValidMsg').hide();
    $('#driverinsp_dateValidMsg').hide();
    $('#driverinsp_trailernoValidMsg').hide();
  }, []);

  const redirect = (page) => {

    navigate(page);

  }
  const handleSubmit = () => {

    let form_is_valid = true;
/*
    var testdate = $('#tpmt_date').val();
    if(testdate == '' || testdate == undefined){
      $('#tpmt_dateValidMsg').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tpmt_dateValidMsg').hide();
      form_is_valid = true;
    }

    var testcustomer = $('#tpmt_customer').val();
    if(testcustomer == '' || testcustomer == undefined){
      $('#tpmt_customerValidMsg').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tpmt_customerValidMsg').hide();
      form_is_valid = true;
    }

    var testunit = $('#tpmt_unit').val();
    if(testunit == '' || testunit == undefined){
      $('#tpmt_unitValidMsg').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tpmt_unitValidMsg').hide();
      form_is_valid = true;
    }

    var testmileage = $('#tpmt_mileage').val();
    if(testmileage == '' || testmileage == undefined){
      $('#tpmt_mileageValidMsg').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tpmt_mileageValidMsg').hide();
      form_is_valid = true;
    }

    var testinvoice = $('#tpmt_invoice').val();
    if(testinvoice == '' || testinvoice == undefined){
      $('#tpmt_invoiceValidMsg').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tpmt_invoiceValidMsg').hide();
      form_is_valid = true;
    }
*/ 
    
    return form_is_valid;

  }


  const save = (status) => {

    try {
      for (var i = 0; i < inputList.length; i++) {
        // console.log("filebase64=="+inputList[i].filetobase64);
        var extension = "";
        var byteString = "";
        console.log("results==" + inputList[i].filetobase64);
        if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
          extension = "pdf";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
        } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
          extension = "jpg";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
        }
        else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
          extension = "png";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
        }
       
        else {

        }


        documentList.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
      }
    } catch (exception) {
      // Swal.fire({
      //   position: 'center',
      //   icon: 'error',
      //   title: "",
      //   text: "Please attach document",
      //   showConfirmButton: true

      // }).then((result) => {

      //   if (result.isConfirmed) {


      //   }
      // });
      // return false;
    }

    // console.log("results=="+JSON.stringify(inputList));
    if (status == 'DRAFT') {


      if ($("#inspectedBy").val() === "") {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "",
          text: "Please enter inspectedBy",
          showConfirmButton: true

        }).then((result) => {

          if (result.isConfirmed) {


          }
        });
        return false;
      }

      var requestObjectForInsp =
      {
        "type": "Driver Vehicle Inspection",
        "workOrderNumber": $("#workOrderNumber").val() != '' ? $("#workOrderNumber").val() : '',
        "notes": $("#notes").val() != '' ? $("#notes").val() : '',
        "inspectedBy": $("#inspectedBy").val(),
        "inspectedDate": $("#inspectedDate").val(),
        "status": status,
        "taskOrderId": taskOrderId,
        "verifiedBy": $("#verifiedBy").val(),
        "verifiedDate": $("#verifiedBy").val(),
        "inspectionType": {
          "id": sessionStorage.getItem("selectedinspectionId"),
          "name": sessionStorage.getItem("selectedinspectionType")
        },
        "result": {
            "approvedCertify": "0",
            "inspectionFailed": "0"
        },
        "dispositionOfVehicle": {
            "vehicleReturnedServices": "0",
            "vehicleWithdrawnFromService": "0",
            "vehicleSentToAnotherRepairFacility": "0"
        },
        "vehicleDetails": {
            "vinNumber": $("#vin").html(),
            "lisencePlateNumber": $("#lisencePlateNumber").html(),
            "id": vid,
            "unitNumber": $("#lisencePlateNumber").html()
        },
        "driverVehicleInspection": {
          "truckDetails": {
            "airCompressor": $("input[name='air_compressor']:checked").val() === "1" ? "1" : "0",
            "horn": $("input[name='horn']:checked").val() === "1" ? "1" : "0",
            "springs": $("input[name='springs']:checked").val() === "1" ? "1" : "0",
            "airLines": $("input[name='air_lines']:checked").val() === "1" ? "1" : "0",
            "lights": $("input[name='lights']:checked").val() === "1" ? "1" : "0",
            "starter": $("input[name='starter']:checked").val() === "1" ? "1" : "0",
            "battery": $("input[name='battery']:checked").val() === "1" ? "1" : "0",
            "mirrors": $("input[name='mirrors']:checked").val() === "1" ? "1" : "0",
            "steering": $("input[name='steering']:checked").val() === "1" ? "1" : "0",
            "brakeAccessories": $("input[name='break_accessories']:checked").val() === "1" ? "1" : "0",
            "muffer": $("input[name='muffler']:checked").val() === "1" ? "1" : "0",
            "tachograph": $("input[name='tachograph']:checked").val() === "1" ? "1" : "0",
            "brakes": $("input[name='breaks']:checked").val() === "1" ? "1" : "0",
            "oilPressure": $("input[name='oil_pressure']:checked").val() === "1" ? "1" : "0",
            "tires": $("input[name='tires']:checked").val() === "1" ? "1" : "0",
            "carburetor": $("input[name='carburetor']:checked").val() === "1" ? "1" : "0",
            "transmission": $("input[name='transmission']:checked").val() === "1" ? "1" : "0",
            "clutch": $("input[name='clutch']:checked").val() === "1" ? "1" : "0",
            "onBoardRecorder": $("input[name='onboard_recorder']:checked").val() === "1" ? "1" : "0",
            "wheels": $("input[name='wheels']:checked").val() === "1" ? "1" : "0",
            "defroster": $("input[name='defroster']:checked").val() === "1" ? "1" : "0",
            "radiator": $("input[name='radiator']:checked").val() === "1" ? "1" : "0",
            "windows": $("input[name='windows']:checked").val() === "1" ? "1" : "0",
            "driveLine": $("input[name='drive_line']:checked").val() === "1" ? "1" : "0",
            "rearEnd": $("input[name='rear_end']:checked").val() === "1" ? "1" : "0",
            "windshieldWipers": $("input[name='windshield_wipers']:checked").val() === "1" ? "1" : "0",
            "engine": $("input[name='engine']:checked").val() === "1" ? "1" : "0",
            "refectors": $("input[name='reflectors']:checked").val() === "1" ? "1" : "0",
            "safetyEquipment": $("input[name='safety_equipments']:checked").val() === "1" ? "1" : "0",
            "fifthWheel": $("input[name='fifth_wheel']:checked").val() === "1" ? "1" : "0",
            "frontAxle": $("input[name='front_axle']:checked").val() === "1" ? "1" : "0",
            "fuelTanks": $("input[name='fuel_tanks']:checked").val() === "1" ? "1" : "0",
            "heater": $("input[name='heater']:checked").val() === "1" ? "1" : "0",
            "Other": $("input[name='other']:checked").val() === "1" ? "1" : "0",
          },
          "trailerNos": $('#driverinsp_trailerno').val(),
          "trailerDetails": {
            "brakeConnections": $("input[name='break_connections']:checked").val() === "1" ? "1" : "0",
            "hitch": $("input[name='hitch']:checked").val() === "1" ? "1" : "0",
            "tarpaulin": $("input[name='tarpaulin']:checked").val() === "1" ? "1" : "0",
            "traileBrakes": $("input[name='trailer_breaks']:checked").val() === "1" ? "1" : "0",
            "landingGear": $("input[name='landing_gear']:checked").val() === "1" ? "1" : "0",
            "trailerTires": $("input[name='trailer_tires']:checked").val() === "1" ? "1" : "0",
            "couplingChains": $("input[name='coupling_chains']:checked").val() === "1" ? "1" : "0",
            "lightsAll": $("input[name='all_lights']:checked").val() === "1" ? "1" : "0",
            "trailerWheels": $("input[name='trailer_wheels']:checked").val() === "1" ? "1" : "0",
            "couplingPin": $("input[name='coupling_pin']:checked").val() === "1" ? "1" : "0",
            "roof": $("input[name='roof']:checked").val() === "1" ? "1" : "0",
            "trailerOther": $("input[name='trailer_other']:checked").val() === "1" ? "1" : "0",
            "doors": $("input[name='doors']:checked").val() === "1" ? "1" : "0",
            "trailerSprings": $("input[name='trailer_springs']:checked").val() === "1" ? "1" : "0",
          },
          "otherDetails": {
            "statisfactory": $("input[name='vehicle_satisfactory']:checked").val() === "1" ? "1" : "0",
            "corrected": $("input[name='defects_corrected']:checked").val() === "1" ? "1" : "0",
            "needNotToBeCorrected": $("input[name='defects_nottobecorrected']:checked").val() === "1" ? "1" : "0",
          },
          "remarks": $("#drivervehicle_remarks").val() != '' ? $("#drivervehicle_remarks").val() : '',                                                                     
        },
        "documentList": documentList
      }

      $("#loadingforsubmit").show();
      // setLoading(true);
      //   const requestOptions = 
      //   {
      //      method: 'POST',
      //      headers: { 'Content-Type': 'application/json'
      //               },

      //         body: JSON.stringify(requestObject)
      //   };
      var requestOptions = requestObject.RequestHeader(requestObjectForInsp);
      services.addInspection(requestOptions).then((res) => {
        // setLoading(false);
        $("#loadingforsubmit").hide();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: "",
          text: res.message,
          showConfirmButton: true

        }).then((result) => {

          if (result.isConfirmed) {
            navigate("/vehicleDetails/" + vid);

          }
        });
      });

    } else {
      //alert(handleSubmit());
      if (handleSubmit() == true) {



        if ($("#inspectedBy").val() === "") {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Please enter inspectedBy",
            showConfirmButton: true

          }).then((result) => {

            if (result.isConfirmed) {


            }
          });
          return false;
        }

        var requestObjectForInsp =
        {

          "type": sessionStorage.getItem("selectedinspectionType"),
          "workOrderNumber": $("#workOrderNumber").val() != '' ? $("#workOrderNumber").val() : '',
        "notes": $("#notes").val() != '' ? $("#notes").val() : '',
        "inspectedBy": $("#inspectedBy").val(),
        "inspectedDate": $("#inspectedDate").val(),
        "status": status,
        "taskOrderId": taskOrderId,
        "verifiedBy": $("#verifiedBy").val(),
        "verifiedDate": $("#verifiedBy").val(),
        "inspectionType": {
          "id": sessionStorage.getItem("selectedinspectionId"),
          "name": sessionStorage.getItem("selectedinspectionType")
        },
        "result": {
            "approvedCertify": "0",
            "inspectionFailed": "0"
        },
        "dispositionOfVehicle": {
            "vehicleReturnedServices": "0",
            "vehicleWithdrawnFromService": "0",
            "vehicleSentToAnotherRepairFacility": "0"
        },
        "vehicleDetails": {
            "vinNumber": $("#vin").html(),
            "lisencePlateNumber": $("#lisencePlateNumber").html(),
            "id": vid,
            "unitNumber": $("#lisencePlateNumber").html()
        },
        "driverVehicleInspection": {
          "truckDetails": {
            "airCompressor": $("input[name='air_compressor']:checked").val() === "1" ? "1" : "0",
            "horn": $("input[name='horn']:checked").val() === "1" ? "1" : "0",
            "springs": $("input[name='springs']:checked").val() === "1" ? "1" : "0",
            "airLines": $("input[name='air_lines']:checked").val() === "1" ? "1" : "0",
            "lights": $("input[name='lights']:checked").val() === "1" ? "1" : "0",
            "starter": $("input[name='starter']:checked").val() === "1" ? "1" : "0",
            "battery": $("input[name='battery']:checked").val() === "1" ? "1" : "0",
            "mirrors": $("input[name='mirrors']:checked").val() === "1" ? "1" : "0",
            "steering": $("input[name='steering']:checked").val() === "1" ? "1" : "0",
            "brakeAccessories": $("input[name='break_accessories']:checked").val() === "1" ? "1" : "0",
            "muffer": $("input[name='muffler']:checked").val() === "1" ? "1" : "0",
            "tachograph": $("input[name='tachograph']:checked").val() === "1" ? "1" : "0",
            "brakes": $("input[name='breaks']:checked").val() === "1" ? "1" : "0",
            "oilPressure": $("input[name='oil_pressure']:checked").val() === "1" ? "1" : "0",
            "tires": $("input[name='tires']:checked").val() === "1" ? "1" : "0",
            "carburetor": $("input[name='carburetor']:checked").val() === "1" ? "1" : "0",
            "transmission": $("input[name='transmission']:checked").val() === "1" ? "1" : "0",
            "clutch": $("input[name='clutch']:checked").val() === "1" ? "1" : "0",
            "onBoardRecorder": $("input[name='onboard_recorder']:checked").val() === "1" ? "1" : "0",
            "wheels": $("input[name='wheels']:checked").val() === "1" ? "1" : "0",
            "defroster": $("input[name='defroster']:checked").val() === "1" ? "1" : "0",
            "radiator": $("input[name='radiator']:checked").val() === "1" ? "1" : "0",
            "windows": $("input[name='windows']:checked").val() === "1" ? "1" : "0",
            "driveLine": $("input[name='drive_line']:checked").val() === "1" ? "1" : "0",
            "rearEnd": $("input[name='rear_end']:checked").val() === "1" ? "1" : "0",
            "windshieldWipers": $("input[name='windshield_wipers']:checked").val() === "1" ? "1" : "0",
            "engine": $("input[name='engine']:checked").val() === "1" ? "1" : "0",
            "refectors": $("input[name='reflectors']:checked").val() === "1" ? "1" : "0",
            "safetyEquipment": $("input[name='safety_equipments']:checked").val() === "1" ? "1" : "0",
            "fifthWheel": $("input[name='fifth_wheel']:checked").val() === "1" ? "1" : "0",
            "frontAxle": $("input[name='front_axle']:checked").val() === "1" ? "1" : "0",
            "fuelTanks": $("input[name='fuel_tanks']:checked").val() === "1" ? "1" : "0",
            "heater": $("input[name='heater']:checked").val() === "1" ? "1" : "0",
            "Other": $("input[name='other']:checked").val() === "1" ? "1" : "0",
          },
          "trailerNos": $('#driverinsp_trailerno').val(),
          "trailerDetails": {
            "brakeConnections": $("input[name='break_connections']:checked").val() === "1" ? "1" : "0",
            "hitch": $("input[name='hitch']:checked").val() === "1" ? "1" : "0",
            "tarpaulin": $("input[name='tarpaulin']:checked").val() === "1" ? "1" : "0",
            "traileBrakes": $("input[name='trailer_breaks']:checked").val() === "1" ? "1" : "0",
            "landingGear": $("input[name='landing_gear']:checked").val() === "1" ? "1" : "0",
            "trailerTires": $("input[name='trailer_tires']:checked").val() === "1" ? "1" : "0",
            "couplingChains": $("input[name='coupling_chains']:checked").val() === "1" ? "1" : "0",
            "lightsAll": $("input[name='all_lights']:checked").val() === "1" ? "1" : "0",
            "trailerWheels": $("input[name='trailer_wheels']:checked").val() === "1" ? "1" : "0",
            "couplingPin": $("input[name='coupling_pin']:checked").val() === "1" ? "1" : "0",
            "roof": $("input[name='roof']:checked").val() === "1" ? "1" : "0",
            "trailerOther": $("input[name='trailer_other']:checked").val() === "1" ? "1" : "0",
            "doors": $("input[name='doors']:checked").val() === "1" ? "1" : "0",
            "trailerSprings": $("input[name='trailer_springs']:checked").val() === "1" ? "1" : "0",
          },
          "otherDetails": {
            "statisfactory": $("input[name='vehicle_satisfactory']:checked").val() === "1" ? "1" : "0",
            "corrected": $("input[name='defects_corrected']:checked").val() === "1" ? "1" : "0",
            "needNotToBeCorrected": $("input[name='defects_nottobecorrected']:checked").val() === "1" ? "1" : "0",
          },
          "remarks": $("#drivervehicle_remarks").val() != '' ? $("#drivervehicle_remarks").val() : '',                                                                     
        },
        "documentList": documentList

        };

        console.log(requestObjectForInsp);
        //return false;

        $("#loadingforsubmit").show();
        // setLoading(true);
        //   const requestOptions = 
        //   {
        //      method: 'POST',
        //      headers: { 'Content-Type': 'application/json'
        //               },

        //         body: JSON.stringify(requestObject)
        //   };
        var requestOptions = requestObject.RequestHeader(requestObjectForInsp);
        services.addInspection(requestOptions).then((res) => {
          // setLoading(false);
          $("#loadingforsubmit").hide();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: "",
            text: res.message,
            showConfirmButton: true

          }).then((result) => {

            if (result.isConfirmed) {
              navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

            }
          });
        });
      }
    }


  }

  const resetAllValues = () => {
    setFromDate('');
  }

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, {}]);
  };
  const fileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(null, reader.result)
    }
    reader.onerror = function (error) {
      cb(error, null)
    }
  }
  const onUploadFileChange = (event, index) => {


    var target = event.target;
    if (target.files < 1 || !target.validity.valid) {
      return
    }
    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        const { name, value } = event.target;
        const list = [...inputList];
        list[index][name] = result;
        setBase64file(result);
        // setFile(result)
        setfileName(target.files[0]);
        // setFileName(target.files[0])
      }
    })
  }

  return (
    <>
    {LoadingComponentForSubmit}
      <div class="row">
                <div class="col-lg-12 col-md-12 m-auto ">
                    <div class="card">
                        <div class="row">
                            <div class="w-100 px-3">
                                <h4 class="mb-2 form-inspection-title text-center">Driver's Vehicle Inspection Report</h4>
                              {/* <div>
                              <p id="leakage-check-type-message" class="error"></p>
                              <p id="unitDesignPressure-message" class="error"></p> 
                              <p id="testPressure-message" class="error"></p>
                              <p id="error_message" class="center"></p>
                              </div> */}
                            </div>
                            
                            <div class="col-lg-12 mx-0"><WorkOrderNumberDiv /></div>
                            
                            <div class="px-3">
                                <div class="inspection-wrap">
                                    <div class="row mb-3 ">
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="air_compressor" value="1" class="mr-2"/>
                                                <span>Air Compressor</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="air_lines" value="1" class="mr-2" />
                                                <span>Air Lines</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="battery" value="1" class="mr-2" />
                                                <span>Battery</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="break_accessories" value="1" class="mr-2" />
                                                <span>Brake Accessories</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="breaks" value="1" class="mr-2" />
                                                <span>Brakes</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="carburetor" value="1" class="mr-2" />
                                                <span>Carburetor</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="clutch" value="1" class="mr-2" />
                                                <span>Clutch</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="defroster" value="1" class="mr-2" />
                                                <span>Defroster</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="drive_line" value="1" class="mr-2" />
                                                <span>Drive Line</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="engine" value="1" class="mr-2" />
                                                <span>Engine</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="fifth_wheel" value="1" class="mr-2" />
                                                <span>Fifth Wheel</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="front_axle" value="1" class="mr-2" />
                                                <span>Front Axle</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="fuel_tanks" value="1" class="mr-2" />
                                                <span>Fuel Tanks</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="heater" value="1" class="mr-2" />
                                                <span>Heater</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="horn" value="1" class="mr-2" />
                                                <span>Horn</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex ">
                                                <input type="checkbox" name="lights" value="1" class="mr-2" style={{minHeight:20, maxHeight:20,}} />
                                                <span>Lights<br/>Head - Stop<br/>Tail - Dash<br/>Turn Indicators</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="mirrors" value="1" class="mr-2" />
                                                <span>Mirrors</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="muffler" value="1" class="mr-2" />
                                                <span>Muffler</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="oil_pressure" value="1" class="mr-2" />
                                                <span>Oil Pressure</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="onboard_recorder" value="1" class="mr-2" />
                                                <span>On-Board Recorder</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="radiator" value="1" class="mr-2" />
                                                <span>Radiator</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="rear_end" value="1" class="mr-2" />
                                                <span>Rear End</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="reflectors" value="1" class="mr-2" />
                                                <span>Reflectors</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex ">
                                                <input type="checkbox" name="safety_equipments" value="1" class="mr-2" style={{minHeight:20, maxHeight:20,}} />
                                                <span>Safety Equipments<br/>Fire Extinguisher<br/>Flags - Flares - Fuses<br/>Spare Bulbs & Fuses<br/>Spare Seal Beam</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="springs" value="1" class="mr-2" />
                                                <span>Springs</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="starter" value="1" class="mr-2" />
                                                <span>Starter</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="steering" value="1" class="mr-2" />
                                                <span>Steering</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="tachograph" value="1" class="mr-2" />
                                                <span>Tachograph</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="tires" value="1" class="mr-2" />
                                                <span>Tires</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="transmission" value="1" class="mr-2" />
                                                <span>Transmission</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="wheels" value="1" class="mr-2" />
                                                <span>Wheels</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="windows" value="1" class="mr-2" />
                                                <span>Windows</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="windshield_wipers" value="1" class="mr-2" />
                                                <span>Windshield Wipers</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="other" value="1" class="mr-2" />
                                                <span>Other</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="w-100 px-3 border-bottom">
                                <div class="row mb-2 mt-2">
                                    <div class="col-lg-6 mb-2 d-flex align-items-center">
                                        <div class="d-flex align-items-center" style={{ minWidth: "150px" }}>
                                            <span>Trailer(s) No(s) :</span>

                                        </div>
                                        <div id="otherField">
                                            <div class="ml-2"> <div class="d-flex align-items-center"> <input type="input" id="driverinsp_trailerno" name="driverinsp_trailerno" aria-describedby="emailHelp" style={{ width: "100px" }} class="form-control"/>
                                            <div id="driverinsp_trailernoValidMsg" class="error">Trailer Number is required.</div> </div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="px-3">
                                <div class="inspection-wrap">
                                    <div class="row mb-3 ">
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="break_connections" value="1" class="mr-2" />
                                                <span>Brake Connections</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="trailer_breaks" value="1" class="mr-2" />
                                                <span>Brakes</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="coupling_chains" value="1" class="mr-2" />
                                                <span>Coupling Chains</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="coupling_pin" value="1" class="mr-2" />
                                                <span>Coupling (King) Pin</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="doors" value="1" class="mr-2" />
                                                <span>Doors</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="hitch" value="1" class="mr-2" />
                                                <span>Hitch</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="landing_gear" value="1" class="mr-2" />
                                                <span>Landing Gear</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="all_lights" value="1" class="mr-2" />
                                                <span>Lights - All</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="roof" value="1" class="mr-2" />
                                                <span>Roof</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="trailer_springs" value="1" class="mr-2" />
                                                <span>Springs</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="tarpaulin" value="1" class="mr-2" />
                                                <span>Tarpaulin</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="trailer_tires" value="1" class="mr-2" />
                                                <span>Tires</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="trailer_wheels" value="1" class="mr-2" />
                                                <span>Wheels</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="trailer_other" value="1" class="mr-2" />
                                                <span>Other</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Remarks</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-8 mb-2">
                                            <div class="d-flex align-items-center">
                                            <textarea class="form-control my-3" name="drivervehicle_remarks" id="drivervehicle_remarks" rows="3" maxLength="300" style={{minHeight:"180px"}}></textarea>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="divider2"></div>
                        <div class="row py-3">
                            <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                        </div>

                        <div class="divider2"></div>
                        <div class="row py-3 ">
                                        <div class="col-lg-12 mb-0">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="vehicle_satisfactory" value="1" class="mr-2" style={{minHeight:20, maxHeight:20,}}/>
                                                <span>Condition of the above vehicle is satisfactory</span>
                                            </div>
                                        </div>
                                        {/*<div class="col-lg-12 mb-2 d-flex align-items-center">
                                          <div class="d-flex align-items-center" style={{ minWidth: "90px" }}>
                                              <span>Driver's Signature</span>

                                          </div>
                                          <div id="otherField">
                                              <div class="ml-2"> <div class="d-flex align-items-center"> 
                                              <input type="input" id="driver_signature" name="driver_signature" aria-describedby="emailHelp" style={{ width: "100px" }} class="form-control"/>
                                              <div id="driver_signatureValidMsg" class="error">Driver Signature is required.</div> </div></div>
                                          </div>
                                      </div>
                                      
                                        <div class="col-lg-12 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="defects_corrected" value="1" class="mr-2" />
                                                <span>Above Defects Corrected</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="defects_nottobecorrected" value="1" class="mr-2" />
                                                <span>Above Defects Need Not be Corrected for Safe Operation of Vehicle</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 mb-2 d-flex align-items-center">
                                          <div class="d-flex align-items-center" style={{ minWidth: "90px" }}>
                                              <span>Mechanic's Signature</span>

                                          </div>
                                          <div id="otherField">
                                              <div class="ml-2"> <div class="d-flex align-items-center"> 
                                              <input type="input" id="mechanic_signature" name="mechanic_signature" aria-describedby="emailHelp" style={{ width: "100px" }} class="form-control"/>
                                              
                                              <div id="mechanic_signatureValidMsg" class="error">Mechanic Signature is required.</div> </div></div>
                                          </div>
                                      </div>
                                      <div class="col-lg-6 mb-2 d-flex align-items-center">
                                          <div class="d-flex align-items-center" style={{ minWidth: "90px" }}>
                                              <span>Date</span>

                                          </div>
                                          <div id="otherField">
                                              <div class="ml-2"> <div class="d-flex align-items-center"> 
                                              <DatePicker id="mechanic_sigdate" selected={fromDate} onChange={(date) => setFromDate(date)} name="mechanic_sigdate" class="form-control" placeholderText="MM/DD/YYYY" size="10"/>
                                              <div id="mechanic_sigdateValidMsg" class="error">Date is required.</div> </div></div>
                                          </div>
                                      </div>
                                      
                                      <div class="col-lg-6 mb-2 d-flex align-items-center">
                                          <div class="d-flex align-items-center" style={{ minWidth: "90px" }}>
                                              <span>Driver's Signature</span>

                                          </div>
                                          <div id="otherField">
                                              <div class="ml-2"> <div class="d-flex align-items-center"> 
                                              <input type="input" id="driver_signature1" name="driver_signature1" aria-describedby="emailHelp" style={{ width: "100px" }} class="form-control"/>
                                              
                                              <div id="driver_signature1ValidMsg" class="error">Driver Signature is required.</div> </div></div>
                                          </div>
                                      </div>
                                      <div class="col-lg-6 mb-2 d-flex align-items-center">
                                          <div class="d-flex align-items-center" style={{ minWidth: "90px" }}>
                                              <span>Date</span>

                                          </div>
                                          <div id="otherField">
                                              <div class="ml-2"> <div class="d-flex align-items-center">
                                              <DatePicker id="driver_sigdate" selected={fromDate} onChange={(date) => setFromDate(date)} name="driver_sigdate" class="form-control" placeholderText="MM/DD/YYYY" size="10"/>
                                              <div id="driver_sigdateValidMsg" class="error">Date is required.</div> </div></div>
                                          </div>
                                      </div> */}
                                    </div>

                        <AddInspectionName setEndDate={setEndDate} endDate={endDate} />

                        <div class="divider2"></div>
                        <div class="row p-3 pr-4 d-flex justify-content-end" style={{ marginBottom: "-20px" }}>
                            <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
                            <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a>
                            <a href="#" class="btn btn-primary mr-1" onClick={() => save("APPROVED PENDING")}>Save</a>
                        </div>
                    </div>
                </div>
            </div>

    </>
  )
};

DriverVehicleTest.propTypes = {};

DriverVehicleTest.defaultProps = {};

export default DriverVehicleTest;
