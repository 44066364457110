import React,{history,useEffect, useState, useMemo } from "react";

const UpcomingInspection = () => {

  return(
  <>
    <div class="main-panel">
                <div class="content-wrapper" style={{display: "block"}}>
                    <div class="row">
                        <div class="col-lg-11 col-md-12 mb-2 m-auto">
                            <div class="d-flex mb-3 align-items-center justify-content-between">
                                <h4 class="font-weight-bold text-uppercase">Reminder</h4>
                                <a   class="btn btn-white">Back</a>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <div class="card mb-2">
                                <div class="col-md-12 pt-4 pr-4 pl-4 d-flex justify-content-between">
                                    <h3>Upcoming Inspection</h3>
                                    <a class="btn btn-success">Received Vehicle</a>
                                </div>
                                <div class="vehicle_duty_wp d-flex p-4">
                                    <div class="vehicle_dtls ">
                                        <label>Inspection Name</label>
                                        <h4 class="mb-0">Leakage Test Inspection</h4>
                                    </div>
                                    <div class="vehicle_dtls">
                                        <label>Next Inspection Date</label>
                                        <h4 class="mb-0">12-08-2021 <span class="badge badge-warning">2 days left</span></h4>
                                    </div>
                                    <div class="vehicle_dtls">
                                        <label>Prev Inspection Date</label>
                                        <h4 class="mb-0">13-02-2021</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <div class="row no-gutters">
                                <div class="col-md-4">
                                    <div class="card mr-2">
                                        <div class="vehicle_pic_wrap">
                                            <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#editPhoto">
                                       <img src="/images/icons/edit-icon.svg"/></a>
                                 </span>
                                            <img src="/images/vehicle_info.png" class="vehicle-pic"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="card">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="p-3 d-flex flex-column">
                                                    <label for="" class="opacity-7">Fleet Owners Unit Number </label>
                                                    <b>302</b>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="p-3 d-flex flex-column">
                                                    <label for="" class="opacity-7">VIN Number </label>
                                                    <b>IPMS44324T1017489</b>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="p-3 d-flex flex-column">
                                                    <label for="" class="opacity-7">Serial Number</label>
                                                    <b>IPMS44324T1017489</b>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="p-3 d-flex flex-column">
                                                    <label for="" class="opacity-7">Make</label>
                                                    <b>Polar</b>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="p-3 d-flex flex-column">
                                                    <label for="" class="opacity-7">Model</label>
                                                    <b>F150</b>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="p-3 d-flex flex-column">
                                                    <label for="" class="opacity-7">Year</label>
                                                    <b>1996</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-2 ">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <div class="card mb-2">
                                <div class="row mb-2">
                                    <div class="col-lg-12 col-md-12 m-auto grid-margin stretch-card ">
                                        <div class="header-top-bg p-3 ">
                                            <h4>Vehicle Information</h4>
                                            <div class="info-labl">
                                                <img src="/images/icons/vehicle-icon.svg" alt=""/>|<span>ERS 4343</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="p-3 d-flex flex-column">
                                            <label for="" class="opacity-7">Vehicle Type</label>
                                            <b>Trailer</b>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="p-3 d-flex flex-column">
                                            <label for="" class="opacity-7">Trailer Sub Type</label>
                                            <b>Tanker</b>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="p-3 d-flex flex-column">
                                            <label for="" class="opacity-7">Tanker Sub Type</label>
                                            <b>Fuel</b>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="p-3 d-flex flex-column">
                                            <label for="" class="opacity-7">Insulated</label>
                                            <b>Yes</b>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="p-3 d-flex flex-column">
                                            <label for="" class="opacity-7">Lined</label>
                                            <b>Yes</b>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="p-3 d-flex flex-column">
                                            <label for="" class="opacity-7">Lining Type</label>
                                            <b>XXXX</b>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="p-3 d-flex flex-column">
                                            <label for="" class="opacity-7">Capacity in Gallons</label>
                                            <b>5000</b>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="p-3 d-flex flex-column">
                                            <label for="" class="opacity-7">Compartmented</label>
                                            <b>Yes</b>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="p-3 d-flex flex-column">
                                            <label for="" class="opacity-7">Number of compartments</label>
                                            <b>5</b>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="p-3 d-flex flex-column">
                                            <label for="" class="opacity-7">Capacity</label>
                                            <b>5000, 5000, 5000, n/a, n/a, n/a</b>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="p-3 d-flex flex-column">
                                            <label for="" class="opacity-7">Installed by Manufacturer/Retrofitted</label>
                                            <b>xxxxxxxxxxxx</b>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="p-3 d-flex flex-column">
                                            <label for="" class="opacity-7">Cargo</label>
                                            <b>Fuel</b>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="p-3 d-flex flex-column">
                                            <label for="" class="opacity-7">Is installed with a vapor collection system</label>
                                            <b>Yes</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Modal --> */}
                <div class="modal fade" id="editPhoto" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Edit Photo</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="search position-relative w-100">
                                            <input type="search" id="gsearch" name="gsearch" placeholder="Search driver" class="pl-5 w-100"/>
                                            <a href="#" class="search-icon"> <img src="/images/icons/search.svg" alt=""/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  </>
)};

UpcomingInspection.propTypes = {};

UpcomingInspection.defaultProps = {};

export default UpcomingInspection;
