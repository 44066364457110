import React,{history,useEffect, useState, useMemo } from "react";
import Swal from "sweetalert2";
import { useNavigate ,useParams} from "react-router-dom";
import { requestObject } from "../../../../utility/requestObject";
import  $ from "jquery";
import services from "../../../../services/services";
import {useLoading} from 'react-hook-loading';
import DatePicker from "react-datepicker";
import Adddocs from "../../../../utility/Adddocs";
import CheckAuthentication from "../../../../utility/checkAuthentication";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { scrollTop } from "../../../../utility/ScrollTop";

const EditExternalVisualSectionThree = () => 
{

    const { fkAccountId, inspectionId, vid } = useParams();
    
  useEffect(() => {
   
    scrollTop();
    getSection3();
    getInpection(fkAccountId, inspectionId);
 
  }, []);

  function getInpection(fkAccountId, inspectionId) {
    var requestObjectForWet =
    {
        
        "id": inspectionId
    }

    var requestOptions = requestObject.RequestHeader(requestObjectForWet);

    services.getInspectionDetails(requestOptions).then((res) => {

      const workOrderNumber=sessionStorage.getItem("workOrderNumber");
         if(workOrderNumber==null)
         {
         $("#workOrderNumber").val(res.rows?res.rows.workOrderNumber:"")
         }else{
         sessionStorage.setItem("workOrderNumber",workOrderNumber);
         }

        var obj = res.rows.externalVisualInspection.section3;
        // alert(res.rows.externalVisualInspection.section3.inoperative);
        var inoperative = obj == null ? '' : obj.inoperative;
    var doomcover = obj == null ? '' : obj.doomcover;
    var gaskets = obj == null ? '' : obj.gaskets;
    var vaccumevent = obj == null ? '' : obj.vaccumevent;
    var popofvalves = obj == null ? '' : obj.popofvalves;
    var reputerDisc = obj == null ? '' : obj.reputerDisc;
    var pressureGauage = obj == null ? '' : obj.pressureGauage;
    var hairHookup = obj == null ? '' : obj.hairHookup;
    var fusible = obj == null ? '' : obj.fusible;
    var bleeder = obj == null ? '' : obj.bleeder;
    var port = obj == null ? '' : obj.port;
    var drainsClogged = obj == null ? '' : obj.drainsClogged;
    var spillcollar = obj == null ? '' : obj.spillcollar;
    var leakofAnyKind = obj == null ? '' : obj.leakofAnyKind;

    $('input[name="step3inoperative"][value="' + inoperative + '"]').trigger("click");
    $('input[name="step3cover"][value="' + doomcover + '"]').trigger("click");
    $('input[name="step3gaskets"][value="' + gaskets + '"]').trigger("click");
    $('input[name="step3vacuum"][value="' + vaccumevent + '"]').trigger("click");
    $('input[name="step3offvalve"][value="' + popofvalves + '"]').trigger("click");
    $('input[name="step3rupture"][value="' + reputerDisc + '"]').trigger("click");
    $('input[name="step3gauge"][value="' + pressureGauage + '"]').trigger("click");
    $('input[name="step3hookup"][value="' + hairHookup + '"]').trigger("click");
    $('input[name="step3fusible"][value="' + fusible + '"]').trigger("click");
    $('input[name="step3bleeder"][value="' + bleeder + '"]').trigger("click");
    $('input[name="step3cleanout"][value="' + port + '"]').trigger("click");
    $('input[name="step3clogged"][value="' + drainsClogged + '"]').trigger("click");
    $('input[name="step3spill"][value="' + spillcollar + '"]').trigger("click");
    $('input[name="step3leaksofany"][value="' + leakofAnyKind + '"]').trigger("click");
    
    sectionSubmit();

    });

}

  function getSection3() {

    JSON.parse(sessionStorage.getItem("section3"));

    console.log("ddd" + JSON.parse(sessionStorage.getItem("section3")));

    var obj = JSON.parse(sessionStorage.getItem("section3"))
    console.log(obj == null ? '' : obj.corrodedOrAbradedAreas);

    // setSection1(obj);
    // console.log("section Data" + JSON.stringify(section1));

    var inoperative = obj == null ? '' : obj.inoperative;
    var doomcover = obj == null ? '' : obj.doomcover;
    var gaskets = obj == null ? '' : obj.gaskets;
    var vaccumevent = obj == null ? '' : obj.vaccumevent;
    var popofvalves = obj == null ? '' : obj.popofvalves;
    var reputerDisc = obj == null ? '' : obj.reputerDisc;
    var pressureGauage = obj == null ? '' : obj.pressureGauage;
    var hairHookup = obj == null ? '' : obj.hairHookup;
    var fusible = obj == null ? '' : obj.fusible;
    var bleeder = obj == null ? '' : obj.bleeder;
    var port = obj == null ? '' : obj.port;
    var drainsClogged = obj == null ? '' : obj.drainsClogged;
    var spillcollar = obj == null ? '' : obj.spillcollar;
    var leakofAnyKind = obj == null ? '' : obj.leakofAnyKind;

    $('input[name="step3inoperative"][value="' + inoperative + '"]').trigger("click");
    $('input[name="step3cover"][value="' + doomcover + '"]').trigger("click");
    $('input[name="step3gaskets"][value="' + gaskets + '"]').trigger("click");
    $('input[name="step3vacuum"][value="' + vaccumevent + '"]').trigger("click");
    $('input[name="step3offvalve"][value="' + popofvalves + '"]').trigger("click");
    $('input[name="step3rupture"][value="' + reputerDisc + '"]').trigger("click");
    $('input[name="step3gauge"][value="' + pressureGauage + '"]').trigger("click");
    $('input[name="step3hookup"][value="' + hairHookup + '"]').trigger("click");
    $('input[name="step3fusible"][value="' + fusible + '"]').trigger("click");
    $('input[name="step3bleeder"][value="' + bleeder + '"]').trigger("click");
    $('input[name="step3cleanout"][value="' + port + '"]').trigger("click");
    $('input[name="step3clogged"][value="' + drainsClogged + '"]').trigger("click");
    $('input[name="step3spill"][value="' + spillcollar + '"]').trigger("click");
    $('input[name="step3leaksofany"][value="' + leakofAnyKind + '"]').trigger("click");

  }

  const sectionSubmit = () => {
    var section3= {
      "inoperative": $("input[name='step3inoperative']:checked").val()==="1"?"1":"0",
      "doomcover": $("input[name='step3cover']:checked").val()==="1"?"1":"0",
      "gaskets":$("input[name='step3gaskets']:checked").val()==="1"?"1":"0",
      "vaccumevent":$("input[name='step3vacuum']:checked").val()==="1"?"1":"0",
      "popofvalves":$("input[name='step3offvalve']:checked").val()==="1"?"1":"0",
      "reputerDisc":$("input[name='step3rupture']:checked").val()==="1"?"1":"0",
      "pressureGauage":$("input[name='step3gauge']:checked").val()==="1"?"1":"0",
      "hairHookup":$("input[name='step3hookup']:checked").val()==="1"?"1":"0",
      "fusible":$("input[name='step3fusible']:checked").val()==="1"?"1":"0",
      "bleeder":$("input[name='step3bleeder']:checked").val()==="1"?"1":"0",
      "port":$("input[name='step3cleanout']:checked").val()==="1"?"1":"0",
      "drainsClogged":$("input[name='step3clogged']:checked").val()==="1"?"1":"0",
      "spillcollar":$("input[name='step3spill']:checked").val()==="1"?"1":"0",
      "leakofAnyKind":$("input[name='step3leaksofany']:checked").val()==="1"?"1":"0",
      
    };
    console.log(JSON.stringify(section3));
  
    sessionStorage.setItem("section3",JSON.stringify(section3));

  }

  const handleSubmit=()=>
  {

let form_is_valid = true;

var step3inoperative=$("input[name='step3inoperative']:checked").val();
var section3dents=$("input[name='step3cover']:checked").val();
var step3gaskets=$("input[name='step3gaskets']:checked").val();
var step3vacuum=$("input[name='step3vacuum']:checked").val();
var step3offvalve=$("input[name='step3offvalve']:checked").val();
var step3rupture=$("input[name='step3rupture']:checked").val();
var step3gauge=$("input[name='step3gauge']:checked").val();
var step3hookup=$("input[name='step3hookup']:checked").val();
var step3fusible=$("input[name='step3fusible']:checked").val();
var step3bleeder=$("input[name='step3bleeder']:checked").val();
var step3cleanout=$("input[name='step3cleanout']:checked").val();
var step3clogged=$("input[name='step3clogged']:checked").val();
var step3spill=$("input[name='step3spill']:checked").val();
var step3leaksofany=$("input[name='step3leaksofany']:checked").val();


console.log(step3inoperative+''+section3dents+''+step3gaskets+''+step3vacuum+''+step3offvalve+''+step3rupture+''+step3gauge+''+step3hookup+''+step3fusible+''+step3bleeder+''+step3cleanout+''+step3clogged+''+step3spill+''+step3leaksofany);

if(step3inoperative==undefined||section3dents==undefined||step3gaskets==undefined||step3vacuum==undefined||step3offvalve==undefined||step3rupture==undefined||step3gauge==undefined||step3hookup==undefined||step3bleeder==undefined||step3clogged==undefined||step3spill==undefined||step3cleanout==undefined||step3leaksofany==undefined||step3fusible==undefined){
  // $('html, body').animate({
  //   scrollTop: $("#inspectionType").offset().top
  // }, 500);
  $('html,body').animate({ scrollTop: "500px" }, 1000);
  $('#error_message').css("color", "#d33232").html("*Please choose one option for each question");
  form_is_valid= false;

}else{
  $('#error_message').html(''); 
}
// alert(form_is_valid);
if(form_is_valid==true){
$("#section4").trigger('click');
}

return form_is_valid;

}


    
  return(
  <>

                
               

                        <div class="card">

                          {/* <div class="divider"></div> */}
                            <div class="row p-3">
                            <div class="w-100">
                              <h4 class="form-inspection-title text-center">External / Visual Inspection</h4>
                              <p id="error_message"></p>
                            </div>
                          <WorkOrderNumberDiv />
                                <div class="col-lg-12 px-0">
                               
                                    <div class="inspection-wrap">

                                    <div class="step-wrap p-3 mb-3">
                                             <div class="step-main d-flex">
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt=""/></div>
                                                     <div class="labl-section">Tank Shell</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt=""/></div>
                                                     <div class="labl-section">External Piping</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 current-tick">3</div>
                                                     <div class="labl-section">Spill Collar</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2">4</div>
                                                     <div class="labl-section">Emergency Devices</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2">5</div>
                                                     <div class="labl-section">Bolts</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2">6</div>
                                                     <div class="labl-section">Vehicle Condition</div>
                                                 </div>
                                             </div>
                                        </div>

                                        <div class="table-2">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Section 3: Spill Collar / Manhole are there any :</th>                                                 
                                                    <th scope="col">Yes</th>
                                                    <th scope="col">No</th>                                              
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                   <td>Manhole cover hold-downs stripped/inoperative </td>
                                                    <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3inoperative" value="1" onChange={() => sectionSubmit()}/></td>
                                                    <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3inoperative" value="0" onChange={() => sectionSubmit()}/></td>
                                                  </tr>

                                                  <tr>
                                                    <td>Defects in the dome cover  </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3cover" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3cover" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>Defects in gaskets </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3gaskets" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3gaskets" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>Defects in vacuum vent</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3vacuum" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3vacuum" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                   {/* <tr>
                                                    <td>Defects in dust cap </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3dustcap" value="1"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3dustcap" value="0"/></td>
                                                   </tr> */}

                                                   <tr>
                                                    <td>Defects in pop off valve </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3offvalve" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3offvalve" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>    

                                                   <tr>
                                                    <td>Defects in rupture disc  </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3rupture" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3rupture" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr> 

                                                   <tr>
                                                    <td> Defects in air pressure gauge </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3gauge" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3gauge" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>  

                                                   <tr>
                                                    <td>Defects in air hook up </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3hookup" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3hookup" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr> 

                                                   <tr>
                                                    <td>Defects in fusible disc / cap </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3fusible" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3fusible" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr> 

                                                   <tr>
                                                    <td>Defects in air inlet valve / bleeder</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3bleeder" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3bleeder" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr> 

                                                   <tr>
                                                    <td> Defects in cleanout ports or gaskets</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3cleanout" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3cleanout" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>  

                                                   <tr>
                                                    <td> Spill collar drains clogged</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3clogged" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3clogged" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>  

                                                   <tr>
                                                    <td>Defects in spill collar </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3spill" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3spill" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>  

                                                   <tr>
                                                    <td>Leaks of any kind </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3leaksofany" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step3leaksofany" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>  
                                                </tbody>
                                              </table>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="divider2"></div>
                            <div class="row p-3 pr-4 d-flex justify-content-end" style={{marginBottom:"-20px"}}>
                             <a id="section2" class="btn btn-primary mr-1 ">Back</a>
                                {/* <a href="#" class="btn btn-secondary mr-1 ">Next</a>
                                <a href="#" class="btn btn-primary mr-1" onClick={() => save("draft")}>Cancel </a> */}
                                {/* <a  class="btn btn-primary mr-1" id="section4">Next</a> */}
                                <a id="section4" class="btn btn-primary mr-1" style={{display:"none"}}>Next</a>
                                <a  class="btn btn-primary mr-1"  onClick={() => handleSubmit()}>Next</a>
                            </div>
                           </div>
        
                 
          
  </>
)};

EditExternalVisualSectionThree.propTypes = {};

EditExternalVisualSectionThree.defaultProps = {};

export default EditExternalVisualSectionThree;