import React,{history,useEffect, useState, useMemo } from "react";
import services from "../../../services/services";
import $ from "jquery";
import { requestObject } from "../../../utility/requestObject";
import { itemperPagetoDisplay } from "../../constant";
const inspectionList = (id,pageNumber,ITEMS_PER_PAGE,setInspectionList,setTotalInspectionList,settotalinspectionPages,setInspectionCurrentPage,setdisplayPageForInspection,inspectionType ) => 
{
 
   var inspectionRequest=
   {
             "accountId": "1",
             "vehicleId":id,
             "inspectionType":inspectionType,
             "pageNumber":pageNumber,
             "pageSize":ITEMS_PER_PAGE,
             "sortBy":"",
             "sortDir":"",
          
   };
  
   // const requestOptions = 
   // {
   //       method: 'POST',
   //       headers:{'Content-Type': 'application/json'},
   //       body: JSON.stringify(inspectionRequest)
   // };
 
   var requestOptions=requestObject.RequestHeader(inspectionRequest);
   services.getInspectionList(requestOptions).then((res) => 
   {
      if (res.success) {
        
         if(res.rows.length>0)
         {

            setInspectionList(res ? res.rows : "");
            setTotalInspectionList(res.customValues.totalNumberOfRecords);
            settotalinspectionPages(res.customValues.totalNumberOfRecords);
            setInspectionCurrentPage(res.customValues.currentPageNumber);
            var displaypages=[];
               
            for(var i=1;i<=parseInt(Math.ceil(res.customValues.totalNumberOfRecords/itemperPagetoDisplay));i++)
            {
               displaypages.push(i*itemperPagetoDisplay);
            }
         
            setdisplayPageForInspection(displaypages);

         }else{
            setInspectionList("");
            setTotalInspectionList("0");
            setInspectionCurrentPage("1");
      
            
         }
      
     }
     else{
   
      setInspectionList("");
      setTotalInspectionList("0");
      setInspectionCurrentPage("1");
     }
   });
      
}

export default inspectionList;