import React,{history,useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useNavigate,useParams } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';
import { ErrorMessage } from "@hookform/error-message";
//import checkAuthentication from "../../../utility/checkAuthentication";
//import { keycloak } from '../../../utility/keycloakConfig';
//import accountType from '../../../utility/keycloakConfig';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReCAPTCHA from "react-google-recaptcha";
import Keycloak from 'keycloak-js';
import validator from 'validator';
import { useLoading } from "react-hook-loading";
import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import { keycloak } from "../../../utility/config";
import jwt_decode from 'jwt-decode'
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { loadingComponent } from "../../../utility/Loading";
import Swal from "sweetalert2";
import { Block } from "@material-ui/icons";
import { Carousel } from 'react-responsive-carousel';
import { useCookies } from 'react-cookie';
import CarouselComponent from "./CarouselComponent";
import UsNumberValidate from "../../../utility/usNumberValidate";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
import { SiteKey } from "../../constant";
const pageBodyWrapper={
display:"block",
marginTop:0
}

var pathName = window.location.pathname;

const Login = () =>
{


    const {
        register,
        formState: { errors },
        handleSubmit
     } = useForm({
        criteriaMode: "all", mode: "onKeyup",
     });
   const [cookies, setCookie] = useCookies(['_AK','_RK']);
   const [authenticate, setAuthenticate] = useState(false);
   const navigate = useNavigate();
   const [emailError, setEmailError] = useState('');
   const [itemName, setFieldName] = useState('');
   const [captch, setcaptcha] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const validateEmail = (e) =>
   {
     var email = e.target.value.replace(/^\s+|\s+$/gm,'');

     if (validator.isEmail(email.trim()))
     {
       setEmailError('');
     }
     else
     {
       setEmailError('Invalid email address')
     }

   }
//    const {
//      register,
//      formState: { errors },
//      handleSubmit
//    } = useForm({
//      criteriaMode: "all",mode: "onBlur"
//    });

function onChange(value) {
    setcaptcha(value)

  }


   useEffect(() =>
   {

    $('#edit_mobile').usPhoneFormat();
    $("#mydiv").removeClass("container-fluid page-body-wrapper");

    if (keycloak.authenticated)
    {

      if(keycloak.token!="")
      {

        if(localStorage.getItem("realm")!==null)
        {

        setAuthenticate(true);
        setCookie('_AK', keycloak.token,{"path":"/",maxAge:"1704085200"});
        setCookie('_RK', keycloak.refreshToken,{"path":"/",maxAge:"1704085200"});
        setCookie('_ET', keycloak.tokenParsed.exp,{"path":"/",maxAge:"1704085200"});
        // localStorage.setItem("accToken",keycloak.token);
        sessionStorage.setItem("fkAccountId","1");
        var accessTokenT=keycloak.token;
        var decoded = jwt_decode(accessTokenT);
        var rolemanage=decoded.resource_access;
         localStorage.setItem("Userroles", JSON.stringify(rolemanage["dotstow_ui"].roles[0]));
        //  window.location.href='/garage/dashboard';

        var customerListReq =
        {

        };
        const requestOptions = 
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 
           'Authorization': "Bearer "+keycloak.token
        },
             
            body: JSON.stringify(customerListReq)
        };

        
        services.getAccountType(requestOptions).then((res) =>
        {

           sessionStorage.setItem("accountType",res.rows.accountTypeName);
           if(res.rows.accountTypeName=='garage')
           {

            window.location.href='/garage/dashboard';


           }if(res.rows.accountTypeName=='fleet')
           {
              window.location.href="/fleetowner/dashboard";

           }

        });
      }


        }else{
          sessionStorage.clear();
          localStorage.clear();
        }


    }else{

    }


   }, []);

   const scroolRedirect = (areaId) => {

        if(areaId=="garage_wrap" || areaId=="newsletter_wrap"){
          $('html,body').animate({
            scrollTop: $("."+areaId).offset().top},
            'smooth');
        }
        if(areaId=="garage_wrap"){
            document.getElementById("features").setAttribute("class", "nav-link active");
            $('#home').removeClass('active');
            $('#contactUs').removeClass('active');
        }if(areaId=="newsletter_wrap"){
            document.getElementById("contactUs").setAttribute("class", "nav-link active");
            $('#features').removeClass('active');
            $('#home').removeClass('active');
        }if(areaId=="home"){
            document.getElementById("home").setAttribute("class", "nav-link active");
            $('#contactUs').removeClass('active');
            $('#features').removeClass('active');
        }
    }

   const mobilevalidate=(e)=>
   {
    $('#edit_mobile').usPhoneFormat();
   }
   const loginMethod=(e)=>
   {
            var email=$("#userEmail").val();

           if(validator.isEmail(email))
            {
                setEmailError('');

                var userDetails=
                {

                  "email":email,

                };

                const requestOptions =
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json'},
                    body: JSON.stringify(userDetails)
                };

              services.getUserByEmailId(requestOptions).then((res) =>
              {
                document.getElementById("edit_close").click();


                    if(res.rows!==null)
                    {



                        if(res.rows.tenantName!==null)
                        {



                            sessionStorage.setItem("email",email);
                            localStorage.setItem("realm",res.rows.tenantName);
                            // console.log(res.rows);
                            // alert(res.rows.tenantName);
                            // const keycloaksetup=KeycloakSetup();

                            // alert("keycloaksetup=="+JSON.stringify(keycloaksetup))
                        // const keycloak= KeycloakSetup()
                        //   var authUrl="http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth"
                        //  var authUrl=""
                        //  switch(res.rows.tenantName)
                        //  {
                        //    case "Dev":
                        //     authUrl= "http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth";
                        //      break;
                        //     case "dotstow":
                        //      authUrl="http://keycloak-dotstow-qa-alb-1314093332.us-east-2.elb.amazonaws.com/auth";
                        //         break;
                        //       default:
                        //         authUrl= "http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth";

                        //          break;
                        //  }

                        //   keycloak.authServerUrl=authUrl;
                    ///  keycloakAuth.login({idpHint: 'facebook'});
                            //keycloak.loginHint=email;

                            keycloak.realm=res.rows.tenantName;

                            keycloak.login({loginHint:email});
                        }
                        else
                        {


                            if(res.message===null||res.message===""||res.message===undefined)
                            {
                                Swal.fire({
                                    position: 'center',
                                    icon: 'error',
                                    title: "",
                                    text:"Email not registered with DotStow",
                                    showConfirmButton: true

                                }).then((result) =>
                                {

                                        if(result.isConfirmed)
                                        {


                                        }
                                });

                            }else{
                                Swal.fire({
                                    position: 'center',
                                    icon: 'error',
                                    title: "",
                                    text:res.message,
                                    showConfirmButton: true

                                }).then((result) =>
                                {

                                        if(result.isConfirmed)
                                        {


                                        }
                                });

                            }


                        }
                        } else{
                            if(res.message===null||res.message===""||res.message===undefined)
                            {
                                Swal.fire({
                                    position: 'center',
                                    icon: 'error',
                                    title: "",
                                    text:"Email not registered with DotStow",
                                    showConfirmButton: true

                                }).then((result) =>
                                {

                                        if(result.isConfirmed)
                                        {


                                        }
                                });

                            }else{
                                Swal.fire({
                                    position: 'center',
                                    icon: 'error',
                                    title: "",
                                    text:res.message,
                                    showConfirmButton: true

                                }).then((result) =>
                                {

                                        if(result.isConfirmed)
                                        {


                                        }
                                });

                            }
                        }            // keycloak.login('/garage/dashboard');

              });


            }else
            {
              setEmailError('Enter valid Email!')
            }

            // if(keycloak.authenticated)
            // {

            //     navigate('/garage/dashboard');
            // }
            // else
            // {

            //     keycloak.login('/garage/dashboard');
            // }
    }
   const contact_us=async (event, error) =>
   {

       if(captch==null||captch=="")
       {

         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text:"Please verify the captcha",
            showConfirmButton: true

          }).then((result) =>
          {

                if(result.isConfirmed)
                {


                }
          });

       }
       else
       {
        // setLoading(true);
        $("#loadingforsubmit").show();
        var conactUs=
        {
                  "firstName":$("#edit_fristName").val(),
                  "lastName":$("#edit_lastName").val(),
                  "phoneNumber":$("#edit_mobile").val(),
                  "email":$("#edit_email").val(),
                  "companyName":$("#edit_Comapny").val(),
                  "message":$("#Message").val()

        };
        // var requestOptions=requestObject.RequestHeader(conactUs);
        const requestOptions =
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(conactUs)
        };
        services.sendMessageToAdmin(requestOptions).then((res)=>
        {
            // setLoading(false);
           $("#loadingforsubmit").hide();
            Swal.fire({
             position: 'center',
             icon: 'success',
             title: "",
             text:res.message,
             showConfirmButton: true

           }).then((result) =>
           {

                 if(result.isConfirmed)
                 {
                    navigate("/login");
                    $("#contact_form").trigger('reset');
                    // $('#rc-anchor-container').trigger('reset');
                    // ReCAPTCHA.trigger('reset');
                 }
           });
        })
       }


   }
   const handleOnEvent = async (event, error) =>
   {

    if (event === 'onAuthSuccess')
    {

      if (keycloak.authenticated)
      {

        if(keycloak.token!="")
        {
           $(".pp").hide();
          if(localStorage.getItem("realm")!==null)
          {

            setAuthenticate(true);
        //   localStorage.setItem("accToken",keycloak.token);
          sessionStorage.setItem("fkAccountId","1");
        //   var accessTokenT=localStorage.getItem("accToken");
          var decoded = jwt_decode(keycloak.token);

          var rolemanage=decoded.resource_access;
           localStorage.setItem("Userroles", JSON.stringify(rolemanage["dotstow_ui"].roles[0]));
          //  window.location.href='/garage/dashboard';
          setCookie('_AK', keycloak.token,{"path":"/",maxAge:"1704085200"});
          setCookie('_RK', keycloak.refreshToken,{"path":"/",maxAge:"1704085200"});
          setCookie('_ET', keycloak.tokenParsed.exp,{"path":"/",maxAge:"1704085200"});
          var customerListReq =
          {

          };

          const requestOptions = 
          {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', 
             'Authorization': "Bearer "+keycloak.token
          },
               
              body: JSON.stringify(customerListReq)
          };
          services.getAccountType(requestOptions).then((res) =>
          {

            sessionStorage.setItem("accountType",res.rows.accountTypeName);
             if(res.rows.accountTypeName=='garage')
             {

              window.location.href='/garage/dashboard';


             }if(res.rows.accountTypeName=='fleet')
             {
                window.location.href="/fleetowner/dashboard";

             }

          });
        }else{
            keycloak.logout();

        }


          }


      }else{

      }
    }else{

    }
  }
  const InitialloadingComponent = (
    <div class="loader-wrapper" id="loading">
      <div class="truck-wrapper">
        <div class="truck">
          <div class="truck-container"></div>
          <div class="glases"></div>
          <div class="bonet"></div>

          <div class="base"></div>

          <div class="base-aux"></div>
          <div class="wheel-back"></div>
          <div class="wheel-front"></div>

          <div class="smoke"></div>
        </div>
      </div>
</div>
  )
  return(
  <>
  {authenticate?InitialloadingComponent:
     <ReactKeycloakProvider
      authClient={keycloak}

      LoadingComponent={InitialloadingComponent}
     onEvent={(event, error) => handleOnEvent(event, error)}>
         {LoadingComponentForSubmit}
  <div class="pp">
  <header class="main_header">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container">
                <a class="navbar-brand" href="#"><img src="/img/logo-dotstow.svg" style={{width: "90px"}}/></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a class="nav-link active" aria-current="page" href="#" onClick={() => scroolRedirect("home")} id="home">Home</a></li>
                        <li class="nav-item" ><a class="nav-link" href="#" onClick={() => scroolRedirect("garage_wrap")} id="features">Features</a></li>
                        <li class="nav-item"><a class="nav-link" href="#" onClick={() => scroolRedirect("newsletter_wrap")} id="contactUs">Contact Us</a></li>

                    </ul>
                    <a class="btn btn-outline-primary" data-toggle="modal" data-target="#edit_Contact" onClick={() => $("#userEmail").val('')}>SIGN IN</a>
                    {/* <a href="#" data-toggle="modal" data-target="#edit_Contact" >Edit</a> */}
                </div>
            </div>
        </nav>
    </header>

    <section class="home_banner_wrap">
      <CarouselComponent/>
         {/* <div class="carousel-wrapper">
            <Carousel infiniteLoop useKeyboardArrows autoPlay>
                <div>
                    <img src="../img-01.jpg" />
                </div>
                <div>
                    <img src="../img-02.jpg" />
                </div>
                <div>
                    <img src="../img-03.jpg" />
                </div>
            </Carousel>
        </div> */}
        {/* <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="/img/banner-1.png" class="img-fluid"/>
                    <div style={{position: "absolute",left: "0",top: "0",width: "100%",height: "100%"}}>
                        <div class="container" style={{position: "relative",height: "100%"}}>
                            <div class="carousel-caption d-md-block animate__animated animate__fadeInUp">
                                <h3>Simplify the vehicle'sinspection.</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <img src="/img/banner-2.png" class="img-fluid"/>
                    <div style={{position: "absolute",left: "0",top: "0",width: "100%",height: "100%"}}>
                        <div class="container" style={{position: "relative",height: "100%"}}>
                            <div class="carousel-caption d-md-block animate__animated animate__fadeInUp">
                                <h3>Lorem ipsum dolor sit consectetur elit.</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <img src="/img/banner-3.png" class="img-fluid"/>
                    <div style={{position: "absolute",left: "0",top: "0",width: "100%",height: "100%"}}>
                        <div class="container" style={{position: "relative",height: "100%"}}>
                            <div class="carousel-caption d-md-block animate__animated animate__fadeInUp">
                                <h3>Lorem ipsum dolor sit consectetur elit.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
               <span class="carousel-control-prev-icon" aria-hidden="true"></span>
               <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
               <span class="carousel-control-next-icon" aria-hidden="true"></span>
               <span class="visually-hidden">Next</span>
            </button>
        </div> */}
    </section>

    {/* Are You Experiencing Any Of These Compliance Document Headaches?*/}
    <section class="newsection garage_wrap" style={{backgroundColor: "#fff"}}>
        <div class="container">
            <div class="title_new_wrap">
                <h2>Are You Experiencing Any Of These Compliance Document Headaches?</h2>
                <div class="sectionwrap sectionwrap-reverse">
                    <div class="sectionitem">
                        <ul class="sectionList">
                            <li> <span><img src="/img/bullet.png"/></span> Dot Fines</li>
                            <li> <span><img src="/img/bullet.png"/></span> Wasted time (completing, scanning, emailing, and keeping track of paperwork). </li>
                            <li> <span><img src="/img/bullet.png"/></span> Keeping boxes and boxes of hard copy compliance records.</li>
                            <li> <span><img src="/img/bullet.png"/></span> Handwritten docs that are difficult to read (trying to "interpret" them). </li>
                            <li> <span><img src="/img/bullet.png"/></span> Waiting to get documents signed.</li>
                            <li> <span><img src="/img/bullet.png"/></span> Painful DOT audits.</li>
                            <li> <span><img src="/img/bullet.png"/></span> Inaccurate compliance docs.</li>
                        </ul>

                        <div class="sectionHighlight">
                            <h4>
                                We know how frustrating and how much of a hassle it can be to deal with these headaches. What would you say if we said these could become a thing of the past?
                            </h4>
                        </div>

                    </div>
                    <div class="sectionitem sectionitemSub">
                        <div class="sectionimage">
                            <img src="/img/experiencing.jpg" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/*} What Compliance Document Management Looks Like With DotStow */}
        <section class="newsection" style={{backgroundColor: "#E0E9EC"}}>
            <div class="container">
                <div class="title_new_wrap">
                    <h2>What Compliance Document Management Looks Like With DotStow</h2>
                    <div class="sectionwrap">
                        <div class="sectionitem sectionitemSub">
                            <div class="sectionimage">
                                <img src="/img/compliance.jpg" alt="" />
                            </div>
                        </div>
                        <div class="sectionitem">
                            <ul class="sectionList">

                                <li> <span><img src="/img/bullet.png"/></span> Compliance documents at your fingertips 24/7.</li>

                                <li> <span><img src="/img/bullet.png"/></span> Never forget important repair or service dates again.</li>

                                <li> <span><img src="/img/bullet.png"/></span> Faster, hassle-free DOT audits.</li>

                                <li> <span><img src="/img/bullet.png"/></span> Minimize record-keeping mistakes and hassles.</li>

                                <li> <span><img src="/img/bullet.png"/></span> Track years of documents without all the boxes.</li>

                                <li> <span><img src="/img/bullet.png"/></span> Ensure your fleet is compliant and operating safely.</li>

                                <li> <span><img src="/img/bullet.png"/></span> Readable documents every time.</li>
                            </ul>

                            <div>
                                <a href="/schedule_demo" class="btn btn-primary  mt-3">SCHEDULE A DEMO</a>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </section>

        {/* Stop Battling Paper Compliance Documents. There's A More Efficient Way */}
        <section class="newsection" style={{backgroundColor: "#fff"}}>
            <div class="container">
                <div class="title_new_wrap">
                    <h2>Stop Battling Paper Compliance Documents. There's A More Efficient Way</h2>
                    <div class="sectionwrap">
                        <div class="sectionitem">
                            <ul class="sectionnumList">
                                <li>
                                    <div class="num_circle">1</div>
                                    <div class="num_right_body">
                                        <b>Schedule a demo </b>
                                        <p>All it takes is a quick 15-minute call for us to show you how DotStow can solve your compliance paperwork challenges.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div class="num_circle">2</div>
                                    <div class="num_right_body">
                                        <b>Start Using DotStow</b>
                                        <p>
                                            DotStow is simple and self-explanatory, so you can start using it today!
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div class="num_circle">3</div>
                                    <div class="num_right_body">
                                        <b>Have The Compliance Documents You Need
                                            When You Need Them
                                        </b>
                                        <p>With DotStow, all your compliance documents are at your fingertips 24/7.
                                        </p>
                                    </div>
                                </li>
                            </ul>

                            <div class="sectionHighlight">
                                <h4>
                                    We know how frustrating and how much of a hassle it can be to deal with these headaches. What would you say if we said these could become a thing of the past?
                                </h4>
                            </div>

                        </div>
                        <div class="sectionitem sectionitemSub position-relative">
                            <div class="demo_button">
                                <a href="/schedule_demo" class="btn btn-primary  mt-3">SCHEDULE A DEMO</a>
                            </div>
                            <div class="sectionimage">
                                <img src="/img/scheduledemo.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* What Compliance Document Management Looks Like With DotStow  */}
        <section class="newsection" style={{backgroundColor: "#E0E9EC"}}>
            <div class="container">
                <div class="title_new_wrap" style={{textAlign: "center"}}>
                <h2>How DotStow Compares To Paper Compliance Documents</h2>
                    <div class="sectionwrap">
                        <div class="comparetable">
                            <table class="w-100 comparetablemain">
                                <tr>
                                    <th></th>
                                    <th class="text-center">DotStow</th>
                                    <th class="text-center">Paper</th>
                                </tr>
                                <tr>
                                    <td>Save time.</td>
                                    <td class="text-center"><img src="/img/yes.png" width="28" height="28" alt="Yes"/>
                                    </td>
                                    <td class="text-center"><img src="/img/no.png" width="28" height="28" alt="No"/></td>

                                </tr>
                                <tr>
                                    <td>Readable documents every time.</td>
                                    <td class="text-center"><img src="/img/yes.png" width="28" height="28" alt="Yes"/>
                                    </td>
                                    <td class="text-center"><img src="/img/no.png" width="28" height="28" alt="No"/></td>
                                </tr>
                                <tr>
                                    <td>Track years of documents without all the boxes</td>
                                    <td class="text-center"><img src="/img/yes.png" width="28" height="28" alt="Yes"/>
                                    </td>
                                    <td class="text-center"><img src="/img/no.png" width="28" height="28" alt="No"/></td>
                                </tr>
                                <tr>
                                    <td>Quickly and easily access what you need when you need it.</td>
                                    <td class="text-center"><img src="/img/yes.png" width="28" height="28" alt="Yes"/>
                                    </td>
                                    <td class="text-center"><img src="/img/no.png" width="28" height="28" alt="No"/></td>
                                </tr>
                                <tr>
                                    <td>Easily track important fleet and repair dates. </td>
                                    <td class="text-center"><img src="/img/yes.png" width="28" height="28" alt="Yes"/>
                                    </td>
                                    <td class="text-center"><img src="/img/no.png" width="28" height="28" alt="No"/></td>
                                </tr>
                                <tr>
                                    <td>Keep documents organized and handy.
                                    </td>
                                    <td class="text-center"><img src="/img/yes.png" width="28" height="28" alt="Yes"/>
                                    </td>
                                    <td class="text-center"><img src="/img/no.png" width="28" height="28" alt="No"/></td>
                                </tr>
                                <tr>
                                    <td>Minimize record-keeping mistakes and hassles. </td>
                                    <td class="text-center"><img src="/img/yes.png" width="28" height="28" alt="Yes"/>
                                    </td>
                                    <td class="text-center"><img src="/img/no.png" width="28" height="28" alt="No"/></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       
        {/* The DotStow Advantage */}
        <section class="dt-advantage">
            <div class="container">
                <div class="title_new_wrap">
                    <h2>The DotStow Advantage</h2>
                    <div class="sectionwrap">

                        <div class="sectionitem">
                            <ul class="sectionList invertclr">

                                <li> <span><img src="/img/bullet.png"/></span> Reduce time spent on compliance paperwork.</li>

                                <li> <span><img src="/img/bullet.png"/></span> DotStow is powered by Amazon Web Services, providing the highest levels of reliability and security.</li>

                                <li> <span><img src="/img/bullet.png"/></span> All your data is backed up daily and stored securely.</li>

                                <li> <span><img src="/img/bullet.png"/></span> Built by a 10-year tanker repair industry veteran & insider.</li>

                                <li> <span><img src="/img/bullet.png"/></span> Reduce your compliance issues and fines.</li>
                            </ul>

                        </div>

                    </div>
                </div>
            </div>
        </section>

        {/* Testmonials */} 
        <section class="newsection" style={{backgroundColor: "#E0E9EC"}}>
            <div class="container">
                <div class="title_new_wrap" style={{textAlign: "center"}}>
                    <h2 class="mb-1">What DotStow Clients Are Saying</h2>
                    <span class="text-center">Testimonials</span>
{/*
                    <div class="wrapper">
                        <input checked type="radio" name="slider" id="slide1" />
                        <input type="radio" name="slider" id="slide2" />
                        <input type="radio" name="slider" id="slide3" />
                        <input type="radio" name="slider" id="slide4" />


                        <div class="slider-wrapper">
                            <div class="inner">
                                <article>
                                    <div class="article">

                                        <p>
                                            “Since implementing DotStow, our business has experienced a significant boost in efficiency and productivity. The software's user-friendly interface and automated features have streamlined our compliance processes, saving us valuable time and effort.It has truly become an indispensable tool for our trucking operations.
                                            <br/> <br/> Efficiency and accuracy have significantly improved since we integrated DotStow into our operations. Our administrative burden has lightened, and the software's ability to consolidate data has made compliance audits a breeze. It's been a game-changer for our company. 
                                            <br/><br/> We've been using DotStow for a while now, and it has transformed the way we handle compliance. It has eliminated the stress and complexity of managing multiple trucks, allowing us to focus on our core business.”*
                                        </p>
                                        <h3>- Scott Beem</h3>

                                    </div>

                                </article>

                                <article>
                                    <div class="article">
                                        <p>
                                            “I have been using DotStow for approximately 8 months. The website is very easy to use, even to the not so" tech savvy" individuals. The website helps to eliminate minor errors when filling out paperwork (ex. writing in wrong numbers, vin numbers, contacts,
                                            testing results). I have also found using DotStow has relieved the stress of having to "find" years past paperwork, driver information, contacts, records, and tests that need to be performed, eliminating time
                                            wasted. At the click of a button, it's all there, due to the organized records. Everything is paperless and easily accessible for all involved.”

                                        </p>
                                        <h3>- Kandi</h3>
                                    </div>

                                </article>

                                <article>
                                    <div class="article">
                                        <p>
                                            “As the owner of a repair facility I am involved in details at a higher level, but using DotStow has given me the ability to quickly and easily locate and view documents with minimal intervention from my office staff. To further test the software I attempted
                                            to complete a set of test papers myself and within 30 minutes and again minimal intervention I was successful.”
                                        </p>
                                        <h3>- Bryan Baker</h3>
                                    </div>

                                </article>

                                <article>
                                    <div class="article">
                                        <p>
                                            “How DotStow has impacted my daily routine. I am able to complete test papers and send to the appropriate people in a matter of minutes as well as knowing exactly where each customer's paperwork is located and stored for quick access.”
                                        </p>
                                        <h3>- Crystal Bake</h3>
                                    </div>

                                </article>


                            </div>

                            {/* .inner *
                        </div>
                        {/* .slider-wrapper *

                        <div class="slider-prev-next-control">
                            <label for="slide1"></label>
                            <label for="slide2"></label>
                            <label for="slide3"></label>
                            <label for="slide4"></label>

                        </div>
                        {/* .slider-prev-next-control *

                        <div class="slider-dot-control">
                            <label for="slide1"></label>
                            <label for="slide2"></label>
                            <label for="slide3"></label>
                            <label for="slide4"></label>

                        </div>
                        {/* .slider-dot-control *
                        <div style={{position: "relative", top: "-22px"}}>
                            <img src="/img/quate.png" alt=""/>
                        </div>
                    </div>*/}

                </div>

                <div id="carouselExampleControls2" class="carousel slide carousel-fade " data-bs-ride="carousel">
                 <div class="carousel-inner testmonials_itemWrap testmonials_new">
                    <div class="carousel-item active">
                        <div class="testmonials_item">

                            <p>
                                “Since implementing DotStow, our business has experienced a significant boost in efficiency and productivity. The software's user-friendly interface and automated features have streamlined our compliance processes, saving us valuable time and effort.
                                It has truly become an indispensable tool for our trucking operations.
                                <br/> <br/> Efficiency and accuracy have significantly improved since we integrated DotStow into our operations. Our administrative burden has lightened, and the software's ability to consolidate data has made compliance
                                audits a breeze. It's been a game-changer for our company. <br/>
                                <br/> We've been using DotStow for a while now, and it has transformed the way we handle compliance. It has eliminated the stress and complexity of managing multiple trucks, allowing us to focus on our core business.”

                            </p>
                            <h3 class="mb-3">- Scott Beem</h3>

                            <div class="quate-hoem">
                                <img src="img/quate.png" alt=""/>
                            </div>

                        </div>

                    </div>
                    <div class="carousel-item">

                        <div class="testmonials_item">
                            <p>
                                “I have been using DotStow for approximately 8 months. The website is very easy to use, even to the not so" tech savvy" individuals. The website helps to eliminate minor errors when filling out paperwork (ex. writing in wrong numbers, vin numbers, contacts,
                                testing results). I have also found using DotStow has relieved the stress of having to "find" years past paperwork, driver information, contacts, records, and tests that need to be performed, eliminating time wasted. At
                                the click of a button, it's all there, due to the organized records. Everything is paperless and easily accessible for all involved.”

                            </p>
                            <h3>- Kandi</h3>
                            <div class="quate-hoem">
                                <img src="img/quate.png" alt=""/>
                            </div>
                        </div>

                    </div>
                    <div class="carousel-item">
                        <div class="testmonials_item">
                            <p>
                                “As the owner of a repair facility I am involved in details at a higher level, but using DotStow has given me the ability to quickly and easily locate and view documents with minimal intervention from my office staff. To further test the software I attempted
                                to complete a set of test papers myself and within 30 minutes and again minimal intervention I was successful.”
                            </p>
                            <h3>- Bryan Baker</h3>
                            <div class="quate-hoem">
                                <img src="img/quate.png" alt=""/>
                            </div>
                        </div>

                    </div>

                    <div class="carousel-item">
                        <div class="testmonials_item">
                            <p>
                                “How DotStow has impacted my daily routine. I am able to complete test papers and send to the appropriate people in a matter of minutes as well as knowing exactly where each customer's paperwork is located and stored for quick access.”
                            </p>
                            <h3>- Crystal</h3>
                            <div class="quate-hoem">
                                <img src="img/quate.png" alt=""/>
                            </div>
                        </div>

                    </div>

                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
              

            </div>
        </section>


    <section class="newsletter_wrap">
	 <div class="safe-hard ">
                <img src="/img/hazard-2.png" class="sh-lt" alt=""/>
            </div>
        <div class="container">

            <div class="row justify-content-center">
                <div class="col-sm-12 col-md-auto">
                    <div class="newsletter_inner">
                        <div class="news_header">
                            <h3>Contact Us</h3>
                            <p>Let us know your questions. We're here to help!</p>
                        </div>
                        <div class="news_content">
                            
                            <div className="d-flex align-items-center flex-column text-center justify-content-center mb-3">
                            <div className="d-flex justify-content-center">
                            <p className="d-flex flex-lg-row flex-column"><span style={{fontSize:"18px", color:"#333", marginBottom:"13px", marginRight:"5px"}}>Simply call us at: </span>  <b style={{fontSize:"30px", color:"#022e5f"}}>614-907-2725</b></p>
                           </div>
                            
                            <div className="or" ><span>OR</span></div>
                             <p><span style={{fontSize:"18px", color:"#333"}}>Complete the form below</span></p>
                            </div>
                         
                         <hr/>

                            <form onSubmit={handleSubmit(contact_us)} id="contact_form">
                                <div class="row">
                                    <div class="col-sm-12 col-md-4 mb-3">
                                        <label class="form-label">First name<span class="star">*</span></label>
                                        <input type="text" class="form-control" placeholder="First name"  id="edit_fristName" maxLength="20" minLength="2"  onChange={e => setFieldName(e.target.value)}    {...register("edit_Firstname", {
                                       required: "First name is required",
                                       maxLength: {
                                        value: 20,
                                        message: "First name shouldn't exceed the max length 20 characters"
                                        },
                                     minLength: {
                                           value: 2,
                                           message: "First name atleast 2 characters"
                                        }
                                        })} />
                                        <ErrorMessage
                                        errors={errors}
                                        name="edit_Firstname"
                                        class="invalid-feedback"
                                        render={({ messages }) => {

                                            return messages
                                                ? Object.entries(messages).map(([type, message]) => (
                                                    <p key={type} class="error">{message}</p>
                                                ))
                                                : null;
                                        }}
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-4 mb-3">
                                        <label class="form-label">Last name<span class="star">*</span></label>
                                        <input type="text" class="form-control" placeholder="Last name" id="edit_lastName" maxLength="20" minLength="2"  onChange={e => setFieldName(e.target.value)}    {...register("edit_Lastname", {
                                       required: "Last name is required",
                                       maxLength: {
                                        value: 20,
                                        message: "Last name shouldn't exceed the max length 20 characters"
                                        },
                                     minLength: {
                                           value: 2,
                                           message: "Last name atleast 2 characters"
                                        }
                                    })} />
                                    <ErrorMessage
                                       errors={errors}
                                       name="edit_Lastname"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                    </div>
                                    <div class="col-sm-12 col-md-4 mb-3">
                                        <label class="form-label">Phone number<span class="star">*</span></label>
                                        <input type="text" class="form-control" placeholder="Phone number" onKeyPress={mobilevalidate} id="edit_mobile" aria-describedby="emailHelp" {...register("MobileNumber", {
                                       required: "Phone number is required",
                                       maxLength: {
                                        value: 12,
                                        message: "Phone number should be 10 digits"
                                        },
                                     minLength: {
                                           value: 12,
                                           message: "Phone number should be 10 digits"
                                        } ,
                                    })}/>
                                    <ErrorMessage
                                       errors={errors}
                                       name="MobileNumber"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-6 mb-3">
                                        <label class="form-label">Email address<span class="star">*</span></label>
                                        <input type="text" class="form-control" placeholder="Email address"  id="edit_email"   onChange={e => setFieldName(e.target.value)} {...register("edit_companyEmail", {
                                       required: "Email is required",

                                       pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                          message: "Invalid email address"
                                       }
                                    })} />
                                    <ErrorMessage
                                       errors={errors}
                                       name="edit_companyEmail"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                    </div>
                                    <div class="col-sm-12 col-md-6 mb-3">
                                        <label class="form-label">Company name<span class="star">*</span></label>
                                        <input type="text" class="form-control" placeholder="Company name" id="edit_Comapny" onChange={e => setFieldName(e.target.value)}    {...register("edit_Comapny", {
                                       required: "Company name is required",
                                       maxLength: {
                                        value: 50,
                                        message: "Company name shouldn't exceed the max length 20 characters"
                                        },
                                     minLength: {
                                           value: 2,
                                           message: "Company name atleast 2 characters"
                                        }
                                        })} />
                                        <ErrorMessage
                                        errors={errors}
                                        name="edit_Comapny"
                                        class="invalid-feedback"
                                        render={({ messages }) => {

                                            return messages
                                                ? Object.entries(messages).map(([type, message]) => (
                                                    <p key={type} class="error">{message}</p>
                                                ))
                                                : null;
                                        }}
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 mb-3">
                                        <label class="form-label">Message </label>
                                        <textarea class="form-control" rows="4" id="Message"></textarea>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <ReCAPTCHA
                                            sitekey={SiteKey}
                                            onChange={onChange}
                                        />
                                        <button type="submit" class="btn btn-primary w-100 mt-3">SUBMIT</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div class="center">
              <span style={{fontSize:"30px",color:"#fff",marginTop:30}}>Download the App</span>
            </div>
          
            <div class="center">
               
                        <a href="https://play.google.com/store/apps/details?id=com.dotstow.driverapp" class="svg_icon" style={{width: "173px",marginLeft:30}}>
                                        {/* <image src="/img/appstore.png" /> *
                         
                            <img src="/img/playstore.png" />
                            {/* <svg height="20" viewBox="0 0 9.327 20.073">
                           <path d="M27.309,12.6H24.133V10.512a.848.848,0,0,1,.884-.965h2.242V6.108L24.171,6.1A3.909,3.909,0,0,0,19.964,10.3V12.6H17.982V16.14h1.982V26.169h4.169V16.14h2.813Z" transform="translate(-17.982 -6.096)" />
                        </svg> *
                        </a>
                        <a href="https://apps.apple.com/app/dotstow-driver-app/id1666349475" class="svg_icon" style={{width: "173px",marginLeft:30,marginRight:30}}>
                         <img src="/img/appstore.png" />
                        </a>
            </div> */}
        </div>
    </section>
    <footer class="footer_wrap">
        <div class="container">
        <div class="row footerlinks">
                <div class="col-lg-3">
                    <h3>Contact Us</h3>
                    <p> DotStow, <br/> 11708 Ashville Pike, <br/> Ashville, Ohio 43103, <br/> Phone: 614-907-2725</p>
                </div>
                <div class="col-lg-6 app-icon-wrap">
                    <h2 class="text-center">Download our Driver APP</h2>
                    <div class="app-download">
                        <span><a href="https://play.google.com/store/apps/details?id=com.dotstow.driverapp" target="_blank"> <img src="img/google-app.png" alt="" /></a></span>
                        <span><a href="https://apps.apple.com/app/dotstow-driver-app/id1666349475" target="_blank"> <img src="img/ios-app.png" alt="" /></a></span>
                    </div>
                </div>
                <div class="col-lg-3">
                    <h3>Quick Links</h3>
                    <ul class="mx-0 ml-0">
                        <li><a href="/login" class="footerlinks">Login</a></li>
                        <li><a href="/privacy" class="footerlinks">Privacy Policy</a></li>
                        <li><a href="/terms" class="footerlinks">Terms & Conditions</a></li>

                    </ul>
                </div>
                <div class="col-lg-6 app-icon-wrapbottom">
                    <h2 class="text-center">Download our Driver APP</h2>
                    <div class="app-download">
                        <span><a href="https://play.google.com/store/apps/details?id=com.dotstow.driverapp" target="_blank"> <img src="img/google-app.png" alt="" /></a></span>
                        <span><a href="https://apps.apple.com/app/dotstow-driver-app/id1666349475" target="_blank"> <img src="img/ios-app.png" alt="" /></a></span>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-between">
                <div class="col-sm-12 col-md-6">
                    <div class="copyrights">
                        <p>© {(new Date().getFullYear())} DotStow. All rights reserved. <a href="/privacy">Privacy Policy</a> <a href="/terms">Terms & Conditions</a></p>
                    </div>
                </div>
                <div class="col-sm-auto col-md-auto ">
                    <div class="social_icons">
                        <a href="#" class="svg_icon">
                            <svg height="20" viewBox="0 0 9.327 20.073">
                           <path d="M27.309,12.6H24.133V10.512a.848.848,0,0,1,.884-.965h2.242V6.108L24.171,6.1A3.909,3.909,0,0,0,19.964,10.3V12.6H17.982V16.14h1.982V26.169h4.169V16.14h2.813Z" transform="translate(-17.982 -6.096)" />
                        </svg>
                        </a>
                        <a href="#" class="svg_icon">
                            <svg width="20" viewBox="0 0 24.7 20.073">
                           <path d="M28.274,12.7a10.083,10.083,0,0,1-2.911.8,5.077,5.077,0,0,0,2.228-2.8,10.144,10.144,0,0,1-3.218,1.23,5.072,5.072,0,0,0-8.635,4.622A14.388,14.388,0,0,1,5.294,11.254a5.074,5.074,0,0,0,1.568,6.766,5.053,5.053,0,0,1-2.3-.634c0,.021,0,.043,0,.064a5.072,5.072,0,0,0,4.065,4.969A5.1,5.1,0,0,1,7.3,22.6a5.021,5.021,0,0,1-.954-.091,5.073,5.073,0,0,0,4.734,3.519,10.226,10.226,0,0,1-7.5,2.1A14.412,14.412,0,0,0,25.76,15.981q0-.329-.014-.656A10.264,10.264,0,0,0,28.274,12.7Z" transform="translate(-3.574 -10.326)" />
                         </svg>
                        </a>
                        <a href="#" class="svg_icon">
                            <svg width="20" viewBox="0 0 20.073 20.073">
                           <path data-name="Path 72" d="M20.58,16.7a5.18,5.18,0,1,0,5.18,5.18A5.19,5.19,0,0,0,20.58,16.7Zm0,8.5A3.319,3.319,0,1,1,23.9,21.88,3.334,3.334,0,0,1,20.58,25.2Z" transform="translate(-10.544 -11.884)" />
                           <circle data-name="Ellipse 18" cx="1.174" cy="1.174" r="1.174" transform="translate(14.245 3.521)"/>
                           <path data-name="Path 73" d="M21.854,6.459A5.764,5.764,0,0,0,17.6,4.8H9.268A5.544,5.544,0,0,0,3.4,10.668v8.3a5.823,5.823,0,0,0,1.7,4.33,5.906,5.906,0,0,0,4.209,1.578h8.256a5.976,5.976,0,0,0,4.249-1.578A5.794,5.794,0,0,0,23.473,19V10.668A5.8,5.8,0,0,0,21.854,6.459ZM21.692,19a3.954,3.954,0,0,1-1.174,2.954,4.183,4.183,0,0,1-2.954,1.052H9.309a4.183,4.183,0,0,1-2.954-1.052,4.068,4.068,0,0,1-1.093-2.995v-8.3A4.035,4.035,0,0,1,6.354,7.714,4.115,4.115,0,0,1,9.309,6.662h8.337A4.035,4.035,0,0,1,20.6,7.754a4.176,4.176,0,0,1,1.093,2.914V19Z" transform="translate(-3.4 -4.8)" />
                         </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </footer>
    </div>
    <div class="modal fade" id="edit_Contact" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
               <div class="modal-dialog">

                     <div class="modal-content">
                        <div class="modal-header">
                           <h5 class="modal-title" id="exampleModalLabel">Log in to DotStow</h5>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="edit_close">
                              <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <div class="modal-body">
                            <label>Username or email</label>
                        <input type="text" class="my-2 w-100" id="userEmail"
                            onChange={(e) => validateEmail(e)}></input> <br />
                            {/* <span class="error" style={{
                            fontWeight: 'bold',
                            color: 'red',
                            marginTop:'10px'
                            }}>{emailError}</span> */}
                            <p class="error">{emailError}</p>
                    {/* <p className="my-3">Don't have an account? Start a <b><a href="#" class="freet">FREE TRIAL</a></b> </p> */}
                        </div>
                        <div class="modal-footer">
                           <button type="button" class="btn btn-secondary" data-dismiss="modal" id="edit_close">Close</button>
                           <button type="button" class="btn btn-primary" id="loginCall"   onClick={() => loginMethod() }>Proceed</button>
                        </div>
                     </div>

               </div>
    </div>

    {/* </div> */}
    </ReactKeycloakProvider>
}
  </>
)};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;