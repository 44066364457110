import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderBlock from "./VehicleHeaderBlock";
import services from "../../../services/services";
import $ from 'jquery';
import document_api from "../document/document_api";
// import vehicle_details_api from "./vehicle_details_api";
import Search from "../../../utility/search";
import Pagination from "../../../utility/pagination";
import Swal from "sweetalert2";
import inspectionList from "../inspection/inspectionList";
import { requestObject } from "../../../utility/requestObject";
import inspectionOrderListDash from "../inspection/listInspectionOrdersDash";
import { loadingComponent } from "../../../utility/Loading";
import DatePicker from "react-datepicker";
import { SelectAllContactList } from "../customer/CustomerContactList";
import { getInspectionType } from "../customer/CustomerContactList";
import moment from 'moment'
import Button from "@restart/ui/esm/Button";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";

const InspectionOrders = () => {


   const { id, vin } = useParams();
   const [originalDate, setOriginalDate] = useState(new Date());
   var vid = id;
   const inlineCss =
   {
      textAlign: "left"
   }
   let navigation = useNavigate();
   const [totalItems, setTotalItems] = useState(0);
   const [totalInspectionList, setTotalInspectionList] = useState(0);
   const [totalInspectionPages, settotalinspectionPages] = useState(0);
   const [inspectioCurrentPage, setInspectionCurrentPage] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const [docname, setDocName] = useState();
   const [totaldocspages, settotalDocsPages] = useState();
   const [totaldocsitems, settotalDocsItems] = useState();
   const [displayPage, setdisplayPage] = useState([]);
   const [displayPageForInspection, setdisplayPageForInspection] = useState([]);
   const [vechileDetails, setVehicleDetails] = useState([]);
   const [cid, setCustomerId] = useState([]);
   const [orderId, setOrderId] = useState([]);
   const [totalRecors, setTotalRecord] = useState([]);
   const [totalDocsRecors, setTotalDocsRecord] = useState([]);
   const [documentList, setDocumentList] = useState("");
   const [inspectionLists, setInspectionList] = useState("");

   const [ITEMS_PER_PAGE_DOCS, setitemperdocspage] = useState(25);
   const [ITEMS_PER_PAGE, setitemperdocs] = useState(25);
   const [inspectioname, setInspectionName] = useState("");
   const [FilterStatus, setFilterStatus] = useState("");
   const [inspectionNames, setInspecName] = useState("");
   const [customerName, setCustomerName] = useState("");
   const [orderDate, setorderDate] = useState(new Date());

   const [sortingField, setSortingField] = useState("");
   const [sortingOrder, setSortingOrder] = useState("asc");
   const [inspectionTypes, setInspectionTypes] = useState();
   const [vehicleId, setVehicleId] = useState();
   const [vehicleType, setVehicleType] = useState();
   const [InspectionType, setInspectionType] = useState();
   const [endDate, setEndDate] = useState(new Date());

   const [fromDate, setFromDate] = useState();
   const [toDate, setToDate] = useState();
   const [statusList, setStatusList] = useState([]);
   const Inspectionheaders =
      [
         { name: "Customer Name", field: "Customer Name", sortable: false, thStyle: 'width="40%"', inlineStyle: inlineCss },
         { name: "Unit Number", field: "Unit number", sortable: true, inlineStyle: inlineCss },
         { name: "VIN Number", field: "VIN Number", sortable: true, inlineStyle: inlineCss },
         //  { name: "Inspection", field: "Inspection Title", sortable: false, thStyle: 'width="40%"', inlineStyle: inlineCss },
         { name: "Order Date", field: "Order Date", sortable: true, inlineStyle: inlineCss },
         { name: "Status", field: "Status", sortable: true, inlineStyle: inlineCss },
         { name: "Actions" }
      ];
   const onSortingChangeDocument = (field) => {

      const order =
         field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
      console.log("order===", field + sortingOrder);
      setSortingField(field);
      setSortingOrder(order);
      // veh_docs(id, currentPage, ITEMS_PER_PAGE_DOCS, "", field, sortingOrder, "");

   };
   useEffect(() => {
      $("#toDateError").hide('');
      $(".error").hide();
      $("#inspectionerror").hide();
      inspectionOrderListDash(id, "1", ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, "", "", "", "");
      listAllStatus();
   }, []);

   const listAllStatus = () => 
   {

      var cto =
      {

         "": ""

      }

      var requestOptions = requestObject.RequestHeader(cto);

      services.listAllStatus(requestOptions).then((res) => {
         setStatusList(res.rows);

      })

   }

   $('#display_pages_in_items').on('change', function () 
   {
      setitemperdocs(this.value);
      inspectionOrderListDash(id, "1", this.value, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, "", $('#statusFilter').val(), fromDate, toDate);
   });

   const searchInspectionList = (inspectionType) => {
      setInspectionName(inspectionType);
      inspectionOrderListDash(id, "1", ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, inspectionType, "", "", "");

   }


   const handlePageChangeForInspection = (offset) => {


      inspectionOrderListDash(id, offset, ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, inspectioname, $('#statusFilter').val(), fromDate, toDate);

   }

   const redirect = (page) => {

      navigation(page);

   }

   const completeTaskOrder = (id) => {


      var cto =
      {

         "id": id

      }
      var requestOptions = requestObject.RequestHeader(cto);

      services.completeTaskOrders(requestOptions).then((res) => {
         document.getElementById("closeModal").click();
         if (res.success) {
            Swal.fire({
               position: 'center',
               icon: 'success',
               title: "",
               text: res.message,

               showConfirmButton: true

            }).then((result) => {
               if (result.isConfirmed) {
                  inspectionOrderListDash(id, "1", ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, "", "", "", "");
               }
            });
         } else {
            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: res.message,

               showConfirmButton: true

            }).then((result) => {
               if (result.isConfirmed) {

               }
            });

         }


      })

   }

   const DeleteInspectionOrder = (id) => {

      Swal.fire({
         title: 'Do you really want to remove this inspection order?',
         position: 'center',
          icon: 'error',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          showCancelButton: true,
         confirmButtonText: 'Yes',
         cancelButtonText: 'No'
      }).then(function (results) {
         if (results.isConfirmed) {
      var cto =
      {

         "id": id

      }
      var requestOptions = requestObject.RequestHeader(cto);

      services.deleteCancelledIO(requestOptions).then((res) => {
         document.getElementById("closeModal").click();
         if (res.success) 
         {
           
            Swal.fire({
               position: 'center',
               icon: 'success',
               title: "",
               text:  res.message==="Successfully marked Inspection Order as deleted"?"Successfully removed inspection order.":"Successfully removed inspection order.",

               showConfirmButton: true

            }).then((result) => {
               if (result.isConfirmed) {
                  inspectionOrderListDash(id, "1", ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, "", "", "", "");
               }
            });

         } else {
            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: res.message,

               showConfirmButton: true

            }).then((result) => {
               if (result.isConfirmed) {

               }
            });

         }


      })
   }
   });

   }
   const addInspection = (inspectionName, vehicleId, customerId) => 
   {

      localStorage.setItem("customerId", customerId);
      localStorage.setItem("vehicleId", vehicleId);
      sessionStorage.setItem("taskOrderfromInspection", inspectionName)

   }
   const creteTO = (inspectionName, orderdate, inspctionName, vehicleId, customerId, orderId, inspectionData,notes,workorder, vehicleTypeName) => {


      //setCustomerName(inspectionName);
      $("#updateworkorder").val(workorder);
      var results = JSON.parse(inspectionData);
   
      $("#noteVal").val(notes);
      setInspecName("");
      setInspectionTypes("");
      getInspectionType(setInspectionTypes);
      var array = [];

      for (var i = 0; i < results.length; i++) {

         if(results[i].Cancelled)
         {

         }else{
            array.push(results[i].name);
         }
        


      }
      setInspecName(array);
      setOriginalDate(new Date(orderdate));
      setVehicleId(vehicleId);
      setVehicleType(vehicleTypeName);
      setCustomerId(customerId);
      setOrderId(orderId);
   }
   const closeModalPopup = () => {

      document.getElementById("closeModal").click();
   }
   const createTaskOrder = () => {

      var inspectionTypeIDs = [];
      if ($("#taskorderName").val() === "") {

         $(".error").show();
         return false;

      } else {
         $(".error").hide();


         $('input:checkbox[name=contactListemails]:checked').each(function () {

            inspectionTypeIDs.push($(this).val());

         });
         if (inspectionTypeIDs.length > 0) {

            $("#inspectionerror").hide();
            document.getElementById("closeModal").click();
            $("#loadingforsubmit").show();
            var cto =
            {

               "name": $("#taskorderName").val(),

               "assignedDate": originalDate,

               "inspectionOrderId": orderId,

               "inspectionTypeIds": inspectionTypeIDs,

               "vehicleId": vehicleId,
               "workOrderNumber": $("#updateworkorder").val() != '' ? $("#updateworkorder").val() : '',
               "customerId": cid,
               "notes": $("#noteVal").val() ? $("#noteVal").val() : ""

            }
            var requestOptions = requestObject.RequestHeader(cto);

            services.createTaskOrder(requestOptions).then((res) => {
               $("#loadingforsubmit").hide();

               if (res.success) {
                  Swal.fire({
                     position: 'center',
                     icon: 'success',
                     title: "",
                     text: res.message,

                     showConfirmButton: true

                  }).then((result) => {
                     if (result.isConfirmed) {
                        inspectionOrderListDash(id, "1", ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, "", "", "", "");
                        $("#taskorderName").trigger('reset');
                     }
                  });
               } else {
                  Swal.fire({
                     position: 'center',
                     icon: 'error',
                     title: "",
                     text: res.message,

                     showConfirmButton: true

                  }).then((result) => {
                     if (result.isConfirmed) {

                     }
                  });

               }


            })
         } else {
            $("#inspectionerror").show();
         }



      }

   }



   const checkValidation = () => {
      const startDate = $("#fromDate").val();
      const endDate = $("#toDate").val();
      if (endDate <= startDate) {
         // $("#toDateError").text("To date should be greater than From date");
         return false;
      } else {
         $("#toDateError").hide('');
         //  inspectionOrderListDash(id, "1", ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, "",$('#statusFilter').val(),fromDate,toDate);
         return true
      };

   }
   const statusFilterMethod=()=>
   {
      
   }
   const searchData = () => {

      const startDate = $("#fromDate").val();
      const endDate = $("#toDate").val();
      if (startDate != '' && endDate != '') {
         if (endDate < startDate) {
            $("#toDateError").show('');
            return false;
         } else {
            $("#toDateError").hide('');
            inspectionOrderListDash(id, "1", ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, "", $('#statusFilter').val(), fromDate, toDate);
            return true
         }
      }
      var statusFilter=$("#statusFilter option:selected").text();
      //alert(statusFilter)
      setFilterStatus(statusFilter);
      inspectionOrderListDash(id, "1", ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, "", $('#statusFilter').val(), fromDate, toDate);

   }

   const resetAllValues = () => {
      $("#toDateError").hide('');
      // $('#reset_value').find('input:text').val('');
      $('#statusFilter').val('');
      // $('#fromDate').val('');
      // $('#toDate').val('');
      setFromDate('');
      setToDate('');
      inspectionOrderListDash(id, "1", ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, "", "", "", "");
   }


   return (
      <>
         {loadingComponent}
         {LoadingComponentForSubmit}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block;" }}>
               <div class="row">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <h4 class="font-weight-bold my-4 text-uppercase">Inspection orders</h4>
                  </div>
               </div>

               <div class="row">
                  <div class="col-lg-11 col-md-12 m-auto grid-margin stretch-card">
                     <div class="card">
                        <div class=" cd-desktop-to-fliter">
                           <div class="d-flex align-items-center" id="reset_value">
                              <div className="mr-2 d-flex align-items-center">
                                 <label className="m-0"> Status </label>
                                 <select class="form-select form-control ml-1" aria-label="Default select example" id="statusFilter" style={{ maxHeight: "38px", marginLeft: "4px" }} >
                                    <option selected value=''>Select</option>

                                    {
                                       statusList ? statusList.map((data, index) => (
                                          data.name != 'TO Created' && data.name != 'To Be Approved'  && data.name != 'Cancelled and Removed'?
                                             <option value={data.id} >{data.name}</option> : ''
                                       )) : <option value=""></option>
                                    }
                                 </select>
                              </div>
                              <div className="mr-2 d-flex align-items-center">
                                 <label className="ml-2 m-0">From</label>
                                 {/* <input className="text-uppercase ml-2 date-sm-tx" type="date" id="date" placeholder="From"/> */}
                                 <DatePicker id="fromDate" selected={fromDate} onChange={(date) => setFromDate(date)} name="fromDate" class="form-control" placeholderText="MM/DD/YYYY" />

                                 <label class="ml-2 m-0">TO</label>
                                 {/* <input className="text-uppercase ml-2 date-sm-tx" type="date" id="toDate" placeholder="From"/> */}
                                 <DatePicker id="toDate" minDate={moment(fromDate).toDate()} selected={toDate} onChange={(date) => setToDate(date)} name="toDate" class="form-control" placeholderText="MM/DD/YYYY" />

                              </div>

                              <div className="to-filter-btns"> <a className="btn btn-primary mr-1 px-3 d-flex align-items-center" id="searchBtn" onClick={searchData}>Search</a> <a className="btn btn-outline-dark mr-1 px-3 d-flex align-items-center" onClick={resetAllValues}>Reset</a> <span id="toDateError" class="error">To date should be greater than From date</span></div>
                           </div>



                           {/* <div class="d-flex align-self-start mb-1 mt-1">

                  {roles.match("Technician")||roles.match("Inspector")?"":<a  class="btn btn-success" data-bs-toggle="modal" data-bs-target="#adddocs">Add Task</a>}
                  </div> */}
                        </div>

                        <div class="cd-mobile-to-fliter justify-content-start">

                           <div class="d-flex align-items-center">
                              <div className="mr-2 d-flex align-items-center statusto">

                                 <select class="form-select form-control ml-1" aria-label="Default select example">
                                    <option selected>select menu</option>
                                    <option value="2">New</option>
                                    <option value="1">InProces</option>
                                    <option value="2">Completed</option>
                                 </select>
                              </div>
                              <div className="to-dt-range mr-2">
                                 <span className="mr-2"> <input className="text-uppercase  date-sm-tx" type="date" id="date" placeholder="From" /></span>
                                 <span> <input className="text-uppercase  date-sm-tx" type="date" id="date" placeholder="From" /></span>
                              </div>
                              <div className="to-filter-btns"> <a href="#" className="btn btn-primary mr-1 px-3 d-flex align-items-center" >Search</a> <a href="#" className="btn btn-outline-dark mr-1 px-3 d-flex align-items-center" >Reset</a></div>

                           </div>



                           {/* <div class="d-flex align-self-start mb-1 mt-1">

                  {roles.match("Technician")||roles.match("Inspector")?"":<a  class="btn btn-success" data-bs-toggle="modal" data-bs-target="#adddocs">Add Task</a>}
                  </div> */}
                        </div>




                        <div class="card-body pt-0 pb-0">

                           <div class="row">
                              <div class="col-12">
                                 <div class="table-responsive  border-bottom">



                                    <table class="table table-hover">
                                       <tr>
                                          {Inspectionheaders.map(({ name, field, sortable, thStyle, inlineStyle }) => (
                                             <th
                                                key={name}
                                                onClick={() =>
                                                   sortable ? "" : null
                                                }
                                                class={thStyle} style={inlineStyle}>
                                                {name}

                                                {sortingField && sortingField === field && (

                                                   sortingOrder === "asc"
                                                      ? "↓"
                                                      : "↑"
                                                )}

                                             </th>
                                          ))}
                                       </tr>

                                       <tbody>
                                          {/* {
                                                               vechileLists.length>0?vechileLists.map((data, index) => (
                                                            <tr>
                                                                <td>Task order name {index+1}</td>
                                                                <td>Customer name {index+1}</td>
                                                               <td>{data.unitNumber?data.unitNumber:"--"}</td>
                                                               <td>{data.vinNumber?data.vinNumber:"--"} </td>
                                                               <td>03-04-2022</td>
                                                               <td  style={{"width":"50px"}}><a  onClick={() => redirect("/vehicleDetails/"+data.id)} class="btn btn-outline-primary text-uppercase">View</a></td>
                                                            </tr>
                                                               )):""
                                                         } */}




                                          {
                                             inspectionLists.length > 0 ? inspectionLists.map((data, index) => (
                                                <tr style={{ position: "relative" }}>
                                                   <td class="to-td text-capitalize" style={{ minWidth: "200px", maxWidth: "200px", paddingBottom: "60px" }}>

                                                      <span>{data.customerName ? data.customerName : "--"}</span>

                                                      {/* <span>{data.unitNumber?data.unitNumber:"--"}</span>
                                                                  <span>{data.vinnumber?data.vinnumber:"--"}</span>
                                                                  <span>{data.assignedDate?data.assignedDate:"--"}</span>

                                                                  <span class="ml-auto">{data.statusName} <a class="btn btn-outline-primary text-uppercase" data-toggle="modal" data-target="#updateModal"  onClick={() =>updateTask(data.name,data.assignedDate,data.inspectionTypeData,data.vehicleId,data.customerId,data.id)}>Edit</a></span> */}
                                                      {/* <div>
                                                                   <lable style={{marginLeft:"15px",fontWeight:"600"}}>Assigned Inspection :</lable>
                                                                  <ul class="px-1 task-list-ord">
                                                                    <li><i class=" arrow right mx-2"></i><a href="#">{data.inspectionTypeData?data.inspectionTypeData:"--"}</a></li>

                                                                    </ul>
                                                              </div>  */}

                                                      <div class="my-3">
                                                         <lable style={{ marginLeft: "0px", fontWeight: "600" }}>Requested Inspection :</lable>

                                                         {JSON.parse(data.inspectionData) !== null ? JSON.parse(data.inspectionData).map((data1, index) => (
                                                            <ul class="px-1 task-list-ord">
                                                               {(() => {
                                                                  if (data1.status=='Checked') {
                                                                     return (
                                                                        <li>{data1.name}</li>
                                                                     )
                                                                  }  else if(data1.Cancelled){
                                                                     return (
                                                                        <li class="iocustomer-actionstatus"><span>{data1.name}</span> <span className="red-txt">Cancelled by customer</span></li>
                                                                     )
                                                                  }else{
                                                                     return(<li>{data1.name}  </li>)
                                                                  }
                                                               })()}

                                                               {/* <li>{data1.status}  </li> */}
                                                               {/* <li>{data1.status=='true'?data1.name:data1.name+" - Cancelled by Customer"}</li> */}
                                                            </ul>
                                                         )) : ""}
                                                         {/* - Cancelled by Customer */}
                                                         {/* {(() =>
                                        {

                                             if(data.inspectionData!==null)
                                             {
                                                var results = JSON.parse(data.inspectionData);

                                                JSON.parse(data.inspectionData).map((data1, index) => {
                                                   console.log("results="+data1.name);

                                                   return(<><a href={"/addInspection/" + data.customerId + "/" + data.vehicleId} onClick={() => addInspection(data.inspectionTypeData,data.vehicleId,data.customerId)}>{data1.name}</a></>)
                                                }

                                                )
                                             }

                                       })()}
                                     */}



                                                         {/* {(() =>
                                        {
                                          localStorage.setItem("customerId", data.customerId);
                                          localStorage.setItem("vehicleId",data.vehicleId);
                                           data.inspectionData?data.inspectionData.map((data1, index) => {
                                           return(

                                                <a href={"/addInspection/" + data.customerId + "/" + data.vehicleId} onClick={() => addInspection(data.inspectionTypeData,data.vehicleId,data.customerId)}>{data1.name}</a>
                                               )
                                           }):""


                                          }

                                        )()} */}



                                                      </div>
                                                      <span class="my-1 ml-2 note not-pos-abs "> <b class="red-text mr-2">Note:</b>  {data.notes ? data.notes : ""}</span>
                                                   </td>
                                                   {/* <td class="to-td text-capitalize"> <span>{data.customerName ? data.customerName : "--"}</span></td> */}
                                                   <td class="to-td" style={{ paddingRight: "20px !important" }}>{data.unitNumber ? data.unitNumber : "--"}</td>
                                                   <td class="to-td" style={{ paddingRight: "20px !important" }}>{data.vehicleVINNumber ? data.vehicleVINNumber : "--"}</td>

                                                   <td class="to-td" style={{ paddingRight: "20px" }}> {data.orderDate ? moment(data.orderDate).format('MM-DD-YYYY') : "--"}</td>
                                                   <td class="to-td">{data.status ? data.status : "--"}</td>
                                                   <td class="to-td" style={{ "width": "90px" }}>

                                                      <div style={{ display: "grid", gridGap: "5px" }}>
                                                         {(() => {

                                                            if (data.status === "In-Progress" || data.status === "Completed" || data.status === "To Be Approved" || data.status === "TO Created" || data.status === "In Process") {


                                                               return (<>{data.status=="Cancelled" || data.status=="Cancelled and Removed"?"":<a class="btn  text-uppercase disable-btn mb-1" type="button"  >Create TO</a>} </>)

                                                            } else {
                                                               return (<>{data.status=="Cancelled" || data.status=="Cancelled and Removed"?"":<a class="btn btn-success text-uppercase" type="button" data-toggle="modal" data-target="#myModal" onClick={() => creteTO(data.customerName, data.orderDate, data.inspectionName, data.vehicleId, data.customerId, data.id, data.inspectionData,data.notes,data.workOrderNumber, data.vehicleTypeName)}>Create TO</a>}</>)
                                                            }


                                                         }

                                                         

                                                         )()}

                                                         {(() => {

                                                         if (data.status === "Cancelled") {


                                                            return (<><a class="btn text-uppercase btn-outline-danger"  onClick={() => DeleteInspectionOrder(data.id)}>Remove</a> </>)

                                                         } else if(data.status === "Cancelled and Removed"){
                                                            return (<></>)
                                                         }else{
                                                            return (<><a class="btn text-uppercase btn-outline-primary" type="button" onClick={() => completeTaskOrder(data.id)}>Complete</a></>)
                                                         }


                                                         }
                                                         )()}
                                                        {/* {data.status=="Cancelled" || data.status=="Cancelled and Removed"?<a class="btn text-uppercase btn-outline-danger"  onClick={() => DeleteInspectionOrder(data.id)}>Delete</a>: <a class="btn text-uppercase btn-outline-primary" type="button" onClick={() => completeTaskOrder(data.id)}>Complete</a>} */}
                                                      </div>
                                                   </td>


                                                </tr>

                                             )) : ""

                                          }




                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                              {inspectionLists.length > 0 ? "" : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                              </div>}
                           </div>
                        </div>
                        {inspectionLists.length > 0 ?
                           <div class="d-flex align-items-center justify-content-between p-3">
                              <div class="d-flex">
                                 <div class="dropdown show mr-3 ">
                                    <select class="form-control custome-select" class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="display_pages_in_items" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                       {
                                          displayPageForInspection.map((data, index) => (
                                             <option value={data}>{data}</option>
                                          ))
                                       }
                                    </select>
                                 </div>
                                 <span class="d-flex align-items-center pagenation-mob">{inspectioCurrentPage * ITEMS_PER_PAGE <= totalInspectionPages ? inspectioCurrentPage * ITEMS_PER_PAGE : totalInspectionPages} out of {totalInspectionPages} Records Displayed</span>
                              </div>
                              <nav aria-label="Page navigation example">
                                 <Pagination
                                    total={totalInspectionPages}
                                    itemsPerPage={ITEMS_PER_PAGE}
                                    currentPage={inspectioCurrentPage}
                                    onPageChange={handlePageChangeForInspection}
                                 />

                              </nav>
                           </div> : ""}

                     </div>

                  </div>

               </div>
            </div>

         </div>
         <div id="myModal" class="modal fade" role="dialog">
            <div class="modal-dialog">


               <div class="modal-content">
                  <div class="modal-header">
                     <h5 class="modal-title">Create Task Order</h5>
                     <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => (closeModalPopup())}><span aria-hidden="true" data-bs-dismiss="modal">×</span></button>
                  </div>

                  <div class="modal-body">
                     <div className="row">
                        <div class="col-md-12">
                           <div class="form-group mb-3">
                              <label for="wqes" class="w-100">Task Order Name <span class="star">*</span> </label>
                              <input type="text" class="form-control" id="taskorderName" placeholder="Task order name" defaultValue={customerName} />
                              <input type="hidden" class="form-control" id="updateworkorder" placeholder="Task order name"  />
                              <span class="error" style={{ marginTop: "20px important" }}>Task Order Name is required</span>
                           </div>

                           <div class="form-group mb-3">
                              <label for="wqes" class="w-100">Assign Date <span class="star">*</span></label>
                              {/* <input type="text" class="form-control" id="qwes"  defaultValue={orderDate}/> */}
                              <DatePicker id="assignDate" minDate={moment(originalDate).toDate()} selected={originalDate} onChange={(date) => setOriginalDate(date)} name="originalDate" class="form-control" id="originalDate" placeholderText="MM/DD/YYYY" />
                           </div>
                           <div class="form-group">
                              <label for="wqes" class="w-100">Inspection List<span class="star">*</span></label>

                              <div class="col-md-12" style={{ marginTop: "16px" }}><input type="checkbox" class="form-check-input" name="contactSelectAll" onClick={(e) => SelectAllContactList(e, "")} />
                                 <label style={{ marginLeft: "26px", fontWeight: "bold", marginBottom: "0px" }}   >Select All</label></div>
                              {
                                 inspectionTypes ? inspectionTypes.map((inspection, index) => {
                                    return (<>

{(() =>
                                       {

                                          if (inspection.name === "WET Test Certification")
                                          {
                                             if (vehicleType === "Trailer" && vehicleType === "Tanker") {

                                                return (
                                                   inspectionNames.includes(inspection.name) ? <div class="form-check"> <div class="col-md-12"><input type="checkbox" class="form-check-input" defaultValue={inspection.id} name="contactListemails" defaultChecked={true} /><label class="form-check-label" for="check1">{inspection.name}</label></div> </div> : <div class="form-check"> <div class="col-md-12"><input type="checkbox" defaultValue={inspection.id} class="form-check-input" name="contactListemails" /><label class="form-check-label" for="check1">{inspection.name}</label></div></div>
                                                   )
                                                } else {
   
                                                }
   
                                             } else if (inspection.name === "Tractor PM/Inspection" || inspection.name ==  "Tractor Preventive Maintenance/Inspection")
                                             {
                                                if (vehicleType === "Tractor") {
   
                                                   return (
                                                      inspectionNames.includes(inspection.name) ? <div class="form-check"> <div class="col-md-12"><input type="checkbox" class="form-check-input" defaultValue={inspection.id} name="contactListemails" defaultChecked={true} /><label class="form-check-label" for="check1">{inspection.name}</label></div> </div> : <div class="form-check"> <div class="col-md-12"><input type="checkbox" defaultValue={inspection.id} class="form-check-input" name="contactListemails" /><label class="form-check-label" for="check1">{inspection.name}</label></div></div>
                                                      )
                                             } else {

                                             }

                                          }
                                          else if (inspection.name === "Driver Vehicle Inspection")
                                          {
                                             if (vehicleType === "Tractor") {

                                                return (
                                                   inspectionNames.includes(inspection.name) ? <div class="form-check"> <div class="col-md-12"><input type="checkbox" class="form-check-input" defaultValue={inspection.id} name="contactListemails" defaultChecked={true} /><label class="form-check-label" for="check1">{inspection.name}</label></div> </div> : <div class="form-check"> <div class="col-md-12"><input type="checkbox" defaultValue={inspection.id} class="form-check-input" name="contactListemails" /><label class="form-check-label" for="check1">{inspection.name}</label></div></div>
                                                   )
                                             } else {

                                             }

                                          } else {
                                             return (
                                                inspectionNames.includes(inspection.name) ? <div class="form-check"> <div class="col-md-12"><input type="checkbox" class="form-check-input" defaultValue={inspection.id} name="contactListemails" defaultChecked={true} /><label class="form-check-label" for="check1">{inspection.name}</label></div> </div> : <div class="form-check"> <div class="col-md-12"><input type="checkbox" defaultValue={inspection.id} class="form-check-input" name="contactListemails" /><label class="form-check-label" for="check1">{inspection.name}</label></div></div>
                                                )
                                             }

   
                                          })()}
                                     {/*  {inspectionNames.includes(inspection.name) ? <div class="form-check"> <div class="col-md-12"><input type="checkbox" class="form-check-input" defaultValue={inspection.id} name="contactListemails" defaultChecked={true} /><label class="form-check-label" for="check1">{inspection.name}</label></div> </div> : <div class="form-check"> <div class="col-md-12"><input type="checkbox" defaultValue={inspection.id} class="form-check-input" name="contactListemails" /><label class="form-check-label" for="check1">{inspection.name}</label></div></div>} */}


                                    </>)
                                 }) : ""
                              }
                              <span class="error" id="inspectionerror">Please choose at least one inspection</span>
                              {/* <input type="text" class="form-control" id="inspectionList" defaultValue={inspectionNames} /> */}
                           </div>
                           <div className="mt-4 clear">
                              <label for="addnotes">Add Note </label>

                              <textarea id="noteVal" name="addnotes" rows="4" maxLength="300" cols="50"> </textarea>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="modal-footer px-3">
                     <button type="button" class="btn btn-secondary border" data-dismiss="modal" id="closeModal" onClick={() => (closeModalPopup())}>Cancel</button>
                     <button type="button" class="btn btn-primary" onClick={createTaskOrder} >Submit</button>
                  </div>
               </div>

            </div>
         </div>
      </>
   )
};

InspectionOrders.propTypes = {};

InspectionOrders.defaultProps = {};

export default InspectionOrders;
