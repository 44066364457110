import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import CheckAuthentication from "../../../../utility/checkAuthentication";
import AddInspectionName from "../../../../utility/addInspectionName";
import Adddocs from "../../../../utility/Adddocs";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const LeakageTest = () => {
  const { taskOrderId,id, vid } = useParams();
  const [fileName, setfileName] = useState("")
  const [endDate, setEndDate] = useState(new Date());
  var customerid = localStorage.getItem("customerId");
  const [noOfRows, setNoOfRows] = useState(1);
  const [loading, setLoading] = useLoading("Please wait...");
  const [inputList, setInputList] = useState([{}]);
  const navigate = useNavigate();
  const [base64file, setBase64file] = useState('');
  var fields = [];
  var documentList = [];
  useEffect(() => {


  }, []);

  $(document).ready(function () {

    $("#unitDesignPressure,#testPressure").keypress(function (e) {
      // ,#testPressure
                // $("#errmsg").html("Digits Only").show().fadeOut("slow");
      // if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {

      //   return false;
      // }

      if ((e.which != 46 || $(this).val().indexOf('.') != -1) &&
      ((e.which < 48 || e.which > 57) &&
        (e.which != 0 && e.which != 8))) {
          return false;
    }

      var text = $(this).val();

  if ((text.indexOf('.') != -1) &&
    (text.substring(text.indexOf('.')).length > 3) &&
    (e.which != 0 && e.which != 8) &&
    ($(this)[0].selectionStart >= text.length - 3)) {
        return false;
  }
    });
  });

  const redirect = (page) => {

    navigate(page);

  }
  const handleSubmit = () => {

    let form_is_valid = true;

    var AnnualVehicleInspection = $("input[name='AnnualVehicleInspection']:checked").val();

    if (AnnualVehicleInspection==undefined) {
      form_is_valid = false;
      $("#leakage-check-type-message").html('*Please select leakage check type');

      // $('html,body').animate({
        
      //   scrollTop: $("#inspectionType").top
      // }, 500);
      

      $('html,body').animate({ scrollTop: "600px" }, 1000);

      return false
    
    } else {

      $("#leakage-check-type-message").html('');
      form_is_valid = true;
    }

    var closeAllValves = $("input[name='closeAllValves']:checked").val();
    var internalValves = $("input[name='internalValves']:checked").val();
    var externalValves = $("input[name='externalValves']:checked").val();
    // var closeAllTanksAndOpens=$("input[name='closeAllTanksAndOpens']:checked").val();
    var duringTheTestPeriod = $("input[name='duringTheTestPeriod']:checked").val();
    var inspectioninternalValves = $("input[name='inspectioninternalValves']:checked").val();
    var inspectionexternalValves = $("input[name='inspectionexternalValves']:checked").val();
    var remotevalveoperator = $("input[name='remotevalveoperator']:checked").val();
    var inspectOverallPiping = $("input[name='inspectOverallPiping']:checked").val();
    var withAllValvesClosed = $("input[name='withAllValvesClosed']:checked").val();
    var inspectiontankMarking = $("input[name='inspectiontankMarking']:checked").val();

    var unitDesignPressure = $('#unitDesignPressure').val();
    var allowMax = /^[0-9]*$/;
    if (!unitDesignPressure) {
      form_is_valid = false;
      $("#unitDesignPressure-message").html('*Unit design pressure is required');

      // $('html, body').animate({
      //   scrollTop: $("#inspectionType").offset().top
      // }, 500);

      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false

    } else {

      $("#unitDesignPressure-message").html('');
      form_is_valid = true;
    }



    var testPressure = $('#testPressure').val();
    if (!testPressure) {
      form_is_valid = false;
      $("#testPressure-message").html('*Test pressure is required');
      // $('html, body').animate({
      //   scrollTop: $("#inspectionType").offset().top
      // }, 500);
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    } else {
      $("#testPressure-message").html('');
      form_is_valid = true;
    }


    // var workOrderNumber = $('#workOrderNumber').val();
    // if (!workOrderNumber) {
    //   form_is_valid = false;
    //   $("#workOrderNumber-message").html('Work order number  is required');
    // }else{
    //         $("#workOrderNumber-message").html('');
    //         form_is_valid=true;
    //   }

    // ||closeAllTanksAndOpens==undefined
    // AnnualVehicleInspection == undefined || 
    if (closeAllValves == undefined || internalValves == undefined || externalValves == undefined || duringTheTestPeriod == undefined || inspectioninternalValves == undefined || inspectionexternalValves == undefined || remotevalveoperator == undefined || inspectOverallPiping == undefined || withAllValvesClosed == undefined || inspectiontankMarking == undefined) {

      // $('html, body').animate({
      //     scrollTop: $("#error_message").offset().top
      // }, 1000);


      // $('html, body').animate({
      //     scrollTop: $("#error_message").offset().top
      // }, 1000);
      // return false;



      // window.scrollTo({
      //   top: $('#error_message').offset().top,
      //   left: 0,
      //   behavior: 'smooth'
      // })

      // $.scrollTo($('#error_message'), 1000);

      // $('html, body').animate({
      //   scrollTop: $("#inspectionType").offset().top
      // }, 500);
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      form_is_valid = false;
      $('#error_message').css("color", "#d33232").html("*Please select one option for each question");
    
    } else {
      $('#error_message').html('');
    }

    
    
    // alert(form_is_valid);
    return form_is_valid;

  }




  // const numericFunDesignPressure=()=>{

  //   var form_is_valid = true;
  //   var unitDesignPressure=$('#unitDesignPressure').val();

  //   var allowMax=/^[0-9]*$/;
  //       if(!(allowMax.test(unitDesignPressure))){
  //           form_is_valid = false;
  //           $("#unitDesignPressure-message").html('Allows only numerics');
  //       }else{
  //           $("#unitDesignPressure-message").html('');
  //           form_is_valid=true;
  //       }

  //  return form_is_valid;

  // }

  // const numericFunTestPressure=()=>{

  //   var form_is_valid = true;
  //   var testPressure=$('#testPressure').val();

  //   var allowMax=/^[0-9]*$/;
  //       if(!(allowMax.test(testPressure))){
  //           form_is_valid = false;
  //           $("#testPressure-message").html('Allows only numerics');
  //       }else{
  //           $("#testPressure-message").html('');
  //           form_is_valid=true;
  //       }

  //  return form_is_valid;

  // }

  const save = (status) => {

    try {
      for (var i = 0; i < inputList.length; i++) {
        // console.log("filebase64=="+inputList[i].filetobase64);
        var extension = "";
        var byteString = "";
        console.log("results==" + inputList[i].filetobase64);
        if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
          extension = "pdf";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
        } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
          extension = "jpg";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
        }
        else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
          extension = "png";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
        }
        // else if (inputList[i].filetobase64.match("data:application/msword;base64,")) {
        //   extension = "word";
        //   byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/msword;base64,", "") : "";
        // }
        // else if (inputList[i].filetobase64.match("data:application/docx;base64,")) {
        //   extension = "docx";
        //   byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/docx;base64,", "") : "";
        // }
        // else if (inputList[i].filetobase64.match("data:application/doc;base64,")) {
        //   extension = "doc";
        //   byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/doc;base64,", "") : "";
        // }
        else {

        }


        documentList.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
      }
    } catch (exception) {
      // Swal.fire({
      //   position: 'center',
      //   icon: 'error',
      //   title: "",
      //   text: "Please attach document",
      //   showConfirmButton: true

      // }).then((result) => {

      //   if (result.isConfirmed) {


      //   }
      // });
      // return false;
    }

    // console.log("results=="+JSON.stringify(inputList));
    if (status == 'DRAFT') {


      if ($("#inspectedBy").val() === "") {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "",
          text: "Please enter inspectedBy",
          showConfirmButton: true

        }).then((result) => {

          if (result.isConfirmed) {


          }
        });
        return false;
      }

      var requestObjectForInsp =
      {

        "type": "Leakage Test",
        "inspectedBy": $("#inspectedBy").val(),
        "inspectedDate": $("#inspectedDate").val(),
        "verifiedBy": $("#verifiedBy").val(),
        "verifiedDate": $("#verifiedBy").val(),
        "workOrderNumber": $("#workOrderNumber").val() != '' ? $("#workOrderNumber").val() : '',
        "notes": $("#notes").val() != '' ? $("#notes").val() : '',
        "status": status,
        "taskOrderId":taskOrderId,
        "inspectionType":
        {
          "id": sessionStorage.getItem("selectedinspectionId"),
          "name": sessionStorage.getItem("selectedinspectionType")
        },
        "result":
        {
          "approvedCertify": "0",
          "inspectionFailed": "0"
        },
        "dispositionOfVehicle":
        {
          "vehicleReturnedServices": "0",
          "vehicleWithdrawnFromService": "0",
          "vehicleSentToAnotherRepairFacility": "0"
        },
        "vehicleDetails":
        {
          "vinNumber": $("#vin").html(),
          "lisencePlateNumber": $("#lisencePlateNumber").html(),
          "id": vid,
          "unitNumber": $("#lisencePlateNumber").html()
        },
        "leakageInspection":
        {
          "checkType":
          {
            "Hydrostatic": $("input[name='AnnualVehicleInspection']:checked").val() === "Hydrostatic" ? "1" : "0",
            "Pneumatic": $("input[name='AnnualVehicleInspection']:checked").val() === "Pneumatic" ? "1" : "0",
            // "modifiedmethod27": $("input[name='MODIFIEDMETHOD27']:checked").val() == "1" ? "1" : "0"
          },
          "unitdeSignPressure":
          {

            "unitDesignPressure": $("input[name='unitDesignPressure']").val() != '' ? $("input[name='unitDesignPressure']").val() : '',
            "closeAllValves": $("input[name='closeAllValves']:checked").val() === "1" ? "1" : "0",
            "internalValves": $("input[name='internalValves']:checked").val() === "1" ? "1" : "0",
            "externalValves": $("input[name='externalValves']:checked").val() === "1" ? "1" : "0",
            // "closeAllTanksAndOpens": $("input[name='closeAllTanksAndOpens']:checked").val() === "1" ? "1" : "0"
          },
          "testPressureUsed":
          {
            "testPressure": $("input[name='testPressure']").val() != '' ? $("input[name='testPressure']").val() : '',
            "duringTheTestPeriod": $("input[name='duringTheTestPeriod']:checked").val() === "1" ? "1" : "0"
          },
          "inspectionForValveLeakage":
          {
            "internalValves": $("input[name='inspectioninternalValves']:checked").val() === "1" ? "1" : "0",
            "externalValves": $("input[name='inspectionexternalValves']:checked").val() === "1" ? "1" : "0",
            "remotevalveoperator": $("input[name='remotevalveoperator']:checked").val() === "1" ? "1" : "0",
            "inspectOverallPiping": $("input[name='inspectOverallPiping']:checked").val() === "1" ? "1" : "0",
            "withAllValvesClosed": $("input[name='withAllValvesClosed']:checked").val() === "1" ? "1" : "0",
            "tankMarking": $("input[name='inspectiontankMarking']:checked").val() === "1" ? "1" : "0",
            "notes": $("input[name='notes']").val() != '' ? $("input[name='notes']").val() : ''
          }
          // "other":$("input[name='notes']").val()!=''?$("input[name='notes']").val():''
        },
        "documentList": documentList

      };

      $("#loadingforsubmit").show();
      // setLoading(true);
      //   const requestOptions = 
      //   {
      //      method: 'POST',
      //      headers: { 'Content-Type': 'application/json'
      //               },

      //         body: JSON.stringify(requestObject)
      //   };
      var requestOptions = requestObject.RequestHeader(requestObjectForInsp);
      services.addInspection(requestOptions).then((res) => {
        // setLoading(false);
        $("#loadingforsubmit").hide();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: "",
          text: res.message,
          showConfirmButton: true

        }).then((result) => {

          if (result.isConfirmed) {
            navigate("/vehicleDetails/" + vid);

          }
        });
      });

    } else {
      if (handleSubmit() == true) {



        if ($("#inspectedBy").val() === "") {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Please enter inspectedBy",
            showConfirmButton: true

          }).then((result) => {

            if (result.isConfirmed) {


            }
          });
          return false;
        }

        var requestObjectForInsp =
        {

          "type": sessionStorage.getItem("selectedinspectionType"),
          "inspectedBy": $("#inspectedBy").val(),
          "inspectedDate": $("#inspectedDate").val(),
          "verifiedBy": $("#verifiedBy").val(),
          "verifiedDate": $("#verifiedBy").val(),
          "workOrderNumber": $("#workOrderNumber").val() != '' ? $("#workOrderNumber").val() : '',
          "notes": $("#notes").val() != '' ? $("#notes").val() : '',
          "status": status,
          "taskOrderId":taskOrderId,
          "inspectionType":
          {
            "id": sessionStorage.getItem("selectedinspectionId"),
            "name": sessionStorage.getItem("selectedinspectionType")
          },
          "result":
          {
            "approvedCertify": "0",
            "inspectionFailed": "0"
          },
          "dispositionOfVehicle":
          {
            "vehicleReturnedServices": "0",
            "vehicleWithdrawnFromService": "0",
            "vehicleSentToAnotherRepairFacility": "0"
          },
          "vehicleDetails":
          {
            "vinNumber": $("#vin").html(),
            "lisencePlateNumber": $("#lisencePlateNumber").html(),
            "id": vid,
            "unitNumber": $("#lisencePlateNumber").html()
          },
          "leakageInspection":
          {
            "checkType":
            {
              "Hydrostatic": $("input[name='AnnualVehicleInspection']:checked").val() === "Hydrostatic" ? "1" : "0",
              "Pneumatic": $("input[name='AnnualVehicleInspection']:checked").val() === "Pneumatic" ? "1" : "0",
              // "modifiedmethod27": $("input[name='MODIFIEDMETHOD27']:checked").val() == "1" ? "1" : "0"
            },
            "unitdeSignPressure":
            {

              "unitDesignPressure": $("input[name='unitDesignPressure']").val() != '' ? $("input[name='unitDesignPressure']").val() : '',
              "closeAllValves": $("input[name='closeAllValves']:checked").val() === "1" ? "1" : "0",
              "internalValves": $("input[name='internalValves']:checked").val() === "1" ? "1" : "0",
              "externalValves": $("input[name='externalValves']:checked").val() === "1" ? "1" : "0",
              // "closeAllTanksAndOpens": $("input[name='closeAllTanksAndOpens']:checked").val() === "1" ? "1" : "0"
            },
            "testPressureUsed":
            {
              "testPressure": $("input[name='testPressure']").val() != '' ? $("input[name='testPressure']").val() : '',
              "duringTheTestPeriod": $("input[name='duringTheTestPeriod']:checked").val() === "1" ? "1" : "0"
            },
            "inspectionForValveLeakage":
            {
              "internalValves": $("input[name='inspectioninternalValves']:checked").val() === "1" ? "1" : "0",
              "externalValves": $("input[name='inspectionexternalValves']:checked").val() === "1" ? "1" : "0",
              "remotevalveoperator": $("input[name='remotevalveoperator']:checked").val() === "1" ? "1" : "0",
              "inspectOverallPiping": $("input[name='inspectOverallPiping']:checked").val() === "1" ? "1" : "0",
              "withAllValvesClosed": $("input[name='withAllValvesClosed']:checked").val() === "1" ? "1" : "0",
              "tankMarking": $("input[name='inspectiontankMarking']:checked").val() === "1" ? "1" : "0",
              // "notes":$("input[name='notes']").val()!=''?$("input[name='notes']").val():''
            }
            // "other":$("input[name='notes']").val()!=''?$("input[name='notes']").val():''
          },
          "documentList": documentList

        };

        $("#loadingforsubmit").show();
        // setLoading(true);
        //   const requestOptions = 
        //   {
        //      method: 'POST',
        //      headers: { 'Content-Type': 'application/json'
        //               },

        //         body: JSON.stringify(requestObject)
        //   };
        var requestOptions = requestObject.RequestHeader(requestObjectForInsp);
        services.addInspection(requestOptions).then((res) => {
          // setLoading(false);
          $("#loadingforsubmit").hide();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: "",
            text: res.message,
            showConfirmButton: true

          }).then((result) => {

            if (result.isConfirmed) {
              navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

            }
          });
        });
      }
    }


  }
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, {}]);
  };
  const fileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(null, reader.result)
    }
    reader.onerror = function (error) {
      cb(error, null)
    }
  }
  const onUploadFileChange = (event, index) => {


    var target = event.target;
    if (target.files < 1 || !target.validity.valid) {
      return
    }
    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        const { name, value } = event.target;
        const list = [...inputList];
        list[index][name] = result;
        setBase64file(result);
        // setFile(result)
        setfileName(target.files[0]);
        // setFileName(target.files[0])
      }
    })
  }
  // const checkMethod = () => {
  //   var checkValue = $("input[name='MODIFIEDMETHOD27']:checked").val();
  //   if (checkValue == '1') {
  //     $("#testPressure").val("METHOD 27");
  //     $("#testPressure").prop('readonly', true);
  //   } else {
  //     $("#testPressure").prop('readonly', false);
  //     $("#testPressure").val("");
      
  //   }
  // }

  return (
    <>
    {LoadingComponentForSubmit}
      {/* <div class="d-flex justify-content-between align-items-center">
        <h4 class="font-weight-bold">Vehicle Inspection</h4>
        <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-white">Back</a>
      </div> */}

      <div class="card">

        {/* <div class="divider"></div> */}
        <div class="row">
          <div class="col-lg-12">
            <h4 class="mb-2 form-inspection-title text-center" id="inspectionType">Leakage Test</h4>
            <p id="leakage-check-type-message" class="error"></p>
            <p id="unitDesignPressure-message" class="error"></p> 
            <p id="testPressure-message" class="error"></p>
            <p id="error_message" class="center"></p>
          </div>

          <div class="col-lg-12">
            <WorkOrderNumberDiv />
            
            {/* <p id="error_message" class="center"></p> */}
          </div>



          <div class="col-lg-3 mb-2 align-items-center d-flex">
            <div class="d-flex align-items-center">
              <span>Leakage Check Type <span class="star">*</span>:</span>
            </div>
          </div>

          <div class="col-lg-2 mb-2">
            <div class="d-flex align-items-center">
              <input type="radio" id="vehicle1" name="AnnualVehicleInspection" value="Hydrostatic" class="mr-2" />
              <span>Hydrostatic</span>
            </div>
          </div>

          <div class="col-lg-2 mb-2">
            <div class="d-flex align-items-center">
              <input type="radio" id="vehicle1" name="AnnualVehicleInspection" value="Pneumatic" class="mr-2" />
              <span>Pneumatic</span>
            </div>
          </div>

          {/* <div class="col-lg-12 mb-3">
            <div class="d-flex align-items-center">
              <input type="checkbox" id="MODIFIEDMETHOD27" onClick={() => checkMethod()} name="MODIFIEDMETHOD27" value="1" class="mr-2" />
              <b>MODIFIED METHOD 27</b>
            </div>
          </div> */}




          <div class="col-lg-12">
            <div class="inspection-wrap">

              <div class="table-2 mb-4">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Unit Design Pressure <span class="star">*</span>: <input name="unitDesignPressure" id="unitDesignPressure" size="10" /></th>
                      <th scope="col"  className="text-center">Yes</th>
                      <th scope="col"  className="text-center">No</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td>Close all valves and all tank with manhole cover open. Inspect all surface for leaks inspect all top and bottom component installation below water line for leaks: cleanouts-welds-vents valve sumps. Are there any leaks?</td>
                      <td class="text-center">  <input type="radio" name="closeAllValves" value="1" aria-label="Radio button for following text input" /></td>
                      <td class="text-center">  <input type="radio" name="closeAllValves" value="0" aria-label="Radio button for following text input" /></td>
                    </tr>

                    <tr>
                      <td>Internal valves: open external valves - inspect for leaks - close external valves. Are there any leaks?</td>
                      <td class="text-center">  <input type="radio" name="internalValves" value="1" aria-label="Radio button for following text input" /></td>
                      <td class="text-center">  <input type="radio" name="internalValves" value="0" aria-label="Radio button for following text input" /></td>
                    </tr>

                    <tr>
                      <td>External valves: open internal valves, fill piping with water - inspect for leaks - close internal valves. Are there any leaks?</td>
                      <td class="text-center">  <input type="radio" name="externalValves" value="1" aria-label="Radio button for following text input" /></td>
                      <td class="text-center">  <input type="radio" name="externalValves" value="0" aria-label="Radio button for following text input" /></td>
                    </tr>

                    {/* <tr>
                      <td>Close all tank openings and valves. Apply water pressure (80% of MAWP) at top of tank - Must hold for 5 minutes</td>
                      <td class="text-center">  <input type="radio" name="closeAllTanksAndOpens" value="1" aria-label="Radio button for following text input" /></td>
                      <td class="text-center">  <input type="radio" name="closeAllTanksAndOpens" value="0" aria-label="Radio button for following text input" /></td>
                    </tr> */}


                  </tbody>
                </table>
              </div>

            </div>
          </div>

          <div class="col-lg-12">
            <div class="inspection-wrap">

              <div class="table-2 mb-4">
                <table class="table">
                  <thead>
                    {/* <tr>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr> */}
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="3">Close all tank openings and valves. Apply water pressure (80% of MAWP) at top of tank - Must hold for 5 minutes.<br />  Test Pressure Used  <span class="star">*</span>: <input name="testPressure" id="testPressure" /> </td>

                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>

          <div class="col-lg-12">
            <div class="inspection-wrap">

              <div class="table-2 mb-4">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col"  className="text-center">Yes</th>
                      <th scope="col"  className="text-center">No</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td>During the test period : Inspect all surfaces and component installations for leaks. Attention given to manhole  assembly area. Are there any leaks? </td>
                      <td class="text-center">  <input type="radio" name="duringTheTestPeriod" value="1" aria-label="Radio button for following text input" /></td>
                      <td class="text-center">  <input type="radio" name="duringTheTestPeriod" value="0" aria-label="Radio button for following text input" /></td>
                    </tr>




                  </tbody>
                </table>
              </div>

            </div>
          </div>

          <div class="col-lg-12">
            <div class="inspection-wrap">

              <div class="table-2 mb-4">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Inspection for valve Leakage</th>
                      <th scope="col"  className="text-center">Yes</th>
                      <th scope="col"  className="text-center">No</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td>Internal valves : open external valves and drain piping. Inspect for continued drainage - close external valves. Are there any leaks?</td>
                      <td class="text-center">  <input type="radio" name="inspectioninternalValves" value="1" aria-label="Radio button for following text input" /></td>
                      <td class="text-center">  <input type="radio" name="inspectioninternalValves" value="0" aria-label="Radio button for following text input" /></td>
                    </tr>

                    <tr>
                      <td>External valves : open internal valves and fill piping with water - inspect leakage from external valves. Are there any leaks?</td>
                      <td class="text-center">  <input type="radio" name="inspectionexternalValves" value="1" aria-label="Radio button for following text input" /></td>
                      <td class="text-center">  <input type="radio" name="inspectionexternalValves" value="0" aria-label="Radio button for following text input" /></td>
                    </tr>

                    <tr>
                      <td>Remote valves operators : open internal valve with normal operator - close with remote operator - open external valves and drain piping-  inspect for continued drainage - close external valves - open and close internal valve with normal operator, filling pipe with water. Are there any leaks? </td>
                      <td class="text-center">  <input type="radio" name="remotevalveoperator" value="1" aria-label="Radio button for following text input" /></td>
                      <td class="text-center">  <input type="radio" name="remotevalveoperator" value="0" aria-label="Radio button for following text input" /></td>
                    </tr>
                    <tr>
                      <td>Inspect overall piping system for leakage. Are there any leaks?</td>
                      <td class="text-center">  <input type="radio" name="inspectOverallPiping" value="1" aria-label="Radio button for following text input" /></td>
                      <td class="text-center">  <input type="radio" name="inspectOverallPiping" value="0" aria-label="Radio button for following text input" /></td>
                    </tr>
                    <tr>
                      <td>With all valves closed, hold test pressure 5 mins for successful test. Are there any leaks?</td>
                      <td class="text-center">  <input type="radio" name="withAllValvesClosed" value="1" aria-label="Radio button for following text input" /></td>
                      <td class="text-center">  <input type="radio" name="withAllValvesClosed" value="0" aria-label="Radio button for following text input" /></td>
                    </tr>

                    <tr>
                      <td>Tank marking: Date(month/year) and service symbol after all defects are corrected. Did you update markings on tank? </td>
                      <td class="text-center">  <input type="radio" name="inspectiontankMarking" value="1" aria-label="Radio button for following text input" /></td>
                      <td class="text-center">  <input type="radio" name="inspectiontankMarking" value="0" aria-label="Radio button for following text input" /></td>
                    </tr>



                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>



        <div class="divider2"></div>
        <div class="row">
          <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
        </div>


        {/* <div class="row p-3">
                                <div class="col-lg-12">
                                   <div class="d-flex align-items-center mb-3">
                                    <input type="checkbox" aria-label="Checkbox for following text input" class="mr-2"/>
                                        <small>Have any service been performed</small>
                                   </div>
                                   <div class="form-group">
                                    <label for="exampleInputPassword1"><b>Add a written Question</b></label>
                                    <textarea class="form-control mb-2" id="exampleFormControlTextarea1" rows="3"></textarea>
                                    <a href="#" type="button" class="btn btn-success">Submit</a>
                                  </div>

                                  <div class="qst mt-5">
                                    <div class="qst-item mb-4">
                                        <span>Q1</span> <span>Would you like us to repaire a trailer engine?</span> <span class="btn-label-orange">Waiting</span>
                                    </div>
                                    <div class="qst-item mb-3">
                                        <span>Q2</span> <span>Would you like us to repaire a trailer engine?</span> <span class="btn-label-green">Approved</span>
                                    </div>
                                  </div>

                                </div>
                            </div> */}

        <AddInspectionName setEndDate={setEndDate} endDate={endDate} />

        <div class="divider2"></div>

        <div class="row p-3 pr-4 d-flex justify-content-end" style={{ marginBottom: "-20px" }}>
          <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
          <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a>
          <a href="#" class="btn btn-primary mr-1" onClick={() => save("APPROVED PENDING")} >Save</a>
        </div>
      </div>



    </>
  )
};

LeakageTest.propTypes = {};

LeakageTest.defaultProps = {};

export default LeakageTest;
