import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import CheckAuthentication from "../../../../utility/checkAuthentication";
import AddInspectionName from "../../../../utility/addInspectionName";
import Adddocs from "../../../../utility/Adddocs";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";

const TractorpmTest = () => {
  const { taskOrderId,id, vid } = useParams();
  const [fileName, setfileName] = useState("")
  const [endDate, setEndDate] = useState(new Date());
  var customerid = localStorage.getItem("customerId");
  const [noOfRows, setNoOfRows] = useState(1);
  const [loading, setLoading] = useLoading("Please wait...");
  const [inputList, setInputList] = useState([{}]);
  const navigate = useNavigate();
  const [base64file, setBase64file] = useState('');
  const [fromDate, setFromDate] = useState();
   const [toDate, setToDate] = useState();
  var fields = [];
  var documentList = [];
  useEffect(() => {
    $('#break_measures').hide();
    $('#threaddepth_measures').hide();
    $('#tpmt_dateValidMsg').hide();
    $('#tpmt_customerValidMsg').hide();
    $('#tpmt_unitValidMsg').hide();
    $('#tpmt_mileageValidMsg').hide();
    $('#tpmt_invoiceValidMsg').hide();
    $('#breaklininglfValidMsg').hide();
    $('#breakliningrfValidMsg').hide();
    $('#breaklininglfrValidMsg').hide();
    $('#breakliningrfrValidMsg').hide();
    $('#breaklininglrrValidMsg').hide();
    $('#breakliningrrrValidMsg').hide();
    $('#treaddepthrfValidMsg').hide();
    $('#treaddepthrfpsiValidMsg').hide();
    $('#treaddepthlfValidMsg').hide();
    $('#treaddepthlfpsiValidMsg').hide();
    $('#treaddepthrfrValidMsg').hide();
    $('#treaddepthrfrpsiValidMsg').hide();
    $('#treaddepthlfrValidMsg').hide();
    $('#treaddepthlfrpsiValidMsg').hide();
    $('#treaddepthrrrValidMsg').hide();
    $('#treaddepthrrrpsiValidMsg').hide();
    $('#treaddepthlrrValidMsg').hide();     
    $('#treaddepthlrrpsiValidMsg').hide(); 
    $('#tractorpm_validation').hide();
  }, []);

  $(document).ready(function () {

    $("#unitDesignPressure,#testPressure").keypress(function (e) {
      // ,#testPressure
                // $("#errmsg").html("Digits Only").show().fadeOut("slow");
      // if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {

      //   return false;
      // }

      if ((e.which != 46 || $(this).val().indexOf('.') != -1) &&
      ((e.which < 48 || e.which > 57) &&
        (e.which != 0 && e.which != 8))) {
          return false;
    }

      var text = $(this).val();

  if ((text.indexOf('.') != -1) &&
    (text.substring(text.indexOf('.')).length > 3) &&
    (e.which != 0 && e.which != 8) &&
    ($(this)[0].selectionStart >= text.length - 3)) {
        return false;
  }
    });
  });

  const redirect = (page) => {

    navigate(page);

  }
  const handleSubmit = () => {

    let form_is_valid = true;

    var lightingdevices = $("input[name='lightingdevices']:checked").val();
    if(lightingdevices == '' || lightingdevices == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectwindshield = $("input[name='inspectwindshield']:checked").val();
    if(inspectwindshield == '' || inspectwindshield == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectwiper = $("input[name='inspectwiper']:checked").val();   
    if(inspectwiper == '' || inspectwiper == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var washerfluid = $("input[name='washerfluid']:checked").val();
    
    var inspectbumper = $("input[name='inspectbumper']:checked").val();
    var inspectsteps = $("input[name='inspectsteps']:checked").val();
    var inspectmirrors = $("input[name='inspectmirrors']:checked").val();
    var inspectfueltank = $("input[name='inspectfueltank']:checked").val();
             
    var inspectemergencyequip = $("input[name='inspectemergencyequip']:checked").val();
    if(inspectemergencyequip == '' || inspectemergencyequip == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectwarnlight = $("input[name='inspectwarnlight']:checked").val();
    if(inspectwarnlight == '' || inspectwarnlight == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectabslight = $("input[name='inspectabslight']:checked").val();
    if(inspectabslight == '' || inspectabslight == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspecthorns = $("input[name='inspecthorns']:checked").val();
    if(inspecthorns == '' || inspecthorns == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }
         
    var measurebreaklining = $("input[name='measurebreaklining']:checked").val();
    var inspectbreaks = $("input[name='inspectbreaks']:checked").val();
    if(inspectbreaks == '' || inspectbreaks == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectparkingbreak = $("input[name='inspectparkingbreak']:checked").val();
    if(inspectparkingbreak == '' || inspectparkingbreak == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectbreakdrum = $("input[name='inspectbreakdrum']:checked").val();
    if(inspectbreakdrum == '' || inspectbreakdrum == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectbreakhoses = $("input[name='inspectbreakhoses']:checked").val();
    if(inspectbreakhoses == '' || inspectbreakhoses == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectbreaktubing = $("input[name='inspectbreaktubing']:checked").val();
    if(inspectbreaktubing == '' || inspectbreaktubing == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectaircompressor = $("input[name='inspectaircompressor']:checked").val();
    if(inspectaircompressor == '' || inspectaircompressor == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectabssystem = $("input[name='inspectabssystem']:checked").val();
    if(inspectabssystem == '' || inspectabssystem == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectslackadjuster = $("input[name='inspectslackadjuster']:checked").val();
    if(inspectslackadjuster == '' || inspectslackadjuster == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }
                
    var inspecthoses = $("input[name='inspecthoses']:checked").val();
    var inspectbelt = $("input[name='inspectbelt']:checked").val();
    var inspectleaks = $("input[name='inspectleaks']:checked").val();
    if(inspectleaks == '' || inspectleaks == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectexhaustleaks = $("input[name='inspectexhaustleaks']:checked").val();
    if(inspectexhaustleaks == '' || inspectexhaustleaks == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectnoburning = $("input[name='inspectnoburning']:checked").val();
    if(inspectnoburning == '' || inspectnoburning == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }
                              
    var inspectsteering = $("input[name='inspectsteering']:checked").val();
    if(inspectsteering == '' || inspectsteering == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectsteeringwheel = $("input[name='inspectsteeringwheel']:checked").val();
    if(inspectsteeringwheel == '' || inspectsteeringwheel == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectsteeringcolumn = $("input[name='inspectsteeringcolumn']:checked").val();
    if(inspectsteeringcolumn == '' || inspectsteeringcolumn == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectsteeringsystem = $("input[name='inspectsteeringsystem']:checked").val();
    if(inspectsteeringsystem == '' || inspectsteeringsystem == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectwheelseals = $("input[name='inspectwheelseals']:checked").val();
    var inspecttransmissionlube = $("input[name='inspecttransmissionlube']:checked").val();
    var inspectjoints = $("input[name='inspectjoints']:checked").val();
    var inspecthousinglube = $("input[name='inspecthousinglube']:checked").val();
    var inspectallseals = $("input[name='inspectallseals']:checked").val();
    
    var inspectframe = $("input[name='inspectframe']:checked").val();
    if(inspectframe == '' || inspectframe == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectspringhangers = $("input[name='inspectspringhangers']:checked").val();
    var inspectspringassembly = $("input[name='inspectspringassembly']:checked").val();
    if(inspectspringassembly == '' || inspectspringassembly == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspecttorque = $("input[name='inspecttorque']:checked").val();
    if(inspecttorque == '' || inspecttorque == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectframemembers = $("input[name='inspectframemembers']:checked").val();
    if(inspectframemembers == '' || inspectframemembers == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspecttireclearance = $("input[name='inspecttireclearance']:checked").val();
    if(inspecttireclearance == '' || inspecttireclearance == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }
                
    var inspectfifthwheel = $("input[name='inspectfifthwheel']:checked").val();
    if(inspectfifthwheel == '' || inspectfifthwheel == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectpintlehook = $("input[name='inspectpintlehook']:checked").val();
    if(inspectpintlehook == '' || inspectpintlehook == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectsafetydevices = $("input[name='inspectsafetydevices']:checked").val();
    if(inspectsafetydevices == '' || inspectsafetydevices == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectsaddlemounts = $("input[name='inspectsaddlemounts']:checked").val();
    if(inspectsaddlemounts == '' || inspectsaddlemounts == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }
                                                                     
    var inspectwheelsforcracks = $("input[name='inspectwheelsforcracks']:checked").val();
    if(inspectwheelsforcracks == '' || inspectwheelsforcracks == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectbrokenlug = $("input[name='inspectbrokenlug']:checked").val();
    if(inspectbrokenlug == '' || inspectbrokenlug == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspectvalvestems = $("input[name='inspectvalvestems']:checked").val();
    if(inspectvalvestems == '' || inspectvalvestems == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var checktirepressure = $("input[name='checktirepressure']:checked").val();
    var inspectsteeringaxle = $("input[name='inspectsteeringaxle']:checked").val();
    if(inspectsteeringaxle == '' || inspectsteeringaxle == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspecttires = $("input[name='inspecttires']:checked").val();
    if(inspecttires == '' || inspecttires == undefined){
      $('#tractorpm_validation').show();
      form_is_valid = false;
      $('html,body').animate({ scrollTop: "600px" }, 1000);
      return false
    }
    else {
      $('#tractorpm_validation').hide();
      form_is_valid = true;
    }

    var inspecttreaddepths = $("input[name='inspecttreaddepths']:checked").val();
    var inspectbatterybox = $("input[name='inspectbatterybox']:checked").val();
    var inspectbatteryconnections = $("input[name='inspectbatteryconnections']:checked").val();

    var changeoil = $("input[name='changeoil']:checked").val();
    var changeoilfilter = $("input[name='changeoilfilter']:checked").val();
    var inspectengineairfilter = $("input[name='inspectengineairfilter']:checked").val();
    var inspectfreshairfilter = $("input[name='inspectfreshairfilter']:checked").val();

    var lubeallpoints = $("input[name='lubeallpoints']:checked").val();
    var lube5thwheel = $("input[name='lube5thwheel']:checked").val();
    var checkfluidlevels = $("input[name='checkfluidlevels']:checked").val();
    var inspectallplugs = $("input[name='inspectallplugs']:checked").val();
    var changeservicenumber = $("input[name='changeservicenumber']:checked").val();
    
//alert(lightingdevices);

if(measurebreaklining != undefined){
  var breaklininglf = $('#breaklininglf').val();
    if(breaklininglf == '' || breaklininglf == undefined){
      $('#breaklininglfValidMsg').show();
      form_is_valid = false;
      $('#breaklininglf').focus();
      return false
    }
    else {
      $('#breaklininglfValidMsg').hide();
      form_is_valid = true;
    }

    var breakliningrf = $('#breakliningrf').val();
    if(breakliningrf == '' || breakliningrf == undefined){
      $('#breakliningrfValidMsg').show();
      form_is_valid = false;
      $('#breakliningrf').focus();
      return false
    }
    else {
      $('#breakliningrfValidMsg').hide();
      form_is_valid = true;
    }

    var breaklininglfr = $('#breaklininglfr').val();
    if(breaklininglfr == '' || breaklininglfr == undefined){
      $('#breaklininglfrValidMsg').show();
      form_is_valid = false;
      $('#breaklininglfr').focus();
      return false
    }
    else {
      $('#breaklininglfrValidMsg').hide();
      form_is_valid = true;
    }

    var breakliningrfr = $('#breakliningrfr').val();
    if(breakliningrfr == '' || breakliningrfr == undefined){
      $('#breakliningrfrValidMsg').show();
      form_is_valid = false;
      $('#breakliningrfr').focus();
      return false
    }
    else {
      $('#breakliningrfrValidMsg').hide();
      form_is_valid = true;
    }

    var breaklininglrr = $('#breaklininglrr').val();
    if(breaklininglrr == '' || breaklininglrr == undefined){
      $('#breaklininglrrValidMsg').show();
      form_is_valid = false;
      $('#breaklininglrr').focus();
      return false
    }
    else {
      $('#breaklininglrrValidMsg').hide();
      form_is_valid = true;
    }

    var breakliningrrr = $('#breakliningrrr').val();
    if(breakliningrrr == '' || breakliningrrr == undefined){
      $('#breakliningrrrValidMsg').show();
      form_is_valid = false;
      $('#breakliningrrr').focus();
      return false
    }
    else {
      $('#breakliningrrrValidMsg').hide();
      form_is_valid = true;
    }
}

if(inspecttreaddepths != undefined){
  var treaddepthrf = $('#treaddepthrf').val();
    if(treaddepthrf == '' || treaddepthrf == undefined){
      $('#treaddepthrfValidMsg').show();
      form_is_valid = false;
      $('#treaddepthrf').focus();
      return false
    }
    else {
      $('#treaddepthrfValidMsg').hide();
      form_is_valid = true;
    }

    var treaddepthrfpsi = $('#treaddepthrfpsi').val();
    if(treaddepthrfpsi == '' || treaddepthrfpsi == undefined){
      $('#treaddepthrfpsiValidMsg').show();
      form_is_valid = false;
      $('#treaddepthrfpsi').focus();
      return false
    }
    else {
      $('#treaddepthrfpsiValidMsg').hide();
      form_is_valid = true;
    }

    var treaddepthlf = $('#treaddepthlf').val();
    if(treaddepthlf == '' || treaddepthlf == undefined){
      $('#treaddepthlfValidMsg').show();
      form_is_valid = false;
      $('#treaddepthlf').focus();
      return false
    }
    else {
      $('#treaddepthlfValidMsg').hide();
      form_is_valid = true;
    }

    var treaddepthlfpsi = $('#treaddepthlfpsi').val();
    if(treaddepthlfpsi == '' || treaddepthlfpsi == undefined){
      $('#treaddepthlfpsiValidMsg').show();
      form_is_valid = false;
      $('#treaddepthlfpsi').focus();
      return false
    }
    else {
      $('#treaddepthlfpsiValidMsg').hide();
      form_is_valid = true;
    }
                   
    var treaddepthrfr = $('#treaddepthrfr').val();
    if(treaddepthrfr == '' || treaddepthrfr == undefined){
      $('#treaddepthrfrValidMsg').show();
      form_is_valid = false;
      $('#treaddepthrfr').focus();
      return false
    }
    else {
      $('#treaddepthrfrValidMsg').hide();
      form_is_valid = true;
    }

    var treaddepthrfrpsi = $('#treaddepthrfrpsi').val();
    if(treaddepthrfrpsi == '' || treaddepthrfrpsi == undefined){
      $('#treaddepthrfrpsiValidMsg').show();
      form_is_valid = false;
      $('#treaddepthrfrpsi').focus();
      return false
    }
    else {
      $('#treaddepthrfrpsiValidMsg').hide();
      form_is_valid = true;
    }

    var treaddepthlfr = $('#treaddepthlfr').val();
    if(treaddepthlfr == '' || treaddepthlfr == undefined){
      $('#treaddepthlfrValidMsg').show();
      form_is_valid = false;
      $('#treaddepthlfr').focus();
      return false
    }
    else {
      $('#treaddepthlfrValidMsg').hide();
      form_is_valid = true;
    }

    var treaddepthlfrpsi = $('#treaddepthlfrpsi').val();
    if(treaddepthlfrpsi == '' || treaddepthlfrpsi == undefined){
      $('#treaddepthlfrpsiValidMsg').show();
      form_is_valid = false;
      $('#treaddepthlfrpsi').focus();
      return false
    }
    else {
      $('#treaddepthlfrpsiValidMsg').hide();
      form_is_valid = true;
    }
    
    var treaddepthrrr = $('#treaddepthrrr').val();
    if(treaddepthrrr == '' || treaddepthrrr == undefined){
      $('#treaddepthrrrValidMsg').show();
      form_is_valid = false;
      $('#treaddepthrrr').focus();
      return false
    }
    else {
      $('#treaddepthrrrValidMsg').hide();
      form_is_valid = true;
    }

    var treaddepthrrrpsi = $('#treaddepthrrrpsi').val();
    if(treaddepthrrrpsi == '' || treaddepthrrrpsi == undefined){
      $('#treaddepthrrrpsiValidMsg').show();
      form_is_valid = false;
      $('#treaddepthrrrpsi').focus();
      return false
    }
    else {
      $('#treaddepthrrrpsiValidMsg').hide();
      form_is_valid = true;
    }

    var treaddepthlrr = $('#treaddepthlrr').val();
    if(treaddepthlrr == '' || treaddepthlrr == undefined){
      $('#treaddepthlrrValidMsg').show();
      form_is_valid = false;
      $('#treaddepthlrr').focus();
      return false
    }
    else {
      $('#treaddepthlrrValidMsg').hide();
      form_is_valid = true;
    }

    var treaddepthlrrpsi = $('#treaddepthlrrpsi').val();
    if(treaddepthlrrpsi == '' || treaddepthlrrpsi == undefined){
      $('#treaddepthlrrpsiValidMsg').show();
      form_is_valid = false;
      $('#treaddepthlrrpsi').focus();
      return false
    }
    else {
      $('#treaddepthlrrpsiValidMsg').hide();
      form_is_valid = true;
    }
}
    
     // alert(form_is_valid);
    
/*
    if(form_is_valid == false) {

      $('html,body').animate({ scrollTop: "600px" }, 1000);
      form_is_valid = false;
      alert(form_is_valid);
      $('#error_message').css("color", "#d33232").html("*Please select one option for each question");
    
    } else {
      $('#error_message').html('');
    }
    */
    // alert(form_is_valid);
    return form_is_valid;

  }


  const save = (status) => {

    try {
      for (var i = 0; i < inputList.length; i++) {
        // console.log("filebase64=="+inputList[i].filetobase64);
        var extension = "";
        var byteString = "";
        console.log("results==" + inputList[i].filetobase64);
        if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
          extension = "pdf";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
        } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
          extension = "jpg";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
        }
        else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
          extension = "png";
          byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
        }
       
        else {

        }


        documentList.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
      }
    } catch (exception) {
      // Swal.fire({
      //   position: 'center',
      //   icon: 'error',
      //   title: "",
      //   text: "Please attach document",
      //   showConfirmButton: true

      // }).then((result) => {

      //   if (result.isConfirmed) {


      //   }
      // });
      // return false;
    }

    // console.log("results=="+JSON.stringify(inputList));
    if (status == 'DRAFT') {


      if ($("#inspectedBy").val() === "") {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "",
          text: "Please enter inspectedBy",
          showConfirmButton: true

        }).then((result) => {

          if (result.isConfirmed) {


          }
        });
        return false;
      }

      var requestObjectForInsp =
      {

        "type": sessionStorage.getItem("selectedinspectionType"),
        "inspectedBy": $("#inspectedBy").val(),
        "inspectedDate": $("#inspectedDate").val(),
        "verifiedBy": $("#verifiedBy").val(),
        "verifiedDate": $("#verifiedBy").val(),
        "workOrderNumber": $("#workOrderNumber").val() != '' ? $("#workOrderNumber").val() : '',
        "notes": $("#notes").val() != '' ? $("#notes").val() : '',
        "status": status,
        "taskOrderId":taskOrderId,
        "inspectionType":
        {
          "id": sessionStorage.getItem("selectedinspectionId"),
          "name": sessionStorage.getItem("selectedinspectionType")
        },
        "result":
        {
          "approvedCertify": "0",
          "inspectionFailed": "0"
        },
        "dispositionOfVehicle":
        {
          "vehicleReturnedServices": "0",
          "vehicleWithdrawnFromService": "0",
          "vehicleSentToAnotherRepairFacility": "0"
        },
        "vehicleDetails":
        {
          "vinNumber": $("#vin").html(),
          "lisencePlateNumber": $("#lisencePlateNumber").html(),
          "id": vid,
          "unitNumber": $("#lisencePlateNumber").html()
        },
        "tractorPmInspection":
        {
          "tpmt_date" : $('#tpmt_date').val(),
          "tpmt_customer" : $('#tpmt_customer').val(),
          "tpmt_unit" : $('#tpmt_unit').val(),
          "tpmt_mileage" : $('#tpmt_mileage').val(),
          "tpmt_invoice" : $('#tpmt_invoice').val(),
          "lighting":
          {
            "allLightingDevices": $("input[name='lightingdevices']:checked").val() === "1" ? "1" : "0",
          },
          "windShield":
          {
            "inspectWindShield": $("input[name='inspectwindshield']:checked").val() === "1" ? "1" : "0",
            "inspectWiper": $("input[name='inspectwiper']:checked").val() === "1" ? "1" : "0",
            "inspectWasherFluid": $("input[name='washerfluid']:checked").val() === "1" ? "1" : "0",
          },
          "generalInspection":
          {
            "inspectBumpers": $("input[name='inspectbumper']:checked").val() === "1" ? "1" : "0",
            "inspectSteps": $("input[name='inspectsteps']:checked").val() === "1" ? "1" : "0",
            "inspectMirrors": $("input[name='inspectmirrors']:checked").val() === "1" ? "1" : "0",
            "inspectFuelTank": $("input[name='inspectfueltank']:checked").val() === "1" ? "1" : "0",
          },
          "inCab":
          {
            "inspectEmergencyEquip": $("input[name='inspectemergencyequip']:checked").val() === "1" ? "1" : "0",
            "inspectWarnLight": $("input[name='inspectwarnlight']:checked").val() === "1" ? "1" : "0",
            "inspectABSLight": $("input[name='inspectabslight']:checked").val() === "1" ? "1" : "0",
            "inspectHorns": $("input[name='inspecthorns']:checked").val() === "1" ? "1" : "0",
          },
          "breakSystem":
          {
            "measureBreakLining": $("input[name='measurebreaklining']:checked").val() === "1" ? "1" : "0",
            "inspectBreaks": $("input[name='inspectbreaks']:checked").val() === "1" ? "1" : "0",
            "inspectParkingBreak": $("input[name='inspectparkingbreak']:checked").val() === "1" ? "1" : "0",
            "inspectBreakDrum": $("input[name='inspectbreakdrum']:checked").val() === "1" ? "1" : "0",
            "inspectBreakHoses": $("input[name='inspectbreakhoses']:checked").val() === "1" ? "1" : "0",
            "inspectBreakTubing": $("input[name='inspectbreaktubing']:checked").val() === "1" ? "1" : "0",
            "inspectAirCompressor": $("input[name='inspectaircompressor']:checked").val() === "1" ? "1" : "0",
            "inspectABSSystem": $("input[name='inspectabssystem']:checked").val() === "1" ? "1" : "0",
            "inspectSlackaAjuster": $("input[name='inspectslackadjuster']:checked").val() === "1" ? "1" : "0",
            "breakLF" : $('#breaklininglf').val(),
            "breakRF" : $('#breakliningrf').val(),
            "breakLFR" : $('#breaklininglfr').val(),
            "breakRFR" : $('#breakliningrfr').val(),
            "breakLRR" : $('#breaklininglrr').val(),
            "breakRRR" : $('#breakliningrrr').val(),
          },
          "engine":
          {
            "inspectHoses": $("input[name='inspecthoses']:checked").val() === "1" ? "1" : "0",
            "inspectBelt": $("input[name='inspectbelt']:checked").val() === "1" ? "1" : "0",
          },
          "fuelSystem":
          {
            "inspectLeaks": $("input[name='inspectleaks']:checked").val() === "1" ? "1" : "0",
          },
          "exhaustSystem":
          {
            "inspectExhaustLeaks": $("input[name='inspectexhaustleaks']:checked").val() === "1" ? "1" : "0",
            "inspectNoBurning": $("input[name='inspectnoburning']:checked").val() === "1" ? "1" : "0",
          },
          "chassis":
          {
            "inspectSteering": $("input[name='inspectsteering']:checked").val() === "1" ? "1" : "0",
              "inspectSteeringWheel": $("input[name='inspectsteeringwheel']:checked").val() === "1" ? "1" : "0",
              "inspectEngineAirFilters": $("input[name='inspectengineairfilter']:checked").val() === "1" ? "1" : "0",
              "inspectFreshAirFilter": $("input[name='inspectfreshairfilter']:checked").val() === "1" ? "1" : 
              "0",
              "lubeAllPoints": $("input[name='lubeallpoints']:checked").val() === "1" ? "1" : 
              "0",
              "lube5thWheel": $("input[name='lube5thwheel']:checked").val() === "1" ? "1" : "0",
              "inspectSteeringColumn": $("input[name='inspectsteeringcolumn']:checked").val() === "1" ? "1" : "0",
              "inspectSteeringSystem": $("input[name='inspectsteeringsystem']:checked").val() === "1" ? "1" : "0",
              "inspectHubWheelSeals": $("input[name='inspectwheelseals']:checked").val() === "1" ? "1" : "0",
              "inspectTransmissionLube": $("input[name='inspecttransmissionlube']:checked").val() === "1" ? "1" : "0",
              "inspectShaftJoints": $("input[name='inspectjoints']:checked").val() === "1" ? "1" : "0",
              "inspectDifferentHousingLube": $("input[name='inspecthousinglube']:checked").val() === "1" ? "1" : "0",
              "inspectAllSealsForLeaks": $("input[name='inspectallseals']:checked").val() === "1" ? "1" : "0",
          },
          "suspension":
          {
            "inspectFrame": $("input[name='inspectframe']:checked").val() === "1" ? "1" : "0",
            "inspectSpringHangers": $("input[name='inspectspringhangers']:checked").val() === "1" ? "1" : 
            "0",
            "inspectSpringAssembly": $("input[name='inspectspringassembly']:checked").val() === "1" ? "1" : "0",
            "inspectTorque": $("input[name='inspecttorque']:checked").val() === "1" ? "1" : "0",
          },
          "frame":
          {
            "inspectFrameMembers": $("input[name='inspectframemembers']:checked").val() === "1" ? "1" : "0",
            "inspectTireClearance": $("input[name='inspecttireclearance']:checked").val() === "1" ? "1" : 
            "0",
          },
          "couplingDevices":
          {
            "inspectFifthWheel": $("input[name='inspectfifthwheel']:checked").val() === "1" ? "1" : "0",
            "inspectPintleHook": $("input[name='inspectpintlehook']:checked").val() === "1" ? "1" : 
            "0",
            "inspectSafetyDevices": $("input[name='inspectsafetydevices']:checked").val() === "1" ? "1" : 
            "0",
            "inspectSaddleMounts": $("input[name='inspectsaddlemounts']:checked").val() === "1" ? "1" : 
            "0",
          },
          "tiresOrWheels":
          {
            "inspectWheelsforCracks": $("input[name='inspectwheelsforcracks']:checked").val() === "1" ? "1" : "0",
            "inspectBrokenLug": $("input[name='inspectbrokenlug']:checked").val() === "1" ? "1" : 
            "0",
            "inspectValveStems": $("input[name='inspectvalvestems']:checked").val() === "1" ? "1" : 
            "0",
            "checkTirePressure": $("input[name='checktirepressure']:checked").val() === "1" ? "1" : 
            "0",
            "inspectSteeringAxle": $("input[name='inspectsteeringaxle']:checked").val() === "1" ? "1" : 
            "0",
            "inspectTires": $("input[name='inspecttires']:checked").val() === "1" ? "1" : 
            "0",
            "inspectTreadDepths": $("input[name='inspecttreaddepths']:checked").val() === "1" ? "1" : 
            "0",
            "treadDepthRF" : $('#treaddepthrf').val(),
            "treadDepthRFPSI" : $('#treaddepthrfpsi').val(),
            "treadDepthLF" : $('#treaddepthlf').val(),
            "treadDepthLFPSI" : $('#treaddepthlfpsi').val(),

            "treadDepthRFR" : $('#treaddepthrfr').val(),
            "treadDepthRFRPSI" : $('#treaddepthrfrpsi').val(),
            "treadDepthLFR" : $('#treaddepthlfr').val(),
            "treadDepthLFRPSI" : $('#treaddepthlfrpsi').val(),

            "treadDepthRRR" : $('#treaddepthrrr').val(),
            "treadDepthRRRPSI" : $('#treaddepthrrrpsi').val(),
            "treadDepthLRR" : $('#treaddepthlrr').val(),
            "treadDepthLRRPSI" : $('#treaddepthlrrpsi').val(),

          },
          "electricalSystem":
          {
            "inspectBatteryBox": $("input[name='inspectbatterybox']:checked").val() === "1" ? "1" : "0",
            "inspectBatteryConnections": $("input[name='inspectbatteryconnections']:checked").val() === "1" ? "1" : 
            "0",
          },   
          "service":
          {
            "changeOil": $("input[name='changeoil']:checked").val() === "1" ? "1" : 
              "0",
              "changeOilFilter": $("input[name='changeoilfilter']:checked").val() === "1" ? "1" : 
              "0",
              "changeFuelFilter": $("input[name='changefuelfilter']:checked").val() === "1" ? "1" : 
              "0",
              "checkAllFluidLevels": $("input[name='checkfluidlevels']:checked").val() === "1" ? "1" : 
              "0",
          },   
          "overall":
          {
            "inspectAllPlugs": $("input[name='inspectallplugs']:checked").val() === "1" ? "1" : "0",
            "changeServiceNumber": $("input[name='changeservicenumber']:checked").val() === "1" ? "1" : 
            "0",
          },
          "notes":$("#tractorpmNotes").val() != '' ? $("#tractorpmNotes").val() : '',                                                                                
        },
        "documentList": documentList

      };

      $("#loadingforsubmit").show();
      // setLoading(true);
      //   const requestOptions = 
      //   {
      //      method: 'POST',
      //      headers: { 'Content-Type': 'application/json'
      //               },

      //         body: JSON.stringify(requestObject)
      //   };
      var requestOptions = requestObject.RequestHeader(requestObjectForInsp);
      services.addInspection(requestOptions).then((res) => {
        // setLoading(false);
        $("#loadingforsubmit").hide();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: "",
          text: res.message,
          showConfirmButton: true

        }).then((result) => {

          if (result.isConfirmed) {
            navigate("/vehicleDetails/" + vid);

          }
        });
      });

    } else {
      //alert(handleSubmit());
      if (handleSubmit() == true) {



        if ($("#inspectedBy").val() === "") {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Please enter inspectedBy",
            showConfirmButton: true

          }).then((result) => {

            if (result.isConfirmed) {


            }
          });
          return false;
        }

        var requestObjectForInsp =
        {

          "type": sessionStorage.getItem("selectedinspectionType"),
          "inspectedBy": $("#inspectedBy").val(),
          "inspectedDate": $("#inspectedDate").val(),
          "verifiedBy": $("#verifiedBy").val(),
          "verifiedDate": $("#verifiedBy").val(),
          "workOrderNumber": $("#workOrderNumber").val() != '' ? $("#workOrderNumber").val() : '',
          "notes": $("#notes").val() != '' ? $("#notes").val() : '',
          "status": status,
          "taskOrderId":taskOrderId,
          "inspectionType":
          {
            "id": sessionStorage.getItem("selectedinspectionId"),
            "name": sessionStorage.getItem("selectedinspectionType")
          },
          "result":
          {
            "approvedCertify": "0",
            "inspectionFailed": "0"
          },
          "dispositionOfVehicle":
          {
            "vehicleReturnedServices": "0",
            "vehicleWithdrawnFromService": "0",
            "vehicleSentToAnotherRepairFacility": "0"
          },
          "vehicleDetails":
          {
            "vinNumber": $("#vin").html(),
            "lisencePlateNumber": $("#lisencePlateNumber").html(),
            "id": vid,
            "unitNumber": $("#lisencePlateNumber").html()
          },
          "tractorPmInspection":
          {
            "tpmt_date" : $('#tpmt_date').val(),
            "tpmt_customer" : $('#tpmt_customer').val(),
            "tpmt_unit" : $('#tpmt_unit').val(),
            "tpmt_mileage" : $('#tpmt_mileage').val(),
            "tpmt_invoice" : $('#tpmt_invoice').val(),
            "lighting":
            {
              "allLightingDevices": $("input[name='lightingdevices']:checked").val() === "1" ? "1" : "0",
            },
            "windShield":
            {
              "inspectWindShield": $("input[name='inspectwindshield']:checked").val() === "1" ? "1" : "0",
              "inspectWiper": $("input[name='inspectwiper']:checked").val() === "1" ? "1" : "0",
              "inspectWasherFluid": $("input[name='washerfluid']:checked").val() === "1" ? "1" : "0",
            },
            "generalInspection":
            {
              "inspectBumpers": $("input[name='inspectbumper']:checked").val() === "1" ? "1" : "0",
              "inspectSteps": $("input[name='inspectsteps']:checked").val() === "1" ? "1" : "0",
              "inspectMirrors": $("input[name='inspectmirrors']:checked").val() === "1" ? "1" : "0",
              "inspectFuelTank": $("input[name='inspectfueltank']:checked").val() === "1" ? "1" : "0",
            },
            "inCab":
            {
              "inspectEmergencyEquip": $("input[name='inspectemergencyequip']:checked").val() === "1" ? "1" : "0",
              "inspectWarnLight": $("input[name='inspectwarnlight']:checked").val() === "1" ? "1" : "0",
              "inspectABSLight": $("input[name='inspectabslight']:checked").val() === "1" ? "1" : "0",
              "inspectHorns": $("input[name='inspecthorns']:checked").val() === "1" ? "1" : "0",
            },
            "breakSystem":
            {
              "measureBreakLining": $("input[name='measurebreaklining']:checked").val() === "1" ? "1" : "0",
              "inspectBreaks": $("input[name='inspectbreaks']:checked").val() === "1" ? "1" : "0",
              "inspectParkingBreak": $("input[name='inspectparkingbreak']:checked").val() === "1" ? "1" : "0",
              "inspectBreakDrum": $("input[name='inspectbreakdrum']:checked").val() === "1" ? "1" : "0",
              "inspectBreakHoses": $("input[name='inspectbreakhoses']:checked").val() === "1" ? "1" : "0",
              "inspectBreakTubing": $("input[name='inspectbreaktubing']:checked").val() === "1" ? "1" : "0",
              "inspectAirCompressor": $("input[name='inspectaircompressor']:checked").val() === "1" ? "1" : "0",
              "inspectABSSystem": $("input[name='inspectabssystem']:checked").val() === "1" ? "1" : "0",
              "inspectSlackaAjuster": $("input[name='inspectslackadjuster']:checked").val() === "1" ? "1" : "0",
              "breakLF" : $('#breaklininglf').val(),
              "breakRF" : $('#breakliningrf').val(),
              "breakLFR" : $('#breaklininglfr').val(),
              "breakRFR" : $('#breakliningrfr').val(),
              "breakLRR" : $('#breaklininglrr').val(),
              "breakRRR" : $('#breakliningrrr').val(),
            },
            "engine":
            {
              "inspectHoses": $("input[name='inspecthoses']:checked").val() === "1" ? "1" : "0",
              "inspectBelt": $("input[name='inspectbelt']:checked").val() === "1" ? "1" : "0",
            },
            "fuelSystem":
            {
              "inspectLeaks": $("input[name='inspectleaks']:checked").val() === "1" ? "1" : "0",
            },
            "exhaustSystem":
            {
              "inspectExhaustLeaks": $("input[name='inspectexhaustleaks']:checked").val() === "1" ? "1" : "0",
              "inspectNoBurning": $("input[name='inspectnoburning']:checked").val() === "1" ? "1" : "0",
            },
            "chassis":
            {
              "inspectSteering": $("input[name='inspectsteering']:checked").val() === "1" ? "1" : "0",
              "inspectSteeringWheel": $("input[name='inspectsteeringwheel']:checked").val() === "1" ? "1" : "0",
              "inspectEngineAirFilters": $("input[name='inspectengineairfilter']:checked").val() === "1" ? "1" : "0",
              "inspectFreshAirFilter": $("input[name='inspectfreshairfilter']:checked").val() === "1" ? "1" : 
              "0",
              "lubeAllPoints": $("input[name='lubeallpoints']:checked").val() === "1" ? "1" : 
              "0",
              "lube5thWheel": $("input[name='lube5thwheel']:checked").val() === "1" ? "1" : "0",
              "inspectSteeringColumn": $("input[name='inspectsteeringcolumn']:checked").val() === "1" ? "1" : "0",
              "inspectSteeringSystem": $("input[name='inspectsteeringsystem']:checked").val() === "1" ? "1" : "0",
              "inspectHubWheelSeals": $("input[name='inspectwheelseals']:checked").val() === "1" ? "1" : "0",
              "inspectTransmissionLube": $("input[name='inspecttransmissionlube']:checked").val() === "1" ? "1" : "0",
              "inspectShaftJoints": $("input[name='inspectjoints']:checked").val() === "1" ? "1" : "0",
              "inspectDifferentHousingLube": $("input[name='inspecthousinglube']:checked").val() === "1" ? "1" : "0",
              "inspectAllSealsForLeaks": $("input[name='inspectallseals']:checked").val() === "1" ? "1" : "0",
            },
            "suspension":
            {
              "inspectFrame": $("input[name='inspectframe']:checked").val() === "1" ? "1" : "0",
              "inspectSpringHangers": $("input[name='inspectspringhangers']:checked").val() === "1" ? "1" : 
              "0",
              "inspectSpringAssembly": $("input[name='inspectspringassembly']:checked").val() === "1" ? "1" : "0",
              "inspectTorque": $("input[name='inspecttorque']:checked").val() === "1" ? "1" : "0",
            },
            "frame":
            {
              "inspectFrameMembers": $("input[name='inspectframemembers']:checked").val() === "1" ? "1" : "0",
              "inspectTireClearance": $("input[name='inspecttireclearance']:checked").val() === "1" ? "1" : 
              "0",
            },
            "couplingDevices":
            {
              "inspectFifthWheel": $("input[name='inspectfifthwheel']:checked").val() === "1" ? "1" : "0",
              "inspectPintleHook": $("input[name='inspectpintlehook']:checked").val() === "1" ? "1" : 
              "0",
              "inspectSafetyDevices": $("input[name='inspectsafetydevices']:checked").val() === "1" ? "1" : 
              "0",
              "inspectSaddleMounts": $("input[name='inspectsaddlemounts']:checked").val() === "1" ? "1" : 
              "0",
            },
            "tiresOrWheels":
            {
              "inspectWheelsforCracks": $("input[name='inspectwheelsforcracks']:checked").val() === "1" ? "1" : "0",
              "inspectBrokenLug": $("input[name='inspectbrokenlug']:checked").val() === "1" ? "1" : 
              "0",
              "inspectValveStems": $("input[name='inspectvalvestems']:checked").val() === "1" ? "1" : 
              "0",
              "checkTirePressure": $("input[name='checktirepressure']:checked").val() === "1" ? "1" : 
              "0",
              "inspectSteeringAxle": $("input[name='inspectsteeringaxle']:checked").val() === "1" ? "1" : 
              "0",
              "inspectTires": $("input[name='inspecttires']:checked").val() === "1" ? "1" : 
              "0",
              "inspectTreadDepths": $("input[name='inspecttreaddepths']:checked").val() === "1" ? "1" : 
              "0",
              "treadDepthRF" : $('#treaddepthrf').val(),
              "treadDepthRFPSI" : $('#treaddepthrfpsi').val(),
              "treadDepthLF" : $('#treaddepthlf').val(),
              "treadDepthLFPSI" : $('#treaddepthlfpsi').val(),
  
              "treadDepthRFR" : $('#treaddepthrfr').val(),
              "treadDepthRFRPSI" : $('#treaddepthrfrpsi').val(),
              "treadDepthLFR" : $('#treaddepthlfr').val(),
              "treadDepthLFRPSI" : $('#treaddepthlfrpsi').val(),
  
              "treadDepthRRR" : $('#treaddepthrrr').val(),
              "treadDepthRRRPSI" : $('#treaddepthrrrpsi').val(),
              "treadDepthLRR" : $('#treaddepthlrr').val(),
              "treadDepthLRRPSI" : $('#treaddepthlrrpsi').val(),
  
            },
            "electricalSystem":
            {
              "inspectBatteryBox": $("input[name='inspectbatterybox']:checked").val() === "1" ? "1" : "0",
              "inspectBatteryConnections": $("input[name='inspectbatteryconnections']:checked").val() === "1" ? "1" : 
              "0",
            },   
            "service":
            {
              "changeOil": $("input[name='changeoil']:checked").val() === "1" ? "1" : 
              "0",
              "changeOilFilter": $("input[name='changeoilfilter']:checked").val() === "1" ? "1" : 
              "0",
              "changeFuelFilter": $("input[name='changefuelfilter']:checked").val() === "1" ? "1" : 
              "0",
              "checkAllFluidLevels": $("input[name='checkfluidlevels']:checked").val() === "1" ? "1" : 
              "0",
            },   
            "overall":
            {
              "inspectAllPlugs": $("input[name='inspectallplugs']:checked").val() === "1" ? "1" : "0",
              "changeServiceNumber": $("input[name='changeservicenumber']:checked").val() === "1" ? "1" : 
              "0",
            },  
            "notes":$("#tractorpmNotes").val() != '' ? $("#tractorpmNotes").val() : '',
          },
          "documentList": documentList

        };

        console.log(requestObjectForInsp);
        //return false;

        $("#loadingforsubmit").show();
        // setLoading(true);
        //   const requestOptions = 
        //   {
        //      method: 'POST',
        //      headers: { 'Content-Type': 'application/json'
        //               },

        //         body: JSON.stringify(requestObject)
        //   };
        var requestOptions = requestObject.RequestHeader(requestObjectForInsp);
        services.addInspection(requestOptions).then((res) => {
          // setLoading(false);
          $("#loadingforsubmit").hide();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: "",
            text: res.message,
            showConfirmButton: true

          }).then((result) => {

            if (result.isConfirmed) {
              navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

            }
          });
        });
      }
    }


  }

  const resetAllValues = () => {
    setFromDate('');
  }

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, {}]);
  };
  const fileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(null, reader.result)
    }
    reader.onerror = function (error) {
      cb(error, null)
    }
  }
  const onUploadFileChange = (event, index) => {


    var target = event.target;
    if (target.files < 1 || !target.validity.valid) {
      return
    }
    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        const { name, value } = event.target;
        const list = [...inputList];
        list[index][name] = result;
        setBase64file(result);
        // setFile(result)
        setfileName(target.files[0]);
        // setFileName(target.files[0])
      }
    })
  }

  const measurelining = () => {
    var measurebreaklining = $("input[name='measurebreaklining']:checked").val();
    if(measurebreaklining == undefined){
      $('#breaklininglf').val('');
      $('#breakliningrf').val('');
      $('#breaklininglfr').val('');
      $('#breakliningrfr').val('');
      $('#breaklininglrr').val('');
      $('#breakliningrrr').val('');
      $('#break_measures').hide();
    }
    else{
      $('#break_measures').show();
    }
  }

  const treaddepths = () => {
    var inspecttreaddepths = $("input[name='inspecttreaddepths']:checked").val();
    if(inspecttreaddepths == undefined){
      $('#treaddepthrf').val('');
      $('#treaddepthrfpsi').val('');
      $('#treaddepthlf').val('');
      $('#treaddepthlfpsi').val('');
      $('#treaddepthrfr').val('');
      $('#treaddepthrfrpsi').val('');
      $('#treaddepthlfr').val('');
      $('#treaddepthlfrpsi').val('');
      $('#treaddepthrrr').val('');
      $('#treaddepthrrrpsi').val('');
      $('#treaddepthlrr').val('');
      $('#treaddepthlrrpsi').val('');
      $('#threaddepth_measures').hide();
    }
    else {
      $('#threaddepth_measures').show();
    }
  }
  // const checkMethod = () => {
  //   var checkValue = $("input[name='MODIFIEDMETHOD27']:checked").val();
  //   if (checkValue == '1') {
  //     $("#testPressure").val("METHOD 27");
  //     $("#testPressure").prop('readonly', true);
  //   } else {
  //     $("#testPressure").prop('readonly', false);
  //     $("#testPressure").val("");
      
  //   }
  // }

  return (
    <>
    {LoadingComponentForSubmit}
      <div class="row">
                <div class="col-lg-12 col-md-12 m-auto ">
                    <div class="card">
                        <div class="row">
                            <div class="w-100 px-3">
                                <h4 class="mb-2 form-inspection-title text-center">Tractor Preventive Maintenance/Inspection</h4>
                              <div>
                              <p id="tractorpm_validation" class="error">*Please select all mandate inspections</p>
                              {/*<p id="unitDesignPressure-message" class="error"></p> 
                              <p id="testPressure-message" class="error"></p>
                              <p id="error_message" class="center"></p> */}
                              </div> 
                            </div>
                            <div class="col-lg-12 mx-0"><WorkOrderNumberDiv /></div>
                            
                            <div class="px-3">
                                <div class="inspection-wrap">
                                  <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Lighting</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="lightingdevices" value="1" class="mr-2" />
                                                <span>All lighting devices and reflectors shall be operable  <span class="star">*</span></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Windshield</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex">
                                                <input type="checkbox" name="inspectwindshield" value="1" class="mr-2" style={{minHeight:20, maxHeight:20,}}/>
                                                <span>Inspect windshield (no crack, discoloration or vision reducing matter allowed) <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectwiper" value="1" class="mr-2" style={{minHeight:"20px"}} />
                                                <span>Inspect wipers, replace as needed <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="washerfluid" value="1" class="mr-2" style={{minHeight:"20px"}} />
                                                <span>Check washer fluid level</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>General Inspection</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex">
                                                <input type="checkbox" name="inspectbumper" value="1" class="mr-2" style={{minHeight:20, maxHeight:20,}}/>
                                                <span>Inspect grille, bumpers, doors and door glass</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectsteps" value="1" class="mr-2" style={{minHeight:"20px"}} />
                                                <span>Inspect steps</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectmirrors" value="1" class="mr-2" style={{minHeight:"20px"}} />
                                                <span>Inspect mirrors and brackets</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectfueltank" value="1" class="mr-2" style={{minHeight:"20px"}} />
                                                <span>Inspect fuel tank</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>In-cab</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectemergencyequip" value="1" class="mr-2" />
                                                <span>Inspect all emergency equipment <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectwarnlight" value="1" class="mr-2" />
                                                <span>Inspect warn lights/buzzer <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectabslight" value="1" class="mr-2" />
                                                <span>Inspect ABS dash light self-test <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspecthorns" value="1" class="mr-2" />
                                                <span>Inspect all horns <span class="star">*</span></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Brake System</h5>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectbreaks" value="1" class="mr-2" />
                                                <span>Inspect/Service brakes <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectparkingbreak" value="1" class="mr-2" />
                                                <span>Inspect Parking brake system <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectbreakdrum" value="1" class="mr-2" />
                                                <span>Inspect brake drums/rotors <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectbreakhoses" value="1" class="mr-2" />
                                                <span>Inspect brake hoses <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectbreaktubing" value="1" class="mr-2" />
                                                <span>Inspect brake tubing <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectaircompressor" value="1" class="mr-2" />
                                                <span>Inspect air compressor <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectabssystem" value="1" class="mr-2" />
                                                <span>Inspect ABS system <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectslackadjuster" value="1" class="mr-2" />
                                                <span>Inspect slack adjuster operation <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="measurebreaklining" value="1" class="mr-2" onClick={measurelining}/>
                                                <span>Measure and record brake lining</span>
                                            </div>
                                        </div>
                                        <div class="table-3 mb-4 px-3" id="break_measures">
                                        <table class="table rt-border-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ borderRight: "1px solid #ccc" }}>Break Lining Measurements</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr class="border-bottom">
                                                    <td>
                                                        <div class="row align-items-center justify-content-between px-2">
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">LF</span>
                                                                    <div>
                                                                    <input type="input" class="form-control" id="breaklininglf" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <div id="breaklininglfValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                </div>
                                                                <span style={{margin:"10px 10px"}}>%</span>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">RF</span>
                                                                    <div>
                                                                    <input type="input" class="form-control" id="breakliningrf" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <div id="breakliningrfValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                  </div>
                                                                  <span style={{margin:"10px 10px"}}>%</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">LFR</span>
                                                                    <div>
                                                                    <input type="input" class="form-control" id="breaklininglfr" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <div id="breaklininglfrValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                  </div>
                                                                  <span style={{margin:"10px 10px"}}>%</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">RFR</span>
                                                                    <div>
                                                                    <input type="input" class="form-control" id="breakliningrfr" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    
                                                                    <div id="breakliningrfrValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>%</span>
                                                                </div>

                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">LRR</span>
                                                                  <div>
                                                                    <input type="input" class="form-control" id="breaklininglrr" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <div id="breaklininglrrValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>%</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60" >RRR</span>
                                                                    <div>
                                                                    <input type="input" class="form-control" id="breakliningrrr" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <div id="breakliningrrrValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>%</span>
                                                                    
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>


                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Engine</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspecthoses" value="1" class="mr-2" />
                                                <span>Inspect hoses/clamps</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectbelt" value="1" class="mr-2" />
                                                <span>Inspect belts</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Fuel System</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectleaks" value="1" class="mr-2" />
                                                <span>Inspect for leaks <span class="star">*</span></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Exhaust System</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-start">
                                                <input type="checkbox" name="inspectexhaustleaks" value="1" class="mr-2" style={{minHeight:20, maxHeight:20,}}/>
                                                <span>Inspect exhaust for leaks (no leak allowed below driver compartment) <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-8 mb-2">
                                            <div class="d-flex align-items-start">
                                                <input type="checkbox" name="inspectnoburning" value="1" class="mr-2" style={{minHeight:20, maxHeight:20,}}/>
                                                <span>Inspect to ensure no part of system located to cause burning, charring or damage to wiring, fuel supply or any combustible part of vehicle <span class="star">*</span></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Chassis</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectsteering" value="1" class="mr-2" />
                                                <span>Inspect steering mechanism <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectsteeringwheel" value="1" class="mr-2" />
                                                <span>Inspect steering wheel free play <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectengineairfilter" value="1" class="mr-2" />
                                                <span>Inspect engine air filters</span>
                                            </div>
                                        </div><div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectfreshairfilter" value="1" class="mr-2" />
                                                <span>Inspect fresh air filters</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="lubeallpoints" value="1" class="mr-2" />
                                                <span>Lube all points</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="lube5thwheel" value="1" class="mr-2" />
                                                <span>Lube 5th wheel</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectsteeringcolumn" value="1" class="mr-2" />
                                                <span>Inspect steering column <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectsteeringsystem" value="1" class="mr-2" />
                                                <span>Inspect steering system <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectwheelseals" value="1" class="mr-2" />
                                                <span>Inspect hub and wheel seals</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspecttransmissionlube" value="1" class="mr-2" />
                                                <span>Inspect transmission lube</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectjoints" value="1" class="mr-2" />
                                                <span>Inspect shaft & joints</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspecthousinglube" value="1" class="mr-2" />
                                                <span>Inspect differential housing and lube</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectallseals" value="1" class="mr-2" />
                                                <span>Inspect all seals for leaks</span>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Suspension</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectframe" value="1" class="mr-2" />
                                                <span>Inspect frame cross members <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectspringhangers" value="1" class="mr-2" />
                                                <span>Inspect spring hangers</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectspringassembly" value="1" class="mr-2" />
                                                <span>Inspect spring assembly <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-start">
                                                <input type="checkbox" name="inspecttorque" value="1" class="mr-2" style={{minHeight:20, maxHeight:20,}}/>
                                                <span>Inspect torque, radius or tracking components <span class="star">*</span></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Frame</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectframemembers" value="1" class="mr-2" />
                                                <span>Inspect frame members <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspecttireclearance" value="1" class="mr-2" />
                                                <span>Inspect tire and wheel clearance <span class="star">*</span></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Coupling Devices</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectfifthwheel" value="1" class="mr-2" />
                                                <span>Inspect fifth wheel <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectpintlehook" value="1" class="mr-2" />
                                                <span>Inspect pintle hook <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectsafetydevices" value="1" class="mr-2" />
                                                <span>Inspect safety devices <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectsaddlemounts" value="1" class="mr-2" />
                                                <span>Inspect saddle mounts <span class="star">*</span></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-2">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Tires/Wheels</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectwheelsforcracks" value="1" class="mr-2" />
                                                <span>Inspect wheels for cracks/welds <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectbrokenlug" value="1" class="mr-2" />
                                                <span>Inspect for loose/broken lug <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectvalvestems" value="1" class="mr-2" />
                                                <span>Inspect valve stems/caps <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex ">
                                                <input type="checkbox" name="checktirepressure" value="1" class="mr-2" style={{minHeight:20, maxHeight:20,}} />
                                                <span>Check tire pressure<br/>-wide base tires @100PSI<br/>-steers @105PSI<br/>-duals @110PSI</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex">
                                                <input type="checkbox" name="inspectsteeringaxle" value="1" class="mr-2" style={{minHeight:20, maxHeight:20,}}/>
                                                <span>Inspect tires on any steering axle of power unit <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspecttires" value="1" class="mr-2" style={{minHeight:"20px"}} />
                                                <span>Inspect all tires <span class="star">*</span></span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspecttreaddepths" value="1" class="mr-2" onClick={treaddepths}/>
                                                <span>Measure all tread depths</span>
                                            </div>
                                        </div>
                                        <div class="table-3 mb-4 px-3" id="threaddepth_measures">
                                        <table class="table rt-border-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ borderRight: "1px solid #ccc" }}>Thread Depths Measurements</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr class="border-bottom">
                                                    <td>
                                                        <div class="row align-items-center justify-content-between px-3">
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                         <span class="mr-3 mm-60">RF</span>
                                                                        <div>
                                                                        <input type="input" class="form-control" id="treaddepthrf" aria-describedby="emailHelp" style={{ width: "100px" }} />

                                                                          <div id="treaddepthrfValidMsg" class="error">Required Field</div>
                                                                        </div>
                                                                    </div>
                                                                    <span style={{margin:"10px 10px"}}>/32</span>
                                                                    
                                                                    <div>
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                      <input type="input" class="form-control" id="treaddepthrfpsi" aria-describedby="emailHelp" style={{ width: "100px" }} /><span style={{margin:"10px 10px"}}>PSI</span>
                                                                      </div>
                                                                    <div id="treaddepthrfpsiValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                   
                                                                   
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">LF</span>
                                                                    <div>
                                                                      <input type="input" class="form-control" id="treaddepthlf" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                      <div id="treaddepthlfValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>/32</span>
                                                                  <div>
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                      <input type="input" class="form-control" id="treaddepthlfpsi" aria-describedby="emailHelp" style={{ width: "100px" }} /><span style={{margin:"10px 10px"}}>PSI</span>
                                                                    </div>
                                                                    <div id="treaddepthlfpsiValidMsg" class="error">Required Field</div>
                                                                  </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">RFR</span>
                                                                    <div>
                                                                    <input type="input" class="form-control" id="treaddepthrfr" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <div id="treaddepthrfrValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                </div>
                                                                    <span style={{margin:"10px 10px"}}>/32</span>
                                                                <div>
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                  
                                                                      <input type="input" class="form-control" id="treaddepthrfrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} /><span style={{margin:"10px 10px"}}>PSI</span>
                                                                    </div>
                                                                      <div id="treaddepthrfrpsiValidMsg" class="error">Required Field</div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">LFR</span>
                                                                    <div>
                                                                    <input type="input" class="form-control" id="treaddepthlfr" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    
                                                                    <div id="treaddepthlfrValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>/32</span>
                                                                  <div>
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                    <input type="input" class="form-control" id="treaddepthlfrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} /><span style={{margin:"10px 10px"}}>PSI</span>
                                                                    </div>
                                                                    <div id="treaddepthlfrpsiValidMsg" class="error">Required Field</div>
                                                                  </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div class="col-md-6 col-sm-12 mb-3">
                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">RRR</span>
                                                                    <div>
                                                                    <input type="input" class="form-control" id="treaddepthrrr" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    
                                                                    <div id="treaddepthrrrValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>/32</span>
                                                                  <div>
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                      <input type="input" class="form-control" id="treaddepthrrrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} /><span style={{margin:"10px 10px"}}>PSI</span>
                                                                    </div>
                                                                    <div id="treaddepthrrrpsiValidMsg" class="error">Required Field</div>
                                                                  </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 mb-3">

                                                                <div class="d-flex align-items-center">
                                                                  <div className="d-flex justify-content-start align-items-center">
                                                                    <span class="mr-3 mm-60">LRR</span>
                                                                    <div>
                                                                    <input type="input" class="form-control" id="treaddepthlrr" aria-describedby="emailHelp" style={{ width: "100px" }} />
                                                                    <div id="treaddepthlrrValidMsg" class="error">Required Field</div>
                                                                    </div>
                                                                  </div>
                                                                    <span style={{margin:"10px 10px"}}>/32</span>
                                                                  <div>
                                                                    <div className="d-flex justify-content-start align-items-center">
                                                                      <input type="input" class="form-control" id="treaddepthlrrpsi" aria-describedby="emailHelp" style={{ width: "100px" }} /><span style={{margin:"10px 10px"}}>PSI</span>
                                                                    </div>
                                                                    <div id="treaddepthlrrpsiValidMsg" class="error">Required Field</div>
                                                                  </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Electrical System</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectbatterybox" value="1" class="mr-2" />
                                                <span>Inspect battery box hold-downs</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectbatteryconnections" value="1" class="mr-2" />
                                                <span>Inspect battery connections</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Service</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="changeoil" value="1" class="mr-2" />
                                                <span>Change oil</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="changeoilfilter" value="1" class="mr-2" />
                                                <span>Change oil filter</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="changefuelfilter" value="1" class="mr-2" />
                                                <span>Change fuel filters as needed</span>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="checkfluidlevels" value="1" class="mr-2" />
                                                <span>Check all fluid levels</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Overall</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="inspectallplugs" value="1" class="mr-2" />
                                                <span>Inspect all plugs for tightness</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                                <input type="checkbox" name="changeservicenumber" value="1" class="mr-2" />
                                                <span>Change service numbers</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Notes</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-8 mb-2">
                                            <div class="d-flex align-items-center">
                                            <textarea class="form-control my-3" name="tractorpmNotes" id="tractorpmNotes" rows="5" maxLength="300" style={{minHeight:"180px"}}></textarea>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="divider2"></div>
                        <div class="row py-3">
                            <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                        </div>

                        <AddInspectionName setEndDate={setEndDate} endDate={endDate} />

                        <div class="divider2"></div>
                        <div class="row p-3 pr-4 d-flex justify-content-end" style={{ marginBottom: "-20px" }}>
                            <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
                            <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a>
                            <a href="#" class="btn btn-primary mr-1" onClick={() => save("APPROVED PENDING")}>Save</a>
                        </div>
                    </div>
                </div>
            </div>

    </>
  )
};

TractorpmTest.propTypes = {};

TractorpmTest.defaultProps = {};

export default TractorpmTest;
