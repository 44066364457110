import services from "../../../services/services";
import { accessTokenTo } from "../../../http-common";
import { requestObject } from "../../../utility/requestObject";
import $ from "jquery";
const getcustomerLists=(pageNumber,ITEMS_PER_PAGE,customerName,pageSize,setTotalItems,settotalPages,setCurrentPage,setCustomerList,setTotalRecord,setdisplayPage,sortBy,sortDir,displayItems,activeStatus)=>
{
  
   if(sortBy==="Customer Name")
   {
    sortBy="customerName";
   }
   else if(sortBy==="Primary Contact")
   {
    sortBy="contactName";
   }
   else if(sortBy==="Phone")
   {
    sortBy="contactNumber";
   }
   else if(sortBy==="Email")
   {
    sortBy="contactEmail";

   }
    var customerListReq=
    {
  
      "customerName":customerName.trim(),
      "pageNumber":pageNumber,
      "vehicleTypeId":"",
      "pageSize":pageSize,
      "sortBy": sortBy,
      "sortDir":sortDir, 
      "isActive" :activeStatus, 
    };


  var requestOptions=requestObject.RequestHeader(customerListReq);


  services.listofcustomers(requestOptions).then((res) => 
  {
    $("#loading").hide();
   // setLoading(false);
   // setIsLoaded(currentIsLoaded => !currentIsLoaded);
    if(res.success)
    {
      setTotalItems(res.customValues.totalNumberOfRecords);
    
      settotalPages(res.customValues.totalNumberOfRecords);
     
      setCurrentPage(res.customValues.currentPageNumber);
      setCustomerList(res.rows);
      setTotalRecord(res.customValues.totalNumberOfRecords);
      var displaypages=[];
      if(displayItems==="diplayItems")
      {
        for(var i=1;i<=parseInt(Math.ceil(res.customValues.totalNumberOfRecords/ITEMS_PER_PAGE));i++)
        {
          displaypages.push(i*ITEMS_PER_PAGE);
        }
        
        setdisplayPage(displaypages);

      }


    }else{
    
      //alert(res.message);
      setCustomerList("");
      setdisplayPage([0]);
      setTotalItems(0);
    
      settotalPages("1");
     
      setCurrentPage("1");
      setCustomerList("");
      setTotalRecord(0);
    }
   
  })

}

export default getcustomerLists;