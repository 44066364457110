import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderBlock from "./VehicleHeaderBlock";
import services from "../../../services/services";
import $ from 'jquery';
import document_api from "../document/document_api";
import vehicle_details_api from "./vehicle_details_api";
import Search from "../../../utility/search";
import Pagination from "../../../utility/pagination";
import Swal from "sweetalert2";
import inspectionList from "../inspection/inspectionList";
import { requestObject } from "../../../utility/requestObject";
import { itemperPagetoDisplay, Manager, Owner } from "../../constant";
import * as ReactBootstrap from 'react-bootstrap';
import { loadingComponent } from "../../../utility/Loading";
import Checkbox from "../../../utility/Checkbox";
import { deleteDocuments, deleteInspectionDocuments, Sendtocustomer } from "../../../utility/sendtocustomer";
import { sendtocustomerCheck } from "../../../utility/sendtocustomer";
import ListOfContacts from "../customer/CustomerContactList";
import { CustomercontactId } from "../customer/CustomerContactList";
import { SelectAllContactList } from "../customer/CustomerContactList";
import { document_details } from "../../../utility/DocumentDetails";
import { document_print } from "../../../utility/DocumentPrint";
import moment from 'moment';
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
import AddRemoveInputField from "../../../utility/AddRemoveInputField";
import ToggleSwitch from "../ToggleSwitch";
import { LoadingComponentfordocuments } from "../../../utility/LoadingForDoc";

const VehicleDetails = () =>
{

   $("#loadingforsubmit").hide();
   const { id, vin } = useParams();
   var vid = id;
   const inlineCss =
   {
      textAlign: "left"
   }

   const inlineWidthAuto =
   {
      width: "auto"
   }

   const lineHeight = {
      //height: "34px",
      //lineHeight: "44px"

   }

   const psA = {
      //position: "absolute",
      //top: "-12px",
      borderBottom: "0px",
      width: "3%",
      textAlign : "center",

      
   }

   const typeStyle = {
      width : "40%",
      verticalAlign : "middle",
   }

   const titleStyle = {
      width : "30%",
      verticalAlign : "middle",
   }

   const dateStyle = {
      width : "12%",
      verticalAlign : "middle",
   }

   const acionStyle = {
      width : "3%",
      verticalAlign : "middle",
   }

   const [roles, setRoles] = useState("");
   let navigation = useNavigate();
   const [loading, setLoading] = useState(false);
   const [totalItems, setTotalItems] = useState(0);
   const [totalInspectionList, setTotalInspectionList] = useState(0);
   const [totalInspectionPages, settotalinspectionPages] = useState(0);
   const [inspectioCurrentPage, setInspectionCurrentPage] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const [docname, setDocName] = useState();
   const [totaldocspages, settotalDocsPages] = useState();
   const [totaldocsitems, settotalDocsItems] = useState();
   const [displayPage, setdisplayPage] = useState([]);
   const [displayPageForInspection, setdisplayPageForInspection] = useState([]);
   const [vechileDetails, setVehicleDetails] = useState([]);
   const [cid, setCustomerId] = useState([]);
   const [totalRecors, setTotalRecord] = useState([]);
   const [totalDocsRecors, setTotalDocsRecord] = useState([]);
   const [documentList, setDocumentList] = useState("");
   const [inspectionLists, setInspectionList] = useState("");
   const [ITEMS_PER_PAGE_DOCS, setitemperdocspage] = useState(25);
   const [ITEMS_PER_PAGE, setitemperdocs] = useState(25);
   const [inspectioname, setInspectionName] = useState("");
   const [sortingField, setSortingField] = useState("");
   const [sortingOrder, setSortingOrder] = useState("");
   const [inspectionTypes, setInspectionTypes] = useState();
   const [CustomerContactList, setCustomerContactList] = useState([]);
   const [checked, setChecked] = useState([]);
   const [vehicleId, setVehicleId] = useState();
   const [emailforsendtoct, setInputEmailList] = useState([{}]);
   const navigate = useNavigate();

   var hour_format=localStorage.getItem('time_format_os');
   const headers =
      [
         { name: <Checkbox from='global' />, field: "documentId", inlineStyle: psA },
         // { name: "Category", field: "Doc category", sortable: true, inlineStyle: inlineCss },
         { name: "Type", field: "documentSubCategoryName", sortable: true, inlineStyle: inlineCss, inlineStyle: typeStyle, },
         { name: "Title", field: "Title", sortable: true, inlineStyle: inlineCss, inlineStyle: titleStyle, },
         // { name: "Doc Date", field: "Document Date", sortable: true, inlineStyle: inlineCss, inlineStyle: inlineWidthAuto, inlineStyle:lineHeight },
         { name: "Expiry Date", field: "expiryDate", sortable: true, inlineStyle: inlineCss, inlineStyle: dateStyle, },
         { name: "Sent to Customer", field: "Unit Number", sortable: false, inlineStyle: inlineCss, inlineStyle : dateStyle, },
         // { name: "Created By", field: "Created By", sortable: true, inlineStyle: inlineCss },
         { name: "Actions", inlineStyle: acionStyle, }
      ];

   const Inspectionheaders =
      [
         { name: "Inspection", field: "Inspection Title", sortable: false, inlineStyle: inlineCss, inlineStyle: inlineWidthAuto },

         { name: "Inspected On", field: "Inspected On", sortable: false, inlineStyle: inlineCss, inlineStyle: inlineWidthAuto },
         { name: "Inspected By", field: "Inspected By", sortable: false, inlineStyle: inlineCss, inlineStyle: inlineWidthAuto },
         { name: "Status", field: "Status", sortable: false, inlineStyle: inlineCss, inlineStyle: inlineWidthAuto },
         { name: "Actions" }
      ];
   const onSortingChangeDocument = (field) => {

      const order =
         field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
      console.log("order===", field + sortingOrder);
      setSortingField(field);
      setSortingOrder(order);
      veh_docs(id, currentPage, ITEMS_PER_PAGE_DOCS, "", field, order, "");

   };

   var navigationfrom = sessionStorage.getItem("naviationfrom");
   const activeVehicle = (status) =>
   {
      setChecked(status)
      // var active = $("#activeClient").val();
      // if ($('#activeClient').prop('checked',true)) {
         var request =
         {

            "isActive": status,
            "id": id,


         };

      //let checkState = $("#activeClient").is(":checked") ? "true" : "false";
      Swal.fire({
         title: 'Do you really want to change the status of this vehicle?',
         // text: unitNumber+" : "+title,

         icon: 'error',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes',
         cancelButtonText: 'No'
     }).then(function (results)
     {
         // Called if you click Yes.
         if(results.isConfirmed)
         {

           // setLoading(true);
            $("#loadingforsubmit").show();
           var requestOptions = requestObject.RequestHeader(request);
            services.inActivateVehicle(requestOptions).then((res) =>
            {
               // setLoading(false);
               $("#loadingforsubmit").hide();
               if (res.success) {
                  Swal.fire({
                     position: 'center',
                     icon: 'success',
                     title: "",
                     text: res.message,

                     showConfirmButton: true

                  }).then((result) =>
                  {
                     if (result.isConfirmed)
                     {
                        // IfitIsCustomer();
                        navigate("/vehicles");
                     }
                  });

               } else {

                  Swal.fire({
                     position: 'center',
                     icon: 'error',
                     title: "",
                     text: res.message,

                     showConfirmButton: true

                  }).then((result) =>
                  {
                     if (result.isConfirmed)
                     {

                     }
                  });

               }

            });

         }
     },
     function (no) {
         // Called if you click No.
         if (no == 'cancel') {

         }
     });


   }
   const backbuttonHandle = () => {

      // if (navigationfrom === "customerDetails") {
      if (navigationfrom === "overview" || navigationfrom === "customerDetails") {
         navigation("/customerDetails/" + localStorage.getItem("customerId"))

      } else if (navigationfrom === "vehicles") {
         navigation("/vehicles")

      } else if (navigationfrom === "Customer-vehiclesList") {
         navigation("/customerDetails/" + localStorage.getItem("customerId"))

      } else if (navigationfrom === "upcoimgOverdue") {
         navigation("/upcomingOverdue")
      }
      else {
         navigation("/vehicles")
      }
   }

   const tabClicks = (tabName) => {

      if (tabName === "doc-tab") {
         veh_docs(id, "1", ITEMS_PER_PAGE_DOCS, "", sortingField, sortingOrder, "diplayItems");
         sessionStorage.setItem("naviationfrom", "doc-tab");

      } else if (tabName === "insp-tab") {
         sessionStorage.setItem("naviationfrom", "insp-tab");

      } else {
         sessionStorage.setItem("naviationfrom", "vd-tab");
      }
   }

   // const onActiveOrInactive=(checked)=>
   // {
   //    alert(checked)
   //    setChecked(checked);

   // }

   const redirect = (page) => {
      sessionStorage.removeItem("taskOrderfromInspection");
      sessionStorage.setItem("naviationfromVehicleDetails", "taskOrderAddTO");
      navigation(page);

   }
   $(document).on("click", "#editDocument", function () {
     sessionStorage.setItem("naviationfrom", "adddocuments");
    });

   useEffect(() => {
      $("#sendtocustomerDisabled").prop("disabled", true)
      $("#printDocuments").prop("disabled", true)
      $("#loadingfordocs").hide();
      sessionStorage.removeItem("workOrderNumber");
      sessionStorage.removeItem("verifiedBy");
      setRoles(localStorage.getItem("Userroles"));

      if (navigationfrom === "addinspection" || navigationfrom === "insp-tab") {
         document.getElementById("insp-tab").click();
         sessionStorage.removeItem("naviationfrom");
      } else if (navigationfrom === "adddocuments" || navigationfrom === "doc-tab") {
         document.getElementById("doc-tab").click();
         sessionStorage.removeItem("naviationfrom");
      }
      setVehicleId(vid);
      sessionStorage.removeItem("section1");
      sessionStorage.removeItem("section2");
      sessionStorage.removeItem("section3");
      sessionStorage.removeItem("section4");
      sessionStorage.removeItem("section5");
      sessionStorage.removeItem("section6");

      sessionStorage.removeItem("section1Break");
      sessionStorage.removeItem("section2Coupling");
      sessionStorage.removeItem("section3Fuel");
      sessionStorage.removeItem("section3Exhaust");
      sessionStorage.removeItem("section3SAFE");
      sessionStorage.removeItem("section3LIGHTING");
      sessionStorage.removeItem("section4FRAME");
      sessionStorage.removeItem("section4SUSPENSION");
      sessionStorage.removeItem("section4TIRES");

      getInspectionType();
      // document_api(cid,"1",ITEMS_PER_PAGE,"",ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setDocumentList,setTotalRecord,setdisplayPage,vin);
      //vehicle_Details(id);
      setInspectionName("");

      veh_docs(id, "1", ITEMS_PER_PAGE_DOCS, "", sortingField, sortingOrder, "diplayItems");

      vehicle_details_api(id, setVehicleDetails, setCustomerId, setLoading,setCustomerContactList);
      inspectionList(id, "1", ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, "");

   }, []);
   $('#display_pages_in_items').on('change', function () {
      setitemperdocs(this.value);
      inspectionList(id, "1", this.value, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, "");
   });
   const getInspectionType = () => {


      var vehicle_request =
      {

      };

      var requestOptions = requestObject.RequestHeader(vehicle_request);
      services.getInspectionTypeS(requestOptions).then((res) => {
         setInspectionTypes(res.rows);

      });
   }

   const searchDocsList = (docname) => {
      setDocName(docname);
      veh_docs(id, "1", ITEMS_PER_PAGE_DOCS, docname, sortingField, sortingOrder, "diplayItems");

   }


   const searchInspectionList = (inspectionType) => {
      setInspectionName(inspectionType);
      inspectionList(id, "1", ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, inspectionType);

   }
   /////////////////vehicle pagination////////////////////////////
   const handlePageChangeForInspection = (offset) => {


      inspectionList(id, offset, ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, inspectioname);

   }
   /////////////////vehicle pagination////////////////////////////
   const handlePageChange = (offset, triggerevent) => {


      veh_docs(id, offset, ITEMS_PER_PAGE_DOCS, docname, sortingField, sortingOrder, "");

   }
   ////////////inspection details////////////////////////////////////
   const inspection_details = (taskOrderId,inspectionTypeNname, inspectionStatus, fkAccountId, id) => {

      sessionStorage.setItem("naviationfrom", "addinspection");
      if (inspectionTypeNname.match("Cargo Tank Thickness Test")) {

         // if (inspectionStatus === "DRAFT") {
         //    navigation("/cargoTankThicknessTestEdit/" + fkAccountId + '/' + id + '/' + vid);
         // } else {
         //    navigation("/cargoTankThicknessTestRead/" + fkAccountId + '/' + id + '/' + vid);
         // }

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/cargoTankThicknessTestRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }else if(inspectionStatus=="DRAFT" || inspectionStatus=="draft"){

               return (
                  navigation("/cargoTankThicknessTestEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
               )
            }
         else if(roles.match("Technician")){
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
            return (
               navigation("/cargoTankThicknessTestRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }
         }else if(roles.match("Manager") ||roles.match("Supervisor")|| roles.match("Owner") || roles.match("Inspector")){
            if ( inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING' ) {
            return (
               navigation("/cargoTankThicknessTestEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
            }
         } else {
            return (
               navigation("/cargoTankThicknessTestEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
         }


      } else if (inspectionTypeNname.match("WET Test Certification")) {

         // if (inspectionStatus === "DRAFT") {
         //    navigation("/wetTestCertificationEdit/" + fkAccountId + '/' + id + '/' + vid);
         // } else {
         //    navigation("/wetTestCertificationRead/" + fkAccountId + '/' + id + '/' + vid);
         // }

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/wetTestCertificationRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }else if(inspectionStatus=="DRAFT" || inspectionStatus=="draft"){

               return (
                  navigation("/wetTestCertificationEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
               )
            }
         else if(roles.match("Technician")){
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
            return (
               navigation("/wetTestCertificationRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }
         }else if(roles.match("Manager")||roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")){
            if ( inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING' ) {
            return (
               navigation("/wetTestCertificationEdit/"+ taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
            }
         } else {
            return (
               navigation("/wetTestCertificationEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
         }

      } else if (inspectionTypeNname=="Tractor PM Test" || inspectionTypeNname=="Tractor PM/Inspection" || inspectionTypeNname=="Tractor Preventive Maintenance/Inspection") {
         // if (inspectionStatus === "DRAFT") {
         //    navigation("/tractorpmTestEdit/" + fkAccountId + '/' + id + '/' + vid);
         // } else {
         //    navigation("/tractorpmTestRead/" + fkAccountId + '/' + id + '/' + vid);
         // }

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/tractorpmTestRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }else if(inspectionStatus=="DRAFT" || inspectionStatus=="draft"){

               return (
                  navigation("/tractorpmTestEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
               )
            }
         else if(roles.match("Technician")){
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
            return (
               navigation("/tractorpmTestRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }
         }else if(roles.match("Manager") ||roles.match("Supervisor")|| roles.match("Owner") || roles.match("Inspector")){
            if ( inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING' ) {
            return (
               navigation("/tractorpmTestEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
            }
         } else {
            return (
               navigation("/tractorpmTestEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
         }

      } else if (inspectionTypeNname=="Driver Vehicle Inspection") {

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/driverVehicleTestRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }else if(inspectionStatus=="DRAFT" || inspectionStatus=="draft"){

               return (
                  navigation("/driverVehicleTestEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
               )
            }
         else if(roles.match("Technician")){
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
            return (
               navigation("/driverVehicleTestRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }
         }else if(roles.match("Manager") ||roles.match("Supervisor")|| roles.match("Owner") || roles.match("Inspector")){
            if ( inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING' ) {
            return (
               navigation("/driverVehicleTestEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
            }
         } else {
            return (
               navigation("/driverVehicleTestEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
         }

      } else if (inspectionTypeNname=="Service Check List") {

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/serviceCheckListRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }else if(inspectionStatus=="DRAFT" || inspectionStatus=="draft"){

               return (
                  navigation("/serviceCheckListEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
               )
            }
         else if(roles.match("Technician")){
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
            return (
               navigation("/serviceCheckListRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }
         }else if(roles.match("Manager") ||roles.match("Supervisor")|| roles.match("Owner") || roles.match("Inspector")){
            if ( inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING' ) {
            return (
               navigation("/serviceCheckListEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
            }
         } else {
            return (
               navigation("/serviceCheckListEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
         }

      } else if (inspectionTypeNname=="Leakage Test") {
         // if (inspectionStatus === "DRAFT") {
         //    navigation("/leakageTestEdit/" + fkAccountId + '/' + id + '/' + vid);
         // } else {
         //    navigation("/leakageTestRead/" + fkAccountId + '/' + id + '/' + vid);
         // }

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/leakageTestRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }else if(inspectionStatus=="DRAFT" || inspectionStatus=="draft"){

               return (
                  navigation("/leakageTestEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
               )
            }
         else if(roles.match("Technician")){
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
            return (
               navigation("/leakageTestRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }
         }else if(roles.match("Manager") ||roles.match("Supervisor")|| roles.match("Owner") || roles.match("Inspector")){
            if ( inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING' ) {
            return (
               navigation("/leakageTestEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
            }
         } else {
            return (
               navigation("/leakageTestEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
         }

      }else if (inspectionTypeNname=="Leakage Test (Method 27)") {

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/leakageTestMethod27Read/" + fkAccountId + '/' + id + '/' + vid)
            )
            }else if(inspectionStatus=="DRAFT" || inspectionStatus=="draft"){

               return (
                  navigation("/leakageTestMethod27Edit/"+ taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
               )
            }
         else if(roles.match("Technician")){
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
            return (
               navigation("/leakageTestMethod27Read/" + fkAccountId + '/' + id + '/' + vid)
            )
            }
         }else if(roles.match("Manager") ||roles.match("Supervisor")|| roles.match("Owner") || roles.match("Inspector")){
            if ( inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING' ) {
            return (
               navigation("/leakageTestMethod27Edit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
            }
         } else {
            return (
               navigation("/leakageTestMethod27Edit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
         }

      }
      else if (inspectionTypeNname.match("DOT Cargo Tank Test") || inspectionTypeNname.match("DOT Cargo Tank") || inspectionTypeNname.match("DOT Cargo") || inspectionTypeNname.match("Ohio EPA")) {
         // if (inspectionStatus === "DRAFT") {
         //    navigation("/dotCargoEdit/" + fkAccountId + '/' + id + '/' + vid);
         // } else {
         //    navigation("/dotCargoRead/" + fkAccountId + '/' + id + '/' + vid);
         // }

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/dotCargoRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }else if(inspectionStatus=="DRAFT" || inspectionStatus=="draft"){

               return (
                  navigation("/dotCargoEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
               )
            }
         else if(roles.match("Technician")){
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
            return (
               navigation("/dotCargoRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }
         }else if(roles.match("Manager") ||roles.match("Supervisor")|| roles.match("Owner") || roles.match("Inspector")){
            if ( inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING' ) {
            return (
               navigation("/dotCargoEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
            }
         } else {
            return (
               navigation("/dotCargoEdit/"+ taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }


      }
      else if (inspectionTypeNname.match("Internal Visual Inspection") || inspectionTypeNname.match("Internal Visual")) {

         // if (inspectionStatus === "DRAFT") {
         //    navigation("/internalVisualEdit/" + fkAccountId + '/' + id + '/' + vid);
         // } else {
         //    navigation("/internalVisualRead/" + fkAccountId + '/' + id + '/' + vid);
         // }

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/internalVisualRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }else if(inspectionStatus=="DRAFT" || inspectionStatus=="draft"){

               return (
                  navigation("/internalVisualEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
               )
            }
         else if(roles.match("Technician")){
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
            return (
               navigation("/internalVisualRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }
         }else if(roles.match("Manager")||roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")){
            if ( inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING' ) {
            return (
               navigation("/internalVisualEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
            }
         } else {
            return (
               navigation("/internalVisualEdit/"+ taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }

      }
      else if (inspectionTypeNname.match("Pressure test - Retest Inspection") || inspectionTypeNname.match("Pressure Vaccum test - Certificate application") || inspectionTypeNname.match("Pressure Test/Retest Inspection")) {

         // if (inspectionStatus === "DRAFT") {
         //    navigation("/pressureTestEdit/" + fkAccountId + '/' + id + '/' + vid);
         // }
         // else {
         //    navigation("/pressureTestRead/" + fkAccountId + '/' + id + '/' + vid);
         // }

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/pressureTestRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }else if(inspectionStatus=="DRAFT" || inspectionStatus=="draft"){

               return (
                  navigation("/pressureTestEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
               )
            }
         else if(roles.match("Technician")){
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
            return (
               navigation("/pressureTestRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }
         }else if(roles.match("Manager") ||roles.match("Supervisor")|| roles.match("Owner") || roles.match("Inspector")){
            if ( inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING' ) {
            return (
               navigation("/pressureTestEdit/"+ taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
            }
         } else {
            return (
               navigation("/pressureTestEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
         }

      }
      else if (inspectionTypeNname.match("Carrier Equipment Inspection")) {
         if (inspectionStatus === "DRAFT") {
            navigation("/carrierEquipmentEdit/"+ taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid);
         }
         else {
            navigation("/carrierEquipmentRead/" + fkAccountId + '/' + id + '/' + vid);
         }
      }
      else if (inspectionTypeNname.match("Annual Vehicle Inspection")) {
         // if (inspectionStatus === "DRAFT") {
         //    navigation("/annualVehicle1Edit/" + fkAccountId + '/' + id + '/' + vid);
         // }
         // else {
         //    navigation("/annualVehicleRead/" + fkAccountId + '/' + id + '/' + vid);
         // }
         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
              navigation("/annualVehicleRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }else if(inspectionStatus=="DRAFT" || inspectionStatus=="draft"){

               return (
                  navigation("/annualVehicle1Edit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
               )
            }
         else if(roles.match("Technician")){
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
            return (
              navigation("/annualVehicleRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }
         }else if(roles.match("Manager") ||roles.match("Supervisor")|| roles.match("Owner") || roles.match("Inspector")){
            if ( inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING' ) {
            return (
               navigation("/annualVehicle1Edit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
            }
         } else {
            return (
               navigation("/annualVehicle1Edit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
         }

      }
      else if (inspectionTypeNname.match("External Visual Inspection") || inspectionTypeNname.match("External/Visual Inspection")) {
         // if (inspectionStatus === "DRAFT") {
         //    navigation("/editExternalVisual/" + fkAccountId + '/' + id + '/' + vid);
         // }
         // else {
         //    navigation("/externalVisualRead/" + fkAccountId + '/' + id + '/' + vid);
         // }

         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/externalVisualRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }else if(inspectionStatus=="DRAFT" || inspectionStatus=="draft"){

               return (
                  navigation("/editExternalVisual/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
               )
            }
         else if(roles.match("Technician")){
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
            return (
               navigation("/externalVisualRead/"+ taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
            }
         }else if(roles.match("Manager") ||roles.match("Supervisor")|| roles.match("Owner") || roles.match("Inspector")){
            if ( inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING' ) {
            return (
               navigation("/editExternalVisual/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
            }
         } else {
            return (
               navigation("/editExternalVisual/"+ taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid)
            )
         }

      } else if (inspectionTypeNname.match("Gassoline Delivery Tank - Pressure Vaccum Test")) {
         if (inspectionStatus === "DRAFT") {
            navigation("/gasolineDeliveryTankPressureVacuumTestEdit/"+ taskOrderId + '/' + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/gasolineDeliveryTankPressureVacuumTestRead/" + fkAccountId + '/' + id + '/' + vid);
         }
         //  navigation("/gasolineDeliveryTankPressureVacuumTestRead/" + fkAccountId + '/' + id + '/' + vid);
      } else if (inspectionTypeNname.match("Vapor Tightness Certification")) {

         // if (inspectionStatus === "DRAFT") {
         //    navigation("/inspectionVaporTightnessCertificateEdit/" + fkAccountId + '/' + id + '/' + vid);
         // } else {
         //    navigation("/inspectionVaporTightnessCertificateRead/" + fkAccountId + '/' + id + '/' + vid);
         // }
         if (inspectionStatus == 'approved' || inspectionStatus === 'Reject' || inspectionStatus === 'approve' || inspectionStatus === 'Rejected' || inspectionStatus == 'APPROVED') {
            return (
               navigation("/inspectionVaporTightnessCertificateRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }else if(inspectionStatus=="DRAFT" || inspectionStatus=="draft"){

               return (
                  navigation("/inspectionVaporTightnessCertificateEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
               )
            }
         else if(roles.match("Technician")){
            if (inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING') {
            return (
               navigation("/inspectionVaporTightnessCertificateRead/" + fkAccountId + '/' + id + '/' + vid)
            )
            }
         }else if(roles.match("Manager")||roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")){
            if ( inspectionStatus === 'APPROVED PENDING' || inspectionStatus === 'approve pending' || inspectionStatus == 'APPROVE PENDING' ) {
            return (
               navigation("/inspectionVaporTightnessCertificateEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
            }
         } else {
            return (
               navigation("/inspectionVaporTightnessCertificateEdit/"+ taskOrderId + '/' +  fkAccountId + '/' + id + '/' + vid)
            )
         }
      }


   }
   // const sendtocustomer= () =>
   // {
   //    var itemIdsForGlobal=[];
   //    $('input:checkbox[name=documentIds]:checked').each(function()
   //    {

   //      itemIdsForGlobal.push($(this).val());

   //    });


   //    var requestParam=
   //    {

   //       "fkCustomerId":localStorage.getItem("customerId"),
   //       "documentIds":itemIdsForGlobal
   //    }



   //    var requestOptions = requestObject.RequestHeader(requestParam);

   //    services.sendtoCustomerAPI(requestOptions).then((res) =>
   //    {
   //       Swal.fire({
   //          position: 'center',
   //          icon: 'success',
   //          title: "",
   //          text: res,

   //          showConfirmButton: true

   //       }).then((result) => {
   //          if (result.isConfirmed) {

   //          }
   //       });

   //    });
   // }
   // const document_details = (id) => {



   //    var document_request =
   //    {

   //       "id": id

   //    };

   //    var requestOptions = requestObject.RequestHeader(document_request);

   //    services.getDocumentDetails(requestOptions).then((res) =>
   //    {
   //       console.log("results=="+JSON.stringify(res))
   //       if (res.customValues.fileByte === "")
   //       {
   //          Swal.fire({
   //             position: 'center',
   //             icon: 'error',
   //             title: "",
   //             text: "File is not attached",

   //             showConfirmButton: true

   //          }).then((result) => {
   //             if (result.isConfirmed) {

   //             }
   //          });
   //       } else {

   //          var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
   //          var byteNumbers = new Array(byteCharacters.length);
   //          for (var i = 0; i < byteCharacters.length; i++) {
   //             byteNumbers[i] = byteCharacters.charCodeAt(i);
   //          }
   //          var byteArray = new Uint8Array(byteNumbers);
   //          var file = new Blob([byteArray], { type: 'application/pdf;base64' });
   //          var fileURL = URL.createObjectURL(file);
   //          window.open(fileURL);
   //       }

   //    });

   // }

   ////////////////Update Customer/////////////////////////////
   const veh_docs = (id, pageNumber, ITEMS_PER_PAGE_DOCS, title, sortBy, sortDir, displayItems) => {


      if (sortBy === "Doc category") {

         sortBy = "documentCategoryName";
      }
      else if (sortBy === "documentSubCategoryName") {
         sortBy = "docSubCategory";
      }
      else if (sortBy === "Document Date") {

         sortBy = "documentDate";
      } else if (sortBy === "Title") {
         sortBy = "title";
       }
      else if (sortBy === "expiryDate") {
         sortBy = "expiryDate";
      }else if (sortBy === "Unit Number") {
         sortBy = "unitNumber";
       }
      else if (sortBy === "Vehicle number") {
         sortBy = "lisencePlateNumber";
      }
      else if (sortBy === "Created By") {
         sortBy = "CreatedBy";
      }
      else {
         sortBy = "";
      }

      var vehicle_request =
      {

         "fkVehicleId": id,
         "title": title,
         "pageNumber": pageNumber,
         "vehicleTypeId": "",
         "pageSize": ITEMS_PER_PAGE_DOCS,
         "sortBy": sortBy,
         "sortDir": sortDir,
         "isActive":true
      };

      // const requestOptions =
      // {
      //    method: 'POST',
      //    headers: { 'Content-Type': 'application/json' },
      //    body: JSON.stringify(vehicle_request)
      // };

      var requestOptions = requestObject.RequestHeader(vehicle_request);
      services.getVehicle_Docs(requestOptions).then((res) => {

         if (res.success) {
            settotalDocsItems(res.customValues.totalNumberOfRecords);
            settotalDocsPages(res.customValues.totalNumberOfRecords);
            setCurrentPage(res.customValues.currentPageNumber);
            // console.log("results=" + JSON.stringify(res ? res.rows : ""));
            setDocumentList(res ? res.rows : "");

            setTotalDocsRecord(res.customValues.totalNumberOfRecords);
            var displaypages = [];
            if (displayItems === "diplayItems") {

               for (var i = 1; i <= parseInt(Math.ceil(res.customValues.totalNumberOfRecords / itemperPagetoDisplay)); i++) {
                  displaypages.push(i * itemperPagetoDisplay);
               }

               setdisplayPage(displaypages);
            }
         }
         else {
            $("#DocumentID").hide();
            setDocumentList("");
            setTotalDocsRecord("0");
            setCurrentPage("1");
         }

      });

   }

   $('input[name=documentIdss]').change(function(){

      if ($('input:checkbox[name=documentIds]:checked').length> 0) {
          $("#sendtocustomerDisabled").prop("disabled", false);
         $("#printDocuments").prop("disabled", false);
      }else{
         $("#sendtocustomerDisabled").prop("disabled", true);
         $("#printDocuments").prop("disabled", true);
      }
   
   });

   $('input[name=documentIds]').change(function(){
      if ($('input:checkbox[name=documentIds]:checked').length> 0) {
         $("#sendtocustomerDisabled").prop("disabled", false);
         $("#printDocuments").prop("disabled", false);
      }else{
         $("#sendtocustomerDisabled").prop("disabled", true);
         $("#printDocuments").prop("disabled", true);
      }
   
   });


   return (
      <>
      {
         LoadingComponentfordocuments
      }
         {loadingComponent}
         {LoadingComponentForSubmit}
         <div class="main-panel">

            <div class="content-wrapper" style={{ display: "block" }}>

              <div class="row">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <div class="d-flex justify-content-between align-items-end ">
                        <h4 class="font-weight-bold my-4 text-uppercase d-flex justify-content-between align-items-center w-100">
                           <div>Unit number : <span style={{fontWeight: "normal !importnant" }}>{vechileDetails.unitNumber}</span></div>
                        <a onClick={() => backbuttonHandle()} class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back </a>
                        </h4>

                     </div>
                  </div>
               </div>

               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                     <div class="card p-3">
                        <div class="banner-owner">
                           <div class="owner-logo">
                              <img id="customerImage" />
                              <div class="owner-name">
                                 <h4 >{vechileDetails.customerName ? vechileDetails.customerName : ""}</h4>
                                 <span>DOT Number: </span> <span>{vechileDetails ? vechileDetails.dotNumber : ""}</span>
                              </div>
                           </div>
                           <div class="banner-contact-info bg-white d-flex flex-column flex-sm-row">
                              <div class="row clearfix w-100">
                                 <div class="col-lg-5 col-md-6">
                                    <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                       <img src="/images/icons/map-icon.svg" alt="" class="mr-2" />
                                       <div style={{ minWidth: "240px" }}>
                                          {/* <span >{vechileDetails.addressLine1 ? vechileDetails.addressLine1 : ""}</span> */}

                                             <span>{vechileDetails ? vechileDetails.addressLine1 : ""}{vechileDetails ? vechileDetails.addressLine1 ? "," : "" : ""}<span>
                                                &nbsp;</span>{vechileDetails ? vechileDetails.addressLine2 : ""}{vechileDetails ? vechileDetails.addressLine2 ? "," : "" : ""}<span>
                                                   &nbsp;</span><br />{vechileDetails ? vechileDetails.city : ""}{vechileDetails ? vechileDetails.city ? "," :"" : ""}<span> <span>
                                                   {vechileDetails ? vechileDetails.stateName : ""}{vechileDetails ? vechileDetails.stateName ? "," :"" : ""}</span>
                                                   &nbsp;</span>{vechileDetails ? vechileDetails.zip : ""}{vechileDetails ? vechileDetails.zip ? "." : "" : ""}</span>



                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-lg-3 col-md-6">
                                    <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                       <img src="/images/icons/phone-icon.svg" alt="" class="mr-2" />
                                       <span >{vechileDetails.customerNumber ? vechileDetails.customerNumber : "N/A"}</span>
                                    </div>
                                 </div>
                                 <div class="col-lg-4 col-md-6">
                                    <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                       <img src="/images/icons/message-icon.svg" alt="" class="mr-2" />
                                       <span >{vechileDetails.customerEmail ? vechileDetails.customerEmail : "N/A"}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>


               {/* <div class="row">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <div class="d-flex justify-content-between align-items-end mb-2">
                       <div class="doc-add-setup-bg w-100" >
                          <div></div>
                          <div>
                          {roles.match("Technician") || roles.match("Inspector") ? "" : <a onClick={() => redirect("/addDocument/" + cid + "/" + id)} class="btn btn-success "  >Add Document</a>}
                           {roles.match("Technician") || roles.match("Inspector") ? "" : <a onClick={() => redirect("/taskOrder/"+ cid + "/" + id + "/" + vechileDetails.customerName + "/" + encodeURIComponent(vechileDetails.vinNumber,"UTF-8"))} class="btn btn-success ml-2"> Add Task Order</a>}
                          </div>
                          <div class="d-flex justify-content-end align-items-center"> {roles.match("Technician") || roles.match("Inspector") ? "" : <label className='mb-0 mr-2 text-white'>Status : </label>}<div class="toggle-wrp">{roles.match("Technician") || roles.match("Inspector") ? "" :    <ToggleSwitch id="switch" checked={vechileDetails ?vechileDetails.isActive ? true : false:false} onChange={checked => activeVehicle(checked)} />}</div></div>




                        </div>
                     </div>
                  </div>
               </div>  */}

               <div class="row mb-2 ">
                  <div class="col-lg-11 col-md-12 m-auto ">
                     <div>

                        <ul class="nav nav-tabs tab-custome" id="myTab" role="tablist">
                           <li class="nav-item" role="presentation">
                              <a class="nav-link active" id="vd-tab" onClick={() => tabClicks('vd-tab')} data-toggle="tab" href="#vd" role="tab" aria-controls="home" aria-selected="true">Vehicle Details</a>
                           </li>
                           <li class="nav-item" role="presentation">
                              <a class="nav-link" id="doc-tab" onClick={() => tabClicks('doc-tab')} data-toggle="tab" href="#doc" role="tab" aria-controls="profile" aria-selected="false">Documents</a>
                           </li>
                           <li class="nav-item" role="presentation">
                              <a class="nav-link" id="insp-tab" onClick={() => tabClicks('insp-tab')} data-toggle="tab" href="#insps" role="tab" aria-controls="contact" aria-selected="false">Inspections</a>
                           </li>
                           <div class="doc-add-setup-indv">
                           {roles.match("Technician") || roles.match("Inspector") ? "" : <a onClick={() => redirect("/addDocument/" + cid + "/" + id)} class="btn btn-outline-primary "  >Add Document</a>}
                           {roles.match("Technician") || roles.match("Inspector") ? "" : <a onClick={() => redirect("/taskOrder/"+ cid + "/" + id + "/" + vechileDetails.customerName + "/" + encodeURIComponent(vechileDetails.vinNumber,"UTF-8"))} class="btn btn-outline-primary mx-2"> Add Task Order</a>}

                           </div>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                           <div class="tab-pane  active" id="vd" role="tabpanel" aria-labelledby="vd-tab">
                              <div class="card">
                                 <div class="row mb-2">
                                    <div class="col-lg-12 col-md-12 m-auto grid-margin stretch-card ">
                                       <div class="header-top-bg p-3 ">
                                          <h4>Vehicle Information</h4>
                                          <div class="info-labl">
                                             <img src="/images/icons/vehicle-icon.svg" alt="" />|<span> {vechileDetails.unitNumber?vechileDetails.unitNumber:"--"}</span>
                                          </div>

                                          {/* {roles.match("Technician") || roles.match("Inspector") ? "" :  <div class="d-flex ml-auto align-items-center">

                                          <label className="mb-0 mr-2">Status : </label><div class="pro-info-item text-capitalize mr-3">{vechileDetails ?vechileDetails.isActive ? "Active" : "Inactive":""}  {vechileDetails ?vechileDetails.isActive ?<a class="btn btn-outline-primary text-uppercase ml-2" onClick={() => activeVehicle("false")}>Inactive</a>:<a class="btn btn-outline-primary text-uppercase ml-2" onClick={() => activeVehicle("true")}>Active</a>:""}</div>

                                          </div>} */}
                                             <div>
                                             {roles.match("Technician") || roles.match("Inspector") ? "" : <label className='mb-2 mr-2'>Status : </label>}{roles.match("Technician") || roles.match("Inspector") ? "" :    <ToggleSwitch id="switch" checked={vechileDetails ?vechileDetails.isActive ? true : false:false} onChange={checked => activeVehicle(checked)} />}
                                             {roles.match("Technician") || roles.match("Inspector") ? "" : <a onClick={() => redirect("/editVehicle/" + id)} class="btn btn-primary text-uppercase ml-3">Edit</a>}
                                             </div>
                                       </div>

                                    </div>

                                    <div class="col-md-4">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7">Fleet Owner Unit Number</label>
                                          <b>{vechileDetails.unitNumber ? vechileDetails.unitNumber : "N/A"}</b>
                                       </div>
                                    </div>

                                    <div class="col-md-4">
                                       <div class="p-3 dlableUpper-flex flex-column">
                                          <label for="" class="opacity-7"><span class="lableUpper">VIN</span> Number</label><br />
                                          <b>{vechileDetails.vinNumber ? vechileDetails.vinNumber : "N/A"}</b>
                                       </div>
                                    </div>

                                    <div class="col-md-4">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7">Serial Number</label>
                                          <b>{vechileDetails.serialNumber ? vechileDetails.serialNumber : "N/A"}</b>
                                       </div>
                                    </div>

                                    <div class="col-md-4">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7">Make</label>
                                          <b>{vechileDetails.make ? vechileDetails.make : "N/A"}</b>
                                       </div>
                                    </div>

                                    <div class="col-md-4">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7">Model</label>
                                          <b>{vechileDetails.model ? vechileDetails.model : "N/A"}</b>
                                       </div>
                                    </div>

                                    <div class="col-md-4">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7">Year</label>
                                          <b>{vechileDetails.year ? vechileDetails.year : "N/A"}</b>
                                       </div>
                                    </div>

                                    <div class="col-md-4">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7">Manufacturer</label>
                                          <b>{vechileDetails.manfacturer ? vechileDetails.manfacturer : "N/A"}</b>
                                       </div>
                                    </div>

                                    <div class="col-md-4">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7">License <span class="lableUpper">P</span>late / <span class="lableUpper">S</span>tate</label>
                                          <b>{vechileDetails.lisencePlateNumber ? vechileDetails.lisencePlateNumber : "--"} / {vechileDetails.vehicleStateName ? vechileDetails.vehicleStateName : "--"}</b>
                                       </div>
                                    </div>

                                    {/* <div class="col-md-4">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7">State</label>
                                          <b>{vechileDetails.stateName ? vechileDetails.stateName : "N/A"}</b>
                                       </div>
                                    </div> */}


                                 </div>
                                 <div class="divider"></div>
                                 <div class="row mb-2">
                                    <div class="col-md-4">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7">Vehicle Type</label>
                                          <b>{vechileDetails.vehicleTypeName ? vechileDetails.vehicleTypeName : "N/A"}</b>
                                       </div>
                                    </div>

                                    {(() => {
                                       if (vechileDetails.vehicleTypeName == 'Trailer') {
                                          return (
                                             <>
                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Trailer Sub Type</label>
                                                      <b>{vechileDetails.trailerSubTypeName ? vechileDetails.trailerSubTypeName : "N/A"}</b>
                                                   </div>
                                                </div>
                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Tanker Sub Type</label>
                                                      <b id="tankersubType">{vechileDetails.tankerSubTypeName ? vechileDetails.tankerSubTypeName : "N/A"}</b>
                                                   </div>
                                                </div>

                                                {(() => {
                                                   if (vechileDetails.trailerSubTypeName=="Tanker" && vechileDetails.tankerSubTypeName == "Other") {
                                                      return (
                                                         <>
                                                            <div class="col-md-4">
                                                               <div class="p-3 d-flex flex-column">
                                                                  <label for="" class="opacity-7">Other</label>
                                                                  <b>{vechileDetails.otherTankerSubType? vechileDetails.otherTankerSubType : "N/A"}</b>
                                                               </div>
                                                            </div>
                                                         </>)
                                                   }
                                                })()}

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Cargo</label>
                                                      <b>{vechileDetails.cargo ? vechileDetails.cargo : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Insulated</label>
                                                      <b>{vechileDetails.isInsulated ? "Yes" : "No"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Lined</label>
                                                      <b>{vechileDetails.isLined ? "Yes" : "No"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Lining Type</label>
                                                      <b>{vechileDetails.liningType ? vechileDetails.liningType : "N/A"}</b>
                                                   </div>
                                                </div>


                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Compartmentalized</label>
                                                      <b> {vechileDetails.isCompartmented ? "Yes" : "No"}</b>
                                                   </div>
                                                </div>

                                                {/* {(() => {
                                                   if (vechileDetails.isCompartmented == true) {
                                                      return (
                                                         <> */}
                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">No of Compartments</label>
                                                      <b>{vechileDetails.numberOfCompartments ? vechileDetails.numberOfCompartments : "N/A"}</b>
                                                   </div>
                                                </div>
                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Compartment capacity in gallons</label>
                                                      {/* <b>{vechileDetails.compartmentCapacityInGallons ? vechileDetails.compartmentCapacityInGallons.replace(/,\s*$/, "") : "N/A"}</b> */}
                                                      <b>{vechileDetails.compartmentCapacityInGallons ? vechileDetails.compartmentCapacityInGallons.split(",").join(", ") : "N/A"}</b>
                                                   </div>
                                                </div>

                                                {/* </>)
                                                   }
                                                })()} */}

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Capacity Gallons</label>
                                                      <b>{vechileDetails.capacityInGallons ? vechileDetails.capacityInGallons : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Is installed with a vapor collection system</label>
                                                      <b>{vechileDetails.isInstalledWithVaporCollectionSys ? "Yes" : "No"}</b>
                                                   </div>
                                                </div>

                                                {(() => {
                                                   if (vechileDetails.isInstalledWithVaporCollectionSys == true) {
                                                      return (
                                                         <>
                                                            <div class="col-md-4">
                                                               <div class="p-3 d-flex flex-column">
                                                                  <label for="" class="opacity-7">Installed by Manufacturer/Retrofitted</label>
                                                                  <b>{vechileDetails.installedByManfacturer === "manufacture" ? "Manufacturer" : "Retrofitted"}</b>
                                                               </div>
                                                            </div>
                                                         </>)
                                                   }
                                                })()}

                                                <div class="divider"></div>
                                                {vechileDetails.vehicleTypeName == 'Trailer' ? vechileDetails.trailerSubTypeName == "Tanker" ?
                                                   <div class="col-md-12 px-4 mt-3 mx-2">
                                                      <h4 class="py-2">Spec Plate</h4>
                                                      <div class="row">
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7"><span class="lableUpper">DOT</span> Classification</label>
                                                               <b>{vechileDetails.dotClassification ? vechileDetails.dotClassification : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Month and Year of manufacture</label>
                                                               <b>{vechileDetails.mnthYrManfacture ? vechileDetails.mnthYrManfacture : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Corrosive Cargo</label>
                                                               <b>{vechileDetails.corrosiveCargo ? "Yes" : "No"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Dead Airspace / Double bulkhead</label>
                                                               <b>{vechileDetails.deadAirspace ? "Yes" : "No"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7"><span className="text-uppercase">MAWP (PSIG)</span></label>
                                                               <b>{vechileDetails.mawp ? vechileDetails.mawp : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Test Pressure <span className="text-uppercase">(PSIG)</span></label>
                                                               <b>{vechileDetails.testPressure ? vechileDetails.testPressure : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Original test date</label>
                                                               <b>{vechileDetails.originatTestDate ? vechileDetails.originatTestDate : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Shell Material</label>
                                                               <b>{vechileDetails.shellMaterial ? vechileDetails.shellMaterial : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Head Material </label>
                                                               <b>{vechileDetails.headMaterial ? vechileDetails.headMaterial : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Weld Material </label>
                                                               <b>{vechileDetails.weldMaterial ? vechileDetails.weldMaterial : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div class="row">
                                                         <div class="col-md-12">
                                                            <div style={{ background: "#f2f2f2", width: "calc(100% - 15px)", padding: "15px 10px" }}>
                                                               <label for="" class="opacity-7">Mfg Shell Thickness </label>
                                                               <div className="row">
                                                                  <div class="col-md-3">
                                                                     <div class="py-3 px-1 d-flex flex-column">
                                                                        <small>Top ends</small>
                                                                        <b>{vechileDetails.mfgShellThicknessTopEnds ? vechileDetails.mfgShellThicknessTopEnds : "N/A"}</b>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-3">
                                                                     <div class="py-3 px-1 d-flex flex-column">
                                                                        <small>Top Center</small>
                                                                        <b>{vechileDetails.mfgShellThicknessTopCentre ? vechileDetails.mfgShellThicknessTopCentre : "N/A"}</b>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-3">
                                                                     <div class="py-3 px-1 d-flex flex-column">
                                                                        <small>Side</small>
                                                                        <b>{vechileDetails.mfgShellThicknessSide ? vechileDetails.mfgShellThicknessSide : "N/A"}</b>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-3">
                                                                     <div class="py-3 px-1 d-flex flex-column">
                                                                        <small>Bottom</small>
                                                                        <b>{vechileDetails.mfgShellThicknessBottom ? vechileDetails.mfgShellThicknessBottom : "N/A"}</b>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <hr />
                                                               <label for="" class="opacity-7">Min Shell Thickness </label>
                                                               <div className="row">
                                                                  <div class="col-md-3">
                                                                     <div class="py-3 px-1 d-flex flex-column">
                                                                        <small>Top ends</small>
                                                                        <b>{vechileDetails.minShellThicknessTopEnds ? vechileDetails.minShellThicknessTopEnds : "N/A"}</b>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-3">
                                                                     <div class="py-3 px-1 d-flex flex-column">
                                                                        <small>Top Center</small>
                                                                        <b>{vechileDetails.minShellThicknessTopCentre ? vechileDetails.minShellThicknessTopCentre : "N/A"}</b>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-3">
                                                                     <div class="py-3 px-1 d-flex flex-column">
                                                                        <small>Side</small>
                                                                        <b>{vechileDetails.minShellThicknessSide ? vechileDetails.minShellThicknessSide : "N/A"}</b>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-3">
                                                                     <div class="py-3 px-1 d-flex flex-column">
                                                                        <small>Bottom</small>
                                                                        <b>{vechileDetails.minShellThicknessBottom ? vechileDetails.minShellThicknessBottom : "N/A"}</b>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>

                                                      </div>
                                                      <div class="row">
                                                      <div class="col-md-6">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Mfg Head Thickness</label>
                                                               <b>{vechileDetails.mfgHeadThickness ? vechileDetails.mfgHeadThickness : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-6">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Min head thickness</label>
                                                               <b>{vechileDetails.minHeadThickness ? vechileDetails.minHeadThickness : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   : "" : ""}
                                                <div class="col-md-12">
                                                   <hr className="mb-0" />
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Additional Notes</label>
                                                      <b>{vechileDetails.notes ? vechileDetails.notes : "N/A"}</b>
                                                   </div>
                                                </div>


                                             </>

                                          )
                                       }
                                    })()}

                                    {/* ///////////////////////Tractor//////////////////////////// */}

                                    {(() => {
                                       if (vechileDetails.vehicleTypeName == 'Tractor') {
                                          return (
                                             <>
                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Axle Configuration</label>
                                                      <b>{vechileDetails.axleConfiguration ? vechileDetails.axleConfiguration : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Cab configuration</label>
                                                      <b>{vechileDetails.cabConfiguration ? vechileDetails.cabConfiguration : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Connector</label>
                                                      <b>{vechileDetails.connector ? vechileDetails.connector : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Engine Make</label>
                                                      <b>{vechileDetails.engineMake ? vechileDetails.engineMake : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Engine Model</label>
                                                      <b>{vechileDetails.engineModel ? vechileDetails.engineModel : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Transmission</label>
                                                      <b>{vechileDetails.transmission ? vechileDetails.transmission : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Suspension</label>
                                                      <b>{vechileDetails.suspension ? vechileDetails.suspension : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Horse power</label>
                                                      <b>{vechileDetails.horsePower ? vechileDetails.horsePower : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-12">
                                                   <hr className="mb-0" />
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Additional Notes</label>
                                                      <b>{vechileDetails.notes ? vechileDetails.notes : "N/A"}</b>
                                                   </div>
                                                </div>
                                             </>

                                          )
                                       }
                                    })()}

                                    {/* ///////////////////////Truck Details//////////////////////////// */}

                                    {(() => {
                                       if (vechileDetails.vehicleTypeName == 'Truck') {
                                          return (
                                             <>
                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Axle Configuration</label>
                                                      <b>{vechileDetails.axleConfiguration ? vechileDetails.axleConfiguration : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Engine Make</label>
                                                      <b>{vechileDetails.engineMake ? vechileDetails.engineMake : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Engine Model</label>
                                                      <b>{vechileDetails.engineModel ? vechileDetails.engineModel : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Transmission</label>
                                                      <b>{vechileDetails.transmission ? vechileDetails.transmission : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Suspension</label>
                                                      <b>{vechileDetails.suspension ? vechileDetails.suspension : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Length Ft</label>
                                                      <b>{vechileDetails.lengthInFeet ? vechileDetails.lengthInFeet : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Width Ft</label>
                                                      <b>{vechileDetails.widthInFeet ? vechileDetails.widthInFeet : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Height Ft</label>
                                                      <b>{vechileDetails.heightInFeet ? vechileDetails.heightInFeet : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Capacity Tons</label>
                                                      <b>{vechileDetails.capacityTons ? vechileDetails.capacityTons : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Horse power</label>
                                                      <b>{vechileDetails.horsePower ? vechileDetails.horsePower : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">isInsulated</label>
                                                      <b>{vechileDetails.isInsulated ? "Yes" : "No"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">isRefrigerated</label>
                                                      <b>{vechileDetails.isRefrigerated ? "Yes" : "No"}</b>
                                                   </div>
                                                </div>


                                                <div class="col-md-12">
                                                   <hr className="mb-0" />
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Additional Notes</label>
                                                      <b>{vechileDetails.notes ? vechileDetails.notes : "N/A"}</b>
                                                   </div>
                                                </div>
                                             </>

                                          )
                                       }
                                    })()}

                                 </div>
                                 <div class="divider"></div>
                                 <div class="row mb-2">
                                    <div class="col-md-6">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7">Vehicle Image</label>


                                          <img id="vehicleImage" />
                                       </div>
                                    </div>








                                 </div>
                              </div>

                           </div>
                           <div class="tab-pane " id="doc" role="tabpanel" aria-labelledby="doc-tab">
                              <div class="row mb-2">
                                 <div class="col-lg-12 col-md-12 m-auto grid-margin stretch-card">
                                    <div class="card border-top">
                                       <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center py-3 pl-3">
                                          <div class="search position-relative align-self-start ">
                                             <Search
                                                // placeholderName="Search by document title/category/sub category"
                                                placeholderName="Search by Title / Type"
                                                onSearch={value => {
                                                   searchDocsList(value);
                                                }}

                                             />
                                             <a href="#" class="search-icon">
                                                <img src="/images/icons/search.svg" alt="" />
                                             </a>
                                             {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search by name / Company" class="pl-5"/>
                                                     <a href="#" class="search-icon">
                                                         <img src="/images/icons/search.svg" alt=""/>
                                                     </a> */}
                                          </div>
                                          <div class="d-flex sort align-self-start mt-1 mb-1">
                                             {/* <div class="d-flex align-items-center align-self-start">
                                                       <span class="mr-2">Sort by :</span>
                                                       <div class="dropdown show mr-3 ">
                                                         <a class="btn btn-outline-info dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Day</a>

                                                         <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                           <a class="dropdown-item" href="#">Week</a>
                                                           <a class="dropdown-item" href="#">Month</a>

                                                         </div>
                                                       </div>

                                                    </div> */}
                                             {/* <div class="d-flex align-items-center align-self-start">
                                                      <span class="mr-2">Vehicle Type :</span>
                                                      <div class="dropdown show mr-3">
                                                        <a class="btn btn-outline-info dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Select</a>
                                                        <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                          <a class="dropdown-item" href="#">Tractor</a>
                                                          <a class="dropdown-item" href="#">Truck</a>
                                                          <a class="dropdown-item" href="#">Trailer</a>
                                                        </div>
                                                      </div>

                                                   </div> */}
                                          </div>
                                          <div class="d-flex align-self-start mb-1 mt-1">
                                             {roles.match("Technician") || roles.match("Inspector") ? "" :
                                                <><button id="printDocuments" onClick={() => document_print()} class="btn btn-primary align-items-center d-flex"  style={{ "margin-right": "15px", fontSize:"0.875em !important" }}><span className="mr-2"><img src="/img/print.svg" style={{filter:"invert(0.9)"}} alt="" width="18"/></span>  Print Document</button>
                                                <button  id="sendtocustomerDisabled" class="btn btn-success align-items-center d-flex" data-toggle="modal" data-target="#myModal" style={{ "margin-right": "15px" }}>Send to Customer</button></>
                                                // <a onClick={() => sendtocustomer()} class="btn btn-success" style={{"margin-right":"15px"}} >Send to Customer</a>
                                             }

                                          </div>
                                       </div>
                                       <div class="card-body pt-0 pb-0">

                                          <div class="row">
                                             <div class="col-12">
                                                <div class="table-responsive border-top border-bottom">



                                                   <table class="table">
                                                      <thead>
                                                         <tr>
                                                            {headers.map(({ name, field, sortable, thStyle, inlineStyle }) => (
                                                               <th
                                                                  key={name}
                                                                  onClick={() =>
                                                                     sortable ? onSortingChangeDocument(field) : null
                                                                  }
                                                                  class={thStyle} style={inlineStyle}>
                                                                  {name}

                                                                  {sortingField && sortingField === field && (

                                                                     sortingOrder === "asc"
                                                                        ? "↑"
                                                                        : "↓"
                                                                  )}

                                                               </th>
                                                            ))}
                                                         </tr>
                                                      </thead>
                                                      <tbody >
                                                         {
                                                            documentList ? documentList.map((data, index) => (
                                                               <tr>
                                                                  <td style={{ width: "3%", textAlign: "center"  }}>
                                                                     <input type="checkbox" name="documentIds" onClick={(e) => CustomercontactId(e, setChecked, checked)} value={data.id} />
                                                                  </td>
                                                                  {/* <td>{data.documentCategoryName ? data.documentCategoryName : "--"} </td> */}
                                                                  <td style={{ width: "36%", whiteSpace: "break-spaces", paddingRight: "15px", textAlign: "left" }}>{data.documentSubCategoryName ? data.documentSubCategoryName : "--"} </td>
                                                                  <td style={{ width: "30%", whiteSpace: "break-spaces", paddingRight: "15px", textAlign: "left" }}>{data.title ? data.title : "--"}</td>

                                                                  {/* <td>{data.lisencePlateNumber ? data.lisencePlateNumber : "--"}</td> */}
                                                                  {/* <td style={{ width: "10%", paddingRight: "10px" }}>{data.documentDate ? data.documentDate : "--"}</td> */}
                                                                  <td style={{ width: "15%", paddingRight: "15px" }}>{data.expiryDate ? moment(data.expiryDate).format('MM-DD-YYYY') : "--"}</td>
                                                                  <td style={{ width: "15%", paddingRight: "15px", textAlign: "left" }}>{data.latestSharedOn!=null ? hour_format=='24'?moment.utc(data.latestSharedOn).local().format('MM-DD-YYYY HH:mm:ss'):moment.utc(data.latestSharedOn).local().format('MM-DD-YYYY hh:mm:ss A') : "--"}</td>
                                                                  {/* <td>{data.createUser ? data.createUser : "--"}</td> */}
                                                                  <td style={{ width: "5%" }} ><a onClick={() => document_details(data.id,data.filePath)} class="btn btn-outline-primary text-uppercase">View</a>&nbsp;{data.documentCategoryName === "Inspection Documents" ? "" : roles.match("Technician") || roles.match("Inspector") ? "" : <a onClick={() => redirect("/editDocument/" + cid + "/" + id + "/" + data.id)} class="btn btn-outline-primary text-uppercase" id="editDocument">Edit</a>} {roles.match(Manager)||roles.match(Owner)?<a onClick={() => deleteDocuments(data.id,data.unitNumber != '' ? data.unitNumber : '',data.title != '' ? data.title : '')} class="btn btn-outline-primary text-uppercase mr-1">Delete</a>:""}</td>
                                                                  {/* <td></td> */}
                                                               </tr>
                                                            )) : ""

                                                         }


                                                      </tbody>
                                                   </table>
                                                </div>
                                                {documentList ? documentList.map((data, index) => { }) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span></div>}
                                             </div>
                                          </div>
                                       </div>

                                       {documentList ? <div class="d-flex align-items-center justify-content-between p-3">
                                          <div class="d-flex">
                                             <div class="dropdown show mr-3 ">
                                                {/* <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                   25
                                                   </a>
                                                   <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                      <a class="dropdown-item" href="#">25</a>
                                                      <a class="dropdown-item" href="#">50</a>
                                                   </div> */}
                                                <select class="btn gray-mid-color dropdown-toggle  form-control custome-select" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onChange={e => {

                                                   setitemperdocspage(e.target.value);
                                                   veh_docs(id, "1", e.target.value, "", sortingField, sortingOrder, "diplayItems");
                                                   // inspectionList(id, "1", e.target.value, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, "");
                                                }}>
                                                   {
                                                      displayPage.map((data, index) => (
                                                         <option value={data}>{data}</option>
                                                      ))
                                                   }
                                                </select>
                                             </div>
                                             <span class="d-flex align-items-center pagenation-mob">{currentPage * ITEMS_PER_PAGE_DOCS <= totalDocsRecors ? currentPage * ITEMS_PER_PAGE_DOCS : totalDocsRecors} out of {totalDocsRecors} Records Displayed</span>
                                          </div>
                                          <nav aria-label="Page navigation example">
                                             <Pagination
                                                total={totalDocsRecors}
                                                itemsPerPage={ITEMS_PER_PAGE_DOCS}
                                                currentPage={currentPage}
                                                //onPageChange={page => setCurrentPage(page)}
                                                onPageChange={handlePageChange}
                                             />
                                             {/* <ul class="pagination mb-0">
                                                   <li class="page-item">
                                                      <a class="page-link" href="#" aria-label="Previous">
                                                      <span aria-hidden="true">«</span>
                                                      <span class="sr-only">Previous</span>
                                                      </a>
                                                   </li>
                                                   <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                   <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                   <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                   <li class="page-item">
                                                      <a class="page-link" href="#" aria-label="Next">
                                                      <span aria-hidden="true">»</span>
                                                      <span class="sr-only">Next</span>
                                                      </a>
                                                   </li>
                                                </ul> */}
                                          </nav>
                                       </div>:""}

                                       {/* <div class="d-flex align-items-center justify-content-between p-3">
                                                 <div class="d-flex">
                                                  <div class="dropdown show mr-3 ">
                                                      <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        25
                                                      </a>

                                                      <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                        <a class="dropdown-item" href="#">25</a>
                                                        <a class="dropdown-item" href="#">50</a>

                                                      </div>
                                                    </div>
                                                    <span class="d-flex align-items-center pagenation-mob">25 out of 72 Records Displayed</span>
                                                 </div>
                                                  <nav aria-label="Page navigation example">
                                                      <ul class="pagination mb-0">
                                                        <li class="page-item">
                                                          <a class="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true">«</span>
                                                            <span class="sr-only">Previous</span>
                                                          </a>
                                                        </li>
                                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                        <li class="page-item">
                                                          <a class="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true">»</span>
                                                            <span class="sr-only">Next</span>
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </nav>
                                              </div> */}

                                    </div>

                                 </div>
                              </div>
                           </div>
                           <div class="tab-pane " id="insps" role="tabpanel" aria-labelledby="insp-tab">
                              <div class="row mb-2">
                                 <div class="col-lg-12 col-md-12 m-auto grid-margin stretch-card">
                                    <div class="card border-top">
                                       <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                                          {/* <div class="search position-relative align-self-start mb-1 mt-1">
                                                      <input type="search" id="gsearch" name="gsearch" placeholder="Search by name / Company" class="pl-5"/>
                                                     <a href="#" class="search-icon">
                                                         <img src="/images/icons/search.svg" alt=""/>
                                                     </a>
                                                  </div> */}

                                          <div class="search position-relative align-self-start ">
                                             {/* <Search
                                                placeholderName="Search with inspection type"
                                                onSearch={value => {
                                                   searchInspectionList(value);
                                                }}

                                             /> */}


                                             {/* <a href="#" class="search-icon">
                                                <img src="/images/icons/search.svg" alt="" />
                                             </a> */}
                                             {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search by name / Company" class="pl-5"/>
                                                     <a href="#" class="search-icon">
                                                         <img src="/images/icons/search.svg" alt=""/>
                                                     </a> */}
                                          </div>

                                          {/* <div class="d-flex sort align-self-start mt-1 mb-1">

                                                         <div class="d-flex align-items-center align-self-start">
                                                         <span class="mr-2">Vehicle Type :</span>
                                                         <div class="dropdown show mr-3">
                                                            <a class="btn btn-outline-info dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Select</a>
                                                            <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                               <a class="dropdown-item" href="#">Tractor</a>
                                                               <a class="dropdown-item" href="#">Truck</a>
                                                               <a class="dropdown-item" href="#">Trailer</a>
                                                            </div>
                                                         </div>

                                                      </div>
                                                  </div> */}

                                          <div class="d-flex align-self-start w-100 justify-content-between">
                                             <select class="form-control" style={{ minWidth: "260px", maxWidth: "260px" }} id="inspectionType" onChange={e => searchInspectionList(e.target.value)}>


                                                <option value="">Select</option>
                                                {
                                                   inspectionTypes ? inspectionTypes.sort((a, b) => a.name.localeCompare(b.name)).map((inspection, index) => (
                                                      <option value={inspection.name}>{inspection.name}</option>
                                                   )) : <option value=""></option>
                                                }
                                                {/* <option value="vapor tightness certificate">vapor tightness certificate</option> */}
                                             </select>&nbsp;&nbsp;&nbsp;
                                             {/* <div class="d-flex align-self-start mb-1 mt-1">
                                                <a onClick={() => redirect("/addInspection/" + cid + "/" + id)} class="btn btn-success d-flex align-items-center justify-content-center">New Inspection</a>
                                             </div> */}

                                          </div>
                                       </div>
                                       <div class="card-body pt-0 pb-0">

                                          <div class="row">
                                             <div class="col-12">
                                                <div class="table-responsive border-top border-bottom">



                                                   <table class="table">
                                                      <thead>
                                                         <tr>
                                                            {Inspectionheaders.map(({ name, field, sortable, thStyle, inlineStyle }) => (
                                                               <th
                                                                  key={name}
                                                                  onClick={() =>
                                                                     sortable ? onSortingChangeDocument(field) : null
                                                                  }
                                                                  class={thStyle} style={inlineStyle}>
                                                                  {name}

                                                                  {sortingField && sortingField === field && (

                                                                     sortingOrder === "asc"
                                                                        ? "↓"
                                                                        : "↑"
                                                                  )}

                                                               </th>
                                                            ))}
                                                         </tr>
                                                      </thead>
                                                      <tbody>


                                                         {
                                                            inspectionLists ? inspectionLists.map((inspection, index) => (
                                                               <tr>
                                                                  <td style={{ minWidth: "200px", maxWidth: "200px", lineHeight: "20px", textAlign: "left", whiteSpace: "break-spaces", paddingRight: "15px" }}>{inspection.inspectionType ? inspection.inspectionType.name : "--"}</td>

                                                                  <td>{inspection.inspectedDate ? inspection.inspectedDate : "--"}</td>
                                                                  <td>{inspection.inspectedBy ? inspection.inspectedBy : "--"}</td>
                                                                  <td>
                                                                     {(() => {
                                                                        if (inspection.status == 'approve pending' || inspection.status == "Approved pending" || inspection.status == "APPROVED PENDING") {
                                                                           return (
                                                                              <span class="status-txt approve-pending">To Be Approved</span>
                                                                              // <span class="status-txt approve-pending">{inspection.status ? inspection.status : "--"}</span>
                                                                           )
                                                                        } else if (inspection.status == 'Rejected') {
                                                                           return (
                                                                              <span class="status-txt reject">{inspection.status ? inspection.status : "--"}</span>
                                                                           )
                                                                        } else if (inspection.status == 'draft') {
                                                                           return (
                                                                              <span class="status-txt draft">{inspection.status ? inspection.status : "--"}</span>
                                                                           )
                                                                        } else if (inspection.status == 'approved' || inspection.status == 'approve' || inspection.status == 'APPROVED') {
                                                                           return (
                                                                              <span class="status-txt approved">{inspection.status ? inspection.status : "--"}</span>
                                                                           )
                                                                        } else {
                                                                           return (
                                                                              <><span class="status-txt">{inspection.status ? inspection.status : "--"}</span></>
                                                                           )
                                                                        }
                                                                     })()}
                                                                     {/* {inspection.status ? inspection.status : "--"} */}
                                                                  </td>
                                                                  <td style={{ width: "90px" }}><a onClick={() => inspection_details(inspection.taskOrderId,inspection.inspectionType.name, inspection.status, inspection.fkCustomerId, inspection.id)} class="btn btn-outline-primary text-uppercase" >
                                                                     {(() => {

                                                                           if (inspection.status == 'approved' || inspection.status === 'Reject' || inspection.status === 'approve' || inspection.status === 'Rejected' || inspection.status == 'APPROVED') {
                                                                           return (
                                                                              <>View</>
                                                                           )
                                                                           }else if(inspection.status=="DRAFT"){

                                                                              return (
                                                                                 <>Edit</>
                                                                              )
                                                                           }
                                                                        else if(roles.match("Technician")){
                                                                           if (inspection.status === 'APPROVED PENDING' || inspection.status === 'approve pending' || inspection.status == 'APPROVE PENDING') {
                                                                           return (
                                                                              <>View</>
                                                                           )
                                                                           }
                                                                        }else if(roles.match("Manager")||roles.match("Supervisor")|| roles.match("Owner") || roles.match("Inspector")){
                                                                           if ( inspection.status === 'APPROVED PENDING' || inspection.status === 'approve pending' || inspection.status == 'APPROVE PENDING' ) {
                                                                           return (
                                                                              <>Edit</>
                                                                           )
                                                                           }
                                                                        } else {
                                                                           return (
                                                                              <>Edit</>
                                                                           )
                                                                        }
                                                                     })()}

                                                                  </a>
                                                                  {roles.match(Manager)||roles.match(Owner)?<a onClick={() => deleteInspectionDocuments(inspection.id)} class="btn btn-outline-primary text-uppercase ml-1">Delete</a>:""}
                                                                  </td>
                                                               </tr>
                                                            )) : ""
                                                         }



                                                         {/* <tr>
                                                            <td>External Visual Inspection</td>
                                                            <td>External Visual Inspection </td>
                                                            <td>05-08-2020</td>
                                                            <td>James</td>
                                                            <td><span class=" status-txt approve-pending ">Approve Pending</span></td>
                                                            <td><a href="#" class="btn btn-outline-primary text-uppercase">View</a></td>
                                                         </tr> */}


                                                      </tbody>
                                                   </table>
                                                </div>

                                             </div>
                                             {inspectionLists ? inspectionLists.map((data, index) => { }) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span></div>}
                                          </div>
                                       </div>
                                       {inspectionLists ?
                                          <div class="d-flex align-items-center justify-content-between p-3">
                                             <div class="d-flex">
                                                <div class="dropdown show mr-3 ">
                                                   {/* <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        25
                                                      </a>

                                                      <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                        <a class="dropdown-item" href="#">25</a>
                                                        <a class="dropdown-item" href="#">50</a>

                                                      </div> */}
                                                   <select class="btn gray-mid-color dropdown-toggle  form-control custome-select" id="display_pages_in_items" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                      {
                                                         displayPageForInspection.map((data, index) => (
                                                            <option value={data}>{data}</option>
                                                         ))
                                                      }
                                                   </select>
                                                </div>
                                                <span class="d-flex align-items-center pagenation-mob">{inspectioCurrentPage * ITEMS_PER_PAGE <= totalInspectionPages ? inspectioCurrentPage * ITEMS_PER_PAGE : totalInspectionPages} out of {totalInspectionPages} Records Displayed</span>
                                             </div>
                                             <nav aria-label="Page navigation example">
                                                <Pagination
                                                   total={totalInspectionPages}
                                                   itemsPerPage={ITEMS_PER_PAGE}
                                                   currentPage={inspectioCurrentPage}
                                                   //onPageChange={page => setCurrentPage(page)}
                                                   onPageChange={handlePageChangeForInspection}
                                                />

                                             </nav>
                                          </div> : ""}

                                    </div>

                                 </div>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>

            <div id="myModal" class="modal fade" role="dialog">
               <div class="modal-dialog">


                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Send to Customer</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="edit_close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body sc-mod-pop">
                        <div class="row">
                           <div class="col-md-12">
                           {/* <p id="emailError" class="error"></p> */}
                              <input type="checkbox" class="form-check-input" name="contactSelectAll" onClick={(e) => SelectAllContactList(e, setChecked)} />
                              <label style={{ marginLeft: "26px", fontWeight: "bold", marginBottom: "11px" }}>Select All</label></div>
                           {/* <input type="checkbox" class="form-check-input"  name="contactSelectAll"   onClick={(e) => SelectAllContactList(e,setChecked)}    />Select All */}
                           {

                              CustomerContactList? CustomerContactList.map((data, index) => {
                                 return (<>
                                    <div class="col-md-12">
                                       <div class="form-check">
                                          <input type="checkbox" class="form-check-input" name="contactListemails" value={`${data.email}`} onClick={(e) => CustomercontactId(e, setChecked, checked)} />
                                          <span style={{marginLeft:"20px"}}>{data.firstName?data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1):""}</span>
                                       </div>
                                    </div>

                                 </>)
                              }) :
                                 <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                 </div>}

                                 <lable style={{paddingLeft:"12px", display:"flex", paddingTop:"20px"}}>Please enter one or more emails to send the documents to.</lable>
                                 <AddRemoveInputField setInputList={setInputEmailList} inputList={emailforsendtoct}/>
                                
                        </div>
                        {/* <div class="row">
                           <div class="col-md-12">
                              <select class="form-control custome-select" id="exampleFormControlSelect1">
                                 <option>One</option>
                                 <option>Two</option>
                                 <option>Three</option>
                              </select>
                           </div>
                           </div> */}
                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-primary"  onClick={() => Sendtocustomer(checked,'adddocuments',emailforsendtoct)} >Submit</button>
                     </div>
                  </div>

               </div>
            </div>
            {/* <!-- Modal --> */}
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Contact</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <div class="row">
                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">First Name</label>
                                 <input type="email" class="form-control custome-input" maxLength="20" minLength="2" id="exampleInputEmail1" aria-describedby="emailHelp" />
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Last Name</label>
                                 <input type="email" class="form-control custome-input" maxLength="20" minLength="2" id="exampleInputEmail1" aria-describedby="emailHelp" />
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Email ID</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Desk Phone</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Mobile Phone</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleFormControlSelect1">Role in Company</label>
                                 <select class="form-control custome-select" id="exampleFormControlSelect1">
                                    <option>One</option>
                                    <option>Two</option>
                                    <option>Three</option>
                                 </select>
                              </div>
                           </div>

                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleFormControlSelect1">Status</label>
                                 <select class="form-control custome-select" id="exampleFormControlSelect1">
                                    <option>Active</option>
                                    <option>Inactive</option>

                                 </select>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* <ReactBootstrap.Spinner animation="border"/> */}
      </>
   )
};

VehicleDetails.propTypes = {};

VehicleDetails.defaultProps = {};

export default VehicleDetails;
