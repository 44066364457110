
import Keycloak from "keycloak-js";
var realmName = "";

if (localStorage.getItem("realm") !== null) 
{
    realmName = localStorage.getItem("realm");
}
else {
    // realmName="Dev";
   realmName = "Master";
    //    realmName="Bradshaw";
    //      realmName="Dev";
}
///for logout/////////
// export const logouturl= "http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth";
///keycloack config setupdone////////////

//////////dev/////////////
//    export const keycloak = new Keycloak({
//       url: "http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth",
//       realm: realmName,
//       clientId: "dotstow_ui"
//    })
/// qa//////////////////
//     export const keycloak = new Keycloak(
//     {
//              url: "http://keycloak-dotstow-qa-alb-1314093332.us-east-2.elb.amazonaws.com/auth",
//             realm: realmName,
//             clientId: "dotstow_ui"
//     })
//////////UAT FOR KEYCLOACK///////////////////
// export const keycloak = new Keycloak({
//   url: "http://keycloak-dotstow-uat-alb-127029246.us-east-2.elb.amazonaws.com/auth",
//   realm:realmName,
//   clientId: "dotstow_ui"
// })

//////////  Production FOR KEYCLOACK///////////////////
// export const keycloak = new Keycloak({
//   url: "http://keycloak-dotstow-prod-alb-1839268278.us-east-2.elb.amazonaws.com/auth",
//   realm:realmName,
//   clientId: "dotstow_ui"
// })

// export const keycloak = new Keycloak({
//   url: "http://keycloak-dotstow-prod-alb-1839268278.us-east-2.elb.amazonaws.com/auth",
//   realm:realmName,
//   clientId: "dotstow_ui"
// })


export const keycloak = new Keycloak({
    url: "https://auth.dotstow.com/auth",
    realm: realmName,
  clientId: "dotstow_ui"
})
