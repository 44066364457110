import React,{history,useEffect, useState, useMemo } from "react";
import services from "../../../services/services";
import { Controller,useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message"
import $ from "jquery"
import DatePicker from "react-datepicker";
import {useNavigate , useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import vehicle_details_api from "../vehicle/vehicle_details_api";
import {useLoading} from 'react-hook-loading';
import Swal from "sweetalert2";
import VehicleBasicInfo from "../../../utility/vehicleBasicInfo";
import moment from 'moment'
import { requestObject } from "../../../utility/requestObject";
import CheckAuthentication from "../../../utility/checkAuthentication";
import { loadingComponent } from "../../../utility/Loading";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
const FleetOwnerAddDocument = () => {
   const [docsCategory,setDocsCategory] = useState([]);
   const [docsCategorys,setDocsCategorys] = useState([]);
   const [loading, setLoading] = useLoading("Please wait...");
   const [imageName, setImageName] = useState("");
   const [itemName, setFieldName] = useState('');
   const [base64file, setBase64file] = useState('');
   const [ fileName, setfileName ] = useState("")
   // const [fileName, setfileName] = useState('');
   const [inputList, setInputList] = useState([{ firstName: "", lastName: "" }]);
   const [startDate, setStartDate] = useState(new Date());
   const [endDate, setEndDate] = useState(new Date());
   const { customerId,vehicleId } = useParams(); 
   const [vechileDetails,setVehicleDetails] = useState([]);
   const [cid,setCustomerId] = useState([]);
   const [inspectionValue,setInspectionValue] = useState([]);
   const [othersValue,setOthersValue] = useState([]);
   const navigate = useNavigate();
   var navigationFrom= sessionStorage.getItem("naviationfrom");

   $(document).ready(function () {
      $('#created_date,#expiryDate').keypress(function( e ) {
         if(e.which === 32){
             return false;
         }
     });

   });
   useEffect(() => 
   {
    
      getDocumentCategorie();
      onChangeValue("inspections");
      $('input[name="others"][value="inspections"]').trigger("click");

      if(CheckAuthentication)
      {
         // getInspectionDocument();
         setCustomerId(customerId);
         // getVehicle_Docs(); 
         vehicle_details_api(vehicleId,setVehicleDetails,setCustomerId,"dontcallcontactsAPI");
      }else{
         sessionStorage.clear();
         localStorage.clear();
        //navigate("/login");
          window.location.href="/login";
      }
     
   },[]);

   const getDocumentCategorie=()=>
{ 
    
   
      var vehicle_request=
      {
             
      };
     
      // const requestOptions = 
      // {
      //       method: 'POST',
      //       headers:{'Content-Type': 'application/json'},
      //       body: JSON.stringify(vehicle_request)
      // };
      var requestOptions=requestObject.RequestHeader(vehicle_request);
 
      services.getDocumentCategories(requestOptions).then((res) => 
      {
         
        console.log("GetDocument Category@@@@@"+JSON.stringify(res));
           setDocsCategory(res.rows);
           for (var i=0;i<res.rows.length;i++){
            if(res.rows[i].name=="Inspection Documents"){
               // alert(res.rows[0].id);
                 setInspectionValue(res.rows[i].id);
              }
             if(res.rows[i].name=="Others"){
            //  alert(res.rows[1].id);
               setOthersValue(res.rows[i].id);
           }
           }
 
         
      });

} 


   const {
      control,
      reset,
      register,
      formState: { errors },
      handleSubmit
    } = useForm({
      criteriaMode: "all",mode: "" 
    });


  
    
    const fileToBase64 = (file, cb) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        cb(null, reader.result)
      }
      reader.onerror = function (error) {
        cb(error, null)
      }
    }
    const onUploadFileChange = ({ target }) => 
    {
      const MIN_FILE_SIZE = 51200 // 50MB
      const fileSizeKiloBytes=Math.floor(target.files[0].size/1024); //
  
      if(fileSizeKiloBytes <= MIN_FILE_SIZE){
      var allowedExtensions =/(\jpg|\png|\jpeg|\pdf)$/i;
      var fileInput =
      document.getElementById('file-input');
   
      var filePath = fileInput.value;
 
      if (!allowedExtensions.exec(filePath)) 
      {
        
          Swal.fire({  
            position: 'center',  
            icon: 'error',  
            title: "",  
            text:"Invalid file type",
            showConfirmButton: true 
            
          }).then((result) => 
          {
               
                if(result.isConfirmed) 
                {
                  
                  //e.target.value = '';
                  return false;
                }
          });
         
      }else{
      if (target.files < 1 || !target.validity.valid) {
        return
      }
      fileToBase64(target.files[0], (err, result) => {
        if (result) {
         if(filePath.split('.').pop()=="jpg"||filePath.split('.').pop()=="jpeg")
           {
        
            setBase64file(result.replace("data:image/jpg;base64,", ""));
            setBase64file(result.replace("data:image/jpeg;base64,", ""));
           }
          
           else if(filePath.split('.').pop()=="png")
           {
            setBase64file(result.replace("data:image/png;base64,", ""));
           }
           else if(filePath.split('.').pop()=="pdf")
           {
            setBase64file(result.replace("data:application/pdf;base64,", ""));
           }
           else{
           setBase64file(result.replace("data:application/pdf;base64,", ""));
           }
         
         // setFile(result)
         setImageName(target.files[0].name.replace(/\.[^/.]+$/, ""))
         setfileName(target.files[0]);
         //setattachPdf(result.replace("data:application/pdf;base64,", ""));
         
         // setFileName(target.files[0])
        }
      })
     }
   }else{
      Swal.fire({
         position: 'center',
         icon: 'error',
         title: "",
         text: "Max file size is 50MB",
         showConfirmButton: true

      }).then((result) => {
      });   
   }
   }
   //  const onUploadFileChange = ({ target }) => {
   //    if (target.files < 1 || !target.validity.valid) {
   //      return
   //    }
   //    fileToBase64(target.files[0], (err, result) => {
   //      if (result) {
   //        setBase64file(result);
   //       // setFile(result)
   //       setfileName(target.files[0]);
   //       // setFileName(target.files[0])
   //      }
   //    })
   //  }

 
       ////////////////Update Customer/////////////////////////////
      const getInspectionDocument=()=>
      { 
         
         
            var inspectionDocs=
            {
                  
            };
         
            // const requestOptions = 
            // {
            //       method: 'POST',
            //       headers:{'Content-Type': 'application/json'},
            //       body: JSON.stringify(vehicle_request)
            // };
            var requestOptions=requestObject.RequestHeader(inspectionDocs);
      
            services.getInspectionDocumentSubCategories(requestOptions).then((res) => 
            {
               
            
               setDocsCategorys(res.rows);
               
            });

      } 
      ////////////////Update Customer/////////////////////////////
      const getVehicle_Docs=()=>
      { 
         
         
            var inspectionDocs=
            {
                 
            };
        
         
            // const requestOptions = 
            // {
            //       method: 'POST',
            //       headers:{'Content-Type': 'application/json'},
            //       body: JSON.stringify(vehicle_request)
            // };
            var requestOptions=requestObject.RequestHeader(inspectionDocs);
      
            services.getVehicleDocumentSubCategories(requestOptions).then((res) => 
            {
               
            
               setDocsCategorys(res.rows);
               
            });

      } 
//////////////submitvalues///////////////////////////
const redirect=(page)=>
    {
         
        navigate(page);
    
    }
const onChangeValue=(event)=>
{

//   alert(event);
   if(event==="others")
   {
      getVehicle_Docs();
     
   }else{
      getInspectionDocument();
   }

}
const submitValue=()=>
{

   // setLoading(true);

   if(base64file ==="")
   {
      // setLoading(false);
      Swal.fire({  
         position: 'center',  
         icon: 'error',  
         title: "",  
         text:"Please upload a document",
         showConfirmButton: true 
         
       }).then((result) => 
       {
            
             if(result.isConfirmed) 
             {
              
              
             }
       });
    return false;
   }

   var addDocuments=
   { 

       
      
      "fkVehicleId":vehicleId, 
      
      "title":$("#documentTitle").val().trim(), 
       
      
      // "fkDocumentCategoryId":  $('input:radio[name="others"]:checked').val(),
      "fkDocumentCategoryId":  $('input:radio[name="others"]:checked').val()==="others"?othersValue:inspectionValue,
      "documentSubCategoryId":parseInt($("#doucmenttype").val()),
      
      // "documentDate":startDate, 
      // "expiryDate":endDate,
      "documentDate":$("#created_date").val() != '' ? moment($("#created_date").val()).format('YYYY-MM-DD') : '', 
      
      "expiryDate":$("#expiryDate").val() != '' ? moment($("#expiryDate").val()).format('YYYY-MM-DD') : '',   
      
      "imageName":base64file?parseInt(Math.random() * 1000000000):"", 
      
      "imageExtension":base64file?fileName.name.split('.').pop() :"", 
      "imageByteString":base64file?base64file:""
   }
   $("#loadingforsubmit").show();
   // const requestOptions = 
   // {
   //       method: 'POST',
   //       headers:{'Content-Type': 'application/json'},
   //       body: JSON.stringify(requestObject)
   // };
   var requestOptions=requestObject.RequestHeader(addDocuments);
   services.addDocuments(requestOptions).then((res) => 
   {
      
      $("#loadingforsubmit").hide();
    
      //  setLoading(false);
       if(res.success)
       {
         Swal.fire({  
            position: 'center',  
            icon: 'success',  
            title: "",  
            text:res.message,
            showConfirmButton: true 
            
          }).then((result) => 
          {
               
                if(result.isConfirmed) 
                {
                  

                  if(navigationFrom=="fleetDocument"){
                     sessionStorage.setItem("naviationfrom", "fleetDocument");
                     navigate("/fleetowner/Vehicle");
                  }else{
                     sessionStorage.setItem("naviationfrom", "doc-tab");
                   navigate("/fleetowner/VehicleInformation/"+vehicleId);
                  }
                 
                }
          });

       }else
       {

         Swal.fire({  
            position: 'center',  
            icon: 'error',  
            title: "",  
            text:res.message,
            showConfirmButton: true 
            
          }).then((result) => 
          {
               
                if(result.isConfirmed) 
                {
                 
                
                }
          });

       }
     
        
      
   });
}

const createdDate=(field,date)=>
{

 field.onChange(date);
 setStartDate(date);
}


  return(
  <>
  {loadingComponent}
  {LoadingComponentForSubmit}
    <div class="main-panel">
               <div class="content-wrapper" style={{display: "block"}}>
                
                  {/* <div class="row mb-2">
                     <div class="col-lg-9 col-md-12 m-auto ">
                        <div class="card p-3">
                           <div class="banner-owner">
                              <div class="owner-logo">
                              <img  id="customerImage" />
                                 <div class="owner-name">
                                    <h4 >{vechileDetails.customerName?vechileDetails.customerName:""}</h4>
                                    <span>DOT Number: </span> <span>{vechileDetails?vechileDetails.dotNumber:""}</span>
                                 </div>
                              </div>
                              <div class="banner-contact-info bg-white d-flex flex-column flex-sm-row">
                                 <div class="row clearfix">
                                    <div class="col-md-4">
                                       <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                          <img src="/images/icons/map-icon.svg" alt="" class="mr-2"/>
                                          <div style={{minWidth: "240px"}}>
                                          <span >{vechileDetails.addressLine1?vechileDetails.addressLine1:""}</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-md-4">
                                       <div class="text-left d-flex align-items-center banner-info mb-1 mt-1"> 
                                          <img src="/images/icons/phone-icon.svg" alt="" class="mr-2"/>
                                          <span>{vechileDetails.customerNumber?vechileDetails.customerNumber:"N/A"}</span>
                                       </div>
                                    </div>
                                    <div class="col-md-4">
                                       <div class="text-left d-flex align-items-center banner-info mb-1 mt-1"> 
                                          <img src="/images/icons/message-icon.svg" alt="" class="mr-2"/>
                                          <span>{vechileDetails.customerEmail?vechileDetails.customerEmail:"N/A"}</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div> */}
                  <div class="row mb-2 ">
                   <div class="col-lg-9 col-md-12 m-auto ">
                      <VehicleBasicInfo customerId={cid}  />
                    </div>
                  </div>

                 <div class="row mb-2">
               
                  <div class="col-lg-9 col-md-12 m-auto">
                  <form onSubmit={handleSubmit(submitValue)}>
                     <div class="card">
                        <div class="p-3 ">
                           <h4>Add Document</h4>
                           
                           <span>Category :</span>
                           <div  class=" d-flex align-items-center">
                           {/* defaultChecked={true} */}
                                 <input type="radio" class="mr-2" value="inspections" name="others"  onClick={(event)=>onChangeValue(event.target.value)}/> Inspection
                                 <input type="radio" class="mr-2" value="others" name="others"  style={{"margin-left":"20px"}} onClick={(event)=>onChangeValue(event.target.value)}/> Others

                                {/* <input type="radio" class="mr-2" value={inspectionValue?inspectionValue:""} name="others"  onClick={onChangeValue}/> Inspection
                                 <input type="radio" class="mr-2" value={othersValue?othersValue:""} name="others"  style={{"margin-left":"20px"}} onClick={onChangeValue}/> Others  */}
                        </div>
                        </div>
                      
                        <div class="card-body pt-0 pb-6">
                           
                              <div>
                                 <table>
                                    <tbody>
                                    <tr>
                                       <td>Type<span class="star">*</span></td>
                                       <td>Title<span class="star">*</span></td>
                                       <td>Created Date<span class="star">*</span></td>
                                       <td>Expiry Date<span class="star">*</span></td>
                                       <td>Upload<span class="star">*</span></td>
                                    </tr>
                                    <tr class="mb-2 pb-2">
                                       <td class="pr-4 ">
                                       <div class="form-group mt-2">
                                          <select class="form-control custome-select" id="doucmenttype" onChange={e => setFieldName(e.target.value)}    {...register("Document", {
                                                required: "Type is required",
                                                 })}>
                                            <option value="">---Select---</option>
                                             {
                                                         docsCategorys?docsCategorys.sort((a, b) => a.name.localeCompare(b.name)).map((state, index) => {

                                                            if (state.name === "WET Test Certification") {

                                                               if (sessionStorage.getItem("vehicleTypeName") === "Trailer" && sessionStorage.getItem("tankerSubTypeName") === "Tanker") {
   
                                                                  return (<option value={state.id} >{state.name}</option>)
                                                               } else {
   
                                                               }
                                                            }
                                                            else if (state.name === "Tractor Preventive Maintenance/Inspection") {

                                                               if (sessionStorage.getItem("vehicleTypeName") === "Tractor") {
   
                                                                  return (<option value={state.id} >{state.name}</option>)
                                                               } else {
   
                                                               }
                                                            }
                                                            else if (state.name === "Driver Vehicle Inspection") {

                                                               if (sessionStorage.getItem("vehicleTypeName") === "Tractor") {
   
                                                                  return (<option value={state.id} >{state.name}</option>)
                                                               } else {
   
                                                               }
                                                            } else {
                                                               return (<option value={state.id} >{state.name}</option>)
                                                            }
                                                            // <option value={state.id}>{state.name}</option>

                                             }): <option value=""></option>
                                             }
                                             </select>
                                             <ErrorMessage
                                                      errors={errors}
                                                      name="Document"
                                                      class="invalid-feedback"
                                                      render={({ messages }) => {
                                                     
                                                      return messages
                                                         ? Object.entries(messages).map(([type, message]) => (
                                                            <p key={type}  class="error">{message}</p>
                                                         ))
                                                         : null;
                                                      }}></ErrorMessage>
                                                </div>
                                       </td>
                                       <td class="pr-4 ">
                                          <div class="form-group mt-2">
                                             <input type="text" class="form-control custome-input" id="documentTitle" placeholder="" aria-describedby="emailHelp" {...register('documentTitle', { required: true })} maxLength="50"/>
                                             {errors.documentTitle && errors.documentTitle.type === "required" && <span class="error">Title is required</span>}
                                          </div>
                                       </td>
                                       <td class="pr-4 ">
                                       <div class="form-group mt-2">
                                            {/* <DatePicker   dateFormat="MM/dd/yyyy" selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                                             {/* <input type="text" class="form-control custome-input" id="createddate" placeholder="Enter Doc Name" aria-describedby="emailHelp"/> */}
                                             <Controller
                                                   name="created_date"
                                                   control={control}
                                                   rules={{ required: true }}
                                                   render={({ field }) => (
                                                      <DatePicker
                                                            onChange={(date) => createdDate(field,date)}
                                                            selected={field.value}
                                                            inputProps={{
                                                               placeholder: "",
                                                            }}
                                                            id="created_date"
                                                      />
                                                   )}
                                                />
                                             {errors.created_date && errors.created_date.type === "required" && <span class="error">Created date is required</span>}
                                          </div>
                                       </td>
                                       <td class="pr-4 ">
                                       <div class="form-group mt-2">
                                           {/* <DatePicker selected={endDate} dateFormat="MM/dd/yyyy" onChange={(date) => setEndDate(date)} class="form-control" /> */}
                                             {/* <input type="text" class="form-control custome-input" id="expiredate" placeholder="Enter Doc Name" aria-describedby="emailHelp"/> */}
                                             <Controller
                                                   name="expiryDate"
                                                   control={control}
                                                   rules={{ required: true }}
                                                   render={({ field }) => (
                                                      <DatePicker
                                                           minDate={moment(startDate).toDate()}
                                                            onChange={(date) => field.onChange(date)}
                                                            selected={field.value}
                                                            inputProps={{
                                                               placeholder: "",
                                                            }}
                                                            id="expiryDate"
                                                      />
                                                   )}
                                                />
                                                {errors.expiryDate && errors.expiryDate.type === "required" && <span class="error">Expiry date is required</span>}
                                          </div>
                                       </td>
                                       <td class="pr-4 ">
                                       <div class="form-group mt-2">
                                          {/* <div className="upload-area">
       
                                          </div> */}
                                          <div class="image-upload" style={{height:"40px"}}> 
                                             <label class="mb-0 tt mr-3" for="file-input">
                                                <img src="/images/icons/upload.svg" style={{"pointer-events": "none","margin-bottom":"0px"}} />
                                             </label>
                                             {/* { fileName && <p className="filename">{fileName.name}</p> } */}
                                          <input type="file" name="filetobase64" id="file-input" onChange={onUploadFileChange} accept="image/jpeg,image/png,image/bmp,application/pdf,application/msword,application/word,application/msword" />
                                             {/* <input id="file-input" type="file" /> */}
                                          </div>
                                          {/* <input type="file" name="file" onClick={(event) => handleUploadFile(event)}  accept=".pdf"/> */}
                                             {/* <a href="#" class="mr-2" type="file" name="file"><img src="images/icons/upload.svg" alt="upload" onClick={(event) => handleUploadFile(event)} /></a> */}
                                          </div>
                                       </td>
                                    </tr>
                                    { fileName? <tr>
                                      <td colSpan={6}>
                                            <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                <img src="" class="mr-2"/>
                                                <span>{ fileName && <p  className="filename mb-0">
                                                   {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>  */}
                                                   {(() => {
                                                                           if(fileName.name.split('.').pop() == 'pdf') {
                                                                              return (
                                                                                <img style={{ width:"42px !important"}} src="/images/icons/pdf-icon.svg" class="mr-2 w-42" />
                                                                              )
                                                                          } else if(fileName.name.split('.').pop() == 'jpg' || fileName.name.split('.').pop() == 'png' || fileName.name.split('.').pop() == 'jpeg' || fileName.name.split('.').pop() == 'bmp') {
                                                                              
                                                                            return (
                                                                                    <img style={{ width:"42px !important"}} src="/images/icons/image-icon.svg" class="mr-2 w-42" />
                                                                                )
                                                                            } else if(fileName.name.split('.').pop() == 'doc' || fileName.name.split('.').pop() == 'word' || fileName.name.split('.').pop() == 'docx' || fileName.name.split('.').pop() == 'dot' || fileName.name.split('.').pop() == 'dotm' || fileName.name.split('.').pop() == 'dotx') {

                                                                                return (
                                                                                    <img style={{ width:"42px !important"}} src="/images/icons/word-icon.svg" class="mr-2 w-42" />
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    // <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                    <img src="/images/icons/upload.svg" alt="" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                   {fileName.name} </p> }</span>
                                             </div>
                                      </td>
                                   </tr>:""}
                                  
                                  
                                 </tbody></table>
                              </div>
                              <div class="col-md-6 text-left mb-3">
                                                <div class="instruction">
                                                    <span>Instruction</span>
                                                    <p>Max File Size : 50MB</p>
                                                    {/* <p>Formats: JPG/PNG/BMP</p> */}
                                                    <p>Formats: JPG/PNG/PDF</p>
                                                 
                                                </div>
                              </div>
                         
                        </div>
                        <div class="border-top ">
                           <div class="white-color p-3 d-flex">
                           <button type="submit" class="btn btn-primary mw-120 mr-2">SUBMIT</button>
                              <a  onClick={() => redirect("/fleetowner/VehicleInformation/"+vehicleId)} class="btn gray-mid-color mw-120">CANCEL</a>
                           </div> 
                        </div>
                     </div>
                    
                     </form>
                  </div>
               
               </div>

              

               </div>
          

            </div>
  </>
)};

FleetOwnerAddDocument.propTypes = {};

FleetOwnerAddDocument.defaultProps = {};

export default FleetOwnerAddDocument;
