import services from "../../../../services/services";
import { requestObject } from "../../../../utility/requestObject";

const UpcomingSearch = (
  customerId,
  pageNumber,
  ITEMS_PER_PAGE,
  title,
  vehicleTypeId,
  pageSize,
  setTotalItems,
  settotalPages,
  setCurrentPage,
  setupcoming,
  setdisplayPage,
  sortBy,
  sortDir,
  displayItems
) => {
  /////////////get vechile list//////////////////////////

  var vechileReq = "";
  if (sortBy === "lisencePlateNumber") {
    sortBy = "lisencePlateNumber";
  }else if(sortBy==="VIN Number")
  {

   sortBy="vinNumber";
  }else if(sortBy==="Unit Number")
  {

   sortBy="unitNumber";
  } else if (sortBy === "documentSubCategoryName") {
    sortBy = "docSubCategory";
  }
  if (customerId == "") {
    vechileReq = {
      vehicleTypeId: vehicleTypeId,
      title: title,
      pageNumber: pageNumber,
      pageSize: pageSize,
      sortBy: sortBy,
      sortDir: sortDir,
      "isActive":true
    };
  } else {
    vechileReq = {
      vehicleTypeId: vehicleTypeId,
      title: title,
      pageNumber: pageNumber,
      pageSize: pageSize,
      sortBy: sortBy,
      sortDir: sortDir,
      "isActive":true
    };
  }

  console.log("results=" + JSON.stringify(vechileReq));

  // const requestOptions =
  // {
  //       method: 'POST',
  //       headers:{'Content-Type': 'application/json'},
  //       body: JSON.stringify(vechileReq)
  // };
  var requestOptions = requestObject.RequestHeader(vechileReq);
  services.upcomingremiders(requestOptions).then((res) => {
    if (res.success) {
      setTotalItems(res.customValues.totalNumberOfRecords);

      settotalPages(
        Math.ceil(res.customValues.totalNumberOfPages / ITEMS_PER_PAGE)
      );
      setCurrentPage(res.customValues.currentPageNumber);
      if (res.rows.length > 0) {
        setupcoming(res.rows);
      } else {
        setupcoming("");
      }

      setTotalItems(res.customValues.totalNumberOfRecords);

      var displaypages = [];
      if (displayItems == "displayItems") {
        for (
          var i = 1;
          i <=
          parseInt(
            Math.ceil(res.customValues.totalNumberOfRecords / ITEMS_PER_PAGE)
          );
          i++
        ) {
          displaypages.push(i * ITEMS_PER_PAGE);
        }

        setdisplayPage(displaypages);
      }
    } else {
      setupcoming("");
      // setdisplayPage(0);
      //alert(res.message);
    }
  });
};

export default UpcomingSearch;
