import React, { history, useEffect, useState, useMemo } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { requestObject } from "../../../../utility/requestObject";
import $ from "jquery";
import services from "../../../../services/services";
import { useLoading } from 'react-hook-loading';
import DatePicker from "react-datepicker";
import Adddocs from "../../../../utility/Adddocs";
import CheckAuthentication from "../../../../utility/checkAuthentication";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { scrollTop } from "../../../../utility/ScrollTop";
const AnnualVehicle2Edit = () => {

    const { fkAccountId, inspectionId,vid } = useParams();
    const [inputList, setInputList] = useState([{ }]);
    const [base64file, setBase64file] = useState('');
    const [loading, setLoading] = useLoading("Please wait...");
    const [ fileName, setfileName ] = useState("");
    const [ inspectionDetails, setInspectionDetails ] = useState("");
    const [ documentList, setDocumentLists ] = useState([]);
    const navigate = useNavigate();
    const [endDate, setEndDate] = useState(new Date());
    const [designpressure, setDesignPressure] = useState();
    const [certify, setApproveCertify] = useState();
    var documentLists=[];

   const [FifthDate, setFifthDate] = useState(null);
   const [PintleDate, setPintleDate] = useState(null);
   const [EyeDate, setEyeDate] = useState(null);
   const [TongueDate, setTongueDate] = useState(null);
   const [SafetyDate, setSafetyDate] = useState(null);
   const [SaddleDate, setSaddleDate] = useState(null);
   const [lowPressureDate, setLowPressureDate] = useState(null);
   const [tractorProtectionDate, setTractorProtectionDate] = useState(null);


   useEffect(() => 
   {
      if(CheckAuthentication) 
      {
            $("input[name='Fifth_repaired']").attr('disabled', 'true');
            $("input[name='FifthDate']").hide();
            $("input[name='FifthDateTemp']").attr('disabled', 'true');

            $("input[name='Pintle_repaired']").attr('disabled', 'true');
            $("input[name='PintleDate']").hide();
            $("input[name='PintleDateTemp']").attr('disabled', 'true');

            $("input[name='Eye_repaired']").attr('disabled', 'true');
            $("input[name='EyeDate']").hide();
            $("input[name='EyeDateTemp']").attr('disabled', 'true');

            $("input[name='Tongue_repaired']").attr('disabled', 'true');
            $("input[name='TongueDate']").hide();
            $("input[name='TongueDateTemp']").attr('disabled', 'true');

            $("input[name='Safety_repaired']").attr('disabled', 'true');
            $("input[name='SafetyDate']").hide();
            $("input[name='SafetyDateTemp']").attr('disabled', 'true');

            $("input[name='Saddle_repaired']").attr('disabled', 'true');
            $("input[name='SaddleDate']").hide();
            $("input[name='SaddleDateTemp']").attr('disabled', 'true');

            $("input[name='lowPressure_repaired']").attr('disabled', 'true');
            $("input[name='lowPressureDate']").hide();
            $("input[name='lowPressureDateTemp']").attr('disabled', 'true');

            $("input[name='tractorProtection_repaired']").attr('disabled', 'true');
            $("input[name='tractorProtectionDate']").hide();
            $("input[name='tractorProtectionDateTemp']").attr('disabled', 'true');
            scrollTop();
            getSection2();
            getInpection(fkAccountId,inspectionId);
            
      }
      else{
         sessionStorage.clear();
         localStorage.clear();
        //navigate("/login");
          window.location.href="/login";
      }
   }, []);

   function getInpection(fkAccountId,inspectionId)
   {
       var requestObjectForWet=
       {
       
         "id":inspectionId
       }
 
       var requestOptions=requestObject.RequestHeader(requestObjectForWet);
 
       services.getInspectionDetails(requestOptions).then((res) => 
       {

         const workOrderNumber=sessionStorage.getItem("workOrderNumber");
         if(workOrderNumber==null)
         {
         $("#workOrderNumber").val(res.rows?res.rows.workOrderNumber:"")
         }else{
         sessionStorage.setItem("workOrderNumber",workOrderNumber);
         }
         
          setInspectionDetails(res.rows);
          console.log("Annual vehicle inspection"+JSON.stringify(res.rows));
 
        var  obj=res.rows.annualVehicleInspection.couplingDevices;

        var FifthNF = obj == null ? '' : obj.FifthNF;
        var fifthWheels = obj == null ? '' : obj.fifthWheels;
        var fifthWheelsDate = obj == null ? '' : obj.fifthWheelsDate;
        $('input[name="Fifth_na"][value="' + FifthNF + '"]').trigger("click");
        $('input[name="Fifth_repaired"][value="' + fifthWheels + '"]').trigger("click").prop('checked', true);
  
  
        var PintleNF = obj == null ? '' : obj.PintleNF;
        var Pintle = obj == null ? '' : obj.Pintle;
        var PintleDate = obj == null ? '' : obj.PintleDate;
  
        $('input[name="Pintle_na"][value="' + PintleNF + '"]').trigger("click");
        $('input[name="Pintle_repaired"][value="' + Pintle + '"]').trigger("click").prop('checked', true);
  
  
  
        var EyeNF = obj == null ? '' : obj.EyeNF;
        var Eye = obj == null ? '' : obj.Eye;
        var EyeDate = obj == null ? '' : obj.EyeDate;
  
        $('input[name="Eye_na"][value="' + EyeNF + '"]').trigger("click");
        $('input[name="Eye_repaired"][value="' + Eye + '"]').trigger("click").prop('checked', true);
  
  
        var TongueNF = obj == null ? '' : obj.TongueNF;
        var Tongue = obj == null ? '' : obj.Tongue;
        var TongueDate = obj == null ? '' : obj.TongueDate;
  
        $('input[name="Tongue_na"][value="' + TongueNF + '"]').trigger("click");
        $('input[name="Tongue_repaired"][value="' + Tongue + '"]').trigger("click").prop('checked', true);
  
  
        var SafetyNF = obj == null ? '' : obj.SafetyNF;
        var Safety = obj == null ? '' : obj.Safety;
        var SafetyDate = obj == null ? '' : obj.SafetyDate;
  
        $('input[name="Safety_na"][value="' + SafetyNF + '"]').trigger("click");
        $('input[name="Safety_repaired"][value="' + Safety + '"]').trigger("click").prop('checked', true);
  
  
  
        var SaddleNF = obj == null ? '' : obj.SaddleNF;
        var Saddle = obj == null ? '' : obj.Saddle;
        var SaddleDate = obj == null ? '' : obj.SaddleDate;
  
        $('input[name="Saddle_na"][value="' + SaddleNF + '"]').trigger("click");
        $('input[name="Saddle_repaired"][value="' + Saddle + '"]').trigger("click").prop('checked', true);
  
  
        var lowPressureNF = obj == null ? '' : obj.lowPressureNF;
        var lowPressure = obj == null ? '' : obj.lowPressure;
        var lowPressureDate = obj == null ? '' : obj.lowPressureDate;
        $('input[name="lowPressure_na"][value="' + lowPressureNF + '"]').trigger("click");
        $('input[name="lowPressure_repaired"][value="' + lowPressure + '"]').trigger("click").prop('checked', true);
  
  
        var tractorProtectionNF = obj == null ? '' : obj.tractorProtectionNF;
        var tractorProtection = obj == null ? '' : obj.tractorProtection;
        var tractorProtectionDate = obj == null ? '' : obj.tractorProtectionDate;
  
        $('input[name="tractorProtection_na"][value="' + tractorProtectionNF + '"]').trigger("click");
        $('input[name="tractorProtection_repaired"][value="' + tractorProtection + '"]').trigger("click").prop('checked', true);
        
        $("#FifthDate").val(fifthWheelsDate);
        $("#PintleDate").val(PintleDate);
        $("#EyeDate").val(EyeDate);
        $("#TongueDate").val(TongueDate);
        $("#SafetyDate").val(SafetyDate);
        $("#SaddleDate").val(SaddleDate);
        $("#lowPressureDate").val(lowPressureDate);
        $("#tractorProtectionDate").val(tractorProtectionDate);

        if (FifthNF||PintleNF||EyeNF||TongueNF||SafetyNF||SaddleNF||lowPressureNF||tractorProtectionNF=="verified") {
           checkVerified();
           
           setFifthDate(fifthWheelsDate?new Date(fifthWheelsDate):"");
           setPintleDate(PintleDate?new Date(PintleDate):"");
           setEyeDate(EyeDate?new Date(EyeDate):"");
           setTongueDate(TongueDate?new Date(TongueDate):"");
           setSafetyDate(SafetyDate?new Date(SafetyDate):"");
           setSaddleDate(SaddleDate?new Date(SaddleDate):"");
         //   setLowPressureDate(serviceBrakeDate?lowPressureDate);
         //   setTractorProtectionDate(serviceBrakeDate?tractorProtectionDate);
         
        }
      //   sectionSubmit();
       });
      //  handleSubmit();
    }

   function getSection2() {

      JSON.parse(sessionStorage.getItem("section2Coupling"));

      console.log("Coupling" + JSON.parse(sessionStorage.getItem("section2Coupling")));

      var obj = JSON.parse(sessionStorage.getItem("section2Coupling"))




      var FifthNF = obj == null ? '' : obj.FifthNF;
      var fifthWheels = obj == null ? '' : obj.fifthWheels;
      var fifthWheelsDate = obj == null ? '' : obj.fifthWheelsDate;
      $('input[name="Fifth_na"][value="' + FifthNF + '"]').trigger("click");
      $('input[name="Fifth_repaired"][value="' + fifthWheels + '"]').trigger("click").prop('checked', true);


      var PintleNF = obj == null ? '' : obj.PintleNF;
      var Pintle = obj == null ? '' : obj.Pintle;
      var PintleDate = obj == null ? '' : obj.PintleDate;

      $('input[name="Pintle_na"][value="' + PintleNF + '"]').trigger("click");
      $('input[name="Pintle_repaired"][value="' + Pintle + '"]').trigger("click").prop('checked', true);



      var EyeNF = obj == null ? '' : obj.EyeNF;
      var Eye = obj == null ? '' : obj.Eye;
      var EyeDate = obj == null ? '' : obj.EyeDate;

      $('input[name="Eye_na"][value="' + EyeNF + '"]').trigger("click");
      $('input[name="Eye_repaired"][value="' + Eye + '"]').trigger("click").prop('checked', true);


      var TongueNF = obj == null ? '' : obj.TongueNF;
      var Tongue = obj == null ? '' : obj.Tongue;
      var TongueDate = obj == null ? '' : obj.TongueDate;

      $('input[name="Tongue_na"][value="' + TongueNF + '"]').trigger("click");
      $('input[name="Tongue_repaired"][value="' + Tongue + '"]').trigger("click").prop('checked', true);


      var SafetyNF = obj == null ? '' : obj.SafetyNF;
      var Safety = obj == null ? '' : obj.Safety;
      var SafetyDate = obj == null ? '' : obj.SafetyDate;

      $('input[name="Safety_na"][value="' + SafetyNF + '"]').trigger("click");
      $('input[name="Safety_repaired"][value="' + Safety + '"]').trigger("click").prop('checked', true);



      var SaddleNF = obj == null ? '' : obj.SaddleNF;
      var Saddle = obj == null ? '' : obj.Saddle;
      var SaddleDate = obj == null ? '' : obj.SaddleDate;

      $('input[name="Saddle_na"][value="' + SaddleNF + '"]').trigger("click");
      $('input[name="Saddle_repaired"][value="' + Saddle + '"]').trigger("click").prop('checked', true);


      var lowPressureNF = obj == null ? '' : obj.lowPressureNF;
      var lowPressure = obj == null ? '' : obj.lowPressure;
      var lowPressureDate = obj == null ? '' : obj.lowPressureDate;
      $('input[name="lowPressure_na"][value="' + lowPressureNF + '"]').trigger("click");
      $('input[name="lowPressure_repaired"][value="' + lowPressure + '"]').trigger("click").prop('checked', true);


      var tractorProtectionNF = obj == null ? '' : obj.tractorProtectionNF;
      var tractorProtection = obj == null ? '' : obj.tractorProtection;
      var tractorProtectionDate = obj == null ? '' : obj.tractorProtectionDate;

      $('input[name="tractorProtection_na"][value="' + tractorProtectionNF + '"]').trigger("click");
      $('input[name="tractorProtection_repaired"][value="' + tractorProtection + '"]').trigger("click").prop('checked', true);

      if (FifthNF||PintleNF||EyeNF||TongueNF||SafetyNF||SaddleNF||lowPressureNF||tractorProtectionNF=="verified") {
         checkVerified();
         $("#FifthDate").val(fifthWheelsDate);
         $("#PintleDate").val(PintleDate);
         $("#EyeDate").val(EyeDate);
         $("#TongueDate").val(TongueDate);
         $("#SafetyDate").val(SafetyDate);
         $("#SaddleDate").val(SaddleDate);
         $("#lowPressureDate").val(lowPressureDate);
         $("#tractorProtectionDate").val(tractorProtectionDate);
         // setFifthDate(FifthDate);
         // setPintleDate(PintleDate);
         // setEyeDate(EyeDate);
         // setTongueDate(TongueDate);
         // setSafetyDate(SafetyDate);
         // setSaddleDate(SaddleDate);
         // setLowPressureDate(lowPressureDate);
         // setTractorProtectionDate(tractorProtectionDate);
       
      }


   }


   const checkVerified = () => {

      //       Fifth

      var FifthValue = $("input[name='Fifth_na']:checked").val();

      if (FifthValue == 'na') {
         $("input[name='Fifth_repaired']").attr('disabled', 'true');
         $("input[name='FifthDateTemp']").show();
         $("input[name='FifthDateTemp']").attr('disabled', 'true');
         $("input[name='FifthDate']").hide();

         // =================@@@@@@@@@@@@@@@@===============
         $("input[name='Fifth_repaired']").prop("checked", false);
         setFifthDate('');

      } if (FifthValue == 'verified') {

         $("input[name='Fifth_repaired']").removeAttr('disabled');
         $("input[name='FifthDateTemp']").removeAttr('disabled');

         if ($("input[name='Fifth_repaired']").is(':checked')) {
            $("input[name='FifthDate']").show();
            $("input[name='FifthDateTemp']").hide();
         } else {
            $("input[name='FifthDate']").hide();
            $("input[name='FifthDateTemp']").show();
         }

      }

      // Pintle

      var PintleValue = $("input[name='Pintle_na']:checked").val();


      if (PintleValue == 'na') {
         $("input[name='Pintle_repaired']").attr('disabled', 'true');
         $("input[name='PintleDateTemp']").show();
         $("input[name='PintleDateTemp']").attr('disabled', 'true');
         $("input[name='PintleDate']").hide();
         // =================@@@@@@@@@@@@@@@@===============
         $("input[name='Pintle_repaired']").prop("checked", false);
         setPintleDate('');

      } if (PintleValue == 'verified') {

         $("input[name='Pintle_repaired']").removeAttr('disabled');
         $("input[name='PintleDateTemp']").removeAttr('disabled');

         if ($("input[name='Pintle_repaired']").is(':checked')) {
            $("input[name='PintleDate']").show();
            $("input[name='PintleDateTemp']").hide();
         } else {
            $("input[name='PintleDate']").hide();
            $("input[name='PintleDateTemp']").show();
         }

      }

      // Eye


      var EyeValue = $("input[name='Eye_na']:checked").val();


      if (EyeValue == 'na') {
         $("input[name='Eye_repaired']").attr('disabled', 'true');
         $("input[name='EyeDateTemp']").show();
         $("input[name='EyeDateTemp']").attr('disabled', 'true');
         $("input[name='EyeDate']").hide();
         // =================@@@@@@@@@@@@@@@@===============
         $("input[name='Eye_repaired']").prop("checked", false);
         setEyeDate('');

      } if (EyeValue == 'verified') {

         $("input[name='Eye_repaired']").removeAttr('disabled');
         $("input[name='EyeDateTemp']").removeAttr('disabled');

         if ($("input[name='Eye_repaired']").is(':checked')) {
            $("input[name='EyeDate']").show();
            $("input[name='EyeDateTemp']").hide();
         } else {
            $("input[name='EyeDate']").hide();
            $("input[name='EyeDateTemp']").show();
         }

      }
      // Tongue

      var TongueValue = $("input[name='Tongue_na']:checked").val();

      if (TongueValue == 'na') {
         $("input[name='Tongue_repaired']").attr('disabled', 'true');
         $("input[name='TongueDateTemp']").show();
         $("input[name='TongueDateTemp']").attr('disabled', 'true');
         $("input[name='TongueDate']").hide();
         // =================@@@@@@@@@@@@@@@@===============
         $("input[name='Tongue_repaired']").prop("checked", false);
         setTongueDate('');

      } if (TongueValue == 'verified') {

         $("input[name='Tongue_repaired']").removeAttr('disabled');
         $("input[name='TongueDateTemp']").removeAttr('disabled');

         if ($("input[name='Tongue_repaired']").is(':checked')) {
            $("input[name='TongueDate']").show();
            $("input[name='TongueDateTemp']").hide();
         } else {
            $("input[name='TongueDate']").hide();
            $("input[name='TongueDateTemp']").show();
         }

      }

      // Safety

      var SafetyValue = $("input[name='Safety_na']:checked").val();

      if (SafetyValue == 'na') {
         $("input[name='Safety_repaired']").attr('disabled', 'true');
         $("input[name='SafetyDateTemp']").show();
         $("input[name='SafetyDateTemp']").attr('disabled', 'true');
         $("input[name='SafetyDate']").hide();
         // =================@@@@@@@@@@@@@@@@===============
         $("input[name='Safety_repaired']").prop("checked", false);
         setSafetyDate('');

      } if (SafetyValue == 'verified') {

         $("input[name='Safety_repaired']").removeAttr('disabled');
         $("input[name='SafetyDateTemp']").removeAttr('disabled');

         if ($("input[name='Safety_repaired']").is(':checked')) {
            $("input[name='SafetyDate']").show();
            $("input[name='SafetyDateTemp']").hide();
         } else {
            $("input[name='SafetyDate']").hide();
            $("input[name='SafetyDateTemp']").show();
         }

      }

      // Saddle

      var SaddleValue = $("input[name='Saddle_na']:checked").val();

      if (SaddleValue == 'na') {
         $("input[name='Saddle_repaired']").attr('disabled', 'true');
         $("input[name='SaddleDateTemp']").show();
         $("input[name='SaddleDateTemp']").attr('disabled', 'true');
         $("input[name='SaddleDate']").hide();
         // =================@@@@@@@@@@@@@@@@===============
         $("input[name='Saddle_repaired']").prop("checked", false);
         setSaddleDate('');
      } if (SaddleValue == 'verified') {

         $("input[name='Saddle_repaired']").removeAttr('disabled');
         $("input[name='SaddleDateTemp']").removeAttr('disabled');

         if ($("input[name='Saddle_repaired']").is(':checked')) {
            $("input[name='SaddleDate']").show();
            $("input[name='SaddleDateTemp']").hide();
         } else {
            $("input[name='SaddleDate']").hide();
            $("input[name='SaddleDateTemp']").show();
         }

      }
      // lowPressure

      var lowPressureValue = $("input[name='lowPressure_na']:checked").val();

      if (lowPressureValue == 'na') {
         $("input[name='lowPressure_repaired']").attr('disabled', 'true');
         $("input[name='lowPressureDateTemp']").show();
         $("input[name='lowPressureDateTemp']").attr('disabled', 'true');
         $("input[name='lowPressureDate']").hide();
         // =================@@@@@@@@@@@@@@@@===============
         $("input[name='lowPressure_repaired']").prop("checked", false);
         setLowPressureDate('');
      } if (lowPressureValue == 'verified') {
     
         $("input[name='lowPressure_repaired']").removeAttr('disabled');
         $("input[name='lowPressureDateTemp']").removeAttr('disabled');

         if ($("input[name='lowPressure_repaired']").is(':checked')) {
            $("input[name='lowPressureDate']").show();
            $("input[name='lowPressureDateTemp']").hide();
         } else {
            $("input[name='lowPressureDate']").hide();
            $("input[name='lowPressureDateTemp']").show();
         }

      }

      // tractorProtection
      var tractorProtectionValue = $("input[name='tractorProtection_na']:checked").val();

      if (tractorProtectionValue == 'na') {
         $("input[name='tractorProtection_repaired']").attr('disabled', 'true');
         $("input[name='tractorProtectionDateTemp']").show();
         $("input[name='tractorProtectionDateTemp']").attr('disabled', 'true');
         $("input[name='tractorProtectionDate']").hide();
         // =================@@@@@@@@@@@@@@@@===============
         $("input[name='tractorProtection_repaired']").prop("checked", false);
         setTractorProtectionDate('');
      } if (tractorProtectionValue == 'verified') {

         $("input[name='tractorProtection_repaired']").removeAttr('disabled');
         $("input[name='tractorProtectionDateTemp']").removeAttr('disabled');

         if ($("input[name='tractorProtection_repaired']").is(':checked')) {
            $("input[name='tractorProtectionDate']").show();
            $("input[name='tractorProtectionDateTemp']").hide();
         } else {
            $("input[name='tractorProtectionDate']").hide();
            $("input[name='tractorProtectionDateTemp']").show();
         }

      }

     



   }

   //    $(document).ready(function () {


   //    $('#annualVehicle3').on('click', function () {



   //    });
   // });

   const handleSubmit = () => {
      // alert("sectin3")

      var section2Coupling = {
         "FifthNF": $("input[name='Fifth_na']:checked").val() === "na" ? "na" : "verified",
         "fifthWheels": $("input[name='Fifth_repaired']:checked").val() === "1" ? "1" : "0",
         "fifthWheelsDate": $("input[name='FifthDate']").val(),

         "PintleNF": $("input[name='Pintle_na']:checked").val() === "na" ? "na" : "verified",
         "Pintle": $("input[name='Pintle_repaired']:checked").val() === "1" ? "1" : "0",
         "PintleDate": $("input[name='PintleDate']").val(),

         "EyeNF": $("input[name='Eye_na']:checked").val() === "na" ? "na" : "verified",
         "Eye": $("input[name='Eye_repaired']:checked").val() === "1" ? "1" : "0",
         "EyeDate": $("input[name='EyeDate']").val(),

         "TongueNF": $("input[name='Tongue_na']:checked").val() === "na" ? "na" : "verified",
         "Tongue": $("input[name='Tongue_repaired']:checked").val() === "1" ? "1" : "0",
         "TongueDate": $("input[name='TongueDate']").val(),

         "SafetyNF": $("input[name='Safety_na']:checked").val() === "na" ? "na" : "verified",
         "Safety": $("input[name='Safety_repaired']:checked").val() === "1" ? "1" : "0",
         "SafetyDate": $("input[name='SafetyDate']").val(),

         "SaddleNF": $("input[name='Saddle_na']:checked").val() === "na" ? "na" : "verified",
         "Saddle": $("input[name='Saddle_repaired']:checked").val() === "1" ? "1" : "0",
         "SaddleDate": $("input[name='SaddleDate']").val(),

         "lowPressureNF": $("input[name='lowPressure_na']:checked").val() === "na" ? "na" : "verified",
         "lowPressure": $("input[name='lowPressure_repaired']:checked").val() === "1" ? "1" : "0",
         "lowPressureDate": $("input[name='lowPressureDate']").val(),

         "tractorProtectionNF": $("input[name='tractorProtection_na']:checked").val() === "na" ? "na" : "verified",
         "tractorProtection": $("input[name='tractorProtection_repaired']:checked").val() === "1" ? "1" : "0",
         "tractorProtectionDate": $("input[name='tractorProtectionDate']").val(),
      };
      console.log(JSON.stringify(section2Coupling));

      sessionStorage.setItem("section2Coupling", JSON.stringify(section2Coupling));

      let form_is_valid = true;

      var Fifth_na = $("input[name='Fifth_na']:checked").val();
      var Pintle_na = $("input[name='Pintle_na']:checked").val();
      var Eye_na = $("input[name='Eye_na']:checked").val();
      var Tongue_na = $("input[name='Tongue_na']:checked").val();
      var Safety_na = $("input[name='Safety_na']:checked").val();
      var Saddle_na = $("input[name='Saddle_na']:checked").val();
      var Saddle_na = $("input[name='Saddle_na']:checked").val();
      var lowPressure_na = $("input[name='lowPressure_na']:checked").val();
      var tractorProtection_na = $("input[name='tractorProtection_na']:checked").val();


      if (Fifth_na == undefined || Pintle_na == undefined || Eye_na == undefined || Tongue_na == undefined || Safety_na == undefined || Saddle_na == undefined || Saddle_na == undefined  ) {

         $('#error_message').css("color", "#d33232").html("*Please choose one option for each question");
         form_is_valid = false;

      } else {
         $('#error_message').html('');
      }

      if (form_is_valid == true) {
         $("#annualVehicle3").trigger('click');
      }

      return form_is_valid;
   }

   return (
      <>

         <div class="row mb-2">
            <div class="col-lg-12 col-md-12 m-auto ">
               <div class="card">

                  {/* <div class="divider"></div> */}

                  {/* <div class="divider"></div> */}
                  <div class="row p-3">
                     <div class="w-100">
                        <h4 class="form-inspection-title text-center">ANNUAL VEHICLE COMPONENTS INSPECTED</h4>
                     </div>
                     <div class="w-100">
                     <WorkOrderNumberDiv />
                        <div class="inspection-wrap mb-4">
                           <div class="step-wrap p-3 mb-3">
                              <div class="step-main d-flex">
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt="" /></div>
                                    <div class="labl-section">Brake</div>
                                 </div>
                                 <div class="step-itm mr-3 ">
                                    <div class="no-cl mr-2 current-tick">2</div>
                                    <div class="labl-section">Coupling </div>
                                 </div>
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2">3</div>
                                    <div class="labl-section">Exhaust-Fuel-Lighting-Safe</div>
                                 </div>
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2">4</div>
                                    <div class="labl-section">Steering-Suspension</div>
                                 </div>
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2">5</div>
                                    <div class="labl-section">Wheels-Glazing</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="row mb-4">
                           <div class="col-lg-12">
                              <h5 class="w-100 text-uppercase p-3 mb-0" style={{ backgroundColor: "#e5e5e5" }}>COUPLING DEVICES</h5>
                              <div className="border mb-3">
                                 <table>
                                    <tbody>
                                       <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                          <th>Type</th>
                                          <th>NA</th>
                                          <th>Verified OK</th>
                                          <th>Repaired</th>
                                          <th>Date</th>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>a. Fifth wheels</td>
                                          <td ><input type="radio" name="Fifth_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="Fifth_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="Fifth_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={FifthDate} onChange={(date) => setFifthDate(date)} name="FifthDate" class="form-control" id="FifthDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="FifthDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>b. Pintle Hooks</td>
                                          <td ><input type="radio" name="Pintle_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="Pintle_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="Pintle_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={PintleDate} onChange={(date) => setPintleDate(date)} name="PintleDate" class="form-control" id="PintleDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="PintleDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>c. Drawbar/Towbar Eye</td>
                                          <td ><input type="radio" name="Eye_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="Eye_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="Eye_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={EyeDate} onChange={(date) => setEyeDate(date)} name="EyeDate" class="form-control" id="EyeDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="EyeDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>d. Drawbar/Towbar Tongue</td>
                                          <td ><input type="radio" name="Tongue_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="Tongue_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="Tongue_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={TongueDate} onChange={(date) => setTongueDate(date)} name="TongueDate" class="form-control" id="TongueDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="TongueDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>e. Safety Devices</td>
                                          <td ><input type="radio" name="Safety_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="Safety_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="Safety_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={SafetyDate} onChange={(date) => setSafetyDate(date)} name="SafetyDate" class="form-control" id="SafetyDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="SafetyDateTemp" /></td>
                                       </tr>

                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>f. Saddle-Mounts</td>
                                          <td ><input type="radio" name="Saddle_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="Saddle_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><input type="checkbox" name="Saddle_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={SaddleDate} onChange={(date) => setSaddleDate(date)} name="SaddleDate" class="form-control" id="SaddleDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="SaddleDateTemp" /></td>
                                       </tr>

                                       {/* <tr className="annuaul-tb-5cl p-2">
                                          <td>g. Low Pressure Warning Device</td>
                                          <td ><input type="radio" name="lowPressure_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="lowPressure_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="lowPressure_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={lowPressureDate} onChange={(date) => setLowPressureDate(date)} name="lowPressureDate" class="form-control" id="lowPressureDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="lowPressureDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>h. Tractor Protection Valve</td>
                                          <td ><input type="radio" name="tractorProtection_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="tractorProtection_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="checkbox" name="tractorProtection_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={tractorProtectionDate} onChange={(date) => setTractorProtectionDate(date)} name="tractorProtectionDate"  class="form-control" id="tractorProtectionDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="tractorProtectionDateTemp" /></td>
                                       </tr> */}
                                    </tbody>
                                 </table>
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="divider2"></div>
                  <div class="row p-3 pr-4 d-flex justify-content-end" style={{ marginBottom: "-20px" }}>
                   
                  <button type="button" id="annualVehicle1" class="btn btn-primary mr-1">Back</button>
                  <button type="button" id="annualVehicle3" class="btn btn-primary mr-1" style={{ display: "none" }}>Next</button>
                     <button class="btn btn-primary mr-1" onClick={() => handleSubmit()}>Next</button>

                  </div>
               </div>
            </div>
         </div>

      </>
   )
};

AnnualVehicle2Edit.propTypes = {};

AnnualVehicle2Edit.defaultProps = {};

export default AnnualVehicle2Edit;
