import React from "react";
import {
  Routes,
  Route ,
  Navigate
} from "react-router-dom";

import Home from "../../modules/components/dashboard/home";

import Customer from "../../modules/components/customer/customer";
import AddCustomer from "../../modules/components/customer/add-customer";
import CustomerDetails from "../../modules/components/customer/customer-details";

import Profiles from "../../modules/components/profile/profiles";

import Vehicles from "../../modules/components/vehicle/vehicles";
import VehicleDetails from "../../modules/components/vehicle/vehicle-details";
// import AddVehicleTractor from "../../modules/components/vehicle/add-vehicle-truck";
// import AddVehicleTrailer from "../../modules/components/vehicle/add-vehicle-trailer";
// import AddVehicleTruck from "../../modules/components/vehicle/add-vehicle-truck_main";
import PressureTestEdit from "../../modules/components/inspection/pressure-test/pressure-test-edit";
import Documents from "../../modules/components/document/documents";
import AddDocument from "../../modules/components/document/add-document";
        

import InspectionVaporTightnessCertificate1 from "../../modules/components/inspection/inspection-vapor-tightness-certificate-1";
import InspectionVaporTightnessCertificate2 from "../../modules/components/inspection/inspection-vapor-tightness-certificate-2";
import InspectionVaporTightnessCertificateRead from "../../modules/components/inspection/inspection-vapor-tightness-certificate-read";

import AnnualVehicle1 from "../../modules/components/inspection/annualvehicle/inspection-annualvehicle-1";
import AnnualVehicle2 from "../../modules/components/inspection/annualvehicle/inspection-annualvehicle-2";
import AnnualVehicle3 from "../../modules/components/inspection/annualvehicle/inspection-annualvehicle-3";
import AnnualVehicle4 from "../../modules/components/inspection/annualvehicle/inspection-annualvehicle-4";
import AnnualVehicleRead from "../../modules/components/inspection/annualvehicle/inspection-annual-vehicle-read";
//import AnnualVehicle1Edit from "../../modules/components/inspection/annualvehicle/edit-inspection-annualvehicle-1";

import CargoTankThicknessTest from "../../modules/components/inspection/cargo-tank-thickness-test/cargo-tank-thickness-test";
import CargoTankThicknessTestRead from "../../modules/components/inspection/cargo-tank-thickness-test/cargo-tank-thickness-test-read";
import CargoTankThicknessTestEdit from "../../modules/components/inspection/cargo-tank-thickness-test/cargo-tank-thickness-test-edit";
import CarrierEquipment from "../../modules/components/inspection/carrier-equipment/carrier-equipment";
import CarrierEquipmentRead from "../../modules/components/inspection/carrier-equipment/carrier-equipment-read";
import CarrierEquipmentEdit from "../../modules/components/inspection/carrier-equipment/carrier-equipment-edit";
import DotCargo from "../../modules/components/inspection/DOT-cargo/DOT-cargo";
import DotCargoRead from "../../modules/components/inspection/DOT-cargo/DOT-cargo-read";

import ExternalVisual from "../../modules/components/inspection/external-visual/external-visual";
import ExternalVisualRead from "../../modules/components/inspection/external-visual/external-visual-read";

import GasolineDeliveryTankPressureVacuumTest from "../../modules/components/inspection/gasoline-delivery tank-pressure-vacuum-test/gasoline-delivery tank-pressure-vacuum-test";
import GasolineDeliveryTankPressureVacuumTestRead from "../../modules/components/inspection/gasoline-delivery tank-pressure-vacuum-test/gasoline-delivery tank-pressure-vacuum-test-read";
import GasolineDeliveryTankPressureVacuumTestEdit from "../../modules/components/inspection/gasoline-delivery tank-pressure-vacuum-test/gasoline-delivery tank-pressure-vacuum-edit";
import InternalVisual from "../../modules/components/inspection/Internal-visual/Internal-visual";
import InternalVisualRead from "../../modules/components/inspection/Internal-visual/Internal-visual-read";

// import LeakageTest from "../../modules/components/inspection/Leakage-Test/Leakage-Testbp";
import LeakageTest from "../../modules/components/inspection/Leakage-Test/Leakage-Test";
import LeakageTestEdit from "../../modules/components/inspection/Leakage-Test/Leakage-Test-edit";
import LeakageTestRead from "../../modules/components/inspection/Leakage-Test/Leakage-Test-read";

import TractorpmTest from "../../modules/components/inspection/Tractor-PM-Test/Tractorpm-Test";
import TractorpmTestEdit from "../../modules/components/inspection/Tractor-PM-Test/Tractorpm-Test-edit";
import TractorpmTestRead from "../../modules/components/inspection/Tractor-PM-Test/Tractorpm-Test-read";

import ServiceCheckListTest from "../../modules/components/inspection/Service-Check-List/Service-Check-List";
import ServiceCheckListEdit from "../../modules/components/inspection/Service-Check-List/Service-Check-List-edit";
import ServiceCheckListRead from "../../modules/components/inspection/Service-Check-List/Service-Check-List-read";

import DriverVehicleTest from "../../modules/components/inspection/Driver-Vehicle-Inspection/Driver-Vehicle";
import DriverVehicleTestEdit from "../../modules/components/inspection/Driver-Vehicle-Inspection/Driver-Vehicle-edit";
import DriverVehicleTestRead from "../../modules/components/inspection/Driver-Vehicle-Inspection/Driver-Vehicle-read";

import LeakageTestMethod27 from "../../modules/components/inspection/Leakage-Test-Method-27/Leakage-Test-Method-27";
import LeakageTestMethod27Edit from "../../modules/components/inspection/Leakage-Test-Method-27/Leakage-Test-Method-27-edit";
import LeakageTestMethod27Read from "../../modules/components/inspection/Leakage-Test-Method-27/Leakage-Test-Method-27-read";

import PressureTest from "../../modules/components/inspection/pressure-test/pressure-test";
import PressureTestRead from "../../modules/components/inspection/pressure-test/pressure-test-read";

import WetTestCertification from "../../modules/components/inspection/wet-test-certification/WET-test-certification";
import WetTestCertificationRead from "../../modules/components/inspection/wet-test-certification/WET-test-certification-read";
import WetTestCertificationEdit from "../../modules/components/inspection/wet-test-certification/WET-test-certification-edit";
import {LoadingProvider} from 'react-hook-loading';
import router from "../../router/router";
// import vehicleInfo from "../../modules/components/vehicle/vehicleInfo";
import AddVehicle from "../../modules/components/vehicle/addVehicle";

import FleetAddVehicle from "../../modules/components/fleet-owner/vehicle/add_vehicle";
import FleetVehicle from "../../modules/components/fleet-owner/vehicle/vehicles";
import FleetVehicleInformation from "../../modules/components/fleet-owner/vehicle/vehicle-information";

import FleetVehicleAtGarage from "../../modules/components/fleet-owner/vehicle/vehicle_at_garage";
import FleetAddDispatcher from "../../modules/components/fleet-owner/employees/add_dispatcher";
import FleetDispatcherDetails from "../../modules/components/fleet-owner/dispatcher_details";
import FleetEditDispatcher from "../../modules/components/fleet-owner/edit_dispatcher";
import FleetAssignToGarrage from "../../modules/components/fleet-owner/assign_to_garrage";
import FleetAddDriver from "../../modules/components/fleet-owner/driver/add_driver";
import Employees from "../../modules/components/fleet-owner/employees/employees";

import FleetAddInspection from "../../modules/components/fleet-owner/fleetOwnerAddInspection";
import FleetEditDocument from "../../modules/components/fleet-owner/edit_document";
import FleetEditVehicle from "../../modules/components/fleet-owner/vehicle/fleet_edit_vehicle";

import FleetDriverDetails from "../../modules/components/fleet-owner/driver/driver_details";
import ReminderAssignToGarrage from "../../modules/components/fleet-owner/reminder_assign_to_garrage";
import Reminder from "../../modules/components/fleet-owner/reminder";
import UpcomingInspection from "../../modules/components/fleet-owner/upcoming_inspection";
import OverdueInspection from "../../modules/components/fleet-owner/overdue_inspection";
import Login from "../../modules/components/dashboard/login";

import Pdf from "../../modules/components/document/pdf";
import SignupGarage from "../../modules/components/dashboard/signup-garage";
import SignupFleet from "../../modules/components/dashboard/signup-fleet";
// import addInspection from "../../modules/components/inspection/addInspectionbp";
// import AddInspection from "../../modules/components/inspection/addInspectionbp";
import GarageDashboard from "../../modules/components/dashboard/garage-dashboard";
import FleetOwnerDashboard from "../../modules/components/dashboard/fleetowner-dashboard";
import EditDocument from "../../modules/components/document/edit-document";
import EditVehicle from "../../modules/components/vehicle/edit-vehicle";
import ExternalVisualSectionOne from "../../modules/components/inspection/external-visual/external-visual-section-one";
import ExternalVisualSectionTwo from "../../modules/components/inspection/external-visual/external-visual-section-two";
import ExternalVisualSectionThree from "../../modules/components/inspection/external-visual/external-visual-section-three";
import ExternalVisualSectionFour from "../../modules/components/inspection/external-visual/external-visual-section-four";
import ExternalVisualSectionFive from "../../modules/components/inspection/external-visual/external-visual-section-five";
import ExternalVisualSix from "../../modules/components/inspection/external-visual/external-visual";
import AnnualVehicle5 from "../../modules/components/inspection/annualvehicle/inspection-annualvehicle-5";
import AddInspection from "../../modules/components/inspection/addInspection";
import InternalVisualEdit from "../../modules/components/inspection/Internal-visual/Internal-visual-edit";
import DOTEdit from "../../modules/components/inspection/DOT-cargo/DOT-cargo-edit";
import InspectionVaporTightnessCertificateEdit from "../../modules/components/inspection/inspection-vapor-tightness-certificate-edit";
import EditAnnualVehicle from "../../modules/components/inspection/annualvehicle/edit-inspection-annualvehicle-1";
import EditExternalVisual from "../../modules/components/inspection/external-visual/edit-external-visual-section";
import FleetOwnerAddDocument from "../../modules/components/fleet-owner/add-document";
import FleetEditDriver from "../../modules/components/fleet-owner/driver/edit_driver";
import FleetOwnerProfiles from "../../modules/components/fleet-owner/profile/profiles";
import FleetOwnerSettings from "../../modules/components/fleet-owner/settings/fleetowner_settings";
import Settings from "../../modules/components/settings/settings";
import Addusers from "../../modules/components/settings/addUser";
import EditUser from "../../modules/components/settings/editUser";
import UserDetails from "../../modules/components/settings/userDetails";
import InspectionOrderList from "../../modules/components/vehicle/inspectionOrderList";
import InspectionOrders from "../../modules/components/vehicle/inspectionOrders";
import Authentication from "../../utility/authentication";
import UpcomingOverdue from "../../modules/components/dashboard/upcoming-overdue";
import TaskOrder from "../../modules/components/dashboard/task-order";
import AddMydocs from "../../modules/components/profile/AddMydocs";
import AddMyFleetdocs from "../../modules/components/fleet-owner/profile/mydocs";
import Users from "../../modules/components/settings/Users";
import Privacy from "../../modules/privacy/privacy";
import Terms from "../../modules/termsandcondition/terms";
import GarageLists from "../../modules/components/fleet-owner/garagelist/garageList";
import GarageDetails from "../../modules/components/fleet-owner/garagelist/garageDetails";
import Releases from "../../modules/releases/Release";

import Schedule_demo from "../../modules/components/schedule-demo/schedule_demo";
import Scheduledemo_thankyou from "../../modules/components/schedule-demo/scheduledemo_thankyou";

function MainContent(props) 
{
  var accountType=sessionStorage.getItem("accountType");
  const routes = router.getInstance().getRoutesInfo([
    "home"  
  ]);
  //const classes = useStyles();

  return (
      // <Switch>
      //   <Route path={routes.home.path} component={Home} />
      //   <Redirect from="/home" to={routes.home.path} />

      // </Switch>
      <LoadingProvider >
      <Routes>
          <Route path='/login' element={<Login/>} />
          <Route path="*" element={<Navigate to ="/login" />}/>

          <Route path='/privacy' element={<Privacy/>} />
          <Route path="/privacy" element={<Navigate to ="/privacy" />}/>
      
          <Route path='/terms' element={<Terms/>} />
          <Route path="/terms" element={<Navigate to ="/terms" />}/>

          <Route path='/releases' element={<Releases/>} />
          <Route path="/releases" element={<Navigate to ="/releases" />}/>

          <Route path='/schedule_demo' element={<Schedule_demo/>} />
          <Route path="*" element={<Navigate to ="/schedule_demo" />}/>

          <Route path='/thank-you-schedule-demo' element={<Scheduledemo_thankyou/>} />
          <Route path="*" element={<Navigate to ="/thank-you-schedule-demo" />}/>

          {(() => {
        if (accountType !==null) {
       
            return (

              <>
            
              <Route path='/customer' element={<Customer/>} />
              <Route path="/customer" element={<Navigate to ="/Customer" />}/>
    
              <Route path='/addCustomer/:id/' element={<AddCustomer/>} />
              <Route path="/addCustomer/:id/" element={<Navigate to ="/AddCustomer/:id/" />}/>
    
              <Route path='/userDetails/:userId' element={<UserDetails/>} />
              <Route path="/userDetails/:userId" element={<Navigate to ="/userDetails/:userId" />}/>
    
              <Route path='/customerDetails/:id/' element={<CustomerDetails/>} />
              <Route path="/customerDetails/:id/" element={<Navigate to ="/CustomerDetails/:id/"/>}/>
    
              <Route path='/pdf' element={<Pdf/>} />
              <Route path="/pdf" element={<Navigate to ="/Documents/pdf" />}/>
    
              <Route path='/profile' element={<Profiles/>} />
              <Route path="/profile" element={<Navigate to ="/Profiles" />}/>
    
              <Route path='/settings' element={<Settings/>} />
              <Route path="/settings" element={<Navigate to ="/Settings" />}/>
    
              <Route path='/addusers' element={<Addusers/>} />
              <Route path="/addusers" element={<Navigate to ="/Addusers" />}/>
    
              <Route path='/editUser/:id/' element={<EditUser/>} />
              <Route path="/editUser/:id/" element={<Navigate to ="/EditUser/:id/" />}/>
    
              <Route path='/fleetowner/profile' element={<FleetOwnerProfiles/>} />
              <Route path="/fleetowner/profile" element={<Navigate to ="/FleetOwnerProfiles" />}/>
    
              <Route path='/fleetowner/settings' element={<FleetOwnerSettings/>} />
              <Route path="/fleetowner/settings" element={<Navigate to ="/FleetOwnerSettings" />}/>
    
    
              <Route path='/vehicles' element={<Vehicles/>} />
              <Route path="/vehicles" element={<Navigate to ="/Vehicles" />}/>
    
              <Route path='/vehicleInfo' element={<vehicleInfo/>} />
              <Route path="/vehicleInfo" element={<Navigate to ="/vehicleInfo" />}/>
    
              <Route path='/AddMydocs/:id' element={<AddMydocs/>} />
              <Route path="/AddMydocs/:id" element={<Navigate to ="/AddMydocs/:id" />}/>

              <Route path='/fleetowner/AddMyFleetdocs/:id' element={<AddMyFleetdocs/>} />
              <Route path="/fleetowner/AddMyFleetdocs/:id" element={<Navigate to ="/fleetowner/AddMyFleetdocs/:id" />}/>

              <Route path='/vehicleDetails/:id' element={<VehicleDetails/>} />
              <Route path="/vehicleDetails/:id" element={<Navigate to ="/VehicleDetails/:id" />}/>
    
              <Route path='/inspectionOrderList/:id' element={<InspectionOrderList/>} />
              <Route path="/inspectionOrderList/:id" element={<Navigate to ="/inspectionOrderList/:id" />}/>
    
              <Route path='/inspectionOrders/:id' element={<InspectionOrders/>} />
              <Route path="/inspectionOrders/:id" element={<Navigate to ="/inspectionOrders/:id" />}/>
              
              <Route path='/addVehicle/:id/' element={<AddVehicle/>} />
              <Route path="/addVehicle/:id/" element={<Navigate to ="/addVehicle/:id/" />}/>
    
              <Route path='/editVehicle/:id/' element={<EditVehicle/>} />
              <Route path="/editVehicle/:id/" element={<Navigate to ="/editVehicle/:id/" />}/>
    
    
              <Route path='/taskOrder/:customerIdFrom/:vehicleIdFrom/:customerNameFrom/:vehicleVINFrom' element={<TaskOrder/>} />
              <Route path="/taskOrder/:customerIdFrom/:vehicleIdFrom/:customerNameFrom/:vehicleVINFrom" element={<Navigate to ="/taskOrder/:customerIdFrom/:vehicleIdFrom/:customerNameFrom/:vehicleVINFrom" />}/>
    
              {/* <Route path='/addVehicleTrailer' element={<AddVehicleTrailer/>} />
              <Route path="/addVehicleTrailer" element={<Navigate to ="/AddVehicleTrailer" />}/>
    
              <Route path='/addVehicleTruck' element={<AddVehicleTruck/>} />
              <Route path="/addVehicleTruck" element={<Navigate to ="/AddVehicleTruck" />}/> */}
    
              <Route path='/documents' element={<Documents/>} />
              <Route path="/documents" element={<Navigate to ="/Documents" />}/>
    
              <Route path='/addDocument/:customerId/:vehicleId' element={<AddDocument/>} />
              <Route path="/addDocument/:customerId/:vehicleId" element={<Navigate to ="/AddDocument/:customerId/:vehicleId" />}/>
    
              <Route path='/editDocument/:customerId/:vehicleId/:documentId' element={<EditDocument/>} />
              <Route path="/editDocument/:customerId/:vehicleId/:documentId" element={<Navigate to ="/editDocument/:customerId/:vehicleId/:documentId" />}/>
    
              <Route path='/inspectionVaporTightnessCertificate1/:id/:vid' element={<InspectionVaporTightnessCertificate1/>} />
              <Route path="/inspectionVaporTightnessCertificate1/:id/:vid" element={<Navigate to ="/InspectionVaporTightnessCertificate1/:id/:vid" />}/>
    
              <Route path='/inspectionVaporTightnessCertificate2/:id/:vid' element={<InspectionVaporTightnessCertificate2/>} />
              <Route path="/inspectionVaporTightnessCertificate2/:id/:vid" element={<Navigate to ="/InspectionVaporTightnessCertificate2/:id/:vid" />}/>
    
              <Route path='/inspectionVaporTightnessCertificateRead/:fkAccountId/:inspectionId/:vid' element={<InspectionVaporTightnessCertificateRead/>} />
              <Route path="/inspectionVaporTightnessCertificateRead/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/InspectionVaporTightnessCertificateRead/:fkAccountId/:inspectionId/:vid" />}/>
              <Route path='/inspectionVaporTightnessCertificateEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid' element={<InspectionVaporTightnessCertificateEdit/>} />
              <Route path="/inspectionVaporTightnessCertificateEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/inspectionVaporTightnessCertificateEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" />}/>
              <Route path='/annualVehicle1' element={<AnnualVehicle1/>} />
              <Route path="/annualVehicle1" element={<Navigate to ="/AnnualVehicle1" />}/>
    
              <Route path='/annualVehicle2' element={<AnnualVehicle2/>} />
              <Route path="/annualVehicle2" element={<Navigate to ="/AnnualVehicle2" />}/>
    
              <Route path='/annualVehicle3' element={<AnnualVehicle3/>} />
              <Route path="/annualVehicle3" element={<Navigate to ="/AnnualVehicle3" />}/>
    
              <Route path='/annualVehicle4' element={<AnnualVehicle4/>} />
              <Route path="/annualVehicle4" element={<Navigate to ="/AnnualVehicle4" />}/>
    
              <Route path='/annualVehicle5' element={<AnnualVehicle5/>} />
              <Route path="/annualVehicle5" element={<Navigate to ="/annualVehicle5" />}/>
    
              <Route path='/annualVehicleRead/:fkAccountId/:inspectionId/:vid' element={<AnnualVehicleRead/>} />
              <Route path="/annualVehicleRead/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/AnnualVehicleRead/:fkAccountId/:inspectionId/:vid" />}/>
              
              <Route path='/annualVehicle1Edit/:taskOrderId/:fkAccountId/:inspectionId/:vid' element={<EditAnnualVehicle/>} />
              <Route path="/annualVehicle1Edit/:taskOrderId/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/annualVehicle1Edit/:taskOrderId/:fkAccountId/:inspectionId/:vid" />}/>
              
    
              <Route path='/cargoTankThicknessTest/:taskOrderId/:id/:vid' element={<CargoTankThicknessTest/>} />
              <Route path="/cargoTankThicknessTest/:taskOrderId/:id/:vid" element={<Navigate to ="/CargoTankThicknessTest/:id/:vid" />}/>
    
              <Route path='/cargoTankThicknessTestRead/:fkAccountId/:inspectionId/:vid' element={<CargoTankThicknessTestRead/>} />
              <Route path="/cargoTankThicknessTestRead/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/CargoTankThicknessTestRead/:fkAccountId/:inspectionId/:vid" />}/>
    
              <Route path='/cargoTankThicknessTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid' element={<CargoTankThicknessTestEdit/>} />
              <Route path="/cargoTankThicknessTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/cargoTankThicknessTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" />}/>
    
              <Route path='/carrierEquipment/:taskOrderId/:id/:vid' element={<CarrierEquipment/>} />
              <Route path="/carrierEquipment/:taskOrderId/:id/:vid" element={<Navigate to ="/CarrierEquipment/:id/:vid" />}/>
    
              <Route path='/carrierEquipmentRead/:fkAccountId/:inspectionId/:vid' element={<CarrierEquipmentRead/>} />
              <Route path="/carrierEquipmentRead/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/CarrierEquipmentRead/:fkAccountId/:inspectionId/:vid" />}/>
              <Route path='/carrierEquipmentEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid' element={<CarrierEquipmentEdit/>} />
              <Route path="/carrierEquipmentEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/carrierEquipmentEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" />}/>
    
              <Route path='/dotCargo/:taskOrderId/:id/:vid' element={<DotCargo/>} />
              <Route path="/dotCargo/:taskOrderId/:id/:vid" element={<Navigate to ="/DotCargo/:id/:vid" />}/>
              <Route path='/dotCargoRead/:fkAccountId/:inspectionId/:vid' element={<DotCargoRead/>} />
              <Route path="/dotCargoRead/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/DotCargoRead/:fkAccountId/:inspectionId/:vid" />}/>
              <Route path='/dotCargoEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid' element={<DOTEdit/>} />
              <Route path="/dotCargoEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/dotCargoEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" />}/>
    
    
    
              <Route path='/externalVisualSectionOne' element={<ExternalVisualSectionOne/>} />
              <Route path="/externalVisualSectionOne" element={<Navigate to ="/ExternalVisualSectionOne" />}/>
    
              <Route path='/externalVisualSectionTwo' element={<ExternalVisualSectionTwo/>} />
              <Route path="/externalVisualSectionTwo" element={<Navigate to ="/ExternalVisualSectionTwo" />}/>
    
              <Route path='/externalVisualSectionThree' element={<ExternalVisualSectionThree/>} />
              <Route path="/externalVisualSectionThree" element={<Navigate to ="/ExternalVisualSectionThree" />}/>
    
              <Route path='/externalVisualSectionFour' element={<ExternalVisualSectionFour/>} />
              <Route path="/externalVisualSectionFour" element={<Navigate to ="/ExternalVisualSectionFour" />}/>
    
              <Route path='/externalVisualSectionFive' element={<ExternalVisualSectionFive/>} />
              <Route path="/externalVisualSectionFive" element={<Navigate to ="/ExternalVisualSectionFive" />}/>
    
              <Route path='/externalVisualSectionSix' element={<ExternalVisualSix/>} />
              <Route path="/externalVisualSectionSix" element={<Navigate to ="/externalVisualSectionSix" />}/>
    
              <Route path='/externalVisualRead/:fkAccountId/:inspectionId/:vid' element={<ExternalVisualRead/>} />
              <Route path="/externalVisualRead/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/ExternalVisualRead/:fkAccountId/:inspectionId/:vid" />}/>
    
              <Route path='/editExternalVisual/:taskOrderId/:fkAccountId/:inspectionId/:vid' element={<EditExternalVisual/>} />
              <Route path="/editExternalVisual/:taskOrderId/:taskOrderId/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/editExternalVisual/:taskOrderId/:fkAccountId/:inspectionId/:vid" />}/>
              
    
              <Route path='/gasolineDeliveryTankPressureVacuumTest/:taskOrderId/:id/:vid' element={<GasolineDeliveryTankPressureVacuumTest/>} />
              <Route path="/gasolineDeliveryTankPressureVacuumTest/:taskOrderId/:id/:vid" element={<Navigate to ="/GasolineDeliveryTankPressureVacuumTest/:taskOrderId/:id/:vid" />}/>
              <Route path='/gasolineDeliveryTankPressureVacuumTestRead/:fkAccountId/:inspectionId/:vid' element={<GasolineDeliveryTankPressureVacuumTestRead/>} />
              <Route path="/gasolineDeliveryTankPressureVacuumTestRead/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/GasolineDeliveryTankPressureVacuumTestRead/:fkAccountId/:inspectionId/:vid" />}/>
    
              <Route path='/gasolineDeliveryTankPressureVacuumTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid' element={<GasolineDeliveryTankPressureVacuumTestEdit/>} />
              <Route path="/gasolineDeliveryTankPressureVacuumTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/gasolineDeliveryTankPressureVacuumTestEdit/:fkAccountId/:inspectionId/:vid" />}/>
    
              <Route path='/internalVisual/:taskOrderId/:id/:vid' element={<InternalVisual/>} />
              <Route path="/internalVisual/:taskOrderId/:id/:vid" element={<Navigate to ="/InternalVisual/:taskOrderId/:id/:vid" />}/>
              <Route path='/internalVisualRead/:fkAccountId/:inspectionId/:vid' element={<InternalVisualRead/>} />
              <Route path="/internalVisualRead/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/InternalVisualVisualRead/:fkAccountId/:inspectionId/:vid" />}/>
              <Route path='/internalVisualEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid' element={<InternalVisualEdit/>} />
              <Route path="/internalVisualEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/internalVisualEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" />}/>
    
    
              <Route path='/addInspection/:taskOrderId/:cid/:vid' element={<AddInspection/>} />
              <Route path="/addInspection/:taskOrderId/:cid/:vid" element={<Navigate to ="/addInspection/:taskOrderId/:cid/:vid" />}/>


              <Route path='/tractorpmTest/:taskOrderId/:id/:vid' element={<TractorpmTest/>} />
              <Route path="/tractorpmTest/:taskOrderId/:id/:vid" element={<Navigate to ="/TractorpmTest/:taskOrderId/:id/:vid" />}/>
              <Route path='/tractorpmTestRead/:fkAccountId/:inspectionId/:vid' element={<TractorpmTestRead/>} />
              <Route path="/tractorpmTestRead/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/TractorpmTestRead/:fkAccountId/:inspectionId/:vid" />}/>
              <Route path='/tractorpmTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid' element={<TractorpmTestEdit/>} />
              <Route path="/tractorpmTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/tractorpmTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" />}/>

              <Route path='/serviceCheckListTest/:taskOrderId/:id/:vid' element={<ServiceCheckListTest/>} />
              <Route path="/serviceCheckListTest/:taskOrderId/:id/:vid" element={<Navigate to ="/ServiceCheckListTest/:taskOrderId/:id/:vid" />}/>
              <Route path='/serviceCheckListRead/:fkAccountId/:inspectionId/:vid' element={<ServiceCheckListRead/>} />
              <Route path="/serviceCheckListRead/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/ServiceCheckListTestRead/:fkAccountId/:inspectionId/:vid" />}/>
              <Route path='/serviceCheckListEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid' element={<ServiceCheckListEdit/>} />
              <Route path="/serviceCheckListEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/ServiceCheckListEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" />}/>

              
              <Route path='/driverVehicleTest/:taskOrderId/:id/:vid' element={<DriverVehicleTest/>} />
              <Route path="/driverVehicleTest/:taskOrderId/:id/:vid" element={<Navigate to ="/DriverVehicleTest/:taskOrderId/:id/:vid" />}/>
              <Route path='/driverVehicleTestRead/:fkAccountId/:inspectionId/:vid' element={<DriverVehicleTestRead/>} />
              <Route path="/driverVehicleTestRead/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/driverVehicleTestRead/:fkAccountId/:inspectionId/:vid" />}/>
              <Route path='/driverVehicleTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid' element={<DriverVehicleTestEdit/>} />
              <Route path="/driverVehicleTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/driverVehicleTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" />}/>
    
              <Route path='/leakageTest/:taskOrderId/:id/:vid' element={<LeakageTest/>} />
              <Route path="/leakageTest/:taskOrderId/:id/:vid" element={<Navigate to ="/LeakageTest/:taskOrderId/:id/:vid" />}/>
              <Route path='/leakageTestRead/:fkAccountId/:inspectionId/:vid' element={<LeakageTestRead/>} />
              <Route path="/leakageTestRead/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/LeakageTestRead/:fkAccountId/:inspectionId/:vid" />}/>
              <Route path='/leakageTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid' element={<LeakageTestEdit/>} />
              <Route path="/leakageTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/leakageTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" />}/>

              <Route path='/leakageTestMethod27/:taskOrderId/:id/:vid' element={<LeakageTestMethod27/>} />
              <Route path="/leakageTestMethod27/:taskOrderId/:id/:vid" element={<Navigate to ="/LeakageTestMethod27/:taskOrderId/:id/:vid" />}/>
              <Route path='/leakageTestMethod27Read/:fkAccountId/:inspectionId/:vid' element={<LeakageTestMethod27Read/>} />
              <Route path="/leakageTestMethod27Read/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/LeakageTestMethod27Read/:fkAccountId/:inspectionId/:vid" />}/>
              <Route path='/leakageTestMethod27Edit/:taskOrderId/:fkAccountId/:inspectionId/:vid' element={<LeakageTestMethod27Edit/>} />
              <Route path="/leakageTestMethod27Edit/:taskOrderId/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/leakageTestMethod27Edit/:taskOrderId/:fkAccountId/:inspectionId/:vid" />}/>
    
              <Route path='/pressureTest/:taskOrderId/:id/:vid' element={<PressureTest/>} />
              <Route path="/pressureTest/:taskOrderId/:id/:vid" element={<Navigate to ="/PressureTest/:taskOrderId/:id/:vid" />}/>
              <Route path='/pressureTestRead/:fkAccountId/:inspectionId/:vid' element={<PressureTestRead/>} />
              <Route path="/pressureTestRead/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/PressureTestRead/:fkAccountId/:inspectionId/:vid" />}/>
              <Route path='/pressureTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid' element={<PressureTestEdit/>} />
              <Route path="/pressureTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/pressureTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" />}/>
            
              <Route path='/wetTestCertification/:taskOrderId/:id/:vid' element={<WetTestCertification/>} />
              <Route path="/wetTestCertification/:taskOrderId/:id/:vid" element={<Navigate to ="/WetTestCertification/:taskOrderId/:id/:vid" />}/>
              <Route path='/wetTestCertificationRead/:fkAccountId/:inspectionId/:vid' element={<WetTestCertificationRead/>} />
              <Route path="/wetTestCertificationRead/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/WetTestCertificationRead/:fkAccountId/:inspectionId/:vid" />}/>
              <Route path='/wetTestCertificationEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid' element={<WetTestCertificationEdit/>} />
              <Route path="/wetTestCertificationEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" element={<Navigate to ="/wetTestCertificationEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid" />}/>
    
              <Route path='/upcomingOverdue' element={<UpcomingOverdue/>} />
              <Route path="/upcomingOverdue" element={<Navigate to ="/upcomingOverdues" />}/>
    
              <Route path='/fleetowner/profile' element={<Profiles/>} />
              <Route path="/fleetowner/profile" element={<Navigate to ="/fleetowner/Profiles" />}/>
    
              <Route path='/fleetowner/AddVehicle' element={<FleetAddVehicle/>} />
              <Route path="/fleetowner/AddVehicle" element={<Navigate to ="/fleetowner/AddVehicle" />}/>
    
              <Route path='/fleetowner/Vehicle' element={<FleetVehicle/>} />
              <Route path="/fleetowner/Vehicle" element={<Navigate to ="/FleetVehicle" />}/>
    
              <Route path='/fleetowner/VehicleInformation/:id' element={<FleetVehicleInformation/>} />
              <Route path="/fleetowner/VehicleInformation/:id" element={<Navigate to ="/FleetVehicleInformation/:id" />}/>
    
              <Route path='/fleetowner/FleetEditVehicle/:id' element={<FleetEditVehicle></FleetEditVehicle>} />
              <Route path="/fleetowner/FleetEditVehicle/:id" element={<Navigate to ="/fleetowner/FleetEditVehicle/:id" />}/>
    
              <Route path='/fleetowner/VehicleAtGarage' element={<FleetVehicleAtGarage/>} />
              <Route path="/fleetowner/VehicleAtGarage" element={<Navigate to ="/FleetVehicleAtGarage" />}/>
    
              <Route path='/fleetowner/AddDispatcher' element={<FleetAddDispatcher/>} />
              <Route path="/fleetowner/AddDispatcher" element={<Navigate to ="/FleetAddDispatcher" />}/>
    
              <Route path='/fleetowner/DispatcherDetails/:userId' element={<FleetDispatcherDetails/>} />
              <Route path="/fleetowner/DispatcherDetails/:userId" element={<Navigate to ="/FleetDispatcherDetails/:userId" />}/>
    
              <Route path='/fleetowner/EditDispatcher/:userId' element={<FleetEditDispatcher/>} />
              <Route path="/fleetowner/EditDispatcher/:userId" element={<Navigate to ="/FleetEditDispatcher/:userId" />}/>
    
              <Route path='/fleetowner/EditDriver/:userId' element={<FleetEditDriver/>} />
              <Route path="/fleetowner/EditDriver/:userId" element={<Navigate to ="/EditDriver/:userId" />}/>
    
              <Route path='/fleetowner/AssignToGarrage/:id' element={<FleetAssignToGarrage/>} />
              <Route path="/fleetowner/AssignToGarrage/:id" element={<Navigate to ="/FleetAssignToGarrage" />}/>
    
              <Route path='/fleetowner/AddDriver' element={<FleetAddDriver/>} />
              <Route path="/fleetowner/AddDriver" element={<Navigate to ="/FleetAddDriver" />}/>
    
              <Route path='/fleetowner/employees' element={<Employees/>} />
              <Route path="/fleetowner/employees" element={<Navigate to ="/employees" />}/>
    

              <Route path='/fleetowner/garagelist' element={<GarageLists/>} />
              <Route path="/fleetowner/garagelist" element={<Navigate to ="/garagelist" />}/>

              <Route path='/fleetowner/garageDetails/:vendorId' element={<GarageDetails/>} />
              <Route path="/fleetowner/garageDetails/:vendorId" element={<Navigate to ="/fleetowner/garageDetails/:vendorId" />}/>

              {/* <Route path='/fleetowner/EditInspection' element={<FleetEditInspection/>} />
              <Route path="/fleetowner/EditInspection" element={<Navigate to ="/fleetowner/EditInspection" />}/> */}
    
              <Route path='/fleetowner/AddInspection/:customerId/:vehicleId' element={<FleetAddInspection/>} />
              <Route path="/fleetowner/AddInspection/:customerId/:vehicleId" element={<Navigate to ="/fleetowner/AddInspection/:customerId/:vehicleId" />}/>
    
              <Route path='/fleetowner/EditDocument/:customerId/:vehicleId/:documentId' element={<FleetEditDocument/>} />
              <Route path="/fleetowner/EditDocument/:customerId/:vehicleId/:documentId" element={<Navigate to ="/FleetEditDocument/:customerId/:vehicleId/:documentId" />}/>
    
              <Route path='/fleetowner/AddDocument/:vehicleId' element={<FleetOwnerAddDocument/>} />
              <Route path="/fleetowner/AddDocument/:vehicleId" element={<Navigate to ="/fleetowner/AddDocument/:vehicleId" />}/>
    
              <Route path='/fleetowner/DriverDetails/:id' element={<FleetDriverDetails/>} />
              <Route path="/fleetowner/DriverDetails/:id" element={<Navigate to ="/fleetowner/DriverDetails/:id" />}/>
    
    
              <Route path='/fleetowner/reminderAssignToGarrage' element={<ReminderAssignToGarrage/>} />
              <Route path="/fleetowner/reminderAssignToGarrage" element={<Navigate to ="/ReminderAssignToGarrage" />}/>
    
              <Route path='/fleetowner/reminder' element={<Reminder/>} />
              <Route path="/fleetowner/reminder" element={<Navigate to ="/Reminder" />}/>
    
     
             
    
              <Route path='/fleetowner/upcomingInspection' element={<UpcomingInspection/>} />
              <Route path="/fleetowner/upcomingInspection" element={<Navigate to ="/upcomingInspection" />}/>
    
              <Route path='/fleetowner/overdueInspection' element={<OverdueInspection/>} />
              <Route path="/fleetowner/overdueInspection" element={<Navigate to ="/overdueInspection" />}/>
    
              <Route path='/signupGarage' element={<SignupGarage/>} />
              <Route path="/signupGarage" element={<Navigate to ="/signupGarage" />}/>
    
              <Route path='/signupFleet' element={<SignupFleet/>} />
              <Route path="/signupFleet" element={<Navigate to ="/signupFleet" />}/>
    
              <Route path='/garage/dashboard' element={<GarageDashboard/>} />
              <Route path="/garage/dashboard" element={<Navigate to ="/garageDashbord" />}/>
    
              <Route path='/users' element={<Users/>} />
              <Route path="/users" element={<Navigate to ="/users" />}/>

              <Route path='/fleetowner/dashboard' key="reload" element={<FleetOwnerDashboard/>} />
              <Route path="/fleetowner/dashboard"  key="reload" element={<Navigate to ="/fleetownerDashboard" />}/>
              <Route path='/authentication' element={<Authentication/>} />
              <Route path="/authentication" element={<Navigate to ="/authentication" />}/></>

              
            )
         }
      })()}

         


      </Routes>
      </LoadingProvider >
  );
}

export default MainContent;
