import $ from "jquery";

const WorkOrderNumberDiv=({status})=>
{
// alert(status);
$(document).on('change', '#workOrderNumber',function(){
   sessionStorage.setItem("workOrderNumber",$('#workOrderNumber').val());
 });

   $(document).ready(function () 
   {
      const workON=sessionStorage.getItem("workOrderNumber");

      if(workON!=null||workON!="null")
      {
        if(workON==null||workON==="null")
        {
           return false;

        }else{
         $("#workOrderNumber").val(workON?workON:"")
        }
       
      }

       $("#workOrderNumber").keypress(function (e) {
          if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
          //   $("#errmsg").html("Digits Only").show().fadeOut("slow");
                   return false;
        }
       });
       if(status=="readOnly")
       {
         $("#workOrderNumber").attr('disabled', 'disabled');
       }
    });
    
  return<>
  <div class="w-100 px-3">
   <div class="row mb-2">
                              <div class="col-lg-12 px-0">
                                 <div class="wo d-flex align-items-center p-3">
                                    <img src="/images/icons/workorder-icon.svg" alt=""/>
                                    <span class="mr-2 ml-2">Work order number</span>
                                    <input type="input" placeholder="Enter W/O" id="workOrderNumber" readOnly/>
                                 </div>
                              </div>
                             
                           </div>
                           </div>
  </>

}



export default WorkOrderNumberDiv;