import React,{history,useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate ,useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import  $ from "jquery";
import {useLoading} from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import AddInspectionName from "../../../../utility/addInspectionName";
import removePdf from "../../../../utility/removePdf";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";

const CarrierEquipmentEdit = () => {
   const { taskOrderId,fkAccountId, inspectionId,vid } = useParams();
   const [inputList, setInputList] = useState([{ }]);
   const [base64file, setBase64file] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const [ fileName, setfileName ] = useState("");
   const [ inspectionDetails, setInspectionDetails ] = useState("");
   const [ documentList, setDocumentLists ] = useState([]);
   const [ maxCompartmentCapacity, setmaxCompartmentCapacity ] = useState([]);
   const [ probeOutage, setprobeOutage ] = useState([]);
   const [ carrierOutage, setcarrierOutage ] = useState([]);
   const [ maximumPreset, setmaximumPreset ] = useState([]);
   const [inspectionTypeId, setinspectionTypeId] = useState("");
   const [ carrierEquipmentInspection, setcarrierEquipmentInspection ] = useState([]);
   const [ certifiedInspectionRequirements, setcertifiedInspectionRequirements ] = useState([]);
   const [ carrierVerificationRequirements, setcarrierVerificationRequirements ] = useState([]);
   const navigate = useNavigate();
   const [endDate, setEndDate] = useState(new Date());
   var documentLists=[];
   useEffect(() => 
   {
    
      // vehicle_details_api(id, setVehicleDetails, setCustomerId); 
    

         getInpection(fkAccountId,inspectionId);
   

   }, []);

  function getInpection(fkAccountId,inspectionId)
  {
      var requestObjectForWet=
      {
      
        "id":inspectionId
      }

      var requestOptions=requestObject.RequestHeader(requestObjectForWet);

      services.getInspectionDetails(requestOptions).then((res) => 
      {
        
         setInspectionDetails(res.rows);
         setDocumentLists(res.rows.documentList);
         setinspectionTypeId(res.rows.inspectionType.id);
         setmaxCompartmentCapacity(res.rows.carrierEquipmentInspection.maxCompartmentCapacity);
         setprobeOutage(res.rows.carrierEquipmentInspection.probeOutage);
         setcarrierOutage(res.rows.carrierEquipmentInspection.carrierOutage);
         setmaximumPreset(res.rows.carrierEquipmentInspection.maximumPreset);
         setcarrierEquipmentInspection(res.rows.carrierEquipmentInspection);
         setcertifiedInspectionRequirements(res.rows.carrierEquipmentInspection.certifiedInspectionRequirements);
         setcarrierVerificationRequirements(res.rows.carrierEquipmentInspection.carrierVerificationRequirements);
         $("#workOrderNumber").val(res.rows?res.rows.workOrderNumber:"");
         $("#notes").val(res.rows?res.rows.notes:"");
         $("#inspectedBy").val(res.rows?res.rows.inspectedBy:"");
         setEndDate(new Date(res.rows?res.rows.inspectedDate:""));
         documentLists.push(res.rows.documentList);
         sessionStorage.setItem("documentList",JSON.stringify(res.rows.documentList));
      });


 

   }

   $(document).ready(function () {

      $("#com1,#com2,#com3,#com4,#com2,#com5,#com6,#outage1,#outage2,#outage3,#outage4,#outage5,#outage6,#coutage1,#coutage2,#coutage3,#coutage4,#coutage5,#coutage6,#preset1,#preset2,#preset3,#preset4,#preset5,#preset6").keypress(function (e) {
          if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
          //   $("#errmsg").html("Digits Only").show().fadeOut("slow");
                   return false;
        }
       });
    });
  
    const redirect=(page)=>
   {
 
      navigate(page);
   
   }
  
    const handleSubmit=()=>
    {
  
  let form_is_valid = true;
  
  var compartmentcapacity=$("input[name='compartmentcapacity']:checked").val();
  var workingcondition=$("input[name='workingcondition']:checked").val();
  var shutdown=$("input[name='shutdown']:checked").val();
  var operatingcondition=$("input[name='operatingcondition']:checked").val();
  var allowloading=$("input[name='allowloading']:checked").val();
  var bondingwires=$("input[name='bondingwires']:checked").val();
  var hose=$("input[name='hose']:checked").val();
  var plateinstalled=$("input[name='plateinstalled']:checked").val();
  var thatarehauled=$("input[name='thatarehauled']:checked").val();
  var datacurrent=$("input[name='datacurrent']:checked").val();
  var decalscurrent=$("input[name='decalscurrent']:checked").val();
  var onboard=$("input[name='onboard']:checked").val();
  var unitnumbers=$("input[name='unitnumbers']:checked").val();
  var uponrequest=$("input[name='uponrequest']:checked").val();
  var recordedabove=$("input[name='recordedabove']:checked").val();
  
  
  // var unitDesignPressure=$('#unitDesignPressure').val();
  // var allowMax=/^[0-9]*$/;
  
  // if (!unitDesignPressure) {
  //   form_is_valid = false;
  //   $("#unitDesignPressure-message").html('Unit design pressure  is required');
  // }else{
  //         $("#unitDesignPressure-message").html('');
  //         form_is_valid=true;
  //   }
  
  
  
  if(compartmentcapacity==undefined||workingcondition==undefined||shutdown==undefined||operatingcondition==undefined||allowloading==undefined||bondingwires==undefined||hose==undefined||plateinstalled==undefined||thatarehauled==undefined||datacurrent==undefined||decalscurrent==undefined||onboard==undefined||unitnumbers==undefined||uponrequest==undefined||recordedabove==undefined){
    // $('html, body').animate({
    //   scrollTop: $("#inspectionType").offset().top
    // }, 500);
    $('html,body').animate({ scrollTop: "500px" }, 1000);
    $('#error_message').css("color", "#d33232").html("*Please choose one option for each question");
    form_is_valid= false;
  
  }else{
    $('#error_message').html(''); 
  }
  return form_is_valid;
  
  }
    const save=(status)=>
    {
  
      if(status=='DRAFT'){
        // alert($("input[name='operatingcondition']").val());
        var documentList=[];
      try
      {
        for(var i=0;i<inputList.length;i++)
        {
            // console.log("filebase64=="+inputList[i].filetobase64);
            var extension="";
            var byteString="";
            if(inputList[i].filetobase64.match("data:application/pdf;base64,"))
            {
                extension="pdf";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/pdf;base64,", ""):"";
            }else if(inputList[i].filetobase64.match("data:image/jpeg;base64,"))
            {
                extension="jpg";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/jpeg;base64,", ""):"";
            }
            else if(inputList[i].filetobase64.match("data:image/png;base64,"))
             {
                 extension="png";
                 byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/png;base64,", ""):"";
             }
             else if(inputList[i].filetobase64.match("data:application/msword;base64,"))
             {
                 extension="word";
                 byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/msword;base64,", ""):"";
             }
             else if(inputList[i].filetobase64.match("data:image/bmp;base64,"))
             {
                 extension="bmp";
                 byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/bmp;base64,", ""):"";
             }
             else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
             {
                 extension="docx";
                 byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
             }
             else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
             {
                 extension="docx";
                 byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
             }
             else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
             {
                 extension="docx";
                 byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
             }
             else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
             {
                 extension="docx";
                 byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
             }
             else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
             {
                 extension="docx";
                 byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
             }
             else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
             {
                 extension="docm";
                 byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
             }
             else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,"))
             {
                 extension="docx";
                 byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", ""):"";
             }
             else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
             {
                 extension="dotx";
                 byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
             }
             else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
             {
                 extension="dotx";
                 byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
             }
             else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,"))
             {
                 extension="dotm";
                 byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", ""):"";
             }
             else{
                
             }
  
             documentLists.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
        }
      }catch(exception)
      {
      //  documentList.push();
        // Swal.fire({  
        //     position: 'center',  
        //     icon: 'error',  
        //     title: "",  
        //     text:"Please attach document",
        //     showConfirmButton: true 
            
        //   }).then((result) => 
        //   {
                
        //         if(result.isConfirmed) 
        //         {
                  
                
        //         }
        //   });
        //   return false;
      }
      // alert(sessionStorage.getItem('documentList')).length;
      try {
        if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {
  
            var i;
            var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
            for (i = 0; i < storedArray.length; i++) {
  
                documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
            }
  
        } else {
  
             documentLists.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
        }
  
    } catch (ex) {
  
    }
      if($("#inspectedBy").val()==="")
      {
        Swal.fire({  
            position: 'center',  
            icon: 'error',  
            title: "",  
            text:"Please enter inspectedBy",
            showConfirmButton: true 
            
          }).then((result) => 
          {
                
                if(result.isConfirmed) 
                {
                  
                
                }
          });
          return false;
      }
          
          var requestObjectForInsp=
          {
            "id": inspectionId,
            "expiryDate": $("#expiryDate").val(),
            "type":"Carrier Equipment Inspection",
            "inspectedBy":  $("#inspectedBy").val(),
            "inspectedDate":$("#inspectedDate").val(),
            "verifiedBy": $("#verifiedBy").val(),
            "verifiedDate": $("#verifiedBy").val(),
            "workOrderNumber": $("#workOrderNumber").val()!=''?$("#workOrderNumber").val():'',
            "notes": $("#notes").val()!=''?$("#notes").val():'',
            "status": status,
            "taskOrderId":taskOrderId,
            "inspectionType":
            {
              "id": inspectionTypeId,
              "name":"Carrier Equipment Inspection"
            },
            "result":
            {
              "approvedCertify": "0",
              "inspectionFailed": "0"
            },
            "dispositionOfVehicle":
            {
              "vehicleReturnedServices": "0",
              "vehicleWithdrawnFromService": "0",
              "vehicleSentToAnotherRepairFacility": "0"
            },
            "carrierEquipmentInspection":{
            "maxCompartmentCapacity": [
              $("#com1").val(),
              $("#com2").val(),
              $("#com3").val(),
              $("#com4").val(),
              $("#com5").val(),
              $("#com6").val()
            ],
            "probeOutage": [
              $("#outage1").val(),
              $("#outage2").val(),
              $("#outage3").val(),
              $("#outage4").val(),
              $("#outage5").val(),
              $("#outage6").val(),
            ],
            "carrierOutage": [
              $("#coutage1").val(),
              $("#coutage2").val(),
              $("#coutage3").val(),
              $("#coutage4").val(),
              $("#coutage5").val(),
              $("#coutage6").val(),
            ],
         
            "maximumPreset": [
              $("#preset1").val(),
              $("#preset2").val(),
              $("#preset3").val(),
              $("#preset4").val(),
              $("#preset5").val(),
              $("#preset6").val()
            ],
           
            "certifiedInspectionRequirements": {
              "maximumCompartmentCapacity": $("input[name='compartmentcapacity']:checked").val()==="1"?"1":"0",
              "systemInWorkingCondition": $("input[name='workingcondition']:checked").val()==="1"?"1":"0",
              "activatestheShutdownCircuitry": $("input[name='shutdown']:checked").val()==="1"?"1":"0",
              "properOperatingCondition": $("input[name='operatingcondition']:checked").val()==="1"?"1":"0",
              "readingToAllowLoading": $("input[name='allowloading']:checked").val()==="1"?"1":"0",
              "secureBondingWires": $("input[name='bondingwires']:checked").val()==="1"?"1":"0",
              "hose": $("input[name='hose']:checked").val()==="1"?"1":"0",
            },
            "carrierVerificationRequirements": {
              "specificationPlateInstalled": $("input[name='plateinstalled']:checked").val()==="1"?"1":"0",
              "productsThatareHauled": $("input[name='thatarehauled']:checked").val()==="1"?"1":"0",
              "dataCurrent": $("input[name='datacurrent']:checked").val()==="1"?"1":"0",
              "decalsCurrent": $("input[name='decalscurrent']:checked").val()==="1"?"1":"0",
              "includingGuidebook": $("input[name='onboard']:checked").val()==="1"?"1":"0",
              "appropriateUnitNumbers": $("input[name='unitnumbers']:checked").val()==="1"?"1":"0",
              "availableUponRequest": $("input[name='uponrequest']:checked").val()==="1"?"1":"0",
              "presetsRecordedAbove": $("input[name='recordedabove']:checked").val()==="1"?"1":"0"
            },
          },
            "vehicleDetails":
            {
                "vinNumber": $("#vin").html(),
                "lisencePlateNumber": $("#lisencePlateNumber").html(),
                "id":localStorage.getItem("vehicleId"),
                "unitNumber": $("#lisencePlateNumber").html()
            },
           
            "documentList": documentLists
          
        }; 
  
      $("#loadingforsubmit").show();
        // setLoading(true);
        // const requestOptions = 
        // {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json'
        //             },
                    
        //       body: JSON.stringify(requestObject)
        // };
        var requestOptions=requestObject.RequestHeader(requestObjectForInsp);
        services.addInspection(requestOptions).then((res) => 
        {
          $("#loadingforsubmit").hide();
          Swal.fire({  
            position: 'center',  
            icon: 'success',  
            title: "",  
            text:res.message,
            showConfirmButton: true 
            
          }).then((result) => 
          {
                
                if(result.isConfirmed) 
                {
                  navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));
                
                }
          });
        });
  
      }else{
        
      if(handleSubmit()==true){
  
        var documentList=[];
      try
      {
        for(var i=0;i<inputList.length;i++)
        {
            // console.log("filebase64=="+inputList[i].filetobase64);
            var extension="";
            var byteString="";
            if(inputList[i].filetobase64.match("data:application/pdf;base64,"))
            {
                extension="pdf";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/pdf;base64,", ""):"";
            }else if(inputList[i].filetobase64.match("data:image/jpeg;base64,"))
            {
                extension="jpg";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/jpeg;base64,", ""):"";
            }
            else if(inputList[i].filetobase64.match("data:image/png;base64,"))
            {
                extension="png";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/png;base64,", ""):"";
            }
            else if(inputList[i].filetobase64.match("data:application/msword;base64,"))
            {
                extension="word";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/msword;base64,", ""):"";
            }
            else if(inputList[i].filetobase64.match("data:image/bmp;base64,"))
            {
                extension="bmp";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/bmp;base64,", ""):"";
            }
            else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
            {
                extension="docx";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
            }
            else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
            {
                extension="docx";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
            }
            else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
            {
                extension="docx";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
            }
            else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
            {
                extension="docx";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
            }
            else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
            {
                extension="docx";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
            }
            else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
            {
                extension="docm";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
            }
            else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,"))
            {
                extension="docx";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", ""):"";
            }
            else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
            {
                extension="dotx";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
            }
            else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
            {
                extension="dotx";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
            }
            else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,"))
            {
                extension="dotm";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", ""):"";
            }
            else{
               
            }
  
  
              documentLists.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
        }
      }catch(exception)
      {
        // Swal.fire({  
        //     position: 'center',  
        //     icon: 'error',  
        //     title: "",  
        //     text:"Please attach document",
        //     showConfirmButton: true 
            
        //   }).then((result) => 
        //   {
                
        //         if(result.isConfirmed) 
        //         {
                  
                
        //         }
        //   });
        //   return false;
        // documentList.push();
      }
      try {
        if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {
  
            var i;
            var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
            for (i = 0; i < storedArray.length; i++) {
  
                documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
            }
  
        } else {
  
             documentLists.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
        }
  
    } catch (ex) {
  
    }
      if($("#inspectedBy").val()==="")
      {
        Swal.fire({  
            position: 'center',  
            icon: 'error',  
            title: "",  
            text:"Please enter inspectedBy",
            showConfirmButton: true 
            
          }).then((result) => 
          {
                
                if(result.isConfirmed) 
                {
                  
                
                }
          });
          return false;
      }
          // alert(inspectionId);
          var requestObjectForInsp=
          {
            "id": inspectionId,
            "expiryDate": $("#expiryDate").val(),
            "type":"Carrier Equipment Inspection",
            "inspectedBy":  $("#inspectedBy").val(),
            "inspectedDate":$("#inspectedDate").val(),
            "verifiedBy": $("#verifiedBy").val(),
            "verifiedDate": $("#verifiedBy").val(),
            "workOrderNumber": $("#workOrderNumber").val()!=''?$("#workOrderNumber").val():'',
            "notes": $("#notes").val()!=''?$("#notes").val():'',
            "status": status,
            "taskOrderId":taskOrderId,
            "inspectionType":
            {
              "id": inspectionTypeId,
              "name":"Carrier Equipment Inspection"
            },
            "result":
            {
                "approvedCertify": "0",
                "inspectionFailed": "0"
            },
            "dispositionOfVehicle": {
              "vehicleReturnedServices": "0",
              "vehicleWithdrawnFromService":"0",
              "vehicleSentToAnotherRepairFacility":"0"
            },
            "carrierEquipmentInspection":{
            "maxCompartmentCapacity": [
              $("#com1").val(),
              $("#com2").val(),
              $("#com3").val(),
              $("#com4").val(),
              $("#com5").val(),
              $("#com6").val()
            ],
            "probeOutage": [
              $("#outage1").val(),
              $("#outage2").val(),
              $("#outage3").val(),
              $("#outage4").val(),
              $("#outage5").val(),
              $("#outage6").val(),
            ],
            "carrierOutage": [
              $("#coutage1").val(),
              $("#coutage2").val(),
              $("#coutage3").val(),
              $("#coutage4").val(),
              $("#coutage5").val(),
              $("#coutage6").val(),
            ],
         
            "maximumPreset": [
              $("#preset1").val(),
              $("#preset2").val(),
              $("#preset3").val(),
              $("#preset4").val(),
              $("#preset5").val(),
              $("#preset6").val()
            ],
           
            "certifiedInspectionRequirements": {
              "maximumCompartmentCapacity": $("input[name='compartmentcapacity']:checked").val()==="1"?"1":"0",
              "systemInWorkingCondition": $("input[name='workingcondition']:checked").val()==="1"?"1":"0",
              "activatestheShutdownCircuitry": $("input[name='shutdown']:checked").val()==="1"?"1":"0",
              "properOperatingCondition": $("input[name='operatingcondition']:checked").val()==="1"?"1":"0",
              "readingToAllowLoading": $("input[name='allowloading']:checked").val()==="1"?"1":"0",
              "secureBondingWires": $("input[name='bondingwires']:checked").val()==="1"?"1":"0",
              "hose": $("input[name='hose']:checked").val()==="1"?"1":"0",
            },
            "carrierVerificationRequirements": {
              "specificationPlateInstalled": $("input[name='plateinstalled']:checked").val()==="1"?"1":"0",
              "productsThatareHauled": $("input[name='thatarehauled']:checked").val()==="1"?"1":"0",
              "dataCurrent": $("input[name='datacurrent']:checked").val()==="1"?"1":"0",
              "decalsCurrent": $("input[name='decalscurrent']:checked").val()==="1"?"1":"0",
              "includingGuidebook": $("input[name='onboard']:checked").val()==="1"?"1":"0",
              "appropriateUnitNumbers": $("input[name='unitnumbers']:checked").val()==="1"?"1":"0",
              "availableUponRequest": $("input[name='uponrequest']:checked").val()==="1"?"1":"0",
              "presetsRecordedAbove": $("input[name='recordedabove']:checked").val()==="1"?"1":"0"
            },
          },
            "vehicleDetails":
            {
                "vinNumber": $("#vin").html(),
                "lisencePlateNumber": $("#lisencePlateNumber").html(),
                "id":localStorage.getItem("vehicleId"),
                "unitNumber": $("#lisencePlateNumber").html()
            },
           
            "documentList": documentLists
          
        }; 
  
  
        $("#loadingforsubmit").show();
        // const requestOptions = 
        // {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json'
        //             },
                    
        //       body: JSON.stringify(requestObject)
        // };
        var requestOptions=requestObject.RequestHeader(requestObjectForInsp);
        services.addInspection(requestOptions).then((res) => 
        {
          $("#loadingforsubmit").hide();
          // setLoading(false);
          Swal.fire({  
            position: 'center',  
            icon: 'success',  
            title: "",  
            text:"Your changes have been successfully saved!",
            showConfirmButton: true 
            
          }).then((result) => 
          {
                
                if(result.isConfirmed) 
                {
                  navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));
                
                }
          });
        });
      }
  
    }
  
    } 

     // handle input change
   const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
 };

 // handle click event of the Remove button
 const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
 };

 // handle click event of the Add button
 const handleAddClick = () => {
    setInputList([...inputList, {}]);
 };
 const fileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
       cb(null, reader.result)
    }
    reader.onerror = function (error) {
       cb(error, null)
    }
 }
 const onUploadFileChange = (event, index) => {


    var target = event.target;
    if (target.files < 1 || !target.validity.valid) {
       return
    }
    fileToBase64(target.files[0], (err, result) => {
       if (result) {
          const { name, value } = event.target;
          const list = [...inputList];
          list[index][name] = result;
          setBase64file(result);
          // setFile(result)
          setfileName(target.files[0]);
          // setFileName(target.files[0])
       }
    })
 }
  return(
  <>
  {LoadingComponentForSubmit}
     <div class="main-panel">
               <div class="content-wrapper" style={{display: "block"}}>
                
                  <div class="row mb-4">
                          <div class="col-lg-11 col-md-12 m-auto ">
                          <HeaderBlock cids={fkAccountId} />
                           </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                       <div class="d-flex justify-content-between align-items-center">
                        <h4 class="font-weight-bold">Vehicle Inspection</h4>
                        <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-white">Back</a>
                       </div>
                    </div>
                 </div>

                  <div class="row mb-2">
                     <div class="col-lg-11 col-md-12 m-auto ">
                        <div class="card">

                          <div class="row mb-2">
                              <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                                    <VehicleBasicInfo customerId={vid} />
                              </div>
                           </div> 
                            <div class="divider"></div>
                            <div class="row p-3">
                                <div class="col-lg-12">
                                    <h4 class="form-inspection-title text-center">Carrier Equipment Inspection</h4>
                                </div>
                                <div class="col-lg-12"><WorkOrderNumberDiv  /></div>
                                
                                <div class="col-lg-12">
                                    <div class="inspection-wrap">

                                        <div class="table-no-border mb-4">
                                            <table class="table  ">
                                                <thead>
                                                  <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col">Example</th>
                                                    <th scope="col">1#</th>
                                                    <th scope="col">2#</th>
                                                    <th scope="col">3#</th>
                                                    <th scope="col">4#</th>
                                                    <th scope="col">5#</th>
                                                    <th scope="col">6#</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                      <td>1. Max Compartment Capacity</td>
                                                      <td class="red-txt">Ex: 3410</td>
                                                      {
                                                                                               maxCompartmentCapacity? maxCompartmentCapacity.map((data, index) => (
                                                                                                   // <td class="text-left">{data!=''?data:"--"}</td>
                                                                                                   <td class="text-left"> <input type="input" class="form-control" id={"com"+(index+1)} defaultValue={data!=''?data:""} aria-describedby="emailHelp"/></td>
                                                                                                )):""
                                                                                               
                                                      }
                                                      {/* <td class="red-txt">Ex: 3410</td>
                                                      <td class="text-left">4510</td>
                                                      <td class="text-left">4510</td>
                                                      <td class="text-left">4510</td>
                                                      <td class="text-left">4510</td>
                                                      <td class="text-left">4510</td>
                                                      <td class="text-left">4510</td> */}
                                                  </tr>

                                                  <tr>
                                                    <td>2. Probe Outage (60 gal min)   </td>
                                                    <td class="red-txt">Ex: 60</td>
                                                    {
                                                                                                probeOutage?probeOutage.map((data, index) => (
                                                                                                   <td class="text-left"> <input type="input" class="form-control" id={"outage"+(index+1)} defaultValue={data!=''?data:""} aria-describedby="emailHelp"/></td>
                                                                                                )):""
                                                                                               
                                                   }
                                                </tr>

                                                <tr>
                                                    <td>3. *Carrier Outage</td>
                                                    <td class="red-txt">Ex: 3080</td>
                                                    {
                                                                                                carrierOutage?carrierOutage.map((data, index) => (
                                                                                                   // <td class="text-left">{data!=''?data:"--"}</td>
                                                                                                   <td class="text-left"> <input type="input" class="form-control" id={"coutage"+(index+1)} defaultValue={data!=''?data:""} aria-describedby="emailHelp"/></td>
                                                                                                )):""
                                                                                               
                                                   }
                                                </tr>

                                                <tr>
                                                    <td>4. Maximum Preset</td>
                                                    <td class="red-txt">Ex: 3000</td>
                                                    {
                                                                                                maximumPreset?maximumPreset.map((data, index) => (
                                                                                                   // <td class="text-left">{data!=''?data:"--"}</td>
                                                                                                   <td class="text-center"><input type="input" class="form-control" id={"preset"+(index+1)} defaultValue={data!=''?data:""} aria-describedby="emailHelp"/></td>
                                                                                                )):""
                                                                                               
                                                   }
                                                </tr>

                                                <tr></tr>

                                                <tr style={{height: "70px"}}>
                                                    <td>Subtract Lines 2&3 from Line 1</td>
                                                    <td></td>
                                                    <td colspan="6">Sections must be completed</td>  
                                                </tr>

                                                 
                                                </tbody>
                                              </table>
                                        </div>

                                        <div class="p-3 mb-4" style={{backgroundColor: "#ccc"}}>
                                            <p>'Carrier outage is the distance between the overfill probe and the product that prevents setting off the rack shutdown system (domeouts). This option is at the discretion of the carrier and varies on the tank strapping charts and the level outage selected.	
                                            </p>
                                        </div>

                                        <div class="table-2 mb-4">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Certified inspection Requirements</th>
                                                    <th scope="col">Yes</th>
                                                    <th scope="col">No</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                   <td>1. Has the overfill protection probe been set & tested to a minimum of 60 gross  below the maximum compartment capacity?</td>
                                                   { (() =>
                                                    {
                                                       if(certifiedInspectionRequirements)
                                                       {
                                                         if (certifiedInspectionRequirements.maximumCompartmentCapacity=='1') 
                                                         {
                                                      
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1" name="compartmentcapacity"  defaultChecked={true}     /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0"  name="compartmentcapacity" /></td></>)
                                                         }else 
                                                         {
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1" name="compartmentcapacity"  /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0"  name="compartmentcapacity"  defaultChecked={true}/></td></>)
                                                         }

                                                       }else{

                                                       }
                                                        
                                                    })()}
                                                   {/* <td class="text-center"> {certifiedInspectionRequirements?certifiedInspectionRequirements.maximumCompartmentCapacity=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{certifiedInspectionRequirements?certifiedInspectionRequirements.maximumCompartmentCapacity=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td> */}
                                                  </tr>

                                                  <tr>
                                                    <td>2. Is the overfill protection system in working condition?</td>
                                                    { (() =>
                                                    {
                                                       if(certifiedInspectionRequirements)
                                                       {
                                                         if (certifiedInspectionRequirements.systemInWorkingCondition=='1') 
                                                         {
                                                      
                                                          return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1" name="workingcondition"  defaultChecked={true}     /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0"  name="workingcondition" /></td></>)
                                                         }else 
                                                         {
                                                          return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1" name="workingcondition"       /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0"  name="workingcondition" defaultChecked={true} /></td></>)
                                                         }

                                                       }else{

                                                       }
                                                        
                                                    })()}
                                                    {/* <td class="text-center">  {certifiedInspectionRequirements?certifiedInspectionRequirements.systemInWorkingCondition=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{certifiedInspectionRequirements?certifiedInspectionRequirements.systemInWorkingCondition=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td> */}
                                                   </tr>

                                                   <tr>
                                                    <td>3. Has each compartments probe been tested with liquid to verify that it activates the shutdown circuitry?</td>
                                                    { (() =>
                                                    {
                                                       if(certifiedInspectionRequirements)
                                                       {
                                                         if (certifiedInspectionRequirements.activatestheShutdownCircuitry=='1') 
                                                         {
                                                      
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1" name="shutdown"  defaultChecked={true}     /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="shutdown"       /></td></>)
                                                         }else 
                                                         {
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1" name="shutdown"       /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="shutdown"    defaultChecked={true}   /></td></>)
                                                         }

                                                       }else{

                                                       }
                                                        
                                                    })()}
                                                    {/* <td class="text-center">  {certifiedInspectionRequirements?certifiedInspectionRequirements.activatestheShutdownCircuitry=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{certifiedInspectionRequirements?certifiedInspectionRequirements.activatestheShutdownCircuitry=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td> */}
                                                   </tr>

                                                     <tr>
                                                    <td>4. Has the grounding system been checked and is in proper operating condition?</td>
                                                    { (() =>
                                                    {
                                                       if(certifiedInspectionRequirements)
                                                       {
                                                         if (certifiedInspectionRequirements.properOperatingCondition=='1') 
                                                         {
                                                      
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1" name="operatingcondition"  defaultChecked={true}/></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="operatingcondition"   /></td></>)
                                                         }else 
                                                         {
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1"  name="operatingcondition" /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="operatingcondition"  defaultChecked={true}     /></td></>)
                                                         }

                                                       }else{

                                                       }
                                                        
                                                    })()}
                                                    {/* <td class="text-center"> {certifiedInspectionRequirements?certifiedInspectionRequirements.properOperatingCondition=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{certifiedInspectionRequirements?certifiedInspectionRequirements.properOperatingCondition=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td> */}
                                                   </tr>

                                                   <tr>
                                                    <td><p>5. Has the grounding system been checked to ensure that has not been modified or rewired in any manner that	
                                                        would allow it to provide a false reading to allow loading?</p>	
                                                        </td>
                                                        { (() =>
                                                    {
                                                       if(certifiedInspectionRequirements)
                                                       {
                                                         if (certifiedInspectionRequirements.readingToAllowLoading=='1') 
                                                         {
                                                      
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1" name="allowloading"  defaultChecked={true}     /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="allowloading"     /></td></>)
                                                         }else 
                                                         {
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1"  name="allowloading" /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="allowloading"   defaultChecked={true}  /></td></>)
                                                         }

                                                       }else{

                                                       }
                                                        
                                                    })()}
                                                        {/* <td class="text-center"> {certifiedInspectionRequirements?certifiedInspectionRequirements.readingToAllowLoading=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center">{certifiedInspectionRequirements?certifiedInspectionRequirements.readingToAllowLoading=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td> */}
                                                   </tr>    

                                                   <tr>
                                                    <td>6. Are all gauge rods and any other compartment protrusions properly grounded with secure bonding wires?</td>
                                                    { (() =>
                                                    {
                                                       if(certifiedInspectionRequirements)
                                                       {
                                                         if (certifiedInspectionRequirements.secureBondingWires=='1') 
                                                         {
                                                      
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1" name="bondingwires"  defaultChecked={true}     /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="bondingwires" /></td></>)
                                                         }else 
                                                         {
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1"  name="bondingwires" /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="bondingwires"  defaultChecked={true}     /></td></>)
                                                         }

                                                       }else{

                                                       }
                                                        
                                                    })()}
                                                    {/* <td class="text-center"> {certifiedInspectionRequirements?certifiedInspectionRequirements.secureBondingWires=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{certifiedInspectionRequirements?certifiedInspectionRequirements.secureBondingWires=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td> */}
                                                   </tr>  

                                                   <tr>
                                                    <td>7. Is a functional brake interlock system installed on the loading header and vapor recovery hose?</td>
                                                    { (() =>
                                                    {
                                                       if(certifiedInspectionRequirements)
                                                       {
                                                         if (certifiedInspectionRequirements.hose=='1') 
                                                         {
                                                      
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1" name="hose"  defaultChecked={true}     /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="hose"       /></td></>)
                                                         }else 
                                                         {
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1"  name="hose" /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="hose"  defaultChecked={true}     /></td></>)
                                                         }

                                                       }else{

                                                       }
                                                        
                                                    })()}
                                                    {/* <td class="text-center"> {certifiedInspectionRequirements?certifiedInspectionRequirements.hose=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{certifiedInspectionRequirements?certifiedInspectionRequirements.hose=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td> */}
                                                   </tr>  
                                                 </tbody>
                                              </table>
                                        </div>

                                        <div class="table-2 mb-2">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Carrier Verification Requirements</th>
                                                    <th scope="col">Yes</th>
                                                    <th scope="col">No</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                   <td>1. Is an MC306, DOT406 or other specification plate installed?</td>
                                                   { (() =>
                                                    {
                                                       if(carrierVerificationRequirements)
                                                       {
                                                         if (carrierVerificationRequirements.specificationPlateInstalled=='1') 
                                                         {
                                                      
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1" name="plateinstalled"  defaultChecked={true}     /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="plateinstalled"   /></td></>)
                                                         }else 
                                                         {
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1"  name="plateinstalled" /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="plateinstalled"  defaultChecked={true}     /></td></>)
                                                         }

                                                       }else{

                                                       }
                                                        
                                                    })()}
                                                   {/* <td class="text-center"> {carrierVerificationRequirements?carrierVerificationRequirements.specificationPlateInstalled=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                   <td class="text-center">{carrierVerificationRequirements?carrierVerificationRequirements.specificationPlateInstalled=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td> */}
                                                  </tr>

                                                  <tr>
                                                    <td>2. Is proper placarding installed for the product(s) that are hauled?</td>
                                                    { (() =>
                                                    {
                                                       if(carrierVerificationRequirements)
                                                       {
                                                         if (carrierVerificationRequirements.productsThatareHauled=='1') 
                                                         {
                                                      
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1" name="thatarehauled"  defaultChecked={true}     /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="thatarehauled"       /></td></>)
                                                         }else 
                                                         {
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1"  name="thatarehauled" /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="thatarehauled"  defaultChecked={true}     /></td></>)
                                                         }

                                                       }else{

                                                       }
                                                        
                                                    })()}
                                                    {/* <td class="text-center"> {carrierVerificationRequirements?carrierVerificationRequirements.productsThatareHauled=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{carrierVerificationRequirements?carrierVerificationRequirements.productsThatareHauled=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td> */}
                                                   </tr>

                                                   <tr>
                                                    <td>3. Is the state DOT inspection or DOT 396/17 data current?</td>
                                                    { (() =>
                                                    {
                                                       if(carrierVerificationRequirements)
                                                       {
                                                         if (carrierVerificationRequirements.dataCurrent=='1') 
                                                         {
                                                      
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1" name="datacurrent"  defaultChecked={true}     /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="datacurrent"       /></td></>)
                                                         }else 
                                                         {
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1"  name="datacurrent" /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="datacurrent"  defaultChecked={true}     /></td></>)
                                                         }

                                                       }else{

                                                       }
                                                        
                                                    })()}
                                                    {/* <td class="text-center"> {carrierVerificationRequirements?carrierVerificationRequirements.dataCurrent=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{carrierVerificationRequirements?carrierVerificationRequirements.dataCurrent=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td> */}
                                                   </tr>

                                                     <tr>
                                                    <td>4. Are pressure, leakage and visual decals current?</td>
                                                    { (() =>
                                                    {
                                                       if(carrierVerificationRequirements)
                                                       {
                                                         if (carrierVerificationRequirements.decalsCurrent=='1') 
                                                         {
                                                      
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1" name="decalscurrent"  defaultChecked={true}     /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="decalscurrent"       /></td></>)
                                                         }else 
                                                         {
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1"  name="decalscurrent" /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="decalscurrent"  defaultChecked={true}     /></td></>)
                                                         }

                                                       }else{

                                                       }
                                                        
                                                    })()}
                                                    {/* <td class="text-center"> {carrierVerificationRequirements?carrierVerificationRequirements.includingGuidebook=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{carrierVerificationRequirements?carrierVerificationRequirements.includingGuidebook=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td> */}
                                                   </tr>

                                                   <tr>
                                                    <td>5. Is emergency response information (including guidebook) on board?
                                                        </td>
                                                        { (() =>
                                                    {
                                                       if(carrierVerificationRequirements)
                                                       {
                                                         if (carrierVerificationRequirements.includingGuidebook=='1') 
                                                         {
                                                      
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1" name="onboard"  defaultChecked={true} /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="onboard"    /></td></>)
                                                         }else 
                                                         {
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1"  name="onboard" /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="onboard"  defaultChecked={true}     /></td></>)
                                                         }

                                                       }else{

                                                       }
                                                        
                                                    })()}
                                                        {/* <td class="text-center"> {carrierVerificationRequirements?carrierVerificationRequirements.includingGuidebook=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center">{carrierEquipmentInspection?carrierEquipmentInspection.includingGuidebook=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td> */}
                                                   </tr>    

                                                   <tr>
                                                    <td>6. Is each tank/trailer marked with appropriate unit numbers?</td>
                                                    { (() =>
                                                    {
                                                       if(carrierVerificationRequirements)
                                                       {
                                                         if (carrierVerificationRequirements.appropriateUnitNumbers=='1') 
                                                         {
                                                      
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1" name="unitnumbers"  defaultChecked={true}     /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="unitnumbers"  /></td></>)
                                                         }else 
                                                         {
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1"  name="unitnumbers" /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="unitnumbers"  defaultChecked={true}     /></td></>)
                                                         }

                                                       }else{

                                                       }
                                                        
                                                    })()}
                                                    {/* <td class="text-center"> {carrierVerificationRequirements?carrierVerificationRequirements.availableUponRequest=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{carrierVerificationRequirements?carrierVerificationRequirements.availableUponRequest=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td> */}
                                                   </tr>  

                                                   <tr>
                                                    <td>7. Are compartment capacity charts current and available upon request?</td>
                                                    { (() =>
                                                    {
                                                       if(carrierVerificationRequirements)
                                                       {
                                                         if (carrierVerificationRequirements.availableUponRequest=='1') 
                                                         {
                                                      
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1" name="uponrequest"  defaultChecked={true}     /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="uponrequest"       /></td></>)
                                                         }else 
                                                         {
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1"  name="uponrequest" /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="uponrequest"  defaultChecked={true}     /></td></>)
                                                         }

                                                       }else{

                                                       }
                                                        
                                                    })()}
                                                    {/* <td class="text-center"> {carrierVerificationRequirements?carrierVerificationRequirements.presetsRecordedAbove=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{carrierVerificationRequirements?carrierVerificationRequirements.presetsRecordedAbove=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td> */}
                                                   </tr>  

                                                   <tr>
                                                    <td>8. Is each compartment loading headers matching with maximum presets recorded above?</td>
                                                    { (() =>
                                                    {
                                                       if(carrierVerificationRequirements)
                                                       {
                                                         if (carrierVerificationRequirements.presetsRecordedAbove=='1') 
                                                         {
                                                      
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1" name="recordedabove"  defaultChecked={true}     /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="recordedabove"       /></td></>)
                                                         }else 
                                                         {
                                                         return(<><td><input type="radio" aria-label="Radio button for following text input"  value="1"  name="recordedabove" /></td><td><input type="radio" aria-label="Radio button for following text input"  value="0" name="recordedabove"  defaultChecked={true}     /></td></>)
                                                         }

                                                       }else{

                                                       }
                                                        
                                                    })()}
                                                    {/* <td class="text-center"> {carrierVerificationRequirements?carrierVerificationRequirements.presetsRecordedAbove=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{carrierVerificationRequirements?carrierVerificationRequirements.presetsRecordedAbove=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td> */}
                                                   </tr>  
                                                 </tbody>
                                              </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="divider"></div>
                           
                            <div class="form-footer-comon-wrap">
                                <div class="row documents p-3">
                                   <div class="col-lg-12">
                                  {documentList.length>0?<h4 class="mb-4">Documents</h4>:""} 
                                      <div class="row">
                                      {
                                                                                                documentList?documentList.map((data, index) => (
                                         <div class="col-md-12" style={{marginTop:"-18px"}}>
                                            <div class="align-items-center">
                                              
                                              
                                                 {/* <b>{data.title}.{data.extension}</b> */}
                                                 <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                    <img src="" class="mr-2" />
                                                                    <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                    <a onClick={() => documentDetails_(data.filePath,data.extension)} class="ml-2 w-100"> {(() => {
                                                                            if (data.extension == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                        {data.name ? data.name : "--"} </a></p></span>
                                                                    <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" /> </div>
                                                 
                                               {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                            </div>
                                         </div>
                                        
                                       
                                         )):""
                                                                                               
                                       }
                                      </div>
                                   </div><br/>
                                   
                                </div>
                               
                                 <div class="row p-3">
                                      <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName}/>
                                 </div>                      
                                       <AddInspectionName setEndDate={setEndDate} endDate={endDate} />
                                
                                {/* <div class="divider2"></div>
                                <div class="row performby p-4">
                                   <div class="col-lg-12">
                                      <div class="row">
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Test Performed By:</label>
                                               <b>{inspectionDetails.inspectedBy}</b>
                                            </div>
                                         </div>
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Date</label>
                                               <b>{inspectionDetails.inspectedDate}</b>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div> */}
                            
                               
   
                             </div>

                            <div class="divider"></div>
                            <div class="row p-3 pr-4 d-flex justify-content-end" style={{marginBottom:"-5px"}}>
                                <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
                                {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a> */}
                                <button type="button" class="btn btn-primary mr-1" data-dismiss="modal"   onClick={() => save("DRAFT")}>Save as Draft</button>
                                {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("APPROVED PENDING")}>Save</a> */}
                                <button type="button" class="btn btn-primary mr-1" data-dismiss="modal"  onClick={() => save("APPROVED PENDING")}>Save</button>
                            </div>
                           </div>
                        </div>
                  </div>
                 
               </div>

            </div>
  </>
)};

CarrierEquipmentEdit.propTypes = {};

CarrierEquipmentEdit.defaultProps = {};

export default CarrierEquipmentEdit;
