import React, { history, useEffect, useState, useMemo } from "react";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import { useNavigate, useParams } from "react-router-dom";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import Adddocs from "../../../../utility/Adddocs";
import AddInspectionName from "../../../../utility/addInspectionName";
import removePdf from "../../../../utility/removePdf";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const CargoTankThicknessTestEdit = () => {
    const { taskOrderId,fkAccountId, inspectionId, vid } = useParams();
    const [inputList, setInputList] = useState([{}]);
    const [base64file, setBase64file] = useState('');
    const [loading, setLoading] = useLoading("Please wait...");
    const [fileName, setfileName] = useState("");
    const [inspectionDetails, setInspectionDetails] = useState("");
    const [documentList, setDocumentLists] = useState([]);
    const [inspectionTypeId, setinspectionTypeId] = useState("");
    const navigate = useNavigate();
    const [endDate, setEndDate] = useState(new Date());
    const [designpressure, setDesignPressure] = useState();
    const [expiryDate, setexpiryDate] = useState(new Date());
    const [checked, setAChecked] = useState();
    var documentLists = [];

    const [roles, setRoles] = useState("");
    const [statusInspection, setStatus] = useState("");
    const [verifiedDATE, setverifiedDate] = useState(new Date());
    useEffect(() => {
        setRoles(localStorage.getItem("Userroles"));

        // vehicle_details_api(id, setVehicleDetails, setCustomerId);
        getInpection(fkAccountId, inspectionId)
    }, []);

    $(document).ready(function () {

        $("#thicknessDesignPressure,#mawp").keypress(function (e) {
            //     if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
            //              return false;
            //   }
            if ((e.which != 46 || $(this).val().indexOf('.') != -1) &&
                ((e.which < 48 || e.which > 57) &&
                    (e.which != 0 && e.which != 8))) {
                return false;
            }

            var text = $(this).val();

            if ((text.indexOf('.') != -1) &&
                (text.substring(text.indexOf('.')).length > 3) &&
                (e.which != 0 && e.which != 8) &&
                ($(this)[0].selectionStart >= text.length - 3)) {
                return false;
            }
        });
    });

    const redirect = (page) => {

        navigate(page);

    }
    function getInpection(fkAccountId, inspectionId) {
        var requestObjectForWet =
        {

            "id": inspectionId
        }

        var requestOptions = requestObject.RequestHeader(requestObjectForWet);

        services.getInspectionDetails(requestOptions).then((res) => {

            setInspectionDetails(res.rows);
            setDocumentLists(res.rows.documentList ? res.rows.documentList : "");
            setinspectionTypeId(res.rows.inspectionType.id);
            documentLists.push(res.rows.documentList);
            // inspectionDetails.cargoTankThicknessTest?inspectionDetails.cargoTankThicknessTest.designPressure:""}
            // setDesignPressure(res.rows.inspectionDetails.cargoTankThicknessTest.designPressure);
            $('#thicknessDesignPressure').val(res.rows.cargoTankThicknessTest ? res.rows.cargoTankThicknessTest.designPressure : '');
            $("#workOrderNumber").val(res.rows ? res.rows.workOrderNumber : "");
            $("#notes").val(res.rows ? res.rows.notes : "");
            $("#inspectedBy").val(res.rows ? res.rows.inspectedBy : "");
            setEndDate(new Date(res.rows ? res.rows.inspectedDate : ""));
            sessionStorage.setItem("documentList", JSON.stringify(res.rows.documentList));
            setStatus(res.rows.status);

            if (res.rows) 
            {
                if(res.rows.verifiedDate) 
                {
                  setverifiedDate(new Date(res.rows ? res.rows.verifiedDate : ''));
                }
                if(res.rows.expiryDate)
                {
                 setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
                }
            }
            if (res.rows.cargoTankThicknessTest.minimumCorrissionAllowence == 1) {
                $("#corrosionallowance").prop('checked', true);
            } else {
                $("#corrosionallowance").prop('checked', false);
            }


        });

    }
    $("#corrosionallowance").change(function () {
        if (this.checked) {
            //setAChecked("1");
            $("input[name='corrosionallowance']").val("1");
        }
        else {
            $("input[name='corrosionallowance']").val("0");

        }
    });

    const deleteObj = (data, column, search) => {
        let result = data.filter(m => m[column] !== search);

        return result;
    }

    const handleSubmit = () => {

        let form_is_valid = true;

        var thicknessDesignPressure = $('#thicknessDesignPressure').val();
        var allowMax = /^[0-9]*$/;
        if (!thicknessDesignPressure) {
            form_is_valid = false;
            $("#thicknessDesignPressure-message").html('*Design pressure is required');

            // $('html, body').animate({
            //     scrollTop: $(".form-inspection-title").offset().top-80

            // }, 500);
            $('html,body').animate({ scrollTop: "500px" }, 1000);
            // $('html,body').animate({scrollTop: $(".header-top-bg").offset().top -120}, 'linear')
            return false

        } else {

            $("#thicknessDesignPressure-message").html('');
            form_is_valid = true;
        }



        var mawp = $('#mawp').val();
        if (!mawp) {
            form_is_valid = false;
            $("#mawp-message").html('*MAWP is required');
            // $('html, body').animate({
            //     scrollTop: $(".header-top-bg").offset().top
            // }, 500);
            $('html,body').animate({ scrollTop: "500px" }, 1000);
            return false
        } else {
            $("#mawp-message").html('');
            form_is_valid = true;
        }

        // var corrosionallowance = $("input[name='corrosionallowance']:checked").val();

        // if (corrosionallowance == undefined) {

        //     $('html, body').animate({
        //         scrollTop: $(".header-top-bg").offset().top
        //     }, 500);
        //     form_is_valid = false;
        //     $('#error_message').css("color", "#d33232").html("*Please select the checkbox");

        // } else {
        //     $('#error_message').html('');
        // }

        return form_is_valid;

    }
    const save = (status) => {
        // sessionStorage.setItem("verifiedBy",$("#verifiedBy").val());
        // console.log("results=="+JSON.stringify(inputList));

        var documentList = [];


        try {
            for (var i = 0; i < inputList.length; i++) {
                // console.log("filebase64=="+inputList[i].filetobase64);
                var extension = "";
                var byteString = "";
                if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
                    extension = "pdf";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
                } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
                    extension = "jpg";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:image/bmp;base64,")) {
                    extension = "bmp";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/bmp;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/docx;base64,")) {
                    extension = "docx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/docx;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/doc;base64,")) {
                    extension = "docx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/doc;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
                    extension = "png";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/msword;base64,")) {
                    extension = "word";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/msword;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/docx;base64,")) {
                    extension = "docx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/docx;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/doc;base64,")) {
                    extension = "docx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/doc;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,")) {
                    extension = "docx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,")) {
                    extension = "docm";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,")) {
                    extension = "docx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,")) {
                    extension = "dotx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,")) {
                    extension = "dotx";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,")) {
                    extension = "dotm";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", "") : "";
                }
                else {

                }

                documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });

            }
        } catch (exception) {
            // documentList.push();
            // Swal.fire({
            //     position: 'center',
            //     icon: 'error',
            //     title: "",
            //     text:"Please attach document",
            //     showConfirmButton: true

            //   }).then((result) =>
            //   {

            //         if(result.isConfirmed)
            //         {


            //         }
            //   });
            //   return false;
        }
        try {
            if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {

                var i;
                var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
                for (i = 0; i < storedArray.length; i++) {

                    documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
                }

            } else {

                documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
            }

        } catch (ex) {

        }
        if ($("#inspectedBy").val() === "") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Please enter Test Performed by",
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {


                }
            });
            return false;
        }

        if (status == 'DRAFT') {
            var requestObjectInspection =
            {
                "id": inspectionId,
                "expiryDate": $("#expiryDate").val(),
                "type": "Cargo Tank Thickness Test",
                "workOrderNumber": $("#workOrderNumber").val(),
                "notes": $("#notes").val(),
                "inspectedBy": $("#inspectedBy").val(),
                "inspectedDate": $("#inspectedDate").val(),
                "verifiedBy": $("#verifiedBy").val(),
                "verifiedDate": $("#verifiedBy").val(),
                "status": status,
                "taskOrderId":taskOrderId,
                "inspectionType": {
                    "id": inspectionTypeId,
                    "name": "Cargo Tank Thickness Test"
                },
                "result":
                {
                    "approvedCertify": "0",
                    "inspectionFailed": "0"
                },
                "dispositionOfVehicle":
                {
                    "vehicleReturnedServices": "0",
                    "vehicleWithdrawnFromService": "0",
                    "vehicleSentToAnotherRepairFacility": "0"
                },
                "vehicleDetails":
                {
                    "vinNumber": $("#vin").html(),
                    "lisencePlateNumber": $("#lisencePlateNumber").html(),
                    "id": vid,
                    "unitNumber": $("#lisencePlateNumber").html()
                },
                "cargoTankThicknessTest":
                {
                    "designPressure": $("#thicknessDesignPressure").val(),
                    "mawp": $("#mawp").val(),

                    // "thicknessTestWithoutException" : $("#thicknessTestWithoutException").val(),

                    // "thicknessTestAtVisualInspection" : $("#thicknessTestAtVisualInspection").val(),

                    // "sequencedInspectionFunction" : $("#sequencedInspectionFunction").val(),

                    // "currentThickness" :$("#currentThickness").val(),

                    "minimumCorrissionAllowence": $("input[name='corrosionallowance']:checked").val() === "1" ? "1" : "0"
                },

                "documentList": documentLists

            };
            // setLoading(true);
            $("#loadingforsubmit").show();
            var requestOptions = requestObject.RequestHeader(requestObjectInspection);
            services.addInspection(requestOptions).then((res) => {
                // setLoading(false);
                $("#loadingforsubmit").hide();
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: "",
                    text: "Your changes have been successfully saved!",
                    showConfirmButton: true

                }).then((result) => {

                    if (result.isConfirmed) {
                        navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

                    }
                });
            });
        } else {
            if (handleSubmit() == true) {
                var requestObjectInspection =
                {
                    "id": inspectionId,
                    "expiryDate": $("#expiryDate").val(),
                    "type": "Cargo Tank Thickness Test",
                    "workOrderNumber": $("#workOrderNumber").val(),
                    "notes": $("#notes").val(),
                    "inspectedBy": $("#inspectedBy").val(),
                    "inspectedDate": $("#inspectedDate").val(),
                    "verifiedBy": $("#verifiedBy").val(),
                    "verifiedDate": $("#verifiedBy").val(),
                    "status": status,
                    "taskOrderId":taskOrderId,
                    "inspectionType": {
                        "id": inspectionTypeId,
                        "name": "Cargo Tank Thickness Test"
                    },
                    "result":
                    {
                        "approvedCertify": "0",
                        "inspectionFailed": "0"
                    },
                    "dispositionOfVehicle":
                    {
                        "vehicleReturnedServices": "0",
                        "vehicleWithdrawnFromService": "0",
                        "vehicleSentToAnotherRepairFacility": "0"
                    },
                    "vehicleDetails":
                    {
                        "vinNumber": $("#vin").html(),
                        "lisencePlateNumber": $("#lisencePlateNumber").html(),
                        "id": vid,
                        "unitNumber": $("#lisencePlateNumber").html()
                    },
                    "cargoTankThicknessTest":
                    {
                        "designPressure": $("#thicknessDesignPressure").val(),
                        "mawp": $("#mawp").val(),

                        // "thicknessTestWithoutException" : $("#thicknessTestWithoutException").val(),

                        // "thicknessTestAtVisualInspection" : $("#thicknessTestAtVisualInspection").val(),

                        // "sequencedInspectionFunction" : $("#sequencedInspectionFunction").val(),

                        // "currentThickness" :$("#currentThickness").val(),

                        "minimumCorrissionAllowence": $("input[name='corrosionallowance']:checked").val() === "1" ? "1" : "0"
                    },

                    "documentList": documentLists

                };

                var requestOptionsObj = requestObject.RequestHeader(requestObjectInspection);

                if (statusInspection == "DRAFT") {
                    // setLoading(true);
                    $("#loadingforsubmit").show();
                    services.addInspection(requestOptionsObj).then((res) => {
                    //    setLoading(false);
                    $("#loadingforsubmit").hide();
                       Swal.fire({
                          position: 'center',
                          icon: 'success',
                          title: "",
                          text: "Your changes have been successfully saved!",
                          showConfirmButton: true

                       }).then((result) => {

                          if (result.isConfirmed) {
                             navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));
                          }
                       });
                    });
                 } else {

                    if ($('input:radio[name="returnedservice"]:checked').val() === "undefined" || $('input:radio[name="returnedservice"]:checked').val() === undefined) {
                       Swal.fire({
                          position: 'center',
                          icon: 'error',
                          title: "",
                          text: "Please select disposition of tank",
                          showConfirmButton: true

                       }).then((result) => {

                          if (result.isConfirmed) {

                          }
                       });
                       return false;
                    }
                    if ($('input:radio[name="approvedCertify"]:checked').val() === "" || $('input:radio[name="approvedCertify"]:checked').val() === undefined) {
                       Swal.fire({
                          position: 'center',
                          icon: 'error',
                          title: "",
                          text: "Please select inspection result",
                          showConfirmButton: true

                       }).then((result) => {

                          if (result.isConfirmed) {


                          }
                       });
                       return false;
                    }
                    if ($("input[name='approvedCertify']:checked").val() === "approvedCertify") {

                    } else {

                       status = "Rejected";
                    }

                    var requestObjectInspection =
                    {
                       "id": inspectionId,
                       "expiryDate": $("#expiryDate").val(),
                       "verifiedBy": $("#verifiedBy").val(),
                       "verifiedDate": $("#verifiedByBate").val(),
                       "status": status,

                       "result": {
                          "approvedCertify": $("input[name='approvedCertify']:checked").val() === "approvedCertify" ? "1" : "0",
                          "inspectionFailed": $("input[name='approvedCertify']:checked").val() === "inspectionFailed" ? "1" : "0"
                       },
                       "dispositionOfVehicle": {
                          "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
                          "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
                          "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
                       }

                    };
                    // setLoading(true);
                    $("#loadingforsubmit").show();
                    services.addInspection(requestOptionsObj).then((res) => {
                       // setLoading(false);
                       $("#loadingforsubmit").hide();
                       if (res.success) {

                          //certify inspection
                          var requestOptions = requestObject.RequestHeader(requestObjectInspection);
                          services.certifyInspection(requestOptions).then((res) => {
                            $("#loadingforsubmit").hide();
                             Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: "",
                                text: res.message,
                                showConfirmButton: true

                             }).then((result) => {

                                if (result.isConfirmed) {
                                   navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

                                }
                             });
                          });
                       } else {
                          Swal.fire({
                             position: 'center',
                             icon: 'success',
                             title: "",
                             text: "Your changes have been successfully saved!",
                             showConfirmButton: true

                          }).then((result) => {

                             if (result.isConfirmed) {
                                navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));
                             }
                          });

                       }
                    });



                 }
            }
        }

    }

    return (
        <>
        {LoadingComponentForSubmit}
            <div class="main-panel">
                <div class="content-wrapper" style={{ display: "block" }}>
                <div class="row mb-0">
                    <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                        <div class="d-flex justify-content-between align-items-center my-4">
                        <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-11 col-md-12 m-auto ">
                    <HeaderBlock cids={fkAccountId} />
                    </div>
                </div>

               

                <div class="row mb-2">
                    <div class="col-lg-11 col-md-12 m-auto ">
                        <div class="card">

                            <div class="row mb-2">
                                <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                                    <VehicleBasicInfo customerId={vid} />
                                </div>
                            </div>
                            <div class="divider"></div>
                            <div class="row p-3">
                                <div class="col-lg-12">
                                    <h4 class="form-inspection-title text-center">Cargo Tank Thickness Test</h4>
                                    <span id="thicknessDesignPressure-message" class="error"></span>
                                    <span id="mawp-message" class="error"></span>
                                    <p id="error_message" class="center error"></p>
                                    <WorkOrderNumberDiv />
                                </div>
                                {/* <WorkOrderNumberDiv/> */}
                                <div class="col-lg-12">
                                    <div class="inspection-wrap">


                                        <div class="table-2 mb-1 ">
                                            <table class="table">

                                                <tbody>

                                                    <tr class="p-2 ">
                                                        <td style={{ overflowX: "hidden" }}>
                                                            {/* <div class="row align-items-center justify-content-start mb-3  px-3 ">
                                                                <div class="col-md-9">1. Design Pressure. </div>
                                                                <div class="col-md-3 border-bottom"> <b>{inspectionDetails.cargoTankThicknessTest?inspectionDetails.cargoTankThicknessTest.designPressure:""}</b></div>
                                                            </div> */}



                                                            <div class="row">
                                                                <div class="col-md-6 mb-3">
                                                                    <div className="unitDzine2">
                                                                        <div> <strong>Design Pressure</strong> <span class="star">*</span></div>
                                                                        <div> <input type="text" class="form-control" defaultValue={inspectionDetails.cargoTankThicknessTest ? inspectionDetails.cargoTankThicknessTest.designPressure : ""} id="thicknessDesignPressure" aria-describedby="emailHelp" width="90px" /></div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6 mb-3">
                                                                    <div className="unitDzine2">
                                                                        <div><strong>MAWP</strong> <span class="star">*</span></div>
                                                                        <div> <input type="text" class="form-control" defaultValue={inspectionDetails.cargoTankThicknessTest ? inspectionDetails.cargoTankThicknessTest.mawp : ""} id="mawp" aria-describedby="emailHelp" width="90px" /></div>
                                                                    </div>
                                                                </div></div>


                                                            <div class="row align-items-center justify-content-start mb-3  px-3 ">
                                                                <div class="col-md-12">1. Thickness test must be performed in numerical sequence without exception. </div>
                                                                {/* <div class="col-md-3 border-bottom"> <b>{inspectionDetails.cargoTankThicknessTest?inspectionDetails.cargoTankThicknessTest.thicknessTestWithoutException:""}</b></div> */}
                                                            </div>

                                                            <div class="row align-items-center justify-content-start mb-3  px-3">
                                                                <div class="col-md-12">2. Thickness test must be performed at completion of internal visual inspection (when thickness testing is required)</div>
                                                                {/* <div class="col-md-3 border-bottom"> <b>{inspectionDetails.cargoTankThicknessTest?inspectionDetails.cargoTankThicknessTest.thicknessTestAtVisualInspection:""}</b></div> */}
                                                            </div>

                                                            <div class="row align-items-center justify-content-start mb-3  px-3">
                                                                <div class="col-md-12">3. Abrated or corroded areas found internally or externally on cargo tank during thickness test must be identified (circle area) on drawing on
                                                                    reverse side and must be thoroughly thickness tested ONLY UPON COMPLETION OF SEQUENCED INSPECTION FUNCTION. </div>
                                                                {/* <div class="col-md-3 border-bottom"> <b>{inspectionDetails.cargoTankThicknessTest?inspectionDetails.cargoTankThicknessTest.sequencedInspectionFunction:""}</b></div> */}
                                                            </div>

                                                            <div class="row align-items-center justify-content-start mb-2  px-3">
                                                                <div class="col-md-12">4. Bolsters, frames, rings and sub-frames must be thoroughly inspected. Any areas that show corrosion, rust or deterioration must        be thickness tested. Areas found to be less than minimum thickness must be reported in comment section noting location and current thickness. </div>
                                                                {/* <div class="col-md-3 border-bottom"> <b>{inspectionDetails.cargoTankThicknessTest?inspectionDetails.cargoTankThicknessTest.currentThickness:""}</b></div> */}
                                                            </div>

                                                            <div class="d-flex align-items-start mb-3">
                                                                <div class="col-md-12"> 5. <input type="checkbox" aria-label="Checkbox for following text input" name="corrosionallowance" id="corrosionallowance" value='1' class="mr-2 cb-ins" style={{ position: "relative", top: "14px", left: "4px" }} /> Check box if tank must be thickness tested annually because last results are within .005" of minimum corrosion allowance.</div>
                                                                {/* <div class="col-md-3 "> {inspectionDetails.cargoTankThicknessTest?inspectionDetails.cargoTankThicknessTest.minimumCorrissionAllowence=='1'?"Yes":"No":""}</div> */}
                                                            </div>



                                                        </td>



                                                    </tr>



                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="divider2"></div>
                            <div class="form-footer-comon-wrap">
                                <div class="row documents p-3">
                                    <div class="col-lg-12">
                                        {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                        <div class="row">
                                            {
                                                documentList ? documentList.map((data, index) => (
                                                    <div class="col-md-12">
                                                        <div class="align-items-center">

                                                            
                                                                {/* <b>{data.title}.{data.extension}</b> */}
                                                                <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                    <img src="" class="mr-2" />
                                                                    <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                    <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100">{(() => {
                                                                            if (data.extension == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                        {data.name ? data.name : "--"}</a> </p></span>
                                                                    <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" /> </div>
                                                            
                                                            {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                                        </div>
                                                    </div>


                                                )) : ""

                                            }
                                        </div>
                                    </div><br />

                                </div>
                                <div class="row p-3">
                                    <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                                </div>
                                <AddInspectionName setEndDate={setEndDate} endDate={endDate} />

                                {statusInspection == "DRAFT" ? "" : <div class="divider"></div>}
                        {(() => {
                           if (statusInspection == "DRAFT") {
                              <></>
                           } else {
                              if (!roles.match("Technician")) {
                                 if (inspectionDetails.status == 'approved' || inspectionDetails.status == 'approve' || inspectionDetails.status == 'APPROVED' || inspectionDetails.status == 'Rejected' || inspectionDetails.status == 'REJECTED') {
                                    return (
                                       <>
                                          <div class="result-didspos">
                                             <div class="p-4">
                                                <span>Result</span>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Inspection Failed</b>
                                                </div>
                                             </div>
                                             <div class="p-4">
                                                <span>Disposition of Tank</span>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : ""}
                                                   <b class="mb-0 ml-2">Tank Returned to Service</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                                </div>
                                             </div>
                                          </div>
                                          <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection}  customerId={fkAccountId}/>
                                          {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} status={"readOnly"} customerId={fkAccountId}/> */}
                                       </>
                                    )
                                 } else {
                                    return (
                                       <><div class="result-didspos ">
                                          <div class="p-4">
                                             <span>Result</span>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                             </div>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Inspection Failed</b>
                                             </div>
                                          </div>
                                          <div class="p-4">
                                             <span>Disposition of Tank</span>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Tank returned to service</b>
                                             </div>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                             </div>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                             </div>
                                          </div>
                                       </div>
                                       <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate}  customerId={fkAccountId}/>
                                          {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} customerId={fkAccountId}/> */}


                                       </>
                                    )
                                 }
                              }
                           }
                        })()}


                            </div>

                            <div class="divider2"></div>
                            <div class="row p-3 pr-4 d-flex justify-content-end">
                                <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
                                {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a> */}
                                {statusInspection == "DRAFT"?<button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("DRAFT")}>Save as Draft</button>:""}

                                {(() => {
                              if (statusInspection == "DRAFT") {
                                 return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("APPROVED PENDING")}>Save</button></>)
                              } else {
                                 if (roles.match("Manager")||roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
                                    return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("approved")}>Save</button></>)
                                 }
                                 else {
                                    return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("APPROVED PENDING")}>Save</button></>)
                                 }
                              }
                           })()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            </div>

        </>
    )
};

CargoTankThicknessTestEdit.propTypes = {};

CargoTankThicknessTestEdit.defaultProps = {};

export default CargoTankThicknessTestEdit;
