import React, { history, useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import getDateAndTimeStamp from "../../../../utility/getTimeStamp";
import moment from "moment";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const GasolineDeliveryTankPressureVacuumTestRead = () => {
   const { fkAccountId, inspectionId, vid } = useParams();
   const [inputList, setInputList] = useState([{}]);
   const [base64file, setBase64file] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const [fileName, setfileName] = useState("");
   const [inspectionDetails, setInspectionDetails] = useState("");
   const [documentList, setDocumentLists] = useState([]);
   const [pressureTest, setPressureTest] = useState([]);
   const [verifiedDATE, setverifiedDate] = useState(new Date());
   const [expiryDate, setexpiryDate] = useState(new Date());


    const [pressureTest1startTime, setPressureTest1startTime] = useState();
    const [pressureTest1finishTime, setPressureTest1finishTime] = useState(null);
    const [pressureTest2startTime, setPressureTest2startTime] = useState(null);
    const [pressureTest2finishTime, setPressureTest2finishTime] = useState(null);
    const [pressureTest3startTime, setPressureTest3startTime] = useState(null);
    const [pressureTest3finishTime, setPressureTest3finishTime] = useState(null);

    const [vaccumeTest1startTime, setVaccumeTest1startTime] = useState(null);
    const [vaccumeTest1finishTime, setVaccumeTest1finishTime] = useState(null);
    const [vaccumeTest2startTime, setVaccumeTest2startTime] = useState(null);
    const [vaccumeTest2finishTime, setVaccumeTest2finishTime] = useState(null);
    const [vaccumeTest3startTime, setVaccumeTest3startTime] = useState(null);
    const [vaccumeTest3finishTime, setVaccumeTest3finishTime] = useState(null);

    const [vaporValveTeststartTime, setVaporValveTeststartTime] = useState(null);
    const [vaporValveTestfinishTime, setVaporValveTestfinishTime] = useState(null);
    const [vaporValveTest2startTime, setVaporValveTest2startTime] = useState(null);
    const [vaporValveTest2finishTime, setVaporValveTest2finishTime] = useState(null);
    const [vaporValveTest3startTime, setVaporValveTest3startTime] = useState(null);
    const [vaporValveTest3finishTime, setVaporValveTest3finishTime] = useState(null);

   // const [initialReading, setpressureTestIntial] = useState([]);
   // const [initialReadingVaccume, setvaccumeTestIntial] = useState([]);
   // const [startTime, setpressureTestStartTime] = useState([]);
   // const [vaccumestartTime, setvaccumeTestStartTime] = useState([]);

   // const [finalReading, setfinalReading] = useState([]);
   
   // const [repairmode, setrepairMode] = useState([]);
   // const [finishTime, setfinishTime] = useState([]);

   // const [vaccumefinish, setvaccumefinishTime] = useState([]);

   // const [vaccumerepair, setvaccumerepairemode] = useState([]);
   const [vaccumeTestResults, setvaccumeTestResults] = useState([]);
   const [internalVaporValueTest, setinternalVaporValueTest] = useState([]);


   const navigate = useNavigate();
   const [endDate, setEndDate] = useState(new Date());
   const [roles, setRoles] = useState("");
   const [statusInspection, setStatus] = useState("");
   useEffect(() => {
     setRoles(localStorage.getItem("Userroles"));

      // vehicle_details_api(id, setVehicleDetails, setCustomerId); 
      getInpection(fkAccountId, inspectionId)
   }, []);

   function getInpection(fkAccountId, inspectionId) {
      var requestObjectForWet =
      {
         
         "id": inspectionId
      }

      var requestOptions = requestObject.RequestHeader(requestObjectForWet);

      services.getInspectionDetails(requestOptions).then((res) => {
         var array=[];
         setPressureTest([res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults]);
        // array.push(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults);
         
         setvaccumeTestResults([res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults]);
         setinternalVaporValueTest([res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest]);

          // ================================1===============
          setPressureTest1startTime(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.startingTime[0]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.startingTime[0])):"");
          setPressureTest2startTime(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.startingTime[1]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.startingTime[1])):"");
          setPressureTest3startTime(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.startingTime[2]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.startingTime[2])):"");
          
          setPressureTest1finishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.finishTime[0]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.finishTime[0])):"");
          setPressureTest2finishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.finishTime[1]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.finishTime[1])):"");
          setPressureTest3finishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.finishTime[2]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.finishTime[2])):"");
        
            // ================================2===============
 
          setVaccumeTest1startTime(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.startingTime[0]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.startingTime[0])):"");
          setVaccumeTest2startTime(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.startingTime[1]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.startingTime[1])):"");
          setVaccumeTest3startTime(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.startingTime[2]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.startingTime[2])):"");
          
          setVaccumeTest1finishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.finishTime[0]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.finishTime[0])):"");
          setVaccumeTest2finishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.finishTime[1]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.finishTime[1])):"");
          setVaccumeTest3finishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.finishTime[2]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.finishTime[2])):"");
         
           // ================================3===============
 
          setVaporValveTeststartTime(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.startingTime[0]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.startingTime[0])):"");
          setVaporValveTest2startTime(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.startingTime[1]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.startingTime[1])):"");
          setVaporValveTest3startTime(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.startingTime[2]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.startingTime[2])):"");
          
          setVaporValveTestfinishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.finishTime[0]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.finishTime[0])):"");
          setVaporValveTest2finishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.finishTime[1]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.finishTime[1])):"");
          setVaporValveTest3finishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.finishTime[2]?new Date(getDateAndTimeStamp(res.rows.gasolineDeliveryTankPressureVacuumTest.internalVaporValueTest.finishTime[2])):"");
 
          // setvaccumeTestStartTime(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.startingTime);
 
          // setfinalReading(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.finalReading);
          // setvaccumefinalReading(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.finalReading);
 
   // setpressureTestIntial(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.initialReading);
         // setvaccumeTestIntial(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.initialReading);

         // setpressureTestStartTime(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.startingTime);
         // setvaccumeTestStartTime(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.startingTime);

         // setfinalReading(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.finalReading);
         // setvaccumefinalReading(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.finalReading);


         // setfinishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.finishTime);
         // setvaccumefinishTime(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.finishTime);


         // setrepairMode(res.rows.gasolineDeliveryTankPressureVacuumTest.pressureTestResults.reapirsMade);
         // setvaccumerepairemode(res.rows.gasolineDeliveryTankPressureVacuumTest.vaccumeTestResults.reapirsMade);

         setInspectionDetails(res.rows);
         setDocumentLists(res.rows.documentList?res.rows.documentList:"");
         $("#workOrderNumber").val(res.rows?res.rows.workOrderNumber:"");
         // sessionStorage.setItem("verifiedBy",res.rows ? res.rows.verifiedBy : "");
         setStatus(res.rows.status);
         if(res.rows)
         {
            if(res.rows.verifiedDate)
            {
               setverifiedDate(new Date(res.rows?res.rows.verifiedDate:''));
            }
           
            if(res.rows.expiryDate)
            {
               setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
            }
           
         }
      });

   }
   const save=(status)=>
   {
 
   // console.log("results=="+JSON.stringify(inputList));
   if($("#inspectedBy").val()==="")
   {
     Swal.fire({  
         position: 'center',  
         icon: 'error',  
         title: "",  
         text:"Please enter Inspected by",
         showConfirmButton: true 
         
       }).then((result) => 
       {
             
             if(result.isConfirmed) 
             {
               
             
             }
       });
       return false;
   }
   if( $('input:radio[name="returnedservice"]:checked').val()==="undefined"||$('input:radio[name="returnedservice"]:checked').val()===undefined)
   {
     Swal.fire({  
         position: 'center',  
         icon: 'error',  
         title: "",  
         text:"Please select disposition of tank",
         showConfirmButton: true 
         
       }).then((result) => 
       {
             
             if(result.isConfirmed) 
             {
               
             
             }
       });
       return false;
   }
   if( $('input:radio[name="approvedCertify"]:checked').val()===""|| $('input:radio[name="approvedCertify"]:checked').val()===undefined)
   {
     Swal.fire({  
         position: 'center',  
         icon: 'error',  
         title: "",  
         text:"Please select inspection result",
         showConfirmButton: true 
         
       }).then((result) => 
       {
             
             if(result.isConfirmed) 
             {
               
             
             }
       });
       return false;
   }
         if($("input[name='approvedCertify']:checked").val()==="approvedCertify")
         {

         }else{

         status="Rejected";
         }
         var requestObjectInspection=
         {
           "id": inspectionId,
           "expiryDate": $("#expiryDate").val(),
            "verifiedBy": $("#verifiedBy").val(),
           "verifiedDate": $("#verifiedByBate").val(),
           "status": status,
          
           "result": {
            "approvedCertify":$("input[name='approvedCertify']:checked").val()==="approvedCertify"?"1":"0",
            "inspectionFailed": $("input[name='approvedCertify']:checked").val()==="inspectionFailed"?"1":"0"
          },
          "dispositionOfVehicle": {
            "vehicleReturnedServices":$("input[name='returnedservice']:checked").val()==="returnedservice"?"1":"0",
            "vehicleWithdrawnFromService":$("input[name='returnedservice']:checked").val()==="withdrawnservice"?"1":"0",
            "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val()==="repairfacility"?"1":"0"
          }
          
         
       }; 
 
       $("#loadingforsubmit").show();
      //  setLoading(true);
       // const requestOptions = 
       // {
       //   method: 'POST',
       //   headers: { 'Content-Type': 'application/json'
       //             },
                   
       //       body: JSON.stringify(requestObject)
       // };
       var requestOptions=requestObject.RequestHeader(requestObjectInspection);
       services.certifyInspection(requestOptions).then((res) => 
       {
         $("#loadingforsubmit").hide();
         // setLoading(false);
         Swal.fire({  
           position: 'center',  
           icon: 'success',  
           title: "",  
           text:res.message,
           showConfirmButton: true 
           
         }).then((result) => 
         {
               
               if(result.isConfirmed) 
               {
                 navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));
               
               }
         });
       });
 
   }
   const redirect=(page)=>
   {

      navigate(page);
   
   }
   return (
      <>
      {LoadingComponentForSubmit}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block" }}>

               <div class="row mb-4">
                  <div class="col-lg-11 col-md-12 m-auto ">
                  <HeaderBlock cids={fkAccountId} />
                  </div>
               </div>

               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <div class="d-flex justify-content-between align-items-center">
                        <h4 class="font-weight-bold">Vehicle Inspection</h4>
                        <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-white">Back</a>
                     </div>
                  </div>
               </div>

               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                     <div class="card">

                        <div class="row mb-2">
                           <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                              <VehicleBasicInfo customerId={vid} />
                           </div>
                        </div>
                        <div class="divider"></div>

                        <div class="row p-3">
                           <div class="col-lg-12">
                              <h4 class="mb-2 form-inspection-title text-center">Gasoline Delivery tank - Pressure Vacuum test results</h4>
                           </div>
                           <div class="col-lg-12"><WorkOrderNumberDiv status={"readOnly"} /></div>
                           <div class="col-lg-12">
                              <div class="inspection-wrap">

                                 <div class="table-4 mb-4">
                                    <div class="p-4 text-center">
                                       <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Pressure Test Results</h5>
                                    </div>
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col">Run</th>
                                             <th scope="col">Initial Reading(Inches of water) </th>
                                             <th scope="col">Starting Time</th>
                                             <th scope="col">Final Reading(Inches of water) </th>
                                             <th scope="col">Finish Time</th>
                                             <th scope="col">Repairs Made</th>

                                          </tr>
                                       </thead>
                                       <tbody>
                                       {
                                                pressureTest ? pressureTest.map((data, index) => (
                                         <tr>
                                            <td class="text-center">{index+1}</td>
                                            <td class="text-center">{data.initialReading[index]}</td>
                                            <td class="text-center">{pressureTest1startTime?moment(pressureTest1startTime).format("HH:mm"):""}</td>
                                            <td class="text-center">{data.finalReading [index]}</td>
                                            <td class="text-center">{pressureTest1finishTime?moment(pressureTest1finishTime).format("HH:mm"):""}</td>
                                            <td class="text-center">{data.reapirsMade [index]}</td>
                                         </tr>
                                          )) : ""

                                       }
                                          {
                                                pressureTest ? pressureTest.map((data, index) => (
                                         <tr>
                                            <td class="text-center">{index+2}</td>
                                            <td class="text-center">{data.initialReading[1]}</td>
                                            <td class="text-center">{pressureTest2startTime?moment(pressureTest2startTime).format("HH:mm"):""}</td>
                                            <td class="text-center">{data.finalReading[1]}</td>
                                            <td class="text-center">{pressureTest2finishTime?moment(pressureTest2finishTime).format("HH:mm"):""}</td>
                                            <td class="text-center">{data.reapirsMade [1]}</td>
                                         </tr>
                                          )) : ""

                                       }
                                        {
                                                pressureTest ? pressureTest.map((data, index) => (
                                         <tr>
                                            <td class="text-center">{index+3}</td>
                                            <td class="text-center">{data.initialReading[2]}</td>
                                            <td class="text-center">{pressureTest3startTime?moment(pressureTest3startTime).format("HH:mm"):""}</td>
                                            <td class="text-center">{data.finalReading [2]}</td>
                                            <td class="text-center">{pressureTest3finishTime?moment(pressureTest3finishTime).format("HH:mm"):""}</td>
                                            <td class="text-center">{data.reapirsMade [2]}</td>
                                         </tr>
                                          )) : ""

                                       }

                                        

                                       </tbody>
                                    </table>
                                 </div>

                                 <div class="table-4 mb-4">
                                    <div class="p-4 text-center">
                                       <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Vacuum Test Results</h5>
                                    </div>
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col">Run</th>
                                             <th scope="col">Initial Reading(Inches of water) </th>
                                             <th scope="col">Starting Time</th>
                                             <th scope="col">Final Reading(Inches of water) </th>
                                             <th scope="col">Finish Time</th>
                                             <th scope="col">Repairs Made</th>

                                          </tr>
                                       </thead>
                                       <tbody>
                                       {
                                          vaccumeTestResults ? vaccumeTestResults.map((data, index) => (
                                         <tr>
                                            <td class="text-center">{index+1}</td>
                                            <td class="text-center">{data.initialReading[index]}</td>
                                            <td class="text-center">{vaccumeTest1startTime?moment(vaccumeTest1startTime).format("HH:mm"):""}</td>
                                            <td class="text-center">{data.finalReading [index]}</td>
                                            <td class="text-center">{vaccumeTest1finishTime?moment(vaccumeTest1finishTime).format("HH:mm"):""}</td>
                                            <td class="text-center">{data.reapirsMade [index]}</td>
                                         </tr>
                                          )) : ""

                                       }
                                          {
                                             vaccumeTestResults ? vaccumeTestResults.map((data, index) => (
                                         <tr>
                                            <td class="text-center">{index+2}</td>
                                            <td class="text-center">{data.initialReading[1]}</td>
                                            <td class="text-center">{vaccumeTest2startTime?moment(vaccumeTest2startTime).format("HH:mm"):""}</td>
                                            <td class="text-center">{data.finalReading [1]}</td>
                                            <td class="text-center">{vaccumeTest2finishTime?moment(vaccumeTest2finishTime).format("HH:mm"):""}</td>
                                            <td class="text-center">{data.reapirsMade [1]}</td>
                                         </tr>
                                          )) : ""

                                       }
                                        {
                                          vaccumeTestResults ? vaccumeTestResults.map((data, index) => (
                                         <tr>
                                            <td class="text-center">{index+3}</td>
                                            <td class="text-center">{data.initialReading[2]}</td>
                                            <td class="text-center">{vaccumeTest3startTime?moment(vaccumeTest3startTime).format("HH:mm"):""}</td>
                                            <td class="text-center">{data.finalReading [2]}</td>
                                            <td class="text-center">{vaccumeTest3finishTime?moment(vaccumeTest3finishTime).format("HH:mm"):""}</td>
                                            <td class="text-center">{data.reapirsMade [2]}</td>
                                         </tr>
                                          )) : ""

                                       }

                                        

                                       </tbody>
                                    </table>
                                 </div>

                                 <div class="table-4 mb-4">
                                    <div class="p-4 text-center">
                                       <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Internal vapor valve Test</h5>
                                    </div>
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col">Run</th>
                                             <th scope="col">Initial Reading(Inches of water) </th>
                                             <th scope="col">Starting Time</th>
                                             <th scope="col">Final Reading(Inches of water) </th>
                                             <th scope="col">Finish Time</th>
                                             <th scope="col">Repairs Made</th>

                                          </tr>
                                       </thead>
                                       <tbody>
                                       {
                                          internalVaporValueTest ? internalVaporValueTest.map((data, index) => (
                                         <tr>
                                            <td class="text-center">{index+1}</td>
                                            <td class="text-center">{data.initialReading[index]}</td>
                                            <td class="text-center">{vaporValveTeststartTime?moment(vaporValveTeststartTime).format("HH:mm"):""}</td>
                                            <td class="text-center">{data.finalReading [index]}</td>
                                            <td class="text-center">{vaporValveTestfinishTime?moment(vaporValveTestfinishTime).format("HH:mm"):""}</td>
                                            <td class="text-center">{data.reapirsMade [index]}</td>
                                         </tr>
                                          )) : ""

                                       }
                                          {
                                             internalVaporValueTest ? internalVaporValueTest.map((data, index) => (
                                         <tr>
                                            <td class="text-center">{index+2}</td>
                                            <td class="text-center">{data.initialReading[1]}</td>
                                            <td class="text-center">{vaporValveTest2startTime?moment(vaporValveTest2startTime).format("HH:mm"):""}</td>
                                            <td class="text-center">{data.finalReading [1]}</td>
                                            <td class="text-center">{vaporValveTest2finishTime?moment(vaporValveTest2finishTime).format("HH:mm"):""}</td>
                                            <td class="text-center">{data.reapirsMade [1]}</td>
                                         </tr>
                                          )) : ""

                                       }
                                        {
                                          internalVaporValueTest ? internalVaporValueTest.map((data, index) => (
                                         <tr>
                                            <td class="text-center">{index+3}</td>
                                            <td class="text-center">{data.initialReading[2]}</td>
                                            <td class="text-center">{vaporValveTest3startTime?moment(vaporValveTest3startTime).format("HH:mm"):""}</td>
                                            <td class="text-center">{data.finalReading [2]}</td>
                                            <td class="text-center">{vaporValveTest3finishTime?moment(vaporValveTest3finishTime).format("HH:mm"):""}</td>
                                            <td class="text-center">{data.reapirsMade [2]}</td>
                                         </tr>
                                          )) : ""

                                       }

                                        

                                       </tbody>
                                    </table>
                                 </div>



                              </div>
                           </div>
                        </div>
                        <div class="divider2"></div>
                        <div class="form-footer-comon-wrap">
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                    {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                    <div class="row">
                                        {
                                            documentList ? documentList.map((data, index) => (
                                                <div class="col-md-12">
                                                    <div class="d-flex align-items-center">
                                                        <img src="images/icons/pdf-icon.svg" alt="" />
                                                        <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100">
                                                            {/* <b>{data.title}.{data.extension}</b> */}
                                                                <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                    <img src="" class="mr-2" />
                                                                    <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                        {(() => {
                                                                            if (data.extension == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                        {data.name ? data.name : "--"} </p></span>
                                                                    {/* <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" />  */}
                                                                    </div>
                                                        </a>
                                                        {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                                    </div>
                                                </div>


                                            )) : ""

                                        }
                                    </div>
                                </div><br />
                                   
                                </div>
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                      <h6 class="mb-4">Notes: </h6>
                                      {inspectionDetails?inspectionDetails.notes:''}
                                   </div>
                                   </div>
                                <div class="divider2"></div>
                                <div class="row performby p-4">
                                   <div class="col-lg-12">
                                      <div class="row">
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Test Performed By:</label>
                                               <b>{inspectionDetails.inspectedBy}</b>
                                            </div>
                                         </div>
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Date</label>
                                               <b>{inspectionDetails.inspectedDate}</b>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                            
                                <div class="divider"></div>
                                {(() => {
                                   if(!roles.match("Technician")){
        if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED') {
          return (
            <>
            <div class="result-didspos">
                                   <div class="p-4">
                                      <span>Result</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Inspection Failed</b>
                                      </div>
                                   </div>
                                   <div class="p-4">
                                      <span>Disposition of Tank</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:""}
                                         <b class="mb-0 ml-2">Tank Returned to Service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                      </div>
                                   </div>
                                </div>
                                <div class="divider"></div>
                                <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection}  customerId={fkAccountId}/>
                                {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} status={"readOnly"} /> */}
            </>
          )
        } else {
          return (
            <><div class="result-didspos ">
            <div class="p-4">
               <span>Result</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Approve &amp; Certify</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Inspection Failed</b>
               </div>
            </div>
            <div class="p-4">
               <span>Disposition of Tank</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="1" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"/>:""}
                  <b class="mb-0 ml-2">Tank returned to service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank withdrawn from service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank sent to another repair facility</b>
               </div>
            </div>
         </div>
         <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} customerId={fkAccountId}/>
         {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} /> */}
         {/* <div class="divider"></div>
         <div class="row verifyby p-4">
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Verified By</label>
                  <input type="text" class="form-control custome-input" id="verifiedBy" value={inspectionDetails.verifiedBy} aria-describedby="emailHelp" placeholder="Enter Name"/>
               </div>
            </div>
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Date</label>
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="verifiedByBate" />
                
               </div>
            </div>
         </div> */}
         
         </>
          )
        }
      }
      })()}
                             </div>

                        <div class="divider2"></div>
                        {(() => 
                            {
                                 if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED') 
                                 {
                                 }
                                 else
                                 {
                                   return(<> <div class="row p-3 pr-4 d-flex justify-content-end">
                                   <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-secondary mr-1 ">Cancel</a>
                                       {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a> */}
                                       {roles.match("Technician")?"":<a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a>}
                                   {roles.match("Technician")?<a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-primary mr-1">Back</a>:""}
                                   </div></>)
                                 }
                           })()}
                     </div>
                  </div>
               </div>

            </div>

         </div>
      </>
   )
};

GasolineDeliveryTankPressureVacuumTestRead.propTypes = {};

GasolineDeliveryTankPressureVacuumTestRead.defaultProps = {};

export default GasolineDeliveryTankPressureVacuumTestRead;