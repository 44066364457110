import React,{history,useEffect, useState, useMemo } from "react";

const FleetVehicleAtGarage = () => {

  return(
  <>
    <div class="main-panel">
                <div class="content-wrapper" style={{display: "block"}}>
                    <div class="row">
                        <div class="col-lg-11 col-md-12 mb-2 m-auto">
                            <div class="d-flex mb-3 align-items-center justify-content-between">
                                <h4 class="font-weight-bold text-uppercase">Vehicle at garage</h4>
                                <a  class="btn btn-white">Back</a>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <div class="card p-3">
                                <div class="banner-owner">
                                    <div class="row">
                                        <div class="col">
                                            <div class="owner-logo mb-3">
                                                <img src="/images/transport-logo.jpg" alt="" style={{width: "70px"}}/>
                                                <div class="owner-name">
                                                    <h4>Princeton Garage</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <a href="#" class="btn btn-success" data-toggle="modal" data-target="#receivedVehicle">Received Vehicle</a>
                                        </div>
                                    </div>

                                    <div class="banner-contact-info">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                                    <img src="/images/icons/map-icon.svg" alt="" class="mr-2"/>
                                                    <div style={{minWidth: "240px"}}>
                                                        <span>Baltimore, MD - 21216</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                                    <img src="/images/icons/phone-icon.svg" alt="" class="mr-2"/>
                                                    <span>(617) 399 8424</span>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                                    <img src="/images/icons/message-icon.svg" alt="" class="mr-2"/>
                                                    <span>info@abcdtransport.com</span>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                                    <img src="/images/icons/message-icon.svg" alt="" class="mr-2"/>
                                                    <span>(617) 399 8424</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <div class="row no-gutters">
                                <div class="col-md-4">
                                    <div class="card mr-2">
                                        <div class="vehicle_pic_wrap" style={{maxHeight: "228px"}}>
                                            <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#editPhoto">
                                       <img src="/images/icons/edit-icon.svg"/></a>
                                 </span>
                                            <img src="/images/vehicle_info.png"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="card">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="p-3 d-flex flex-column">
                                                    <label for="" class="opacity-7">Fleet Owners Unit Number </label>
                                                    <b>302</b>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="p-3 d-flex flex-column">
                                                    <label for="" class="opacity-7">VIN Number </label>
                                                    <b>IPMS44324T1017489</b>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="p-3 d-flex flex-column">
                                                    <label for="" class="opacity-7">Serial Number</label>
                                                    <b>IPMS44324T1017489</b>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="p-3 d-flex flex-column">
                                                    <label for="" class="opacity-7">Make</label>
                                                    <b>Polar</b>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="p-3 d-flex flex-column">
                                                    <label for="" class="opacity-7">Model</label>
                                                    <b>F150</b>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="p-3 d-flex flex-column">
                                                    <label for="" class="opacity-7">Year</label>
                                                    <b>1996</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row mb-2 ">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <div>
                                <ul class="nav nav-pills tab-custome" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Vehicle details</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Inspection</a>
                                    </li>
                                </ul>

                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <div class="card mb-2">
                                            <div class="row mb-2">
                                                <div class="col-lg-12 col-md-12 m-auto grid-margin stretch-card ">
                                                    <div class="header-top-bg p-3 ">
                                                        <h4>Vehicle Information</h4>
                                                        <div class="info-labl">
                                                            <img src="/images/icons/vehicle-icon.svg" alt=""/>|<span>ERS 4343</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="p-3 d-flex flex-column">
                                                        <label for="" class="opacity-7">Vehicle Type</label>
                                                        <b>Trailer</b>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="p-3 d-flex flex-column">
                                                        <label for="" class="opacity-7">Trailer Sub Type</label>
                                                        <b>Tanker</b>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="p-3 d-flex flex-column">
                                                        <label for="" class="opacity-7">Tanker Sub Type</label>
                                                        <b>Fuel</b>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="p-3 d-flex flex-column">
                                                        <label for="" class="opacity-7">Insulated</label>
                                                        <b>Yes</b>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="p-3 d-flex flex-column">
                                                        <label for="" class="opacity-7">Lined</label>
                                                        <b>Yes</b>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="p-3 d-flex flex-column">
                                                        <label for="" class="opacity-7">Lining Type</label>
                                                        <b>XXXX</b>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="p-3 d-flex flex-column">
                                                        <label for="" class="opacity-7">Capacity in Gallons</label>
                                                        <b>5000</b>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="p-3 d-flex flex-column">
                                                        <label for="" class="opacity-7">Compartmented</label>
                                                        <b>Yes</b>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="p-3 d-flex flex-column">
                                                        <label for="" class="opacity-7">Number of compartments</label>
                                                        <b>5</b>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="p-3 d-flex flex-column">
                                                        <label for="" class="opacity-7">Capacity</label>
                                                        <b>5000, 5000, 5000, n/a, n/a, n/a</b>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="p-3 d-flex flex-column">
                                                        <label for="" class="opacity-7">Installed by Manufacturer/Retrofitted</label>
                                                        <b>xxxxxxxxxxxx</b>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="p-3 d-flex flex-column">
                                                        <label for="" class="opacity-7">Cargo</label>
                                                        <b>Fuel</b>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="p-3 d-flex flex-column">
                                                        <label for="" class="opacity-7">Is installed with a vapor collection system</label>
                                                        <b>Yes</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                        <div class="row mb-1">
                                            <div class="col-md-12">
                                                <div class="card">
                                                    <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                                                        <div class="search position-relative align-self-start mb-1 mt-1 mr-4">
                                                            <input type="search" id="gsearch" name="gsearch" placeholder="Search by Inspection Type" class="pl-5"/>
                                                            <a href="#" class="search-icon">
                                                                <img src="/images/icons/search.svg" alt=""/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="card-body pt-0 pb-0">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="table-responsive  border-bottom">
                                                                    <table class="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Inspection</th>
                                                                                <th>Inspection Spec </th>
                                                                                <th>Assigned Date</th>
                                                                                <th>Return Expect</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Annual Vehicle Inspection</td>
                                                                                <td>External Visual Inspection</td>
                                                                                <td>15-05-2020</td>
                                                                                <td>15-05-2020</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Annual Vehicle Inspection</td>
                                                                                <td>External Visual Inspection</td>
                                                                                <td>15-05-2020</td>
                                                                                <td>15-05-2020</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-between p-3">
                                                        <div class="d-flex">
                                                            <div class="dropdown show mr-3 ">
                                                                <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                25
                                                </a>
                                                                <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                                    <a class="dropdown-item" href="#">25</a>
                                                                    <a class="dropdown-item" href="#">50</a>
                                                                </div>
                                                            </div>
                                                            <span class="d-flex align-items-center pagenation-mob">25 out of 72 Records Displayed</span>
                                                        </div>
                                                        <nav aria-label="Page navigation example">
                                                            <ul class="pagination mb-0">
                                                                <li class="page-item">
                                                                    <a class="page-link" href="#" aria-label="Previous">
                                                                        <span aria-hidden="true">«</span>
                                                                        <span class="sr-only">Previous</span>
                                                                    </a>
                                                                </li>
                                                                <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                                <li class="page-item">
                                                                    <a class="page-link" href="#" aria-label="Next">
                                                                        <span aria-hidden="true">»</span>
                                                                        <span class="sr-only">Next</span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Modal --> */}
                <div class="modal fade" id="editPhoto" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Edit Photo</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="search position-relative w-100">
                                            <input type="search" id="gsearch" name="gsearch" placeholder="Search driver" class="pl-5 w-100"/>
                                            <a href="#" class="search-icon"> <img src="/images/icons/search.svg" alt=""/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="receivedVehicle" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <svg viewBox="0 0 50 50" class="mb-3" style={{width:"64px",borderRadius: "100%"}}>
                                  <g id="vehicle-icon" transform="translate(399 -183)">
                                    <rect width="50" height="50" transform="translate(-399 183)" fill="#ddd"/>
                                    <g transform="translate(-795.866 9.024)">
                                      <path id="Path_530" data-name="Path 530" d="M438.078,199.107a14.08,14.08,0,0,1,.261,1.731q.1,1.012.1,1.763v3.331a2.509,2.509,0,0,1-2.514,2.514h-1.273a3.332,3.332,0,0,1-5.78,0h-1.306a2.373,2.373,0,0,1-1.453-.473,2.544,2.544,0,0,1-.9-1.192H416.69a3.331,3.331,0,0,1-6.661,0h-2.514A2.508,2.508,0,0,1,405,204.266V190.878a2.508,2.508,0,0,1,2.514-2.514h16.718a2.508,2.508,0,0,1,2.514,2.514v.979a2.461,2.461,0,0,1,.816-.13h6.694a2.956,2.956,0,0,1,2.808,2.318l.359,1.7.653,3.331Zm-21.812,5.975h8.784v-14.2a.837.837,0,0,0-.816-.817H407.514a.819.819,0,0,0-.6.245.784.784,0,0,0-.245.571v13.388a.784.784,0,0,0,.245.571.82.82,0,0,0,.6.245h2.939a3.516,3.516,0,0,1,1.224-1.208,3.322,3.322,0,0,1,3.363,0A3.525,3.525,0,0,1,416.265,205.082Zm-1.73,2.874a1.6,1.6,0,0,0,.49-1.176,1.643,1.643,0,0,0-.49-1.208,1.656,1.656,0,0,0-2.351,0,1.643,1.643,0,0,0-.49,1.208,1.662,1.662,0,0,0,2.841,1.176Zm21.387-1.176a.837.837,0,0,0,.849-.849v-2.514h-.849a.837.837,0,0,1-.816-.817.82.82,0,0,1,.245-.6.783.783,0,0,1,.571-.245h.817q-.033-.392-.082-.833t-.082-.833h-5.649a.837.837,0,0,1-.849-.849v-3.331a.836.836,0,0,1,.849-.849h4.637l-.13-.686a1.116,1.116,0,0,0-.425-.686,1.173,1.173,0,0,0-.751-.294h-6.694a.783.783,0,0,0-.571.245.821.821,0,0,0-.245.6v11.69a.821.821,0,0,0,.245.6.784.784,0,0,0,.571.245h.849a3.245,3.245,0,0,1,.979-2.384,3.206,3.206,0,0,1,2.351-.979,3.344,3.344,0,0,1,3.363,3.363Zm-3,1.176a1.6,1.6,0,0,0,.49-1.176,1.642,1.642,0,0,0-.49-1.208,1.655,1.655,0,0,0-2.351,0,1.642,1.642,0,0,0-.49,1.208,1.662,1.662,0,0,0,2.841,1.176Zm3.331-9.535-.327-1.7h-4.18v1.7Z" transform="translate(0 0)" fill="#000"/>
                                    </g>
                                  </g>
                                </svg>
                                        <p class="mb-0">Does the vehicle "<b>ERS 8547</b>" has return to your <br/>shed with the following?</p>
                                    </div>
                                </div>
                                <div class="col-md-6 d-flex flex-column m-auto">
                                    <div class="form-check mt-4 pt-3">
                                        <input class="form-check-input" type="radio" id="flexRadioDefault1"/>
                                        <label class="form-check-label" for="flexRadioDefault1">With Inspection</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" id="flexRadioDefault2" checked/>
                                        <label class="form-check-label" for="flexRadioDefault2">Without Inspection</label>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn gray-mid-color" data-dismiss="modal">Cancel</button>
                                <button type="button" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
  </>
)};

FleetVehicleAtGarage.propTypes = {};

FleetVehicleAtGarage.defaultProps = {};

export default FleetVehicleAtGarage;
