import React, { history, useEffect, useState, useMemo } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { requestObject } from "../../../../utility/requestObject";
import $ from "jquery";
import services from "../../../../services/services";
import { useLoading } from 'react-hook-loading';
import DatePicker from "react-datepicker";
import Adddocs from "../../../../utility/Adddocs";
import CheckAuthentication from "../../../../utility/checkAuthentication";
import AddInspectionName from "../../../../utility/addInspectionName";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import removePdf from "../../../../utility/removePdf";
import VerifiedBy from "../../../../utility/verfifiedBy";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
import { scrollTop } from "../../../../utility/ScrollTop";
const AnnualVehicle5 = () => {

   const {taskOrderId,fkAccountId, inspectionId, vid } = useParams();
   const [fileName, setfileName] = useState("")
   const [endDate, setEndDate] = useState(new Date());
   var customerid = localStorage.getItem("customerId");
   const [noOfRows, setNoOfRows] = useState(1);
   const [loading, setLoading] = useLoading("Please wait...");
   const [inputList, setInputList] = useState([{}]);
   const navigate = useNavigate();
   const [base64file, setBase64file] =  useState('');
   const [ inspectionDetails, setInspectionDetails ] = useState("");
   const [ documentList, setDocumentLists ] = useState([]);
   const [designpressure, setDesignPressure] = useState();
   const [certify, setApproveCertify] = useState();
   const [inspectionTypeId, setinspectionTypeId] = useState("");
   const [expiryDate, setexpiryDate] = useState(new Date());
   var documentLists=[];

   const [step5_lockDate, setStep5_lockDate] = useState(null);
   const [step5_wheelsDate, setStep5_wheelsDate] = useState(null);
   const [step5_fastenersDate, setStep5_fastenersDate] = useState(null);
   const [step5_weldsDate, setStep5_weldsDate] = useState(null);
   const [step5_requirementsDate, setStep5_requirementsDate] = useState(null);
   const [step5_powerDate, setStep5_powerDate] = useState(null);
   const [step5_passengerDate, setStep5_passengerDate] = useState(null);
   const [step5_otherDate, setStep5_otherDate] = useState(null);

   


   const [roles, setRoles] = useState("");
   const [statusInspection, setStatus] = useState("");
   const [verifiedDATE, setverifiedDate] = useState(new Date());
   useEffect(() => {
      setRoles(localStorage.getItem("Userroles"));
      if(CheckAuthentication) 
      {
      $("input[name='step5_lock_repaired']").attr('disabled', 'true');
      $("input[name='step5_lockDate']").hide();
      $("input[name='step5_lockDateTemp']").attr('disabled', 'true');

      $("input[name='step5_wheels_repaired']").attr('disabled', 'true');
      $("input[name='step5_wheelsDate']").hide();
      $("input[name='step5_wheelsDateTemp']").attr('disabled', 'true');

      $("input[name='step5_fasteners_repaired']").attr('disabled', 'true');
      $("input[name='step5_fastenersDate']").hide();
      $("input[name='step5_fastenersDateTemp']").attr('disabled', 'true');

      $("input[name='step5_welds_repaired']").attr('disabled', 'true');
      $("input[name='step5_weldsDate']").hide();
      $("input[name='step5_weldsDateTemp']").attr('disabled', 'true');

      $("input[name='step5_requirements_repaired']").attr('disabled', 'true');
      $("input[name='step5_requirementsDate']").hide();
      $("input[name='step5_requirementsDateTemp']").attr('disabled', 'true');

      $("input[name='step5_power_repaired']").attr('disabled', 'true');
      $("input[name='step5_powerDate']").hide();
      $("input[name='step5_powerDateTemp']").attr('disabled', 'true');

      $("input[name='step5_passenger_repaired']").attr('disabled', 'true');
      $("input[name='step5_passengerDate']").hide();
      $("input[name='step5_passengerDateTemp']").attr('disabled', 'true');

      $("input[name='step5_other_repaired']").attr('disabled', 'true');
      $("input[name='step5_otherDate']").hide();
      $("input[name='step5_otherDateTemp']").attr('disabled', 'true');
      scrollTop();
      getSection5();
      getInpection(fkAccountId,inspectionId);
      
   }
   else{
      sessionStorage.clear();
      localStorage.clear();
     //navigate("/login");
       window.location.href="/login";
   }
   }, []);

   const getInpection =(fkAccountId,inspectionId)=>
  {


     

      var requestObjectForWet=
      {
      
        "id":inspectionId
      }

      var requestOptions=requestObject.RequestHeader(requestObjectForWet);

      services.getInspectionDetails(requestOptions).then((res) => 
      {
        setDocumentLists(res.rows.documentList);
       documentLists.push(res.rows.documentList);
       setinspectionTypeId(res.rows.inspectionType.id);
       setStatus(res.rows.status);
       if (res.rows) {
         if (res.rows.verifiedDate) {
            setverifiedDate(new Date(res.rows ? res.rows.verifiedDate : ''));
         }
         if(res.rows.expiryDate)
            {
               setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
            }
      }

      $("#notes").val(res.rows?res.rows.notes:"");

      $("#inspectedBy").val(res.rows?res.rows.inspectedBy:"");
      $("#otherText").val(res.rows?res.rows.annualVehicleInspection?res.rows.annualVehicleInspection.other:"":"");

      $("#workOrderNumber").val(res.rows?res.rows.workOrderNumber:"");
      setEndDate(new Date(res.rows?res.rows.inspectedDate:""));

         setInspectionDetails(res.rows);
         console.log("Annual vehicle inspection"+JSON.stringify(res.rows));

       var  section5wheelsAndRims=res.rows.annualVehicleInspection.wheelsAndRims;
       var  section5windshieldGlazing=res.rows.annualVehicleInspection.windshieldGlazing;
       var  section5windshieldWipers=res.rows.annualVehicleInspection.windshieldWipers;
       var  section5motorcoachSeats=res.rows.annualVehicleInspection.motorcoachSeats;
       var other = res.rows.annualVehicleInspection.other;
      

       var step5_lockNF = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_lockNF;
       var step5_lock = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_lock;
       var step5_lockDate = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_lockDate;
 
       $('input[name="step5_lock_na"][value="' + step5_lockNF + '"]').trigger("click");
       $('input[name="step5_lock_repaired"][value="' + step5_lock + '"]').trigger("click").prop('checked', true);
 
 
       var step5_wheelsNF = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_wheelsNF;
       var step5_wheels = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_wheels;
       var step5_wheelsDate = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_wheelsDate;
 
       $('input[name="step5_wheels_na"][value="' + step5_wheelsNF + '"]').trigger("click");
       $('input[name="step5_wheels_repaired"][value="' + step5_wheels + '"]').trigger("click").prop('checked', true);
 
 
       var step5_fastenersNF = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_fastenersNF;
       var step5_fasteners = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_fasteners;
       var step5_fastenersDate = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_fastenersDate;
 
       $('input[name="step5_fasteners_na"][value="' + step5_fastenersNF + '"]').trigger("click");
       $('input[name="step5_fasteners_repaired"][value="' + step5_fasteners + '"]').trigger("click").prop('checked', true);
 
 
       var step5_weldsNF = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_weldsNF;
       var step5_welds = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_welds;
       var step5_weldsDate = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_weldsDate;
 
       $('input[name="step5_welds_na"][value="' + step5_weldsNF + '"]').trigger("click");
       $('input[name="step5_welds_repaired"][value="' + step5_welds + '"]').trigger("click").prop('checked', true);
 
     
       var step5_requirementsNF = section5windshieldGlazing == null ? '' : section5windshieldGlazing.step5_requirementsNF;
       var step5_requirements = section5windshieldGlazing == null ? '' : section5windshieldGlazing.step5_requirements;
       var step5_requirementsDate = section5windshieldGlazing == null ? '' : section5windshieldGlazing.step5_requirementsDate;
 
       $('input[name="step5_requirements_na"][value="' + step5_requirementsNF + '"]').trigger("click");
       $('input[name="step5_requirements_repaired"][value="' + step5_requirements + '"]').trigger("click").prop('checked', true);
 
          var step5_powerNF = section5windshieldWipers == null ? '' : section5windshieldWipers.step5_powerNF;
          var step5_power = section5windshieldWipers == null ? '' : section5windshieldWipers.step5_power;
          var step5_powerDate = section5windshieldWipers == null ? '' : section5windshieldWipers.step5_powerDate;
    
          $('input[name="step5_power_na"][value="' + step5_powerNF + '"]').trigger("click");
          $('input[name="step5_power_repaired"][value="' + step5_power + '"]').trigger("click").prop('checked', true);
   
 
 
          var step5_passengerNF = section5motorcoachSeats == null ? '' : section5motorcoachSeats.step5_passengerNF;
          var step5_passenger = section5motorcoachSeats == null ? '' : section5motorcoachSeats.step5_passenger;
          var step5_passengerDate = section5motorcoachSeats == null ? '' : section5motorcoachSeats.step5_passengerDate;
    
          $('input[name="step5_passenger_na"][value="' + step5_passengerNF + '"]').trigger("click");
          $('input[name="step5_passenger_repaired"][value="' + step5_passenger + '"]').trigger("click").prop('checked', true);
     
 
          var step5_otherNF = other == null ? '' : other.step5_otherNF;
          var step5_other = other == null ? '' : other.step5_other;
          var step5_otherDate = other == null ? '' : other.step5_otherDate;
    
          $('input[name="step5_other_na"][value="' + step5_otherNF + '"]').trigger("click");
          $('input[name="step5_other_repaired"][value="' + step5_other + '"]').trigger("click").prop('checked', true);

          
          $("#step5_lockDate").val(step5_lockDate);
          $("#step5_wheelsDate").val(step5_wheelsDate);
          $("#step5_fastenersDate").val(step5_fastenersDate);
          $("#step5_weldsDate").val(step5_weldsDate);
          $("#step5_requirementsDate").val(step5_requirementsDate);
          $("#step5_powerDate").val(step5_powerDate);
          $("#step5_passengerDate").val(step5_passengerDate);
          $("#step5_otherDate").val(step5_otherDate);
    
          if (step5_lockNF || step5_wheelsNF || step5_fastenersNF || step5_weldsNF || step5_requirementsNF || step5_powerNF || step5_passengerNF || step5_otherNF == 'verified') {
             checkVerified();

   setStep5_lockDate(step5_lockDate?new Date(step5_lockDate):'');
   setStep5_wheelsDate(step5_wheelsDate?new Date(step5_wheelsDate):'');
   setStep5_fastenersDate(step5_fastenersDate?new Date(step5_fastenersDate):'');
   setStep5_weldsDate(step5_weldsDate?new Date(step5_weldsDate):'');
   setStep5_requirementsDate(step5_requirementsDate?new Date(step5_requirementsDate):'');
   setStep5_powerDate(step5_powerDate?new Date(step5_powerDate):'');
   setStep5_passengerDate(step5_passengerDate?new Date(step5_passengerDate):'');
   setStep5_otherDate(step5_otherDate?new Date(step5_otherDate):'');
 
       }

       
     
       
      });
      // handleSubmit();
   }

  

   function getSection5() {

      // JSON.parse(sessionStorage.getItem("section3Exhaust"));

      // console.log("section3Exhaust" + JSON.parse(sessionStorage.getItem("section3Exhaust")));

      var section5wheelsAndRims = JSON.parse(sessionStorage.getItem("section5wheelsAndRims"))
      var section5windshieldGlazing = JSON.parse(sessionStorage.getItem("section5windshieldGlazing"))
      var section5windshieldWipers = JSON.parse(sessionStorage.getItem("section5windshieldWipers"))
      var section5motorcoachSeats = JSON.parse(sessionStorage.getItem("section5motorcoachSeats"))
      var other = JSON.parse(sessionStorage.getItem("other"))

      var step5_lockNF = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_lockNF;
      var step5_lock = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_lock;
      var step5_lockDate = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_lockDate;

      $('input[name="step5_lock_na"][value="' + step5_lockNF + '"]').trigger("click");
      $('input[name="step5_lock_repaired"][value="' + step5_lock + '"]').trigger("click").prop('checked', true);


      var step5_wheelsNF = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_wheelsNF;
      var step5_wheels = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_wheels;
      var step5_wheelsDate = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_wheelsDate;

      $('input[name="step5_wheels_na"][value="' + step5_wheelsNF + '"]').trigger("click");
      $('input[name="step5_wheels_repaired"][value="' + step5_wheels + '"]').trigger("click").prop('checked', true);


      var step5_fastenersNF = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_fastenersNF;
      var step5_fasteners = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_fasteners;
      var step5_fastenersDate = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_fastenersDate;

      $('input[name="step5_fasteners_na"][value="' + step5_fastenersNF + '"]').trigger("click");
      $('input[name="step5_fasteners_repaired"][value="' + step5_fasteners + '"]').trigger("click").prop('checked', true);


      var step5_weldsNF = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_weldsNF;
      var step5_welds = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_welds;
      var step5_weldsDate = section5wheelsAndRims == null ? '' : section5wheelsAndRims.step5_weldsDate;

      $('input[name="step5_welds_na"][value="' + step5_weldsNF + '"]').trigger("click");
      $('input[name="step5_welds_repaired"][value="' + step5_welds + '"]').trigger("click").prop('checked', true);

    
      var step5_requirementsNF = section5windshieldGlazing == null ? '' : section5windshieldGlazing.step5_requirementsNF;
      var step5_requirements = section5windshieldGlazing == null ? '' : section5windshieldGlazing.step5_requirements;
      var step5_requirementsDate = section5windshieldGlazing == null ? '' : section5windshieldGlazing.step5_requirementsDate;

      $('input[name="step5_requirements_na"][value="' + step5_requirementsNF + '"]').trigger("click");
      $('input[name="step5_requirements_repaired"][value="' + step5_requirements + '"]').trigger("click").prop('checked', true);

         var step5_powerNF = section5windshieldWipers == null ? '' : section5windshieldWipers.step5_powerNF;
         var step5_power = section5windshieldWipers == null ? '' : section5windshieldWipers.step5_power;
         var step5_powerDate = section5windshieldWipers == null ? '' : section5windshieldWipers.step5_powerDate;
   
         $('input[name="step5_power_na"][value="' + step5_powerNF + '"]').trigger("click");
         $('input[name="step5_power_repaired"][value="' + step5_power + '"]').trigger("click").prop('checked', true);
  


         var step5_passengerNF = section5motorcoachSeats == null ? '' : section5motorcoachSeats.step5_passengerNF;
         var step5_passenger = section5motorcoachSeats == null ? '' : section5motorcoachSeats.step5_passenger;
         var step5_passengerDate = section5motorcoachSeats == null ? '' : section5motorcoachSeats.step5_passengerDate;
   
         $('input[name="step5_passenger_na"][value="' + step5_passengerNF + '"]').trigger("click");
         $('input[name="step5_passenger_repaired"][value="' + step5_passenger + '"]').trigger("click").prop('checked', true);
    

         var step5_otherNF = other == null ? '' : other.step5_otherNF;
         var step5_other = other == null ? '' : other.step5_other;
         var step5_otherDate = other == null ? '' : other.step5_otherDate;
   
         $('input[name="step5_other_na"][value="' + step5_otherNF + '"]').trigger("click");
         $('input[name="step5_other_repaired"][value="' + step5_other + '"]').trigger("click").prop('checked', true);
   

         if (step5_lockNF || step5_wheelsNF || step5_fastenersNF || step5_weldsNF || step5_requirementsNF || step5_powerNF || step5_passengerNF || step5_otherNF == 'verified') {
            checkVerified();

            $("#step5_lockDate").val(step5_lockDate);
            $("#step5_wheelsDate").val(step5_wheelsDate);
            $("#step5_fastenersDate").val(step5_fastenersDate);
            $("#step5_weldsDate").val(step5_weldsDate);
            $("#step5_requirementsDate").val(step5_requirementsDate);
            $("#step5_powerDate").val(step5_powerDate);
            $("#step5_passengerDate").val(step5_passengerDate);
            $("#step5_otherDate").val(step5_otherDate);
         
            // setStep5_lockDate(step5_lockDate);
            // setStep5_wheelsDate(step5_wheelsDate);
            // setStep5_fastenersDate(step5_fastenersDate);
            // setStep5_weldsDate(step5_weldsDate);
            // setStep5_requirementsDate(step5_requirementsDate);
            // setStep5_powerDate(step5_powerDate);
            // setStep5_passengerDate(step5_passengerDate);
            // setStep5_otherDate(step5_otherDate);
        
         }

   }


   const checkVerified = () => {

      //       step5_lock

      var step5_lockValue = $("input[name='step5_lock_na']:checked").val();

      if (step5_lockValue == 'na') {
         $("input[name='step5_lock_repaired']").attr('disabled', 'true');
         $("input[name='step5_lockDateTemp']").show();
         $("input[name='step5_lockDateTemp']").attr('disabled', 'true');
         $("input[name='step5_lockDate']").hide();
         $("input[name='step5_lock_repaired']").prop("checked", false);
         setStep5_lockDate('');

      } if (step5_lockValue == 'verified') {

         $("input[name='step5_lock_repaired']").removeAttr('disabled');
         $("input[name='step5_lockDateTemp']").removeAttr('disabled');

         if ($("input[name='step5_lock_repaired']").is(':checked')) {
            $("input[name='step5_lockDate']").show();
            $("input[name='step5_lockDateTemp']").hide();
         } else {
            $("input[name='step5_lockDate']").hide();
            $("input[name='step5_lockDateTemp']").show();
         }

      }

      // step5_wheels

      var step5_wheelsValue = $("input[name='step5_wheels_na']:checked").val();


      if (step5_wheelsValue == 'na') {
         $("input[name='step5_wheels_repaired']").attr('disabled', 'true');
         $("input[name='step5_wheelsDateTemp']").show();
         $("input[name='step5_wheelsDateTemp']").attr('disabled', 'true');
         $("input[name='step5_wheelsDate']").hide();
         $("input[name='step5_wheels_repaired']").prop("checked", false);
         setStep5_wheelsDate('');

      } if (step5_wheelsValue == 'verified') {

         $("input[name='step5_wheels_repaired']").removeAttr('disabled');
         $("input[name='step5_wheelsDateTemp']").removeAttr('disabled');

         if ($("input[name='step5_wheels_repaired']").is(':checked')) {
            $("input[name='step5_wheelsDate']").show();
            $("input[name='step5_wheelsDateTemp']").hide();
         } else {
            $("input[name='step5_wheelsDate']").hide();
            $("input[name='step5_wheelsDateTemp']").show();
         }

      }

      // step5_fasteners


      var step5_fastenersValue = $("input[name='step5_fasteners_na']:checked").val();


      if (step5_fastenersValue == 'na') {
         $("input[name='step5_fasteners_repaired']").attr('disabled', 'true');
         $("input[name='step5_fastenersDateTemp']").show();
         $("input[name='step5_fastenersDateTemp']").attr('disabled', 'true');
         $("input[name='step5_fastenersDate']").hide();
         $("input[name='step5_fasteners_repaired']").prop("checked", false);
         setStep5_fastenersDate('');
      } if (step5_fastenersValue == 'verified') {

         $("input[name='step5_fasteners_repaired']").removeAttr('disabled');
         $("input[name='step5_fastenersDateTemp']").removeAttr('disabled');

         if ($("input[name='step5_fasteners_repaired']").is(':checked')) {
            $("input[name='step5_fastenersDate']").show();
            $("input[name='step5_fastenersDateTemp']").hide();
         } else {
            $("input[name='step5_fastenersDate']").hide();
            $("input[name='step5_fastenersDateTemp']").show();
         }

      }
      // step5_welds

      var step5_weldsValue = $("input[name='step5_welds_na']:checked").val();

      if (step5_weldsValue == 'na') {
         $("input[name='step5_welds_repaired']").attr('disabled', 'true');
         $("input[name='step5_weldsDateTemp']").show();
         $("input[name='step5_weldsDateTemp']").attr('disabled', 'true');
         $("input[name='step5_weldsDate']").hide();
         $("input[name='step5_welds_repaired']").prop("checked", false);
         setStep5_weldsDate('');
      } if (step5_weldsValue == 'verified') {

         $("input[name='step5_welds_repaired']").removeAttr('disabled');
         $("input[name='step5_weldsDateTemp']").removeAttr('disabled');

         if ($("input[name='step5_welds_repaired']").is(':checked')) {
            $("input[name='step5_weldsDate']").show();
            $("input[name='step5_weldsDateTemp']").hide();
         } else {
            $("input[name='step5_weldsDate']").hide();
            $("input[name='step5_weldsDateTemp']").show();
         }

      }

      // step5_requirements

      var step5_requirementsValue = $("input[name='step5_requirements_na']:checked").val();

      if (step5_requirementsValue == 'na') {
         $("input[name='step5_requirements_repaired']").attr('disabled', 'true');
         $("input[name='step5_requirementsDateTemp']").show();
         $("input[name='step5_requirementsDateTemp']").attr('disabled', 'true');
         $("input[name='step5_requirementsDate']").hide();
         $("input[name='step5_requirements_repaired']").prop("checked", false);
         setStep5_requirementsDate('');
      } if (step5_requirementsValue == 'verified') {

         $("input[name='step5_requirements_repaired']").removeAttr('disabled');
         $("input[name='step5_requirementsDateTemp']").removeAttr('disabled');

         if ($("input[name='step5_requirements_repaired']").is(':checked')) {
            $("input[name='step5_requirementsDate']").show();
            $("input[name='step5_requirementsDateTemp']").hide();
         } else {
            $("input[name='step5_requirementsDate']").hide();
            $("input[name='step5_requirementsDateTemp']").show();
         }

      }

      // step5_power

      var step5_powerValue = $("input[name='step5_power_na']:checked").val();

      if (step5_powerValue == 'na') {
         $("input[name='step5_power_repaired']").attr('disabled', 'true');
         $("input[name='step5_powerDateTemp']").show();
         $("input[name='step5_powerDateTemp']").attr('disabled', 'true');
         $("input[name='step5_powerDate']").hide();
         $("input[name='step5_power_repaired']").prop("checked", false);
         setStep5_powerDate('');
      } if (step5_powerValue == 'verified') {

         $("input[name='step5_power_repaired']").removeAttr('disabled');
         $("input[name='step5_powerDateTemp']").removeAttr('disabled');

         if ($("input[name='step5_power_repaired']").is(':checked')) {
            $("input[name='step5_powerDate']").show();
            $("input[name='step5_powerDateTemp']").hide();
         } else {
            $("input[name='step5_powerDate']").hide();
            $("input[name='step5_powerDateTemp']").show();
         }

      }
      // step5_passenger

      var step5_passengerValue = $("input[name='step5_passenger_na']:checked").val();

      if (step5_passengerValue == 'na') {
         $("input[name='step5_passenger_repaired']").attr('disabled', 'true');
         $("input[name='step5_passengerDateTemp']").show();
         $("input[name='step5_passengerDateTemp']").attr('disabled', 'true');
         $("input[name='step5_passengerDate']").hide();
         $("input[name='step5_passenger_repaired']").prop("checked", false);
         setStep5_passengerDate('');
      } if (step5_passengerValue == 'verified') {

         $("input[name='step5_passenger_repaired']").removeAttr('disabled');
         $("input[name='step5_passengerDateTemp']").removeAttr('disabled');

         if ($("input[name='step5_passenger_repaired']").is(':checked')) {
            $("input[name='step5_passengerDate']").show();
            $("input[name='step5_passengerDateTemp']").hide();
         } else {
            $("input[name='step5_passengerDate']").hide();
            $("input[name='step5_passengerDateTemp']").show();
         }

      }

      // step5_other
      var step5_otherValue = $("input[name='step5_other_na']:checked").val();

      if (step5_otherValue == 'na') {
         $("input[name='step5_other_repaired']").attr('disabled', 'true');
         $("input[name='step5_otherDateTemp']").show();
         $("input[name='step5_otherDateTemp']").attr('disabled', 'true');
         $("input[name='step5_otherDate']").hide();
         $("input[name='step5_other_repaired']").prop("checked", false);
         setStep5_otherDate('');
      } if (step5_otherValue == 'verified') {

         $("input[name='step5_other_repaired']").removeAttr('disabled');
         $("input[name='step5_otherDateTemp']").removeAttr('disabled');

         if ($("input[name='step5_other_repaired']").is(':checked')) {
            $("input[name='step5_otherDate']").show();
            $("input[name='step5_otherDateTemp']").hide();
         } else {
            $("input[name='step5_otherDate']").hide();
            $("input[name='step5_otherDateTemp']").show();
         }

      }


     
   }


   $(document).ready(function () {

      $('#annualVehicle4').on('click', function () {

      var section5wheelsAndRims = {
         "step5_lockNF": $("input[name='step5_lock_na']:checked").val() === "na" ? "na" : "verified",
         "step5_lock": $("input[name='step5_lock_repaired']:checked").val() === "1" ? "1" : "0",
         "step5_lockDate": $("input[name='step5_lockDate']").val(),

         "step5_wheelsNF": $("input[name='step5_wheels_na']:checked").val() === "na" ? "na" : "verified",
         "step5_wheels": $("input[name='step5_wheels_repaired']:checked").val() === "1" ? "1" : "0",
         "step5_wheelsDate": $("input[name='step5_wheelsDate']").val(),

         "step5_fastenersNF": $("input[name='step5_fasteners_na']:checked").val() === "na" ? "na" : "verified",
         "step5_fasteners": $("input[name='step5_fasteners_repaired']:checked").val() === "1" ? "1" : "0",
         "step5_fastenersDate": $("input[name='step5_fastenersDate']").val(),

         "step5_weldsNF": $("input[name='step5_welds_na']:checked").val() === "na" ? "na" : "verified",
         "step5_welds": $("input[name='step5_welds_repaired']:checked").val() === "1" ? "1" : "0",
         "step5_weldsDate": $("input[name='step5_weldsDate']").val(),

      };

      console.log(JSON.stringify(section5wheelsAndRims));
      sessionStorage.setItem("section5wheelsAndRims", JSON.stringify(section5wheelsAndRims));

      var section5windshieldGlazing = {
         "step5_requirementsNF": $("input[name='step5_requirements_na']:checked").val() === "na" ? "na" : "verified",
         "step5_requirements": $("input[name='step5_requirements_repaired']:checked").val() === "1" ? "1" : "0",
         "step5_requirementsDate": $("input[name='step5_requirementsDate']").val(),

      };

      console.log(JSON.stringify(section5windshieldGlazing));
      sessionStorage.setItem("section5windshieldGlazing", JSON.stringify(section5windshieldGlazing));


      var section5windshieldWipers = {
         "step5_powerNF": $("input[name='step5_power_na']:checked").val() === "na" ? "na" : "verified",
         "step5_power": $("input[name='step5_power_repaired']:checked").val() === "1" ? "1" : "0",
         "step5_powerDate": $("input[name='step5_powerDate']").val(),

      };
      console.log(JSON.stringify(section5windshieldWipers));
      sessionStorage.setItem("section5windshieldWipers", JSON.stringify(section5windshieldWipers));


      var section5motorcoachSeats = {
         "step5_passengerNF": $("input[name='step5_passenger_na']:checked").val() === "na" ? "na" : "verified",
         "step5_passenger": $("input[name='step5_passenger_repaired']:checked").val() === "1" ? "1" : "0",
         "step5_passengerDate": $("input[name='step5_passengerDate']").val(),

      };
      console.log(JSON.stringify(section5motorcoachSeats));
      sessionStorage.setItem("section5motorcoachSeats", JSON.stringify(section5motorcoachSeats));

      var other = {
         // "step5_otherNF": $("input[name='step5_other_na']:checked").val() === "na" ? "na" : "verified",
         "other": $("#otherText").val()!=''?$("#otherText").val():'',
         // "step5_otherDate": $("input[name='step5_otherDate']").val(),

      };
      console.log(JSON.stringify(other));
      sessionStorage.setItem("other", JSON.stringify(other));



      });
   });


   const handleSubmit = () => {

      

      let form_is_valid = true;

      var step5_lock_na = $("input[name='step5_lock_na']:checked").val();
      var step5_wheels_na = $("input[name='step5_wheels_na']:checked").val();
      var step5_fasteners_na = $("input[name='step5_fasteners_na']:checked").val();
      var step5_welds_na = $("input[name='step5_welds_na']:checked").val();
      var step5_requirements_na = $("input[name='step5_requirements_na']:checked").val();
      var step5_power_na = $("input[name='step5_power_na']:checked").val();
      var step5_passenger_na = $("input[name='step5_passenger_na']:checked").val();
      // var step5_other_na = $("input[name='step5_other_na']:checked").val();
      var step6AirLines = $("input[name='step6AirLines']:checked").val();
      var step6tires = $("input[name='step6tires']:checked").val();
      var step6illegible = $("input[name='step6illegible']:checked").val();
      var step6specsillegible = $("input[name='step6specsillegible']:checked").val();
      var step6Does5th = $("input[name='step6Does5th']:checked").val();
      var step6updated = $("input[name='step6updated']:checked").val();
      var step6inspected = $("input[name='step6inspected']:checked").val();

      var workOrderNumber = $('#workOrderNumber').val();

      // if (!workOrderNumber) {
      //    form_is_valid = false;
      //    $("#workOrderNumber-message").html('Work order number  is required');
      // } else {
      //    $("#workOrderNumber-message").html('');
      //    form_is_valid = true;
      // }

      if (step5_lock_na == undefined || step5_wheels_na == undefined || step5_fasteners_na == undefined || step5_welds_na == undefined || step5_requirements_na == undefined || step5_power_na == undefined || step5_passenger_na == undefined) {
         // || step5_other_na == undefined
         // $('html, body').animate({
         //    scrollTop: $("#inspectionType").offset().top
         //  }, 500);
         $('html,body').animate({ scrollTop: "600px" }, 1000);
         $('#error_message').css("color", "#d33232").html("*Please choose one option for each question");
         form_is_valid = false;

      } else {
         $('#error_message').html('');
      }
      // alert(form_is_valid);
      return form_is_valid;

   }
   const save = (status) => {

      // sessionStorage.setItem("verifiedBy",$("#verifiedBy").val());

      if (status == 'DRAFT') {
         var documentList = [];

         try {
            for (var i = 0; i < inputList.length; i++) {
               // console.log("filebase64=="+inputList[i].filetobase64);
               var extension = "";
               var byteString = "";
               if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
                  extension = "pdf";
                  byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
               } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
                  extension = "jpg";
                  byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
               }
               else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
                  extension = "png";
                  byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
               }
               else if (inputList[i].filetobase64.match("data:application/msword;base64,")) {
                  extension = "word";
                  byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/msword;base64,", "") : "";
               }
               else {

               }
     
               documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(), "extension": extension });
            }
         } catch (exception) {
            // documentList.push();
            //   Swal.fire({  
            //       position: 'center',  
            //       icon: 'error',  
            //       title: "",  
            //       text:"Please attach document",
            //       showConfirmButton: true 

            //     }).then((result) => 
            //     {

            //           if(result.isConfirmed) 
            //           {


            //           }
            //     });
            //     return false;
         }
         try {
            if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {
    
                var i;
                var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
                for (i = 0; i < storedArray.length; i++) {
    
                    documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
                }
    
            } else {
    
                 documentLists.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
            }
    
        } catch (ex) {
    
        }
         if ($("#inspectedBy").val() === "") {
            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: "Please enter inspectedBy",
               showConfirmButton: true

            }).then((result) => {

               if (result.isConfirmed) {


               }
            });
            return false;
         }


         // console.log("results=="+JSON.stringify(inputList));


         var requestObjectForInsp =
         {
            "id": inspectionId,
            "expiryDate": $("#expiryDate").val(),
            "type": "Annual Vehicle Inspection",
            "workOrderNumber": $("#workOrderNumber").val(),
            "notes": $("#notes").val(),
            "inspectedBy": $("#inspectedBy").val(),
            "inspectedDate": $("#inspectedDate").val(),
            "verifiedBy": $("#verifiedBy").val(),
            "verifiedDate": $("#verifiedBy").val(),
            "status": status,
            "taskOrderId":taskOrderId,
            "inspectionType":
            {
               "id":inspectionTypeId,
               "name":"Annual Vehicle Inspection"
            },
            "result":
            {
               "approvedCertify": "0",
               "inspectionFailed": "0"
            },
            "dispositionOfVehicle":
            {
               "vehicleReturnedServices": "0",
               "vehicleWithdrawnFromService": "0",
               "vehicleSentToAnotherRepairFacility": "0"
            },
            "vehicleDetails":
            {
               "vinNumber": $("#vin").html(),
               "lisencePlateNumber": $("#serviceBrakelisencePlateNumber").html(),
               "id": vid,
               "unitNumber": $("#lisencePlateNumber").html()
            },
            "annualVehicleInspection": {
               "breakSystem": JSON.parse(sessionStorage.getItem("section1Break")),
               "couplingDevices": JSON.parse(sessionStorage.getItem("section2Coupling")),
               "exahustSystem": JSON.parse(sessionStorage.getItem("section3Exhaust")),
               "fuelSystem": JSON.parse(sessionStorage.getItem("section3Fuel")),
               "lightingDevices": JSON.parse(sessionStorage.getItem("section3LIGHTING")),
               "safeLoading": JSON.parse(sessionStorage.getItem("section3SAFE")),
               "steeringMechanism": JSON.parse(sessionStorage.getItem("section4STEERING")),
               "suspension": JSON.parse(sessionStorage.getItem("section4SUSPENSION")),
               "frame": JSON.parse(sessionStorage.getItem("section4FRAME")),
               "tires": JSON.parse(sessionStorage.getItem("section4TIRES")),
               "wheelsAndRims": {
                  "step5_lockNF": $("input[name='step5_lock_na']:checked").val() === "na" ? "na" : "verified",
                  "step5_lock": $("input[name='step5_lock_repaired']:checked").val() === "1" ? "1" : "0",
                  "step5_lockDate": $("input[name='step5_lockDate']").val(),

                  "step5_wheelsNF": $("input[name='step5_wheels_na']:checked").val() === "na" ? "na" : "verified",
                  "step5_wheels": $("input[name='step5_wheels_repaired']:checked").val() === "1" ? "1" : "0",
                  "step5_wheelsDate": $("input[name='step5_wheelsDate']").val(),

                  "step5_fastenersNF": $("input[name='step5_fasteners_na']:checked").val() === "na" ? "na" : "verified",
                  "step5_fasteners": $("input[name='step5_fasteners_repaired']:checked").val() === "1" ? "1" : "0",
                  "step5_fastenersDate": $("input[name='step5_fastenersDate']").val(),

                  "step5_weldsNF": $("input[name='step5_welds_na']:checked").val() === "na" ? "na" : "verified",
                  "step5_welds": $("input[name='step5_welds_repaired']:checked").val() === "1" ? "1" : "0",
                  "step5_weldsDate": $("input[name='step5_weldsDate']").val(),
               },
               "windshieldGlazing": {
                  "step5_requirementsNF": $("input[name='step5_requirements_na']:checked").val() === "na" ? "na" : "verified",
                  "step5_requirements": $("input[name='step5_requirements_repaired']:checked").val() === "1" ? "1" : "0",
                  "step5_requirementsDate": $("input[name='step5_requirementsDate']").val(),
               },
               "windshieldWipers": {
                  "step5_powerNF": $("input[name='step5_power_na']:checked").val() === "na" ? "na" : "verified",
                  "step5_power": $("input[name='step5_power_repaired']:checked").val() === "1" ? "1" : "0",
                  "step5_powerDate": $("input[name='step5_powerDate']").val(),
               },
               "motorcoachSeats": {
                  "step5_passengerNF": $("input[name='step5_passenger_na']:checked").val() === "na" ? "na" : "verified",
                  "step5_passenger": $("input[name='step5_passenger_repaired']:checked").val() === "1" ? "1" : "0",
                  "step5_passengerDate": $("input[name='step5_passengerDate']").val(),
               },
               // "step5_otherNF": $("input[name='step5_other_na']:checked").val() === "na" ? "na" : "verified",
               "other": $("#otherText").val()!=''?$("#otherText").val():'',
               // "step5_otherDate": $("input[name='step5_otherDate']").val(),
            },
            "documentList": documentLists

         };

         $("#loadingforsubmit").show();
         // setLoading(true);
         //   const requestOptions = 
         //   {
         //      method: 'POST',
         //      headers: { 'Content-Type': 'application/json'
         //               },

         //         body: JSON.stringify(requestObject)
         //   };
         var requestOptions = requestObject.RequestHeader(requestObjectForInsp);
         services.addInspection(requestOptions).then((res) => {
            // setLoading(false);
            $("#loadingforsubmit").hide();
            Swal.fire({
               position: 'center',
               icon: 'success',
               title: "",
               text:"Your changes have been successfully saved!",
               showConfirmButton: true

            }).then((result) => {

               if (result.isConfirmed) {

                  sessionStorage.removeItem("section1Break");
                  sessionStorage.removeItem("section2Coupling");
                  sessionStorage.removeItem("section3Exhaust");
                  sessionStorage.removeItem("section3Fuel");
                  sessionStorage.removeItem("section3LIGHTING");
                  sessionStorage.removeItem("section3SAFE");
                  sessionStorage.removeItem("section4STEERING");
                  sessionStorage.removeItem("section4SUSPENSION");
                  sessionStorage.removeItem("section4FRAME");
                  sessionStorage.removeItem("section4TIRES");
                  sessionStorage.removeItem("section5wheelsAndRims");
                  sessionStorage.removeItem("section5windshieldGlazing");
                  sessionStorage.removeItem("section5windshieldWipers");
                  sessionStorage.removeItem("section5motorcoachSeats");
                  sessionStorage.removeItem("other");
                  navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

               }
            });
         });
      } else {

         if (handleSubmit() == true) {
            var documentList = [];

            try {
               for (var i = 0; i < inputList.length; i++) {
                  // console.log("filebase64=="+inputList[i].filetobase64);
                  var extension = "";
                  var byteString = "";
                  if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
                     extension = "pdf";
                     byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
                  } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
                     extension = "jpg";
                     byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
                  }
                  else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
                     extension = "png";
                     byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
                  }
                  else if (inputList[i].filetobase64.match("data:application/msword;base64,")) {
                     extension = "word";
                     byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/msword;base64,", "") : "";
                  }
                  else {

                  }


                  documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(), "extension": extension });
               }
            } catch (exception) {
               // documentList.push();
               //   Swal.fire({  
               //       position: 'center',  
               //       icon: 'error',  
               //       title: "",  
               //       text:"Please attach document",
               //       showConfirmButton: true 

               //     }).then((result) => 
               //     {

               //           if(result.isConfirmed) 
               //           {


               //           }
               //     });
               //     return false;
            }
            try {
               if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {
       
                   var i;
                   var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
                   for (i = 0; i < storedArray.length; i++) {
       
                       documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
                   }
       
               } else {
       
                    documentLists.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
               }
       
           } catch (ex) {
       
           }
            if ($("#inspectedBy").val() === "") {
               Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: "",
                  text: "Please enter inspectedBy",
                  showConfirmButton: true

               }).then((result) => {

                  if (result.isConfirmed) {


                  }
               });
               return false;
            }


            // console.log("results=="+JSON.stringify(inputList));


            var requestObjectForInsp =
            {
                "id": inspectionId,
               
               "type": "Annual Vehicle Inspection",
               "workOrderNumber": $("#workOrderNumber").val(),
               "notes": $("#notes").val(),
               "inspectedBy": $("#inspectedBy").val(),
               "inspectedDate": $("#inspectedDate").val(),
               "expiryDate": $("#expiryDate").val(),
               "verifiedBy": $("#verifiedBy").val(),
               "verifiedDate": $("#verifiedBy").val(),
               "status": status,
               "taskOrderId":taskOrderId,
               "inspectionType":
               {
                  // "id": sessionStorage.getItem("selectedinspectionId"),
                  // "name":sessionStorage.getItem("selectedinspectionType")
                  "id": inspectionTypeId,
                 "name":"Annual Vehicle Inspection"
               },
               "result":
               {
                  "approvedCertify": "0",
                  "inspectionFailed": "0"
               },
               "dispositionOfVehicle":
               {
                  "vehicleReturnedServices": "0",
                  "vehicleWithdrawnFromService": "0",
                  "vehicleSentToAnotherRepairFacility": "0"
               },
               // "result": {
               //    "approvedCertify": $("input[name='approvedCertify']:checked").val() === "approvedCertify" ? "1" : "0",
               //    "inspectionFailed": $("input[name='approvedCertify']:checked").val() === "inspectionFailed" ? "1" : "0"
               // },
               // "dispositionOfVehicle": {
               //    "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
               //    "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
               //    "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
               // },
               "vehicleDetails":
               {
                  "vinNumber": $("#vin").html(),
                  "lisencePlateNumber": $("#serviceBrakelisencePlateNumber").html(),
                  "id": vid,
                  "unitNumber": $("#lisencePlateNumber").html()
               },
               "annualVehicleInspection": {
                  "breakSystem": JSON.parse(sessionStorage.getItem("section1Break")),
                  "couplingDevices": JSON.parse(sessionStorage.getItem("section2Coupling")),
                  "exahustSystem": JSON.parse(sessionStorage.getItem("section3Exhaust")),
                  "fuelSystem": JSON.parse(sessionStorage.getItem("section3Fuel")),
                  "lightingDevices": JSON.parse(sessionStorage.getItem("section3LIGHTING")),
                  "safeLoading": JSON.parse(sessionStorage.getItem("section3SAFE")),
                  "steeringMechanism": JSON.parse(sessionStorage.getItem("section4STEERING")),
                  "suspension": JSON.parse(sessionStorage.getItem("section4SUSPENSION")),
                  "frame": JSON.parse(sessionStorage.getItem("section4FRAME")),
                  "tires": JSON.parse(sessionStorage.getItem("section4TIRES")),
                  "wheelsAndRims": {
                     "step5_lockNF": $("input[name='step5_lock_na']:checked").val() === "na" ? "na" : "verified",
                     "step5_lock": $("input[name='step5_lock_repaired']:checked").val() === "1" ? "1" : "0",
                     "step5_lockDate": $("input[name='step5_lockDate']").val(),

                     "step5_wheelsNF": $("input[name='step5_wheels_na']:checked").val() === "na" ? "na" : "verified",
                     "step5_wheels": $("input[name='step5_wheels_repaired']:checked").val() === "1" ? "1" : "0",
                     "step5_wheelsDate": $("input[name='step5_wheelsDate']").val(),

                     "step5_fastenersNF": $("input[name='step5_fasteners_na']:checked").val() === "na" ? "na" : "verified",
                     "step5_fasteners": $("input[name='step5_fasteners_repaired']:checked").val() === "1" ? "1" : "0",
                     "step5_fastenersDate": $("input[name='step5_fastenersDate']").val(),

                     "step5_weldsNF": $("input[name='step5_welds_na']:checked").val() === "na" ? "na" : "verified",
                     "step5_welds": $("input[name='step5_welds_repaired']:checked").val() === "1" ? "1" : "0",
                     "step5_weldsDate": $("input[name='step5_weldsDate']").val(),
                  },
                  "windshieldGlazing": {
                     "step5_requirementsNF": $("input[name='step5_requirements_na']:checked").val() === "na" ? "na" : "verified",
                     "step5_requirements": $("input[name='step5_requirements_repaired']:checked").val() === "1" ? "1" : "0",
                     "step5_requirementsDate": $("input[name='step5_requirementsDate']").val(),
                  },
                  "windshieldWipers": {
                     "step5_powerNF": $("input[name='step5_power_na']:checked").val() === "na" ? "na" : "verified",
                     "step5_power": $("input[name='step5_power_repaired']:checked").val() === "1" ? "1" : "0",
                     "step5_powerDate": $("input[name='step5_powerDate']").val(),
                  },
                  "motorcoachSeats": {
                     "step5_passengerNF": $("input[name='step5_passenger_na']:checked").val() === "na" ? "na" : "verified",
                     "step5_passenger": $("input[name='step5_passenger_repaired']:checked").val() === "1" ? "1" : "0",
                     "step5_passengerDate": $("input[name='step5_passengerDate']").val(),
                  },
                  // "step5_otherNF": $("input[name='step5_other_na']:checked").val() === "na" ? "na" : "verified",
                  "other": $("#otherText").val()!=''?$("#otherText").val():'',
                  // "step5_otherDate": $("input[name='step5_otherDate']").val(),
               },
               "documentList": documentLists

            };


          
            //   const requestOptions = 
            //   {
            //      method: 'POST',
            //      headers: { 'Content-Type': 'application/json'
            //               },

            //         body: JSON.stringify(requestObject)
            //   };
            var requestOptions = requestObject.RequestHeader(requestObjectForInsp);
           
            if (statusInspection == "DRAFT") {
               // setLoading(true);
               services.addInspection(requestOptions).then((res) => {
                  // setLoading(false);
                  // alert(res.success);
                  Swal.fire({
                     position: 'center',
                     icon: 'success',
                     title: "",
                     text: "Your changes have been successfully saved!",
                     showConfirmButton: true

                  }).then((result) => {

                     if (result.isConfirmed) {
                                  sessionStorage.removeItem("section1Break");
                     sessionStorage.removeItem("section2Coupling");
                     sessionStorage.removeItem("section3Exhaust");
                     sessionStorage.removeItem("section3Fuel");
                     sessionStorage.removeItem("section3LIGHTING");
                     sessionStorage.removeItem("section3SAFE");
                     sessionStorage.removeItem("section4STEERING");
                     sessionStorage.removeItem("section4SUSPENSION");
                     sessionStorage.removeItem("section4FRAME");
                     sessionStorage.removeItem("section4TIRES");
                     sessionStorage.removeItem("section5wheelsAndRims");
                     sessionStorage.removeItem("section5windshieldGlazing");
                     sessionStorage.removeItem("section5windshieldWipers");
                     sessionStorage.removeItem("section5motorcoachSeats");
                     sessionStorage.removeItem("other");
                 
                        navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));
                     }
                  });
               });
            } else {

               if ($('input:radio[name="returnedservice"]:checked').val() === "undefined" || $('input:radio[name="returnedservice"]:checked').val() === undefined) {
                  Swal.fire({
                     position: 'center',
                     icon: 'error',
                     title: "",
                     text: "Please select disposition of tank",
                     showConfirmButton: true

                  }).then((result) => {

                     if (result.isConfirmed) {

                        // alert("hhhhd")
                     }
                  });
                  return false;
               }
               if ($('input:radio[name="approvedCertify"]:checked').val() === "" || $('input:radio[name="approvedCertify"]:checked').val() === undefined) {
                  Swal.fire({
                     position: 'center',
                     icon: 'error',
                     title: "",
                     text: "Please select inspection result",
                     showConfirmButton: true

                  }).then((result) => {

                     if (result.isConfirmed) {


                     }
                  });
                  return false;
               }
               if ($("input[name='approvedCertify']:checked").val() === "approvedCertify") {

               } else {

                  status = "Rejected";
               }
           
               var requestObjectInspection =
               {
                  "id": inspectionId,
                  "expiryDate": $("#expiryDate").val(),
                  "verifiedBy": $("#verifiedBy").val(),
                  "verifiedDate": $("#verifiedByBate").val(),
                  "status": status,
                  "taskOrderId":taskOrderId,
                  "result": {
                     "approvedCertify": $("input[name='approvedCertify']:checked").val() === "approvedCertify" ? "1" : "0",
                     "inspectionFailed": $("input[name='approvedCertify']:checked").val() === "inspectionFailed" ? "1" : "0"
                  },
                  "dispositionOfVehicle": {
                     "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
                     "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
                     "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
                  }

               };
               // setLoading(true);
               $("#loadingforsubmit").show();
               services.addInspection(requestOptions).then((res) => {
                  // setLoading(false);
                  $("#loadingforsubmit").hide();
                  if (res.success) {

                     //certify inspection
                     var requestOptions = requestObject.RequestHeader(requestObjectInspection);
                     services.certifyInspection(requestOptions).then((res) => {
                        $("#loadingforsubmit").hide();
                        // setLoading(false);
                        Swal.fire({
                           position: 'center',
                           icon: 'success',
                           title: "",
                           text: res.message,
                           showConfirmButton: true

                        }).then((result) => {

                           if (result.isConfirmed) {
                              sessionStorage.removeItem("section1Break");
                              sessionStorage.removeItem("section2Coupling");
                              sessionStorage.removeItem("section3Exhaust");
                              sessionStorage.removeItem("section3Fuel");
                              sessionStorage.removeItem("section3LIGHTING");
                              sessionStorage.removeItem("section3SAFE");
                              sessionStorage.removeItem("section4STEERING");
                              sessionStorage.removeItem("section4SUSPENSION");
                              sessionStorage.removeItem("section4FRAME");
                              sessionStorage.removeItem("section4TIRES");
                              sessionStorage.removeItem("section5wheelsAndRims");
                              sessionStorage.removeItem("section5windshieldGlazing");
                              sessionStorage.removeItem("section5windshieldWipers");
                              sessionStorage.removeItem("section5motorcoachSeats");
                              sessionStorage.removeItem("other");
                              navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

                           }
                        });
                     });
                  } else {
                     Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: "",
                        text: "Your changes have been successfully saved!",
                        showConfirmButton: true

                     }).then((result) => {

                        if (result.isConfirmed) {
                           sessionStorage.removeItem("section1Break");
                           sessionStorage.removeItem("section2Coupling");
                           sessionStorage.removeItem("section3Exhaust");
                           sessionStorage.removeItem("section3Fuel");
                           sessionStorage.removeItem("section3LIGHTING");
                           sessionStorage.removeItem("section3SAFE");
                           sessionStorage.removeItem("section4STEERING");
                           sessionStorage.removeItem("section4SUSPENSION");
                           sessionStorage.removeItem("section4FRAME");
                           sessionStorage.removeItem("section4TIRES");
                           sessionStorage.removeItem("section5wheelsAndRims");
                           sessionStorage.removeItem("section5windshieldGlazing");
                           sessionStorage.removeItem("section5windshieldWipers");
                           sessionStorage.removeItem("section5motorcoachSeats");
                           sessionStorage.removeItem("other");
                           navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));
                        }
                     });

                  }
               });



            }
         }
      }

   }
   // // handle input change
   // const handleInputChange = (e, index) => {
   //    const { name, value } = e.target;
   //    const list = [...inputList];
   //    list[index][name] = value;
   //    setInputList(list);
   // };

   // // handle click event of the Remove button
   // const handleRemoveClick = index => {
   //    const list = [...inputList];
   //    list.splice(index, 1);
   //    setInputList(list);
   // };

   // // handle click event of the Add button
   // const handleAddClick = () => {
   //    setInputList([...inputList, {}]);
   // };
   const fileToBase64 = (file, cb) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
         cb(null, reader.result)
      }
      reader.onerror = function (error) {
         cb(error, null)
      }
   }
   const onUploadFileChange = (event, index) => {


      var target = event.target;
      if (target.files < 1 || !target.validity.valid) {
         return
      }
      fileToBase64(target.files[0], (err, result) => {
         if (result) {
            const { name, value } = event.target;
            const list = [...inputList];
            list[index][name] = result;
            setBase64file(result);
            // setFile(result)
            setfileName(target.files[0]);
            // setFileName(target.files[0])
         }
      })
   }

   function homeRedirect(){
      navigate("/vehicleDetails/" + localStorage.getItem("vehicleId")); 
   }
   return (
      <>
        {LoadingComponentForSubmit}
         <div class="row mb-2">
            <div class="col-lg-12 col-md-12 m-auto ">
               <div class="card">

                  {/* <div class="divider"></div>

                  <div class="divider"></div> */}
                  <div class="row p-3">
                     <div class="w-100">
                        <h4 class="form-inspection-title text-center">ANNUAL VEHICLE COMPONENTS INSPECTED</h4>
                        <p id="error_message"></p>
                     </div>
                     <div>
                        
                        <WorkOrderNumberDiv />
                       
                        <div class="inspection-wrap">
                           <div class="step-wrap p-3 mb-2">
                              <div class="step-main d-flex">
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt="" /></div>
                                    {/* <!-- <div class="no-cl mr-2 chk-tick "><img src="images/icons/tick.svg" alt=""/></div> --> */}
                                    <div class="labl-section">Brake</div>
                                 </div>
                                 <div class="step-itm mr-3 ">
                                    <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt="" /></div>
                                    <div class="labl-section">Coupling </div>
                                 </div>
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt="" /></div>
                                    <div class="labl-section">Exhaust-Fuel-Lighting-Safe</div>
                                 </div>
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt="" /></div>
                                    <div class="labl-section">Steering-Suspension</div>
                                 </div>
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2 current-tick">5</div>
                                    <div class="labl-section">Wheels-Glazing</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="row mb-4">

                           <div class="col-lg-12">
                              <h5 class="w-100 text-uppercase p-3 mb-0" style={{ backgroundColor: "#e5e5e5" }}>Wheels and Rims  </h5>
                              <div class="border mb-2">
                                 <table>
                                    <tbody>
                                       <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                          <th>Type</th>
                                          <th>NA</th>
                                          <th>Verified OK</th>
                                          <th>Repaired</th>
                                          <th>Date</th>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>a. Lock or Side Ring</td>
                                          <td ><input type="radio" name="step5_lock_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step5_lock_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td class="text-center"><input type="checkbox" name="step5_lock_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step5_lockDate} onChange={(date) => setStep5_lockDate(date)} name="step5_lockDate" class="form-control" id="step5_lockDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step5_lockDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>b. Wheels and Rims</td>
                                          <td ><input type="radio" name="step5_wheels_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step5_wheels_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td class="text-center"><input type="checkbox" name="step5_wheels_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step5_wheelsDate} onChange={(date) => setStep5_wheelsDate(date)} name="step5_wheelsDate" class="form-control" id="step5_wheelsDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step5_wheelsDateTemp" /></td>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>c. Fasteners </td>
                                          <td ><input type="radio" name="step5_fasteners_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step5_fasteners_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td class="text-center"><input type="checkbox" name="step5_fasteners_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step5_fastenersDate} onChange={(date) => setStep5_fastenersDate(date)} name="step5_fastenersDate" class="form-control" id="step5_fastenersDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step5_fastenersDateTemp" /></td>
                                       </tr>

                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>d. Welds  </td>
                                          <td ><input type="radio" name="step5_welds_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step5_welds_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td class="text-center"><input type="checkbox" name="step5_welds_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step5_weldsDate} onChange={(date) => setStep5_weldsDate(date)} name="step5_weldsDate" class="form-control" id="step5_weldsDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step5_weldsDateTemp" /></td>
                                       </tr>


                                    </tbody>
                                 </table>
                              </div>
                           </div>

                           <div class="col-lg-12">
                              <h5 class="w-100 text-uppercase p-3 mb-0" style={{ backgroundColor: "#e5e5e5" }}>WINDSHIELD GLAZING</h5>
                              <div class="border mb-2">
                                 <table>
                                    <tbody>
                                       <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                          <th>Type</th>
                                          <th>NA</th>
                                          <th>Verified OK</th>
                                          <th>Repaired</th>
                                          <th>Date</th>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>Requirements and exceptions as stated pertaining to any  crack, discoloration or vision reducing matter (reference 393.60 for exceptions)</td>
                                          <td ><input type="radio" name="step5_requirements_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step5_requirements_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td class="text-center"><input type="checkbox" name="step5_requirements_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step5_requirementsDate} onChange={(date) => setStep5_requirementsDate(date)} name="step5_requirementsDate" class="form-control" id="step5_requirementsDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step5_requirementsDateTemp" /></td>
                                       </tr>

                                    </tbody>
                                 </table>
                              </div>
                           </div>

                           <div class="col-lg-12">
                              <h5 class="w-100 text-uppercase p-3 mb-0" style={{ backgroundColor: "#e5e5e5" }}>WINDSHIELD WIPERS</h5>
                              <div class="border mb-2">
                                 <table>
                                    <tbody>
                                       <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                          <th>Type</th>
                                          <th>NA</th>
                                          <th>Verified OK</th>
                                          <th>Repaired</th>
                                          <th>Date</th>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>Any power unit that has an inoperative wiper, or missing or damaged parts that render it ineffective</td>
                                          <td ><input type="radio" name="step5_power_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step5_power_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td class="text-center"><input type="checkbox" name="step5_power_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step5_powerDate} onChange={(date) => setStep5_powerDate(date)} name="step5_powerDate" class="form-control" id="step5_powerDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step5_powerDateTemp" /></td>
                                       </tr>

                                    </tbody>
                                 </table>
                              </div>
                           </div>

                           <div class="col-lg-12">
                              <h5 class="w-100 text-uppercase p-3 mb-0" style={{ backgroundColor: "#e5e5e5" }}>MOTORCOACH SEATS</h5>
                              <div class="border mb-2">
                                 <table>
                                    <tbody>
                                       <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                          <th>Type</th>
                                          <th>NA</th>
                                          <th>Verified OK</th>
                                          <th>Repaired</th>
                                          <th>Date</th>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>Any passenger seat that is not securely fastened to the vehicle structure</td>
                                          <td ><input type="radio" name="step5_passenger_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step5_passenger_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td class="text-center"><input type="checkbox" name="step5_passenger_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step5_passengerDate} onChange={(date) => setStep5_passengerDate(date)} name="step5_passengerDate" class="form-control" id="step5_passengerDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step5_passengerDateTemp" /></td>
                                       </tr>

                                    </tbody>
                                 </table>
                              </div>
                           </div>

                           <div class="col-lg-12">
                              <h5 class="w-100 text-uppercase p-3 mb-0" style={{ backgroundColor: "#e5e5e5" }}>OTHER </h5>
                              <div class="border mb-2">

                                 <textarea class="form-control" name="otherText" maxLength="300" id="otherText" placeholder="Enter notes">

                                 </textarea>
                                 {/* <table>
                                    <tbody>
                                       <tr class="pb-2 annuaul-tb-5cl border-bottom p-2">
                                          <th>Type</th>
                                          <th>NA</th>
                                          <th>Verified OK</th>
                                          <th>Repaired</th>
                                          <th>Date</th>
                                       </tr>
                                       <tr className="annuaul-tb-5cl p-2">
                                          <td>List any other condition(s) which may prevent safe operation of this vehicle</td>
                                          <td ><input type="radio" name="step5_other_na" value="na" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td ><input type="radio" name="step5_other_na" value="verified" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td class="text-center"><input type="checkbox" name="step5_other_repaired" value="1" aria-label="Checkbox for following text input" class="mr-2" onChange={() => checkVerified()} /></td>
                                          <td><DatePicker selected={step5_otherDate} onChange={(date) => setStep5_otherDate(date)} name="step5_otherDate" class="form-control" id="inspectedDate" placeholderText="MM-DD-YYYY" /><input type="text" class="form-control" name="step5_otherDateTemp" /></td>
                                       </tr>

                                    </tbody>
                                 </table> */}
                              </div>
                              
                           </div>
                        </div>
                     </div> 
                       
                     <div class="divider2"></div>
                            <div class="form-footer-comon-wrap w-100">
                                <div class="row documents p-3">
                                   <div class="col-lg-12">
                                      <h4 class="mb-4">Documents</h4>
                                      <div class="row">
                                      {
                                                                                                documentList?documentList.map((data, index) => (
                                         <div class="col-md-12" style={{marginTop:"-18px"}}>
                                            <div class="align-items-center">
                                               <img src="images/icons/pdf-icon.svg" alt=""/>
                                               
                                                  {/* <b>{data.title}.{data.extension}</b> */}
                                                  <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                    <img src="" class="mr-2" />
                                                                    <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                    <a onClick={() => documentDetails_(data.filePath,data.extension)} class="ml-2 w-100">  {(() => {
                                                                            if (data.extension == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                        {data.name ? data.name : "--"} </a></p></span>
                                                                    <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" /> </div>
                                                  
                                               {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                            </div>
                                         </div>
                                        
                                       
                                         )):""
                                                                                               
                                       }
                                      </div>
                                   </div><br/>
                                   
                                </div>
                                <div class="row p-3">
                            <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName}/>
                            </div>                      
                            <AddInspectionName setEndDate={setEndDate} endDate={endDate} />
                             
                            
                               
                    
                             </div>
                             </div>

                             {statusInspection == "DRAFT" ? "" : <div class="divider"></div>}
                        {(() => {
                           if (statusInspection == "DRAFT") {
                              <></>
                           } else {
                              if (!roles.match("Technician")) {
                                 if (inspectionDetails.status == 'approved' || inspectionDetails.status == 'approve' || inspectionDetails.status == 'APPROVED' || inspectionDetails.status == 'Rejected' || inspectionDetails.status == 'REJECTED') {
                                    return (
                                       <>
                                          <div class="result-didspos">
                                             <div class="p-4">
                                                <span>Result</span>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Inspection Failed</b>
                                                </div>
                                             </div>
                                             <div class="p-4">
                                                <span>Disposition of Tank</span>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : ""}
                                                   <b class="mb-0 ml-2">Tank Returned to Service</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                                </div>
                                             </div>
                                          </div>
                                          <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate}  status={"readOnly"} statusInspection={statusInspection} customerId={fkAccountId}/>
                                          {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} status={"readOnly"} customerId={fkAccountId}/> */}
                                       </>
                                    )
                                 } else {
                                    return (
                                       <><div class="result-didspos ">
                                          <div class="p-4">
                                             <span>Result</span>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                             </div>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Inspection Failed</b>
                                             </div>
                                          </div>
                                          <div class="p-4">
                                             <span>Disposition of Tank</span>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Tank returned to service</b>
                                             </div>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                             </div>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                             </div>
                                          </div>
                                       </div>
                                       <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate}   customerId={fkAccountId}/>
                                          {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} customerId={fkAccountId}/> */}


                                       </>
                                    )
                                 }
                              }
                           }
                        })()}

                  <div class="divider2"></div>
                  <div class="row p-2 pr-4 d-flex justify-content-end" style={{ marginBottom: "-20px" }}>

                     <button type="button" id="annualVehicle4" class="btn btn-primary mr-1" >Back</button>
                     <button  class="btn btn-secondary mr-1" onClick={() => homeRedirect()}>Cancel</button>
                     {statusInspection == "DRAFT"?<button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("DRAFT")}>Save as Draft</button>:""}
                                {(() => {
                              if (statusInspection == "DRAFT") {
                                 return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("APPROVED PENDING")}>Save</button></>)
                              } else {
                                 if (roles.match("Manager")||roles.match("Supervisor") || roles.match("Owner") || roles.match("Inspector")) {
                                    return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("approved")}>Save</button></>)
                                 }
                                 else {
                                    return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("APPROVED PENDING")}>Save</button></>)
                                 }
                              }
                           })()}

                     {/* <a  class="btn btn-primary mr-3">Back</a>
                              <a href="#" class="btn btn-primary mr-5" id="inspectionVehicle5">Save</a> */}
                  </div>
               </div>
            </div>
         </div>

      </>
   )
};

AnnualVehicle5.propTypes = {};

AnnualVehicle5.defaultProps = {};

export default AnnualVehicle5;
