import React, { history, useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const ExternalVisualRead = () => {
   const { fkAccountId, inspectionId, vid } = useParams();
   const [inputList, setInputList] = useState([{}]);
   const [base64file, setBase64file] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const [fileName, setfileName] = useState("");
   const [inspectionDetails, setInspectionDetails] = useState("");
   const [expiryDate, setexpiryDate] = useState(new Date());
   const [documentList, setDocumentLists] = useState([]);
   const navigate = useNavigate();
   const [endDate, setEndDate] = useState(new Date());
   const [certify, setApproveCertify] = useState();
   const [verifiedDATE, setverifiedDate] = useState(new Date());
   const [roles, setRoles] = useState("");
   const [statusInspection, setStatus] = useState("");
   useEffect(() => {
      setRoles(localStorage.getItem("Userroles"));
      // vehicle_details_api(id, setVehicleDetails, setCustomerId); 
      getInpection(fkAccountId, inspectionId)
   }, []);

   function getInpection(fkAccountId, inspectionId) {
      var requestObjectForWet =
      {

         "id": inspectionId
      }

      var requestOptions = requestObject.RequestHeader(requestObjectForWet);

      services.getInspectionDetails(requestOptions).then((res) => {

         setInspectionDetails(res.rows);
         setDocumentLists(res.rows.documentList ? res.rows.documentList : "");
         $("#workOrderNumber").val(res.rows ? res.rows.workOrderNumber : "");
         // sessionStorage.setItem("verifiedBy",res.rows ? res.rows.verifiedBy : "");
         setApproveCertify(res.result ? res.result.approvedCertify : "");
         setStatus(res.rows.status);
         if (res.rows) {

            if (res.rows.expiryDate) {
               setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
            }
         }
      });

   }

   const redirect = (page) => {

      navigate(page);

   }
   ////////////////documentDetails Customer/////////////////////////////
   const documentDetails_ = (filePath) => {


      var document_request =
      {

         "filePath": filePath
      };


      var requestOptions = requestObject.RequestHeader(document_request);
      services.getInspectionDocumentDetails(requestOptions).then((res) => {

         if (res.customValues.fileByte === "") {
            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: "File is not attached",

               showConfirmButton: true

            }).then((result) => {
               if (result.isConfirmed) {

               }
            });
         } else {
            var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
               byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var file = new Blob([byteArray], { type: 'application/pdf;base64' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
         }



      });

   }
   const save = (status) => {

      // console.log("results=="+JSON.stringify(inputList));
      if ($("#inspectedBy").val() === "") {
         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Please enter Inspected by",
            showConfirmButton: true

         }).then((result) => {

            if (result.isConfirmed) {


            }
         });
         return false;
      }

      if ($('input:radio[name="returnedservice"]:checked').val() === "undefined" || $('input:radio[name="returnedservice"]:checked').val() === undefined) {
         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Please select disposition of tank",
            showConfirmButton: true

         }).then((result) => {

            if (result.isConfirmed) {


            }
         });
         return false;
      }
      if ($('input:radio[name="approvedCertify"]:checked').val() === "" || $('input:radio[name="approvedCertify"]:checked').val() === undefined) {

         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Please select inspection result",
            showConfirmButton: true

         }).then((result) => {

            if (result.isConfirmed) {


            }
         });
         return false;
      }
      if ($("input[name='approvedCertify']:checked").val() === "approvedCertify") {

      } else {

         status = "Rejected";
      }
      var requestObjectInspection =
      {
         "id": inspectionId,
         "expiryDate": $("#expiryDate").val(),
         "verifiedBy": $("#verifiedBy").val(),
         "verifiedDate": $("#verifiedByBate").val(),
         "status": status,

         "result": {
            "approvedCertify": $("input[name='approvedCertify']:checked").val() === "approvedCertify" ? "1" : "0",
            "inspectionFailed": $("input[name='approvedCertify']:checked").val() === "inspectionFailed" ? "1" : "0"
         },
         "dispositionOfVehicle": {
            "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
            "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
            "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
         }


      };

      $("#loadingforsubmit").show();
      //  setLoading(true);
      // const requestOptions = 
      // {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json'
      //             },

      //       body: JSON.stringify(requestObject)
      // };
      var requestOptions = requestObject.RequestHeader(requestObjectInspection);
      services.certifyInspection(requestOptions).then((res) => {
         $("#loadingforsubmit").hide();
         Swal.fire({
            position: 'center',
            icon: 'success',
            title: "",
            text: res.message,
            showConfirmButton: true

         }).then((result) => {

            if (result.isConfirmed) {
               navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

            }
         });
      });

   }
   return (
      <>
         {LoadingComponentForSubmit}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block" }}>
               <div class="row mb-0">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <div class="d-flex justify-content-between align-items-center my-4">
                     <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                     </div>
                  </div>
               </div>
               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                     <HeaderBlock cids={fkAccountId} />
                  </div>
               </div>

               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                     <div class="card">
                        <div class="row mb-2">
                           <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                              <VehicleBasicInfo customerId={vid} />
                           </div>
                        </div>
                        <div class="divider"></div>
                        <div class="row p-3">
                           <div class="col-lg-12">
                              <h4 class="mb-2 form-inspection-title text-center">External / Visual Inspection</h4>
                           </div>
                           <div class="col-lg-12"><WorkOrderNumberDiv status={"readOnly"} /></div><br />
                           <div class="col-lg-12">
                              <div class="inspection-wrap">
                                 <div class="table-2 mb-4">
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col">Section 1: Tank Shell / Heads are there any                                                   </th>
                                             <th scope="col">Yes</th>
                                             <th scope="col">No</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr>
                                             <td>
                                                <p>Corroded or abraded areas</p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section1.corrodedOrAbradedAreas == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section1.corrodedOrAbradedAreas == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Dents</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section1.dents == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section1.dents == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Distortions</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section1.distortions == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section1.distortions == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Defects in Welds</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section1.defects == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section1.defects == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Leak of any kind</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section1.leaksOfAnyKind == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section1.leaksOfAnyKind == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Any condition that might render tank unsafe for transportation service</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section1.anyConditionThatmightRender == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section1.anyConditionThatmightRender == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Corrosive ladding being transported</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section1.beingtransported == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section1.beingtransported == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                              <div class="inspection-wrap">
                                 <div class="table-2 mb-4">
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col">Section 2: External Piping / Valves are there any:  </th>
                                             <th scope="col">Yes</th>
                                             <th scope="col">No</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr>
                                             <td>
                                                <p>Corroded or abraded areas</p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.corroded == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.corroded == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Defects in Welds</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.welds == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.welds == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Defects in valves or gaskets</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.gaskets == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.gaskets == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Defects in manifold</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.defectmanifold == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.defectmanifold == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Defects in dust cap</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.defectindustCap == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.defectindustCap == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          {/* <tr>
                                                <td>Defects in dust cap</td>
                                                <td class="text-center">{inspectionDetails.externalVisualInspection?inspectionDetails.externalVisualInspection.section2.defectindustCap=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                <td class="text-center">{inspectionDetails.externalVisualInspection?inspectionDetails.externalVisualInspection.section2.defectindustCap=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                             </tr> */}
                                          <tr>
                                             <td>Defects in safety valve operator</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.defectinsafety == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.defectinsafety == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Defects in remote control</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.defectinremoteControl == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.defectinremoteControl == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Defects in fusible link/plug</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.defectfusibleLink == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.defectfusibleLink == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Defects in pump offline</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.defectinpumpOffline == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.defectinpumpOffline == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Leaks of any kind</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.leaksOfAnyKind == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.leaksOfAnyKind == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Any condition that might render tank unsafe for
                                                transportation service
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.transportionService == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section2.transportionService == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                              <div class="inspection-wrap">
                                 <div class="table-2 mb-4">
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col">Section 3: Spill Collar/ Manhole are there any  </th>
                                             <th scope="col">Yes</th>
                                             <th scope="col">No</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr>
                                             <td>
                                                <p>Manhole cover hold-downs stripped/inoperative                                                      </p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.inoperative == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.inoperative == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>
                                                <p>Defects in the dome cover                                                      </p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.doomcover == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.doomcover == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>
                                                <p>Defects in gaskets                                                      </p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.gaskets == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.gaskets == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>
                                                <p>Defects in vacuum vent                                                      </p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.vaccumevent == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.vaccumevent == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>
                                                <p>Defects in pop off valve
                                                </p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.popofvalves == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.popofvalves == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>
                                                <p>Defects in rupture disc
                                                </p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.reputerDisc == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.reputerDisc == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>
                                                <p>Defects in air pressure gauge
                                                </p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.pressureGauage == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.pressureGauage == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>
                                                <p>Defects in air hook up
                                                </p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.hairHookup == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.hairHookup == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>
                                                <p>Defects in fusible disc / cap
                                                </p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.fusible == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.fusible == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>
                                                <p>Defects in air inlet valve / bleeder
                                                </p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.bleeder == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.bleeder == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>
                                                <p>Defects in cleanout ports or gaskets
                                                </p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.port == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.port == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>
                                                <p>Spill collar drains clogged
                                                </p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.drainsClogged == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.drainsClogged == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>
                                                <p>Defects in spill collar
                                                </p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.spillcollar == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.spillcollar == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>
                                                <p>Leaks of any kind
                                                </p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.leakofAnyKind == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section3.leakofAnyKind == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-12">
                              <div class="inspection-wrap">
                                 <div class="table-2 mb-4">
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col">Section 4: Emergency devices, valves including self-Closing stop
                                                valves. Excess flow remote closure device are there any:
                                             </th>
                                             <th scope="col">Yes</th>
                                             <th scope="col">No</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr>
                                             <td>
                                                <p>Corroded or abraded areas                                                   </p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section4.corroded == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section4.corroded == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          {/* <tr>
                                                <td>Dents</td>
                                                <td class="text-center">{inspectionDetails.externalVisualInspection?inspectionDetails.externalVisualInspection.section4.leakofAnyKind=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                <td class="text-center">{inspectionDetails.externalVisualInspection?inspectionDetails.externalVisualInspection.section4.leakofAnyKind=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                             </tr> */}
                                          <tr>
                                             <td>Distortions</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section4.distortions == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section4.distortions == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Indications of erosion</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section4.indicationOferasion == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section4.indicationOferasion == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>External damage that will prevent safe operation</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section4.preventSafeOperation == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section4.preventSafeOperation == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Devices not functioning properly</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section4.devicenotfunctioning == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section4.devicenotfunctioning == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                              <div class="inspection-wrap">
                                 <div class="table-2 mb-4">
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col">Section 5: Bolts / Flanges:                                             </th>
                                             <th scope="col">Yes</th>
                                             <th scope="col">No</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr>
                                             <td>
                                                <p>Are any bolts, nuts or fusible links missing     </p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section5 ? inspectionDetails.externalVisualInspection.section5.linksMissing == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : "" : ''}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section5 ? inspectionDetails.externalVisualInspection.section5.linksMissing == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : "" : ''}</td>
                                          </tr>
                                          <tr>
                                             <td>Are any bolts, nuts or fusible links loose</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section5.linksloose == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section5.linksloose == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                              <div class="inspection-wrap">
                                 <div class="table-2 mb-4">
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col">Section 6: General vehicle condition/Are any items not in good
                                                operating condition ?
                                             </th>
                                             <th scope="col">Yes</th>
                                             <th scope="col">No</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr>
                                             <td>
                                                <p>Lights</p>
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.lights == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.lights == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>"U" bolts</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.bolts == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.bolts == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Ladder</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.ladder == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.ladder == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Frame</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.frame == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.frame == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>5th wheel plate and pin</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.plateAndPin == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.plateAndPin == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Landing gear and legs</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.landingGear == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.landingGear == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Spring and air bags</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.airBags == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.airBags == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Brakes</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.brakes == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.brakes == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Air lines</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.airlines == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.airlines == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Tires</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.tires == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.tires == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Are any required markings on cargo tank illegible</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.tankIllegible == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.tankIllegible == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Spec plate illegible</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.specsPlate == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.specsPlate == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Does the 5th wheel assembly ,suspension system attachments and connecting structures show signs of corrosion or damage which might prevent safe operation</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.Does5th == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.Does5th == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>Tank marking: date (month/year) and service symbol after
                                                all defects are corrected. Did markings get updated on
                                                tank
                                             </td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.updatedonTank == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.updatedonTank == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                          <tr>
                                             <td>5th wheel plate removed and inspected</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.inspected == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.externalVisualInspection ? inspectionDetails.externalVisualInspection.section6.inspected == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="divider"></div>
                        <div class="form-footer-comon-wrap">
                           <div class="row documents p-3">
                              <div class="col-lg-12">
                                 {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                 <div class="row">
                                    {
                                       documentList ? documentList.map((data, index) => (
                                          <div class="col-md-12">
                                             <div class="d-flex align-items-center">
                                                <img src="images/icons/pdf-icon.svg" alt="" />
                                                <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100">
                                                   {/* <b>{data.title}.{data.extension}</b> */}
                                                   <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                      <img src="" class="mr-2" />
                                                      <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                         {(() => {
                                                            if (data.extension == 'pdf') {
                                                               return (
                                                                  <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                               )
                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                               return (
                                                                  <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                               )
                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                               return (
                                                                  <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                               )
                                                            } else {
                                                               return (
                                                                  <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                               )
                                                            }
                                                         })()}
                                                         {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                         {data.name ? data.name : "--"} </p></span>
                                                      {/* <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" />  */}
                                                   </div>
                                                </a>
                                                {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                             </div>
                                          </div>


                                       )) : ""

                                    }
                                 </div>
                              </div><br />

                           </div>
                           <div class="row documents p-3">
                              <div class="col-lg-12">
                                 <h6 class="mb-4">Notes: </h6>
                                 {inspectionDetails ? inspectionDetails.notes : ''}

                              </div>
                           </div>
                           <div class="divider2"></div>
                           <div class="row performby p-4">
                              <div class="col-lg-12">
                                 <div class="row">
                                    <div class="col-md-4">
                                       <div class="d-flex flex-column">
                                          <label for="">Test Performed By:</label>
                                          <b>{inspectionDetails.inspectedBy}</b>
                                       </div>
                                    </div>
                                    <div class="col-md-4">
                                       <div class="d-flex flex-column">
                                          <label for="">Date</label>
                                          <b>{inspectionDetails.inspectedDate}</b>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div class="divider"></div>
                           {(() => {
                              if (!roles.match("Technician")) {
                                 if (inspectionDetails.status == 'approved' || inspectionDetails.status == 'approve' || inspectionDetails.status == 'APPROVED' || inspectionDetails.status == 'Rejected' || inspectionDetails.status == 'REJECTED') {
                                    return (
                                       <>
                                          <div class="result-didspos">
                                             <div class="p-4">
                                                <span>Result</span>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Inspection Failed</b>
                                                </div>
                                             </div>
                                             <div class="p-4">
                                                <span>Disposition of Tank</span>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : ""}
                                                   <b class="mb-0 ml-2">Tank Returned to Service</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                                </div>
                                             </div>
                                          </div>
                                          <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection} customerId={fkAccountId} />
                                          {/* <VerifiedBy setEndDate={setEndDate} endDate={endDate} status={"readOnly"} /> */}
                                       </>
                                    )
                                 } else {
                                    return (
                                       <>
                                          <div class="result-didspos ">
                                             <div class="p-4">
                                                <span>Result</span>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" defaultChecked={"true"} /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" /> : ""}
                                                   <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" defaultChecked={"true"} /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" /> : ""}
                                                   <b class="mb-0 ml-2">Inspection Failed</b>
                                                </div>
                                             </div>
                                             <div class="p-4">
                                                <span>Disposition of Tank</span>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" /> : ""}
                                                   <b class="mb-0 ml-2">Tank returned to service</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" /> : ""}
                                                   <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" /> : ""}
                                                   <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                                </div>
                                             </div>
                                          </div>
                                          <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} customerId={fkAccountId} />
                                          {/* <VerifiedBy setEndDate={setEndDate} endDate={endDate} status={""}/> */}
                                          {/* <div class="divider"></div>
         <div class="row verifyby p-4">
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Verified By</label>
                  <input type="text" class="form-control custome-input" id="verifiedBy" value={inspectionDetails.verifiedBy} aria-describedby="emailHelp" placeholder="Enter Name"/>
               </div>
            </div>
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Date</label>
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="verifiedByBate" />
                
               </div>
            </div>
         </div> */}

                                       </>
                                    )
                                 }
                              }
                           })()}
                        </div>

                        <div class="divider"></div>
                        {(() => {
                           if (inspectionDetails.status == 'approved' || inspectionDetails.status == 'approve' || inspectionDetails.status == 'APPROVED' || inspectionDetails.status == 'Rejected' || inspectionDetails.status == 'REJECTED') {
                           }
                           else {
                              return (<> <div class="row p-3 pr-4 d-flex justify-content-end">
                                 <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1 ">Cancel</a>
                                 {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a> */}
                                 {roles.match("Technician") ? "" : <a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a>}
                                 {roles.match("Technician") ? <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-primary mr-1">Back</a> : ""}
                              </div></>)
                           }
                        })()}
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </>
   )
};

ExternalVisualRead.propTypes = {};

ExternalVisualRead.defaultProps = {};

export default ExternalVisualRead;