import React,{history,useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate ,useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import  $ from "jquery";
import {useLoading} from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import AddInspectionName from "../../../../utility/addInspectionName";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const CarrierEquipment = () => {
  const { taskOrderId,id, vid } = useParams();
  const [inputList, setInputList] = useState([{ }]);
  const [base64file, setBase64file] = useState('');
  const [loading, setLoading] = useLoading("Please wait...");
  const [ fileName, setfileName ] = useState("");
  const navigate = useNavigate();  
  const [endDate, setEndDate] = useState(new Date());

  $(document).ready(function () {

    $("#com1,#com2,#com3,#com4,#com2,#com5,#com6,#outage1,#outage2,#outage3,#outage4,#outage5,#outage6,#coutage1,#coutage2,#coutage3,#coutage4,#coutage5,#coutage6,#preset1,#preset2,#preset3,#preset4,#preset5,#preset6").keypress(function (e) {
        if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
        //   $("#errmsg").html("Digits Only").show().fadeOut("slow");
                 return false;
      }
     });
  });

 

  const handleSubmit=()=>
  {

let form_is_valid = true;

var compartmentcapacity=$("input[name='compartmentcapacity']:checked").val();
var workingcondition=$("input[name='workingcondition']:checked").val();
var shutdown=$("input[name='shutdown']:checked").val();
var operatingcondition=$("input[name='operatingcondition']:checked").val();
var allowloading=$("input[name='allowloading']:checked").val();
var bondingwires=$("input[name='bondingwires']:checked").val();
var hose=$("input[name='hose']:checked").val();
var plateinstalled=$("input[name='plateinstalled']:checked").val();
var thatarehauled=$("input[name='thatarehauled']:checked").val();
var datacurrent=$("input[name='datacurrent']:checked").val();
var decalscurrent=$("input[name='decalscurrent']:checked").val();
var onboard=$("input[name='onboard']:checked").val();
var unitnumbers=$("input[name='unitnumbers']:checked").val();
var uponrequest=$("input[name='uponrequest']:checked").val();
var recordedabove=$("input[name='recordedabove']:checked").val();


// var unitDesignPressure=$('#unitDesignPressure').val();
// var allowMax=/^[0-9]*$/;

// if (!unitDesignPressure) {
//   form_is_valid = false;
//   $("#unitDesignPressure-message").html('Unit design pressure  is required');
// }else{
//         $("#unitDesignPressure-message").html('');
//         form_is_valid=true;
//   }



if(compartmentcapacity==undefined||workingcondition==undefined||shutdown==undefined||operatingcondition==undefined||allowloading==undefined||bondingwires==undefined||hose==undefined||plateinstalled==undefined||thatarehauled==undefined||datacurrent==undefined||decalscurrent==undefined||onboard==undefined||unitnumbers==undefined||uponrequest==undefined||recordedabove==undefined){
  // $('html, body').animate({
  //   scrollTop: $("#inspectionType").offset().top
  // }, 500);
  $('html,body').animate({ scrollTop: "500px" }, 1000);
  $('#error_message').css("color", "#d33232").html("*Please choose one option for each question");
  form_is_valid= false;

}else{
  $('#error_message').html(''); 
}
return form_is_valid;

}
const redirect=(page)=>
{

   navigate(page);

}
  const save=(status)=>
  {

    if(status=='DRAFT'){

      var documentList=[];
    try
    {
      for(var i=0;i<inputList.length;i++)
      {
          // console.log("filebase64=="+inputList[i].filetobase64);
          var extension="";
          var byteString="";
          if(inputList[i].filetobase64.match("data:application/pdf;base64,"))
          {
              extension="pdf";
              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/pdf;base64,", ""):"";
          }else if(inputList[i].filetobase64.match("data:image/jpeg;base64,"))
          {
              extension="jpg";
              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/jpeg;base64,", ""):"";
          }
          else if(inputList[i].filetobase64.match("data:image/png;base64,"))
           {
               extension="png";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/png;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/msword;base64,"))
           {
               extension="word";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/msword;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:image/bmp;base64,"))
           {
               extension="bmp";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/bmp;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
           {
               extension="docm";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
           {
               extension="dotx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
           {
               extension="dotx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,"))
           {
               extension="dotm";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", ""):"";
           }
           else{
              
           }


             documentList.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
      }
    }catch(exception)
    {
      documentList.push();
      // Swal.fire({  
      //     position: 'center',  
      //     icon: 'error',  
      //     title: "",  
      //     text:"Please attach document",
      //     showConfirmButton: true 
          
      //   }).then((result) => 
      //   {
              
      //         if(result.isConfirmed) 
      //         {
                
              
      //         }
      //   });
      //   return false;
    }
    if($("#inspectedBy").val()==="")
    {
      Swal.fire({  
          position: 'center',  
          icon: 'error',  
          title: "",  
          text:"Please enter inspectedBy",
          showConfirmButton: true 
          
        }).then((result) => 
        {
              
              if(result.isConfirmed) 
              {
                
              
              }
        });
        return false;
    }
        
        var requestObjectForInsp=
        {
          
          "type":"Carrier Equipment Inspection",
          "inspectedBy":  $("#inspectedBy").val(),
          "inspectedDate":$("#inspectedDate").val(),
          "verifiedBy": $("#verifiedBy").val(),
          "verifiedDate": $("#verifiedBy").val(),
          "workOrderNumber": $("#workOrderNumber").val()!=''?$("#workOrderNumber").val():'',
          "notes": $("#notes").val()!=''?$("#notes").val():'',
          "status": status,
          "taskOrderId":taskOrderId,
          "inspectionType":
          {
              "id": sessionStorage.getItem("selectedinspectionId"),
              "name":sessionStorage.getItem("selectedinspectionType")
          },
          "result":
          {
            "approvedCertify": "0",
            "inspectionFailed": "0"
          },
          "dispositionOfVehicle":
          {
            "vehicleReturnedServices": "0",
            "vehicleWithdrawnFromService": "0",
            "vehicleSentToAnotherRepairFacility": "0"
          },
          "carrierEquipmentInspection":{
          "maxCompartmentCapacity": [
            $("#com1").val(),
            $("#com2").val(),
            $("#com3").val(),
            $("#com4").val(),
            $("#com5").val(),
            $("#com6").val()
          ],
          "probeOutage": [
            $("#outage1").val(),
            $("#outage2").val(),
            $("#outage3").val(),
            $("#outage4").val(),
            $("#outage5").val(),
            $("#outage6").val(),
          ],
          "carrierOutage": [
            $("#coutage1").val(),
            $("#coutage2").val(),
            $("#coutage3").val(),
            $("#coutage4").val(),
            $("#coutage5").val(),
            $("#coutage6").val(),
          ],
       
          "maximumPreset": [
            $("#preset1").val(),
            $("#preset2").val(),
            $("#preset3").val(),
            $("#preset4").val(),
            $("#preset5").val(),
            $("#preset6").val()
          ],
         
          "certifiedInspectionRequirements": {
            "maximumCompartmentCapacity": $("input[name='compartmentcapacity']:checked").val()==="on"?"1":"0",
            "systemInWorkingCondition": $("input[name='workingcondition']:checked").val()==="on"?"1":"0",
            "activatestheShutdownCircuitry": $("input[name='shutdown']:checked").val()==="on"?"1":"0",
            "properOperatingCondition": $("input[name='operatingcondition']:checked").val()==="on"?"1":"0",
            "readingToAllowLoading": $("input[name='allowloading']:checked").val()==="on"?"1":"0",
            "secureBondingWires": $("input[name='bondingwires']:checked").val()==="on"?"1":"0",
            "hose": $("input[name='hose']:checked").val()==="on"?"1":"0",
          },
          "carrierVerificationRequirements": {
            "specificationPlateInstalled": $("input[name='plateinstalled']:checked").val()==="on"?"1":"0",
            "productsThatareHauled": $("input[name='thatarehauled']:checked").val()==="on"?"1":"0",
            "dataCurrent": $("input[name='datacurrent']:checked").val()==="on"?"1":"0",
            "decalsCurrent": $("input[name='decalscurrent']:checked").val()==="on"?"1":"0",
            "includingGuidebook": $("input[name='onboard']:checked").val()==="on"?"1":"0",
            "appropriateUnitNumbers": $("input[name='unitnumbers']:checked").val()==="on"?"1":"0",
            "availableUponRequest": $("input[name='uponrequest']:checked").val()==="on"?"1":"0",
            "presetsRecordedAbove": $("input[name='recordedabove']:checked").val()==="on"?"1":"0"
          },
        },
          "vehicleDetails":
          {
              "vinNumber": $("#vin").html(),
              "lisencePlateNumber": $("#lisencePlateNumber").html(),
              "id":localStorage.getItem("vehicleId"),
              "unitNumber": $("#lisencePlateNumber").html()
          },
         
          "documentList": documentList
        
      }; 

      $("#loadingforsubmit").show();
      // setLoading(true);
      // const requestOptions = 
      // {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json'
      //             },
                  
      //       body: JSON.stringify(requestObject)
      // };
      var requestOptions=requestObject.RequestHeader(requestObjectForInsp);
      services.addInspection(requestOptions).then((res) => 
      {
        $("#loadingforsubmit").hide();
        // setLoading(false);
        Swal.fire({  
          position: 'center',  
          icon: 'success',  
          title: "",  
          text:res.message,
          showConfirmButton: true 
          
        }).then((result) => 
        {
              
              if(result.isConfirmed) 
              {
                navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));
              
              }
        });
      });

    }else{
      
    if(handleSubmit()==true){

    var documentList=[];
    try
    {
      for(var i=0;i<inputList.length;i++)
      {
          // console.log("filebase64=="+inputList[i].filetobase64);
          var extension="";
          var byteString="";
          if(inputList[i].filetobase64.match("data:application/pdf;base64,"))
          {
              extension="pdf";
              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/pdf;base64,", ""):"";
          }else if(inputList[i].filetobase64.match("data:image/jpeg;base64,"))
          {
              extension="jpg";
              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/jpeg;base64,", ""):"";
          }
          else if(inputList[i].filetobase64.match("data:image/png;base64,"))
          {
              extension="png";
              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/png;base64,", ""):"";
          }
          else if(inputList[i].filetobase64.match("data:application/msword;base64,"))
          {
              extension="word";
              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/msword;base64,", ""):"";
          }
          else if(inputList[i].filetobase64.match("data:image/bmp;base64,"))
          {
              extension="bmp";
              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/bmp;base64,", ""):"";
          }
          else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
          {
              extension="docx";
              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
          }
          else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
          {
              extension="docx";
              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
          }
          else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
          {
              extension="docx";
              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
          }
          else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
          {
              extension="docx";
              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
          }
          else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
          {
              extension="docx";
              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
          }
          else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
          {
              extension="docm";
              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
          }
          else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,"))
          {
              extension="docx";
              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", ""):"";
          }
          else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
          {
              extension="dotx";
              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
          }
          else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
          {
              extension="dotx";
              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
          }
          else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,"))
          {
              extension="dotm";
              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", ""):"";
          }
          else{
             
          }


            documentList.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
      }
    }catch(exception)
    {
      // Swal.fire({  
      //     position: 'center',  
      //     icon: 'error',  
      //     title: "",  
      //     text:"Please attach document",
      //     showConfirmButton: true 
          
      //   }).then((result) => 
      //   {
              
      //         if(result.isConfirmed) 
      //         {
                
              
      //         }
      //   });
      //   return false;
      documentList.push();
    }
    if($("#inspectedBy").val()==="")
    {
      Swal.fire({  
          position: 'center',  
          icon: 'error',  
          title: "",  
          text:"Please enter inspectedBy",
          showConfirmButton: true 
          
        }).then((result) => 
        {
              
              if(result.isConfirmed) 
              {
                
              
              }
        });
        return false;
    }
        
        var requestObjectForInsp=
        {
        
          "type":"Carrier Equipment Inspection",
          "inspectedBy":  $("#inspectedBy").val(),
          "inspectedDate":$("#inspectedDate").val(),
          "verifiedBy": $("#verifiedBy").val(),
          "verifiedDate": $("#verifiedBy").val(),
          "workOrderNumber": $("#workOrderNumber").val()!=''?$("#workOrderNumber").val():'',
          "notes": $("#notes").val()!=''?$("#notes").val():'',
          "status": status,
          "taskOrderId":taskOrderId,
          "inspectionType":
          {
            "id": sessionStorage.getItem("selectedinspectionId"),
            "name":sessionStorage.getItem("selectedinspectionType")
          },
          "result":
          {
              "approvedCertify": "0",
              "inspectionFailed": "1"
          },
          "dispositionOfVehicle": {
            "vehicleReturnedServices": $("#vehicleReturnedServices").val(),
            "vehicleWithdrawnFromService":$("#vehicleWithdrawnFromService").val(),
            "vehicleSentToAnotherRepairFacility":$("#vehicleSentToAnotherRepairFacility").val()
          },
          "carrierEquipmentInspection":{
          "maxCompartmentCapacity": [
            $("#com1").val(),
            $("#com2").val(),
            $("#com3").val(),
            $("#com4").val(),
            $("#com5").val(),
            $("#com6").val()
          ],
          "probeOutage": [
            $("#outage1").val(),
            $("#outage2").val(),
            $("#outage3").val(),
            $("#outage4").val(),
            $("#outage5").val(),
            $("#outage6").val(),
          ],
          "carrierOutage": [
            $("#coutage1").val(),
            $("#coutage2").val(),
            $("#coutage3").val(),
            $("#coutage4").val(),
            $("#coutage5").val(),
            $("#coutage6").val(),
          ],
       
          "maximumPreset": [
            $("#preset1").val(),
            $("#preset2").val(),
            $("#preset3").val(),
            $("#preset4").val(),
            $("#preset5").val(),
            $("#preset6").val()
          ],
         
          "certifiedInspectionRequirements": {
            "maximumCompartmentCapacity": $("input[name='compartmentcapacity']:checked").val()==="on"?"1":"0",
            "systemInWorkingCondition": $("input[name='workingcondition']:checked").val()==="on"?"1":"0",
            "activatestheShutdownCircuitry": $("input[name='shutdown']:checked").val()==="on"?"1":"0",
            "properOperatingCondition": $("input[name='operatingcondition']:checked").val()==="on"?"1":"0",
            "readingToAllowLoading": $("input[name='allowloading']:checked").val()==="on"?"1":"0",
            "secureBondingWires": $("input[name='bondingwires']:checked").val()==="on"?"1":"0",
            "hose": $("input[name='hose']:checked").val()==="on"?"1":"0",
          },
          "carrierVerificationRequirements": {
            "specificationPlateInstalled": $("input[name='plateinstalled']:checked").val()==="on"?"1":"0",
            "productsThatareHauled": $("input[name='thatarehauled']:checked").val()==="on"?"1":"0",
            "dataCurrent": $("input[name='datacurrent']:checked").val()==="on"?"1":"0",
            "decalsCurrent": $("input[name='decalscurrent']:checked").val()==="on"?"1":"0",
            "includingGuidebook": $("input[name='onboard']:checked").val()==="on"?"1":"0",
            "appropriateUnitNumbers": $("input[name='unitnumbers']:checked").val()==="on"?"1":"0",
            "availableUponRequest": $("input[name='uponrequest']:checked").val()==="on"?"1":"0",
            "presetsRecordedAbove": $("input[name='recordedabove']:checked").val()==="on"?"1":"0"
          },
        },
          "vehicleDetails":
          {
              "vinNumber": $("#vin").html(),
              "lisencePlateNumber": $("#lisencePlateNumber").html(),
              "id":localStorage.getItem("vehicleId"),
              "unitNumber": $("#lisencePlateNumber").html()
          },
         
          "documentList": documentList
        
      }; 


      $("#loadingforsubmit").show();
      // const requestOptions = 
      // {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json'
      //             },
                  
      //       body: JSON.stringify(requestObject)
      // };
      var requestOptions=requestObject.RequestHeader(requestObjectForInsp);
      services.addInspection(requestOptions).then((res) => 
      {
        $("#loadingforsubmit").hide();
        // setLoading(false);
        Swal.fire({  
          position: 'center',  
          icon: 'success',  
          title: "",  
          text:res.message,
          showConfirmButton: true 
          
        }).then((result) => 
        {
              
              if(result.isConfirmed) 
              {
                navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));
              
              }
        });
      });
    }

  }

  } 
  return(
  <>

       {LoadingComponentForSubmit}
       <div class="row mb-2">
                     <div class="col-lg-12 col-md-12 m-auto ">
                        <div class="card">

                         
                            <div class="row px-3">
                                <div class="w-100">
                                    <h4 class="form-inspection-title text-center">Carrier Equipment Inspection</h4>
                                    <p id="error_message" class="center"></p>
                                </div>
                                <div class="col-md-12 px-0">
                                
                                  <WorkOrderNumberDiv/>
                                  </div>
                                
                                <div class="">
                                    <div class="inspection-wrap">

                                        <div class="table-no-border mb-4">
                                            <table class="table  ">
                                                <thead>
                                                  <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col">Example</th>
                                                    <th scope="col">1#</th>
                                                    <th scope="col">2#</th>
                                                    <th scope="col">3#</th>
                                                    <th scope="col">4#</th>
                                                    <th scope="col">5#</th>
                                                    <th scope="col">6#</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                      <td>1. Max Compartment Capacity</td>
                                                      <td>Ex: 3410</td>
                                                      <td class="text-center"><input type="input" class="form-control" id="com1" aria-describedby="emailHelp"/></td>
                                                      <td class="text-center"><input type="input" class="form-control" id="com2" aria-describedby="emailHelp"/></td>
                                                      <td class="text-center"><input type="input" class="form-control" id="com3" aria-describedby="emailHelp"/></td>
                                                      <td class="text-center"><input type="input" class="form-control" id="com4" aria-describedby="emailHelp"/></td>
                                                      <td class="text-center"><input type="input" class="form-control" id="com5" aria-describedby="emailHelp"/></td>
                                                      <td class="text-center"><input type="input" class="form-control" id="com6" aria-describedby="emailHelp"/></td>
                                                  </tr>

                                                  <tr>
                                                    <td>2. Probe Outage (60 gal min)   </td>
                                                    <td>Ex: 60</td>
                                                    <td class="text-center"><input type="input" class="form-control" id="outage1" aria-describedby="emailHelp"/></td>
                                                    <td class="text-center"><input type="input" class="form-control" id="outage2" aria-describedby="emailHelp"/></td>
                                                    <td class="text-center"><input type="input" class="form-control" id="outage3" aria-describedby="emailHelp"/></td>
                                                    <td class="text-center"><input type="input" class="form-control" id="outage4" aria-describedby="emailHelp"/></td>
                                                    <td class="text-center"><input type="input" class="form-control" id="outage5" aria-describedby="emailHelp"/></td>
                                                    <td class="text-center"><input type="input" class="form-control" id="outage6" aria-describedby="emailHelp"/></td>
                                                </tr>

                                                <tr>
                                                    <td>3. *Carrier Outage</td>
                                                    <td>Ex: 3080</td>
                                                    <td class="text-center"><input type="input" class="form-control" id="coutage1" aria-describedby="emailHelp"/></td>
                                                    <td class="text-center"><input type="input" class="form-control" id="coutage2" aria-describedby="emailHelp"/></td>
                                                    <td class="text-center"><input type="input" class="form-control" id="coutage3" aria-describedby="emailHelp"/></td>
                                                    <td class="text-center"><input type="input" class="form-control" id="coutage4" aria-describedby="emailHelp"/></td>
                                                    <td class="text-center"><input type="input" class="form-control" id="coutage5" aria-describedby="emailHelp"/></td>
                                                    <td class="text-center"><input type="input" class="form-control" id="coutage6" aria-describedby="emailHelp"/></td>
                                                </tr>

                                                <tr>
                                                    <td>4. Maximum Preset</td>
                                                    <td>Ex: 3000</td>
                                                    <td class="text-center"><input type="input" class="form-control" id="preset1" aria-describedby="emailHelp"/></td>
                                                    <td class="text-center"><input type="input" class="form-control" id="preset2" aria-describedby="emailHelp"/></td>
                                                    <td class="text-center"><input type="input" class="form-control" id="preset3" aria-describedby="emailHelp"/></td>
                                                    <td class="text-center"><input type="input" class="form-control" id="preset4" aria-describedby="emailHelp"/></td>
                                                    <td class="text-center"><input type="input" class="form-control" id="preset5" aria-describedby="emailHelp"/></td>
                                                    <td class="text-center"><input type="input" class="form-control" id="preset6" aria-describedby="emailHelp"/></td>
                                                </tr>

                                                <tr></tr>

                                                <tr>
                                                    <td>Subtract Lines 2&3 from Line 1</td>
                                                    <td></td>
                                                    <td colspan="6">Sections must be completed</td>  
                                                </tr>

                                                 
                                                </tbody>
                                              </table>
                                        </div>

                                        <div class="p-3 mb-4" style={{backgroundColor: "#ccc"}}>
                                            <p>'Carrier outage is the distance between the overfill probe and the product that prevents setting off the rack shutdown system (domeouts). This option is at the discretion of the carrier and varies on the tank strapping charts and the level outage selected.	
                                            </p>
                                        </div>

                                        <div class="table-2 mb-4">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Certified inspection Requirements</th>
                                                    <th scope="col"  className="text-center">Yes</th>
                                                    <th scope="col"  className="text-center">No</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                   <td>1. Has the overfill protection probe been set & tested to a minimum of 60 gross  below the maximum compartment capacity?</td>
                                                    <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="compartmentcapacity"/></td>
                                                    <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="compartmentcapacity"/></td>
                                                  </tr>

                                                  <tr>
                                                    <td>2. Is the overfill protection system in working condition?</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="workingcondition"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="workingcondition"/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>3. Has each compartments probe been tested with liquid to verify that it activates the shutdown circuitry?</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="shutdown"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="shutdown"/></td>
                                                   </tr>

                                                     <tr>
                                                    <td>4. Has the grounding system been checked and is in proper operating condition?</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="operatingcondition" /></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="operatingcondition"/></td>
                                                   </tr>

                                                   <tr>
                                                    <td><p>5. Has the grounding system been checked to ensure that has not been modified or rewired in any manner that	
                                                        would allow it to provide a false reading to allow loading?</p>	
                                                        </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="allowloading"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="allowloading"/></td>
                                                   </tr>    

                                                   <tr>
                                                    <td>6. Are all gauge rods and any other compartment protrusions properly grounded with secure bonding wires?</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="bondingwires"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="bondingwires"/></td>
                                                   </tr>  
                                                   <tr>
                                                    <td>7. Is a functional brake interlock system installed on the loading header and vapor recovery hose?</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="hose"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="hose"/></td>
                                                   </tr>  
                                                 </tbody>
                                              </table>
                                        </div>

                                        <div class="table-2 mb-2">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Carrier Verification Requirements</th>
                                                    <th scope="col"  className="text-center">Yes</th>
                                                    <th scope="col"  className="text-center">No</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                   <td>1. Is an MC306, DOT406 or other specification plate installed?</td>
                                                    <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="plateinstalled" /></td>
                                                    <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="plateinstalled"/></td>
                                                  </tr>

                                                  <tr>
                                                    <td>2. Is proper placarding installed for the product(s) that are hauled?</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="thatarehauled"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="thatarehauled"/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>3. Is the state DOT inspection or DOT 396/17 data current?</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="datacurrent"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="datacurrent"/></td>
                                                   </tr>

                                                     <tr>
                                                    <td>4. Are pressure, leakage and visual decals current?</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="decalscurrent"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="decalscurrent"/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>5. Is emergency response information (including guidebook) on board?
                                                        </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="onboard"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="onboard"/></td>
                                                   </tr>    

                                                   <tr>
                                                    <td>6. Is each tank/trailer marked with appropriate unit numbers?</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="unitnumbers"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="unitnumbers"/></td>
                                                   </tr>  

                                                   <tr>
                                                    <td>7. Are compartment capacity charts current and available upon request?</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="uponrequest"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="uponrequest"/></td>
                                                   </tr>  

                                                   <tr>
                                                    <td>8. Is each compartment loading headers matching with maximum presets recorded above?</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="recordedabove"/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="recordedabove"/></td>
                                                   </tr>  
                                                 </tbody>
                                              </table>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                                                    
                            <div class="divider2"></div>
                            <div class="row ">
                            <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName}/>
                            </div>
                            <AddInspectionName setEndDate={setEndDate} endDate={endDate} />
                           
                            <div class="divider2"></div>
                            <div class="row p-3 pr-4 d-flex justify-content-end" style={{marginBottom:"-20px"}}>
                            <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
                                <button type="button" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </button>
                                <button type="button" class="btn btn-primary mr-1" onClick={() => save("APPROVED PENDING")}>Save</button>
                            </div>
                           </div>
                        </div>
                  </div>
                 
              
  </>
)};

CarrierEquipment.propTypes = {};

CarrierEquipment.defaultProps = {};

export default CarrierEquipment;
