import React, { history, useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const ServiceCheckListTestRead = () => {


   const [fromDate, setFromDate] = useState();
   const { fkAccountId, inspectionId, vid } = useParams();
   const [inputList, setInputList] = useState([{}]);
   const [base64file, setBase64file] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const [fileName, setfileName] = useState("");
   const [inspectionDetails, setInspectionDetails] = useState("");
   const [documentList, setDocumentLists] = useState([]);
   const navigate = useNavigate();
   const [endDate, setEndDate] = useState(new Date());
   const [verifiedDATE, setverifiedDate] = useState(new Date());
   const [expiryDate, setexpiryDate] = useState(new Date());
   const [roles, setRoles] = useState("");
   const [statusInspection, setStatus] = useState("");
   useEffect(() => {
     setRoles(localStorage.getItem("Userroles"));
      // vehicle_details_api(id, setVehicleDetails, setCustomerId);
      getInpection(fkAccountId, inspectionId)
   }, []);

   function getInpection(fkAccountId, inspectionId) {
      var requestObjectForLeakage =
      {

         "id": inspectionId
      }

      var requestOptions = requestObject.RequestHeader(requestObjectForLeakage);

      services.getInspectionDetails(requestOptions).then((res) => {
         console.log("  " + JSON.stringify(res));
         setInspectionDetails(res.rows);
         // $('input[name="Pintle_repaired"][value="' + Pintle + '"]').trigger("click").prop('checked', true);
         setDocumentLists(res.rows.documentList?res.rows.documentList:"");
         $("#workOrderNumber").val(res.rows ? res.rows.workOrderNumber : "");
         // sessionStorage.setItem("verifiedBy",res.rows ? res.rows.verifiedBy : "");
         setEndDate(new Date(res.rows?res.rows.inspectedDate:""));
         setStatus(res.rows.status);
         if(res.rows)
         {
            if(res.rows.verifiedDate)
            {
               setverifiedDate(new Date(res.rows?res.rows.verifiedDate:''));
            }
     
            if(res.rows.expiryDate)
            {
               setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
            }
         }
      });

     // setEndDate(inspectionDetails.verifiedDate);
   }

   const redirect=(page)=>
   {

      navigate(page);

   }

   const save=(status)=>
   {

   // console.log("results=="+JSON.stringify(inputList));
   if($("#inspectedBy").val()==="")
   {
     Swal.fire({
         position: 'center',
         icon: 'error',
         title: "",
         text:"Please enter Inspected by",
         showConfirmButton: true

       }).then((result) =>
       {

             if(result.isConfirmed)
             {


             }
       });
       return false;
   }
   if( $('input:radio[name="returnedservice"]:checked').val()==="undefined"||$('input:radio[name="returnedservice"]:checked').val()===undefined)
   {
     Swal.fire({
         position: 'center',
         icon: 'error',
         title: "",
         text:"Please select disposition of tank",
         showConfirmButton: true

       }).then((result) =>
       {

             if(result.isConfirmed)
             {


             }
       });
       return false;
   }
   if( $('input:radio[name="approvedCertify"]:checked').val()===""|| $('input:radio[name="approvedCertify"]:checked').val()===undefined)
   {
     Swal.fire({
         position: 'center',
         icon: 'error',
         title: "",
         text:"Please select inspection result",
         showConfirmButton: true

       }).then((result) =>
       {

             if(result.isConfirmed)
             {


             }
       });
       return false;
   }
   if($("input[name='approvedCertify']:checked").val()==="approvedCertify")
   {

   }else{

    status="Rejected";
   }
         var requestObjectInspection=
         {
           "id": inspectionId,
           "expiryDate": $("#expiryDate").val(),
           "verifiedBy": $("#verifiedBy").val(),
           "verifiedDate": $("#verifiedByBate").val(),
           "status": status,

           "result": {
            "approvedCertify":$("input[name='approvedCertify']:checked").val()==="approvedCertify"?"1":"0",
            "inspectionFailed": $("input[name='approvedCertify']:checked").val()==="inspectionFailed"?"1":"0"
          },
          "dispositionOfVehicle": {
            "vehicleReturnedServices":$("input[name='returnedservice']:checked").val()==="returnedservice"?"1":"0",
            "vehicleWithdrawnFromService":$("input[name='returnedservice']:checked").val()==="withdrawnservice"?"1":"0",
            "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val()==="repairfacility"?"1":"0"
          }


       };

       $("#loadingforsubmit").show();
      //  setLoading(true);
       // const requestOptions =
       // {
       //   method: 'POST',
       //   headers: { 'Content-Type': 'application/json'
       //             },

       //       body: JSON.stringify(requestObject)
       // };
       var requestOptions=requestObject.RequestHeader(requestObjectInspection);
       services.certifyInspection(requestOptions).then((res) =>
       {
         $("#loadingforsubmit").hide();
         Swal.fire({
           position: 'center',
           icon: 'success',
           title: "",
           text:res.message,
           showConfirmButton: true

         }).then((result) =>
         {

               if(result.isConfirmed)
               {
                 navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));

               }
         });
       });

   }


   return (
      <>
      {LoadingComponentForSubmit}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block" }}>
            <div class="row mb-0">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <div class="d-flex justify-content-between align-items-center my-4">
                     <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                     </div>
                  </div>
               </div>
               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                  <HeaderBlock cids={fkAccountId} />
                  </div>
               </div>

               

               <div class="row mb-2">
               <div class="col-lg-11 col-md-12 m-auto ">
                     <div class="card">

                       <div class="row mb-2">
                              <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                                    <VehicleBasicInfo customerId={vid} />
                              </div>
                        </div>
                        <div class="divider"></div>

                        <div class="row p-3">
                        <div class="col-lg-12">
                              <h4 class="mb-4 form-inspection-title text-center">Service Check List</h4>
                              {/*<p id="leakage-check-type-message" class="error"></p>
                              <p id="unitDesignPressure-message" class="error"></p>
                              <p id="testPressure-message" class="error"></p>
   <p id="error_message" class="center error" ></p> */}
                           </div>
                           <div class="col-lg-12"><WorkOrderNumberDiv /></div><br />
                        

                        
                            
                            <div class="px-3">
                                <div class="inspection-wrap">
                                  <div class="row mb-3 mt-3">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Upper Coupler</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.upperCouplerDetails != null){
                                                         if (inspectionDetails.serviceCheckList.upperCouplerDetails.sideTorCracks == '1') {
                                                            return (<><input type="checkbox" name="sideTorCracks" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="sideTorCracks" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="sideTorCracks" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Inspect side for cracks</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.upperCouplerDetails != null){
                                                         if (inspectionDetails.serviceCheckList.upperCouplerDetails.pin == '1') {
                                                            return (<><input type="checkbox" name="pin" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="pin" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="pin" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect pin (wear or bent)</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.upperCouplerDetails != null){
                                                         if (inspectionDetails.serviceCheckList.upperCouplerDetails.torLooseHardware == '1') {
                                                            return (<><input type="checkbox" name="torLooseHardware" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="torLooseHardware" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="torLooseHardware" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect for loose hardware</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Emergency Shut-off</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.emergencyShutoffDetails != null){
                                                         if (inspectionDetails.serviceCheckList.emergencyShutoffDetails.emergencyShutOffSystem == '1') {
                                                            return (<><input type="checkbox" name="emergencyShutOffSystem" class="mr-2" defaultChecked={true} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="emergencyShutOffSystem" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="emergencyShutOffSystem" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Check operation of emergency shut-off system</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.emergencyShutoffDetails != null){
                                                         if (inspectionDetails.serviceCheckList.emergencyShutoffDetails.emergencyShutOffDecals == '1') {
                                                            return (<><input type="checkbox" name="emergencyShutOffDecals" class="mr-2" defaultChecked={true} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="emergencyShutOffDecals" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="emergencyShutOffDecals" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Check “Emergency Shut-off Decals” (replace if needed)</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Lights</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.lightsDetails != null){
                                                         if (inspectionDetails.serviceCheckList.lightsDetails.allTorNormalOperation == '1') {
                                                            return (<><input type="checkbox" name="allTorNormalOperation" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="allTorNormalOperation" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="allTorNormalOperation" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Check all for normal operation</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.lightsDetails != null){
                                                         if (inspectionDetails.serviceCheckList.lightsDetails.workLights == '1') {
                                                            return (<><input type="checkbox" name="workLights" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="workLights" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="workLights" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Work lights</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.lightsDetails != null){
                                                         if (inspectionDetails.serviceCheckList.lightsDetails.scully == '1') {
                                                            return (<><input type="checkbox" name="scully" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="scully" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="scully" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Scully</span>
                                            </div>
                                        </div>
                                    </div>
             
                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Reflective Tape</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.reflectiveTypeDetails != null){
                                                         if (inspectionDetails.serviceCheckList.reflectiveTypeDetails.mustHaveEachSide == '1') {
                                                            return (<><input type="checkbox" name="mustHaveEachSide" class="mr-2" defaultChecked={true} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="mustHaveEachSide" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="mustHaveEachSides" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Must have at least 50% on each side</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.reflectiveTypeDetails != null){
                                                         if (inspectionDetails.serviceCheckList.reflectiveTypeDetails.silverOnRearTop == '1') {
                                                            return (<><input type="checkbox" name="silverOnRearTop" class="mr-2" defaultChecked={true} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="silverOnRearTop" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="silverOnRearTop" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>4 pcs (11 ½ “) silver, on rear top right and left side</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.reflectiveTypeDetails != null){
                                                         if (inspectionDetails.serviceCheckList.reflectiveTypeDetails.rearBumber == '1') {
                                                            return (<><input type="checkbox" name="rearBumber" class="mr-2" defaultChecked={true} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="rearBumber" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="rearBumber" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Full length of rear bumper</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Placards</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.placardsDetails != null){
                                                         if (inspectionDetails.serviceCheckList.placardsDetails.tarBeingTraded == '1') {
                                                            return (<><input type="checkbox" name="tarBeingTraded" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="tarBeingTraded" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="tarBeingTraded" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect for being faded or torn</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Overfill protection</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.overTillProtectionDetails != null){
                                                         if (inspectionDetails.serviceCheckList.overTillProtectionDetails.forProperOperation == '1') {
                                                            return (<><input type="checkbox" name="forProperOperation" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="forProperOperation" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="forProperOperation" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect system for proper operation</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Inspect operation of all brake interlock valves</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.breakInterLockValvesDetails != null){
                                                         if (inspectionDetails.serviceCheckList.breakInterLockValvesDetails.lubriCationAsNeeded == '1') {
                                                            return (<><input type="checkbox" name="lubriCationAsNeeded" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="lubriCationAsNeeded" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="lubriCationAsNeeded" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Apply lubrication as needed</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Lubricate following items</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.lubricantFollowingItemDetails != null){
                                                         if (inspectionDetails.serviceCheckList.lubricantFollowingItemDetails.doorHinges == '1') {
                                                            return (<><input type="checkbox" name="doorHinges" class="mr-2" defaultChecked={true} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="doorHinges" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="doorHinges" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Door hinges and latches</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.lubricantFollowingItemDetails != null){
                                                         if (inspectionDetails.serviceCheckList.lubricantFollowingItemDetails.lockoutBar == '1') {
                                                            return (<><input type="checkbox" name="lockoutBar" class="mr-2" defaultChecked={true} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="lockoutBar" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="lockoutBar" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Lockout bar pivot points and slide pin</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Check brakes and brake components</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.breaksAndBreakComponentsDetails != null){
                                                         if (inspectionDetails.serviceCheckList.breaksAndBreakComponentsDetails.shoesForWear == '1') {
                                                            return (<><input type="checkbox" name="shoesForWear" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="shoesForWear" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="shoesForWear" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect shoes for wear and cracks</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.breaksAndBreakComponentsDetails != null){
                                                         if (inspectionDetails.serviceCheckList.breaksAndBreakComponentsDetails.scamsAndBushings == '1') {
                                                            return (<><input type="checkbox" name="scamsAndBushings" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="scamsAndBushings" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="scamsAndBushings" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect S-Cams and bushings</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.breaksAndBreakComponentsDetails != null){
                                                         if (inspectionDetails.serviceCheckList.breaksAndBreakComponentsDetails.wheelSeals == '1') {
                                                            return (<><input type="checkbox" name="wheelSeals" class="mr-2" defaultChecked={true} disabled /></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="wheelSeals" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="wheelSeals" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect wheel seals</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.breaksAndBreakComponentsDetails != null){
                                                         if (inspectionDetails.serviceCheckList.breaksAndBreakComponentsDetails.drums == '1') {
                                                            return (<><input type="checkbox" name="drums" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="drums" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="drums" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect drums</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Inspect Air brake chambers</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.airBreakChambersDetails != null){
                                                         if (inspectionDetails.serviceCheckList.airBreakChambersDetails.cracks == '1') {
                                                            return (<><input type="checkbox" name="cracks" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="cracks" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="cracks" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect for cracks in mounting brackets</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.airBreakChambersDetails != null){
                                                         if (inspectionDetails.serviceCheckList.airBreakChambersDetails.springs == '1') {
                                                            return (<><input type="checkbox" name="springs" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="springs" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="springs" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect springs (any broken?)</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Brake lining remaining</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mb-2">
                                            <div class="d-flex align-items-center">
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">DS Front </lable>
                                                                
                                                            <div>
                                                            {(() => {
                                                               if (inspectionDetails.serviceCheckList) {
                                                                  if(inspectionDetails.serviceCheckList.breakLiningRemainingDetails != null){
                                                                     return (<><input type="input" class="form-control" id="blrDsFront" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.serviceCheckList.breakLiningRemainingDetails.blrDsFront} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="blrDsFront" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                            </div>
                                                        </div>
                                                        <span style={{margin:"10px 10px"}}>%</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">DS Rear </lable>
                                                                
                                                            <div>
                                                            {(() => {
                                                               if (inspectionDetails.serviceCheckList) {
                                                                  if(inspectionDetails.serviceCheckList.breakLiningRemainingDetails != null){
                                                                     return (<><input type="input" class="form-control" id="blrDsRear" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.serviceCheckList.breakLiningRemainingDetails.blrDsRear} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="blrDsRear" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                            </div>
                                                        </div>
                                                        <span style={{margin:"10px 10px"}}>%</span>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">PS Front </lable>
                                                                
                                                            <div>
                                                            {(() => {
                                                               if (inspectionDetails.serviceCheckList) {
                                                                  if(inspectionDetails.serviceCheckList.breakLiningRemainingDetails != null){
                                                                     return (<><input type="input" class="form-control" id="blrPsFront" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.serviceCheckList.breakLiningRemainingDetails.blrPsFront} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="blrPsFront" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                            </div>
                                                        </div>
                                                        <span style={{margin:"10px 10px"}}>%</span>
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">PS Rear </lable>
                                                                
                                                            <div>
                                                            {(() => {
                                                               if (inspectionDetails.serviceCheckList) {
                                                                  if(inspectionDetails.serviceCheckList.breakLiningRemainingDetails != null){
                                                                     return (<><input type="input" class="form-control" id="blrPsRear" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.serviceCheckList.breakLiningRemainingDetails.blrPsRear} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="blrPsRear" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                            </div>
                                                        </div>
                                                        <span style={{margin:"10px 10px"}}>%</span>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Brake measurement prior to adjustment</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mb-2">
                                            <div class="d-flex align-items-center">
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">DS Front </lable>
                                                            <div>
                                                            {(() => {
                                                               if (inspectionDetails.serviceCheckList) {
                                                                  if(inspectionDetails.serviceCheckList.breakMeasurementPriorAdjustmentDetails != null){
                                                                     return (<><input type="input" class="form-control" id="bmpaDsFront" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.serviceCheckList.breakMeasurementPriorAdjustmentDetails.bmpaDsFront} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="bmpaDsFront" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">DS Rear </lable>
                                                                
                                                            <div>
                                                            {(() => {
                                                               if (inspectionDetails.serviceCheckList) {
                                                                  if(inspectionDetails.serviceCheckList.breakMeasurementPriorAdjustmentDetails != null){
                                                                     return (<><input type="input" class="form-control" id="bmpaDsRear" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.serviceCheckList.breakMeasurementPriorAdjustmentDetails.bmpaDsRear} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="bmpaDsRear" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">PS Front </lable>
                                                                
                                                            <div>
                                                            {(() => {
                                                               if (inspectionDetails.serviceCheckList) {
                                                                  if(inspectionDetails.serviceCheckList.breakMeasurementPriorAdjustmentDetails != null){
                                                                     return (<><input type="input" class="form-control" id="bmpaPsFront" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.serviceCheckList.breakMeasurementPriorAdjustmentDetails.bmpaPsFront} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="bmpaPsFront" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">PS Rear </lable>
                                                                
                                                            <div>
                                                            {(() => {
                                                               if (inspectionDetails.serviceCheckList) {
                                                                  if(inspectionDetails.serviceCheckList.breakMeasurementPriorAdjustmentDetails != null){
                                                                     return (<><input type="input" class="form-control" id="bmpaPsRear" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.serviceCheckList.breakMeasurementPriorAdjustmentDetails.bmpaPsRear} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="bmpaPsRear" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg    1111111111111111mb-3">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Brake measurement after adjustment</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mb-2">
                                            <div class="d-flex align-items-center">
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                            <lable class="mr-2">DS Front </lable>
                                                            <div>
                                                            {(() => {
                                                               if (inspectionDetails.serviceCheckList) {
                                                                  if(inspectionDetails.serviceCheckList.breakMeasurementAfterAdjustmentDetails != null){
                                                                     return (<><input type="input" class="form-control" id="bmaaDsFront" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.serviceCheckList.breakMeasurementAfterAdjustmentDetails.bmaaDsFront} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="bmaaDsFront" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">DS Rear </lable>
                                                            <div>
                                                            {(() => {
                                                               if (inspectionDetails.serviceCheckList) {
                                                                  if(inspectionDetails.serviceCheckList.breakMeasurementAfterAdjustmentDetails != null){
                                                                     return (<><input type="input" class="form-control" id="bmaaDsRear" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.serviceCheckList.breakMeasurementAfterAdjustmentDetails.bmaaDsRear} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="bmaaDsRear" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">PS Front </lable>
                                                            <div>
                                                            {(() => {
                                                               if (inspectionDetails.serviceCheckList) {
                                                                  if(inspectionDetails.serviceCheckList.breakMeasurementAfterAdjustmentDetails != null){
                                                                     return (<><input type="input" class="form-control" id="bmaaPsFront" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.serviceCheckList.breakMeasurementAfterAdjustmentDetails.bmaaPsFront} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="bmaaPsFront" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12 mb-3">
                                                    <div class="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                        <lable class="mr-2">PS Rear </lable>
                                                            <div>
                                                            {(() => {
                                                               if (inspectionDetails.serviceCheckList) {
                                                                  if(inspectionDetails.serviceCheckList.breakMeasurementAfterAdjustmentDetails != null){
                                                                     return (<><input type="input" class="form-control" id="bmaaPsRear" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.serviceCheckList.breakMeasurementAfterAdjustmentDetails.bmaaPsRear} disabled/></>)
                                                                  } else {
                                                                     return (<><input type="input" class="form-control" id="bmaaPsRear" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                                  }
                                                               }
                                                            })()}
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Grease</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.greaseDetails != null){
                                                         if (inspectionDetails.serviceCheckList.greaseDetails.allGreaseFittings == '1') {
                                                            return (<><input type="checkbox" name="allGreaseFittings" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="allGreaseFittings" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="allGreaseFittings" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Apply grease to all grease fittings</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.greaseDetails != null){
                                                         if (inspectionDetails.serviceCheckList.greaseDetails.cableConduit == '1') {
                                                            return (<><input type="checkbox" name="cableConduit" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="cableConduit" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="cableConduit" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Cable conduit</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.greaseDetails != null){
                                                         if (inspectionDetails.serviceCheckList.greaseDetails.loadingHeads == '1') {
                                                            return (<><input type="checkbox" name="loadingHeads" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="loadingHeads" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="loadingHeads" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Loading heads</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Inspect suspension for non-air ride</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.suspensionTorNonAirRideDetails != null){
                                                         if (inspectionDetails.serviceCheckList.suspensionTorNonAirRideDetails.inspectAllSprings == '1') {
                                                            return (<><input type="checkbox" name="inspectAllSprings" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectAllSprings" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectAllSprings" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect all springs for cracks and movement</span>
                                            </div>
                                        </div>                                        
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Torque Arms</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.torqueArmsDetails != null){
                                                         if (inspectionDetails.serviceCheckList.torqueArmsDetails.bushings == '1') {
                                                            return (<><input type="checkbox" name="bushings" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="bushings" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="bushings" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect bushings</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.torqueArmsDetails != null){
                                                         if (inspectionDetails.serviceCheckList.torqueArmsDetails.torWear == '1') {
                                                            return (<><input type="checkbox" name="torWear" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="torWear" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="torWear" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect for wear on bolt holes</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.torqueArmsDetails != null){
                                                         if (inspectionDetails.serviceCheckList.torqueArmsDetails.torOverallWear == '1') {
                                                            return (<><input type="checkbox" name="torOverallWear" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="torOverallWear" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="torOverallWear" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect for overall wear</span>
                                            </div>
                                        </div>                                        
                                    </div>


                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Equalizer</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.equalizerDetails != null){
                                                         if (inspectionDetails.serviceCheckList.equalizerDetails.inspectBushings == '1') {
                                                            return (<><input type="checkbox" name="inspectBushings" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectBushings" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectBushings" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect bushings</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.equalizerDetails != null){
                                                         if (inspectionDetails.serviceCheckList.equalizerDetails.inspectTorOverallWear == '1') {
                                                            return (<><input type="checkbox" name="inspectTorOverallWear" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="inspectTorOverallWear" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="inspectTorOverallWear" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Inspect for overall wear</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Inspect suspension for air ride</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.suspensionTorAirRideDetails != null){
                                                         if (inspectionDetails.serviceCheckList.suspensionTorAirRideDetails.airBags == '1') {
                                                            return (<><input type="checkbox" name="airBags" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="airBags" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="airBags" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Air bags and mounts</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.suspensionTorAirRideDetails != null){
                                                         if (inspectionDetails.serviceCheckList.suspensionTorAirRideDetails.shocks == '1') {
                                                            return (<><input type="checkbox" name="shocks" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="shocks" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="shocks" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Shocks</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.suspensionTorAirRideDetails != null){
                                                         if (inspectionDetails.serviceCheckList.suspensionTorAirRideDetails.swingArmBushings == '1') {
                                                            return (<><input type="checkbox" name="swingArmBushings" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="swingArmBushings" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="swingArmBushings" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Swing arm bushings</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg mb-2">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Inspect safety valves</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-start">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.safetyValvesDetails != null){
                                                         if (inspectionDetails.serviceCheckList.safetyValvesDetails.fusiblePlug == '1') {
                                                            return (<><input type="checkbox" name="fusiblePlug" class="mr-2" defaultChecked={true} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="fusiblePlug" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="fusiblePlug" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Check for fusible plug at valves</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-start">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.safetyValvesDetails != null){
                                                         if (inspectionDetails.serviceCheckList.safetyValvesDetails.adjustCableAsNeeded == '1') {
                                                            return (<><input type="checkbox" name="adjustCableAsNeeded" class="mr-2" defaultChecked={true}  style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="adjustCableAsNeeded" class="mr-2" defaultChecked={false}  style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="adjustCableAsNeeded" class="mr-2" defaultChecked={false}  style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Check operation and adjust cable as needed</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-start">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.safetyValvesDetails != null){
                                                         if (inspectionDetails.serviceCheckList.safetyValvesDetails.airOperatedValves == '1') {
                                                            return (<><input type="checkbox" name="airOperatedValves" class="mr-2" defaultChecked={true} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="airOperatedValves" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="airOperatedValves" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Check operation of air operated valves</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Inspect Hub Caps</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.hubCapsDetails != null){
                                                         if (inspectionDetails.serviceCheckList.hubCapsDetails.oilLevl == '1') {
                                                            return (<><input type="checkbox" name="oilLevl" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="oilLevl" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="oilLevl" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Check oil level (add if needed)</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.hubCapsDetails != null){
                                                         if (inspectionDetails.serviceCheckList.hubCapsDetails.metalShavings == '1') {
                                                            return (<><input type="checkbox" name="metalShavings" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="metalShavings" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="metalShavings" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Look for metal shavings</span>
                                            </div>
                                        </div>
                                    </div> 

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Inspect slack adjusters</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.slackAdjestersDetails != null){
                                                         if (inspectionDetails.serviceCheckList.slackAdjestersDetails.pawForRockwell == '1') {
                                                            return (<><input type="checkbox" name="pawForRockwell" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="pawForRockwell" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="pawForRockwell" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Check paw for Rockwell adjusters</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.slackAdjestersDetails != null){
                                                         if (inspectionDetails.serviceCheckList.slackAdjestersDetails.adjustmentPointer == '1') {
                                                            return (<><input type="checkbox" name="adjustmentPointer" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="adjustmentPointer" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="adjustmentPointer" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Check adjustment pointer</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.slackAdjestersDetails != null){
                                                         if (inspectionDetails.serviceCheckList.slackAdjestersDetails.nutForClevis == '1') {
                                                            return (<><input type="checkbox" name="nutForClevis" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="nutForClevis" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="nutForClevis" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Check nut for clevis assembly</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Inspect tank data plates</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.tankDataPlatesDetails != null){
                                                         if (inspectionDetails.serviceCheckList.tankDataPlatesDetails.verifyVin == '1') {
                                                            return (<><input type="checkbox" name="verifyVin" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="verifyVin" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="verifyVin" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Verify the VIN # is accurate</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.tankDataPlatesDetails != null){
                                                         if (inspectionDetails.serviceCheckList.tankDataPlatesDetails.dataPlatesLegible == '1') {
                                                            return (<><input type="checkbox" name="dataPlatesLegible" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="dataPlatesLegible" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="dataPlatesLegible" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                                <span>Are the data plates legible</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>External Inspection (document all defects and action taken)</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-8 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                if (inspectionDetails.serviceCheckList) {
                                                    if(inspectionDetails.serviceCheckList.externalInspectionRemarks != null){
                                                        return (<><textarea class="form-control my-3" name="externalInspectionRemarks" id="externalInspectionRemarks" rows="3" maxLength="300" style={{minHeight:"180px"}} disabled>{inspectionDetails.serviceCheckList.externalInspectionRemarks}</textarea></>)
                                                    } else {
                                                        return (<><textarea class="form-control my-3" name="externalInspectionRemarks" id="externalInspectionRemarks" rows="3" maxLength="300" style={{minHeight:"180px"}} disabled></textarea></>)
                                                    }
                                                }
                                            })()}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        
                        </div>
                        <div class="divider2"></div>
                        <div class="form-footer-comon-wrap">
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                    {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                    <div class="row">
                                        {
                                            documentList ? documentList.map((data, index) => (
                                                <div class="col-md-12">
                                                    <div class="d-flex align-items-center">
                                                        <img src="images/icons/pdf-icon.svg" alt="" />
                                                        <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100">
                                                            {/* <b>{data.title}.{data.extension}</b> */}
                                                                <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                    <img src="" class="mr-2" />
                                                                    <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                        {(() => {
                                                                            if (data.extension == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                        {data.name ? data.name : "--"} </p></span>
                                                                    {/* <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" />  */}
                                                                    </div>
                                                        </a>
                                                        {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                                    </div>
                                                </div>


                                            )) : ""

                                        }
                                    </div>
                                </div><br />

                                </div>
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                      <h6 class="mb-4">Additional notes: </h6>
                                      {inspectionDetails?inspectionDetails.notes:''}
                                   </div>
                                   </div>
                                   <div class="divider2"></div>
                                   <div class="row  py-3">
                                <div class="row mb-2 mt-2">
                                    <div class="col-lg-12">
                                        <div class="d-flex align-items-center ml-4">
                                        {(() => {
                                                   if (inspectionDetails.serviceCheckList) {
                                                      if(inspectionDetails.serviceCheckList.serviceDecal != null){
                                                         if (inspectionDetails.serviceCheckList.serviceDecal == '1') {
                                                            return (<><input type="checkbox" name="serviceDecal" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="serviceDecal" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="serviceDecal" class="mr-2" defaultChecked={false} disabled /></>)
                                                      }
                                                   } 
                                                })()}
                                            <span>Service decal has been changed. (Service decal not to be changed until work is complete)</span>
                                        </div>
                                    </div>                                  
                                </div>
                            </div>

                                <div class="divider2"></div>
                                <div class="row performby p-4">
                                   <div class="col-lg-12">
                                      <div class="row">
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Test Performed By:</label>
                                               <b>{inspectionDetails.inspectedBy}</b>
                                            </div>
                                         </div>
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Date</label>
                                               <b>{inspectionDetails.inspectedDate}</b>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>

                                <div class="divider"></div>
                                {(() => {
                                   if(!roles.match("Technician")){
        if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED') {
          return (
            <>
            <div class="result-didspos">
                                   <div class="p-4">
                                      <span>Result</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Inspection Failed</b>
                                      </div>
                                   </div>
                                   <div class="p-4">
                                      <span>Disposition of Tank</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:""}
                                         <b class="mb-0 ml-2">Tank Returned to Service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                      </div>
                                   </div>
                                </div>
                                <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection}  customerId={fkAccountId}/>
                                {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} status={"readOnly"} /> */}
            </>
          )
        } else {
          return (
            <><div class="result-didspos ">
            <div class="p-4">
               <span>Result</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Approve &amp; Certify</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Inspection Failed</b>
               </div>
            </div>
            <div class="p-4">
               <span>Disposition of Tank</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"/>:""}
                  <b class="mb-0 ml-2">Tank returned to service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank withdrawn from service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank sent to another repair facility</b>
               </div>
            </div>
         </div>
         <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate}   customerId={fkAccountId}/>
         {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} /> */}
         {/* <div class="divider"></div>
         <div class="row verifyby p-4">
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Verified By</label>
                  <input type="text" class="form-control custome-input" id="verifiedBy" value={inspectionDetails.verifiedBy} aria-describedby="emailHelp" placeholder="Enter Name"/>
               </div>
            </div>
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Date</label>
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="verifiedByBate" />

               </div>
            </div>
         </div> */}

         </>
          )
        }
      }
      })()}
                             </div>

                        <div class="divider"></div>
                        {(() =>
                            {
                                 if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED')
                                 {
                                 }
                                 else
                                 {
                                   return(<> <div class="row p-3 pr-4 d-flex justify-content-end">
                                   <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-secondary mr-1 ">Cancel</a>
                                       {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a>
                                        */}
                                        {roles.match("Technician")?"":<a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a>}
                                   {roles.match("Technician")?<a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-primary mr-1">Back</a>:""}
                                   </div></>)
                                 }
                           })()}
                     </div>
                  </div>
               </div>

            </div>



         </div>
      </>
   )
};

ServiceCheckListTestRead.propTypes = {};

ServiceCheckListTestRead.defaultProps = {};

export default ServiceCheckListTestRead;
