import services from "../../../../services/services";
import { requestObject } from "../../../../utility/requestObject";

const DueSearch = (customerId, pageNumber, ITEMS_PER_PAGE_DUE, status, vehicleTypeId, pageSize, setTotalItemsDue, settotalPagesDue, setCurrentPageDue, setDue, setdisplayPageDue, sortBy, sortDir, displayItems, dstatus,isCancelled) => {
  /////////////get vechile list//////////////////////////
  var vechileReq = "";
  if (sortBy === "lisencePlateNumber") {

    sortBy = "lisencePlateNumber";
  }
  //  else if(sortBy==="documentCategoryName")
  else if (sortBy === "documentSubCategoryName") {
    // docSubCategory
    // sortBy="documentCategoryName";
    sortBy = "docSubCategory";
  } else if (sortBy === "VIN Number") {

    sortBy = "vinNumber";
  } else if (sortBy === "Unit Number") {

    sortBy = "unitNumber";
  }
  if (customerId == "") {
    vechileReq =
    {

      "pageNumber": pageNumber.toString(),
      "pageSize": ITEMS_PER_PAGE_DUE.toString(),
      "vinNumber": status,
      // "vehicleTypeId": vehicleTypeId,
      // "overDue": dstatus,
      // "sortBy": sortBy,
      // "sortDir": sortDir,
      "isActive": dstatus,

      // "pageNumber":pageNumber,
      // "pageSize":pageSize,
      // "lisencePlateNumber": "",
      // "vehicleTypeId":vehicleTypeId,
      //  "overDue":title,
      // "sortBy": sortBy,
      // "sortDir": sortDir
      "isCancelled":isCancelled

    };
  } else {
    vechileReq =
    {

      "pageNumber": pageNumber.toString(),
      "pageSize": ITEMS_PER_PAGE_DUE.toString(),
      "vinNumber": status,
      // "vehicleTypeId": vehicleTypeId,
      // "overDue": dstatus,
      // "sortBy": sortBy,
      // "sortDir": sortDir,
      "isActive": dstatus,
      "isCancelled":isCancelled

    }
  }




  // const requestOptions = 
  // {
  //       method: 'POST',
  //       headers:{'Content-Type': 'application/json'},
  //       body: JSON.stringify(vechileReq)
  // };
  var requestOptions = requestObject.RequestHeader(vechileReq);
  services.remindersAll(requestOptions).then((res) => {

    if (res.success) {

      setTotalItemsDue(res.customValues.totalNumberOfRecords);

      settotalPagesDue(Math.ceil(res.customValues.totalNumberOfPages / ITEMS_PER_PAGE_DUE));
      setCurrentPageDue(res.customValues.currentPageNumber);
      let temarray = [];
      let parentJson = [];
      if (res.rows.length > 0) {

        // setDue(res.rows);



        for (var i = 0; i < res.rows.length; i++) {

          // setinspectionList(res.rows[i].inspectionList);
          // setdocslist(res.rows[i].documentList);
          //    for(var j=0;j<res.rows[i].documentList.length;j++)
          //    {

          //       for(var k=0;k<res.rows[i].inspectionList.length;k++)
          //         {
          //             if(res.rows[i].documentList[j].documentSubCategoryName===res.rows[i].inspectionList[k].documentSubCategoryName)
          //             {
          //                 console.log("if");

          //             }else{
          //                 temarray.push({"documentSubCategoryName":res.rows[i].documentList[j].documentSubCategoryName,"expiryDate":res.rows[i].documentList[j].expiryDate,"id":res.rows[i].documentList[j].id})
          //             }
          //         }

          //    }

          // const finalData1 = res.rows[i].documentList.concat(res.rows[i].inspectionList).reduce(function (index, obj) {
          //     index[obj.id] = Object.assign({}, obj, index[obj.id]);
          //     return index;
          // }, []).filter(function (res, obj) {
          //     return obj;
          // });
          let hashData = new Map();
     
          if (res.rows[i].documentList === null || res.rows[i].documentList.length == 0) 
          {
            res.rows[i].inspectionList.concat(res.rows[i].inspectionList).forEach(function (obj) 
            {
                hashData.set(obj.documentSubCategoryName, Object.assign(hashData.get(obj.documentSubCategoryName) || {}, obj))
            });
            const finalinspectionData = Array.from(hashData.values());

            parentJson.push({ "assignedDate": res.rows[i].assignedDate, "id": res.rows[i].id, "unitNumber": res.rows[i].unitNumber, "vehicleId": res.rows[i].vehicleId, "vendorId": res.rows[i].vendorId, "vendorName": res.rows[i].vendorName, "vinNumber": res.rows[i].vinNumber, "workOrderNumber": res.rows[i].workOrderNumber, "mergedocumentList": finalinspectionData, "documentList": res.rows[i].documentList, "inspectionList": res.rows[i].inspectionList })

          } else if (res.rows[i].inspectionList === null || res.rows[i].inspectionList.length == 0) 
          {
            res.rows[i].documentList.concat(res.rows[i].documentList).forEach(function (obj) {
              hashData.set(obj.documentSubCategoryName, Object.assign(hashData.get(obj.documentSubCategoryName) || {}, obj))
             });
            const finalDocumentData = Array.from(hashData.values());
            parentJson.push({ "assignedDate": res.rows[i].assignedDate, "id": res.rows[i].id, "unitNumber": res.rows[i].unitNumber, "vehicleId": res.rows[i].vehicleId, "vendorId": res.rows[i].vendorId, "vendorName": res.rows[i].vendorName, "vinNumber": res.rows[i].vinNumber, "workOrderNumber": res.rows[i].workOrderNumber, "mergedocumentList": finalDocumentData, "documentList": res.rows[i].documentList, "inspectionList": res.rows[i].inspectionList })
          } 
          else 
          {


            res.rows[i].documentList.concat(res.rows[i].inspectionList).forEach(function (obj) {
              hashData.set(obj.documentSubCategoryName, Object.assign(hashData.get(obj.documentSubCategoryName) || {}, obj))
            });
            const finalData2 = Array.from(hashData.values());
            // setdocuments(finalData2);
            temarray.push(finalData2);

            parentJson.push({ "assignedDate": res.rows[i].assignedDate, "id": res.rows[i].id, "unitNumber": res.rows[i].unitNumber, "vehicleId": res.rows[i].vehicleId, "vendorId": res.rows[i].vendorId, "vendorName": res.rows[i].vendorName, "vinNumber": res.rows[i].vinNumber, "workOrderNumber": res.rows[i].workOrderNumber, "mergedocumentList": finalData2, "documentList": res.rows[i].documentList, "inspectionList": res.rows[i].inspectionList })
          }




        }

        //  var arr = inspectionLists.concat(docslist);
        // console.log("results=="+JSON.stringify(arr))
        // var sorted_arr = arr.sort();
        // var results = [];
        // for (var i = 0; i < arr.length - 1; i++) {
        //     if (sorted_arr[i + 1] == sorted_arr[i]) {
        //         results.push({"documentSubCategoryName":arr[i].documentSubCategoryName,"expiryDate":arr[i].expiryDate,"id":arr[i].id});
        //     }
        // }
        // console.log("results==" + JSON.stringify(parentJson));
        //const myTimeout = setTimeout( setdocuments(temarray), 5000);
        //    setdocuments(temarray);
        //   setdocuments(temarray);
        setDue(parentJson);
      } else {


        setDue("");
      }

      setTotalItemsDue(res.customValues.totalNumberOfRecords);

      var displaypages = [];
      if (displayItems == "displayItems") {

        for (var i = 1; i <= parseInt(Math.ceil(res.customValues.totalNumberOfRecords / ITEMS_PER_PAGE_DUE)); i++) {

          displaypages.push(i * ITEMS_PER_PAGE_DUE);
        }


        if (displaypages.length > 0) {
          setdisplayPageDue(displaypages);

        } else {
          setdisplayPageDue([0])
        }

      }
    } else {

      // setDue("");
      setdisplayPageDue("");
      setdisplayPageDue([0])
      //alert(res.message);

    }

  });





}

export default DueSearch;