import React, { history, useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import AddInspectionName from "../../../../utility/addInspectionName";
import removePdf from "../../../../utility/removePdf";
import { ExitToApp } from "@material-ui/icons";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const LeakageTestEdit = () => {

   const { taskOrderId,fkAccountId, inspectionId, vid } = useParams();
   const [inputList, setInputList] = useState([{}]);
   const [base64file, setBase64file] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const [fileName, setfileName] = useState("");
   const [inspectionDetails, setInspectionDetails] = useState("");
   const [documentList, setDocumentLists] = useState([]);
   const [testPressureSet, setTestPressure] = useState([]);
   const [inspectionTypeId, setinspectionTypeId] = useState("");
   const navigate = useNavigate();
   const [endDate, setEndDate] = useState(new Date());
   var documentLists = [];
   const [roles, setRoles] = useState("");
   const [statusInspection, setStatus] = useState("");
   const [verifiedDATE, setverifiedDate] = useState(new Date());
   const [expiryDate, setexpiryDate] = useState(new Date());
   useEffect(() => {
      setRoles(localStorage.getItem("Userroles"));
      // vehicle_details_api(id, setVehicleDetails, setCustomerId);
      getInpection(fkAccountId, inspectionId)
   }, []);

   $(document).ready(function () {

      $("#unitDesignPressure,#testPressure").keypress(function (e) {
         // ,#testPressure
         // $("#errmsg").html("Digits Only").show().fadeOut("slow");
         // if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {

         //   return false;
         // }

         if ((e.which != 46 || $(this).val().indexOf('.') != -1) &&
            ((e.which < 48 || e.which > 57) &&
               (e.which != 0 && e.which != 8))) {
            return false;
         }

         var text = $(this).val();

         if ((text.indexOf('.') != -1) &&
            (text.substring(text.indexOf('.')).length > 3) &&
            (e.which != 0 && e.which != 8) &&
            ($(this)[0].selectionStart >= text.length - 3)) {
            return false;
         }
      });
   });

   function getInpection(fkAccountId, inspectionId) {
      var requestObjectForLeakage =
      {

         "id": inspectionId
      }

      var requestOptions = requestObject.RequestHeader(requestObjectForLeakage);

      services.getInspectionDetails(requestOptions).then((res) => {

         // if(res.rows.leakageInspection.testPressureUsed.testPressure=="METHOD 27"){
         //    $("#testPressure").prop('disabled', true);
         // }else{
         //    $("#testPressure").prop('disabled', false);
         // }

         setInspectionDetails(res.rows);
         $("#testPressure").val(res.rows.leakageInspection ? res.rows.leakageInspection.testPressureUsed.testPressure : '');
         setTestPressure(res.rows.leakageInspection ? res.rows.leakageInspection.testPressureUsed.testPressure : '');
        
         // if (res.rows.leakageInspection.checkType.modifiedmethod27 == '1') {
         //    $("#testPressure").prop('readonly', true);
         //  } else {
         //    $("#testPressure").prop('readonly', false);
         //  }
         setDocumentLists(res.rows.documentList);
         setinspectionTypeId(res.rows.inspectionType.id);
         $("#workOrderNumber").val(res.rows ? res.rows.workOrderNumber : "");
         $("#notes").val(res.rows ? res.rows.notes : "");
         $("#inspectedBy").val(res.rows ? res.rows.inspectedBy : "");
         setEndDate(new Date(res.rows ? res.rows.inspectedDate : ""));
   
      
         documentLists.push(res.rows.documentList);
         sessionStorage.setItem("documentList", JSON.stringify(res.rows.documentList));
         setStatus(res.rows.status);
         // alert(res.rows.status)
         if (res.rows) 
         {
            if (res.rows.verifiedDate) 
            {
               setverifiedDate(new Date(res.rows ? res.rows.verifiedDate : ''));

            }
            if(res.rows.expiryDate)
            {
               setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
            }
         }
         // $('input[name="ApproveCertify"][value="0"]').trigger("click");
         // $('input[name="inspectionFailed"][value="1"]').trigger("click");
         // $('input[name="inspectionFailed"][value="' + res.rows.result.inspectionFailed + '"]').trigger("click");
         // $("#result1").attr('checked', true).trigger('click');
      });

      // setEndDate(inspectionDetails.verifiedDate);
   }


   const redirect = (page) => {

      navigate(page);

   }

   const handleSubmit = () => {

      let form_is_valid = true;

      var AnnualVehicleInspection = $("input[name='AnnualVehicleInspection']:checked").val();

      if (AnnualVehicleInspection == undefined) {
         form_is_valid = false;
         $("#leakage-check-type-message").html('*Please select leakage check type');

         // $('html, body').animate({
         //    scrollTop: $(".form-inspection-title").offset().top
         // }, 500);
         $('html,body').animate({ scrollTop: "600px" }, 1000);
         return false

      } else {

         $("#leakage-check-type-message").html('');
         form_is_valid = true;
      }

      var closeAllValves = $("input[name='closeAllValves']:checked").val();
      var internalValves = $("input[name='internalValves']:checked").val();
      var externalValves = $("input[name='externalValves']:checked").val();
      // var closeAllTanksAndOpens=$("input[name='closeAllTanksAndOpens']:checked").val();
      var duringTheTestPeriod = $("input[name='duringTheTestPeriod']:checked").val();
      var inspectioninternalValves = $("input[name='inspectioninternalValves']:checked").val();
      var inspectionexternalValves = $("input[name='inspectionexternalValves']:checked").val();
      var remotevalveoperator = $("input[name='remotevalveoperator']:checked").val();
      var inspectOverallPiping = $("input[name='inspectOverallPiping']:checked").val();
      var withAllValvesClosed = $("input[name='withAllValvesClosed']:checked").val();
      var inspectiontankMarking = $("input[name='inspectiontankMarking']:checked").val();

      var unitDesignPressure = $('#unitDesignPressure').val();
      var allowMax = /^[0-9]*$/;
      if (!unitDesignPressure) {
         form_is_valid = false;
         $("#unitDesignPressure-message").html('*Unit design pressure is required');

         // $('html, body').animate({
         //    scrollTop: $(".form-inspection-title").offset().top
         // }, 500);
         $('html,body').animate({ scrollTop: "600px" }, 1000);

         return false

      } else {

         $("#unitDesignPressure-message").html('');
         form_is_valid = true;
      }



      var testPressure = $('#testPressure').val();
      if (!testPressure) {
         form_is_valid = false;
         $("#testPressure-message").html('*Test pressure is required');
         // $('html, body').animate({
         //    scrollTop: $(".form-inspection-title").offset().top
         // }, 500);
         $('html,body').animate({ scrollTop: "600px" }, 1000);
         return false
      } else {
         $("#testPressure-message").html('');
         form_is_valid = true;
      }

      if (closeAllValves == undefined || internalValves == undefined || externalValves == undefined || duringTheTestPeriod == undefined || inspectioninternalValves == undefined || inspectionexternalValves == undefined || remotevalveoperator == undefined || inspectOverallPiping == undefined || withAllValvesClosed == undefined || inspectiontankMarking == undefined) {

         // $('html, body').animate({
         //    scrollTop: $(".form-inspection-title").offset().top
         // }, 500);
         $('html,body').animate({ scrollTop: "600px" }, 1000);
         form_is_valid = false;
         $('#error_message').css("color", "#d33232").html("*Please select one option for each question");

      } else {
         $('#error_message').html('');
      }

      return form_is_valid;

   }

   const save = (status) => {
      // sessionStorage.setItem("verifiedBy",$("#verifiedBy").val());
      // console.log("results=="+JSON.stringify(inputList));
      try {
         for (var i = 0; i < inputList.length; i++) {
            // console.log("filebase64=="+inputList[i].filetobase64);
            var extension = "";
            var byteString = "";
            if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
               extension = "pdf";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
            } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
               extension = "jpg";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
               extension = "png";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/msword;base64,")) {
               extension = "word";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/msword;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:image/bmp;base64,")) {
               extension = "bmp";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/bmp;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/docx;base64,")) {
               extension = "docx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/docx;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/doc;base64,")) {
               extension = "docx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/doc;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/docx;base64,")) {
               extension = "docx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/docx;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/doc;base64,")) {
               extension = "docx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/doc;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,")) {
               extension = "docx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,")) {
               extension = "docm";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,")) {
               extension = "docx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,")) {
               extension = "dotx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,")) {
               extension = "dotx";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", "") : "";
            }
            else if (inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,")) {
               extension = "dotm";
               byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", "") : "";
            }
            else {

            }

            documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
         }
      } catch (exception) {
         // documentList.push();
         // Swal.fire({
         //     position: 'center',
         //     icon: 'error',
         //     title: "",
         //     text:"Please attach document",
         //     showConfirmButton: true

         //   }).then((result) =>
         //   {

         //         if(result.isConfirmed)
         //         {


         //         }
         //   });
         //   return false;
      }
      try {
         if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {

            var i;
            var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
            for (i = 0; i < storedArray.length; i++) {

               documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
            }

         } else {

            documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });
         }

      } catch (ex) {

      }
      if ($("#inspectedBy").val() === "") {
         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Please enter Inspected by",
            showConfirmButton: true

         }).then((result) => {

            if (result.isConfirmed) {


            }
         });
         return false;
      }
      if (status == 'DRAFT') {


         var requestObjectForInsp =
         {
            "id": inspectionId,
            "expiryDate": $("#expiryDate").val(),
            "type": "Leakage Test",
            "inspectedBy": $("#inspectedBy").val(),
            "inspectedDate": $("#inspectedDate").val(),
            "verifiedBy": $("#verifiedBy").val(),
            "verifiedDate": $("#verifiedByBate").val(),
            "workOrderNumber": $("#workOrderNumber").val() != '' ? $("#workOrderNumber").val() : '',
            "notes": $("#notes").val() != '' ? $("#notes").val() : '',
            "status": status,
            "taskOrderId":taskOrderId,
            "inspectionType":
            {
               "id": inspectionTypeId,
               "name": "Leakage Test"
            },
            "result":
            {
               "approvedCertify": "0",
               "inspectionFailed": "0"
            },
            "dispositionOfVehicle":
            {
               "vehicleReturnedServices": "0",
               "vehicleWithdrawnFromService": "0",
               "vehicleSentToAnotherRepairFacility": "0"
            },
            "vehicleDetails":
            {
               "vinNumber": $("#vin").html(),
               "lisencePlateNumber": $("#lisencePlateNumber").html(),
               "id": vid,
               "unitNumber": $("#lisencePlateNumber").html()
            },
            "leakageInspection":
            {
               "checkType":
               {
                  "Hydrostatic": $("input[name='AnnualVehicleInspection']:checked").val() === "Hydrostatic" ? "1" : "0",
                  "Pneumatic": $("input[name='AnnualVehicleInspection']:checked").val() === "Pneumatic" ? "1" : "0",
                  // "modifiedmethod27": $("input[name='MODIFIEDMETHOD27']:checked").val() === "1" ? "1" : "0"
               },
               "unitdeSignPressure":
               {

                  "unitDesignPressure": $("input[name='unitDesignPressure']").val() != '' ? $("input[name='unitDesignPressure']").val() : '',
                  "closeAllValves": $("input[name='closeAllValves']:checked").val() === "1" ? "1" : "0",
                  "internalValves": $("input[name='internalValves']:checked").val() === "1" ? "1" : "0",
                  "externalValves": $("input[name='externalValves']:checked").val() === "1" ? "1" : "0",
                  // "closeAllTanksAndOpens": $("input[name='closeAllTanksAndOpens']:checked").val() === "1" ? "1" : "0"
               },
               "testPressureUsed":
               {
                  "testPressure": $("input[name='testPressure']").val() != '' ? $("input[name='testPressure']").val() : '',
                  "duringTheTestPeriod": $("input[name='duringTheTestPeriod']:checked").val() === "1" ? "1" : "0"
               },
               "inspectionForValveLeakage":
               {
                  "internalValves": $("input[name='inspectioninternalValves']:checked").val() === "1" ? "1" : "0",
                  "externalValves": $("input[name='inspectionexternalValves']:checked").val() === "1" ? "1" : "0",
                  "remotevalveoperator": $("input[name='remotevalveoperator']:checked").val() === "1" ? "1" : "0",
                  "inspectOverallPiping": $("input[name='inspectOverallPiping']:checked").val() === "1" ? "1" : "0",
                  "withAllValvesClosed": $("input[name='withAllValvesClosed']:checked").val() === "1" ? "1" : "0",
                  "tankMarking": $("input[name='inspectiontankMarking']:checked").val() === "1" ? "1" : "0",
                  // "notes":$("input[name='notes']").val()!=''?$("input[name='notes']").val():''
               }
               // "other":$("input[name='notes']").val()!=''?$("input[name='notes']").val():''
            },
            "documentList": documentLists

         };

         $("#loadingforsubmit").show();
         // setLoading(true);
         // const requestOptions =
         // {
         //   method: 'POST',
         //   headers: { 'Content-Type': 'application/json'
         //             },

         //       body: JSON.stringify(requestObject)
         // };
         var requestOptions = requestObject.RequestHeader(requestObjectForInsp);
         services.addInspection(requestOptions).then((res) => {
            // setLoading(false);
            $("#loadingforsubmit").hide();
            Swal.fire({
               position: 'center',
               icon: 'success',
               title: "",
               text: "Your changes have been successfully saved!",
               showConfirmButton: true

            }).then((result) => {

               if (result.isConfirmed) {
                  navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

               }
            });
         });
      } else {
         if (handleSubmit() == true) {


            var requestObjectForInsp =
            {
               "id": inspectionId,
               "expiryDate": $("#expiryDate").val(),
               "type": "Leakage Test",
               "inspectedBy": $("#inspectedBy").val(),
               "inspectedDate": $("#inspectedDate").val(),
               "verifiedBy": $("#verifiedBy").val(),
               "verifiedDate": $("#verifiedByBate").val(),
               "workOrderNumber": $("#workOrderNumber").val() != '' ? $("#workOrderNumber").val() : '',
               "notes": $("#notes").val() != '' ? $("#notes").val() : '',
               "status": status,
               "taskOrderId":taskOrderId,
               "inspectionType":
               {
                  "id": inspectionTypeId,
                  "name": "Leakage Test"
               },
               "result":
               {
                  "approvedCertify": "0",
                  "inspectionFailed": "0"
               },
               "dispositionOfVehicle":
               {
                  "vehicleReturnedServices": "0",
                  "vehicleWithdrawnFromService": "0",
                  "vehicleSentToAnotherRepairFacility": "0"
               },
               // "result": {
               //    "approvedCertify": $("input[name='approvedCertify']:checked").val() === "approvedCertify" ? "1" : "0",
               //    "inspectionFailed": $("input[name='approvedCertify']:checked").val() === "inspectionFailed" ? "1" : "0"
               // },
               // "dispositionOfVehicle": {
               //    "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
               //    "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
               //    "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
               // },
               "vehicleDetails":
               {
                  "vinNumber": $("#vin").html(),
                  "lisencePlateNumber": $("#lisencePlateNumber").html(),
                  "id": vid,
                  "unitNumber": $("#lisencePlateNumber").html()
               },
               "leakageInspection":
               {
                  "checkType":
                  {
                     "Hydrostatic": $("input[name='AnnualVehicleInspection']:checked").val() === "Hydrostatic" ? "1" : "0",
                     "Pneumatic": $("input[name='AnnualVehicleInspection']:checked").val() === "Pneumatic" ? "1" : "0",
                     // "modifiedmethod27": $("input[name='MODIFIEDMETHOD27']:checked").val() === "1" ? "1" : "0"
                  },
                  "unitdeSignPressure":
                  {

                     "unitDesignPressure": $("input[name='unitDesignPressure']").val() != '' ? $("input[name='unitDesignPressure']").val() : '',
                     "closeAllValves": $("input[name='closeAllValves']:checked").val() === "1" ? "1" : "0",
                     "internalValves": $("input[name='internalValves']:checked").val() === "1" ? "1" : "0",
                     "externalValves": $("input[name='externalValves']:checked").val() === "1" ? "1" : "0",
                     // "closeAllTanksAndOpens": $("input[name='closeAllTanksAndOpens']:checked").val() === "1" ? "1" : "0"
                  },
                  "testPressureUsed":
                  {
                     "testPressure": $("input[name='testPressure']").val() != '' ? $("input[name='testPressure']").val() : '',
                     "duringTheTestPeriod": $("input[name='duringTheTestPeriod']:checked").val() === "1" ? "1" : "0"
                  },
                  "inspectionForValveLeakage":
                  {
                     "internalValves": $("input[name='inspectioninternalValves']:checked").val() === "1" ? "1" : "0",
                     "externalValves": $("input[name='inspectionexternalValves']:checked").val() === "1" ? "1" : "0",
                     "remotevalveoperator": $("input[name='remotevalveoperator']:checked").val() === "1" ? "1" : "0",
                     "inspectOverallPiping": $("input[name='inspectOverallPiping']:checked").val() === "1" ? "1" : "0",
                     "withAllValvesClosed": $("input[name='withAllValvesClosed']:checked").val() === "1" ? "1" : "0",
                     "tankMarking": $("input[name='inspectiontankMarking']:checked").val() === "1" ? "1" : "0",
                     // "notes":$("input[name='notes']").val()!=''?$("input[name='notes']").val():''
                  }
                  // "other":$("input[name='notes']").val()!=''?$("input[name='notes']").val():''
               },
               "documentList": documentLists

            };

            var requestOptions = requestObject.RequestHeader(requestObjectForInsp);
            // setLoading(true);

            if (statusInspection == "DRAFT") {
               // setLoading(true);
               $("#loadingforsubmit").show();
               services.addInspection(requestOptions).then((res) => {
                  // setLoading(false);
                  $("#loadingforsubmit").hide();
                  // alert(res.success);
                  Swal.fire({
                     position: 'center',
                     icon: 'success',
                     title: "",
                     text: "Your changes have been successfully saved!",
                     showConfirmButton: true

                  }).then((result) => {

                     if (result.isConfirmed) {
                        navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));
                     }
                  });
               });
            } else {

               if ($('input:radio[name="returnedservice"]:checked').val() === "undefined" || $('input:radio[name="returnedservice"]:checked').val() === undefined) {
                  Swal.fire({
                     position: 'center',
                     icon: 'error',
                     title: "",
                     text: "Please select disposition of tank",
                     showConfirmButton: true

                  }).then((result) => {

                     if (result.isConfirmed) {

                        // alert("hhhhd")
                     }
                  });
                  return false;
               }
               if ($('input:radio[name="approvedCertify"]:checked').val() === "" || $('input:radio[name="approvedCertify"]:checked').val() === undefined) {
                  Swal.fire({
                     position: 'center',
                     icon: 'error',
                     title: "",
                     text: "Please select inspection result",
                     showConfirmButton: true

                  }).then((result) => {

                     if (result.isConfirmed) {


                     }
                  });
                  return false;
               }
               if ($("input[name='approvedCertify']:checked").val() === "approvedCertify") {

               } else {

                  status = "Rejected";
               }

               var requestObjectInspection =
               {
                  "id": inspectionId,
                  "taskOrderId":taskOrderId,
                  "expiryDate": $("#expiryDate").val(),
                  "verifiedBy": $("#verifiedBy").val(),
                  "verifiedDate": $("#verifiedByBate").val(),
                  
                  "status": status,

                  "result": {
                     "approvedCertify": $("input[name='approvedCertify']:checked").val() === "approvedCertify" ? "1" : "0",
                     "inspectionFailed": $("input[name='approvedCertify']:checked").val() === "inspectionFailed" ? "1" : "0"
                  },
                  "dispositionOfVehicle": {
                     "vehicleReturnedServices": $("input[name='returnedservice']:checked").val() === "returnedservice" ? "1" : "0",
                     "vehicleWithdrawnFromService": $("input[name='returnedservice']:checked").val() === "withdrawnservice" ? "1" : "0",
                     "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val() === "repairfacility" ? "1" : "0"
                  }

               };
               // setLoading(true);
               $("#loadingforsubmit").show();
               services.addInspection(requestOptions).then((res) => {
                  // setLoading(false);
                  if (res.success) {

                     //certify inspection
                     var requestOptions = requestObject.RequestHeader(requestObjectInspection);
                     services.certifyInspection(requestOptions).then((res) => {
                        // setLoading(false);
                        $("#loadingforsubmit").hide();
                        Swal.fire({
                           position: 'center',
                           icon: 'success',
                           title: "",
                           text: res.message,
                           showConfirmButton: true

                        }).then((result) => {

                           if (result.isConfirmed) {
                              navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));

                           }
                        });
                     });
                  } else {
                     Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: "",
                        text: "Your changes have been successfully saved!",
                        showConfirmButton: true

                     }).then((result) => {

                        if (result.isConfirmed) {
                           navigate("/vehicleDetails/" + localStorage.getItem("vehicleId"));
                        }
                     });

                  }
               });



            }





         }
      }

   }

   // const checkMethod = () => {

   //    var checkValue = $("input[name='MODIFIEDMETHOD27']:checked").val();
   //    if (checkValue == '1') {
   //       $("#testPressure").prop('readonly', true);
   //       $("#testPressure").val("METHOD 27");
   //    }
   //    else {
   //       const Tp = testPressureSet == "METHOD 27" ? "" : testPressureSet;
   //       $("#testPressure").val(Tp);
   //       $("#testPressure").prop('readonly', false);
   //    }
   // }


   return (
      <>
      {LoadingComponentForSubmit}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block" }}>
            <div class="row mb-0">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <div class="d-flex justify-content-between align-items-center my-4">
                     <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                     </div>
                  </div>
               </div>
               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                     <HeaderBlock />
                  </div>
               </div>

               

               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                     <div class="card">

                        <div class="row mb-2">
                           <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                              <VehicleBasicInfo customerId={vid} />
                           </div>
                        </div>
                        <div class="divider"></div>

                        <div class="row p-3">
                           <div class="col-lg-12">
                              <h4 class="mb-4 form-inspection-title text-center">Leakage Test</h4>
                              <p id="leakage-check-type-message" class="error"></p>
                              <p id="unitDesignPressure-message" class="error"></p>
                              <p id="testPressure-message" class="error"></p>
                              <p id="error_message" class="center error" ></p>
                           </div>
                           <div class="col-lg-12"><WorkOrderNumberDiv /></div><br />


                           <div class="col-lg-3 mb-2 align-items-center d-flex">
                              <div class="d-flex align-items-center">
                                 <span>Leakage Check Type <span class="star">*</span>:</span>
                              </div>
                           </div>
                           <div class="col-lg-2 mb-2"> <div class="d-flex align-items-center">
                              {(() => {
                                 if (inspectionDetails.leakageInspection) {
                                    if (inspectionDetails.leakageInspection.checkType.Hydrostatic == '1') {
                                       return (<><input type="radio" id="vehicle1" name="AnnualVehicleInspection" value="Hydrostatic" class="mr-2" defaultChecked={true} /></>);
                                    } else {
                                       return (<><input type="radio" id="vehicle1" name="AnnualVehicleInspection" value="Hydrostatic" class="mr-2" defaultChecked={false} /></>);
                                    }

                                 }

                              })()}

                              &nbsp;<span>Hydrostatic</span>
                           </div>
                           </div>
                           <div class="col-lg-2 mb-2">
                              <div class="d-flex align-items-center">

                                 {(() => {
                                    if (inspectionDetails.leakageInspection) {
                                       if (inspectionDetails.leakageInspection.checkType.Pneumatic == '1') {
                                          return (<><input type="radio" id="vehicle1" name="AnnualVehicleInspection" value="Pneumatic" class="mr-2" defaultChecked={true} /></>);
                                       } else {
                                          return (<><input type="radio" id="vehicle1" name="AnnualVehicleInspection" value="Pneumatic" class="mr-2" defaultChecked={false} /></>);
                                       }

                                    }

                                 })()}
                                 &nbsp; <span>Pneumatic</span>
                              </div>
                           </div>
                           {/* { (() => {
                                                        if (inspectionDetails.leakageInspection?inspectionDetails.leakageInspection.checkType.hydrosta == '1'?"true":'false':'false')
                                                        {
                                                            return<> <div class="col-lg-2 mb-2"> <div class="d-flex align-items-center"> <input type="radio" id="vehicle1" name="AnnualVehicleInspection" value="Hydrostatic" class="mr-2" checked />
                                                                  <span>Hydrostatic</span>
                                                                  </div>
                                                               </div>

                                                               <div class="col-lg-2 mb-2">
                                                                  <div class="d-flex align-items-center">
                                                                  <input type="radio" id="vehicle1" name="AnnualVehicleInspection" value="Pneumatics" class="mr-2" />
                                                                  <span>Pneumatics</span>
                                                                  </div>
                                                               </div></>
                                                        }
                                                        else
                                                        {

                                                            return<> <div class="col-lg-2 mb-2"> <div class="d-flex align-items-center"> <input type="radio" id="vehicle1" name="AnnualVehicleInspection" value="Hydrostatic" class="mr-2"  />
                                                                  <span>Hydrostatic</span>
                                                                  </div>
                                                               </div>

                                                               <div class="col-lg-2 mb-2">
                                                                  <div class="d-flex align-items-center">
                                                                  <input type="radio" id="vehicle1" name="AnnualVehicleInspection" value="Pneumatics" class="mr-2" checked />
                                                                  <span>Pneumatics</span>
                                                                  </div>
                                                               </div></>

                                                        }
                            })()} */}


                           {/* <div class="col-lg-12 mb-3">
                              <div class="d-flex align-items-center">
                                 {(() => {
                                    if (inspectionDetails.leakageInspection) {
                                       if (inspectionDetails.leakageInspection.checkType.modifiedmethod27 == '1') {
                                          return (<>

                                             <input type="checkbox" id="MODIFIEDMETHOD27" onClick={() => checkMethod()} name="MODIFIEDMETHOD27" value="1" class="mr-2" defaultChecked={true} />
                                          </>);
                                       } else {
                                          return (<><input type="checkbox" id="MODIFIEDMETHOD27" onClick={() => checkMethod()} name="MODIFIEDMETHOD27" value="1" class="mr-2" defaultChecked={false} /></>);
                                       }

                                    }

                                 })()}
                                 <span>MODIFIED METHOD 27</span>
                              </div>
                           </div> */}








                           <div class="col-lg-12">
                              <div class="inspection-wrap">

                                 <div class="table-2 mb-4">
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col">Unit Design Pressure <span class="star">*</span>: <b><input name="unitDesignPressure" id="unitDesignPressure" size="10" defaultValue={inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.unitdeSignPressure.unitDesignPressure : ''} />  </b></th>
                                             <th scope="col">Yes</th>
                                             <th scope="col">No</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr>
                                             <td>Close all valves and all tank with manhole cover open. Inspect all surface for leaks inspect all top and bottom component installation below water line for leaks: cleanouts-welds-vents valve sumps. Are there any leaks?</td>
                                             {(() => {
                                                if (inspectionDetails.leakageInspection) {
                                                   if (inspectionDetails.leakageInspection.unitdeSignPressure.closeAllValves === '1') {
                                                      return (<><td class="text-center">  <input type="radio" name="closeAllValves" value="1" aria-label="Radio button for following text input" defaultChecked={true} /></td>
                                                         <td class="text-center">  <input type="radio" name="closeAllValves" value="0" aria-label="Radio button for following text input" defaultChecked={false} /></td></>)
                                                   } else {
                                                      return (<><td class="text-center">  <input type="radio" name="closeAllValves" value="1" aria-label="Radio button for following text input" defaultChecked={false} /></td>
                                                         <td class="text-center">  <input type="radio" name="closeAllValves" value="0" aria-label="Radio button for following text input" defaultChecked={true} /></td></>)
                                                   }

                                                } else {

                                                }



                                             })()}
                                          </tr>

                                          <tr>
                                             <td>Internal valves: open external valves - inspect for leaks - close external valves. Are there any leaks?</td>
                                             {/* <td class="text-center">  <input type="radio" name="internalValves" value="1" aria-label="Radio button for following text input" /></td>
                                          <td class="text-center">  <input type="radio" name="internalValves" value="0" aria-label="Radio button for following text input" /></td> */}

                                             {(() => {
                                                if (inspectionDetails.leakageInspection) {
                                                   if (inspectionDetails.leakageInspection.unitdeSignPressure.internalValves == '1') {
                                                      return (<><td class="text-center">  <input type="radio" name="internalValves" value="1" aria-label="Radio button for following text input" defaultChecked={true} /></td>
                                                         <td class="text-center">  <input type="radio" name="internalValves" value="0" aria-label="Radio button for following text input" defaultChecked={false} /></td></>)
                                                   } else {
                                                      return (<><td class="text-center">  <input type="radio" name="internalValves" value="1" aria-label="Radio button for following text input" defaultChecked={false} /></td>
                                                         <td class="text-center">  <input type="radio" name="internalValves" value="0" aria-label="Radio button for following text input" defaultChecked={true} /></td></>)
                                                   }
                                                }
                                                else {

                                                }


                                             })()}
                                          </tr>

                                          <tr>
                                             <td>External valves: open internal valves, fill piping with water - inspect for leaks - close internal valves. Are there any leaks?</td>
                                             {(() => {
                                                if (inspectionDetails.leakageInspection) {
                                                   if (inspectionDetails.leakageInspection.unitdeSignPressure.externalValves == '1') {
                                                      return (<><td class="text-center">  <input type="radio" name="externalValves" value="1" aria-label="Radio button for following text input" defaultChecked={true} /></td>
                                                         <td class="text-center">  <input type="radio" name="externalValves" value="0" aria-label="Radio button for following text input" defaultChecked={false} /></td></>)
                                                   } else {
                                                      return (<><td class="text-center">  <input type="radio" name="externalValves" value="1" aria-label="Radio button for following text input" defaultChecked={false} /></td>
                                                         <td class="text-center">  <input type="radio" name="externalValves" value="0" aria-label="Radio button for following text input" defaultChecked={true} /></td></>)
                                                   }
                                                }
                                                else {

                                                }


                                             })()}
                                             {/* <td class="text-center">  <input type="radio" name="externalValves" value="1" aria-label="Radio button for following text input" /></td>
                                          <td class="text-center">  <input type="radio" name="externalValves" value="0" aria-label="Radio button for following text input" /></td> */}
                                          </tr>
                                          {/* <tr>
                                             <td>Close all valves and all tank with manhole cover open. Inspect all surface r leaks inspect all top and bottom component installation below water line for leaks: cleanouts-welds-vents valve sumps. Are there any leaks?</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.unitdeSignPressure.closeAllValves == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.unitdeSignPressure.closeAllValves == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""} </td>
                                          </tr>

                                          <tr>
                                             <td>Internal valves: open external valves - inspect for leaks - close external valves. Are there any leaks?</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.unitdeSignPressure.internalValves == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center"> {inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.unitdeSignPressure.internalValves == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr>

                                          <tr>
                                             <td>External valves: open internal valves, fill piping with water - inspect for leaks - close internal valves. Are there any leaks?</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.unitdeSignPressure.externalValves == '1' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                             <td class="text-center">{inspectionDetails.leakageInspection ? inspectionDetails.leakageInspection.unitdeSignPressure.externalValves == '0' ? <img src="/images/icons/tick-gray.svg" style={{ maxHeight: "16px" }} alt="" /> : "" : ""}</td>
                                          </tr> */}

                                          {/* <tr>
                                                        <td><td>Close all tank openings and valves. Apply water pressure (80% of MAWP) at top of tank - Must hold for 5 minutes</td></td>
                                                        <td class="text-center">{inspectionDetails.leakageInspection?inspectionDetails.leakageInspection.unitdeSignPressure.closeAllTanksAndOpens=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center">{inspectionDetails.leakageInspection?inspectionDetails.leakageInspection.unitdeSignPressure.closeAllTanksAndOpens=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                       </tr>  */}


                                       </tbody>
                                    </table>
                                 </div>

                              </div>
                           </div>

                           <div class="col-lg-12">
                              <div class="inspection-wrap">

                                 <div class="table-2 mb-4">
                                    <table class="table">
                                       <thead>
                                          {/* <tr>
                                       <th scope="col"></th>
                                       <th scope="col"></th>
                                       <th scope="col"></th>
                                    </tr> */}
                                       </thead>
                                       <tbody>
                                          <tr>
                                             <td colspan="3">Close all tank openings and valves. Apply water pressure (80% of MAWP) at top of tank - Must hold for 5 minutes.<br />  <strong>Test Pressure Used</strong>  <span class="star">*</span>: <b>  <input name="testPressure" id="testPressure" defaultValue="testPressure" /></b></td>

                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>

                              </div>
                           </div>

                           <div class="col-lg-12">
                              <div class="inspection-wrap">

                                 <div class="table-2 mb-4">
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col"></th>
                                             <th scope="col">Yes</th>
                                             <th scope="col">No</th>
                                          </tr>
                                       </thead>
                                       <tbody>

                                          <tr>
                                             <td>During the test period : Inspect all surfaces and component installations for leaks. Attention given to manhole  assembly area. Are there any leaks? </td>
                                             {/* <td class="text-center">  <input type="radio" name="duringTheTestPeriod" value="1" aria-label="Radio button for following text input" /></td>
                                          <td class="text-center">  <input type="radio" name="duringTheTestPeriod" value="0" aria-label="Radio button for following text input" /></td> */}

                                             {(() => {
                                                if (inspectionDetails.leakageInspection) {
                                                   if (inspectionDetails.leakageInspection.testPressureUsed.duringTheTestPeriod == '1') {
                                                      return (<><td class="text-center">  <input type="radio" name="duringTheTestPeriod" value="1" aria-label="Radio button for following text input" defaultChecked={true} /></td>
                                                         <td class="text-center">  <input type="radio" name="duringTheTestPeriod" value="0" aria-label="Radio button for following text input" defaultChecked={false} /></td></>)
                                                   } else {
                                                      return (<><td class="text-center">  <input type="radio" name="duringTheTestPeriod" value="1" aria-label="Radio button for following text input" defaultChecked={false} /></td>
                                                         <td class="text-center">  <input type="radio" name="duringTheTestPeriod" value="0" aria-label="Radio button for following text input" defaultChecked={true} /></td></>)
                                                   }
                                                }
                                                else {

                                                }


                                             })()}
                                          </tr>




                                       </tbody>
                                    </table>
                                 </div>

                              </div>
                           </div>

                           <div class="col-lg-12">
                              <div class="inspection-wrap">

                                 <div class="table-2 mb-4">
                                    <table class="table">
                                       <thead>
                                          <tr>
                                             <th scope="col">Inspection for valve Leakage</th>
                                             <th scope="col">Yes</th>
                                             <th scope="col">No</th>
                                          </tr>
                                       </thead>
                                       <tbody>

                                          <tr>
                                             <td>Internal valves : open external valves and drain piping. Inspect for continued drainage - close external valves. Are there any leaks?</td>
                                             {(() => {
                                                if (inspectionDetails.leakageInspection) {
                                                   if (inspectionDetails.leakageInspection.inspectionForValveLeakage.internalValves == '1') {
                                                      return (<><td class="text-center">  <input type="radio" name="inspectioninternalValves" value="1" aria-label="Radio button for following text input" defaultChecked={true} /></td>
                                                         <td class="text-center">  <input type="radio" name="inspectioninternalValves" value="0" aria-label="Radio button for following text input" defaultChecked={false} /></td></>)
                                                   } else {
                                                      return (<><td class="text-center">  <input type="radio" name="inspectioninternalValves" value="1" aria-label="Radio button for following text input" defaultChecked={false} /></td>
                                                         <td class="text-center">  <input type="radio" name="inspectioninternalValves" value="0" aria-label="Radio button for following text input" defaultChecked={true} /></td></>)
                                                   }
                                                } else {

                                                }

                                             })()}
                                             {/* <td class="text-center">  <input type="radio" name="inspectioninternalValves" value="1" aria-label="Radio button for following text input" /></td>
                                          <td class="text-center">  <input type="radio" name="inspectioninternalValves" value="0" aria-label="Radio button for following text input" /></td> */}
                                          </tr>

                                          <tr>
                                             <td>External valves : open internal valves and fill piping with water - inspect leakage from external valves. Are there any leaks?</td>
                                             {(() => {
                                                if (inspectionDetails.leakageInspection) {
                                                   if (inspectionDetails.leakageInspection.inspectionForValveLeakage.externalValves == '1') {
                                                      return (<><td class="text-center">  <input type="radio" name="inspectionexternalValves" value="1" aria-label="Radio button for following text input" defaultChecked={true} /></td>
                                                         <td class="text-center">  <input type="radio" name="inspectionexternalValves" value="0" aria-label="Radio button for following text input" defaultChecked={false} /></td></>)
                                                   } else {
                                                      return (<><td class="text-center">  <input type="radio" name="inspectionexternalValves" value="1" aria-label="Radio button for following text input" defaultChecked={false} /></td>
                                                         <td class="text-center">  <input type="radio" name="inspectionexternalValves" value="0" aria-label="Radio button for following text input" defaultChecked={true} /></td></>)
                                                   }
                                                } else {

                                                }

                                             })()}
                                             {/* <td class="text-center">  <input type="radio" name="inspectionexternalValves" value="1" aria-label="Radio button for following text input" /></td>
                                          <td class="text-center">  <input type="radio" name="inspectionexternalValves" value="0" aria-label="Radio button for following text input" /></td> */}
                                          </tr>

                                          <tr>
                                             <td>Remote valves operators : open internal valve with normal operator - close with remote operator - open external valves and drain piping-  inspect for continued drainage - close external valves - open and close internal valve with normal operator, filling pipe with water. Are there any leaks? </td>
                                             {/* <td class="text-center">  <input type="radio" name="remotevalveoperator" value="1" aria-label="Radio button for following text input" /></td>
                                          <td class="text-center">  <input type="radio" name="remotevalveoperator" value="0" aria-label="Radio button for following text input" /></td> */}
                                             {(() => {
                                                if (inspectionDetails.leakageInspection) {
                                                   if (inspectionDetails.leakageInspection.inspectionForValveLeakage.remotevalveoperator == '1') {
                                                      return (<><td class="text-center">  <input type="radio" name="remotevalveoperator" value="1" aria-label="Radio button for following text input" defaultChecked={true} /></td>
                                                         <td class="text-center">  <input type="radio" name="remotevalveoperator" value="0" aria-label="Radio button for following text input" defaultChecked={false} /></td></>)
                                                   } else {
                                                      return (<><td class="text-center">  <input type="radio" name="remotevalveoperator" value="1" aria-label="Radio button for following text input" defaultChecked={false} /></td>
                                                         <td class="text-center">  <input type="radio" name="remotevalveoperator" value="0" aria-label="Radio button for following text input" defaultChecked={true} /></td></>)
                                                   }
                                                }
                                                else {

                                                }


                                             })()}

                                          </tr>
                                          <tr>
                                             <td>Inspect overall piping system for leakage. Are there any leaks?</td>
                                             {(() => {
                                                if (inspectionDetails.leakageInspection) {
                                                   if (inspectionDetails.leakageInspection.inspectionForValveLeakage.inspectOverallPiping == '1') {
                                                      return (<><td class="text-center">  <input type="radio" name="inspectOverallPiping" value="1" aria-label="Radio button for following text input" defaultChecked={true} /></td>
                                                         <td class="text-center">  <input type="radio" name="inspectOverallPiping" value="0" aria-label="Radio button for following text input" defaultChecked={false} /></td></>)
                                                   } else {
                                                      return (<><td class="text-center">  <input type="radio" name="inspectOverallPiping" value="1" aria-label="Radio button for following text input" defaultChecked={false} /></td>
                                                         <td class="text-center">  <input type="radio" name="inspectOverallPiping" value="0" aria-label="Radio button for following text input" defaultChecked={true} /></td></>)
                                                   }
                                                }
                                                else {

                                                }
                                             })()}

                                             {/* <td class="text-center">  <input type="radio" name="inspectOverallPiping" value="1" aria-label="Radio button for following text input" /></td>
                                          <td class="text-center">  <input type="radio" name="inspectOverallPiping" value="0" aria-label="Radio button for following text input" /></td> */}
                                          </tr>
                                          <tr>
                                             <td>With all valves closed, hold test pressure 5 mins for successful test. Are there any leaks?</td>
                                             {(() => {
                                                if (inspectionDetails.leakageInspection) {
                                                   if (inspectionDetails.leakageInspection.inspectionForValveLeakage.withAllValvesClosed == '1') {
                                                      return (<><td class="text-center">  <input type="radio" name="withAllValvesClosed" value="1" aria-label="Radio button for following text input" defaultChecked={true} /></td>
                                                         <td class="text-center">  <input type="radio" name="withAllValvesClosed" value="0" aria-label="Radio button for following text input" defaultChecked={false} /></td></>)
                                                   } else {
                                                      return (<><td class="text-center">  <input type="radio" name="withAllValvesClosed" value="1" aria-label="Radio button for following text input" defaultChecked={false} /></td>
                                                         <td class="text-center">  <input type="radio" name="withAllValvesClosed" value="0" aria-label="Radio button for following text input" defaultChecked={true} /></td></>)
                                                   }
                                                }
                                                else {

                                                }


                                             })()}
                                             {/* <td class="text-center">  <input type="radio" name="withAllValvesClosed" value="1" aria-label="Radio button for following text input" /></td>
                                          <td class="text-center">  <input type="radio" name="withAllValvesClosed" value="0" aria-label="Radio button for following text input" /></td> */}
                                          </tr>

                                          <tr>
                                             <td>Tank marking: Date(month/year) and service symbol after all defects are corrected. Did you update markings on tank? </td>
                                             {(() => {
                                                if (inspectionDetails.leakageInspection) {
                                                   if (inspectionDetails.leakageInspection.inspectionForValveLeakage.tankMarking == '1') {
                                                      return (<><td class="text-center">  <input type="radio" name="inspectiontankMarking" value="1" aria-label="Radio button for following text input" defaultChecked={true} /></td>
                                                         <td class="text-center">  <input type="radio" name="inspectiontankMarking" value="0" aria-label="Radio button for following text input" defaultChecked={false} /></td></>)
                                                   } else {
                                                      return (<><td class="text-center">  <input type="radio" name="inspectiontankMarking" value="1" aria-label="Radio button for following text input" defaultChecked={false} /></td>
                                                         <td class="text-center">  <input type="radio" name="inspectiontankMarking" value="0" aria-label="Radio button for following text input" defaultChecked={true} /></td></>)
                                                   }
                                                } else {

                                                }

                                             })()}
                                             {/* <td class="text-center">  <input type="radio" name="inspectiontankMarking" value="1" aria-label="Radio button for following text input" /></td>
                                          <td class="text-center">  <input type="radio" name="inspectiontankMarking" value="0" aria-label="Radio button for following text input" /></td> */}
                                          </tr>

                                          {/* <tr>
                                                        <td>"Tank marking: Date (month/Year) and service symbol after all defects are corrected. Did you update markings on tank                                                        </td>
                                                        <td class="text-center">{inspectionDetails.leakageInspection?inspectionDetails.leakageInspection.inspectionForValveLeakage.tankMarking=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center">{inspectionDetails.leakageInspection?inspectionDetails.leakageInspection.inspectionForValveLeakage.tankMarking=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                       </tr> */}


                                       </tbody>
                                    </table>
                                 </div>

                              </div>
                           </div>
                        </div>
                        <div class="divider2"></div>
                        <div class="form-footer-comon-wrap">
                           <div class="row documents p-3">
                              <div class="col-lg-12">
                                 {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                 <div class="row">
                                    {
                                       documentList ? documentList.map((data, index) => (
                                          <div class="col-md-12" style={{marginTop:"-18px"}}>
                                             <div class="align-items-center">
                                                <img src="images/icons/pdf-icon.svg" alt="" />
                                                
                                                   {/* <b>{data.title}.{data.extension}</b> */}
                                                   <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                      <img src="" class="mr-2" />
                                                      <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                      <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100"> {(() => {
                                                            if (data.extension == 'pdf') {
                                                               return (
                                                                  <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                               )
                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                               return (
                                                                  <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                               )
                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                               return (
                                                                  <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                               )
                                                            } else {
                                                               return (
                                                                  <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                               )
                                                            }
                                                         })()}
                                                         {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                         {data.name ? data.name : "--"}  </a></p></span>
                                                      <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" /> </div>
                                               
                                                {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                             </div>
                                          </div>


                                       )) : ""

                                    }
                                 </div>
                              </div><br />

                           </div>




                        </div>
                        {/* <div class="divider2"></div> */}
                        <div class="row p-3">
                           <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                        </div>


                        {/* <div class="row p-3">
                                                         <div class="col-lg-12">
                                                            <div class="d-flex align-items-center mb-3">
                                                               <input type="checkbox" aria-label="Checkbox for following text input" class="mr-2"/>
                                                                  <small>Have any service been performed</small>
                                                            </div>
                                                            <div class="form-group">
                                                               <label for="exampleInputPassword1"><b>Add a written Question</b></label>
                                                               <textarea class="form-control mb-2" id="exampleFormControlTextarea1" rows="3"></textarea>
                                                               <a href="#" type="button" class="btn btn-success">Submit</a>
                                                            </div>

                                                            <div class="qst mt-5">
                                                               <div class="qst-item mb-4">
                                                                  <span>Q1</span> <span>Would you like us to repaire a trailer engine?</span> <span class="btn-label-orange">Waiting</span>
                                                               </div>
                                                               <div class="qst-item mb-3">
                                                                  <span>Q2</span> <span>Would you like us to repaire a trailer engine?</span> <span class="btn-label-green">Approved</span>
                                                               </div>
                                                            </div>

                                                         </div>
                                                      </div> */}

                        <AddInspectionName setEndDate={setEndDate} endDate={endDate} />

                        {statusInspection == "DRAFT" ? "" : <div class="divider"></div>}
                        {(() => {
                           if (statusInspection == "DRAFT") {
                              <></>
                           } else {
                              if (!roles.match("Technician")) {
                                 if (inspectionDetails.status == 'approved' || inspectionDetails.status == 'approve' || inspectionDetails.status == 'APPROVED' || inspectionDetails.status == 'Rejected' || inspectionDetails.status == 'REJECTED') {
                                    return (
                                       <>
                                          <div class="result-didspos">
                                             <div class="p-4">
                                                <span>Result</span>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Inspection Failed</b>
                                                </div>
                                             </div>
                                             <div class="p-4">
                                                <span>Disposition of Tank</span>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked /> : ""}
                                                   <b class="mb-0 ml-2">Tank Returned to Service</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                   {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled /> : ""}
                                                   <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                                </div>
                                             </div>
                                          </div>
                                          <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection} customerId={fkAccountId}/>
                                       </>
                                    )
                                 } else {
                                    return (
                                       <><div class="result-didspos ">
                                          <div class="p-4">
                                             <span>Result</span>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.result ? inspectionDetails.result.approvedCertify == "1" ? <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                             </div>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.result ? inspectionDetails.result.inspectionFailed == "1" ? <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" checked /> : <input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Inspection Failed</b>
                                             </div>
                                          </div>
                                          <div class="p-4">
                                             <span>Disposition of Tank</span>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleReturnedServices == "1" ? <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Tank returned to service</b>
                                             </div>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService == "1" ? <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                             </div>
                                             <div class="d-flex align-items-center">
                                                {inspectionDetails.dispositionOfVehicle ? inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility == "1" ? <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" checked /> : <input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" /> : ""}
                                                <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                             </div>
                                          </div>
                                       </div>
                                       <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} customerId={fkAccountId}/>
                                          {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} customerId={fkAccountId}/> */}


                                       </>
                                    )
                                 }
                              }
                           }
                        })()}

                        <div class="divider2"></div>

                        <div class="row p-3 pr-4 d-flex justify-content-end" >
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
                           {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a> */}
                           {statusInspection == "DRAFT"?<button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("DRAFT")}>Save as Draft</button>:""}

                           {(() => {
                              if (statusInspection == "DRAFT") {
                                 return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("APPROVED PENDING")}>Save</button></>)
                              } else {
                                 if (roles.match("Manager") ||roles.match("Supervisor")|| roles.match("Owner") || roles.match("Inspector")) {
                                    return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("approved")}>Save</button></>)
                                 }
                                 else {
                                    return (<><button type="button" class="btn btn-primary mr-1" data-dismiss="modal" onClick={() => save("APPROVED PENDING")}>Save</button></>)
                                 }
                              }
                           })()}

                        </div>
                     </div>
                  </div>
               </div>

            </div>



         </div>
      </>
   )
};

LeakageTestEdit.propTypes = {};

LeakageTestEdit.defaultProps = {};

export default LeakageTestEdit;
