import React,{history,useEffect, useState, useMemo } from "react";
import HeaderBlock from "../vehicle/VehicleHeaderBlock";
import  $ from "jquery";
import WorkOrderNumberDiv from "../../../utility/WorkorderNumberDiv";
const InspectionVaporTightnessCertificate1 = () => {


const handleSubmit=()=>
{

let form_is_valid = true;

var overfill=$("input[name='overfill']:checked").val();
var connect=$("input[name='connect']:checked").val();
var liquid=$("input[name='liquid']:checked").val();
var compartments=$("input[name='compartments']:checked").val();
var stablization=$("input[name='stablization']:checked").val();
// var location=$("input[name='location']:checked").val();
var location=$("input[name='locationTesting']").val();
var cove=$("input[name='cove']:checked").val();
var internally=$("input[name='internally']:checked").val();
var recovery=$("input[name='recovery']:checked").val();
var pressure=$("input[name='pressure']:checked").val();
var manometer=$("input[name='manometer']:checked").val();


if(overfill==undefined||connect==undefined||liquid==undefined||compartments==undefined||stablization==undefined||cove==undefined||internally==undefined||recovery==undefined||pressure==undefined||manometer==undefined){
   // ||location==''
   // $('html, body').animate({
   //    scrollTop: $("#inspectionType").offset().top
   //  }, 500);
   $('html,body').animate({ scrollTop: "500px" }, 1000);
$('#error_message').css("color", "#d33232").html("*Please choose one option for each question");
form_is_valid= false;

}else{
$('#error_message').html(''); 
}

if (form_is_valid == true) {
   $("#certificate2").trigger('click');
 }
return form_is_valid;

}


useEffect(() => 
{
    // $('input[name="overfill"][value="1"]').trigger("click");

$("#otherField").hide();
 $('input[name="greaterthan2499Gallons"][value="' + sessionStorage.getItem("greaterthan2499Gallons") + '"]').trigger("click");
 $('input[name="1500to2499Gallons"][value="' + sessionStorage.getItem("1500to2499Gallons") + '"]').trigger("click");
 $('input[name="1000to1499Gallons"][value="' + sessionStorage.getItem("1000to1499Gallons") + '"]').trigger("click");
 $('input[name="lessthan999Gallons"][value="' + sessionStorage.getItem("lessthan999Gallons") + '"]').trigger("click");
 $('input[name="overfill"][value="' + sessionStorage.getItem("overfiils") + '"]').trigger("click");
 $('input[name="optic"][value="' + sessionStorage.getItem("optic") + '"]').trigger("click");
//  $('input[name="thermister"][value="' + sessionStorage.getItem("thermister") + '"]').trigger("click");
//  $('input[name="other"][value="' + sessionStorage.getItem("other") + '"]').trigger("click");
 $('input[name="connect"][value="' + sessionStorage.getItem("connect") + '"]').trigger("click");
 $('input[name="liquid"][value="' + sessionStorage.getItem("liquid") + '"]').trigger("click");
 $('input[name="compartments"][value="' + sessionStorage.getItem("compartments") + '"]').trigger("click");
 $('input[name="stablization"][value="' + sessionStorage.getItem("stablization") + '"]').trigger("click");
//  $('location"][value="' + sessionStorage.getItem("location") + '"]');
 $('#locationTesting').val(sessionStorage.getItem("location"));
//  $('input[name="location"][value="' + sessionStorage.getItem("location") + '"]').trigger("click");
 $('input[name="cove"][value="' + sessionStorage.getItem("cove") + '"]').trigger("click");
 $('input[name="internally"][value="' + sessionStorage.getItem("internally") + '"]').trigger("click");
 $('input[name="recovery"][value="' + sessionStorage.getItem("recovery") + '"]').trigger("click");
 $('input[name="pressure"][value="' + sessionStorage.getItem("pressure") + '"]').trigger("click");
 $('input[name="manometer"][value="' + sessionStorage.getItem("manometer") + '"]').trigger("click");
 $('input[name="loadOfNonVolatile"][value="' + sessionStorage.getItem("loadOfNonVolatile") + '"]').trigger("click");
 $('input[name="steamCleaned"][value="' + sessionStorage.getItem("steamCleaned") + '"]').trigger("click");
 $('input[name="purgeEachCompartmentWithAirFor20Minutes"][value="' + sessionStorage.getItem("purgeEachCompartmentWithAirFor20Minutes") + '"]').trigger("click");
 $("#otherData").val(sessionStorage.getItem("otherData"));

},[]); 


       
const gall=function(e)
{
   // let isChecked = e.target.checked;
   // sessionStorage.setItem("greaterthan2499Gallons", isChecked?"1":"0");
   sessionStorage.setItem("greaterthan2499Gallons",  $("input[name='greaterthan2499Gallons']:checked").val()==="1"?"1":"0");
  
}
const galltwonine=function(e)
{
   // let isChecked = e.target.checked;
   // sessionStorage.setItem("1500to2499Gallons", isChecked?"1":"0");
   sessionStorage.setItem("1500to2499Gallons",  $("input[name='1500to2499Gallons']:checked").val()==="1"?"1":"0");
}
const gallthousand=function(e)
{
   // let isChecked = e.target.checked;
   // sessionStorage.setItem("1000to1499Gallons", isChecked?"1":"0");
   sessionStorage.setItem("1000to1499Gallons",  $("input[name='1000to1499Gallons']:checked").val()==="1"?"1":"0");
}
const gallthousandnine=function(e)
{
   // let isChecked = e.target.checked;
   // sessionStorage.setItem("lessthan999Gallons", isChecked?"1":"0");
   sessionStorage.setItem("lessthan999Gallons",  $("input[name='lessthan999Gallons']:checked").val()==="1"?"1":"0");
}
const overfiils=function(e)
{
//   alert ($("input[name='overfill']:checked").val());
   // sessionStorage.setItem("overfiils",  $("input[name='overfill']:checked").val()==="1"?"1":"0");
   sessionStorage.setItem("overfiils",  $("input[name='overfill']:checked").val()!=""?$("input[name='overfill']:checked").val():"");
   
}

const typeOver=function(e)
{
 
   sessionStorage.setItem("optic",  $("input[name='optic']:checked").val()!=""?$("input[name='optic']:checked").val():"");
   if($("input[name='optic']:checked").val()=='2'){
      // alert("ddd");
      $('#otherField').show();
   }else{
      $('#otherField').hide();
   }
}

 $(document).on("keyup", "#otherData", function () {
   sessionStorage.setItem("otherData",  $("#otherData").val()!=""?$("#otherData").val():"");
 });

 $(document).on("keyup", "#locationTesting", function () {
   sessionStorage.setItem("location",  $("#locationTesting").val()!=""?$("#locationTesting").val():"");
 });

// const optic=function(e)
// {
 
//    sessionStorage.setItem("optic",  $("input[name='optic']:checked").val()==="1"?"1":"0");
   
// }
// const other=function(e)
// {
 
//    sessionStorage.setItem("other",  $("input[name='other']:checked").val()==="1"?"1":"0");
   
// }
// const thermister=function(e)
// {
 
//    sessionStorage.setItem("thermister",  $("input[name='thermister']:checked").val()==="1"?"1":"0");
   
// }
const connect=function(e)
{
 
   sessionStorage.setItem("connect",  $("input[name='connect']:checked").val()==="1"?"1":"0");
   
}
const liquid=function(e)
{
 
   sessionStorage.setItem("liquid",  $("input[name='liquid']:checked").val()==="1"?"1":"0");
   
}
const compartments=function(e)
{
 
   sessionStorage.setItem("compartments",  $("input[name='compartments']:checked").val()==="1"?"1":"0");
   
}
const stablization=function(e)
{
 
   sessionStorage.setItem("stablization",  $("input[name='stablization']:checked").val()==="1"?"1":"0");
   
}
// const location=function(e)
// {
 
//    sessionStorage.setItem("location",  $("input[name='location']:checked").val()==="1"?"1":"0");
   
// }
const cove=function(e)
{
 
   sessionStorage.setItem("cove",  $("input[name='cove']:checked").val()==="1"?"1":"0");
   
}
const internally=function(e)
{
 
   sessionStorage.setItem("internally",  $("input[name='internally']:checked").val()==="1"?"1":"0");
   
}
const recovery=function(e)
{
 
   sessionStorage.setItem("recovery",  $("input[name='recovery']:checked").val()==="1"?"1":"0");
   
}
const pressure=function(e)
{
 
   sessionStorage.setItem("pressure",  $("input[name='pressure']:checked").val()==="1"?"1":"0");
   
}
const manometer=function(e)
{
 
   sessionStorage.setItem("manometer",  $("input[name='manometer']:checked").val()==="1"?"1":"0");
   
}
const loadOfNonVolatile=function(e)
{
 
   sessionStorage.setItem("loadOfNonVolatile",  $("input[name='loadOfNonVolatile']:checked").val()==="1"?"1":"0");
   
}

const steamCleaned=function(e)
{
 
   sessionStorage.setItem("steamCleaned",  $("input[name='steamCleaned']:checked").val()==="1"?"1":"0");
   
}
const purgeEachCompartmentWithAirFor20Minutes=function(e)
{
 
   sessionStorage.setItem("purgeEachCompartmentWithAirFor20Minutes",  $("input[name='purgeEachCompartmentWithAirFor20Minutes']:checked").val()==="1"?"1":"0");
   
}

  return(
  <>

                  <div class="row mb-2">
                     <div class="col-lg-12 col-md-12 m-auto ">
                        <div class="card">

                            {/* <div class="divider"></div> */}
                            <div class="row px-3">
                               
                                <div class="w-100">
                                 <h4 class="form-inspection-title text-center">Vapor Tightness Certification Form</h4>
                                 <p id="error_message" class="center"></p>
                              </div>
                              {/* <div class="col-lg-12"><WorkOrderNumberDiv/></div> */}
                                <div class="w-100">
                                    <div class="inspection-wrap">

                                        <div class="step-wrap p-3 mb-2">
                                             <div class="step-main d-flex">
                                                 <div class="step-itm mr-3">
                                                    <div class="no-cl mr-2 current-tick">1</div>
                                                     <div class="labl-section">Annual Certification</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                    <div class="no-cl mr-2">2</div>
                                                     <div class="labl-section">Pressure Test</div>
                                                 </div>

                                                 <div class="step-itm mr-3">
                                                    <div class="no-cl mr-2">3</div>
                                                     <div class="labl-section">Internal Vapor Valve Test</div>
                                                 </div>

                                                 <div class="step-itm mr-3">
                                                    <div class="no-cl mr-2">4</div>
                                                     <div class="labl-section">Vaccum Test</div>
                                                 </div>
                                             </div>
                                        </div>

                                       </div>

                                       <div class="text-center mb-5 mt-2">
                                          <h6>Form for cargo tank truck vapor tightness testing :</h6>
                                       <span class="mb-3"> Air regulation for vapor tightness: VA SAPCB Article 4-37, 40 CFR 60.505, 40 CFR 63.425(e) and 40 CFR 63.11092(f).</span>
                                      <br/><br/>
                                      <b></b>
                                       </div>
                                                
                                       <div class="row mb-4">
                                          <h6 class="ml-3">EPA Reference Method 27 MACT R Annual Certification - Allowed Pressure Change</h6><br/><br/>
                                          <div class="col-lg-6 mb-2">
                                             <div class="d-flex align-items-center">
                                                 <input type="checkbox" name="greaterthan2499Gallons"  id="greaterthan2499Gallons" value="1"  class="mr-2" onChange={e => gall(e)}/>
                                                <span>(tank truck ≥ 2500 gallons) 1.0 inch</span>
                                             </div>
                                         </div>

                                         <div class="col-lg-6 mb-2">
                                          <div class="d-flex align-items-center">
                                              <input type="checkbox" name="1500to2499Gallons" value="1"  class="mr-2" onChange={e => galltwonine(e)}/>
                                             <span>(tank truck 1500-2499 gallons) 1.5 inch</span>
                                          </div>
                                      </div>

                                      <div class="col-lg-6 mb-2">
                                       <div class="d-flex align-items-center">
                                           <input type="checkbox"  name="1000to1499Gallons" value="1"  class="mr-2" onChange={e => gallthousand(e)}/>
                                          <span>(tank truck 1,499 to 1,000 gallons) 2.0 inch</span>
                                       </div>
                                   </div>

                                   <div class="col-lg-6 mb-2">
                                    <div class="d-flex align-items-center">
                                        <input type="checkbox"  name="lessthan999Gallons" value="1"  class="mr-2" onChange={e => gallthousandnine(e)}/>
                                       <span>(tank truck less 999 gallons) 2.5 inch</span>
                                    </div>
                                </div>
                                       </div>

                                      <div class="row">
                                         <div class="col-lg-12">
                                          <div class="table-2 mb-4">
                                             <table class="table">
                                                 <thead>
                                                   <tr>
                                                     <th scope="col"></th>
                                                     <th scope="col"  className="text-center">Good</th>
                                                      <th scope="col"  className="text-center">Faulty</th>
                                                      <th scope="col"  className="text-center" style={{width:"110px"}}>Repaired</th>
                                                   </tr>
                                                 </thead>
                                                 <tbody>
                                                   <tr>
                                                    <td>Overfill Protection System Repaired</td>
                                                     <td class="text-center">  <input type="radio" name="overfill" value="1" aria-label="Radio button for following text input"   onChange={e => overfiils(e)}/></td>
                                                     <td class="text-center">  <input type="radio" name="overfill" value="0" aria-label="Radio button for following text input"  onChange={e => overfiils(e)} /></td>
                                                     <td class="text-center">  <input type="radio" name="overfill" value="2" aria-label="Radio button for following text input"  onChange={e => overfiils(e)} /></td>
                                                   </tr>
   
                                                  
                                                 </tbody>
                                               </table>
                                         </div>
                                         </div>
                                      </div>

                                      <div class="row">
                                         <div class="col-lg-12">
                                          <div class="table-2 mb-4">
                                             <table class="table">
                                                 <thead>
                                                   <tr>
                                                     <th scope="col"></th>
                                                     <th scope="col"  className="text-center">Optic</th>
                                                      <th scope="col"  className="text-center">Thermister</th>
                                                      <th scope="col"  className="text-center" style={{width:"110px"}}>Other</th>
                                                   </tr>
                                                 </thead>
                                                 <tbody>
                                                   <tr>
                                                    <td>Type overfill protection system</td>
                                                     <td class="text-center">  <input type="radio" name="optic" value="1" aria-label="Radio button for following text input"   onChange={e => typeOver(e)}/></td>
                                                     <td class="text-center">  <input type="radio" name="optic" value="0" aria-label="Radio button for following text input"  onChange={e => typeOver(e)} /></td>
                                                     <td class="text-center">  <input type="radio" name="optic" value="2" aria-label="Radio button for following text input"  onChange={e => typeOver(e)} /><div id="otherField">
                                         <input type="text" name="otherData" id="otherData" class="form-control"/>
                                       </div></td>
                                                    
                                                   </tr>
   
                                                  
                                                 </tbody>
                                               </table>
                                              
                                         </div>
                                         </div>
                                      </div>
                                      {/* <div id="otherField">
                                         <input type="text" name="otherData" id="otherData" class="form-control"/>
                                       </div><br/> */}

                                       {/* <div class="row mb-4">
                                          <div class="col-md-12">
                                             <h4>Type over protection system</h4>
                                             <div class="d-flex align-items-center">
                                                <input type="checkbox"  name="optic" value="1"  class="mr-2"  onChange={e => optic(e)}/>
                                               <span>Optic</span>
                                            </div>

                                            <div class="d-flex align-items-center">
                                             <input type="checkbox"  name="thermister" value="1"  class="mr-2"  onChange={e => thermister(e)}/>
                                            <span>Thermister</span>
                                         </div>

                                         <div class="d-flex align-items-center">
                                          <input type="checkbox"  name="other" value="1"  class="mr-2"  onChange={e => other(e)}/>
                                         <span>Other</span>
                                      </div>
                                          </div>
                                       </div> */}

                                       <div class="row mb-4">
                                          <div class="col-lg-12">
                                           <div class="table-2 mb-4">
                                              <table class="table">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col"></th>
                                                      <th scope="col"  className="text-center">Yes</th>
                                                      <th scope="col"  className="text-center">No</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                     <td>Connect static electrical ground to tank</td>
                                                      <td class="text-center">  <input type="radio" name="connect" value="1" aria-label="Radio button for following text input"  onChange={e => connect(e)}/></td>
                                                      <td class="text-center">  <input type="radio" name="connect" value="0"  aria-label="Radio button for following text input" onChange={e => connect(e)}/></td>
                                                    </tr>

                                                    <tr>
                                                      <td>Purged lines of liquid	</td>
                                                       <td class="text-center">  <input type="radio" name="liquid" value="1" aria-label="Radio button for following text input" onChange={e => liquid(e)}/></td>
                                                       <td class="text-center">  <input type="radio" name="liquid" value="0"  aria-label="Radio button for following text input" onChange={e => liquid(e)}/></td>
                                                     </tr>

                                                     <tr>
                                                      <td>Purged tank compartments of Vapor</td>
                                                       <td class="text-center">  <input type="radio" name="compartments" value="1" aria-label="Radio button for following text input" onChange={e => compartments(e)}/></td>
                                                       <td class="text-center">  <input type="radio" name="compartments" value="0"  aria-label="Radio button for following text input" onChange={e => compartments(e)}/></td>
                                                     </tr>

                                                     <tr>
                                                      <td>Temp Stablization</td>
                                                       <td class="text-center">  <input type="radio" name="stablization" value="1" aria-label="Radio button for following text input" onChange={e => stablization(e)}/></td>
                                                       <td class="text-center">  <input type="radio" name="stablization" value="0"  aria-label="Radio button for following text input" onChange={e => stablization(e)}/></td>
                                                     </tr>

                                                     <tr>
                                                      <td>Testing location: <input type="text" name="locationTesting" id="locationTesting"/></td>
                                                      <td class="text-center"></td>
                                                       <td class="text-center"></td>
                                                       {/* <td class="text-center">  <input type="radio" name="location" value="1" aria-label="Radio button for following text input" onChange={e => location(e)}/></td>
                                                       <td class="text-center">  <input type="radio" name="location" value="0"  aria-label="Radio button for following text input" onChange={e => location(e)}/></td> */}
                                                     </tr>

                                                     <tr>
                                                      <td>Open & Close each dome cover</td>
                                                       <td class="text-center">  <input type="radio" name="cove" value="1" aria-label="Radio button for following text input"  onChange={e => cove(e)}/></td>
                                                       <td class="text-center">  <input type="radio" name="cove" value="0"  aria-label="Radio button for following text input"  onChange={e => cove(e)}/></td>
                                                     </tr>
                                                       
                                                  </tbody>
                                                </table>
                                          </div>

                                         
                                          </div>
                                          <div class="col-lg-12">
                                             <div class="table-2 mb-4">
                                                <table class="table">
                                                    <thead>
                                                      <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col"  className="text-center">Yes</th>
                                                        <th scope="col"  className="text-center">No</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                       <td>Connect compartments of tank internally</td>
                                                        <td class="text-center">  <input type="radio" name="internally" value="1" aria-label="Radio button for following text input" onChange={e => internally(e)}/></td>
                                                        <td class="text-center">  <input type="radio" name="internally" value="0"  aria-label="Radio button for following text input" onChange={e => internally(e)}/></td>
                                                      </tr>

                                                      <tr>
                                                         <td>Attach test cap to vapor recovery coupling</td>
                                                          <td class="text-center">  <input type="radio" name="recovery" value="1" aria-label="Radio button for following text input" onChange={e => recovery(e)}/></td>
                                                          <td class="text-center">  <input type="radio" name="recovery" value="0"  aria-label="Radio button for following text input" onChange={e => recovery(e)}/></td>
                                                        </tr>

                                                        <tr>
                                                         <td>Connect pressure-vacuum supply & pressure relief valve to shut-off valve                                                         </td>
                                                          <td class="text-center">  <input type="radio" name="pressure" value="1" aria-label="Radio button for following text input" onChange={e => pressure(e)}/></td>
                                                          <td class="text-center">  <input type="radio" name="pressure" value="0"   aria-label="Radio button for following text input" onChange={e => pressure(e)}/></td>
                                                        </tr>

                                                        <tr>
                                                         <td>Attach Manometer (or equivalent) to pressure tap</td>
                                                          <td class="text-center">  <input type="radio"  name="manometer" value="1" aria-label="Radio button for following text input" onChange={e => manometer(e)}/></td>
                                                          <td class="text-center">  <input type="radio"  name="manometer" value="0"   aria-label="Radio button for following text input" onChange={e => manometer(e)}/></td>
                                                        </tr>
      
                                                     
                                                    </tbody>
                                                  </table>
                                            </div>
                                      
                                            {/* <p id="error_message" class="center"></p> */}
                                           
                                            </div>
                                       </div>

                                       <div class="row">
                                          <div class="col-md-12">
                                             {/* <h4>Type over protection system</h4> */}
                                             <div class="d-flex align-items-center">
                                                <input type="checkbox"  name="loadOfNonVolatile" value="1" class="mr-2" onChange={e => loadOfNonVolatile(e)}/>
                                               <span>Load of Non-Volatile</span>
                                            </div>

                                            <div class="d-flex align-items-center">
                                             <input type="checkbox"  name="steamCleaned" value="1" class="mr-2" onChange={e => steamCleaned(e)}/>
                                            <span>Steam cleaned</span>
                                         </div>

                                         <div class="d-flex align-items-center">
                                          <input type="checkbox"  name="purgeEachCompartmentWithAirFor20Minutes" value="1" class="mr-2" onChange={e => purgeEachCompartmentWithAirFor20Minutes(e)}/>
                                         <span>Purge each compartment with air for 20 minutes</span>
                                      </div>
                                          </div>
                                       </div>
                                        
                                </div>
                            </div>
                          

                           

                            <div class="divider2"></div>
                            <div class="row p-3 pr-4 d-flex justify-content-end" style={{marginBottom:"-20px"}}>
                               
                                <a id="certificate2" style={{ display: "none" }}>Next</a>
                                <a class="btn btn-primary mr-1" onClick={() => handleSubmit()}>Next</a>
                            </div>
                           </div>
                        </div>
                  </div>
                 
              
  </>
)};

InspectionVaporTightnessCertificate1.propTypes = {};

InspectionVaporTightnessCertificate1.defaultProps = {};

export default InspectionVaporTightnessCertificate1;
