// import React from "react";
import React, {useEffect,useState} from "react";

import { BrowserRouter } from "react-router-dom";
import MainContent from "./mainContent/MainContent";
import Header from "./header/header";
import Footer from "./footer/index";
import AppBar from "./appBar/AppBar";

//import { useKeycloak } from '@react-keycloak/web';
var pathName = window.location.pathname;

function Layout() {
  var accountType=sessionStorage.getItem("accountType");
  
  //const [pathName, setPathName] = useState([]);
  //const { keycloak } = useKeycloak();
  useEffect(() => {
 
 
    //  var pathArray = window.location.pathname
    //  setPathName(pathArray);

    //  if(keycloak.authenticated)
    //  {
               
    //  } else
    //  {
    //           //  keycloak.login();
    //  }  

  }, []);

  return (
   
   
      <BrowserRouter>
      {(() => {
         if (accountType !==null) {
 
            return (
              <Header/>
            )
         }
      })()}
       
        <div id="mydiv" class="container-fluid page-body-wrapper">
        {(() => {
        if (accountType !==null) {
       
            return (
              <AppBar/>
            )
         }
      })()}


          <MainContent />
         
        </div>
       
        {/* <Footer/> */}
      </BrowserRouter>
      
     
  );
}

export default Layout;