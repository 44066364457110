import React,{history,useEffect, useState, useMemo,useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import $, { parseJSON } from 'jquery';
import { useForm } from "react-hook-form";
import vehicle_api from "../vehicle/vehicle_lists";
import CheckAuthentication from "../../../utility/checkAuthentication";
import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import AddGarage from "./addGarage";
import moment from "moment";
import AddDynamicInspectionList from "../../../utility/AddDynamicInspectionList";

const ReminderAssignToGarrage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [singleSelections, setSingleSelections] = useState('');

    const [inspectionList,setInspectionList] = useState([]);
    const [listVendors,setListVendors] = useState([]);
    // const [assignDate, setAssignDate] = useState(new Date());
    const [assignDate, setAssignDate] = useState();

    const [inputList, setInputList] = useState([{ }]);
    const [base64file, setBase64file] = useState('');
    const [ fileName, setfileName ] = useState("");

    const [vechileLists,setVehicleList] = useState([]);
    const [totalRecors,setTotalRecord] = useState([]);
    const [totalItems,setTotalItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalpages, settotalPages] = useState();
    const [displayPage, setdisplayPage] = useState([]);
    const [ITEMS_PER_PAGE, setitemperpage] = useState(25);

     const [sortingField, setSortingField] = useState("");
     const [sortingOrder, setSortingOrder] = useState("");

    useEffect(() => 
    {
     
      inspectionListApi();
      listVendorsApi();

      if(CheckAuthentication)
      {
            vehicle_api("1","1",ITEMS_PER_PAGE,"",ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setVehicleList,setTotalRecord,setdisplayPage,sortingField,sortingOrder,"diplayItems");
            $('#display_pages_in_items').on('change', function() {
             
              setitemperpage(this.value);
              vehicle_api("","1",this.value,"",this.value,setTotalItems,settotalPages,setCurrentPage,setVehicleList,setTotalRecord,setdisplayPage,sortingField,sortingOrder,"");
            //  getcustomerLists("1",ITEMS_PER_PAGE,customerName,this.value*ITEMS_PER_PAGE);
            });
      }
      else
      {
        sessionStorage.clear();
        localStorage.clear();
       //navigate("/login");
         window.location.href="/login";
      }


    },[]);

    const inspectionListApi=()=>
{
    var inspectionListReq=
    {
        
    };

 var requestOptions=requestObject.RequestHeader(inspectionListReq);
 services.getInspectionDocumentSubCategories(requestOptions).then((res) => 
 {
     console.log("@@@@@Inspection list@@@@@@"+res.rows);
    setInspectionList(res.rows);
 });
}

const listVendorsApi=()=>
{
    var listVendorsReq=
    {
      
    };

 var requestOptions=requestObject.RequestHeader(listVendorsReq);
 services.listVendors(requestOptions).then((res) => 
 {
     console.log("@@@@@vendors list@@@@@@"+res.rows);
    setListVendors(res.rows);
 });
}


const {
    register,
    formState: { errors },
    handleSubmit
 } = useForm({
    criteriaMode: "all", mode: "onBlur"
 });

 const redirect=(page)=>
  {
       
      navigate(page);
  
  }

const submitValue = () => {

//    var documentList=[];
//    try
//    {
//      for(var i=0;i<inputList.length;i++)
//      {
//          var extension="";
//          var byteString="";
//          if(inputList[i].filetobase64.match("data:application/pdf;base64,"))
//          {
//              extension="pdf";
//              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/pdf;base64,", ""):"";
//          }else if(inputList[i].filetobase64.match("data:image/jpeg;base64,"))
//          {
//              extension="jpg";
//              byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/jpeg;base64,", ""):"";
//          }
//          else if(inputList[i].filetobase64.match("data:image/png;base64,"))
//           {
//               extension="png";
//               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/png;base64,", ""):"";
//           }
//           else if(inputList[i].filetobase64.match("data:application/msword;base64,"))
//           {
//               extension="word";
//               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/msword;base64,", ""):"";
//           }
//           else{
             
//           }


//             documentList.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": inputList[i].fileName.replace(/\.[^/.]+$/, ""),"extension":extension});
//      }
//    }catch(exception)
//    {
//      documentList.push();
     
//    }


    //    var inspectionArray = [];

    //   $.each($("#inspectionIdList"), function(){
    //     inspectionArray.push($(this).val());
    //   });

    var inspectionArray = [];

    $.each($("select[name='inspectionName[]'] option:selected"), function(){
      inspectionArray.push($(this).val());
    });

    //   console.log(inspectionArray);
    //   var array = inspectionArray;
    // console.log(JSON.stringify(array)); 

    var string = JSON.stringify(inspectionArray);
    // const inspeVal=string.replace (/"/g,''); 
    // console.log("current"+string.replace (/"/g,''))
    //   const va=inspectionArray.replace(/\D/g, '');
    //   alert(va)
    //   const categoryData=JSON.parse(inspectionArray);
    //   console.log(categoryData);

    //    const inspectionIdList=$("#inspectionIdList").val()!=''?$("#inspectionIdList").val():'';
    // //    const va=inspectionIdList.replace(/\D/g, '');
    //       const arry= inspectionArray.push(inspectionIdList);
    //    alert(arry);
      var vechileReq =
      {
            "accountId":1, 
            "vehicleId":$("#vehicleId").val()!=''?$("#vehicleId").val():'', 
            // "vendorId":singleSelections, 
            "vendorId":$("#vendorId").val()!=''?$("#vendorId").val():'', 
            // "inspectionIdList":[JSON.parse(inspectionIdList)], 
            // "inspectionIdList":JSON.parse(string.replace (/"/g,'')), 
            "inspectionIdList":inspectionArray, 
            // "inspectionIdList":["1"], 
            // "inspectionCategory":$("#inspectionCategory").val()!=''?$("#inspectionCategory").val():'', 
            "workOrderNumber":'', 
            // "assignDate":assignDate,
            "assignDate":$("#assignDate").val()!=''?$("#assignDate").val():'',
            // "assignDate":"2022-03-01",   
            "notes":$("#notes").val()!=''?$("#notes").val():''

       }

       console.log("Assign gauge object"+JSON.stringify(vechileReq));

      var requestOptions=requestObject.RequestHeader(vechileReq);
      services.assignToGarage(requestOptions).then((res) => {
       //   setLoading(false);
         Swal.fire({
            position: 'center',
            icon: 'success',
            title: "",
            text: res.message,
            showConfirmButton: true

         }).then((result) => {

            if (result.isConfirmed) {
               navigate("/fleetowner/reminder");
            }
         });

      });
   // }

}

   
  return(
  <>
  <div class="main-panel">
                <div class="content-wrapper" style={{display: "block"}}>
                    <div class="col-lg-6 col-md-12 m-auto ">
                        <div class="row">
                        <form  onSubmit={handleSubmit(submitValue)}>
                            <div class="col-md-12 m-auto mb-2 ">
                                <h4 class="font-weight-bold my-4">Assign To Garage</h4>
                                <div class="card pt-3 pb-3 mb-2">
                                    <div class="col-12 ">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Vehicle <span class="star">*</span></label>
                                                    <select class="form-control custome-select" id="vehicleId" {...register('vehicleId', { required: true})}>
                                                    <option value='' selected>Select Vehicle</option>
                                                    {/* <option value='' selected><input type="text" class="form-control"/></option> */}
                                                       {
                                                              vechileLists?vechileLists.map((data,index) => (
                                                                <option value={data.id}>{data.lisencePlateNumber}</option>
                                                           
                                                               )):""
                                                         }
                                                </select>
                                                {errors.vehicleId && errors.vehicleId.type === "required" && <span class="error">Vehicle Id is required</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Inspection<span class="star">*</span></label>
                                                    <AddDynamicInspectionList inputList={inputList} setInputList={setInputList}/>
                                                    {/* <select class="form-control custome-select" id="inspectionIdList" name="inspectionList[]" {...register('inspectionIdList', { required: true})}>
                                                    <option value="" selected>Select Inspection</option>
                                                    {
                                                              inspectionList?inspectionList.map((data,index) => (
                                                                <option value={data.id}>{data.name}</option>
                                                           
                                                               )):""
                                                         }
                                                    
                                                </select> */}
                                                {errors.inspectionIdList && errors.inspectionIdList.type === "required" && <span class="error">Inspection is required</span>}
                                                    {/* <a href="#" style={{color:"#0056b3",textDecoration: "underline",float:"right",margin: ".25rem 0"}} data-toggle="modal" data-target="#addInspection">Add New</a> */}
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Notes</label>
                                                <textarea class="form-control" name="notes" maxLength="300" id="notes"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card pt-3 pb-3 mb-2">
                                    <div class="col-12 ">
                                        <h3 class="font-weight-bold mb-4">Select Garage </h3>
                                        <div class="row">
                                            <div class="col-6">
                                            
                                                <div class="form-group">
                                                    <label>Select Garage <span class="star">*</span></label>
                                                 
                                                   
                                                    <select class="form-control custome-select" id="vendorId" {...register('vendorId', { required: true})}>
                                                    <option value="" selected>Select Garage</option>
                                                    {
                                                              listVendors?listVendors.map((data,index) => (
                                                                <option value={data.id}>{data.name}</option>
                                                           
                                                               )):""
                                                         }   
                                                    </select>
                                            {/* {errors.vendorId && errors.vendorId.type === "required" && <span class="error">Garage  is required</span>} */}
                                                    <a href="#" style={{color:"#0056b3",textDecoration: "underline",float:"right",margin: ".25rem 0"}} data-toggle="modal" data-target="#addGarrage">Add New Garage</a>
                                                    
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Assign Date </label>
                                                    {/* <span class="star">*</span> */}
                                                    {/* <input type="email" class="form-control custome-input"/> */}
                                                    {/* {...register('assignDate', { required: true})} */}
                                                    <DatePicker dateFormat="yyyy-MM-dd" selected={assignDate} minDate={moment().toDate()} onChange={(date) => setAssignDate(date.getTime())} name="assignDate" class="form-control" id="assignDate" placeholderText="YYYY-MM-DD" />
                                                    {/* {...register('assignDate', { required: true})} */}
                                                    {/* <DatePicker dateFormat="yyyy/MM/dd" selected={assignDate} onChange={(date) => setAssignDate(date.getTime())} name="assignDate" class="form-control" id="assignDate" placeholderText="MM-DD-YYYY" /> */}
                                                    {/* {errors.assignDate && errors.assignDate.type === "required" && <span class="error">Assign date  is required</span>} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="p-3">
                                        <button type="submit" class="btn btn-primary mw-120 mr-2">SUBMIT</button>
                                        <a  onClick={() => redirect("/fleetowner/reminder")} class="btn gray-mid-color mw-120">CANCEL</a>
                                    </div>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <!-- partial --> */}

                {/* <!-- Modal --> */}
                <div class="modal fade" id="addInspection" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Add New Inspection Title</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                         </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="row">
                                        <div class="col-md-12 mb-3">
                                            <label>Inspection Name</label>
                                            <input type="text" class="form-control custome-input"/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

                <AddGarage/>

                {/* <div class="modal fade" id="addGarrage" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Add New Garrage</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                         </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="row">
                                        <div class="col-md-12 mb-3">
                                            <label>Garrage Name</label>
                                            <input type="text" class="form-control custome-input"/>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <div class="row g-2">
                                                <div class="col-8">
                                                    <label>Desk Number</label>
                                                    <input type="text" class="form-control custome-input"/>
                                                </div>
                                                <div class="col-4">
                                                    <label>Ext</label>
                                                    <input type="text" class="form-control custome-input"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>Mobile Number</label>
                                            <input type="text" class="form-control custome-input"/>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>Email Address</label>
                                            <input type="text" class="form-control custome-input"/>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>Fax Number</label>
                                            <input type="text" class="form-control custome-input"/>
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <label>Address line 1</label>
                                            <input type="text" class="form-control custome-input"/>
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <label>Address line 2 (optional)</label>
                                            <input type="text" class="form-control custome-input"/>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>City</label>
                                            <input type="text" class="form-control custome-input"/>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>State</label>
                                            <select class="form-control custome-select" id="exampleFormControlSelect1">
                                        <option>Select</option>
                                     </select>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label>Zip</label>
                                            <input type="text" class="form-control custome-input"/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>  
  </>
)};

ReminderAssignToGarrage.propTypes = {};

ReminderAssignToGarrage.defaultProps = {};

export default ReminderAssignToGarrage;
