import React,{history,useEffect, useState, useMemo } from "react";
import services from "../../../../services/services";
import { requestObject } from "../../../../utility/requestObject";
import $ from "jquery";
import Swal from "sweetalert2";
import { useLoading } from "react-hook-loading";
import UsNumberValidate from "../../../../utility/usNumberValidate";
import { useForm } from "react-hook-form";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const FleetOwnerEditProfile = ({documentIDs}) => 
{
   // console.log("@@@@@@11111"+JSON.stringify(documentIDs))
   const [loading, setLoading] = useLoading("Please wait...");

   const {
      register,
      formState: { errors },
      handleSubmit
   } = useForm({
      criteriaMode: "all", mode: "onKeyup", defaultValues: {firstName:"firstName",lastName:"lastName",email:"test@mailnator.com",phone:"111-111-1111"},
   });

   const handleSubmit2 = () => {

      let form_is_valid = true;

      var phoneValidate = $("#FleetOwnerPhone").val();

      if (phoneValidate == '') {
         form_is_valid = false;
         $("#phoneValidateMessage").html('Phone number is required');       
         return false
      } else {
         $("#phoneValidateMessage").html('');
         form_is_valid = true;
      }
      return form_is_valid;

   }

   
   const update=()=>
   {
      
   if(handleSubmit2()==true){
      document.getElementById("closeBtn").click();

      // setLoading(true);
     var customerListReq=
     {
       
         "id":sessionStorage.getItem("userId"),
         "firstName":$("#FleetOwnerfirstName").val().trim(),
         "lastName":$("#FleetOwnerlastName").val().trim(), 
         "email":$("#FleetOwnerEmail").val().trim(),
         "phoneNumber":$("#FleetOwnerPhone").val().trim(), 
         "documents":documentIDs
     };
   console.log(JSON.stringify(customerListReq));
     
      $("#loadingforsubmit").show();
     var requestOptions=requestObject.RequestHeader(customerListReq);
     services.updateUser(requestOptions).then((res) => 
     {
       
         //  setLoading(false);
         $("#loadingforsubmit").hide();
          if (res.success) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: "",
                text: "Your profile has been updated successfully",

                showConfirmButton: true

            }).then((result) => {
                if (result.isConfirmed) {
                 window.location.reload();
                }
            });
        }
        else{
         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: res.message,

            showConfirmButton: true

        }).then((result) => {
            if (result.isConfirmed) {

            }
        });
        }
        
     });
   }
   }
   useEffect(()=>
   {
     
   $('#FleetOwnerPhone').usPhoneFormat();
   },[])
  return(
  <>
      {LoadingComponentForSubmit}
               <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                  <div class="modal-dialog">
                     <div class="modal-content">
                        <div class="modal-header">
                           <h5 class="modal-title" id="exampleModalLabel">Edit</h5>
                           <button type="button" id="closeBtn" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <div class="modal-body">
                        <form onSubmit={handleSubmit(update)}>
                           <div class="row">
                              
                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">First Name<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="FleetOwnerfirstName" maxLength="20" minLength="2" aria-describedby="emailHelp" {...register('firstName', { required: true})}/>
                                    {errors.firstName && errors.firstName.type === "required" && <span class="error">First name is required</span>}
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Last Name<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="FleetOwnerlastName" maxLength="20" minLength="2" aria-describedby="emailHelp" {...register('lastName', { required: true})}/>
                                    {errors.lastName && errors.lastName.type === "required" && <span class="error">Last name is required</span>}
                                 </div>
                              </div>

                              <div class="col-md-12">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Email<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="FleetOwnerEmail"  readOnly aria-describedby="emailHelp" {...register('email', { required: true,pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                          })} />
                                    {errors.email && errors.email.type === "required" && <span class="error">Email is required</span>}
                                                {errors.email && errors.email.type === "pattern" && <span class="error">Invalid email address</span>}
                                 </div>
                              </div>

                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Phone number<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="FleetOwnerPhone" aria-describedby="emailHelp" {...register('phone', { maxLength: 12,minLength:12})} onKeyUp={handleSubmit2}/>
                                    {/* {errors.phone && errors.phone.type === "required" && <span class="error">Phone number is required</span>} */}
                                    {errors.phone && errors.phone.type === "maxLength" && <p class="error">Phone number should be 10 digits</p>}
                                    {errors.phone && errors.phone.type === "minLength" && <p class="error">Phone number should be 10 digits</p>}
                                    <span id="phoneValidateMessage" class="error" ></span>  
                                 </div>
                              </div>
                           </div>
                           <div class="modal-footer">
                           <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                           <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                        </form>
                        </div>
                       
                     </div>
                  </div>
               </div>
  </>
)};

FleetOwnerEditProfile.propTypes = {};

FleetOwnerEditProfile.defaultProps = {};

export default FleetOwnerEditProfile;
