import React, { history, useEffect, useState, useMemo } from "react";
import { useKeycloak } from '@react-keycloak/web'
import { useNavigate, useParams } from "react-router-dom";
import CheckAuthentication from "../../utility/checkAuthentication";
import $ from "jquery";
const AppBar = (props) => {

   const [pathName, setPathName] = useState([]);
   const [activePath, setActive] = useState('garage');
   const [roles, setRole] = useState("");
   const navigate = useNavigate();
  // const { keycloak } = useKeycloak();



   //  setRole(role);




   useEffect(() =>
   {

   //  setActive(window.location.pathname.split('/')[1]);

     if(CheckAuthentication)
      {
         var pathArray = window.location.pathname.split('/');
         var secondLevelLocation = pathArray[1];

         setPathName(secondLevelLocation);

         setRole(localStorage.getItem("Userroles"));

      }else
      {
         sessionStorage.clear();
         localStorage.clear();
        //navigate("/login");
        $("#sidebar").hide();
          window.location.href="/login";
      }



   }, []);
   // const handleLogInOut = () => {
   //    if (keycloak.authenticated) {
   //      props.history.push('/login')
   //      keycloak.logout()
   //    } else {
   //      keycloak.login()
   //    }
   //  }
   $( document ).ready(function() {
      if(window.location.pathname.split('/')[1]!='fleetowner'){
      setActive(window.location.pathname.split('/')[1])

   if (activePath=='garage') {
      // return 'nav-link active';
      document.getElementById("dashboard").setAttribute("class", "nav-link active");
      document.getElementById("sidebar").setAttribute("class", "sidebar sidebar-offcanvas");
      // $('#dashboard').removeClass('active');
      $('#customer').removeClass('active');
      $('#vehicles').removeClass('active');
      $('#documents').removeClass('active');
      $('#profile').removeClass('active');
      $('#settings').removeClass('active');
      $('#users').removeClass('active');
   }else if(activePath=='customer' || activePath=='addCustomer'){
      // $('#customer').addClass('nav-link active');
      document.getElementById("customer").setAttribute("class", "nav-link active");
      document.getElementById("sidebar").setAttribute("class", "sidebar sidebar-offcanvas");
      $('#dashboard').removeClass('active');
      // $('#customer').removeClass('active');
      $('#vehicles').removeClass('active');
      $('#documents').removeClass('active');
      $('#profile').removeClass('active');
      $('#settings').removeClass('active');
      $('#users').removeClass('active');
   }else if(activePath=='vehicles' || activePath=='addVehicle' || activePath=='addInspection' || activePath=='vehicleDetails' || activePath=='editVehicle'){
      document.getElementById("vehicles").setAttribute("class", "nav-link active");
      document.getElementById("sidebar").setAttribute("class", "sidebar sidebar-offcanvas");
      $('#dashboard').removeClass('active');
      $('#customer').removeClass('active');
      // $('#vehicles').removeClass('active');
      $('#documents').removeClass('active');
      $('#profile').removeClass('active');
      $('#settings').removeClass('active');
   }else if(activePath=='documents' || activePath=='addDocument' || activePath=='editDocument'){
      document.getElementById("documents").setAttribute("class", "nav-link active");
      document.getElementById("sidebar").setAttribute("class", "sidebar sidebar-offcanvas");
      $('#dashboard').removeClass('active');
      $('#customer').removeClass('active');
      $('#vehicles').removeClass('active');
      // $('#documents').removeClass('active');
      $('#profile').removeClass('active');
      $('#settings').removeClass('active');
      $('#users').removeClass('active');
   }else if(activePath=='profile' || activePath=='AddMyFleetdocs'){
      document.getElementById("profile").setAttribute("class", "nav-link active");
      document.getElementById("sidebar").setAttribute("class", "sidebar sidebar-offcanvas");
      $('#dashboard').removeClass('active');
      $('#customer').removeClass('active');
      $('#vehicles').removeClass('active');
      $('#documents').removeClass('active');
      // $('#profile').removeClass('active');
      $('#settings').removeClass('active');
      $('#users').removeClass('active');
   }else if(activePath=='settings' ){
      document.getElementById("settings").setAttribute("class", "nav-link active");
      document.getElementById("sidebar").setAttribute("class", "sidebar sidebar-offcanvas");
      $('#dashboard').removeClass('active');
      $('#customer').removeClass('active');
      $('#vehicles').removeClass('active');
      $('#documents').removeClass('active');
      $('#profile').removeClass('active');
      $('#users').removeClass('active');
   }
   else if(activePath=='users' || activePath=='editUser' || activePath=='addusers' || activePath=='userDetails')
   {
      document.getElementById("users").setAttribute("class", "nav-link active");
      document.getElementById("sidebar").setAttribute("class", "sidebar sidebar-offcanvas");
      $('#dashboard').removeClass('active');
      $('#customer').removeClass('active');
      $('#vehicles').removeClass('active');
      $('#documents').removeClass('active');
      $('#profile').removeClass('active');
      $('#settings').removeClass('active');
      // $('#settings').removeClass('active');
   }
    else {
      // return 'nav-link';
   }
}else{

   // Fleet owner
   setActive(window.location.pathname.split('/')[2])
   // alert('/fleetowner/'+window.location.pathname.split('/')[2]);

   if ('/fleetowner/'+activePath=='/fleetowner/dashboard') {
      document.getElementById("Fdashboard").setAttribute("class", "nav-link active");
      document.getElementById("sidebar").setAttribute("class", "sidebar sidebar-offcanvas");
      // $('#Fdashboard').removeClass('active');
      $('#Femployees').removeClass('active');
      $('#FVehicle').removeClass('active');
      $('#Freminder').removeClass('active');
      $('#Fprofile').removeClass('active');
      $('#Fsettings').removeClass('active');
      $('#garages').removeClass('active');
   }
   else if ('/fleetowner/'+activePath=='/fleetowner/garagelist') 
   {
      document.getElementById("garages").setAttribute("class", "nav-link active");
      document.getElementById("sidebar").setAttribute("class", "sidebar sidebar-offcanvas");
      $('#Fdashboard').removeClass('active');
      $('#Femployees').removeClass('active');
      $('#FVehicle').removeClass('active');
      $('#Freminder').removeClass('active');
      $('#Fprofile').removeClass('active');
      $('#Fsettings').removeClass('active');
   }
   
   else if('/fleetowner/'+activePath=='/fleetowner/employees' || '/fleetowner/'+activePath=='/fleetowner/AddDispatcher' || '/fleetowner/'+activePath=='/fleetowner/DispatcherDetails' || '/fleetowner/'+activePath=='/fleetowner/EditDispatcher' || '/fleetowner/'+activePath=='/fleetowner/AddDriver' || '/fleetowner/'+activePath=='/fleetowner/EditDriver' || '/fleetowner/'+activePath=='/fleetowner/DriverDetails'){
      document.getElementById("Femployees").setAttribute("class", "nav-link active");
      document.getElementById("sidebar").setAttribute("class", "sidebar sidebar-offcanvas");
     $('#Fdashboard').removeClass('active');
   //   $('#Femployees').removeClass('active');
     $('#FVehicle').removeClass('active');
     $('#Freminder').removeClass('active');
     $('#Fprofile').removeClass('active');
     $('#Fsettings').removeClass('active');
     $('#garages').removeClass('active');
   }else if('/fleetowner/'+activePath=='/fleetowner/Vehicle' || '/fleetowner/'+activePath=='/fleetowner/AddVehicle' || '/fleetowner/'+activePath=='/fleetowner/VehicleInformation' || '/fleetowner/'+activePath=='/fleetowner/FleetEditVehicle' || '/fleetowner/'+activePath=='/fleetowner/VehicleAtGarage' || '/fleetowner/'+activePath=='/fleetowner/addDocument' || '/fleetowner/'+activePath=='/fleetowner/editDocument'){
      document.getElementById("FVehicle").setAttribute("class", "nav-link active");
      document.getElementById("sidebar").setAttribute("class", "sidebar sidebar-offcanvas");
     $('#Fdashboard').removeClass('active');
     $('#Femployees').removeClass('active');
   //   $('#FVehicle').removeClass('active');
     $('#Freminder').removeClass('active');
     $('#Fprofile').removeClass('active');
     $('#Fsettings').removeClass('active');
     $('#garages').removeClass('active');
   }else if('/fleetowner/'+activePath=='/fleetowner/reminder'){
      document.getElementById("Freminder").setAttribute("class", "nav-link active");
      document.getElementById("sidebar").setAttribute("class", "sidebar sidebar-offcanvas");
     $('#Fdashboard').removeClass('active');
     $('#Femployees').removeClass('active');
     $('#FVehicle').removeClass('active');
   //   $('#Freminder').removeClass('active');
     $('#Fprofile').removeClass('active');
     $('#Fsettings').removeClass('active');
     $('#garages').removeClass('active');
   }else if('/fleetowner/'+activePath=='/fleetowner/profile'){
      document.getElementById("Fprofile").setAttribute("class", "nav-link active");
      document.getElementById("sidebar").setAttribute("class", "sidebar sidebar-offcanvas");
     $('#Fdashboard').removeClass('active');
     $('#Femployees').removeClass('active');
     $('#FVehicle').removeClass('active');
     $('#Freminder').removeClass('active');
   //   $('#Fprofile').removeClass('active');
     $('#Fsettings').removeClass('active');
     $('#garages').removeClass('active');
   }else if('/fleetowner/'+activePath=='/fleetowner/settings'){
      document.getElementById("Fsettings").setAttribute("class", "nav-link active");
      document.getElementById("sidebar").setAttribute("class", "sidebar sidebar-offcanvas");
     $('#Fdashboard').removeClass('active');
     $('#Femployees').removeClass('active');
     $('#FVehicle').removeClass('active');
     $('#Freminder').removeClass('active');
     $('#Fprofile').removeClass('active');
     $('#garages').removeClass('active');
   //   $('#Fsettings').removeClass('active');
   }
    else {
      // return 'nav-link';
   }
}

});
   function getBtnClassName(path) {

      // if (!keycloak.authenticated) {
      //    try {

      //       // keycloak.login();
      //    } catch (Exception) {

      //    }

      // } else {
      //    // keycloak.login();
      //    keycloak.logout()
      // }


      // if (window.location.pathname.match(path)) {

      //    return 'nav-link active';
      // } else {
      //    return 'nav-link';
      // }



      // if(CheckAuthentication)
      // {

      // }else{
      //    navigate("/login");
      // }

   }
   const redirect = (page) => {

      navigate(page);

   }
   
   return (

      <>
         <nav class="sidebar sidebar-offcanvas " id="sidebar">
            <ul class="nav position-fixed">

           {(() => {
                  if (pathName != 'fleetowner')
                  {
                     return (
                        <>
                           <li class="nav-item">
                              <a class="nav-link active" id="dashboard"  className={getBtnClassName('/garage/dashboard')} onClick={() => redirect("/garage/dashboard")}>
                                 <i><img src="/images/icons/home-icon.svg" alt="" /></i>
                                 <span class="menu-title">Dashboard</span>
                              </a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link" id="customer" className={getBtnClassName('/customer')} onClick={() => redirect("/customer")}>
                                 <i><img src="/images/icons/customer-icon.svg" alt="" /></i>
                                 <span class="menu-title">Customers</span>
                              </a>
                           </li>

                           <li class="nav-item">
                              <a class="nav-link" id="vehicles" className={getBtnClassName('/vehicles')} onClick={() => redirect("/vehicles")}>
                                 <i><img src="/images/icons/vehicle-icon.svg" alt="" /></i>
                                 <span class="menu-title">Vehicles</span>
                              </a>
                           </li>
                        </>
                     )
                  }
               })()}


               {(() =>
               {
                  if (pathName === 'fleetowner')
                  {

                     return (
                        <>
                          {roles.match("Driver")?"": <li class="nav-item">
                              <a class="nav-link" id="Fdashboard" className={getBtnClassName('/fleetowner/dashboard')} onClick={() => redirect("/fleetowner/dashboard")}>
                                 <i><img src="/images/icons/home-icon.svg" alt="" /></i>
                                 <span class="menu-title">Dashboard</span>
                              </a>
                           </li>}
                           {roles.match("Driver")?"":
                           <li class="nav-item">
                                          <a class="nav-link" id="Freminder" className={getBtnClassName('/fleetowner/reminder')} onClick={() => redirect('/fleetowner/reminder')}>
                                             <i><img src="/images/icons/document-icon.svg" alt="" /></i>
                                             <span class="menu-title">Reminders</span>
                                          </a>
                          </li>}
                           <li class="nav-item">
                              <a class="nav-link" id="FVehicle" className={getBtnClassName('/fleetowner/Vehicle')} onClick={() => redirect("/fleetowner/Vehicle")}>
                                 <i><img src="/images/icons/vehicle-icon.svg" alt="" /></i>
                                 <span class="menu-title">Vehicles</span>
                              </a>
                           </li>
                           {roles.match("Driver")?"":<li class="nav-item">
                                          <a class="nav-link" id="Femployees" className={getBtnClassName('/fleetowner/employees')} onClick={() => redirect('/fleetowner/employees')}>
                                          <i><img src="/images/icons/users-icon.svg" alt="" width={"50px"} /></i>
                                             <span class="menu-title">Users</span>
                                          </a>
                          </li>}

                          {roles.match("Driver")?"":<li class="nav-item">
                                          <a class="nav-link" id="garages" className={getBtnClassName('/fleetowner/garagelist')} onClick={() => redirect('/fleetowner/garagelist')}>
                                          <i><img src="/icon_svg/garage.svg" alt="" width={"50px"} /></i>
                                             <span class="menu-title">Vendors</span>
                                          </a>
                          </li>}

                           <li class="nav-item">
                              <a class="nav-link" id="Fprofile" className={getBtnClassName('/fleetowner/profile')} onClick={() => redirect('/fleetowner/profile')}>
                                 <i><img src="/images/icons/profile-icon.svg" alt="" /></i>
                                 <span class="menu-title">My Profile</span>
                              </a>
                           </li>

                           {roles.match("Driver")||roles.match("Dispatcher")?"":<li class="nav-item">
                              <a class="nav-link" id="Fsettings" className={getBtnClassName('/fleetowner/settings')} onClick={() => redirect('/fleetowner/settings')}>
                                 <i><img src="/images/icons/setting-icon.svg" alt="" /></i>
                                 <span class="menu-title">Company Profile</span>
                              </a>
                           </li>}


                        </>
                     )
                  }
               })()}


               {(() => {
                  if (pathName != 'fleetowner') {
                     return (
                        <>
                           <li class="nav-item">
                              <a class="nav-link" id="documents" className={getBtnClassName('/documents')} onClick={() => redirect('/documents')}>
                                 <i><img src="/images/icons/document-icon.svg" alt="" /></i>
                                 <span class="menu-title">Documents</span>
                              </a>
                           </li>
                        
                           {roles.match("Owner")||roles.match("Manager")||roles.match("Supervisor")?<li class="nav-item">
                                          <a class="nav-link" id="users" className={getBtnClassName('/users')} onClick={() => redirect('/users')}>
                                          <i><img src="/images/icons/users-icon.svg" alt="" width={"50px"} /></i>
                                             <span class="menu-title">Users</span>
                                          </a>
                          </li>:""}
                          <li class="nav-item">
                              <a class="nav-link" id="profile" className={getBtnClassName('/profile')} onClick={() => redirect('/profile')}>
                                 <i><img src="/images/icons/profile-icon.svg" alt="" /></i>
                                 <span class="menu-title">My Profile</span>
                              </a>
                           </li>
                           {roles.match("Technician")||roles.match("Inspector")?"":<li class="nav-item">
                              <a class="nav-link" id="settings" className={getBtnClassName('/settings')} onClick={() => redirect('/settings')}>
                                 <i><img src="/images/icons/setting-icon.svg" alt="" /></i>
                                 <span class="menu-title">Company Profile</span>
                              </a>
                           </li> }
                        </>
                     )
                  }
               })()}


            </ul>
            <div class="d-flex flex-column text-center justify-content-center powerby">
               <span class="mb-3">Powered by</span>
               <img src="/images/dotstow-logo2.svg" alt="" class="m-auto mt-2" />
            </div>
         </nav>

      </>

   );
};

export default AppBar;