import React, { history, useEffect, useState, useMemo, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import $, { parseJSON } from 'jquery';
import { useForm } from "react-hook-form";
import services from "../../../services/services";
import { useLoading } from "react-hook-loading";
import Swal from "sweetalert2";
import { requestObject } from "../../../utility/requestObject";
import { loadingComponent } from "../../../utility/Loading";
import { Driver, Dispatcher } from "../../constant";
import ToggleSwitch from "../ToggleSwitch";
import { LoadingComponentfordocuments } from "../../../utility/LoadingForDoc";
const FleetDispatcherDetails = () => {

    var roles = localStorage.getItem("Userroles");
    

    const { userId } = useParams();
    // alert(userId);
    const navigate = useNavigate();
    const [dispatcherDetails, setDispatcherDetails] = useState([]);
    const [documentList, setDocumentsList] = useState([]);
    const [checked, setChecked] = useState([]);

    var securityRoleName = sessionStorage.getItem("securityRoleName");
    useEffect(() => {
        $("#loadingfordocs").hide();
        dispatcherDetailsApi();

    }, []);

    const dispatcherDetailsApi = () => {

        var vechileReq =
        {
            "id": userId
        }
        $("#loading").show();
        var requestOptions = requestObject.RequestHeader(vechileReq);
        services.getUserDetails(requestOptions).then((res) => {
            $("#loading").hide();
            setDispatcherDetails(res.rows);
            setDocumentsList(res.rows ? res.rows.documents : "");
            $("#vehicleImage").attr("src", res.customValues.userImageFileByte ? "data:image/png;base64," + res.customValues.userImageFileByte : "/images/avatar.png");

            console.log("Dispatcher Details" + JSON.stringify(res.rows));
        });

    }

    const document_details = (id, filePath) => {
        //   alert(id+''+filePath);
        var extension = filePath.match('[^.]+$');

        var document_request =
        {

            "id": id

        };

        var requestOptions = requestObject.RequestHeader(document_request);
        $("#loadingfordocs").show();
        services.getUserDocumentDetails(requestOptions).then((res) => {
            $("#loadingfordocs").hide();
            if (res.customValues.fileByte === "") {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "",
                    text: "File is not attached",

                    showConfirmButton: true

                }).then((result) => {
                    if (result.isConfirmed) {

                    }
                });
            }
            else {

                var file;

                if (extension == "pdf") {
                    var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    file = new Blob([byteArray], { type: 'application/pdf;base64' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
                else if (extension == "jpg") {
                    // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                    var image = new Image();
                    image.src = "data:image/jpg;base64," + res.customValues.fileByte;

                    var w = window.open("");
                    w.document.write(image.outerHTML);
                }
                else if (extension == "jpeg") {
                    // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                    var image = new Image();
                    image.src = "data:image/jpg;base64," + res.customValues.fileByte;

                    var w = window.open("");
                    w.document.write(image.outerHTML);
                }
                else if (extension == "png") {
                    // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                    var image = new Image();
                    image.src = "data:image/png;base64," + res.customValues.fileByte;

                    var w = window.open("");
                    w.document.write(image.outerHTML);
                }
                else if (extension == "bmp") {
                    // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                    var image = new Image();
                    image.src = "data:image/bmp;base64," + res.customValues.fileByte;

                    var w = window.open("");
                    w.document.write(image.outerHTML);
                }
                else if (extension == "word") {

                    var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    file = new Blob([byteArray], { type: 'application/msword;base64' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
                else if (extension == "docx" || extension == "doc" || extension == "docm" || extension == "dotx" || extension == "dotm") {

                    var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    file = new Blob([byteArray], { type: 'application/msword;base64' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
            }

        });

    }
    const redirect = (page) => {

        navigate(page);

    }

    const activeVehicle = (status) => {
        setChecked(status);
        // var active = $("#activeClient").val();
        // if ($('#activeClient').prop('checked',true)) {
        var request =
        {
            // "fkAccountId":"117",
            "id": userId,
            "isActive": status,
            //    "securityRoleName":"Dispatcher",


        };

        //let checkState = $("#activeClient").is(":checked") ? "true" : "false";
        Swal.fire({
            title: 'Do you really want to change the status of this user?',
            // text: unitNumber+" : "+title,

            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(function (results) {
            // Called if you click Yes.
            if (results.isConfirmed) {

                // setLoading(true);
                $("#loadingforsubmit").show();
                var requestOptions = requestObject.RequestHeader(request);
                services.inactivateUser(requestOptions).then((res) => {
                    // setLoading(false);
                    $("#loadingforsubmit").hide();
                    if (res.success) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: "",
                            text: res.message,

                            showConfirmButton: true

                        }).then((result) => {
                            if (result.isConfirmed) {
                                // IfitIsCustomer();
                                navigate("/fleetowner/employees");
                                //   navigate("/fleetowner/DispatcherDetails/" + userId);
                            }
                        });

                    } else {

                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: "",
                            text: res.message,

                            showConfirmButton: true

                        }).then((result) => {
                            if (result.isConfirmed) {

                            }
                        });

                    }

                });

            }
        },
            function (no) {
                // Called if you click No.
                if (no == 'cancel') {

                }
            });


    }
    return (
        <>
        {LoadingComponentfordocuments}
            {loadingComponent}
            <div class="main-panel">
                <div class="content-wrapper" style={{ display: "block" }}>
                    <div class="row">
                        <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                            <div class="d-flex mb-3 align-items-center justify-content-between">
                                <h4 class="font-weight-bold text-uppercase ">Employee Details</h4>
                                {/* text-uppercase */}
                                <a onClick={() => redirect("/fleetowner/employees")} class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <div class="card p-3">
                                <div class="banner-owner">
                                    <div class="row">
                                        <div className="d-flex justify-content-between w-100 align-items-start">
                                            <div class="col">
                                                <div class="owner-logo mb-3">
                                                    {/* <img src="/images/avatar.png" alt="" style={{width: "70px"}}/> */}
                                                    <img id="vehicleImage" alt="" style={{ width: "70px" }} />
                                                    <div class="owner-name">
                                                        <h4 class="mb-0">{dispatcherDetails ? dispatcherDetails.firstName + "    " + dispatcherDetails.lastName : "N/A"} <label class="badge badge-secondary text-uppercase text-white mb-0 ml-1">{dispatcherDetails ? dispatcherDetails.securityRoleName : "N/A"}</label></h4>
                                                        <p class="opacity-7">EMP ID: <b>{dispatcherDetails ? dispatcherDetails.id : "N/A"}</b></p>
                                                    </div>
                                                </div>
                                            </div>

                                            {(() => {
                                                if (securityRoleName == "Manager") {
                                                    if (dispatcherDetails.securityRoleName == "Owner" || dispatcherDetails.securityRoleName == "Manager") {
                                                        return (
                                                            ""
                                                        )
                                                    } else {
                                                        if (sessionStorage.getItem('userId') == userId) { return ("") } else {
                                                            return (<div class="d-flex ml-auto align-items-center">

                                                            <label className="mb-0 mr-2">Status : </label>
                                                            <div class="pro-info-item text-capitalize mr-3">
        
                                                                <ToggleSwitch id="switch" checked={dispatcherDetails ? dispatcherDetails.isActive ? true : false : false} onChange={checked => activeVehicle(checked)} />
        
        
                                                            </div>
        
                                                        </div>
                                                            )
                                                        }
                                                    }
                                                } else {
                                                    if(securityRoleName == "Driver" || securityRoleName == "Dispatcher"){
                                                        return ('')
                                                    }else{
                                                    if (dispatcherDetails.securityRoleName == "Owner") {
                                                        return ("")
                                                        // if (sessionStorage.getItem('userId') == userId) { return ("") } else {
                                                        //     return (<div class="d-flex ml-auto align-items-center">

                                                        //     <label className="mb-0 mr-2">Status : </label>
                                                        //     <div class="pro-info-item text-capitalize mr-3">
        
                                                        //         <ToggleSwitch id="switch" checked={dispatcherDetails ? dispatcherDetails.isActive ? true : false : false} onChange={checked => activeVehicle(checked)} />
        
        
                                                        //     </div>
        
                                                        // </div>
                                                        //     )
                                                        // }
                                                    } else{
                                                        return (<div class="d-flex ml-auto align-items-center">

                                                            <label className="mb-0 mr-2">Status : </label>
                                                            <div class="pro-info-item text-capitalize mr-3">
        
                                                                <ToggleSwitch id="switch" checked={dispatcherDetails ? dispatcherDetails.isActive ? true : false : false} onChange={checked => activeVehicle(checked)} />
        
        
                                                            </div>
        
                                                        </div>
                                                            ) 
                                                    }
                                                }
                                                }
                                            })()}
                                            {/* {roles.match("Driver") || roles.match("Dispatcher") ? "" :

                                                sessionStorage.getItem('userId') == userId ? '' : <div class="d-flex ml-auto align-items-center">

                                                    <label className="mb-0 mr-2">Status : </label>
                                                    <div class="pro-info-item text-capitalize mr-3">

                                                        <ToggleSwitch id="switch" checked={dispatcherDetails ? dispatcherDetails.isActive ? true : false : false} onChange={checked => activeVehicle(checked)} />


                                                    </div>

                                                </div>

                                            } */}
                                            {/* <div class="col-auto mt-1">

                                                {roles.match(Driver) || roles.match(Dispatcher) ? "" : <a onClick={() => redirect("/fleetowner/EditDispatcher/" + userId)} ><img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />Edit</a>}
                                            </div> */}
                                            {(() => {

                                                if (securityRoleName == "Manager") {
                                                    if (dispatcherDetails.securityRoleName == "Owner") {
                                                        return (
                                                            ""
                                                        )
                                                    } else {
                                                        if(dispatcherDetails.securityRoleName == "Driver" || dispatcherDetails.securityRoleName == "Dispatcher"){return(<div class="col-auto mt-1"><a onClick={() => redirect("/fleetowner/EditDispatcher/" + userId)} ><img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />Edit</a></div>)}else{
                                                        if (sessionStorage.getItem('userId') == userId){
                                                        return (<div class="col-auto mt-1">
                                                            <a onClick={() => redirect("/fleetowner/EditDispatcher/" + userId)} ><img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />Edit</a>
                                                        </div>
                                                        )
                                                        }else{
                                                            return ('')
                                                        }
                                                    }
                                                    }
                                                } else {

                                                    if (roles.match(Driver) || roles.match(Dispatcher)) {
                                                        return ("")
                                                    } else {
                                                        if (dispatcherDetails.securityRoleName == "Owner") {
                                                            if (sessionStorage.getItem('userId') == userId) {  return (<div class="col-auto mt-1">
                                                            <a onClick={() => redirect("/fleetowner/EditDispatcher/" + userId)} ><img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />Edit</a>
                                                        </div>
                                                        ) }else{}
                                                            return ("")}else{
                                                        return (<div class="col-auto mt-1">
                                                            <a onClick={() => redirect("/fleetowner/EditDispatcher/" + userId)} ><img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />Edit</a>
                                                        </div>
                                                        )
                                                            }
                                                    }
                                                }

                                            })()}
                                        </div>
                                    </div>
                                    <div class="banner-contact-info">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                                    <img src="/images/icons/map-icon.svg" alt="" class="mr-2" />
                                                    <div style={{ minWidth: "240px" }}>
                                                        <span>{dispatcherDetails ? dispatcherDetails.addressLine1 : ""}{dispatcherDetails ? dispatcherDetails.addressLine1 ? ', ' : "" : ""}</span>
                                                        <span>{dispatcherDetails ? dispatcherDetails.addressLine2 : ""}{dispatcherDetails ? dispatcherDetails.addressLine2 ? ', ' : "" : ""}</span><br />
                                                        <span>{dispatcherDetails ? dispatcherDetails.city : ""}{dispatcherDetails ? dispatcherDetails.city ? ', ' : "" : ""}</span>
                                                        <span>{dispatcherDetails ? dispatcherDetails.stateName : ""}{dispatcherDetails ? dispatcherDetails.stateName ? ', ' : "" : ""}</span>
                                                        <span>{dispatcherDetails ? dispatcherDetails.zip : ""}{dispatcherDetails ? dispatcherDetails.zip ? '.' : "" : ""}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                                    <img src="/images/icons/phone-icon.svg" alt="" class="mr-2" />
                                                    <span>{dispatcherDetails ? dispatcherDetails.phoneNumber == '' ? "N/A" : dispatcherDetails.phoneNumber : "N/A"}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                                    <img src="/images/icons/message-icon.svg" alt="" class="mr-2" />
                                                    <span>{dispatcherDetails ? dispatcherDetails.email : "N/A"}</span>
                                                </div>
                                            </div>
                                            {/* <div class="col-md-3">
                                                <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                                    <img src="/images/icons/message-icon.svg" alt="" class="mr-2"/>
                                                    <span>(617) 399 8424</span>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {roles.match(Driver) || roles.match(Dispatcher) ? "" :
                        <div class="row mb-2 ">
                            <div class="col-lg-11 col-md-12 m-auto ">
                                <div>
                                    <ul class="nav nav-pills tab-custome" id="pills-tab" role="tablist">
                                        {/* <li class="nav-item">
                                        <a class="nav-link" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="false">Vehicles</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Drivers</a>
                                    </li> */}
                                        <li class="nav-item">
                                            <a class="nav-link active" id="pills-proofs-tab" data-toggle="pill" href="#pills-proofs" role="tab" aria-controls="pills-proofs" aria-selected="true">Documents</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent">
                                        {/* <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <div class="row mb-1">
                                            <div class="col-md-12">
                                                <div class="card">
                                                    <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                                                        <div class="search position-relative align-self-start mb-1 mt-1 mr-4">
                                                            <input type="search" id="gsearch" name="gsearch" placeholder="Search by Name/Emp ID" class="pl-5"/>
                                                            <a href="#" class="search-icon">
                                                                <img src="/images/icons/search.svg" alt=""/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="card-body pt-0 pb-0">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="table-responsive border-top border-bottom">
                                                                    <table class="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Vehicle Number</th>
                                                                                <th>Driver Name</th>
                                                                                <th>Email Id</th>
                                                                                <th>Contact Number</th>
                                                                                <th>Actions</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>ERS 8547</td>
                                                                                <td>George Christopher</td>
                                                                                <td>george@abcd.com</td>
                                                                                <td>(617) 399 8424</td>
                                                                                <td><a  class="btn btn-outline-primary text-uppercase">View</a></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-between p-3">
                                                        <div class="d-flex">
                                                            <div class="dropdown show mr-3 ">
                                                                <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                   25
                                                   </a>
                                                                <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                                    <a class="dropdown-item" href="#">25</a>
                                                                    <a class="dropdown-item" href="#">50</a>
                                                                </div>
                                                            </div>
                                                            <span class="d-flex align-items-center pagenation-mob">25 out of 72 Records Displayed</span>
                                                        </div>
                                                        <nav aria-label="Page navigation example">
                                                            <ul class="pagination mb-0">
                                                                <li class="page-item">
                                                                    <a class="page-link" href="#" aria-label="Previous">
                                                                        <span aria-hidden="true">«</span>
                                                                        <span class="sr-only">Previous</span>
                                                                    </a>
                                                                </li>
                                                                <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                                <li class="page-item">
                                                                    <a class="page-link" href="#" aria-label="Next">
                                                                        <span aria-hidden="true">»</span>
                                                                        <span class="sr-only">Next</span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <div class="row mb-1">
                                            <div class="col-md-12">
                                                <div class="card">
                                                    <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                                                        <div class="search position-relative align-self-start mb-1 mt-1 mr-4">
                                                            <input type="search" id="gsearch" name="gsearch" placeholder="Search by Name/Emp ID" class="pl-5"/>
                                                            <a href="#" class="search-icon">
                                                                <img src="/images/icons/search.svg" alt=""/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="card-body pt-0 pb-0">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="table-responsive border-top border-bottom">
                                                                    <table class="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>EMP ID</th>
                                                                                <th>Driver Name</th>
                                                                                <th>Vehicle</th>
                                                                                <th>Email id</th>
                                                                                <th>Contact Number</th>
                                                                                <th>Actions</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>00001</td>
                                                                                <td>William James</td>
                                                                                <td>ERS 8547</td>
                                                                                <td>william@abcd.com</td>
                                                                                <td>(617) 399 8424</td>
                                                                                <td><a href="/fleetowner/DriverDetails" class="btn btn-outline-primary text-uppercase">View</a></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-between p-3">
                                                        <div class="d-flex">
                                                            <div class="dropdown show mr-3 ">
                                                                <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                   25
                                                   </a>
                                                                <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                                    <a class="dropdown-item" href="#">25</a>
                                                                    <a class="dropdown-item" href="#">50</a>
                                                                </div>
                                                            </div>
                                                            <span class="d-flex align-items-center pagenation-mob">25 out of 72 Records Displayed</span>
                                                        </div>
                                                        <nav aria-label="Page navigation example">
                                                            <ul class="pagination mb-0">
                                                                <li class="page-item">
                                                                    <a class="page-link" href="#" aria-label="Previous">
                                                                        <span aria-hidden="true">«</span>
                                                                        <span class="sr-only">Previous</span>
                                                                    </a>
                                                                </li>
                                                                <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                                <li class="page-item">
                                                                    <a class="page-link" href="#" aria-label="Next">
                                                                        <span aria-hidden="true">»</span>
                                                                        <span class="sr-only">Next</span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                        <div class="tab-pane fade show active" id="pills-proofs" role="tabpanel" aria-labelledby="pills-proofs-tab">
                                            <div class="card pl-3 pr-3">
                                                {/* {dispatcherDetails.documents?dispatcherDetails.documents.length>0?<h4 class="mb-4">Documents</h4>:"":""} */}

                                                {/* <div class="col-md-4">
                                                    <div class="proof_doc_container mt-3">
                                                        <label>Driving Licence</label>
                                                        <div class="proof_doc_wp">
                                                            <span class="edit-cust"><a href="#"><img
                                                                src="/images/icons/edit-icon.svg"/></a></span>
                                                            <img src="/images/id_proof.jpg"/>
                                                        </div>
                                                        <div class="mb-3 d-flex flex-column">
                                                            <label class="mb-1 opacity-7">License Number</label>
                                                            <b>{dispatcherDetails?dispatcherDetails.phoneNumber:"N/A"}</b>
                                                        </div>
                                                        <div class="mb-3 d-flex flex-column">
                                                            <label class="mb-1 opacity-7">DL Expiry Date</label>
                                                            <b>20-07-2034</b>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div class="row" style={{ marginBottom: "30px" }}>
                                                    {
                                                        dispatcherDetails.documents ? dispatcherDetails.documents.map((data, index) => (



                                                            <div class="col-md-6" style={{ cursor: "pointer" }}>
                                                                <a onClick={() => document_details(data.id, data.filePath)} class="ml-2">
                                                                    {/* <div class="proof_doc_container mt-3">
                                                                    <label style={{cursor:"pointer",color:"#3f88c5"}}>{data.name ? data.name : "--"}</label>
                                                                </div> */}
                                                                    <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                        <img src="" class="mr-2" />
                                                                        <span><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                            {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>  */}
                                                                            {(() => {
                                                                                if (data.filePath.split(".")[1] == 'pdf') {
                                                                                    return (
                                                                                        <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                    )
                                                                                } else if (data.filePath.split(".")[1] == 'jpg' || data.filePath.split(".")[1] == 'png' || data.filePath.split(".")[1] == 'jpeg' || data.filePath.split(".")[1] == 'bmp') {
                                                                                    return (
                                                                                        <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                    )
                                                                                } else if (data.filePath.split(".")[1] == 'doc' || data.filePath.split(".")[1] == 'word' || data.filePath.split(".")[1] == 'docx' || data.filePath.split(".")[1] == 'dot' || data.filePath.split(".")[1] == 'dotm' || data.filePath.split(".")[1] == 'dotx') {
                                                                                    return (
                                                                                        <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                    )
                                                                                }
                                                                            })()}
                                                                            {data.name ? data.name : "--"} </p></span>
                                                                    </div>
                                                                </a>
                                                            </div>

                                                        )) : <div class="col-md-4">
                                                            <div class="proof_doc_container mt-3">
                                                                <label>No documents added</label>
                                                                {/* <div class="proof_doc_wp" style={{height: "100%"}}>
                                                        <img src="/images/no-vehicle-image.png" />
                                                        </div> */}
                                                            </div>
                                                        </div>

                                                    }

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>

                {/* <!-- Modal --> */}
                <div class="modal fade" id="assignDriver" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Assign to Driver</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="search position-relative w-100">
                                            <input type="search" id="gsearch" name="gsearch" placeholder="Search driver" class="pl-5 w-100" />
                                            <a href="#" class="search-icon"> <img src="/images/icons/search.svg" alt="" /></a>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                            <label class="form-check-label" for="flexRadioDefault1">Noah Oliver</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                            <label class="form-check-label" for="flexRadioDefault2">Benjamin Roads</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                            <label class="form-check-label" for="flexRadioDefault3">Luke Asther</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                            <label class="form-check-label" for="flexRadioDefault4">Noah Oliver</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
                                            <label class="form-check-label" for="flexRadioDefault5">Benjamin Roads</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" />
                                            <label class="form-check-label" for="flexRadioDefault6">Luke Asther</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="assignDispatcher" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Assign to Employee</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="search position-relative w-100">
                                            <input type="search" id="gsearch" name="gsearch" placeholder="Search Dispatcher" class="pl-5 w-100" />
                                            <a href="#" class="search-icon"> <img src="/images/icons/search.svg" alt="" /></a>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                            <label class="form-check-label" for="flexRadioDefault1">Noah Oliver</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                            <label class="form-check-label" for="flexRadioDefault2">Benjamin Roads</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                            <label class="form-check-label" for="flexRadioDefault3">Luke Asther</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                            <label class="form-check-label" for="flexRadioDefault4">Noah Oliver</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
                                            <label class="form-check-label" for="flexRadioDefault5">Benjamin Roads</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" />
                                            <label class="form-check-label" for="flexRadioDefault6">Luke Asther</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

FleetDispatcherDetails.propTypes = {};

FleetDispatcherDetails.defaultProps = {};

export default FleetDispatcherDetails;
