import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import $ from "jquery";
const document_api = (
  customerId,
  pageNumber,
  ITEMS_PER_PAGE,
  vehicleId,
  pageSize,
  setTotalItems,
  settotalPages,
  setCurrentPage,
  setDocumentList,
  setTotalRecord,
  setdisplayPage,
  lisencePlateNumber,
  sortBy,
  sortDir,
  displayItems
) => {
  /////////////get vechile list//////////////////////////
  var documentReq = "";
  if (sortBy === "Doc category") {
    sortBy = "documentCategoryName";
  } else if (sortBy === "Title") {
    sortBy = "title";
  } else if (sortBy === "documentSubCategoryName") {
    sortBy = "docSubCategory";
  } else if (sortBy === "Document Date") {
    sortBy = "createTime";
  } else if (sortBy === "lisencePlateNumber") {
    sortBy = "lisencePlateNumber";
  } else if (sortBy === "Unit Number") {
    sortBy = "unitNumber";
  } else if (sortBy === "expiryDate") {
    sortBy = "expiryDate";
  } else {
    sortBy = "";
  }

  if (customerId == "") {
    documentReq = {
      lisencePlateNumber: lisencePlateNumber,
      pageNumber: pageNumber,
      vehicleTypeId: "",
      pageSize: pageSize,
      sortBy: sortBy,
      sortDir: sortDir,
      "isActive":true
    };
    // const requestOptions =
    // {
    //       method: 'POST',
    //       headers:{'Content-Type': 'application/json'},
    //       body: JSON.stringify(documentReq)
    // };
    var requestOptions = requestObject.RequestHeader(documentReq);
    services.getListOfDocuments(requestOptions).then((res) => {
      $("#loading").hide();
      if (res.success) {
        setTotalItems(res.customValues.totalNumberOfRecords);
        settotalPages(res.customValues.totalNumberOfRecords);
        setCurrentPage(res.customValues.currentPageNumber);
        setDocumentList(res.rows);
        setTotalRecord(res.customValues.totalNumberOfRecords);
        var displaypages = [];

        if (displayItems === "diplayItems") {
          for (
            var i = 1;
            i <=
            parseInt(
              Math.ceil(res.customValues.totalNumberOfRecords / ITEMS_PER_PAGE)
            );
            i++
          ) {
            displaypages.push(i * ITEMS_PER_PAGE);
          }
          if(displaypages=="")
          {
            setdisplayPage([0]);
          }else{
            setdisplayPage(displaypages);
          }
         
        }
      } else {
        //alert(res.message);
      }
    });
  } else {
    documentReq = {
      fkCustomerId: customerId,
      lisencePlateNumber: lisencePlateNumber,
      pageNumber: pageNumber,
      vehicleTypeId: "",
      pageSize: pageSize,
      sortBy: sortBy,
      sortDir: sortDir,
      "isActive":true
    };
   
    var requestOptions = requestObject.RequestHeader(documentReq);
    services.getCustomerDocuments(requestOptions).then((res) => {
      if (res.success) {
        setTotalItems(res.customValues.totalNumberOfRecords);
        settotalPages(res.customValues.totalNumberOfRecords);
        setCurrentPage(res.customValues.currentPageNumber);
        setDocumentList(res.rows);
        setTotalRecord(res.customValues.totalNumberOfRecords);
        var displaypages = [];

        if (displayItems === "diplayItems") {
          for (
            var i = 1;
            i <=
            parseInt(
              Math.ceil(res.customValues.totalNumberOfRecords / ITEMS_PER_PAGE)
            );
            i++
          ) {
            displaypages.push(i * ITEMS_PER_PAGE);
          }
       alert(displaypages)
          setdisplayPage(displaypages);
        }
      } else {
        //alert(res.message);
      }
    });
  }
};

export default document_api;
