import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore from "./store/configureStore";
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
const store = configureStore({});
if (process.env.NODE_ENV === 'production') 
{
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
  console.warn = () => {}
}
ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
          <App />
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
