import React, { history, useEffect, useState, useMemo } from "react";
import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import { useLoading } from "react-hook-loading";
import Swal from "sweetalert2";
import $ from "jquery";
import Cropper from "react-cropper";
import { useForm } from "react-hook-form";
import { loadingComponent } from "../../../utility/Loading";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
import { LoadingComponentfordocuments } from "../../../utility/LoadingForDoc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DocumentMeta from "react-document-meta";
const Schedule_demo = () => {
   const [profileDetails, setProfileDetails] = useState("");
   const [profilePic, setProfilePic] = useState("");
   const [loading, setLoading] = useLoading("Please wait...");
   const [cropper, setCropper] = useState();
   const [image, setImage] = useState();
   const [documentIdsArray, setDocumentIDs] = useState([]);
   const [startDate, setStartDate] = useState(new Date());
   const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value}
    </button>
  );
  

   const {
      register,
      formState: { errors },
      handleSubmit
   } = useForm({
      criteriaMode: "all", mode: "onKeyup",
   });

   useEffect(() => {
      $("#loadingfordocs").hide();
      
   }, []);

   const createdDate=(field,date)=>
  {

   field.onChange(date);
   setStartDate(date);
  }
  const meta = {
    title: 'DotStow: Schedule a Demo',
  description: 'Schedule a demo today to see how DotStow can help your truck fleet or repair company simplify compliance document management by going from paper to digital.',
  canonical: 'https://dotstow.com/schedule_demo',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'demo call dotstow, demo,call,dotstow'
    }
  }};


   return (
      <>
      <DocumentMeta {...meta} />
      <div class="pp w-100" style={{backgroundColor: "#E0E9EC", marginTop:"-60px", width:"100%"}}>
      <header >
                    <nav class="navbar navbar-expand-lg navbar-light bg-light">
                        <div class="container" style={{ maxWidth: "1200px", margin: "auto" }}>
                            <a class="navbar-brand" href="/login"><img src="/img/logo-dotstow.svg" style={{ width: "90px" }} /></a>
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarSupportedContent">

                                <a class="btn btn-outline-primary text-uppercase" href="/login" >Home</a>
                                {/* <a href="#" data-toggle="modal" data-target="#edit_Contact" >Edit</a> */}
                            </div>
                        </div>
                    </nav>
                </header>
            <section>
               <div class="container">
                  <div class="row">
                     <div class="col-lg-12">
                        <div class="schedule_wrap">
                        <img src="img/dotstow-logo-circle.png" class="circle-logo" alt=""/>
                        <div class="title">
                            <h1>Who Knew Compliance Document Management Could Be This Simple?</h1>
                        </div>
                        <div class="position-relative">
                            <div class="line position-relative"> </div>
                            <b class="mb-5 boldline">Schedule your DotStow demo below.</b>
                        </div>
                        <br/>
                        <b>Thank you for your interest in seeing a live demo of DotStow.</b> <br/>
                        <p>During your call we’ll learn more about how you currently manage compliance documents and the challenges you face. Then, we’ll show you how DotStow can solve your problems.
                            <br/> <br/> Thanks for taking the time to schedule this call and we look forward to speaking with you soon.</p>
                        
                            
                          
                        <div id="schedule_form" class="calendar_wrap">
                       {/* <DatePicker 
                            selected={startDate}  
                            minDate={new Date()} 
                            onChange={(date) => setStartDate(date)} 
                            showTimeSelect  
                            dateFormat="Pp" 
                            inline
                        />
                        */}
                        {/*<div 
                            class="calendly-inline-widget" 
                            data-url="https://calendly.com/bbaker-dotstow/30min" 
                            style={{minHeight:"400px", minWidth:"400px"}}>
                            
                        </div> */}
                       
      <iframe
        src="https://calendly.com/bbaker-dotstow/30min?hide_landing_page_details=1&hide_gdpr_banner=1"
        width="100%"
        height="100%"
        frameborder="0"
        style={{ minHeight: "660px", maxHeight:"660px", overflow:"hidden" }}
      ></iframe>
    
                        </div>

                        

                        </div>
                     </div>
                  </div>
               </div>
            </section>

            <footer class="footer_wrap">
        <div class="container">
        <div class="row footerlinks">
                <div class="col-lg-3">
                    <h3>Contact Us</h3>
                    <p> DotStow, <br/> 11708 Ashville Pike, <br/> Ashville, Ohio 43103, <br/> Phone: 614-907-2725</p>
                </div>
                <div class="col-lg-6 app-icon-wrap">
                    <h2 class="text-center">Download our Driver APP</h2>
                    <div class="app-download">
                        <span><a href="https://play.google.com/store/apps/details?id=com.dotstow.driverapp" target="_blank"> <img src="img/google-app.png" alt="" /></a></span>
                        <span><a href="https://apps.apple.com/app/dotstow-driver-app/id1666349475" target="_blank"> <img src="img/ios-app.png" alt="" /></a></span>
                    </div>
                </div>
                <div class="col-lg-3">
                    <h3>Quick Links</h3>
                    <ul class="mx-0 ml-0">
                        <li><a href="/login" class="footerlinks">Login</a></li>
                        <li><a href="/privacy" class="footerlinks">Privacy Policy</a></li>
                        <li><a href="/terms" class="footerlinks">Terms & Conditions</a></li>

                    </ul>
                </div>
                <div class="col-lg-6 app-icon-wrapbottom">
                    <h2 class="text-center">Download our Driver APP</h2>
                    <div class="app-download">
                        <span><a href="https://play.google.com/store/apps/details?id=com.dotstow.driverapp" target="_blank"> <img src="img/google-app.png" alt="" /></a></span>
                        <span><a href="https://apps.apple.com/app/dotstow-driver-app/id1666349475" target="_blank"> <img src="img/ios-app.png" alt="" /></a></span>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-between">
                <div class="col-sm-12 col-md-6">
                    <div class="copyrights">
                        <p>© {(new Date().getFullYear())} DotStow. All rights reserved. <a href="/privacy">Privacy Policy</a> <a href="/terms">Terms & Conditions</a></p>
                    </div>
                </div>
                <div class="col-sm-auto col-md-auto ">
                    <div class="social_icons">
                        <a href="#" class="svg_icon">
                            <svg height="20" viewBox="0 0 9.327 20.073">
                           <path d="M27.309,12.6H24.133V10.512a.848.848,0,0,1,.884-.965h2.242V6.108L24.171,6.1A3.909,3.909,0,0,0,19.964,10.3V12.6H17.982V16.14h1.982V26.169h4.169V16.14h2.813Z" transform="translate(-17.982 -6.096)" />
                        </svg>
                        </a>
                        <a href="#" class="svg_icon">
                            <svg width="20" viewBox="0 0 24.7 20.073">
                           <path d="M28.274,12.7a10.083,10.083,0,0,1-2.911.8,5.077,5.077,0,0,0,2.228-2.8,10.144,10.144,0,0,1-3.218,1.23,5.072,5.072,0,0,0-8.635,4.622A14.388,14.388,0,0,1,5.294,11.254a5.074,5.074,0,0,0,1.568,6.766,5.053,5.053,0,0,1-2.3-.634c0,.021,0,.043,0,.064a5.072,5.072,0,0,0,4.065,4.969A5.1,5.1,0,0,1,7.3,22.6a5.021,5.021,0,0,1-.954-.091,5.073,5.073,0,0,0,4.734,3.519,10.226,10.226,0,0,1-7.5,2.1A14.412,14.412,0,0,0,25.76,15.981q0-.329-.014-.656A10.264,10.264,0,0,0,28.274,12.7Z" transform="translate(-3.574 -10.326)" />
                         </svg>
                        </a>
                        <a href="#" class="svg_icon">
                            <svg width="20" viewBox="0 0 20.073 20.073">
                           <path data-name="Path 72" d="M20.58,16.7a5.18,5.18,0,1,0,5.18,5.18A5.19,5.19,0,0,0,20.58,16.7Zm0,8.5A3.319,3.319,0,1,1,23.9,21.88,3.334,3.334,0,0,1,20.58,25.2Z" transform="translate(-10.544 -11.884)" />
                           <circle data-name="Ellipse 18" cx="1.174" cy="1.174" r="1.174" transform="translate(14.245 3.521)"/>
                           <path data-name="Path 73" d="M21.854,6.459A5.764,5.764,0,0,0,17.6,4.8H9.268A5.544,5.544,0,0,0,3.4,10.668v8.3a5.823,5.823,0,0,0,1.7,4.33,5.906,5.906,0,0,0,4.209,1.578h8.256a5.976,5.976,0,0,0,4.249-1.578A5.794,5.794,0,0,0,23.473,19V10.668A5.8,5.8,0,0,0,21.854,6.459ZM21.692,19a3.954,3.954,0,0,1-1.174,2.954,4.183,4.183,0,0,1-2.954,1.052H9.309a4.183,4.183,0,0,1-2.954-1.052,4.068,4.068,0,0,1-1.093-2.995v-8.3A4.035,4.035,0,0,1,6.354,7.714,4.115,4.115,0,0,1,9.309,6.662h8.337A4.035,4.035,0,0,1,20.6,7.754a4.176,4.176,0,0,1,1.093,2.914V19Z" transform="translate(-3.4 -4.8)" />
                         </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </footer>
           </div>
      </>
   )
};

Schedule_demo.propTypes = {};

Schedule_demo.defaultProps = {};

export default Schedule_demo;
