import React,{history,useEffect, useState, useMemo } from "react";
import { useNavigate ,useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import  $ from "jquery";
import {useLoading} from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const InternalVisualRead = () => 
{
   var totalGallons=0;
   const { fkAccountId, inspectionId,vid } = useParams();
   const [inputList, setInputList] = useState([{ }]);
   const [base64file, setBase64file] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const [ fileName, setfileName ] = useState("");
   const [ inspectionDetails, setInspectionDetails ] = useState("");
   const [ documentList, setDocumentLists ] = useState([]);
   const navigate = useNavigate();
   const [endDate, setEndDate] = useState(new Date());
   const [compartments, setcompartments] = useState();
   const [verifiedDATE, setverifiedDate] = useState(new Date());
   const [expiryDate, setexpiryDate] = useState(new Date());
   const [roles, setRoles] = useState("");
   const [statusInspection, setStatus] = useState("");
   useEffect(() => {
     setRoles(localStorage.getItem("Userroles"));
    
      // vehicle_details_api(id, setVehicleDetails, setCustomerId); 
      getInpection(fkAccountId,inspectionId)
   }, []);
   var workingLen=[];
  function getInpection(fkAccountId,inspectionId)
  {
      var requestObjectForWet=
      {
      
        "id":inspectionId
      }

      var requestOptions=requestObject.RequestHeader(requestObjectForWet);

      services.getInspectionDetails(requestOptions).then((res) => 
      {
     
         setInspectionDetails(res.rows);
         setDocumentLists(res.rows.documentList?res.rows.documentList:"");
        
         setcompartments(res.rows.internalVisualInspection?res.rows.internalVisualInspection.compartmentList:"");
         if(res.rows.internalVisualInspection?res.rows.internalVisualInspection.compartmentList:""!=="")
         {
           for(var i=0;i<res.rows.internalVisualInspection.compartmentList.length;i++)
           {
               if(res.rows.internalVisualInspection.compartmentList[i].working=="1")
               {
                  workingLen.push(res.rows.internalVisualInspection.compartmentList[i].working);
                 totalGallons+=parseFloat(res.rows.internalVisualInspection.compartmentList[i].safeFillAmountInGals);
               }
            
           }
           $("#allValues").val(totalGallons);
           if(res.rows.internalVisualInspection.compartmentList.length==workingLen.length)
           {
              $('#checkAll').prop('checked', true);
           }
           $("#workOrderNumber").val(res.rows?res.rows.workOrderNumber:"");
         //   sessionStorage.setItem("verifiedBy",res.rows ? res.rows.verifiedBy : "");
         setStatus(res.rows.status);
           if(res.rows)
           {
              if(res.rows.verifiedDate)
              {
                 setverifiedDate(new Date(res.rows?res.rows.verifiedDate:''));
              }
             
              if(res.rows.expiryDate)
              {
                 setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
              }
              
           }
         }
         
      
      });

   }
   const redirect=(page)=>
    {
    
       navigate(page);
    
    }
   const save=(status)=>
   {
 
   // console.log("results=="+JSON.stringify(inputList));
   if($("#inspectedBy").val()==="")
   {
     Swal.fire({  
         position: 'center',  
         icon: 'error',  
         title: "",  
         text:"Please enter Inspected by",
         showConfirmButton: true 
         
       }).then((result) => 
       {
             
             if(result.isConfirmed) 
             {
               
             
             }
       });
       return false;
   }
   if( $('input:radio[name="returnedservice"]:checked').val()==="undefined"||$('input:radio[name="returnedservice"]:checked').val()===undefined)
   {
     Swal.fire({  
         position: 'center',  
         icon: 'error',  
         title: "",  
         text:"Please select disposition of tank",
         showConfirmButton: true 
         
       }).then((result) => 
       {
             
             if(result.isConfirmed) 
             {
               
             
             }
       });
       return false;
   }
 
   if( $('input:radio[name="approvedCertify"]:checked').val()===""|| $('input:radio[name="approvedCertify"]:checked').val()===undefined)
   {
     Swal.fire({  
         position: 'center',  
         icon: 'error',  
         title: "",  
         text:"Please select inspection result",
         showConfirmButton: true 
         
       }).then((result) => 
       {
             
             if(result.isConfirmed) 
             {
               
             
             }
       });
       return false;
   }
   if($("input[name='approvedCertify']:checked").val()==="approvedCertify")
   {

   }else{

    status="Rejected";
   }
         var requestObjectInspection=
         {
           "id": inspectionId,
           "expiryDate": $("#expiryDate").val(),
            "verifiedBy": $("#verifiedBy").val(),
           "verifiedDate": $("#verifiedByBate").val(),
           "status": status,
          
           "result": {
            "approvedCertify":$("input[name='approvedCertify']:checked").val()==="approvedCertify"?"1":"0",
            "inspectionFailed": $("input[name='approvedCertify']:checked").val()==="inspectionFailed"?"1":"0"
          },
          "dispositionOfVehicle": {
            "vehicleReturnedServices":$("input[name='returnedservice']:checked").val()==="returnedservice"?"1":"0",
            "vehicleWithdrawnFromService":$("input[name='returnedservice']:checked").val()==="withdrawnservice"?"1":"0",
            "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val()==="repairfacility"?"1":"0"
          }
          
         
       }; 
 
       $("#loadingforsubmit").show();
      //  setLoading(true);
       // const requestOptions = 
       // {
       //   method: 'POST',
       //   headers: { 'Content-Type': 'application/json'
       //             },
                   
       //       body: JSON.stringify(requestObject)
       // };
       var requestOptions=requestObject.RequestHeader(requestObjectInspection);
       services.certifyInspection(requestOptions).then((res) => 
       {
         // setLoading(false);
         $("#loadingforsubmit").hide();
         Swal.fire({  
           position: 'center',  
           icon: 'success',  
           title: "",  
           text:res.message,
           showConfirmButton: true 
           
         }).then((result) => 
         {
               
               if(result.isConfirmed) 
               {
                 navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));
               
               }
         });
       });
 
   }
   
  return(
  <>
  {LoadingComponentForSubmit}
 <div class="main-panel">
               <div class="content-wrapper" style={{display: "block"}}>
               <div class="row mb-0">
                    <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                       <div class="d-flex justify-content-between align-items-center my-4">
                       <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                       </div>
                    </div>
                 </div>

                  <div class="row mb-2">
                     <div class="col-lg-11 col-md-12 m-auto ">
                     <HeaderBlock cids={fkAccountId} />
                        {/* <div class="card p-3">
                           <div class="banner-owner">
                              <div class="owner-logo">
                                 <img src="images/transport-logo.jpg" alt=""/>
                                 <div class="owner-name">
                                    <h4>ABCD Transport Ltd.</h4>
                                   
                                 </div>
                              </div>
                              <div class="banner-contact-info bg-white d-flex align-items-center flex-column flex-sm-row">
                                 <div class="row">
                                    <div class="col-md-4">
                                       <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                          <img src="images/icons/map-icon.svg" alt="" class="mr-2"/>
                                          <div style={{minWidth: "240px"}}>
                                             <span>Baltimore, MD - 21216</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-md-4">
                                       <div class="text-left d-flex align-items-center banner-info mb-1 mt-1"> 
                                          <img src="images/icons/phone-icon.svg" alt="" class="mr-2"/>
                                          <span>(617) 399 8424</span>
                                       </div>
                                    </div>
                                    <div class="col-md-4">
                                       <div class="text-left d-flex align-items-center banner-info mb-1 mt-1"> 
                                          <img src="images/icons/message-icon.svg" alt="" class="mr-2"/>
                                          <span>info@abcdtransport.com</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div> */}
                     </div>
                  </div>

                  
                  <div class="row mb-2">
                     <div class="col-lg-11 col-md-12 m-auto ">
                        <div class="card">

                           <div class="row mb-2">
                              <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                                    <VehicleBasicInfo customerId={vid} />
                              </div>
                           </div>
                           
                          
                            <div class="divider"></div>
                            <div class="row p-3">
                                <div class="col-lg-12">
                                    <h4 class="mb-2 form-inspection-title text-center">Internal Visual Inspection Report</h4>
                                </div>
                                <div class="col-lg-12"><WorkOrderNumberDiv status={"readOnly"} /></div> 
                                <div class="w-100 px-3">
                                    <div class="inspection-wrap">

                                    <div class="table-2 mb-4">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col" style={{ "width": "50%", "lineHeight":"38px", "paddingLeft":"15px" }}>Compartment Tested/Inspected </th>
                                                    <th scope="col" style={{ "width": "40%", "lineHeight":"38px", "paddingLeft":"15px" }}>Safe fill amount(GALS)</th>
                                                    <th scope="col" class="d-flex"><input type="checkbox"    placeholder=""   id="checkAll"  disabled /><span class="mx-2 mt-2">ALL</span></th>
                                                  </tr>
                                                </thead>
                                                <tbody>

                                                      {
                                                                  compartments ? compartments.map((data, index) => (
                                                                     <tr>
                                                                     <td>
                                                                        <div class="row align-items-center">
                                                                              <div class="col-md-4">Compartment #{index+1} </div>
                                                                        
                                                                        </div>
                                                                     </td>
                                                                     <td>
                                                                     
                                                                              <div class="col-md-4"><input type="text" class="form-control" id={"comp"+index+"gallons"} aria-describedby="emailHelp" placeholder="Capacity NNNN Gallons" defaultValue={data.safeFillAmountInGals} disabled /></div>
                                                                           
                                                                     </td>
                                                                  { (() => {
                                                                        if (data.working=="1") 
                                                                        {
                                                                     
                                                                        return(<><td><input type="checkbox" aria-label="Radio button for following text input"  value="1"  checked   disabled /></td></>)
                                                                        }else 
                                                                        {
                                                                        return(<><td><input type="checkbox" aria-label="Radio button for following text input"  value="1"  disabled /></td></>)
                                                                        }
                                                                     })()}

                                                                  </tr>
                                                                  )) : ""

                                                      }
                                                                                                         
                                                                     <td>Total in Gallons</td> <td><div class="col-md-4"><input type="text" class="form-control"  id="allValues" disabled  /></div></td>

                                                                  </tbody>
                                              </table>
                                        </div>
                                         
                                    </div>
                                </div>
                           
                                <div class="col-lg-6">
                                    <div class="inspection-wrap">

                                       <div class="table-2 mb-4">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col" style={{ "paddingLeft":"15px" }}>Section 1 Tank Shell/Heads are there any?</th>
                                                    <th scope="col">Yes</th>
                                                    <th scope="col">No</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  
                                                    <tr>
                                                        <td>Corroded or abraded areas</td>
                                                         <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.corroded=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                         <td class="text-center">{inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.corroded=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                                       </tr>  

                                                       <tr>
                                                        <td>Dents</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.dents=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.dents=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                       </tr>  

                                                       <tr>
                                                        <td>Distortions</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.distortions=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.distortions=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                                       </tr> 
                                                       
                                                       <tr>
                                                        <td>Defects in Welds</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.defectsInWelds=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.defectsInWelds=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                       </tr>

                                                       <tr>
                                                        <td>Leaks of any kind</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.leaksOfAnyKind=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center">{inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.leaksOfAnyKind=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                                       </tr>

                                                       <tr>
                                                        <td>Defects in baffles</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.defectInBaffels=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.defectInBaffels=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                       </tr>

                                                       <tr>
                                                        <td>Defects in interior emergency valves</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.defectInInterios=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.defectInInterios=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                       </tr>

                                                       <tr>
                                                        <td>Defects in lining</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.defectInLining=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center">{inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.defectInLining=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                                       </tr>

                                                       <tr>
                                                        <td>Defects in dip tube</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.defectIndiptube=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center">{inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.defectIndiptube=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                                       </tr>

                                                       <tr>
                                                        <td>Defects in cleanout ports/nozzles</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.defectInCleanoutPorts=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center">{inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.defectInCleanoutPorts=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                                       </tr>

                                                       <tr>
                                                        <td>Any condition that might render tank unsafe for transportation service </td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.anyConditionThatmightRender=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section1.anyConditionThatmightRender=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                       </tr>

                                                       {/* <tr>
                                                        <td>transportation service</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section2.corroded=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center">{inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section2.corroded=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                                       </tr> */}
                                                 </tbody>
                                              </table>
                                        </div>
                                
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="inspection-wrap">

                                       <div class="table-2 mb-4">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col" style={{ "paddingLeft":"15px", "paddingRight":"15px" }}>Section 2 Tank Shell/Head areas covered by the UC plate, are there any?</th>
                                                    <th scope="col">Yes</th>
                                                    <th scope="col">No</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  
                                                    <tr>
                                                        <td>Corroded or abraded areas</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section2.corroded=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section2.corroded=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                       </tr>  

                                                       <tr>
                                                        <td>Dents</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section2.dents=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center">{inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section2.dents=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                                       </tr>  

                                                       <tr>
                                                        <td>Distortions</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section2.distortions=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center">{inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section2.distortions=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                                       </tr> 
                                                       
                                                       <tr>
                                                        <td>Defects in Welds</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section2.defectsInWelds=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center">{inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section2.defectsInWelds=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                                       </tr>

                                                       <tr>
                                                        <td>Leaks of any kind</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section2.leaksOfAnyKind=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section2.leaksOfAnyKind=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                       </tr>

                                                       <tr>
                                                        <td>Any condition that might render tank unsafe for transportation service                                                        </td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section2.anyConditionThatmightRender=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section2.anyConditionThatmightRender=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                       </tr>

                                                       <tr>
                                                        <td>"Tank marking: Date (month/Year) and service symbol after all defects are corrected. Did you update markings on tank                                                        </td>
                                                        <td class="text-center">{inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section2.tankMarking=='1'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                        <td class="text-center"> {inspectionDetails.internalVisualInspection?inspectionDetails.internalVisualInspection.section2.tankMarking=='0'?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                       </tr>


                                                 </tbody>
                                              </table>
                                        </div>
                                
                                    </div>
                                </div>
                            </div>
                            <div class="divider"></div>
                         
                            <div class="form-footer-comon-wrap">
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                    {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                    <div class="row">
                                        {
                                            documentList ? documentList.map((data, index) => (
                                                <div class="col-md-12">
                                                    <div class="d-flex align-items-center">
                                                        <img src="images/icons/pdf-icon.svg" alt="" />
                                                        <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100">
                                                            {/* <b>{data.title}.{data.extension}</b> */}
                                                                <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                    <img src="" class="mr-2" />
                                                                    <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                        {(() => {
                                                                            if (data.extension == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                        {data.name ? data.name : "--"} </p></span>
                                                                    {/* <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" />  */}
                                                                    </div>
                                                        </a>
                                                        {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                                    </div>
                                                </div>


                                            )) : ""

                                        }
                                    </div>
                                </div><br />
                                   
                                </div>
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                      <h6 class="mb-4">Notes: </h6>
                                      {inspectionDetails?inspectionDetails.notes:''}
                                   </div>
                                   </div>
                                <div class="divider2"></div>
                                <div class="row performby p-4">
                                   <div class="col-lg-12">
                                      <div class="row">
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Test Performed By:</label>
                                               <b>{inspectionDetails.inspectedBy}</b>
                                            </div>
                                         </div>
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Date</label>
                                               <b>{inspectionDetails.inspectedDate}</b>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                            
                                <div class="divider"></div>
                                {(() => {
                                   if(!roles.match("Technician")){
        if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED') {
          return (
            <>
            <div class="result-didspos">
                                   <div class="p-4">
                                      <span>Result</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Inspection Failed</b>
                                      </div>
                                   </div>
                                   <div class="p-4">
                                      <span>Disposition of Tank</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:""}
                                         <b class="mb-0 ml-2">Tank Returned to Service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                      </div>
                                   </div>
                                </div>
                                <div class="divider"></div>
                                <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection}  customerId={fkAccountId}/>
                                {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} status={"readOnly"} /> */}
            </>
          )
        } else {
          return (
            <><div class="result-didspos ">
            <div class="p-4">
               <span>Result</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Approve &amp; Certify</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Inspection Failed</b>
               </div>
            </div>
            <div class="p-4">
               <span>Disposition of Tank</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"/>:""}
                  <b class="mb-0 ml-2">Tank returned to service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank withdrawn from service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank sent to another repair facility</b>
               </div>
            </div>
         </div>
         <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate}  customerId={fkAccountId}/>
         {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} /> */}
         {/* <div class="divider"></div>
         <div class="row verifyby p-4">
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Verified By</label>
                  <input type="text" class="form-control custome-input" id="verifiedBy" value={inspectionDetails.verifiedBy} aria-describedby="emailHelp" placeholder="Enter Name"/>
               </div>
            </div>
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Date</label>
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="verifiedByBate" />
                
               </div>
            </div>
         </div> */}
         
         </>
          )
        }
      }
      })()}
                             </div>

                            <div class="divider"></div>
                            {(() => 
                            {
                                 if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED') 
                                 {
                                 }
                                 else
                                 {
                                   return(<> <div class="row p-3 pr-4 d-flex justify-content-end">
                                   <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-secondary mr-1 ">Cancel</a>
                                       {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a> */}
                                       {roles.match("Technician")?"":<a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a>}
                                   {roles.match("Technician")?<a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-primary mr-1">Back</a>:""}
                                   </div></>)
                                 }
                           })()}
                           </div>
                        </div>
                  </div>
                 
               </div>
              

            </div>
  </>
)};

InternalVisualRead.propTypes = {};

InternalVisualRead.defaultProps = {};

export default InternalVisualRead;
