import React,{history,useEffect, useState, useMemo } from "react";
import Swal from "sweetalert2";
import { useNavigate ,useParams} from "react-router-dom";
import { requestObject } from "../../../../utility/requestObject";
import  $ from "jquery";
import services from "../../../../services/services";
import {useLoading} from 'react-hook-loading';
import DatePicker from "react-datepicker";
import Adddocs from "../../../../utility/Adddocs";
import CheckAuthentication from "../../../../utility/checkAuthentication";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { scrollTop } from "../../../../utility/ScrollTop";
const ExternalVisualSectionFive = () => 
{
  // var section4= {
  //   "corroded":  $("input[name='step4abradedArea']:checked").val()==="1"?"1":"0",
  //   "distortions": $("input[name='step4Distortions']:checked").val()==="1"?"1":"0",
  //   "indicationOferasion": $("input[name='step4erosion']:checked").val()==="1"?"1":"0",
  //   "preventSafeOperation": $("input[name='step4safeoperation']:checked").val()==="1"?"1":"0",
  //   "devicenotfunctioning": $("input[name='step4devices']:checked").val()==="1"?"1":"0"
    
  // };
  // console.log(JSON.stringify(section4));

  // sessionStorage.setItem("section4",JSON.stringify(section4));

  useEffect(() => {

    scrollTop();
    getSection5();

  }, []);

  function getSection5() {

    JSON.parse(sessionStorage.getItem("section5"));

    console.log("ddd" + JSON.parse(sessionStorage.getItem("section5")));

    var obj = JSON.parse(sessionStorage.getItem("section5"))
    console.log(obj == null ? '' : obj.corrodedOrAbradedAreas);

    // setSection1(obj);
    // console.log("section Data" + JSON.stringify(section1));

    var linksMissing = obj == null ? '' : obj.linksMissing;
    var linksloose = obj == null ? '' : obj.linksloose;

    $('input[name="step5missing"][value="' + linksMissing + '"]').trigger("click");
    $('input[name="step5loose"][value="' + linksloose + '"]').trigger("click");

  }

  const sectionSubmit = () => {
    var section5= {
      "linksMissing": $("input[name='step5missing']:checked").val()==="1"?"1":"0",
      "linksloose": $("input[name='step5loose']:checked").val()==="1"?"1":"0"
    };

    console.log(JSON.stringify(section5));

    sessionStorage.setItem("section5",JSON.stringify(section5));

  }


  const handleSubmit=()=>
  {

let form_is_valid = true;


var step5missing=$("input[name='step5missing']:checked").val();
var step5loose=$("input[name='step5loose']:checked").val();


// console.log(step5missing+''+step5loose);

if(step5missing==undefined||step5loose==undefined){
  // $('html, body').animate({
  //   scrollTop: $("#inspectionType").offset().top
  // }, 500);
  $('html,body').animate({ scrollTop: "500px" }, 1000);
  $('#error_message').css("color", "#d33232").html("*Please choose one option for each question");
  form_is_valid= false;

}else{
  $('#error_message').html(''); 
}

if(form_is_valid==true){
$("#section6").trigger('click');
}

return form_is_valid;

if(form_is_valid==true){
  var section5= {
    "linksMissing": $("input[name='step5missing']:checked").val()==="1"?"1":"0",
    "linksloose": $("input[name='step5loose']:checked").val()==="1"?"1":"0"
  };

  console.log(JSON.stringify(section5));

  sessionStorage.setItem("section5",JSON.stringify(section5));
}

}

  return(
  <>

                

                        <div class="card">

                          {/* <div class="divider"></div> */}
                            <div class="row px-3">
                            <div class="w-100">
            <h4 class="form-inspection-title text-center">External / Visual Inspection</h4>
            <p id="error_message"></p>
          </div>
     <WorkOrderNumberDiv />
                                <div class="col-lg-12 px-0">
                                   
                                    <div class="inspection-wrap">

                                        <div class="step-wrap p-3 mb-3">
                                             <div class="step-main d-flex">
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 chk-tick "><img src="/images/icons/tick.svg" alt=""/></div>
                                                     <div class="labl-section">Tank Shell</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt=""/></div>
                                                     <div class="labl-section">External Piping</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt=""/></div>
                                                     <div class="labl-section">Spill Collar</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt=""/></div>
                                                     <div class="labl-section">Emergency Devices</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 current-tick">5</div>
                                                     <div class="labl-section">Bolts</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2">6</div>
                                                     <div class="labl-section">Vehicle Condition</div>
                                                 </div>
                                             </div>
                                        </div>

                                        <div class="table-2">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Section 5: Bolts / Flanges :</th>                                                 
                                                    <th scope="col">Yes</th>
                                                    <th scope="col">No</th>                                              
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                   <td>Are any bolts, nuts or fusible links missing</td>
                                                    <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step5missing" value="1" onChange={() => sectionSubmit()}/></td>
                                                    <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step5missing" value="0" onChange={() => sectionSubmit()}/></td>
                                                  </tr>

                                                  <tr>
                                                    <td>Are any bolts, nuts or fusible links loose</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step5loose" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step5loose" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                </tbody>
                                              </table>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>

                            <div class="divider2"></div>
                            <div class="row p-3 pr-4 d-flex justify-content-end" style={{marginBottom:"-20px"}}>
                            <a id="section4" class="btn btn-secondary mr-1 ">Back</a>
                                {/* <a href="#" class="btn btn-secondary mr-1 ">Next</a>
                                <a href="#" class="btn btn-primary mr-1" onClick={() => save("draft")}>Cancel </a> */}
                                {/* <a  id="section6" class="btn btn-primary mr-1">Next</a> */}
                                <a id="section6" class="btn btn-primary mr-1" style={{display:"none"}}>Next</a>
                                <a  class="btn btn-primary mr-1"  onClick={() => handleSubmit()}>Next</a>
                            </div>
                           </div>
              
  </>
)};

ExternalVisualSectionFive.propTypes = {};

ExternalVisualSectionFive.defaultProps = {};

export default ExternalVisualSectionFive;