import React,{history,useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate ,useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import  $ from "jquery";
import {useLoading} from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import documentDetails_ from "./documentDetails_";
import AddInspectionName from "../../../../utility/addInspectionName";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const WetTestCertificationRead = () => {
   const { fkAccountId, inspectionId,vid } = useParams();
   const [inputList, setInputList] = useState([{ }]);
   const [base64file, setBase64file] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const [ fileName, setfileName ] = useState("");
   const [ inspectionDetails, setInspectionDetails ] = useState("");
   const [ documentList, setDocumentLists ] = useState([]);
   const navigate = useNavigate();
   const [expiryDate, setexpiryDate] = useState(new Date());
   const [endDate, setEndDate] = useState(new Date());
   const [compartments, setcompartments] = useState();
   const [verifiedDATE, setverifiedDate] = useState(new Date());
   const [roles, setRoles] = useState("");
   const [statusInspection, setStatus] = useState("");
   useEffect(() => {
     setRoles(localStorage.getItem("Userroles"));
    
      // vehicle_details_api(id, setVehicleDetails, setCustomerId); 
      getInpection(fkAccountId,inspectionId)
   }, []);

  function getInpection(fkAccountId,inspectionId)
  {
      var requestObjectForWet=
      {
      
        "id":inspectionId
      }

      var requestOptions=requestObject.RequestHeader(requestObjectForWet);

      services.getInspectionDetails(requestOptions).then((res) => 
      {
     
         setInspectionDetails(res.rows);
         setDocumentLists(res.rows.documentList?res.rows.documentList:"");
         sessionStorage.setItem("inspectionId",res.rows.id);
         setcompartments(res.rows.wetTestCertification.compartmentList);
         $("#workOrderNumber").val(res.rows?res.rows.workOrderNumber:"");
         // sessionStorage.setItem("verifiedBy",res.rows ? res.rows.verifiedBy : "");
         $("#inspectedBy").val(res.rows?res.rows.inspectedBy:"");
         setEndDate(new Date(res.rows?res.rows.inspectedDate:""));
         setStatus(res.rows.status);
         if(res.rows)
         {
            if(res.rows.verifiedDate)
            {
               setverifiedDate(new Date(res.rows?res.rows.verifiedDate:''));
            }
            if(res.rows.expiryDate)
            {
               setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
            }
         }
      });

   }
  
   const save=(status)=>
   {
   
   // console.log("results=="+JSON.stringify(inputList));
   if($("#inspectedBy").val()==="")
   {
     Swal.fire({  
         position: 'center',  
         icon: 'error',  
         title: "",  
         text:"Please enter Inspected by",
         showConfirmButton: true 
         
       }).then((result) => 
       {
             
             if(result.isConfirmed) 
             {
               
             
             }
       });
       return false;
   }
   if( $('input:radio[name="returnedservice"]:checked').val()==="undefined"||$('input:radio[name="returnedservice"]:checked').val()===undefined)
   {
     Swal.fire({  
         position: 'center',  
         icon: 'error',  
         title: "",  
         text:"Please select disposition of tank",
         showConfirmButton: true 
         
       }).then((result) => 
       {
             
             if(result.isConfirmed) 
             {
               
             
             }
       });
       return false;
   }
   if( $('input:radio[name="approvedCertify"]:checked').val()===""|| $('input:radio[name="approvedCertify"]:checked').val()===undefined)
   {
     Swal.fire({  
         position: 'center',  
         icon: 'error',  
         title: "",  
         text:"Please select inspection result",
         showConfirmButton: true 
         
       }).then((result) => 
       {
             
             if(result.isConfirmed) 
             {
               
             
             }
       });
       return false;
   }
         if($("input[name='approvedCertify']:checked").val()==="approvedCertify")
         {

         }else{

         status="Rejected";
         }
         var requestObjectInspection=
         {
           "id": inspectionId,
           "expiryDate": $("#expiryDate").val(),
            "verifiedBy": $("#verifiedBy").val(),
           "verifiedDate": $("#verifiedByBate").val(),
           "status": status,
          
           "result": {
            "approvedCertify":$("input[name='approvedCertify']:checked").val()==="approvedCertify"?"1":"0",
            "inspectionFailed": $("input[name='approvedCertify']:checked").val()==="inspectionFailed"?"1":"0"
          },
          "dispositionOfVehicle": {
            "vehicleReturnedServices":$("input[name='returnedservice']:checked").val()==="returnedservice"?"1":"0",
            "vehicleWithdrawnFromService":$("input[name='returnedservice']:checked").val()==="withdrawnservice"?"1":"0",
            "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val()==="repairfacility"?"1":"0"
          }
          
         
       }; 
   
       $("#loadingforsubmit").show();
      //  setLoading(true);
       // const requestOptions = 
       // {
       //   method: 'POST',
       //   headers: { 'Content-Type': 'application/json'
       //             },
                   
       //       body: JSON.stringify(requestObject)
       // };
       var requestOptions=requestObject.RequestHeader(requestObjectInspection);
       services.certifyInspection(requestOptions).then((res) => 
       {
         $("#loadingforsubmit").hide();
         // setLoading(false);
         Swal.fire({  
           position: 'center',  
           icon: 'success',  
           title: "",  
           text:res.message,
           showConfirmButton: true 
           
         }).then((result) => 
         {
               
               if(result.isConfirmed) 
               {
                 navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));
               
               }
         });
       });
   
   }
   const redirect=(page)=>
   {
   
      navigate(page);
   
   }
  return(
  <>
  {LoadingComponentForSubmit}
 <div class="main-panel">
               <div class="content-wrapper" style={{display: "block"}}>
               <div class="row mb-0">
                    <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                       <div class="d-flex justify-content-between align-items-center my-4">
                       <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                       </div>
                    </div>
                 </div>
                  <div class="row mb-2">
                           <div class="col-lg-11 col-md-12 m-auto ">
                           <HeaderBlock cids={fkAccountId} />
                           </div>
                  </div>
                  

                  <div class="row mb-2">
                     <div class="col-lg-11 col-md-12 m-auto ">
                        <div class="card">

                           <div class="row mb-2">
                              <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                                    <VehicleBasicInfo customerId={vid} />
                              </div>
                           </div>
                           
                            <div class="divider"></div>
                            <div class="row p-3">
                                <div class="w-100 px-3">
                                    <h4 class="mb-2 form-inspection-title text-center">WET Test Certification Form</h4>
                                </div>
                                <div class="col-lg-12 "><WorkOrderNumberDiv status={"readOnly"} /></div>
                                <div class="col-lg-12">
                                    <div class="inspection-wrap">

                                        <div class="table-2 mb-4">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col" style={{"width":"50%"}}>Probes Tested and Set Properly </th>
                                                    <th scope="col" style={{"width":"40%"}}>Safe fill amount(GALS)</th>
                                                    <th scope="col">Yes</th>
                                                    <th scope="col">No</th>
                                                  </tr>
                                                </thead>
                                                <tbody>

                                                {
                                                  compartments ? compartments.map((data, index) => (
                                                    <tr>
                                                    <td colSpan={2}>
                                                        <div class="row align-items-center">
                                                            <div class="col-md-3">Compartment #{index+1} </div>
                                                            <div class="col-md-3">Capacity</div>
                                                            <div class="col-md-4 d-flex align-items-center"><input type="text" class="form-control mr-2" id={"comp"+index+"gallons"} aria-describedby="emailHelp" placeholder="Capacity NNNN Gallons" defaultValue={data.safeFillAmountInGals} disabled /><lable>Gallons</lable></div>
                                                         
                                                        </div>
                                                    </td>
                                                   
                                                   { (() => {
                                                       if (data.working==="1") 
                                                       {
                                                       
                                                       return(<><td class="text-center"> <input type="radio" name={"sec"+index+"Distortion"} value="yes" aria-label="Radio button for following text input" checked disabled /></td>
                                                       <td class="text-center">  <input type="radio" name={"sec"+index+"Distortion"}  value="no" aria-label="Radio button for following text input" disabled /></td></>)
                                                       }else 
                                                       {
                                                       return(<><td class="text-center"> <input type="radio" name={"sec"+index+"Distortion"} value="yes" aria-label="Radio button for following text input" disabled /></td>
                                                       <td class="text-center">  <input type="radio" name={"sec"+index+"Distortion"} value="no" aria-label="Radio button for following text input" checked disabled  /></td></>)
                                                       }
                                                      //   if (data.working==="1") 
                                                      //   {
                                                        
                                                      //    return(<><td class="text-center"> <input type="radio" name="yes" aria-label="Radio button for following text input" checked disabled/></td>
                                                      //    <td class="text-center">  <input type="radio" name="yes" aria-label="Radio button for following text input" disabled /></td></>)
                                                      //   }else 
                                                      //   {
                                                      //    return(<><td class="text-center"> <input type="radio" name="yes" aria-label="Radio button for following text input"  disabled/></td>
                                                      //    <td class="text-center">  <input type="radio" name="yes" aria-label="Radio button for following text input" checked disabled /></td></>)
                                                      //   }
                                                       })()}
                                                   
                                                  </tr>
                                                  )) : ""

                                               }
                                               
                                                  {/* <tr>
                                                   <td>
                                                       <div class="row align-items-center">
                                                            <div class="col-md-4">Compartment #1 </div>
                                                            <div class="col-md-4">Gallon Capacity</div>
                                                            <div class="col-md-4">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment1.safeFillAmountInGals:""}</div>
                                                       </div>
                                                   </td>
                                                    <td class="text-center"> {inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment1.working=1?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment1.working=0?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                  </tr>

                                                  <tr>
                                                    <td>
                                                        <div class="row align-items-center">
                                                             <div class="col-md-4">Compartment #2 </div>
                                                             <div class="col-md-4">Gallon Capacity</div>
                                                             <div class="col-md-4">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment2.safeFillAmountInGals:""}</div>
                                                        </div>
                                                    </td>
                                                    <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment2.working=1?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                                    <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment2.working=0?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                                   </tr>

                                                  

                                                   <tr>
                                                    <td>
                                                        <div class="row align-items-center">
                                                             <div class="col-md-4">Compartment #3 </div>
                                                             <div class="col-md-4">Gallon Capacity</div>
                                                             <div class="col-md-4">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment3.safeFillAmountInGals:""}</div>
                                                        </div>
                                                    </td>
                                                    <td class="text-center"> {inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment6.working=1?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                    <td class="text-center"> {inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment6.working=0?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                   </tr>

                                                   <tr>
                                                    <td>
                                                        <div class="row align-items-center">
                                                             <div class="col-md-4">Compartment #4 </div>
                                                             <div class="col-md-4">Gallon Capacity</div>
                                                             <div class="col-md-4">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment4.safeFillAmountInGals:""}</div>
                                                        </div>
                                                    </td>
                                                    <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment4.working=1?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                                    <td class="text-center"> {inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment6.working=0?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                   </tr>

                                                   <tr>
                                                    <td>
                                                        <div class="row align-items-center">
                                                             <div class="col-md-4">Compartment #5 </div>
                                                             <div class="col-md-4">Gallon Capacity</div>
                                                             <div class="col-md-4">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment5.safeFillAmountInGals:""}</div>
                                                        </div>
                                                    </td>
                                                    <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment5.working=1?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                                    <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment5.working=0?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                                   </tr>

                                                   <tr>
                                                    <td>
                                                        <div class="row align-items-center">
                                                             <div class="col-md-4">Compartment #6 </div>
                                                             <div class="col-md-4">Gallon Capacity</div>
                                                             <div class="col-md-4">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment6.safeFillAmountInGals:""}</div>
                                                        </div>
                                                    </td>
                                                    <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment6.working=1?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""} </td>
                                                    <td class="text-center">{inspectionDetails.wetTestCertification?inspectionDetails.wetTestCertification.compartment6.working=0?<img src="/images/icons/tick-gray.svg" style={{maxHeight: "16px"}} alt=""/>:"":""}</td>
                                                   </tr> */}

                                                

                                                  
                                                 </tbody>
                                              </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="divider"></div>
                            <div class="form-footer-comon-wrap">
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                    {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                    <div class="row">
                                        {
                                            documentList ? documentList.map((data, index) => (
                                                <div class="col-md-12">
                                                    <div class="d-flex align-items-center">
                                                        <img src="images/icons/pdf-icon.svg" alt="" />
                                                        <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100">
                                                            {/* <b>{data.title}.{data.extension}</b> */}
                                                                <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                    <img src="" class="mr-2" />
                                                                    <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                        {(() => {
                                                                            if (data.extension == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                        {data.name ? data.name : "--"} </p></span>
                                                                    {/* <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" />  */}
                                                                    </div>
                                                        </a>
                                                        {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                                    </div>
                                                </div>


                                            )) : ""

                                        }
                                    </div>
                                </div><br />
                                   
                                </div>
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                      <h6 class="mb-4">Notes: </h6>
                                      {inspectionDetails?inspectionDetails.notes:''}
                                   </div>
                                 </div>
                                <div class="divider2"></div>
                                <div class="row performby p-4">
                                   <div class="col-lg-12">
                                      <div class="row">
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Test Performed By:</label>
                                               <b>{inspectionDetails.inspectedBy}</b>
                                            </div>
                                         </div>
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Date</label>
                                               <b>{inspectionDetails.inspectedDate}</b>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                            
                                <div class="divider"></div>
                                {(() => {
                                   if(!roles.match("Technician")){
        if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED') {
          return (
            <>
            <div class="result-didspos">
                                   <div class="p-4">
                                      <span>Result</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Inspection Failed</b>
                                      </div>
                                   </div>
                                   <div class="p-4">
                                      <span>Disposition of Tank</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:""}
                                         <b class="mb-0 ml-2">Tank Returned to Service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                      </div>
                                   </div>
                                </div>
                                <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection}  customerId={fkAccountId}/>
                                {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} status={"readOnly"} /> */}
            </>
          )
        } else {
          return (
            <>
           <div class="result-didspos ">
            <div class="p-4">
               <span>Result</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Approve &amp; Certify</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Inspection Failed</b>
               </div>
            </div>
            <div class="p-4">
               <span>Disposition of Tank</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"/>:""}
                  <b class="mb-0 ml-2">Tank returned to service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank withdrawn from service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank sent to another repair facility</b>
               </div>
            </div>
         </div>
         <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate}   customerId={fkAccountId}/>
         {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} /> */}
         {/* <div class="divider"></div>
         <div class="row verifyby p-4">
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Verified By</label>
                  <input type="text" class="form-control custome-input" id="verifiedBy" value={inspectionDetails.verifiedBy} aria-describedby="emailHelp" placeholder="Enter Name"/>
               </div>
            </div>
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Date</label>
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="verifiedByBate" />
                
               </div>
            </div>
         </div> */}
         
         </>
          )
        }
      }
      })()}
                             </div>

                            <div class="divider"></div>
                            {(() => 
                            {
                                 if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED') 
                                 {
                                 }
                                 else
                                 {
                                   return(<> <div class="row p-3 pr-4 d-flex justify-content-end">
                                   <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-secondary mr-1 ">Cancel</a>
                                       {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a> */}
                                       {roles.match("Technician")?"":<a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a>}
                                   {roles.match("Technician")?<a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-primary mr-1">Back</a>:""}
                                   </div></>)
                                 }
                           })()}
                           </div>
                        </div>
                  </div>
                 
               </div>
              


            </div>
  </>
)};

WetTestCertificationRead.propTypes = {};

WetTestCertificationRead.defaultProps = {};

export default WetTestCertificationRead;
