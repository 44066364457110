import React,{history,useEffect, useState, useMemo } from "react";
import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import Pagination from "../../../utility/pagination";
import {useNavigate , useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import $ from "jquery";
import CheckAuthentication from "../../../utility/checkAuthentication";
import moment from 'moment';
import { Driver } from "../../constant";
import Swal from "sweetalert2";
import { SelectAllinspections } from "../customer/CustomerContactList";
import DatePicker from "react-datepicker";
import { loadingComponent } from "../../../utility/Loading";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
import UpdateUpcomingAndoverdue from "../fleet-owner/reminder_utility/UpcomingOverdue";
import vehicle_details_api from "../vehicle/vehicle_details_api";

const FleetOwnerDashboard = () => 
{
    var roles = localStorage.getItem("Userroles");
    const [vechileDetails, setVehicleDetails] = useState([]);
    const[customerName,setCustomerName]=useState("");
    const[inspectionCuntType,setinspectionCountType]=useState("");
    const[dashboardAggregationData,setdashBoardAgreggation]=useState("");
    const[staffAndEmp,setstaff]=useState("");
    const[upcoming,setupcoming]=useState("");
    const[dues,setdues]=useState("");
    const [displayDocsPage, setdisplayDocsPage] = useState([]);
    const navigate = useNavigate();

    const [ITEMS_PER_PAGE, setitemperpage] = useState(25);
    const [ITEMS_PER_PAGE1, setitemperpage1] = useState(25);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalpages, settotalPages] = useState();
    const [displayPage, setdisplayPage] = useState([]);
    const [DashboardAggregation, setgetDashboardAggregation] = useState([]);
   
    // const [currentDocsPage, setCurrentDocsPage] = useState(5);
    // const [totaldocspages, settotalDocsPages] = useState();
    const [totalRecors,setTotalRecord] = useState([]);
    // const [totalDocsItems, setTotalDocsItems] = useState(0);
   
    const [ITEMS_PER_PAGE_DOCS, setitemperdocspage] = useState(25);

    const [value, setValue] = useState("");
    const [Id, setIspectionId] = useState()
    const [notes, setNotes] = useState();
    const [originalDate, setOriginalDate] = useState(new Date());
    const [orderId, setOrderId] = useState([]);
    const [customerId, setCustomerIds] = useState();
    const [cid, setCustomerId] = useState([]);
    const [vehicleId, setVehicleId] = useState();
    const [vendorId, setvendorId] = useState()
    var inspectionArray = [];
    var inspectionData = [];
    var inspect = {};
    var inspectionJson = [];
    const [inspectiondisable, setInspecchecked] = useState([]);
    const [inspectionStatus, setInspecStatus] = useState([]);
    const [inspectionTypes, setInspectionTypes] = useState();
    const [inspectionNames, setInspecName] = useState([]);
    const [garageId, setgarageId] = useState([{}]);
    const [InspectionTypesStatus, setInspectionTypesStatus] = useState([]);
    const [inspectionNamearray, setInspecNamearray] = useState([]);

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });

    useEffect(() => 
    {
        $("#loadingforsubmit").hide();
        sessionStorage.removeItem("resolveAll");
        sessionStorage.removeItem("resolveSignleInspection");
        sessionStorage.removeItem("reminderDocumentId");
        
        if(CheckAuthentication)
        {
            var customerName= sessionStorage.getItem("customerName");
            setCustomerName(customerName);
    
            getTopTenReminders();
    
            FleetOwnerDashboardAgression();
            accountType();
            driverrole();
             if(localStorage.getItem("Userroles").match(Driver))
                {
                    navigate("/fleetowner/Vehicle");

                }
        }
        else
        {
            sessionStorage.clear();
            localStorage.clear();
           //navigate("/login");
             window.location.href="/login";
        }

    }, []); 

    const driverrole=()=>
    {
       var vechileReq=
        {
             
             
           
        }
    
        var requestOptions=requestObject.RequestHeader(vechileReq);
        services.DriverRole(requestOptions).then((res) => 
        {
            if(res.rows)
            {
                for(var i=0;i<res.rows.length;i++)
                {
                    if(res.rows[i].name==="Driver")
                    {
                        sessionStorage.setItem("driverRoleId",res.rows[i].id);
                        sessionStorage.setItem("driverRoleName",res.rows[i].name);
        
                    }


                }
           
            }
          
    
           
        });

    }
    const FleetOwnerDashboardAgression=()=>
    {
       var vechileReq=
        {
             
             
           
        }
    
        var requestOptions=requestObject.RequestHeader(vechileReq);
        services.getFleetDashBoardAggregations(requestOptions).then((res) => 
        {
          
          
            setgetDashboardAggregation(res.customValues?res.customValues:"");
    
           
        });

    }

    
    const vehicleInfo = (id,documentId,inspectionName) => {

        localStorage.setItem("vehicleId",id);
       // redirect("/fleetowner/VehicleInformation/" + data.id,'')
        navigate("/fleetowner/AssignToGarrage/" + id);
        sessionStorage.setItem("resolveAll", "true");
        sessionStorage.setItem("resolveSignleInspection", "true");
        sessionStorage.setItem("selectedinspectionName",inspectionName);
        sessionStorage.setItem("reminderDocumentId", documentId);
    }
   
    const redirect=(page,slected)=>
    {
        sessionStorage.setItem("selected",slected);   
        sessionStorage.setItem("naviationfrom",slected);   
        navigate(page);
    
    }
    
      /////////////////docs pagination////////////////////////////
    const navigateTabSelected = (slected) => 
    {

            sessionStorage.setItem(slected,"selected");    
        
        
    }
    const getTopTenReminders=()=>
    {
       var vechileReq=
        {
            "pageNumber": "1",
            "pageSize": '5',
            "vinNumber": "",
            "isActive": true   
           
        }
    
        var requestOptions=requestObject.RequestHeader(vechileReq);
        services.listReminderVehicles(requestOptions).then((res) => 
        {
          
            if(res.success)
            {

              setTotalItems(res.customValues.totalNumberOfRecords);
            
              settotalPages(res.customValues.totalNumberOfRecords);
             
              setCurrentPage(res.customValues.currentPageNumber);
              let temarray = [];
              let parentJson = [];
              if (res.rows.length > 0) {
  
                  // setDue(res.rows);
  
  
  
                  for (var i = 0; i < res.rows.length; i++) {
  
                    
                      let hashData = new Map();
  
                      if (res.rows[i].documentList === null || res.rows[i].documentList.length == 0) 
                      {
  
                            res.rows[i].inspectionList.concat(res.rows[i].inspectionList).forEach(function (obj) 
                            {
                                hashData.set(obj.documentSubCategoryName, Object.assign(hashData.get(obj.documentSubCategoryName) || {}, obj))
                            });
                            const finalinspectionData = Array.from(hashData.values());
                           parentJson.push({ "assignedDate": res.rows[i].assignedDate, "id": res.rows[i].id, "unitNumber": res.rows[i].unitNumber, "vehicleId": res.rows[i].vehicleId, "vendorId": res.rows[i].vendorId, "vendorName": res.rows[i].vendorName, "vinNumber": res.rows[i].vinNumber, "workOrderNumber": res.rows[i].workOrderNumber, "mergedocumentList": finalinspectionData, "documentList": res.rows[i].documentList, "inspectionList": res.rows[i].inspectionList,"notes":res.rows[i].notes,"status":res.rows[i].status })
  
                      } else if (res.rows[i].inspectionList === null || res.rows[i].inspectionList.length == 0) 
                      {

                         res.rows[i].documentList.concat(res.rows[i].documentList).forEach(function (obj) 
                         {
                            hashData.set(obj.documentSubCategoryName, Object.assign(hashData.get(obj.documentSubCategoryName) || {}, obj))
                           });
                          const finalDocumentData = Array.from(hashData.values());

                          parentJson.push({ "assignedDate": res.rows[i].assignedDate, "id": res.rows[i].id, "unitNumber": res.rows[i].unitNumber, "vehicleId": res.rows[i].vehicleId, "vendorId": res.rows[i].vendorId, "vendorName": res.rows[i].vendorName, "vinNumber": res.rows[i].vinNumber, "workOrderNumber": res.rows[i].workOrderNumber, "mergedocumentList": finalDocumentData, "documentList": res.rows[i].documentList, "inspectionList": res.rows[i].inspectionList,"notes":res.rows[i].notes,"status":res.rows[i].status })
                      }
                      else 
                      {
  
  
                          res.rows[i].documentList.concat(res.rows[i].inspectionList).forEach(function (obj) {
                              hashData.set(obj.documentSubCategoryName, Object.assign(hashData.get(obj.documentSubCategoryName) || {}, obj))
                          });
                          const finalData2 = Array.from(hashData.values());
                          // setdocuments(finalData2);
                          temarray.push(finalData2);
  
                          parentJson.push({ "assignedDate": res.rows[i].assignedDate, "id": res.rows[i].id, "unitNumber": res.rows[i].unitNumber, "vehicleId": res.rows[i].vehicleId, "vendorId": res.rows[i].vendorId, "vendorName": res.rows[i].vendorName, "vinNumber": res.rows[i].vinNumber, "workOrderNumber": res.rows[i].workOrderNumber, "mergedocumentList": finalData2, "documentList": res.rows[i].documentList, "inspectionList": res.rows[i].inspectionList,"notes":res.rows[i].notes,"status":res.rows[i].status})
                      }
  
                      //  var arr = inspectionLists.concat(docslist);
                      // console.log("results=="+JSON.stringify(arr))
                      // var sorted_arr = arr.sort();
                      // var results = [];
                      // for (var i = 0; i < arr.length - 1; i++) {
                      //     if (sorted_arr[i + 1] == sorted_arr[i]) {
                      //         results.push({"documentSubCategoryName":arr[i].documentSubCategoryName,"expiryDate":arr[i].expiryDate,"id":arr[i].id});
                      //     }
                      // }
                  
                      //const myTimeout = setTimeout( setdocuments(temarray), 5000);
                      //    setdocuments(temarray);
                      //   setdocuments(temarray);
                      setdashBoardAgreggation(parentJson);
                  }
              } else {
  
  
                setdashBoardAgreggation("");
              }
             
              setTotalRecord(res.customValues.totalNumberOfRecords);
        
              var displaypages=[];
            
              
                      for(var i=1;i<=parseInt(Math.ceil(res.customValues.totalNumberOfRecords/ITEMS_PER_PAGE));i++)
                      {
                       
                        displaypages.push(i*ITEMS_PER_PAGE);
                      }
                      
                      setdisplayPage(displaypages);
              
                    }
          
    
           
        });

    }

    const accountType = () => 
    {
        var customerListReq =
        {
  
  
  
        };
  
        var requestOptions = requestObject.RequestHeader(customerListReq);
        services.getAccountType(requestOptions).then((res) => 
        {
           
            if (res.customValues) {
                $("#fleetcompanyLogo").attr("src", "data:image/png;base64," + res.customValues.fileByte);
             }
            // if(res.customValues)
            // {
            //     try{
            //         sessionStorage.setItem("fleetCompanyLogo",res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/fleeto-icon.png" );
            //         $("#fleetCompanyLogo").attr("src","data:image/png;base64,"+res.customValues.fileByte);
            //     }
            //     catch(error)
            //     {
                

            //     }
               
            // }else{
            //     try
            //     {
            //         sessionStorage.setItem("fleetCompanyLogo", "/images/fleeto-icon.png" );
            //     }catch(error)
            //     {
                

            //     }
               
            // }
            sessionStorage.setItem("comapnyName",res.rows ? res.rows.name:"");
            $("#comapnyName").html(res.rows ? res.rows.name:"")
            // if(res.customValues)
            // {
               
            //     try{
            //         alert("if"+res.customValues.fileByte)
            //         sessionStorage.setItem("fleetCompanyLogo",res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/fleeto-icon.png" );
            //         $("#fleetCompanyLogo").attr("src",res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/fleeto-icon.png");
            //         // $("#fleetCompanyLogo").attr("src","data:image/png;base64,"+res.customValues.fileByte);
            //     }
            //     catch(error)
            //     {
                

            //     }
               
            // }else{
            //     try
            //     {
            //         sessionStorage.setItem("fleetCompanyLogo", "/images/fleeto-icon.png" );
            //     }catch(error)
            //     {
                

            //     }
               
            // }
            sessionStorage.setItem("comapnyName",res.rows ? res.rows.name:"");
            $("#comapnyName").html(res.rows ? res.rows.name:"")
            if(res.customValues)
            {
                if(res.customValues.fileByte==="")
                {
                   
                    $("#fleetcompanyLogo").attr("src","/images/fleeto-icon.png");
                  
                }else
                {
                    try
                    {
                       sessionStorage.setItem("fleetCompanyLogo",res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/fleeto-icon.png" );
                       $("#fleetcompanyLogo").attr("src","data:image/png;base64,"+res.customValues.fileByte);
                    } 
                    catch(error)
                    {
                    
       
                    }
                }

            }
  
           
  

  
        });
    }

    var array = [];
    var arrayinspection = [];
    function sort_by_key(array, key) {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }
    const cancel = () => {
        setInspecchecked([]);
    }
    function unique(obj) {
        var uniques = [];
        var stringify = {};
        for (var i = 0; i < obj.length; i++) {
            var keys = Object.keys(obj[i]);
            keys.sort(function (a, b) { return a - b });
            var str = '';
            for (var j = 0; j < keys.length; j++) {
                str += JSON.stringify(keys[j]);
                str += JSON.stringify(obj[i][keys[j]]);
            }
            if (!stringify.hasOwnProperty(str)) {
                uniques.push(obj[i]);
                stringify[str] = true;
            }
        }
        return uniques;
    }

    const closeModalPopup = () => {

        document.getElementById("closeModal").click();
    }

    ////////// unchecked function/////////

    var k=0;
    const Unchecked=(inspectionId,inspectionName)=>{

        if (inspectionNames[k] == inspectionName) {
        // alert(inspectionNames[k]);
                inspect.id = inspectionId;
                inspect.name = inspectionName;
                inspect.status = 'checked'
                inspectionJson.push({ ...inspect });
        }else{

            if (inspectionNames[k] == undefined) {
                inspect.id = inspectionId;
                inspect.name = inspectionNames[k];
                inspect.status = 'unchecked'
                inspectionJson.push({ ...inspect });
            } else {

                inspect.id = inspectionId;
                inspect.name = inspectionName;
                inspect.status = 'unchecked'
                inspectionJson.push({ ...inspect });
            }
            // inspect.id = inspectionId;
            // inspect.name = inspectionName;
            // inspect.status = 'false'
            // inspectionJson.push({ ...inspect });
        }
        k++;
     }

    ////////// exit unchecked function/////////
    const validateDate = () => {
        let form_is_valid = true;



        var garage = $(".react-autosuggest__input").val();
        if (garage == '') {
            $('#error-message-garage').css("color", "#d33232").html("*Garage is required");
            form_is_valid = false;
        } else {
            $('#error-message-garage').html('');
        }

        var assignDate = $("#assignDate").val();
        if (assignDate == '') {
            $('#error-message').css("color", "#d33232").html("*Assign date is required");
            form_is_valid = false;
        } else {
            $('#error-message').html('');
        }



        // $('input:checkbox[name=inspectionIds]:checked').each(function () {
        //     // alert($(this).next('span').text());
        //     inspectionArray.push($(this).val());
        //     inspectionData.push($(this).next('span').text());
        //     // inspectionData.push($(this).text().toString())
        //     inspect.id = $(this).val();
        //     inspect.name = $(this).next('span').text().split(' ').join(' ').toString();
        //     // inspect.name = $(this).next("label").text().split(' ').join(' ').toString();
        //     inspectionJson.push({ ...inspect });

        // });

        /////////////////////Canceled by customer function/////////////////
        var count = 0;
        $('input:checkbox[name=inspectionIds]:checked').each(function () {

            // console.log(JSON.stringify(inspectiondisable))
            // console.log(inspectionNames[count]);
            // console.log("compare -"+ $(this).next('span').text());

            inspectionArray.push($(this).val());
            inspectionData.push($(this).next('span').text());
            // inspectionData.push($(this).text().toString())
            // if(inspectiondisable[count].name.match($(this).next('span').text())){
            if (inspectionNames[count] == $(this).next('span').text()) {

                inspect.id = $(this).val();
                inspect.name = $(this).next('span').text().split(' ').join(' ').toString();
                inspect.status = 'checked'
                inspectionJson.push({ ...inspect });
            } else if(inspectionNames[count] != $(this).next('span').text()){
                inspect.id = $(this).val();
                inspect.name = $(this).next('span').text();
                inspect.status = 'checked'
                inspectionJson.push({ ...inspect });
            }
            else{
                if (inspectionNames[count] == undefined) {
                    inspect.id = $(this).val();
                    inspect.name = $(this).next('span').text();
                    inspect.status = 'checked'
                    inspectionJson.push({ ...inspect });
                } else {
                    inspect.id = $(this).val();
                    inspect.name = inspectionNames[count];
                    inspect.status = 'unchecked'
                    inspectionJson.push({ ...inspect });
                }
            }
            count++;
        });
        /////////////////////Ending Canceled by customer function/////////////////






        if (inspectionArray.length > 0) {

            // $('#error-messageIn').html('');
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Please choose the atleast one inspection",
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {
                }
            });
            // $('#error-messageIn').css("color", "#d33232").html("*Inspection is required");
            form_is_valid = false;
        }



        return form_is_valid;
    }
    const submitValue = () => {

        if (validateDate()) {


            closeModalPopup();
            $("#loadingforsubmit").show();

            var vechileReq =
            {
                "id": Id,

                "vendorId": vendorId,
                "vehicleId": vehicleId,
                // "inspectionIdList":[1],
                "inspectionIdList": inspectionArray,
                "inspectionData": JSON.stringify(inspectionJson),
                // "workOrderNumber": $("#workOrderNumber").val() != '' ? $("#workOrderNumber").val() : '',
                "assignDate": $("#assignDate").val() != '' ? moment($("#assignDate").val()).format('YYYY-MM-DD') : '',
                "notes": $("#notes").val() != '' ? $("#notes").val() : ''



                //    "imageName":cropper.getCroppedCanvas()?parseInt(Math.random() * 1000000000):"",
                //    "imageExtension":cropper.getCroppedCanvas()?"png":"",
                //    "imageByteString":cropper.getCroppedCanvas()?cropper.getCroppedCanvas({  minWidth: 240,  minHeight: 240,  maxWidth: 350,  maxHeight: 350}).toDataURL('image/jpeg').replace("data:image/jpeg;base64,", ""):"",
                //    "documentList": documentList
            }

            var requestOptions = requestObject.RequestHeader(vechileReq);
            services.Updateassigntogarage(requestOptions).then((res) => {
                $("#loadingforsubmit").hide();
                if (res.message == "Successfully assigned Vehicle") {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: "",
                        text: res.message,
                        showConfirmButton: true

                    }).then((result) => {

                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                } else {

                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: "",
                        text: res.message,
                        showConfirmButton: true

                    }).then((result) => {

                        if (result.isConfirmed) {
                        }
                    });

                }

            });

        }

    }
    const updateTask = (workorder, inspectionData, assignedDate, Inspectionid, vendorName, vehicleId, vendorId,notes) => 
    {

        var vehicle_request=
        {
             
                    "id":vehicleId
                
        };
        
       var requestOptions=requestObject.RequestHeader(vehicle_request);

        services.getVehicle_Details(requestOptions).then((res) => 
        {
            
            sessionStorage.setItem("tankerSubTypeName",res.rows.trailerSubTypeName);
            sessionStorage.setItem("vehicleTypeName",res.rows.vehicleTypeName);
            
           updateData(workorder, JSON.parse(res.rows.inspectionData), assignedDate, Inspectionid, vendorName, vehicleId, vendorId,notes)
              
        });

    }
    const updateData=(workorder, inspectionData, assignedDate, Inspectionid, vendorName, vehicleId, vendorId,notes)=>
    {
        $("#notes").val(notes);
        $("#garageName").val(vendorName);
         $("#workOrderNumber").val(workorder);
        // vehicle_details_api(vehicleId, setVehicleDetails, setCustomerId);
        setInspecStatus(inspectionStatus);
        setvendorId(vendorId)
        setVehicleId(vehicleId);
        setIspectionId(Inspectionid);
        setNotes(notes ? notes : "");
        setInspectionTypes("");
        setInspecName("");
        setValue(vendorName);
  
        $("#updateworkorder").val(workorder ? workorder : "")
        for (var key in inspectionData) {
            array.push(inspectionData[key].name)
            setInspecName(array);
        }
  
  
        arrayinspection.push(sort_by_key(inspectionData), 'name');
  
  
  
  
        // setInspecStatus(inspectionStatus);
        // setCustomerName(customerName);
        var request =
        {
  
        };
  
  
        var requestOptions = requestObject.RequestHeader(request);
  
        var arrayPay = [];
        var inspectionNames = new Array();
        services.getInspectionTypeS(requestOptions).then((res) => {
  
  
            setInspectionTypesStatus(res.rows);
            var dummayarray = res.rows;
  
            var tempArray = [];
  
            for (var i = 0; i < dummayarray.length; i++) {
  
                tempArray.push({
                    "id": dummayarray[i].id, "name": dummayarray[i].name,
                    "status": null
                })
  
            }
  
  
  
  
  
            var confirmedList = tempArray.filter((a) => {
  
                return !inspectionData.find((p) => p.documentSubCategoryName === a.name);
  
  
            });
  
  
            setInspecNamearray(confirmedList);
  
  
  
            setInspectionTypesStatus(confirmedList);
            // for(var i=0;i<res.rows.length;i++)
            // {
            //    var booktemp = {
            //       "id" : res.rows[i].id,
  
            //       "name" :  res.rows[i].name,
            //       "status":
            //    };
            // }
  
            for (var i = 0; i < sort_by_key(res.rows, 'name').length; i++) {
                for (var j = 0; j < sort_by_key(inspectionData, 'documentSubCategoryName').length; j++) {
  
                    if (res.rows[i].name === inspectionData[j].documentSubCategoryName) {
                        var inspection =
                        {
                            "id": res.rows[i].id,
  
                            "name": res.rows[i].name,
                            "status": inspectionData[j].inspectionStatus
                        }
  
                        inspectionNames.push(inspection);
                    } else {
  
                    }
  
                }
  
                // arrayinspection.push(inspectionData[i].inspectionStatus)
  
  
  
            }
            var dummayarray = res.rows;
            var tempArray = [];
            for (var i = 0; i < dummayarray.length; i++) {
  
                // const rr=dummayarray[i].name;
                for (var j = 0; j < sort_by_key(inspectionData, 'documentSubCategoryName').length; j++) {
  
                    if (res.rows[i].name === inspectionData[j].documentSubCategoryName) {
  
                        tempArray.push({ "id": dummayarray[i].id, "name": dummayarray[i].name, "status": inspectionData[j].inspectionStatus })
                    }
                    else {
  
                        //tempArray.push({"id":dummayarray[i].id,"name":dummayarray[i].name, "status":null})
  
                    }
  
                }
  
  
            }
  
            // tempArray.push(confirmedList);
            var output = confirmedList.concat(tempArray);
  
            const set = new Set(output.map(item => JSON.stringify(item)));
            const dedup = [...set].map(item => JSON.parse(item));
            //  console.log(`Removed ${tempArray.length - dedup.length} elements`);
            // console.log(dedup);
  
            setInspecchecked(unique(dedup));
            dedup.filter((obj, pos, arr) => {
                return arr.map(mapObj =>
                    mapObj.name).indexOf(obj.name) == pos;
            });
  
            // removeDupliactes(standardsList)
  
  
        });
  
  
        //getInspectionType(setInspectionTypes);
  
        setOriginalDate(assignedDate ? new Date(assignedDate) : "");
        // setVehicleId(vehicleId);
        setCustomerId(customerId);
        setOrderId(orderId);
    }
    // const updateTask = (workorder, inspectionData, assignedDate, Inspectionid, vendorName, vehicleId, vendorId,notes) => {

    //     $("#notes").val(notes);
    //     $("#garageName").val(vendorName);
    //     // vehicle_details_api(vehicleId, setVehicleDetails, setCustomerId, setLoading, setCustomerContactList);
    //     setInspecStatus(inspectionStatus);
    //     setvendorId(vendorId)
    //     setVehicleId(vehicleId);
    //     setIspectionId(Inspectionid);
    //     setNotes(notes ? notes : "");
    //     setInspectionTypes("");
    //     setInspecName("");
    //     setValue(vendorName);
    //     $("#updateworkorder").val(workorder ? workorder : "")
    //     for (var key in inspectionData) 
    //     {
    //         array.push(inspectionData[key].documentSubCategoryName)
    //         setInspecName(array);
    //     }


    //     arrayinspection.push(sort_by_key(inspectionData, 'documentSubCategoryName'));




    //     // setInspecStatus(inspectionStatus);
    //     // setCustomerName(customerName);
    //     var request =
    //     {

    //     };


    //     var requestOptions = requestObject.RequestHeader(request);

    //     var arrayPay = [];
    //     var inspectionNames = new Array();
    //     services.getInspectionTypeS(requestOptions).then((res) => 
    //     {


    //         setInspectionTypesStatus(res.rows);
    //         var dummayarray = res.rows;

    //         var tempArray = [];

    //         for (var i = 0; i < dummayarray.length; i++) {

    //             tempArray.push({
    //                 "id": dummayarray[i].id, "name": dummayarray[i].name,
    //                 "status": null
    //             })

    //         }





    //         var confirmedList = tempArray.filter((a) => {

    //             return !inspectionData.find((p) => p.documentSubCategoryName === a.name);


    //         });


    //         setInspecNamearray(confirmedList);



    //         setInspectionTypesStatus(confirmedList);
    //         // for(var i=0;i<res.rows.length;i++)
    //         // {
    //         //    var booktemp = {
    //         //       "id" : res.rows[i].id,

    //         //       "name" :  res.rows[i].name,
    //         //       "status":
    //         //    };
    //         // }

    //         for (var i = 0; i < sort_by_key(res.rows, 'name').length; i++) {
    //             for (var j = 0; j < sort_by_key(inspectionData, 'documentSubCategoryName').length; j++) {

    //                 if (res.rows[i].name === inspectionData[j].documentSubCategoryName) {
    //                     var inspection =
    //                     {
    //                         "id": res.rows[i].id,

    //                         "name": res.rows[i].name,
    //                         "status": inspectionData[j].inspectionStatus,
    //                         "inspectionStatus":inspectionData[j].status
    //                     }

    //                     inspectionNames.push(inspection);
    //                 } else {

    //                 }

    //             }

    //             // arrayinspection.push(inspectionData[i].inspectionStatus)



    //         }
    //         var dummayarray = res.rows;
    //         var tempArray = [];
    //         for (var i = 0; i < dummayarray.length; i++) {

    //             // const rr=dummayarray[i].name;
    //             for (var j = 0; j < sort_by_key(inspectionData, 'documentSubCategoryName').length; j++) {

    //                 if (res.rows[i].name === inspectionData[j].documentSubCategoryName) {

    //                     tempArray.push({ "id": dummayarray[i].id, "name": dummayarray[i].name, "status": inspectionData[j].inspectionStatus, "inspectionStatus":inspectionData[j].status })
    //                 }
    //                 else {

    //                     //tempArray.push({"id":dummayarray[i].id,"name":dummayarray[i].name, "status":null})

    //                 }

    //             }


    //         }

    //         // tempArray.push(confirmedList);
    //         var output = confirmedList.concat(tempArray);

    //         const set = new Set(output.map(item => JSON.stringify(item)));
    //         const dedup = [...set].map(item => JSON.parse(item));
    //         //  console.log(`Removed ${tempArray.length - dedup.length} elements`);
    //         // console.log(dedup);


    //         dedup.filter((obj, pos, arr) => {
    //             return arr.map(mapObj =>
    //                 mapObj.name).indexOf(obj.name) == pos;
    //         });
    //         setInspecchecked(unique(dedup));
    //         // removeDupliactes(standardsList)


    //     });


    //     //getInspectionType(setInspectionTypes);

    //     setOriginalDate(assignedDate ? new Date(assignedDate) : "");
    //     // setVehicleId(vehicleId);
    //     setCustomerId(customerId);
    //     setOrderId(orderId);
    // }
  
  return(
  <>
              {LoadingComponentForSubmit}
     <div class="main-panel">
                <div class="content-wrapper">
                    <div class="row">
                        <div class="col-md-12 grid-margin">
                            <div class="row">
                                <div class="col-12 col-xl-8 mb-xl-0">
                                    {/* <h3 class="font-weight-bold">{customerName}</h3> */}
                                    {/* <h6 class="font-weight-normal mb-0"><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry</span>
                                    </h6> */}
                                </div>
                            </div>
                        </div>
                    </div>
                 
                        {/* <div class="col-lg-4 col-md-6 mb-4">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="card-title mb-0 text-uppercase">Inspections Reminder</p>
                                        <span class="fleet-dash-count">{upcoming+dues}</span>
                                    </div>
                                    <div class="t-item-wrap">
                                        <div class="t-item text-center ">
                                            <h4>{upcoming}</h4>
                                            <span class="text-uppercase">
                                                upcoming
                                            </span>
                                        </div>
                                        <div class="t-item text-center ">
                                            <h4 class="red-text">{dues}</h4>
                                            <span class="text-uppercase">
                                                dues
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div class="col-lg-4 col-md-6 mb-4">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="card-title mb-0 text-uppercase">Staff/Employess</p>
                                        <span class="fleet-dash-count">37</span>
                                    </div>
                                    <div class="t-item-wrap">
                                        <div class="t-item text-center ">
                                            <h4>04</h4>
                                            <span class="text-uppercase">
                                                dispatchers
                                            </span>
                                        </div>
                                        <div class="t-item text-center ">
                                            <h4>33</h4>
                                            <span class="text-uppercase">
                                                drivers
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div class="col-lg-4 col-md-6 mb-4">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="card-title mb-0 text-uppercase">Staff/Employess</p>
                                      <span class="fleet-dash-count">{staffAndEmp?staffAndEmp.length:""}</span> 
                                    </div>
                                    <div class="t-item-wrap">
                                    {
                                          staffAndEmp?staffAndEmp.map((data, index) => (
                                          <div class="t-item text-center ">
                                            <h4>{data.noOfUsers}</h4>
                                            <span class="text-uppercase">
                                            {data.securityRoleName}
                                            </span>
                                          </div>

                                           )):<div class="no-data"><span class="mt-2">No Data</span>
                                          </div>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div> */}
                          <div class="row mb-4">
                        <div class="col-lg-12">
                            <div class="dash-grid">
                               <a onClick={() => redirect("/fleetowner/reminder",'overDue')} class="db-hvr" >
                                    <div class="grid-item-db" style={{backgroundColor: "#FACE7F"}}>
                                        <div class="grid-db-icon"><img src="/images/icons/db/db-03.png" alt=""/></div>
                                        <div class="grid-bottom-db">
                                            <span class="count-db">{DashboardAggregation.DUE_INSPECTION_VEHICLE_COUNT}</span>
                                            <span class="count-label">Overdue</span>
                                        </div>
                                    </div>
                                </a>
                                <a  class="db-hvr" onClick={() => redirect("/fleetowner/reminder",'')}>
                                    <div class="grid-item-db" style={{backgroundColor: "#9FC088"}}>
                                        <div class="grid-db-icon"><img src="/images/icons/db/upcoming.png" alt=""/></div>
                                        <div class="grid-bottom-db">
                                            <span class="count-db">{DashboardAggregation.UPCOMING_INSPECTION_VEHICLE_COUNT}</span>
                                            <span class="count-label">Upcoming</span>
                                        </div>
                                    </div>
                                </a>
                                <a  class="db-hvr" onClick={() => redirect("/fleetowner/reminder",'inprocess')}>
                                    <div class="grid-item-db" style={{backgroundColor: "#E4CDA7"}} >
                                        <div class="grid-db-icon"><img src="/images/icons/db/in-progress.png" alt=""/></div>
                                        <div class="grid-bottom-db">
                                            <span class="count-db" >{DashboardAggregation.INPROCESS_INSPECTION_VEHICLE_COUNT}</span>
                                            <span class="count-label">Inprocess</span>
                                        </div>
                                    </div>
                                </a>
                                <a  class="db-hvr" onClick={() => redirect("/fleetowner/reminder",'completed')}>
                                    <div class="grid-item-db" style={{backgroundColor: "#FE8F8F"}} >
                                        <div class="grid-db-icon"><img src="/images/icons/db/completed.png" alt=""/></div>
                                        <div class="grid-bottom-db">
                                            <span class="count-db" >{DashboardAggregation.COMPLETED_INSPECTION_VEHICLE_COUNT}</span>
                                            <span class="count-label">Completed</span>
                                        </div>
                                    </div>
                                </a>
                              
                                <a  class="db-hvr" onClick={() => redirect("/fleetowner/Vehicle",'fleetVehicle')}>
                                    <div class="grid-item-db" style={{backgroundColor: "#90AACB"}}>
                                        <div class="grid-db-icon"><img src="/images/icons/db/db-05.png" alt=""/></div>
                                        <div class="grid-bottom-db">
                                            <span class="count-db" >{DashboardAggregation.VEHICLE_COUNT}</span>
                                            <span class="count-label">Vehicles</span>
                                        </div>
                                    </div>
                                </a>
                               
                                <a  class="db-hvr" onClick={() => redirect("/fleetowner/Vehicle",'fleetDocument')}>
                                    <div class="grid-item-db" style={{backgroundColor: "#D8B6A4"}}>
                                        <div class="grid-db-icon"><img src="/images/icons/db/db-06.png" alt=""/></div>
                                        <div class="grid-bottom-db">
                                            <span class="count-db" >{DashboardAggregation.DOCUMENT_COUNT}</span>
                                            <span class="count-label">Documents</span>
                                        </div>
                                    </div>
                                </a>

                            </div>
                        </div>
                    </div>
                 
                    <div class="row mb-4">
                        <div class="col-md-12 grid-margin stretch-card mb-0">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center mb-4">
                                        <p class="card-title mb-0 text-uppercase">Upcoming / Overdue</p>
                                        {/* <span class="fleet-dash-count">4</span> */}
                                        <a  onClick={() => redirect("/fleetowner/reminder",'')} class="btn btn-outline-primary text-uppercase">View ALL</a>
                                    </div>
                                    <div class="table-responsive  border-bottom">
                                    <table className="w-100 hr-pop">
                                                                {/* <tr className="border-bottom py-3">
                                                            <th style={{width:"33%", padding:"8px 8px 8px 24px"}}>Unit Number</th>
                                                            <th style={{width:"33%", padding:"8px"}}>VIN Number</th>

                                                            <th style={{width:"14%", padding:"8px"}}>Assigned Date</th>
                                                            <th style={{width:"150px", padding:"8px"}}>Actions</th>

                                                        </tr> */}
                                                                {
                                                                    dashboardAggregationData ? dashboardAggregationData.map((data, index) => (
                                                                        <tr>
                                                                            <td colSpan={5} className="px-3">
                                                                                <div className="w-100 bg-rem-grd">
                                                                                    <div className="top-rem-unit-vin">
                                                                                        <div className="d-flex">
                                                                                            <label htmlFor="" className="text-uppercase mb-0" style={{width:"40px"}}>Unit: </label>
                                                                                            <span>{data.unitNumber ? data.unitNumber : "--"}</span>
                                                                                        </div>
                                                                                        <div className="d-flex">
                                                                                            <label htmlFor="" className="text-uppercase mb-0" style={{width:"34px"}}>Vin: </label>
                                                                                            <span>{data.vinNumber ? data.vinNumber : "--"}</span>
                                                                                        </div>
                                                                                        <div style={{ width: "150px" }} className="ml-auto">
                                                                                            <a onClick={() => redirect("/fleetowner/VehicleInformation/" + data.vehicleId, data.workOrderNumber)} class="btn btn-outline-primary  text-uppercase">View</a>
                                                                                            {/* <a href="" class="btn disable-btn text-uppercase ml-2">Resolve</a> */}
                                                                                            {roles.match("Driver") ? "" : data.inspectionList === null || data.inspectionList.length == 0 ? <a onClick={() => vehicleInfo(data.vehicleId, data.workOrderNumber)} class="btn btn-primary text-uppercase ml-2">Resolve</a> : <a class="btn disable-btn text-uppercase ml-2">Resolve</a>}
                                                                                        </div>
                                                                                    </div>

                                                                                    {data.inspectionList === null || data.inspectionList.length == 0 ? "" : <div className="wopopven">
                                                                                        <div className="wo">
                                                                                            <span>WO: </span>
                                                                                            <span>{data.workOrderNumber ? data.workOrderNumber : "--"}</span>
                                                                                        </div>
                                                                                        <div className="vn mx-3">
                                                                                            <label htmlFor="">Vendor: </label>
                                                                                            <span>{data.vendorName ? data.vendorName : "--"}</span>
                                                                                        </div>
                                                                                        <div className="vn ml-3">
                                                                                            <label htmlFor="">Date: </label>
                                                                                            <span>{data.assignedDate ? moment(data.assignedDate).format('MM-DD-YYYY') : "--"}</span>
                                                                                        </div>

                                                                                        {roles.match("Driver") ? "" :
                                                                                            data.inspectionList === null || data.inspectionList.length == 0 ? "" : <a href="" data-toggle="modal" data-backdrop="static" data-keyboard="false" class="btn btn-primary text-uppercase ml-auto" data-target="#updateModal" onClick={() => updateTask(data.workOrderNumber, data.inspectionList, data.assignedDate, data.id, data.vendorName, data.vehicleId, data.vendorId,data.notes)}>EDIT</a>}


                                                                                    </div>}
                                                                                    <div className="innertabDv">
                                                                                        <table className="w-100">
                                                                                            <tr>
                                                                                                <th style={{ width: "40%", padding: "8px 8px 8px 24px" }}>Inspection Type</th>
                                                                                                <th style={{ width: "20%", padding: "8px" }}>Expiry Date</th>
                                                                                                <th style={{ width: "auto", padding: "8px" }}>Status</th>
                                                                                            </tr>
                                                                                            {

                                                                                                data.mergedocumentList ? data.mergedocumentList.map((data1, index) => {
                                                                                                    return <tr>
                                                                                                        <td style={{ width: "40%", padding: "14px 8px 14px 24px" }}>{data1.documentSubCategoryName ? data1.documentSubCategoryName : "--"}</td>
                                                                                                        <td style={{ width: "20%", padding: "14px 8px" }}>{data1.expiryDate ? moment(data1.expiryDate).format('MM-DD-YYYY') : "--"}</td>
                                                                                                        <td style={{ width: "auto", padding: "14px 8px" }} className="approve-pending font-weight-bold">

                                                                                                            {(() => {
                                                                                                                var today = new Date();
                                                                                                                var dd = String(today.getDate()).padStart(2, '0');
                                                                                                                var mm = String(today.getMonth() + 1).padStart(2, '0');
                                                                                                                var yyyy = today.getFullYear();

                                                                                                                today = mm + '/' + dd + '/' + yyyy;
                                                                                                                const currentDate = new Date(today);
                                                                                                                const expiredDate = new Date(moment(data1.expiryDate).format('MM-DD-YYYY'));
                                                                                                                if (currentDate <= expiredDate) {
                                                                                                                    return ("Upcoming");
                                                                                                                }
                                                                                                                else if (currentDate > expiredDate) {
                                                                                                                    return ("Overdue");
                                                                                                                } else {
                                                                                                                    return (data1.status);
                                                                                                                }


                                                                                                            })()}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }) : ""
                                                                                            }
                                                                                            {/* {

                                                                                                data.inspectionList ? data.inspectionList.map((inspection, index) => {
                                                                                                    return <tr>
                                                                                                        <td style={{ width: "33%", padding: "14px 8px 14px 24px" }}>{inspection.documentSubCategoryName ? inspection.documentSubCategoryName : "--"}</td>
                                                                                                        <td style={{ width: "20%", padding: "14px 8px" }}>{inspection.expiryDate ? moment(inspection.expiryDate).format('MM-DD-YYYY') : "--"}</td>
                                                                                                        <td style={{ width: "auto", padding: "14px 8px" }} className="text-uppercase approve-pending font-weight-bold">InProcess</td>
                                                                                                    </tr>
                                                                                                }) : ""
                                                                                                } */}
                                                                                            {/* <tr>
                                                                                                <td style={{ width: "33%", padding: "14px 8px 14px 24px" }}>Pressure Test</td>
                                                                                                <td style={{ width: "20%", padding: "14px 8px" }}>22-12-2022</td>
                                                                                                <td style={{ width: "auto", padding: "14px 8px" }} className="text-uppercase approve-pending font-weight-bold">Inprocess</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td style={{ width: "33%", padding: "14px 8px 14px 24px" }}>Leakage Test</td>
                                                                                                <td style={{ width: "20%", padding: "14px 8px" }}>29-12-2022</td>
                                                                                                <td style={{ width: "auto", padding: "14px 8px" }} className="text-uppercase approved font-weight-bold">Completed</td>
                                                                                            </tr> */}
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )) : ""
                                                                }







                                                            </table>
                                    </div>
                                   
                                </div>
                            </div>
                          
                        </div>

                    </div>



                </div>
            </div>
            <UpdateUpcomingAndoverdue Id={Id} vendorId={vendorId} vehicleId={vehicleId} originalDate={originalDate} setOriginalDate={setOriginalDate} inspectiondisable={inspectiondisable} notes={notes} inspectionNames={inspectionNames} setInspecchecked={setInspecchecked}/>
  </>
)};

FleetOwnerDashboard.propTypes = {};

FleetOwnerDashboard.defaultProps = {};

export default FleetOwnerDashboard;
