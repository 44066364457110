import React, { history, useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import { useLoading } from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import HeaderBlock from "../../vehicle/VehicleHeaderBlock";
import VehicleBasicInfo from "../../../../utility/vehicleBasicInfo";
import documentDetails_ from "../wet-test-certification/documentDetails_";
import VerifiedBy from "../../../../utility/verfifiedBy";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const DriverVehicleTestRead = () => {


   const [fromDate, setFromDate] = useState();
   const { fkAccountId, inspectionId, vid } = useParams();
   const [inputList, setInputList] = useState([{}]);
   const [base64file, setBase64file] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const [fileName, setfileName] = useState("");
   const [inspectionDetails, setInspectionDetails] = useState("");
   const [documentList, setDocumentLists] = useState([]);
   const navigate = useNavigate();
   const [endDate, setEndDate] = useState(new Date());
   const [verifiedDATE, setverifiedDate] = useState(new Date());
   const [expiryDate, setexpiryDate] = useState(new Date());
   const [roles, setRoles] = useState("");
   const [statusInspection, setStatus] = useState("");
   useEffect(() => {
     setRoles(localStorage.getItem("Userroles"));
      // vehicle_details_api(id, setVehicleDetails, setCustomerId);
      getInpection(fkAccountId, inspectionId)
   }, []);

   function getInpection(fkAccountId, inspectionId) {
      var requestObjectForLeakage =
      {

         "id": inspectionId
      }

      var requestOptions = requestObject.RequestHeader(requestObjectForLeakage);

      services.getInspectionDetails(requestOptions).then((res) => {
         console.log("  " + JSON.stringify(res));
         setInspectionDetails(res.rows);
         // $('input[name="Pintle_repaired"][value="' + Pintle + '"]').trigger("click").prop('checked', true);
         setDocumentLists(res.rows.documentList?res.rows.documentList:"");
         $("#workOrderNumber").val(res.rows ? res.rows.workOrderNumber : "");
         // sessionStorage.setItem("verifiedBy",res.rows ? res.rows.verifiedBy : "");
         setEndDate(new Date(res.rows?res.rows.inspectedDate:""));
         setStatus(res.rows.status);
         if(res.rows)
         {
            if(res.rows.verifiedDate)
            {
               setverifiedDate(new Date(res.rows?res.rows.verifiedDate:''));
            }
     
            if(res.rows.expiryDate)
            {
               setexpiryDate(new Date(res.rows ? res.rows.expiryDate : ""))
            }
         }
      });

     // setEndDate(inspectionDetails.verifiedDate);
   }

   const redirect=(page)=>
   {

      navigate(page);

   }

   const save=(status)=>
   {

   // console.log("results=="+JSON.stringify(inputList));
   if($("#inspectedBy").val()==="")
   {
     Swal.fire({
         position: 'center',
         icon: 'error',
         title: "",
         text:"Please enter Inspected by",
         showConfirmButton: true

       }).then((result) =>
       {

             if(result.isConfirmed)
             {


             }
       });
       return false;
   }
   if( $('input:radio[name="returnedservice"]:checked').val()==="undefined"||$('input:radio[name="returnedservice"]:checked').val()===undefined)
   {
     Swal.fire({
         position: 'center',
         icon: 'error',
         title: "",
         text:"Please select disposition of tank",
         showConfirmButton: true

       }).then((result) =>
       {

             if(result.isConfirmed)
             {


             }
       });
       return false;
   }
   if( $('input:radio[name="approvedCertify"]:checked').val()===""|| $('input:radio[name="approvedCertify"]:checked').val()===undefined)
   {
     Swal.fire({
         position: 'center',
         icon: 'error',
         title: "",
         text:"Please select inspection result",
         showConfirmButton: true

       }).then((result) =>
       {

             if(result.isConfirmed)
             {


             }
       });
       return false;
   }
   if($("input[name='approvedCertify']:checked").val()==="approvedCertify")
   {

   }else{

    status="Rejected";
   }
         var requestObjectInspection=
         {
           "id": inspectionId,
           "expiryDate": $("#expiryDate").val(),
           "verifiedBy": $("#verifiedBy").val(),
           "verifiedDate": $("#verifiedByBate").val(),
           "status": status,

           "result": {
            "approvedCertify":$("input[name='approvedCertify']:checked").val()==="approvedCertify"?"1":"0",
            "inspectionFailed": $("input[name='approvedCertify']:checked").val()==="inspectionFailed"?"1":"0"
          },
          "dispositionOfVehicle": {
            "vehicleReturnedServices":$("input[name='returnedservice']:checked").val()==="returnedservice"?"1":"0",
            "vehicleWithdrawnFromService":$("input[name='returnedservice']:checked").val()==="withdrawnservice"?"1":"0",
            "vehicleSentToAnotherRepairFacility": $("input[name='returnedservice']:checked").val()==="repairfacility"?"1":"0"
          }


       };

       $("#loadingforsubmit").show();
      //  setLoading(true);
       // const requestOptions =
       // {
       //   method: 'POST',
       //   headers: { 'Content-Type': 'application/json'
       //             },

       //       body: JSON.stringify(requestObject)
       // };
       var requestOptions=requestObject.RequestHeader(requestObjectInspection);
       services.certifyInspection(requestOptions).then((res) =>
       {
         $("#loadingforsubmit").hide();
         Swal.fire({
           position: 'center',
           icon: 'success',
           title: "",
           text:res.message,
           showConfirmButton: true

         }).then((result) =>
         {

               if(result.isConfirmed)
               {
                 navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));

               }
         });
       });

   }


   return (
      <>
      {LoadingComponentForSubmit}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block" }}>
            <div class="row mb-0">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <div class="d-flex justify-content-between align-items-center my-4">
                     <h4 class="font-weight-bold text-uppercase mb-0">Vehicle Inspection</h4>
                           <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                     </div>
                  </div>
               </div>
               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                  <HeaderBlock cids={fkAccountId} />
                  </div>
               </div>

               

               <div class="row mb-2">
               <div class="col-lg-11 col-md-12 m-auto ">
                     <div class="card">

                       <div class="row mb-2">
                              <div class="col-lg-12 col-md-12 m-auto mb-2 ">
                                    <VehicleBasicInfo customerId={vid} />
                              </div>
                        </div>
                        <div class="divider"></div>

                        <div class="row p-3">
                           <div class="col-lg-12">
                              <h4 class="mb-4 form-inspection-title text-center">Driver's Vehicle Inspection Report</h4>
                              {/*<p id="leakage-check-type-message" class="error"></p>
                              <p id="unitDesignPressure-message" class="error"></p>
                              <p id="testPressure-message" class="error"></p>
   <p id="error_message" class="center error" ></p> */}
                           </div>
                           <div class="col-lg-12"><WorkOrderNumberDiv /></div><br />
                           

                            <div class="px-3">
                                <div class="inspection-wrap">
                                    <div class="row mb-3 ">
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.airCompressor == '1') {
                                                            return (<><input type="checkbox" name="air_compressor" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="air_compressor" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="air_compressor" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Air Compressor</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.airLines == '1') {
                                                            return (<><input type="checkbox" name="air_lines" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="air_lines" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="air_lines" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Air Lines</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.battery == '1') {
                                                            return (<><input type="checkbox" name="battery" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="battery" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="battery" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Battery</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.brakeAccessories == '1') {
                                                            return (<><input type="checkbox" name="break_accessories" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="break_accessories" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="break_accessories" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Brake Accessories</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.brakes == '1') {
                                                            return (<><input type="checkbox" name="breaks" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="breaks" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="breaks" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Brakes</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.carburetor == '1') {
                                                            return (<><input type="checkbox" name="carburetor" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="carburetor" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="carburetor" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Carburetor</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.clutch == '1') {
                                                            return (<><input type="checkbox" name="clutch" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="clutch" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="clutch" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Clutch</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.defroster == '1') {
                                                            return (<><input type="checkbox" name="defroster" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="defroster" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="defroster" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Defroster</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.driveLine == '1') {
                                                            return (<><input type="checkbox" name="drive_line" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="drive_line" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="drive_line" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Drive Line</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.engine == '1') {
                                                            return (<><input type="checkbox" name="engine" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="engine" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="engine" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Engine</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.fifthWheel == '1') {
                                                            return (<><input type="checkbox" name="fifth_wheel" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="fifth_wheel" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="fifth_wheel" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Fifth Wheel</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.frontAxle == '1') {
                                                            return (<><input type="checkbox" name="front_axle" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="front_axle" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="front_axle" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Front Axle</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.fuelTanks == '1') {
                                                            return (<><input type="checkbox" name="fuel_tanks" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="fuel_tanks" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="fuel_tanks" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Fuel Tanks</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.heater == '1') {
                                                            return (<><input type="checkbox" name="heater" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="heater" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="heater" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Heater</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.horn == '1') {
                                                            return (<><input type="checkbox" name="horn" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="horn" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="horn" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Horn</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex ">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.lights == '1') {
                                                            return (<><input type="checkbox" name="lights" class="mr-2" defaultChecked={true} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="lights" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="lights" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Lights<br/>Head - Stop<br/>Tail - Dash<br/>Turn Indicators</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.mirrors == '1') {
                                                            return (<><input type="checkbox" name="mirrors" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="mirrors" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="mirrors" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Mirrors</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.muffer == '1') {
                                                            return (<><input type="checkbox" name="muffler" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="muffler" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="muffler" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Muffler</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.oilPressure == '1') {
                                                            return (<><input type="checkbox" name="oil_pressure" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="oil_pressure" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="oil_pressure" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Oil Pressure</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.onBoardRecorder == '1') {
                                                            return (<><input type="checkbox" name="onboard_recorder" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="onboard_recorder" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="onboard_recorder" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>On-Board Recorder</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.radiator == '1') {
                                                            return (<><input type="checkbox" name="radiator" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="radiator" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="radiator" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Radiator</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.rearEnd == '1') {
                                                            return (<><input type="checkbox" name="rear_end" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="rear_end" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="rear_end" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Rear End</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.refectors == '1') {
                                                            return (<><input type="checkbox" name="reflectors" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="reflectors" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="reflectors" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Reflectors</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex ">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.safetyEquipment == '1') {
                                                            return (<><input type="checkbox" name="safety_equipments" class="mr-2" defaultChecked={true} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="safety_equipments" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="safety_equipments" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Safety Equipments<br/>Fire Extinguisher<br/>Flags - Flares - Fuses<br/>Spare Bulbs & Fuses<br/>Spare Seal Beam</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.springs == '1') {
                                                            return (<><input type="checkbox" name="springs" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="springs" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="springs" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Springs</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.starter == '1') {
                                                            return (<><input type="checkbox" name="starter" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="starter" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="starter" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Starter</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.steering == '1') {
                                                            return (<><input type="checkbox" name="steering" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="steering" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="steering" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Steering</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.tachograph == '1') {
                                                            return (<><input type="checkbox" name="tachograph" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="tachograph" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="tachograph" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Tachograph</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.tires == '1') {
                                                            return (<><input type="checkbox" name="tires" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="tires" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="tires" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Tires</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.transmission == '1') {
                                                            return (<><input type="checkbox" name="transmission" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="transmission" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="transmission" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Transmission</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.wheels == '1') {
                                                            return (<><input type="checkbox" name="wheels" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="wheels" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="wheels" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Wheels</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.windows == '1') {
                                                            return (<><input type="checkbox" name="windows" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="windows" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="windows" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Windows</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.windshieldWipers == '1') {
                                                            return (<><input type="checkbox" name="windshield_wipers" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="windshield_wipers" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="windshield_wipers" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Windshield Wipers</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.truckDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.truckDetails.Other == '1') {
                                                            return (<><input type="checkbox" name="other" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="other" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="other" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Other</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="w-100 px-3 border-bottom">
                                <div class="row mb-2 mt-2">
                                    <div class="col-lg-6 mb-2 d-flex align-items-center">
                                        <div class="d-flex align-items-center" style={{ minWidth: "90px" }}>
                                            <span>Trailer(s) No(s) :</span>

                                        </div>
                                        <div id="otherField">
                                            <div class="ml-2"> <div class="d-flex align-items-center"> {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.trailerNos != null){
                                                         return (<><input type="input" class="form-control" id="driverinsp_trailerno" aria-describedby="emailHelp" style={{ width: "100px" }} defaultValue={inspectionDetails.driverVehicleInspection.trailerNos} disabled/></>)
                                                      } else {
                                                         return (<><input type="input" class="form-control" id="driverinsp_trailerno" aria-describedby="emailHelp" style={{ width: "100px" }} disabled/></>)
                                                      }
                                                   }
                                                })()}
                                             </div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="px-3">
                                <div class="inspection-wrap">
                                    <div class="row mb-3 ">
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.trailerDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.trailerDetails.brakeConnections == '1') {
                                                            return (<><input type="checkbox" name="break_connections" class="mr-2" defaultChecked={true}  disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="break_connections" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="break_connections" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Brake Connections</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.trailerDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.trailerDetails.traileBrakes == '1') {
                                                            return (<><input type="checkbox" name="trailer_breaks" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="trailer_breaks" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="trailer_breaks" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Brakes</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.trailerDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.trailerDetails.couplingChains == '1') {
                                                            return (<><input type="checkbox" name="coupling_chains" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="coupling_chains" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="coupling_chains" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Coupling Chains</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.trailerDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.trailerDetails.couplingPin == '1') {
                                                            return (<><input type="checkbox" name="coupling_pin" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="coupling_pin" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="coupling_pin" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Coupling (King) Pin</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.trailerDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.trailerDetails.doors == '1') {
                                                            return (<><input type="checkbox" name="doors" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="doors" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="doors" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Doors</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.trailerDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.trailerDetails.hitch == '1') {
                                                            return (<><input type="checkbox" name="hitch" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="hitch" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="hitch" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Hitch</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.trailerDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.trailerDetails.landingGear == '1') {
                                                            return (<><input type="checkbox" name="landing_gear" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="landing_gear" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="landing_gear" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Landing Gear</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.trailerDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.trailerDetails.lightsAll == '1') {
                                                            return (<><input type="checkbox" name="all_lights" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="all_lights" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="all_lights" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Lights - All</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.trailerDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.trailerDetails.roof == '1') {
                                                            return (<><input type="checkbox" name="roof" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="roof" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="roof" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Roof</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.trailerDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.trailerDetails.trailerSprings == '1') {
                                                            return (<><input type="checkbox" name="trailer_springs" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="trailer_springs" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="trailer_springs" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Springs</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.trailerDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.trailerDetails.tarpaulin == '1') {
                                                            return (<><input type="checkbox" name="tarpaulin" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="tarpaulin" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="tarpaulin" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Tarpaulin</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.trailerDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.trailerDetails.trailerTires == '1') {
                                                            return (<><input type="checkbox" name="trailer_tires" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="trailer_tires" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="trailer_tires" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Tires</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.trailerDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.trailerDetails.trailerWheels == '1') {
                                                            return (<><input type="checkbox" name="trailer_wheels" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="trailer_wheels" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="trailer_wheels" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Wheels</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.trailerDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.trailerDetails.trailerOther == '1') {
                                                            return (<><input type="checkbox" name="trailer_other" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="trailer_other" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="trailer_other" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Other</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-3 ">
                                        <div class="col-lg-12">
                                            <div class="p-2 text-center hd-bg">
                                                <h5 class="text-uppercase" style={{ textDecoration: "underline" }}>Remarks</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-8 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                               if (inspectionDetails.driverVehicleInspection) {
                                                                  if(inspectionDetails.driverVehicleInspection.remarks != null){
                                                                     return (<><textarea class="form-control my-3" name="drivervehicle_remarks" id="drivervehicle_remarks" rows="3" maxLength="300" style={{minHeight:"180px"}} disabled>{inspectionDetails.driverVehicleInspection.remarks}</textarea></>)
                                                                  } else {
                                                                     return (<><textarea class="form-control my-3" name="drivervehicle_remarks" id="drivervehicle_remarks" rows="3" maxLength="300" style={{minHeight:"180px"}} disabled></textarea></>)
                                                                  }
                                                               }
                                                            })()}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="divider2"></div>
                        <div class="form-footer-comon-wrap">
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                    {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}
                                    <div class="row">
                                        {
                                            documentList ? documentList.map((data, index) => (
                                                <div class="col-md-12">
                                                    <div class="d-flex align-items-center">
                                                        <img src="images/icons/pdf-icon.svg" alt="" />
                                                        <a onClick={() => documentDetails_(data.filePath, data.extension)} class="ml-2 w-100">
                                                            {/* <b>{data.title}.{data.extension}</b> */}
                                                                <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                    <img src="" class="mr-2" />
                                                                    <span style={{ width: "calc(100% - 50px)" }}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                        {(() => {
                                                                            if (data.extension == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.extension == 'jpg' || data.extension == 'png' || data.extension == 'jpeg' || data.extension == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else if (data.extension == 'doc' || data.extension == 'word' || data.extension == 'docx' || data.extension == 'dot' || data.extension == 'dotm' || data.extension == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/upload.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> */}
                                                                        {data.name ? data.name : "--"} </p></span>
                                                                    {/* <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" />  */}
                                                                    </div>
                                                        </a>
                                                        {/* <img src="/images/icons/remove-minus.svg"  onClick={() => removePdf(data.id,setDocumentLists)} alt="upload"/> */}
                                                    </div>
                                                </div>


                                            )) : ""

                                        }
                                    </div>
                                </div><br />

                                </div>
                                <div class="row documents p-3">
                                <div class="col-lg-12">
                                      <h6 class="mb-4">Additional notes: </h6>
                                      {inspectionDetails?inspectionDetails.notes:''}
                                   </div>
                                   </div>
                                   <div class="divider2"></div>

                                   <div class="row p-3 ">
                                        <div class="col-lg-12 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.otherDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.otherDetails.statisfactory == '1') {
                                                            return (<><input type="checkbox" name="vehicle_satisfactory" class="mr-2" defaultChecked={true}  style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="vehicle_satisfactory" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="vehicle_satisfactory" class="mr-2" defaultChecked={false} style={{minHeight:20, maxHeight:20,}} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Condition of the above vehicle is satisfactory</span>
                                            </div>
                                        </div>
                                        {/*<div class="col-lg-12 mb-2 d-flex align-items-center">
                                          <div class="d-flex align-items-center" style={{ minWidth: "90px" }}>
                                              <span>Driver's Signature</span>

                                          </div>
                                          <div id="otherField">
                                              <div class="ml-2"> <div class="d-flex align-items-center"> <input type="input" id="driver_signature" name="driver_signature" aria-describedby="emailHelp" style={{ width: "100px" }} class="form-control" disabled/>
                                               </div></div>
                                          </div>
                                      </div>
                                      
                                        <div class="col-lg-12 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.otherDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.otherDetails.corrected == '1') {
                                                            return (<><input type="checkbox" name="defects_corrected" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="defects_corrected" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="defects_corrected" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Above Defects Corrected</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mb-2">
                                            <div class="d-flex align-items-center">
                                            {(() => {
                                                   if (inspectionDetails.driverVehicleInspection) {
                                                      if(inspectionDetails.driverVehicleInspection.otherDetails != null){
                                                         if (inspectionDetails.driverVehicleInspection.otherDetails.needNotToBeCorrected == '1') {
                                                            return (<><input type="checkbox" name="defects_nottobecorrected" class="mr-2" defaultChecked={true} disabled/></>)
                                                         } else {
                                                            return (<><input type="checkbox" name="defects_nottobecorrected" class="mr-2" defaultChecked={false} disabled/></>)
                                                         }
                                                      } else {
                                                         return (<><input type="checkbox" name="defects_nottobecorrected" class="mr-2" defaultChecked={false} disabled/></>)
                                                      }
                                                   }
                                                   
                                                })()}
                                                <span>Above Defects Need Not be Corrected for Safe Operation of Vehicle</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 mb-2 d-flex align-items-center">
                                          <div class="d-flex align-items-center" style={{ minWidth: "90px" }}>
                                              <span>Mechanic's Signature</span>

                                          </div>
                                          <div id="otherField">
                                              <div class="ml-2"> <div class="d-flex align-items-center"> <input type="input" id="mechanic_signature" name="mechanic_signature" aria-describedby="emailHelp" style={{ width: "100px" }} class="form-control" disabled/>
                                               </div></div>
                                          </div>
                                      </div>
                                      <div class="col-lg-6 mb-2 d-flex align-items-center">
                                          <div class="d-flex align-items-center" style={{ minWidth: "90px" }}>
                                              <span>Date</span>

                                          </div>
                                          <div id="otherField">
                                              <div class="ml-2"> <div class="d-flex align-items-center"> <input type="input" id="mechanic_sigdate" name="mechanic_sigdate" aria-describedby="emailHelp" style={{ width: "100px" }} class="form-control" disabled/>
                                              </div></div>
                                          </div>
                                      </div>
                                      
                                      <div class="col-lg-6 mb-2 d-flex align-items-center">
                                          <div class="d-flex align-items-center" style={{ minWidth: "90px" }}>
                                              <span>Driver's Signature</span>

                                          </div>
                                          <div id="otherField">
                                              <div class="ml-2"> <div class="d-flex align-items-center"> <input type="input" id="driver_signature1" name="driver_signature1" aria-describedby="emailHelp" style={{ width: "100px" }} class="form-control" disabled/>
                                               </div></div>
                                          </div>
                                      </div>
                                      <div class="col-lg-6 mb-2 d-flex align-items-center">
                                          <div class="d-flex align-items-center" style={{ minWidth: "90px" }}>
                                              <span>Date</span>

                                          </div>
                                          <div id="otherField">
                                              <div class="ml-2"> <div class="d-flex align-items-center"> <input type="input" id="driver_sigdate" name="driver_sigdate" aria-describedby="emailHelp" style={{ width: "100px" }} class="form-control" disabled/>
                                               </div></div>
                                          </div>
                                      </div>*/}
                                    </div>

                                <div class="divider2"></div>
                                <div class="row performby p-4">
                                   <div class="col-lg-12">
                                      <div class="row">
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Test Performed By:</label>
                                               <b>{inspectionDetails.inspectedBy}</b>
                                            </div>
                                         </div>
                                         <div class="col-md-4">
                                            <div class="d-flex flex-column">
                                               <label for="">Date</label>
                                               <b>{inspectionDetails.inspectedDate}</b>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>

                                <div class="divider"></div>
                                {(() => {
                                   if(!roles.match("Technician")){
        if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED') {
          return (
            <>
            <div class="result-didspos">
                                   <div class="p-4">
                                      <span>Result</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Approve &amp; Certify</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Inspection Failed</b>
                                      </div>
                                   </div>
                                   <div class="p-4">
                                      <span>Disposition of Tank</span>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input" disabled checked/>:""}
                                         <b class="mb-0 ml-2">Tank Returned to Service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank withdrawn from service</b>
                                      </div>
                                      <div class="d-flex align-items-center">
                                      {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" disabled/>:""}
                                         <b class="mb-0 ml-2">Tank sent to another repair facility</b>
                                      </div>
                                   </div>
                                </div>
                                <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate} status={"readOnly"} statusInspection={statusInspection}  customerId={fkAccountId}/>
                                {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} status={"readOnly"} /> */}
            </>
          )
        } else {
          return (
            <><div class="result-didspos ">
            <div class="p-4">
               <span>Result</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.approvedCertify=="1"?<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="approvedCertify" value='approvedCertify' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Approve &amp; Certify</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.result?inspectionDetails.result.inspectionFailed=="1"?<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input"  checked/>:<input type="radio" name="approvedCertify" value='inspectionFailed' aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Inspection Failed</b>
               </div>
            </div>
            <div class="p-4">
               <span>Disposition of Tank</span>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleReturnedServices=="1"?<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="returnedservice" aria-label="Radio button for following text input"/>:""}
                  <b class="mb-0 ml-2">Tank returned to service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleWithdrawnFromService=="1"?<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="withdrawnservice" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank withdrawn from service</b>
               </div>
               <div class="d-flex align-items-center">
               {inspectionDetails.dispositionOfVehicle?inspectionDetails.dispositionOfVehicle.vehicleSentToAnotherRepairFacility=="1"?<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input"  checked/>:<input type="radio" name="returnedservice" value="repairfacility" aria-label="Radio button for following text input" />:""}
                  <b class="mb-0 ml-2">Tank sent to another repair facility</b>
               </div>
            </div>
         </div>
         <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} setExpiryDate={setexpiryDate} expiryDATE={expiryDate}   customerId={fkAccountId}/>
         {/* <VerifiedBy setEndDate={setverifiedDate} endDate={verifiedDATE} /> */}
         {/* <div class="divider"></div>
         <div class="row verifyby p-4">
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Verified By</label>
                  <input type="text" class="form-control custome-input" id="verifiedBy" value={inspectionDetails.verifiedBy} aria-describedby="emailHelp" placeholder="Enter Name"/>
               </div>
            </div>
            <div class="col-4">
               <div class="form-group">
                  <label for="exampleInputEmail1">Date</label>
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="verifiedByBate" />

               </div>
            </div>
         </div> */}

         </>
          )
        }
      }
      })()}
                             </div>

                        <div class="divider"></div>
                        {(() =>
                            {
                                 if (inspectionDetails.status=='approved' || inspectionDetails.status=='approve' || inspectionDetails.status=='APPROVED'||inspectionDetails.status=='Rejected'||inspectionDetails.status=='REJECTED')
                                 {
                                 }
                                 else
                                 {
                                   return(<> <div class="row p-3 pr-4 d-flex justify-content-end">
                                   <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))}class="btn btn-secondary mr-1 ">Cancel</a>
                                       {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a>
                                        */}
                                        {roles.match("Technician")?"":<a href="#" class="btn btn-primary mr-1" onClick={() => save("approved")}>Submit</a>}
                                   {roles.match("Technician")?<a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-primary mr-1">Back</a>:""}
                                   </div></>)
                                 }
                           })()}
                     </div>
                  </div>
               </div>

            </div>



         </div>
      </>
   )
};

DriverVehicleTestRead.propTypes = {};

DriverVehicleTestRead.defaultProps = {};

export default DriverVehicleTestRead;
