import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import services from "../../../../services/services";
import $ from 'jquery';

import vehicle_details_api from "../../vehicle/vehicle_details_api";
import Search from "../../../../utility/search";
import Pagination from "../../../../utility/pagination";
import Swal from "sweetalert2";
import inspectionList from "../../inspection/inspectionList";
import { requestObject } from "../../../../utility/requestObject";
import moment from 'moment'
import { itemperPagetoDisplay } from "../../../constant";
import { loadingComponent } from "../../../../utility/Loading";
import { document_details } from "../../../../utility/DocumentDetails";

import { deleteDocuments } from "../../../../utility/sendtocustomer";
import { deleteInspectionDocuments } from "../../../../utility/sendtocustomer";
import { Manager, Owner } from "../../../constant";
import { Driver } from "../../../constant";
import ToggleSwitch from "../../ToggleSwitch";
import UpdateUpcomingAndoverdue from "../reminder_utility/UpcomingOverdue";

import { useForm } from "react-hook-form";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
import { LoadingComponentfordocuments } from "../../../../utility/LoadingForDoc";
import AddRemoveInputField from "../../../../utility/AddRemoveInputField";
import { Sendtocustomer } from "../../../../utility/sendtocustomer";
import { SendtocustomerFleet } from "../../../../utility/sendtocustomer";
import { SelectAllContactList } from "../../customer/CustomerContactList";
import { sendtocustomerCheck } from "../../../../utility/sendtocustomer";
import { document_print } from "../../../../utility/DocumentPrint";
import Checkbox from "../../../../utility/Checkbox";
import { resetCheckbox } from "../../../../utility/sendtocustomer";
const FleetVehicleInformation = () => {

   var roles = localStorage.getItem("Userroles");

   const { id, vin } = useParams();
   var vid = id;
   const inlineCss =
   {
      textAlign: "left"
   }
   const inlineWidth =
   {
      minWidth: "20%",
      paddingRight: "10px"
   }
   const inlineW125 =
   {
      maxWidth: "100px",
      paddingRight: "10px"
   }
   let navigation = useNavigate();
   const [totalItems, setTotalItems] = useState(0);
   const [totalInspectionList, setTotalInspectionList] = useState(0);
   const [totalInspectionPages, settotalinspectionPages] = useState(0);
   const [inspectioCurrentPage, setInspectionCurrentPage] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const [docname, setDocName] = useState();
   const [totaldocspages, settotalDocsPages] = useState();
   const [totaldocsitems, settotalDocsItems] = useState();
   const [displayPage, setdisplayPage] = useState([]);
   const [displayPageForInspection, setdisplayPageForInspection] = useState([]);
   const [vechileDetails, setVehicleDetails] = useState([]);
   const [cid, setCustomerId] = useState([]);
   const [totalRecors, setTotalRecord] = useState([]);
   const [totalDocsRecors, setTotalDocsRecord] = useState([]);
   const [documentList, setDocumentList] = useState("");
   const [inspectionLists, setInspectionList] = useState("");
   const [DueDate, setDueDate] = useState([]);
   const [ITEMS_PER_PAGE_DOCS, setitemperdocspage] = useState(25);
   const [ITEMS_PER_PAGE, setitemperdocs] = useState(25);
   const [inspectioname, setInspectionName] = useState("");
   const [documentCategoryName, setDocumentCategoryName] = useState("others");
   const [DocumentCategoryId, setDocumentCategoryId] = useState("1");
   const [sortingField, setSortingField] = useState("");
   const [sortingOrder, setSortingOrder] = useState("");
   const [inspectionTypes, setInspectionTypes] = useState();
   const [vehicleId, setVehicleId] = useState();
   const [checked, setChecked] = useState([]);
   const navigate = useNavigate();

   const [value, setValue] = useState("");
   const [Id, setIspectionId] = useState()
   const [notes, setNotes] = useState();
   const [originalDate, setOriginalDate] = useState(new Date());
   const [orderId, setOrderId] = useState([]);
   const [customerId, setCustomerIds] = useState();
   // const [cid, setCustomerId] = useState([]);
   // const [vehicleId, setVehicleId] = useState();
   const [vendorId, setvendorId] = useState()
   var inspectionArray = [];
   var inspectionData = [];
   var inspect = {};
   var inspectionJson = [];
   const [inspectiondisable, setInspecchecked] = useState([]);
   const [inspectionStatus, setInspecStatus] = useState([]);
   // const [inspectionTypes, setInspectionTypes] = useState();
   const [inspectionNames, setInspecName] = useState([]);
   const [garageId, setgarageId] = useState([{}]);
   const [InspectionTypesStatus, setInspectionTypesStatus] = useState([]);
   const [inspectionNamearray, setInspecNamearray] = useState([]);
   
   const [emailforsendtoct, setInputEmailList] = useState([{}]);
   const {
       register,
       formState: { errors },
       handleSubmit
   } = useForm({
       criteriaMode: "all", mode: "onBlur"
   });

   const psA = {
      //position: "absolute",
      top: "-12px",
      borderBottom: "0px",

   }

   const headers =
      [
         // { name: "Category", field: "Doc category", sortable: true, inlineStyle: inlineCss, inlineStyle: inlineWidth },
         { name: <Checkbox from='global' />, field: "documentId", inlineStyle: psA },
         { name: "Type", field: "Doc sub category", sortable: true, inlineStyle: inlineCss, inlineStyle: inlineWidth },
         { name: "Title", field: "Document Title", sortable: false, thStyle: 'width="40%"', inlineStyle: inlineCss, inlineStyle: inlineWidth, },
         // { name: "Date", field: "Document Date", sortable: true, inlineStyle: inlineCss },
         { name: "Unit Number", field: "unitNumber", sortable: true, inlineStyle: inlineCss, inlineStyle: inlineWidth },
         // { name: "Doc Date", field: "Document Date", sortable: true, inlineStyle: inlineCss, inlineStyle: inlineW125 },
         { name: "Expiry Date", field: "Expiry Date", sortable: true, inlineStyle: inlineCss, inlineStyle: inlineW125 },
         // { name: "Created By", field: "Created By", sortable: true, inlineStyle: inlineCss },
         { name: "Actions" }
      ];

   const Inspectionheaders =
      [
         { name: "Inspection", field: "Inspection", sortable: false, thStyle: 'width="40%"', inlineStyle: inlineCss },

         { name: "Inspected On", field: "Inspected On", sortable: true, inlineStyle: inlineCss },
         { name: "Inspected By", field: "Inspected By", sortable: true, inlineStyle: inlineCss },
         { name: "Expires On", field: "Expires On", sortable: true, inlineStyle: inlineCss },
         { name: "Actions" }
      ];
   const onSortingChangeDocument = (field) => {
      const order =
         field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

      setSortingField(field);
      setSortingOrder(order);
      veh_docs(id, currentPage, ITEMS_PER_PAGE_DOCS, docname, field, order, "", documentCategoryName, DocumentCategoryId);

   };
   const naviationToInspection = () => {
      sessionStorage.setItem("naviationfrom", "addinspection");
   }
   useEffect(() => {

      $("#distribute").prop("disabled", true);
      $("#loadingfordocs").hide();
      $("#loadingforsubmit").hide();
      sessionStorage.removeItem("resolveAll");
      var navigation = sessionStorage.getItem("naviationfrom");
      if (navigation === "doc-tab") {
         document.getElementById("doc-tab").click();
         sessionStorage.removeItem("naviationfrom");
      } else {
         document.getElementById("vd-tab").click();
         sessionStorage.removeItem("naviationfrom");
      }


      // if (navigation === "addinspection") {
      //    //  document.getElementById("insp-tab").click();
      //    sessionStorage.removeItem("naviationfrom");
      // }
      setVehicleId(vid);


      getInspectionType();
      // document_api(cid,"1",ITEMS_PER_PAGE,"",ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setDocumentList,setTotalRecord,setdisplayPage,vin);
      //vehicle_Details(id);
      setInspectionName("");
      getDocumentValidationCheck();
      //checkInspectionStatus();
      veh_docs(id, currentPage, ITEMS_PER_PAGE_DOCS, "", "", sortingOrder, "diplayItems", documentCategoryName, DocumentCategoryId);
      // veh_docs(id, currentPage, ITEMS_PER_PAGE_DOCS, "", "expiryDate", sortingOrder, "diplayItems", documentCategoryName, DocumentCategoryId);
      // veh_docs(id, "1", ITEMS_PER_PAGE_DOCS, sortingField, sortingOrder, "diplayItems","others","DocumentCategoryId");
      vehicle_details_api(id, setVehicleDetails, setCustomerId, "dontcallcontactsAPI");
      inspectionList(id, "1", ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, "");

   }, []);
   const activeVehicle = (status) => {
      setChecked(status);
      // var active = $("#activeClient").val();
      // if ($('#activeClient').prop('checked',true)) {
      var request =
      {

         "isActive": status,
         "id": id,


      };

      //let checkState = $("#activeClient").is(":checked") ? "true" : "false";
      Swal.fire({
         title: 'Do you really want to change the status of this vehicle?',
         // text: unitNumber+" : "+title,

         icon: 'error',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes',
         cancelButtonText: 'No'
      }).then(function (results) {
         // Called if you click Yes.
         if (results.isConfirmed) {

            // setLoading(true);
            $("#loadingforsubmit").show();
            var requestOptions = requestObject.RequestHeader(request);
            services.inActivateVehicle(requestOptions).then((res) => {
               // setLoading(false);
               $("#loadingforsubmit").hide();
               if (res.success) {
                  Swal.fire({
                     position: 'center',
                     icon: 'success',
                     title: "",
                     text: res.message,

                     showConfirmButton: true

                  }).then((result) => {
                     if (result.isConfirmed) {
                        // IfitIsCustomer();
                        navigate("/fleetowner/Vehicle");
                     }
                  });

               } else {

                  Swal.fire({
                     position: 'center',
                     icon: 'error',
                     title: "",
                     text: res.message,

                     showConfirmButton: true

                  }).then((result) => {
                     if (result.isConfirmed) {

                     }
                  });

               }

            });

         }
      },
         function (no) {
            // Called if you click No.
            if (no == 'cancel') {

            }
         });


   }
   const getDocumentValidationCheck = () => {

      var vehicle_request =
      {

         "fkVehicleId": id
      };

      var requestOptions = requestObject.RequestHeader(vehicle_request);
      services.getExpiringDocuments(requestOptions).then((res) => {

         setDueDate(res.rows);
      });
   }

   $('#display_pages_in_items').on('change', function () {
      setitemperdocs(this.value);
      inspectionList(id, "1", this.value, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, "");
   });
   const getInspectionType = () => {


      var vehicle_request =
      {

      };

      var requestOptions = requestObject.RequestHeader(vehicle_request);
      services.getInspectionTypeS(requestOptions).then((res) => {
         setInspectionTypes(res.rows);

      });
   }

   const searchDocsList = (docname) => {
      setDocName(docname);
      veh_docs(id, "1", ITEMS_PER_PAGE_DOCS, docname, sortingField, sortingOrder, "diplayItems", documentCategoryName, DocumentCategoryId);

   }


   const searchInspectionList = (inspectionType) => {
      setInspectionName(inspectionType);
      inspectionList(id, "1", ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, inspectionType);

   }
   /////////////////vehicle pagination////////////////////////////
   const handlePageChangeForInspection = (offset) => {


      inspectionList(id, offset, ITEMS_PER_PAGE, setInspectionList, setTotalInspectionList, settotalinspectionPages, setInspectionCurrentPage, setdisplayPageForInspection, inspectioname);

   }

   const onChangeValue = (event) => {


      setitemperdocspage(event.target.value);
      veh_docs(id, "1", event.target.value, docname, sortingField, sortingOrder, "", documentCategoryName, DocumentCategoryId);


   }
   /////////////////vehicle pagination////////////////////////////
   const handlePageChange = (offset, triggerevent) => {


      veh_docs(id, offset, ITEMS_PER_PAGE_DOCS, docname, sortingField, sortingOrder, "", documentCategoryName, DocumentCategoryId);

   }
   /////////
   const resolve = (page, inspectionname) => {


      sessionStorage.setItem("resolveSignleInspection", "true");
      sessionStorage.setItem("selectedinspectionName", inspectionname);
      redirect(page);


   }
   ////////////inspection details////////////////////////////////////
   const inspection_details = (inspectionTypeNname, inspectionStatus, fkAccountId, id) => {
      //  alert(inspectionTypeNname);

      if (inspectionTypeNname.match("Cargo Tank Thickness Test")) {

         if (inspectionStatus === "DRAFT") {
            navigation("/cargoTankThicknessTestEdit/" + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/cargoTankThicknessTestRead/" + fkAccountId + '/' + id + '/' + vid);
         }
         // navigation("/cargoTankThicknessTestRead/" + fkAccountId + '/' + id + '/' + vid);
      } else if (inspectionTypeNname.match("WET Test Certification")) {
         if (inspectionStatus === "DRAFT") {
            navigation("/wetTestCertificationEdit/" + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/wetTestCertificationRead/" + fkAccountId + '/' + id + '/' + vid);
         }

      } else if (inspectionTypeNname.match("Tractor PM Test") || inspectionTypeNname.match("Tractor PM/Inspection") || inspectionTypeNname.match("Tractor Preventive Maintenance/Inspection")) {

         if (inspectionStatus === "DRAFT") {
            navigation("/tractorpmTestEdit/" + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/tractorpmTestRead/" + fkAccountId + '/' + id + '/' + vid);
         }

      }
      else if (inspectionTypeNname.match("Service Check List")) {

         if (inspectionStatus === "DRAFT") {
            navigation("/serviceCheckListEdit/" + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/serviceCheckListRead/" + fkAccountId + '/' + id + '/' + vid);
         }

      }
      else if (inspectionTypeNname.match("Driver Vehicle Inspection")) {

         if (inspectionStatus === "DRAFT") {
            navigation("/driverVehicleTestEdit/" + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/driverVehicleTestRead/" + fkAccountId + '/' + id + '/' + vid);
         }

      } else if (inspectionTypeNname.match("Leakage Test")) {

         if (inspectionStatus === "DRAFT") {
            navigation("/leakageTestEdit/" + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/leakageTestRead/" + fkAccountId + '/' + id + '/' + vid);
         }

      } else if (inspectionTypeNname.match("Leakage Test (Method 27)")) {

         if (inspectionStatus === "DRAFT") {
            navigation("/leakageTestMethod27Edit/" + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/leakageTestMethod27Read/" + fkAccountId + '/' + id + '/' + vid);
         }

      }
      else if (inspectionTypeNname.match("DOT Cargo Tank Test")) {
         if (inspectionStatus === "DRAFT") {
            navigation("/dotCargoEdit/" + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/dotCargoRead/" + fkAccountId + '/' + id + '/' + vid);
         }
      }
      else if (inspectionTypeNname.match("Internal Visual Inspection")) {
         if (inspectionStatus === "DRAFT") {
            navigation("/internalVisualEdit/" + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/internalVisualRead/" + fkAccountId + '/' + id + '/' + vid);
         }
         // navigation("/internalVisualRead/" + fkAccountId + '/' + id + '/' + vid);
      }
      else if (inspectionTypeNname.match("Pressure test - Retest Inspection") || inspectionTypeNname.match("Pressure Vaccum test - Certificate application")) {

         if (inspectionStatus === "DRAFT") {
            navigation("/pressureTestEdit/" + fkAccountId + '/' + id + '/' + vid);
         }
         else {
            navigation("/pressureTestRead/" + fkAccountId + '/' + id + '/' + vid);
         }

      }
      else if (inspectionTypeNname.match("Carrier Equipment Inspection")) {
         if (inspectionStatus === "DRAFT") {
            navigation("/carrierEquipmentEdit/" + fkAccountId + '/' + id + '/' + vid);
         }
         else {
            navigation("/carrierEquipmentRead/" + fkAccountId + '/' + id + '/' + vid);
         }
      }
      else if (inspectionTypeNname.match("Annual Vehicle Inspection")) {
         if (inspectionStatus === "DRAFT") {
            navigation("/annualVehicle1Edit/" + fkAccountId + '/' + id + '/' + vid);
         }
         else {
            navigation("/annualVehicleRead/" + fkAccountId + '/' + id + '/' + vid);
         }

      }
      else if (inspectionTypeNname.match("External Visual Inspection")) {
         if (inspectionStatus === "DRAFT") {
            navigation("/editExternalVisual/" + fkAccountId + '/' + id + '/' + vid);
         }
         else {
            navigation("/externalVisualRead/" + fkAccountId + '/' + id + '/' + vid);
         }

      } else if (inspectionTypeNname.match("Gassoline Delivery Tank - Pressure Vaccum Test")) {
         if (inspectionStatus === "DRAFT") {
            navigation("/gasolineDeliveryTankPressureVacuumTestEdit/" + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/gasolineDeliveryTankPressureVacuumTestRead/" + fkAccountId + '/' + id + '/' + vid);
         }
         //  navigation("/gasolineDeliveryTankPressureVacuumTestRead/" + fkAccountId + '/' + id + '/' + vid);
      } else if (inspectionTypeNname.match("Vapor Tightness Certification")) {
         if (inspectionStatus === "DRAFT") {
            navigation("/inspectionVaporTightnessCertificateEdit/" + fkAccountId + '/' + id + '/' + vid);
         } else {
            navigation("/inspectionVaporTightnessCertificateRead/" + fkAccountId + '/' + id + '/' + vid);
         }
      }


   }
   // const document_details = (id, filePath) => {

   //    var extension = filePath.match('[^.]+$');

   //    var document_request =
   //    {

   //       "id": id

   //    };

   //    var requestOptions = requestObject.RequestHeader(document_request);

   //    services.getDocumentDetails(requestOptions).then((res) => {

   //       if (res.customValues.fileByte === "") {
   //          Swal.fire({
   //             position: 'center',
   //             icon: 'error',
   //             title: "",
   //             text: "File is not attached",

   //             showConfirmButton: true

   //          }).then((result) => {
   //             if (result.isConfirmed) {

   //             }
   //          });
   //       } else {

   //          var file;

   //          if (extension == "pdf") {
   //             var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
   //             var byteNumbers = new Array(byteCharacters.length);
   //             for (var i = 0; i < byteCharacters.length; i++) {
   //                byteNumbers[i] = byteCharacters.charCodeAt(i);
   //             }
   //             var byteArray = new Uint8Array(byteNumbers);
   //             file = new Blob([byteArray], { type: 'application/pdf;base64' });
   //             var fileURL = URL.createObjectURL(file);
   //             window.open(fileURL);
   //          }
   //          else if (extension == "jpg") {
   //             // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
   //             var image = new Image();
   //             image.src = "data:image/jpg;base64," + res.customValues.fileByte;

   //             var w = window.open("");
   //             w.document.write(image.outerHTML);
   //          }
   //          else if (extension == "png") {
   //             // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
   //             var image = new Image();
   //             image.src = "data:image/jpeg;base64," + res.customValues.fileByte;

   //             var w = window.open("");
   //             w.document.write(image.outerHTML);
   //          }
   //          else if (extension == "bmp") {
   //             // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
   //             var image = new Image();
   //             image.src = "data:image/bmp;base64," + res.customValues.fileByte;

   //             var w = window.open("");
   //             w.document.write(image.outerHTML);
   //          }
   //          else if (extension == "word") {

   //             var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
   //             var byteNumbers = new Array(byteCharacters.length);
   //             for (var i = 0; i < byteCharacters.length; i++) {
   //                byteNumbers[i] = byteCharacters.charCodeAt(i);
   //             }
   //             var byteArray = new Uint8Array(byteNumbers);
   //             file = new Blob([byteArray], { type: 'application/msword;base64' });
   //             var fileURL = URL.createObjectURL(file);
   //             window.open(fileURL);
   //          }
   //          else if (extension == "docx" || extension == "doc" || extension == "docm" || extension == "dotx" || extension == "dotm") {

   //             var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
   //             var byteNumbers = new Array(byteCharacters.length);
   //             for (var i = 0; i < byteCharacters.length; i++) {
   //                byteNumbers[i] = byteCharacters.charCodeAt(i);
   //             }
   //             var byteArray = new Uint8Array(byteNumbers);
   //             file = new Blob([byteArray], { type: 'application/msword;base64' });
   //             var fileURL = URL.createObjectURL(file);
   //             window.open(fileURL);
   //          }
   //       }

   //    });

   // }
   function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
   }

   function getFilenameAndExtension(str) {

      var file = str.split('/').pop();
      return [file.substr(0, file.lastIndexOf('.')), file.substr(file.lastIndexOf('.') + 1, file.length)]

   }

   const redirect = (page) => {

      navigate(page);

   }

   const redirectResolve = (page) => {
      sessionStorage.setItem("resolveAll", "true");
      navigate(page);

   }

   ////////////////Update Customer/////////////////////////////
   const veh_docs = (id, pageNumber, ITEMS_PER_PAGE_DOCS, title, sortBy, sortDir, displayItems, documentCategoryName, DocumentCategoryId) => {

      if (sortBy === "Doc category") {

         sortBy = "documentCategoryName";
      }
      else if (sortBy === "Doc sub category") {
         sortBy = "docSubCategory";
      }
      else if (sortBy === "Document Date") {

         sortBy = "documentDate";
      }
      else if (sortBy === "Expiry Date") {
         sortBy = "expiryDate";
      }
      else if (sortBy === "Vehicle number") {
         sortBy = "lisencePlateNumber";
      }
      else if (sortBy === "Created By") {
         sortBy = "CreatedBy";
      }
      else {

      } var vehicle_request =
      {

         "fkVehicleId": id,
         "documentCategoryName": "",
         "fkDocumentCategoryId": "",
         "title": title,
         "pageNumber": pageNumber,
         "vehicleTypeId": "",
         "pageSize": ITEMS_PER_PAGE_DOCS,
         "sortBy": sortBy,
         "sortDir": sortDir,
         "isActive": true
      };

      // const requestOptions =
      // {
      //    method: 'POST',
      //    headers: { 'Content-Type': 'application/json' },
      //    body: JSON.stringify(vehicle_request)
      // };

      var requestOptions = requestObject.RequestHeader(vehicle_request);
      services.getVehicle_Docs(requestOptions).then((res) => {

         if (res.success) {
            settotalDocsItems(res.customValues.totalNumberOfRecords);
            settotalDocsPages(res.customValues.totalNumberOfRecords);
            setCurrentPage(res.customValues.currentPageNumber);
            setDocumentList(res ? res.rows : "");
            setTotalDocsRecord(res.customValues.totalNumberOfRecords);
            var displaypages = [];
            if (displayItems === "diplayItems") {
               for (var i = 1; i <= parseInt(Math.ceil(res.customValues.totalNumberOfRecords / itemperPagetoDisplay)); i++) {
                  displaypages.push(i * itemperPagetoDisplay);
               }

               setdisplayPage(displaypages);
            }
         }
         else {

            setDocumentList("");
            setTotalDocsRecord("0");
            setCurrentPage("1");
         }

      });

   }

   const backbuttonHandle = (tabName) => {
      // alert(tabName);
      if (tabName === "vd-tab") {
         sessionStorage.setItem("naviationfrom", "vd-tab");

      } if (tabName === "doc-tab") {
         sessionStorage.setItem("naviationfrom", "doc-tab");
      }
   }

   var array = [];
   var arrayinspection = [];
   function sort_by_key(array, key) {
       return array.sort(function (a, b) {
           var x = a[key]; var y = b[key];
           return ((x < y) ? -1 : ((x > y) ? 1 : 0));
       });
   }
   const cancel = () => {
       setInspecchecked([]);
   }
   function unique(obj) {
       var uniques = [];
       var stringify = {};
       for (var i = 0; i < obj.length; i++) {
           var keys = Object.keys(obj[i]);
           keys.sort(function (a, b) { return a - b });
           var str = '';
           for (var j = 0; j < keys.length; j++) {
               str += JSON.stringify(keys[j]);
               str += JSON.stringify(obj[i][keys[j]]);
           }
           if (!stringify.hasOwnProperty(str)) {
               uniques.push(obj[i]);
               stringify[str] = true;
           }
       }
       return uniques;
   }


   const updateTask = (workorder, inspectionData, assignedDate, Inspectionid, vendorName, vehicleId, vendorId) => {

      console.log("inspection data"+ JSON.stringify(inspectionData));
      $("#garageName").val(vendorName);
      // vehicle_details_api(vehicleId, setVehicleDetails, setCustomerId, setLoading, setCustomerContactList);
      setInspecStatus(inspectionStatus);
      setvendorId(vendorId)
      setVehicleId(vehicleId);
      setIspectionId(Inspectionid);
      setNotes(vechileDetails.inspectionNotes ? vechileDetails.inspectionNotes : "");
      setInspectionTypes("");
      setInspecName("");
      setValue(vendorName);
      $("#updateworkorder").val(workorder ? workorder : "")
      for (var key in inspectionData) {
          array.push(inspectionData[key].name)
          setInspecName(array);
      }


      arrayinspection.push(sort_by_key(inspectionData), 'name');




      // setInspecStatus(inspectionStatus);
      // setCustomerName(customerName);
      var request =
      {

      };


      var requestOptions = requestObject.RequestHeader(request);

      var arrayPay = [];
      var inspectionNames = new Array();
      services.getInspectionTypeS(requestOptions).then((res) => {


          setInspectionTypesStatus(res.rows);
          var dummayarray = res.rows;

          var tempArray = [];

          for (var i = 0; i < dummayarray.length; i++) {

              tempArray.push({
                  "id": dummayarray[i].id, "name": dummayarray[i].name,
                  "status": null
              })

          }





          var confirmedList = tempArray.filter((a) => {

              return !inspectionData.find((p) => p.documentSubCategoryName === a.name);


          });


          setInspecNamearray(confirmedList);



          setInspectionTypesStatus(confirmedList);
          // for(var i=0;i<res.rows.length;i++)
          // {
          //    var booktemp = {
          //       "id" : res.rows[i].id,

          //       "name" :  res.rows[i].name,
          //       "status":
          //    };
          // }

          for (var i = 0; i < sort_by_key(res.rows, 'name').length; i++) {
              for (var j = 0; j < sort_by_key(inspectionData, 'documentSubCategoryName').length; j++) {

                  if (res.rows[i].name === inspectionData[j].documentSubCategoryName) {
                      var inspection =
                      {
                          "id": res.rows[i].id,

                          "name": res.rows[i].name,
                          "status": inspectionData[j].inspectionStatus
                      }

                      inspectionNames.push(inspection);
                  } else {

                  }

              }

              // arrayinspection.push(inspectionData[i].inspectionStatus)



          }
          var dummayarray = res.rows;
          var tempArray = [];
          for (var i = 0; i < dummayarray.length; i++) {

              // const rr=dummayarray[i].name;
              for (var j = 0; j < sort_by_key(inspectionData, 'documentSubCategoryName').length; j++) {

                  if (res.rows[i].name === inspectionData[j].documentSubCategoryName) {

                      tempArray.push({ "id": dummayarray[i].id, "name": dummayarray[i].name, "status": inspectionData[j].inspectionStatus })
                  }
                  else {

                      //tempArray.push({"id":dummayarray[i].id,"name":dummayarray[i].name, "status":null})

                  }

              }


          }

          // tempArray.push(confirmedList);
          var output = confirmedList.concat(tempArray);

          const set = new Set(output.map(item => JSON.stringify(item)));
          const dedup = [...set].map(item => JSON.parse(item));
          //  console.log(`Removed ${tempArray.length - dedup.length} elements`);
          // console.log(dedup);

          setInspecchecked(unique(dedup));
          dedup.filter((obj, pos, arr) => {
              return arr.map(mapObj =>
                  mapObj.name).indexOf(obj.name) == pos;
          });

          // removeDupliactes(standardsList)


      });


      //getInspectionType(setInspectionTypes);

      setOriginalDate(assignedDate ? new Date(assignedDate) : "");
      // setVehicleId(vehicleId);
      setCustomerId(customerId);
      setOrderId(orderId);
  }
  const openModal = () => {
   $('input:checkbox[name=contactListemails]').prop('checked', false).attr('checked', 'checked');
   $('input:checkbox[name=contactSelectAll]').prop('checked', false).attr('checked', 'checked');
}

$('input[name=documentIdss]').change(function(){

   if ($('input:checkbox[name=documentIds]:checked').length> 0) {
      $("#distribute").prop("disabled", false)
   }else{
      $("#distribute").prop("disabled", true)
   }

});

$('input[name=documentIdss]').change(function(){

   if ($('input:checkbox[name=documentIds]:checked').length> 0) {
      $("#distribute").prop("disabled", false)
   }else{
      $("#distribute").prop("disabled", true)
   }

});

 $('input[name=documentIds]').change(function(){

   if ($('input:checkbox[name=documentIds]:checked').length> 0) {
      $("#distribute").prop("disabled", false)
   }else{
      $("#distribute").prop("disabled", true)
   }

});




   return (
      <>
      {LoadingComponentfordocuments}
      {LoadingComponentForSubmit}
         {loadingComponent}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block" }}>
               <div class="row">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <div class="d-flex justify-content-between align-items-end mb-2">
                        <h4 class="font-weight-bold text-uppercase">{vechileDetails.lisencePlateNumber}</h4>
                        <a onClick={() => redirect("/fleetowner/Vehicle")} class="btn btn-white font-weight-bold text-uppercase"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                     </div>
                  </div>
               </div>
               <div class="row mb-2">
                  <div class="col-lg-11 col-md-12 m-auto ">
                     <div class="row no-gutters">
                        <div class="col-md-4 d-flex" style={{ flex: "1" }}>
                           <div class="card w-100 mr-2">
                              <div class="vehicle_pic_wrap">
                                 {/* <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#editPhoto">
                                       <img src="/images/icons/edit-icon.svg"/>
                                       </a>
                                 </span> */}
                                 {/* <img src="/images/vehicle_info.png" class="vehicle-pic"/> */}
                                 <img id="vehicleImage" class="vehicle-pic" />
                              </div>
                           </div>
                        </div>
                        <div class="col-md-8 d-flex" style={{ flex: "1" }} >
                           <div class="card w-100">
                              <div class="row">
                                 <div class="col-md-6">
                                    <div class="p-3 d-flex flex-column">
                                       <label for="" class="opacity-7">Fleet Owners Unit Number </label>
                                       <b>{vechileDetails.unitNumber ? vechileDetails.unitNumber : "N/A"}</b>
                                    </div>
                                 </div>
                                 <div class="col-md-6">
                                    <div class="p-3 d-flex flex-column">
                                       <label for="" class="opacity-7"><span class="lableUpper">VIN</span> Number </label>
                                       <b>{vechileDetails.vinNumber ? vechileDetails.vinNumber : "N/A"}</b>
                                    </div>
                                 </div>
                                 <div class="col-md-6">
                                    <div class="p-3 d-flex flex-column">
                                       <label for="" class="opacity-7">Serial Number</label>
                                       <b>{vechileDetails.serialNumber ? vechileDetails.serialNumber : "N/A"}</b>
                                    </div>
                                 </div>
                                 <div class="col-md-6">
                                    <div class="p-3 d-flex flex-column">
                                       <label for="" class="opacity-7">Make</label>
                                       <b>{vechileDetails.make ? vechileDetails.make : "N/A"}</b>
                                    </div>
                                 </div>
                                 <div class="col-md-6">
                                    <div class="p-3 d-flex flex-column">
                                       <label for="" class="opacity-7">Model</label>
                                       <b>{vechileDetails.model ? vechileDetails.model : "N/A"}</b>
                                    </div>
                                 </div>
                                 <div class="col-md-6">
                                    <div class="p-3 d-flex flex-column">
                                       <label for="" class="opacity-7">Year</label>
                                       <b>{vechileDetails.year ? vechileDetails.year : "N/A"}</b>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="row">
                  {roles.match("Driver") ? "" : <div class="col-lg-11 col-md-12 m-auto">
                     {
                        DueDate ? DueDate.map((data, index) => (
                           <div class="card alerts_card mb-0">
                              <div class="row">
                                 <div class="col d-flex justify-content-between">
                                    {data.status === "Overdue" ?
                                       <div class="alert_cust_wp alert_red align-items-center">
                                          <span class="alert_cust ">
                                             <svg viewBox="0 0 31.502 28.2">
                                                <g transform="translate(-812 -306.672)">
                                                   <path id="Path_605" data-name="Path 605" d="M812.842,334.872a.822.822,0,0,1-.421-.113.978.978,0,0,1-.324-.308.892.892,0,0,1,0-.809l14.925-26.548a.731.731,0,0,1,.291-.308.839.839,0,0,1,.842,0,.982.982,0,0,1,.324.308L843.4,333.641a.892.892,0,0,1,.1.4.723.723,0,0,1-.129.4.732.732,0,0,1-.292.308.822.822,0,0,1-.421.113Zm28.394-1.651-13.5-24.023-13.5,24.023Zm-13.5-6.637a.778.778,0,0,1-.567-.243.814.814,0,0,1-.243-.6v-8.288a.83.83,0,0,1,.81-.809.816.816,0,0,1,.6.243.779.779,0,0,1,.243.567v8.288a.829.829,0,0,1-.842.842Zm0,4.954a.83.83,0,0,1-.81-.809v-1.651a.816.816,0,0,1,.243-.6.778.778,0,0,1,.567-.243.829.829,0,0,1,.842.842v1.651a.778.778,0,0,1-.243.567A.816.816,0,0,1,827.735,331.537Z" fill="#fff" />
                                                </g>
                                             </svg>
                                          </span>

                                          <p class="m-0 pl-4"> {data.unitNumber ? capitalizeFirstLetter(data.unitNumber) : ""} is <span>{data.status}</span> for <b>{data.documentSubCategoryName ? data.documentSubCategoryName : ""}</b>  {moment(data.expiryDate).fromNow()} </p>
                                       </div>
                                       :
                                       <div class="alert_cust_wp alert_orange align-items-center">
                                          <span class="alert_cust "><svg viewBox="0 0 25.623 30.124">
                                             <g transform="translate(-829.586 -453.5)">
                                                <path d="M852.944,483.624H831.851a2.26,2.26,0,0,1-2.265-2.265V460.266a2.15,2.15,0,0,1,.662-1.574,2.182,2.182,0,0,1,1.6-.662h1.5a.741.741,0,0,1,.544.22.708.708,0,0,1,.221.515.753.753,0,0,1-.765.765h-1.5a.74.74,0,0,0-.544.22.708.708,0,0,0-.22.515v21.093a.753.753,0,0,0,.765.765h21.093a.708.708,0,0,0,.515-.22.74.74,0,0,0,.22-.544V460.266a.753.753,0,0,0-.735-.736h-1.5a.753.753,0,0,1-.765-.765.708.708,0,0,1,.221-.515.74.74,0,0,1,.544-.22h1.5a2.183,2.183,0,0,1,1.6.662,2.15,2.15,0,0,1,.662,1.574v21.093a2.26,2.26,0,0,1-2.265,2.265Zm-16.562-22.593a.753.753,0,0,1-.765-.765,3.821,3.821,0,0,1,.5-1.971,3.413,3.413,0,0,1,1.412-1.324q.324-.147.618-.25a2.6,2.6,0,0,1,.529-.132,3.816,3.816,0,0,1,1.294-2.207,3.591,3.591,0,0,1,2.412-.882,3.76,3.76,0,0,1,3.707,3.089,2.554,2.554,0,0,1,.559.132,5.131,5.131,0,0,1,.588.25,3.31,3.31,0,0,1,1.427,1.309,4.056,4.056,0,0,1,.515,1.956v.029a.753.753,0,0,1-.765.765Zm3.766,13.562a.8.8,0,0,1-.559-.235l-2.236-2.236a.748.748,0,0,1,0-1.089.7.7,0,0,1,.515-.206.805.805,0,0,1,.544.206l1.735,1.736,6.237-6.237a.714.714,0,0,1,1.059,0,.657.657,0,0,1,.235.515.747.747,0,0,1-.235.544l-6.766,6.766a.8.8,0,0,1-.559.235Zm-2.942-15.062H847.59a1.874,1.874,0,0,0-.971-1.206,4.022,4.022,0,0,0-1.206-.294.753.753,0,0,1-.765-.765A2.26,2.26,0,0,0,842.383,455a2.15,2.15,0,0,0-1.574.662,2.183,2.183,0,0,0-.662,1.6.753.753,0,0,1-.765.765,4.02,4.02,0,0,0-1.206.294,1.873,1.873,0,0,0-.971,1.206Zm5.178-1.5a.678.678,0,0,1-.279-.059.837.837,0,0,1-.25-.177,1.276,1.276,0,0,1-.147-.25.672.672,0,0,1-.059-.279.779.779,0,0,1,.059-.294.693.693,0,0,1,.147-.236.843.843,0,0,1,.25-.176.678.678,0,0,1,.279-.059.787.787,0,0,1,.294.059,1.036,1.036,0,0,1,.412.412.762.762,0,0,1,0,.588,1.041,1.041,0,0,1-.412.412A.786.786,0,0,1,842.383,458.03Z" transform="translate(0)" fill="#fff"></path>
                                             </g>
                                          </svg></span>

                                          <p class="m-0 pl-4"> {data.unitNumber ? capitalizeFirstLetter(data.unitNumber) : ""} is <span>{data.status}</span> for <b>{data.documentSubCategoryName ? data.documentSubCategoryName : ""}</b>  {moment(data.expiryDate).fromNow()} </p>
                                       </div>

                                    }
                                    {/* <div class="col-auto d-flex align-items-center" >
                                       <a onClick={() => resolve("/fleetowner/AssignToGarrage/" + vehicleId, data.documentSubCategoryName)} class="btn btn-primary">Resolve</a>
                                    </div> */}
                                 </div>
                              </div>
                           </div>
                        )) : ""
                     }


                     {
                        DueDate.length > 0 ?
                           <div class="card alerts_card" id="resolveAll">
                              <div class="row">
                                 <div class="col d-flex align-items-center justify-content-end">
                                    <button onClick={() => redirectResolve("/fleetowner/AssignToGarrage/" + vehicleId)} class="btn btn-success" disabled={vechileDetails.vendorId==null?'':'disabled'}>Resolve</button>
                                 </div>
                              </div>
                           </div>
                           : ""
                     }
                  </div>}

               </div>
                     {
                        vechileDetails.vendorId!=null?
                    
               <div class="row mb-2 ">
                  <div class="col-lg-11 col-md-12 m-auto ">
                  
                     {roles.match("Driver") ? "" :   <div class="card pb-2"><div className="wopopven mt-3" style={{backgroundColor:"#ededed"}}>
                           <div className="wo" style={{backgroundColor:"#d3d3d3"}}>
                              <span>WO: </span>
                              <span>{vechileDetails.workOrderNumber ? vechileDetails.workOrderNumber : "--"}</span>
                           </div>
                           <div className="vn mx-3">
                              <label htmlFor="">Vendor: </label>
                              <span>{vechileDetails.vendorName ? vechileDetails.vendorName : "--"}</span>
                           </div>
                           <div className="vn ml-3">
                              <label htmlFor="">Date: </label>
                              <span>{vechileDetails.assignedDate ? moment(vechileDetails.assignedDate).format('MM-DD-YYYY') : "--"}</span>
                           </div>

                           {roles.match("Driver") ? "" : <a href="" data-toggle="modal" data-backdrop="static" data-keyboard="false" class="btn btn-primary text-uppercase ml-auto" data-target="#updateModal" onClick={() => updateTask(vechileDetails.workOrderNumber, JSON.parse(vechileDetails.inspectionData), vechileDetails.assignedDate, vechileDetails.mvvId, vechileDetails.vendorName, vechileDetails.id, vechileDetails.vendorId)}>EDIT</a>}
                           {/* {roles.match("Driver") ? "" :
                              vechileDetails.inspectionList === null || vechileDetails.inspectionList.length == 0 ? "" : <a href="" data-toggle="modal" data-backdrop="static" data-keyboard="false" class="btn btn-primary text-uppercase ml-auto" data-target="#updateModal">EDIT</a>} */}
                              {/* onClick={() => updateTask(vechileDetails.workOrderNumber, vechileDetails.inspectionList, vechileDetails.assignedDate, vechileDetails.id, vechileDetails.vendorName, vechileDetails.vehicleId, vechileDetails.vendorId)} */}


                        </div></div>}
                     </div></div>:''
                      }

               {/* <div class="row mb-4">
                  <div class="col-lg-11 col-md-12 m-auto ">
                     <div class="card p-3">
                        <div class="banner-owner">
                           <div class="owner-logo">
                              <img id="customerImage" />
                              <div class="owner-name">
                                 <h4 >{vechileDetails.customerName ? vechileDetails.customerName : ""}</h4>
                                 <span>DOT Number: </span> <span>{vechileDetails ? vechileDetails.dotNumber : ""}</span>
                              </div>
                           </div>
                           <div class="banner-contact-info bg-white d-flex flex-column flex-sm-row">
                              <div class="row clearfix">
                                 <div class="col-md-4">
                                    <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                       <img src="/images/icons/map-icon.svg" alt="" class="mr-2" />
                                       <div style={{ minWidth: "240px" }}>
                                          <span >{vechileDetails.addressLine1 ? vechileDetails.addressLine1 : ""}</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-4">
                                    <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                       <img src="/images/icons/phone-icon.svg" alt="" class="mr-2" />
                                       <span >{vechileDetails.customerNumber ? vechileDetails.customerNumber : "N/A"}</span>
                                    </div>
                                 </div>
                                 <div class="col-md-4">
                                    <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                       <img src="/images/icons/message-icon.svg" alt="" class="mr-2" />
                                       <span >{vechileDetails.customerEmail ? vechileDetails.customerEmail : "N/A"}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div> */}



               <div class="row mb-2 ">
                  <div class="col-lg-11 col-md-12 m-auto ">
                     <div>

                        <ul class="nav nav-tabs tab-custome" id="myTab" role="tablist">
                           <li class="nav-item" role="presentation">
                              <a class="nav-link active" id="vd-tab" onClick={() => backbuttonHandle('vd-tab')} data-toggle="tab" href="#vd" role="tab" aria-controls="home" aria-selected="true">Vehicle Details</a>
                           </li>
                           <li class="nav-item" role="presentation">
                              <a class="nav-link" id="doc-tab" onClick={() => backbuttonHandle('doc-tab')} data-toggle="tab" href="#doc" role="tab" aria-controls="profile" aria-selected="false">Documents</a>
                           </li>
                           {/* <li class="nav-item" role="presentation">
                              <a class="nav-link" id="insp-tab" data-toggle="tab" href="#insps" role="tab" aria-controls="contact" aria-selected="false">Inspection</a>
                           </li> */}
                        </ul>
                        <div class="tab-content" id="myTabContent">
                           <div class="tab-pane  active" id="vd" role="tabpanel" aria-labelledby="vd-tab">
                              <div class="card">
                                 <div class="row mb-2">
                                    <div class="col-lg-12 col-md-12 m-auto grid-margin stretch-card ">
                                       <div class="header-top-bg p-3 ">
                                          <h4>Vehicle Information</h4>
                                          <div class="info-labl">
                                             <img src="/images/icons/vehicle-icon.svg" alt="" />| &nbsp;<span>{vechileDetails.unitNumber ? vechileDetails.unitNumber : "--"}</span>
                                          </div>
                                          {roles.match("Driver") || roles.match("Dispatcher") ? "" :

                                             <div class="d-flex ml-auto align-items-center">

                                                <label className="mb-0 mr-2">Status : </label>
                                                <div class="pro-info-item text-capitalize mr-3">

                                                   <ToggleSwitch id="switch" checked={vechileDetails ? vechileDetails.isActive ? true : false : false} onChange={checked => activeVehicle(checked)} />
                                                   {/* {vechileDetails ?vechileDetails.isActive ? "Active" : "Inactive":""}  {vechileDetails ?vechileDetails.isActive ?<a class="btn btn-outline-primary text-uppercase ml-2" onClick={() => activeVehicle("false")}>Inactive</a>:
                                             <a class="btn btn-outline-primary text-uppercase ml-2" onClick={() => activeVehicle("true")}>Active</a>:""} */}

                                                </div>

                                             </div>

                                          }

                                          {roles.match("Driver") || roles.match("Dispatcher") ? "" : <a onClick={() => redirect("/fleetowner/FleetEditVehicle/" + id)} class="btn btn-primary text-uppercase">Edit</a>}

                                       </div>

                                    </div>

                                    <div class="col-md-4">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7">Fleet Owner Unit Number</label>
                                          <b>{vechileDetails.unitNumber ? vechileDetails.unitNumber : "N/A"}</b>
                                       </div>
                                    </div>

                                    <div class="col-md-4">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7" ><span class="lableUpper">VIN</span> Number</label>
                                          <b>{vechileDetails.vinNumber ? vechileDetails.vinNumber : "N/A"}</b>
                                       </div>
                                    </div>

                                    <div class="col-md-4">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7">Serial Number</label>
                                          <b>{vechileDetails.serialNumber ? vechileDetails.serialNumber : "N/A"}</b>
                                       </div>
                                    </div>

                                    <div class="col-md-4">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7">Make</label>
                                          <b>{vechileDetails.make ? vechileDetails.make : "N/A"}</b>
                                       </div>
                                    </div>

                                    <div class="col-md-4">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7">Model</label>
                                          <b>{vechileDetails.model ? vechileDetails.model : "N/A"}</b>
                                       </div>
                                    </div>

                                    <div class="col-md-4">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7">Year</label>
                                          <b>{vechileDetails.year ? vechileDetails.year : "N/A"}</b>
                                       </div>
                                    </div>

                                    <div class="col-md-4">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7">Manufacturer</label>
                                          <b>{vechileDetails.manfacturer ? vechileDetails.manfacturer : "N/A"}</b>
                                       </div>
                                    </div>

                                    <div class="col-md-4">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7">License <span class="lableUpper">P</span>late / <span class="lableUpper">S</span>tate</label>
                                          <b>{vechileDetails.lisencePlateNumber ? vechileDetails.lisencePlateNumber : "--"} / {vechileDetails.vehicleStateName ? vechileDetails.vehicleStateName : "--"}</b>
                                       </div>
                                    </div>


                                 </div>
                                 <div class="divider"></div>
                                 <div class="row mb-2">
                                    <div class="col-md-4">
                                       <div class="p-3 d-flex flex-column">
                                          <label for="" class="opacity-7">Vehicle Type</label>
                                          <b>{vechileDetails.vehicleTypeName ? vechileDetails.vehicleTypeName : "N/A"}</b>
                                       </div>
                                    </div>

                                    {(() => {
                                       if (vechileDetails.vehicleTypeName == 'Trailer') {
                                          return (
                                             <>
                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Trailer Sub Type</label>
                                                      <b>{vechileDetails.trailerSubTypeName ? vechileDetails.trailerSubTypeName : "N/A"}</b>
                                                   </div>
                                                </div>


                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Tanker Sub Type</label>
                                                      <b id="tankersubType">{vechileDetails.tankerSubTypeName ? vechileDetails.tankerSubTypeName : "N/A"}</b>
                                                   </div>
                                                </div>

                                                {(() => {
                                                   if (vechileDetails.trailerSubTypeName == "Tanker" && vechileDetails.tankerSubTypeName == "Other") {
                                                      return (
                                                         <>
                                                            <div class="col-md-4">
                                                               <div class="p-3 d-flex flex-column">
                                                                  <label for="" class="opacity-7">Other</label>
                                                                  <b>{vechileDetails.otherTankerSubType ? vechileDetails.otherTankerSubType : "N/A"}</b>
                                                               </div>
                                                            </div>
                                                         </>)
                                                   }
                                                })()}

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Cargo</label>
                                                      <b>{vechileDetails.cargo ? vechileDetails.cargo : "N/A"}</b>
                                                   </div>
                                                </div>


                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Insulated</label>
                                                      <b>{vechileDetails.isInsulated ? "Yes" : "No"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Lined</label>
                                                      <b>{vechileDetails.isLined ? "Yes" : "No"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Lining Type</label>
                                                      <b>{vechileDetails.liningType ? vechileDetails.liningType : "N/A"}</b>
                                                   </div>
                                                </div>



                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Compartmentalized</label>
                                                      <b> {vechileDetails.isCompartmented ? "Yes" : "No"}</b>
                                                   </div>
                                                </div>

                                                {/* {(() => {
                                                   if (vechileDetails.isCompartmented == true) {
                                                      return (
                                                         <> */}

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">No of Compartments</label>
                                                      <b>{vechileDetails.numberOfCompartments ? vechileDetails.numberOfCompartments : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Compartment capacity in gallons</label>
                                                      {/* <b>{vechileDetails.compartmentCapacityInGallons ? vechileDetails.compartmentCapacityInGallons.replace(/,\s*$/, " ") : "N/A"}</b> */}
                                                      <b>{vechileDetails.compartmentCapacityInGallons ? vechileDetails.compartmentCapacityInGallons.split(",").join(", ") : "N/A"}</b>
                                                   </div>
                                                </div>



                                                {/* </>)
                                                   }
                                                })()} */}

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Capacity Gallons</label>
                                                      <b>{vechileDetails.capacityInGallons ? vechileDetails.capacityInGallons : "N/A"}</b>
                                                   </div>
                                                </div>
                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Is installed with a vapor collection system</label>
                                                      <b>{vechileDetails.isInstalledWithVaporCollectionSys ? "Yes" : "No"}</b>
                                                   </div>
                                                </div>

                                                {(() => {
                                                   if (vechileDetails.isInstalledWithVaporCollectionSys == true) {
                                                      return (
                                                         <>
                                                            <div class="col-md-4">
                                                               <div class="p-3 d-flex flex-column">
                                                                  <label for="" class="opacity-7">Installed by Manufacturer/Retrofitted</label>
                                                                  <b>{vechileDetails.installedByManfacturer === "manufacture" ? "Manufacturer" : "Retrofitted"}</b>
                                                               </div>
                                                            </div>
                                                         </>)
                                                   }
                                                })()}

                                                <div class="divider"></div>
                                                {vechileDetails.vehicleTypeName == 'Trailer' ? vechileDetails.trailerSubTypeName == "Tanker" ?
                                                   <div class="col-md-12 px-4 mt-3 mx-2">
                                                      <h4 class="py-2">Spec Plate</h4>
                                                      <div class="row">
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7"><span class="lableUpper">DOT</span> Classification</label>
                                                               <b>{vechileDetails.dotClassification ? vechileDetails.dotClassification : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Month and Year of manufacture</label>
                                                               <b>{vechileDetails.mnthYrManfacture ? vechileDetails.mnthYrManfacture : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Corrosive Cargo</label>
                                                               <b>{vechileDetails.corrosiveCargo ? "Yes" : "No"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Dead Airspace / Double bulkhead</label>
                                                               <b>{vechileDetails.deadAirspace ? "Yes" : "No"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7"><span className="text-uppercase">MAWP (PSIG)</span></label>
                                                               <b>{vechileDetails.mawp ? vechileDetails.mawp : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Test Pressure <span className="text-uppercase">(PSIG)</span></label>
                                                               <b>{vechileDetails.testPressure ? vechileDetails.testPressure : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Original test date</label>
                                                               <b>{vechileDetails.originatTestDate ? vechileDetails.originatTestDate : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Shell Material</label>
                                                               <b>{vechileDetails.shellMaterial ? vechileDetails.shellMaterial : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Head Material </label>
                                                               <b>{vechileDetails.headMaterial ? vechileDetails.headMaterial : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Weld Material </label>
                                                               <b>{vechileDetails.weldMaterial ? vechileDetails.weldMaterial : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div class="row">
                                                         <div class="col-md-12">
                                                            <div style={{ background: "#f2f2f2", width: "calc(100% - 15px)", padding: "15px 10px" }}>
                                                               <label for="" class="opacity-7">Mfg Shell Thickness </label>
                                                               <div className="row">
                                                                  <div class="col-md-3">
                                                                     <div class="py-3 px-1 d-flex flex-column">
                                                                        <small>Top ends</small>
                                                                        <b>{vechileDetails.mfgShellThicknessTopEnds ? vechileDetails.mfgShellThicknessTopEnds : "N/A"}</b>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-3">
                                                                     <div class="py-3 px-1 d-flex flex-column">
                                                                        <small>Top Center</small>
                                                                        <b>{vechileDetails.mfgShellThicknessTopCentre ? vechileDetails.mfgShellThicknessTopCentre : "N/A"}</b>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-3">
                                                                     <div class="py-3 px-1 d-flex flex-column">
                                                                        <small>Side</small>
                                                                        <b>{vechileDetails.mfgShellThicknessSide ? vechileDetails.mfgShellThicknessSide : "N/A"}</b>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-3">
                                                                     <div class="py-3 px-1 d-flex flex-column">
                                                                        <small>Bottom</small>
                                                                        <b>{vechileDetails.mfgShellThicknessBottom ? vechileDetails.mfgShellThicknessBottom : "N/A"}</b>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <hr />
                                                               <label for="" class="opacity-7">Min Shell Thickness </label>
                                                               <div className="row">
                                                                  <div class="col-md-3">
                                                                     <div class="py-3 px-1 d-flex flex-column">
                                                                        <small>Top ends</small>
                                                                        <b>{vechileDetails.minShellThicknessTopEnds ? vechileDetails.minShellThicknessTopEnds : "N/A"}</b>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-3">
                                                                     <div class="py-3 px-1 d-flex flex-column">
                                                                        <small>Top Center</small>
                                                                        <b>{vechileDetails.minShellThicknessTopCentre ? vechileDetails.minShellThicknessTopCentre : "N/A"}</b>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-3">
                                                                     <div class="py-3 px-1 d-flex flex-column">
                                                                        <small>Side</small>
                                                                        <b>{vechileDetails.minShellThicknessSide ? vechileDetails.minShellThicknessSide : "N/A"}</b>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-3">
                                                                     <div class="py-3 px-1 d-flex flex-column">
                                                                        <small>Bottom</small>
                                                                        <b>{vechileDetails.minShellThicknessBottom ? vechileDetails.minShellThicknessBottom : "N/A"}</b>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>

                                                      </div>
                                                      <div class="row">
                                                         <div class="col-md-6">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Mfg Head Thickness</label>
                                                               <b>{vechileDetails.mfgHeadThickness ? vechileDetails.mfgHeadThickness : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-6">
                                                            <div class="py-3 px-1 d-flex flex-column">
                                                               <label for="" class="opacity-7">Min head thickness</label>
                                                               <b>{vechileDetails.minHeadThickness ? vechileDetails.minHeadThickness : "N/A"}</b>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   : "" : ""}

                                                <div class="col-md-12">
                                                   <hr className="mb-0" />
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Additional Notes</label>
                                                      <b>{vechileDetails.notes ? vechileDetails.notes : "N/A"}</b>
                                                   </div>
                                                </div>
                                             </>

                                          )
                                       }
                                    })()}

                                    {/* ///////////////////////Tractor//////////////////////////// */}

                                    {(() => {
                                       if (vechileDetails.vehicleTypeName == 'Tractor') {
                                          return (
                                             <>
                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Axle Configuration</label>
                                                      <b>{vechileDetails.axleConfiguration ? vechileDetails.axleConfiguration : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Cab configuration</label>
                                                      <b>{vechileDetails.cabConfiguration ? vechileDetails.cabConfiguration : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Connector</label>
                                                      <b>{vechileDetails.connector ? vechileDetails.connector : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Engine Make</label>
                                                      <b>{vechileDetails.engineMake ? vechileDetails.engineMake : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Engine Model</label>
                                                      <b>{vechileDetails.engineModel ? vechileDetails.engineModel : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Transmission</label>
                                                      <b>{vechileDetails.transmission ? vechileDetails.transmission : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Suspension</label>
                                                      <b>{vechileDetails.suspension ? vechileDetails.suspension : "N/A"}</b>
                                                   </div>
                                                </div>
                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Horse power</label>
                                                      <b>{vechileDetails.horsePower ? vechileDetails.horsePower : "N/A"}</b>
                                                   </div>
                                                </div>
                                                <div class="col-md-12">
                                                   <hr className="mb-0" />
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Additional Notes</label>
                                                      <b>{vechileDetails.notes ? vechileDetails.notes : "N/A"}</b>
                                                   </div>
                                                </div>
                                             </>

                                          )
                                       }
                                    })()}

                                    {/* ///////////////////////Truck Details//////////////////////////// */}

                                    {(() => {
                                       if (vechileDetails.vehicleTypeName == 'Truck') {
                                          return (
                                             <>
                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Axle Configuration</label>
                                                      <b>{vechileDetails.axleConfiguration ? vechileDetails.axleConfiguration : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Engine Make</label>
                                                      <b>{vechileDetails.engineMake ? vechileDetails.engineMake : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Engine Model</label>
                                                      <b>{vechileDetails.engineModel ? vechileDetails.engineModel : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Transmission</label>
                                                      <b>{vechileDetails.transmission ? vechileDetails.transmission : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Suspension</label>
                                                      <b>{vechileDetails.suspension ? vechileDetails.suspension : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Length Ft</label>
                                                      <b>{vechileDetails.lengthInFeet ? vechileDetails.lengthInFeet : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Width Ft</label>
                                                      <b>{vechileDetails.widthInFeet ? vechileDetails.widthInFeet : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Height Ft</label>
                                                      <b>{vechileDetails.heightInFeet ? vechileDetails.heightInFeet : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Capacity Tons</label>
                                                      <b>{vechileDetails.capacityTons ? vechileDetails.capacityTons : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Horse power</label>
                                                      <b>{vechileDetails.horsePower ? vechileDetails.horsePower : "N/A"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">isInsulated</label>
                                                      <b>{vechileDetails.isInsulated ? "Yes" : "No"}</b>
                                                   </div>
                                                </div>

                                                <div class="col-md-4">
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">isRefrigerated</label>
                                                      <b>{vechileDetails.isRefrigerated ? "Yes" : "No"}</b>
                                                   </div>
                                                </div>


                                                <div class="col-md-12">
                                                   <hr className="mb-0" />
                                                   <div class="p-3 d-flex flex-column">
                                                      <label for="" class="opacity-7">Additional Notes</label>
                                                      <b>{vechileDetails.notes ? vechileDetails.notes : "N/A"}</b>
                                                   </div>
                                                </div>
                                             </>

                                          )
                                       }
                                    })()}

                                 </div>

                              </div>

                           </div>
                           <div class="tab-pane " id="doc" role="tabpanel" aria-labelledby="doc-tab">
                              <div class="row mb-2">
                                 <div class="col-lg-12 col-md-12 m-auto grid-margin stretch-card">
                                    <div class="card border-top">
                                       <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                                          <div class="search position-relative align-self-start mb-1 mt-1">
                                             <Search
                                                placeholderName="Search by Type / Title"
                                                onSearch={value => {
                                                   searchDocsList(value);
                                                }}

                                             />
                                             <a href="#" class="search-icon">
                                                <img src="/images/icons/search.svg" alt="" />
                                             </a>
                                             {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search by name / Company" class="pl-5"/>
                                                     <a href="#" class="search-icon">
                                                         <img src="/images/icons/search.svg" alt=""/>
                                                     </a> */}

                                          </div>
                                          {/* <div onChange={onChangeValue} class=" d-flex align-items-center">
                                                         <input type="radio" class="mr-2" value="others" name="others" defaultChecked={true} /> Others
                                                         <input type="radio" class="mr-2" value="inspections" name="others" style={{"margin-left":"20px"}} /> Inspection

                                          </div> */}
                                          <div class="d-flex sort align-self-start mt-1 mb-1">

                                             {/* <div class="d-flex align-items-center align-self-start">
                                                       <span class="mr-2">Sort by :</span>
                                                       <div class="dropdown show mr-3 ">
                                                         <a class="btn btn-outline-info dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Day</a>

                                                         <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                           <a class="dropdown-item" href="#">Week</a>
                                                           <a class="dropdown-item" href="#">Month</a>

                                                         </div>
                                                       </div>

                                                    </div> */}
                                             {/* <div class="d-flex align-items-center align-self-start">
                                                      <span class="mr-2">Vehicle Type :</span>
                                                      <div class="dropdown show mr-3">
                                                        <a class="btn btn-outline-info dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Select</a>
                                                        <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                          <a class="dropdown-item" href="#">Tractor</a>
                                                          <a class="dropdown-item" href="#">Truck</a>
                                                          <a class="dropdown-item" href="#">Trailer</a>
                                                        </div>
                                                      </div>

                                                   </div> */}
                                          </div>
                                          <div class="d-flex align-self-start mb-1 mt-1">

                                             {roles.match("Driver") || roles.match("Dispatcher") ? "" : <>
                                             {/* <a onClick={() => document_print()} class="btn btn-primary align-items-center d-flex mr-2"  style={{ "margin-right": "15px", fontSize:"0.875em !important" }}><span className="mr-2"><img src="/img/print.svg" style={{filter:"invert(0.9)"}} alt="" width="18"/></span>  Print Document</a> */}
                                             <button id="distribute"  class="btn btn-success mr-2" data-toggle="modal" data-target="#myModal" onClick={openModal} >Distribute</button> <a onClick={() => redirect("/fleetowner/AddDocument/" + id)} class="btn btn-success">Add Document</a></>}

                                          </div>
                                       </div>
                                       <div class="card-body pt-0 pb-0">

                                          <div class="row">
                                             <div class="col-12">
                                                <div class="table-responsive border-top ">



                                                   <table class="table">
                                                      <thead>
                                                         <tr>
                                                            {headers.map(({ name, field, sortable, thStyle, inlineStyle }) => (
                                                               <th
                                                                  key={name}
                                                                  onClick={() =>
                                                                     sortable ? onSortingChangeDocument(field) : null
                                                                  }
                                                                  class={thStyle} style={inlineStyle}>
                                                                  {name}

                                                                  {sortingField && sortingField === field && (

                                                                     sortingOrder === "asc"
                                                                        ? "↑"
                                                                        : "↓"
                                                                  )}

                                                               </th>
                                                            ))}
                                                         </tr>
                                                      </thead>
                                                      <tbody>
                                                         {
                                                            documentList ? documentList.map((data, index) => (
                                                               <tr>
                                                                  {/* <td style={{ width: "25%", whiteSpace: "break-spaces", lineHeight: "18px", paddingRight: "15px" }}>{data.documentCategoryName ? data.documentCategoryName : "--"} </td> */}
                                                                  <td style={{ width: "3%" }}>
                                                                     <input type="checkbox" name="documentIds" onChange={(event) => sendtocustomerCheck(data.id)} value={data.id} />
                                                                  </td>
                                                                  <td style={{ width: "40%", whiteSpace: "break-spaces", lineHeight: "18px", paddingRight: "15px", textAlign: "left" }}>{data.documentSubCategoryName ? data.documentSubCategoryName : "--"} </td>
                                                                  <td style={{ width: "30%", whiteSpace: "break-spaces", lineHeight: "18px", paddingRight: "15px", textAlign: "left" }}>{data.title ? data.title : "--"}</td>
                                                                  <td style={{ width: "12%", whiteSpace: "break-spaces", lineHeight: "18px", paddingRight: "15px" }}>{data.unitNumber ? data.unitNumber : "--"}</td>
                                                                  {/* <td>{data.documentDate ? data.documentDate : "--"}</td> */}
                                                                  {/* <td style={{ width: "10%", whiteSpace: "break-spaces", lineHeight: "18px", paddingRight: "15px" }}>{data.documentDate ? data.documentDate : "--"}</td> */}
                                                                  <td style={{ width: "12%", whiteSpace: "break-spaces", lineHeight: "18px", paddingRight: "15px" }}>{data.expiryDate ? moment(data.expiryDate).format('MM-DD-YYYY') : "--"}</td>
                                                                  {/* <td>{data.createUser ? data.createUser : "--"}</td> */}
                                                                  <td style={{ width: "50px" }}><a onClick={() => document_details(data.id, data.filePath)} class="btn btn-outline-primary text-uppercase">View</a>&nbsp;
                                                                     {roles.match(Owner) ? <a onClick={() => deleteDocuments(data.id, data.unitNumber != '' ? data.unitNumber : '', data.title != '' ? data.title : '')} class="btn btn-outline-primary text-uppercase">Delete</a> : ""}&nbsp;
                                                                     {data.documentCategoryName === "Inspection Documents" ? "" : roles.match("Driver") || roles.match("Dispatcher") ? "" : <a onClick={() => redirect("/fleetowner/EditDocument/" + cid + "/" + id + "/" + data.id)} class="btn btn-outline-primary text-uppercase">Edit</a>}

                                                                  </td>
                                                                  {/* <td></td> */}
                                                               </tr>
                                                            )) : ""

                                                         }


                                                      </tbody>
                                                   </table>
                                                </div>
                                                {documentList ? documentList.map((data, index) => { }) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span></div>}
                                             </div>
                                          </div>
                                       </div>

                                       {documentList ? <div class="d-flex align-items-center justify-content-between p-3">
                                          <div class="d-flex">
                                             <div class="dropdown show mr-3 ">
                                                {/* <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                   25
                                                   </a>
                                                   <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                      <a class="dropdown-item" href="#">25</a>
                                                      <a class="dropdown-item" href="#">50</a>
                                                   </div> */}
                                                <select class="btn gray-mid-color dropdown-toggle display_pages_in_items form-control custome-select" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onChange={onChangeValue}>
                                                   {
                                                      displayPage.map((data, index) => (
                                                         <option value={data}>{data}</option>
                                                      ))
                                                   }
                                                </select>
                                             </div>
                                             <span class="d-flex align-items-center pagenation-mob">{currentPage * ITEMS_PER_PAGE_DOCS <= totalDocsRecors ? currentPage * ITEMS_PER_PAGE_DOCS : totalDocsRecors} out of {totalDocsRecors} Records Displayed</span>
                                          </div>
                                          <nav aria-label="Page navigation example">
                                             <Pagination
                                                total={totalDocsRecors}
                                                itemsPerPage={ITEMS_PER_PAGE_DOCS}
                                                currentPage={currentPage}
                                                //onPageChange={page => setCurrentPage(page)}
                                                onPageChange={handlePageChange}
                                             />
                                             {/* <ul class="pagination mb-0">
                                                   <li class="page-item">
                                                      <a class="page-link" href="#" aria-label="Previous">
                                                      <span aria-hidden="true">«</span>
                                                      <span class="sr-only">Previous</span>
                                                      </a>
                                                   </li>
                                                   <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                   <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                   <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                   <li class="page-item">
                                                      <a class="page-link" href="#" aria-label="Next">
                                                      <span aria-hidden="true">»</span>
                                                      <span class="sr-only">Next</span>
                                                      </a>
                                                   </li>
                                                </ul> */}
                                          </nav>
                                       </div> : ""}

                                       {/* <div class="d-flex align-items-center justify-content-between p-3">
                                                 <div class="d-flex">
                                                  <div class="dropdown show mr-3 ">
                                                      <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        25
                                                      </a>

                                                      <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                        <a class="dropdown-item" href="#">25</a>
                                                        <a class="dropdown-item" href="#">50</a>

                                                      </div>
                                                    </div>
                                                    <span class="d-flex align-items-center pagenation-mob">25 out of 72 Records Displayed</span>
                                                 </div>
                                                  <nav aria-label="Page navigation example">
                                                      <ul class="pagination mb-0">
                                                        <li class="page-item">
                                                          <a class="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true">«</span>
                                                            <span class="sr-only">Previous</span>
                                                          </a>
                                                        </li>
                                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                        <li class="page-item">
                                                          <a class="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true">»</span>
                                                            <span class="sr-only">Next</span>
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </nav>
                                              </div> */}

                                    </div>

                                 </div>
                              </div>
                           </div>
                           <div class="tab-pane " id="insps" role="tabpanel" aria-labelledby="insp-tab">
                              <div class="row mb-2">
                                 <div class="col-lg-12 col-md-12 m-auto grid-margin stretch-card">
                                    <div class="card border-top">
                                       <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                                          {/* <div class="search position-relative align-self-start mb-1 mt-1">
                                                      <input type="search" id="gsearch" name="gsearch" placeholder="Search by name / Company" class="pl-5"/>
                                                     <a href="#" class="search-icon">
                                                         <img src="/images/icons/search.svg" alt=""/>
                                                     </a>
                                                  </div> */}

                                          <div class="search position-relative align-self-start mb-1 mt-1">
                                             {/* <Search
                                                placeholderName="Search with inspection type"
                                                onSearch={value => {
                                                   searchInspectionList(value);
                                                }}

                                             /> */}


                                             {/* <a href="#" class="search-icon">
                                                <img src="/images/icons/search.svg" alt="" />
                                             </a> */}
                                             {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search by name / Company" class="pl-5"/>
                                                     <a href="#" class="search-icon">
                                                         <img src="/images/icons/search.svg" alt=""/>
                                                     </a> */}
                                          </div>

                                          {/* <div class="d-flex sort align-self-start mt-1 mb-1">

                                                         <div class="d-flex align-items-center align-self-start">
                                                         <span class="mr-2">Vehicle Type :</span>
                                                         <div class="dropdown show mr-3">
                                                            <a class="btn btn-outline-info dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Select</a>
                                                            <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                               <a class="dropdown-item" href="#">Tractor</a>
                                                               <a class="dropdown-item" href="#">Truck</a>
                                                               <a class="dropdown-item" href="#">Trailer</a>
                                                            </div>
                                                         </div>

                                                      </div>
                                                  </div> */}

                                          <div class="d-flex align-self-start mb-1 mt-1">
                                             <select class="form-control" id="inspectionType" onChange={e => searchInspectionList(e.target.value)}>


                                                <option value="">Select</option>
                                                {
                                                   inspectionTypes ? inspectionTypes.map((inspection, index) => (
                                                      <option value={inspection.name}>{inspection.name}</option>
                                                   )) : <option value=""></option>
                                                }
                                                {/* <option value="vapor tightness certificate">vapor tightness certificate</option> */}
                                             </select>&nbsp;&nbsp;&nbsp;
                                             <a onClick={() => redirect("/fleetowner/AddInspection/" + cid + "/" + id)} class="btn btn-success" >New Inspection</a>
                                          </div>
                                       </div>
                                       <div class="card-body pt-0 pb-0">

                                          <div class="row">
                                             <div class="col-12">
                                                <div class="table-responsive border-top border-bottom">



                                                   <table class="table">
                                                      <thead>
                                                         <tr>
                                                            {Inspectionheaders.map(({ name, field, sortable, thStyle, inlineStyle }) => (
                                                               <th
                                                                  key={name}
                                                                  onClick={() =>
                                                                     sortable ? onSortingChangeDocument(field) : null
                                                                  }
                                                                  class={thStyle} style={inlineStyle}>
                                                                  {name}

                                                                  {sortingField && sortingField === field && (

                                                                     sortingOrder === "asc"
                                                                        ? "↓"
                                                                        : "↑"
                                                                  )}

                                                               </th>
                                                            ))}
                                                         </tr>
                                                      </thead>
                                                      <tbody>


                                                         {
                                                            inspectionLists ? inspectionLists.map((inspection, index) => (
                                                               <tr>
                                                                  <td>{inspection.inspectionType ? inspection.inspectionType.name : "--"}</td>

                                                                  <td>{inspection.inspectedDate ? inspection.inspectedDate : "--"}</td>
                                                                  <td>{inspection.inspectedBy ? inspection.inspectedBy : "--"}</td>
                                                                  <td>{inspection.expiryDate ? inspection.expiryDate : "--"}
                                                                     {/* {(() => {
                                                                        if (inspection.status == 'approve pending' || inspection.status == "Approved pending" || inspection.status == "APPROVED PENDING") {
                                                                           return (
                                                                              <span class=" status-txt approve-pending">{inspection.status ? inspection.status : "--"}</span>
                                                                           )
                                                                        } else if (inspection.status == 'Rejected') {
                                                                           return (
                                                                              <span class=" status-txt reject">{inspection.status ? inspection.status : "--"}</span>
                                                                           )
                                                                        } else if (inspection.status == 'draft') {
                                                                           return (
                                                                              <span class=" status-txt draft">{inspection.status ? inspection.status : "--"}</span>
                                                                           )
                                                                        } else if (inspection.status == 'approved' || inspection.status == 'approve'||inspection.status == 'APPROVED') {
                                                                           return (
                                                                              <span class=" status-txt approved">{inspection.status ? inspection.status : "--"}</span>
                                                                           )
                                                                        } else {
                                                                           return (
                                                                              <>{inspection.status ? inspection.status : "--"}</>
                                                                           )
                                                                        }
                                                                     })()} */}
                                                                     {/* {inspection.status ? inspection.status : "--"} */}
                                                                  </td>
                                                                  <td style={{ width: "50px" }}><a onClick={() => inspection_details(inspection.inspectionType.name, inspection.status, inspection.fkAccountId, inspection.id)} class="btn btn-outline-primary text-uppercase" >
                                                                     {(() => {
                                                                        if (inspection.status == 'approved' || inspection.status === 'Reject' || inspection.status === 'approve' || inspection.status === 'APPROVED PENDING' || inspection.status === 'approve pending' || inspection.status === 'Rejected' || inspection.status == 'APPROVE PENDING' || inspection.status == 'APPROVED') {
                                                                           return (
                                                                              <>View</>
                                                                           )
                                                                        } else {
                                                                           return (
                                                                              <>Edit</>
                                                                           )
                                                                        }
                                                                     })()}

                                                                  </a>
                                                                     {roles.match(Manager) || roles.match(Owner) ? <a onClick={() => deleteInspectionDocuments(inspection.id)} class="btn btn-outline-primary text-uppercase">Delete</a> : ""}
                                                                  </td>
                                                               </tr>
                                                            )) : ""
                                                         }



                                                         {/* <tr>
                                                            <td>External Visual Inspection</td>
                                                            <td>External Visual Inspection </td>
                                                            <td>05-08-2020</td>
                                                            <td>James</td>
                                                            <td><span class=" status-txt approve-pending ">Approve Pending</span></td>
                                                            <td><a href="#" class="btn btn-outline-primary text-uppercase">View</a></td>
                                                         </tr> */}


                                                      </tbody>
                                                   </table>
                                                </div>

                                             </div>
                                             {inspectionLists ? inspectionLists.map((data, index) => { }) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span></div>}
                                          </div>
                                       </div>
                                       {inspectionLists ?
                                          <div class="d-flex align-items-center justify-content-between p-3">
                                             <div class="d-flex">
                                                <div class="dropdown show mr-3 ">
                                                   {/* <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        25
                                                      </a>

                                                      <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                        <a class="dropdown-item" href="#">25</a>
                                                        <a class="dropdown-item" href="#">50</a>

                                                      </div> */}
                                                   <select class="btn gray-mid-color dropdown-toggle  form-control custome-select" id="display_pages_in_items" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                      {
                                                         displayPageForInspection.map((data, index) => (
                                                            <option value={data}>{data}</option>
                                                         ))
                                                      }
                                                   </select>
                                                </div>
                                                <span class="d-flex align-items-center pagenation-mob">{inspectioCurrentPage * ITEMS_PER_PAGE <= totalInspectionPages ? inspectioCurrentPage * ITEMS_PER_PAGE : totalInspectionPages} out of {totalInspectionPages} Records Displayed</span>
                                             </div>
                                             <nav aria-label="Page navigation example">
                                                <Pagination
                                                   total={totalInspectionPages}
                                                   itemsPerPage={ITEMS_PER_PAGE}
                                                   currentPage={inspectioCurrentPage}
                                                   //onPageChange={page => setCurrentPage(page)}
                                                   onPageChange={handlePageChangeForInspection}
                                                />

                                             </nav>
                                          </div> : ""}

                                    </div>

                                 </div>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
            {/* <!-- Modal --> */}
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Contact</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <div class="row">
                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">First Name</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" maxLength="20" minLength="2" aria-describedby="emailHelp" />
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Last Name</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" maxLength="20" minLength="2" aria-describedby="emailHelp" />
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Email ID</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Desk Phone</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Mobile Phone</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleFormControlSelect1">Role in Company</label>
                                 <select class="form-control custome-select" id="exampleFormControlSelect1">
                                    <option>One</option>
                                    <option>Two</option>
                                    <option>Three</option>
                                 </select>
                              </div>
                           </div>

                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleFormControlSelect1">Status</label>
                                 <select class="form-control custome-select" id="exampleFormControlSelect1">
                                    <option>Active</option>
                                    <option>Inactive</option>

                                 </select>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                     </div>
                  </div>
               </div>
            </div>
            <UpdateUpcomingAndoverdue Id={Id} vendorId={vendorId} vehicleId={vehicleId} originalDate={originalDate} setOriginalDate={setOriginalDate} inspectiondisable={inspectiondisable} notes={vechileDetails.inspectionNotes} inspectionNames={inspectionNames} setInspecchecked={setInspecchecked}/>
         </div>

         {/* Send to customer */}
         <div id="myModal" class="modal fade" role="dialog">
               <div class="modal-dialog">


                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Distribute</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModelDistribute">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body sc-mod-pop pl-2">
                        
                        <div>
                           {/* <div class="col-md-12"><input type="checkbox" class="form-check-input" name="contactSelectAll" onClick={(e) => SelectAllContactList(e, setChecked)} />
                              </div> */}
                           

                           {/* <p id="emailError" class="error" style={{marginLeft:'15px'}}></p> */}
                           <lable style={{paddingLeft:"12px", display:"flex"}}>Please enter one or more emails to send the documents to.</lable>
                                 <AddRemoveInputField setInputList={setInputEmailList} inputList={emailforsendtoct}/>
                                
                        </div>

                     </div>

                     <div class="modal-footer">
                        <button type="button" class="btn btn-primary"  onClick={() => SendtocustomerFleet(checked, 'doc-tab',emailforsendtoct)} >Submit</button>
                     </div>
                  </div>

               </div>
            </div>
      </>
   )
};

FleetVehicleInformation.propTypes = {};

FleetVehicleInformation.defaultProps = {};

export default FleetVehicleInformation;
