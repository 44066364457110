import moment from "moment-timezone";

const getDateAndTimeStamp = time => {
   
     
  var cdt = moment(time, 'HH:mm');

  return cdt.format('YYYY-MM-DD HH:mm');


}

export default getDateAndTimeStamp;