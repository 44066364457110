import React,{history,useEffect, useState, useMemo } from "react";
import { useNavigate ,useParams} from "react-router-dom";
import CheckAuthentication from "./checkAuthentication";
import $ from "jquery";
import Swal from "sweetalert2";
import services from "../services/services";
import { requestObject } from "./requestObject";
const AddDynamicInspectionList = ({inputList, setInputList,setBase64file,setfileName}) => 
{
      var fileNamesArray=[];
      const [ fileName, setfileNames ] = useState([]);
      const navigate = useNavigate();
      const [inspectionList,setInspectionList] = useState([]);
      useEffect(() => 
      {
        if(CheckAuthentication)
        {
        }
        else
        {
          sessionStorage.clear();
          localStorage.clear();
          //navigate("/login");
              window.location.href="/login";
        }
        inspectionListApi();

      },[]); 
      
      
      const inspectionListApi=()=>
{
    var inspectionListReq=
    {
        
    };

 var requestOptions=requestObject.RequestHeader(inspectionListReq);
//  services.getInspectionDocumentSubCategories(requestOptions).then((res) => 
 services.getInspectionTypeForAssignToGarage(requestOptions).then((res) => 
 {
     console.log("@@@@@Inspection list@@@@@@"+res.rows);
    setInspectionList(res.rows);
 });
}


      // handle input change
   const handleInputChange = (e, index) => 
    {
      const { name, value,filename } = e.target;
      const list = [...inputList];
      setfileNames(value);
      list[index][name] = value;
      list[index][filename] = value;
      setInputList(list);
    };
     
    // handle click event of the Remove button
    const handleRemoveClick = index => {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    };
     
    // handle click event of the Add button
    const handleAddClick = (e,index) => {
      const { name, value,filename } = e.target;
      const list = [...inputList];
     
  
      if(list[index][filename]==""||list[index][filename]=="undefined"||list[index][filename]==undefined)
      { 
        
        Swal.fire({  
          position: 'center',  
          icon: 'error',  
          title: "",  
          text:"Please Select Inspection Name",
          showConfirmButton: true 
          
        }).then((result) => 
        {
              
              if(result.isConfirmed) 
              {
                
              
              }
        });
        return false;

      }else{
        setInputList([...inputList, {}]);
      }
      
    };
   
  
   

      return(<>
    
                             {/* <div class="row p-3">
                                <div class="col-lg-12">
                                    <div class="table-1"> */}
                                  {inputList?inputList.map((x, i) => 
                                                {
                                                return (
                                                  
                                                  
                                                      
                                                   <div class="d-flex justify-content-between">
                                                        <div className="box">
                                                        {/* <input
                                                            name="title"
                                                            placeholder="Enter Doc Name"
                                                            value={x.docName}
                                                            id={"docName"+i}
                                                            onChange={e => handleInputChange(e, i)}
                                                        /> */}
                                                        <select class="form-control custome-select mr-4 w-80"   name="inspectionName[]"
                                                    
                                                            value={x.inspectionName}
                                                            id={"inspectionName"+i}
                                                            onChange={e => handleInputChange(e, i)} style={{width:"365px"}} >
                                                        <option value="" >--- Select Inspection Name ---</option>
                                                        {
                                                              inspectionList?inspectionList.map((data,index) => (
                                                                <option value={data.id}>{data.name}</option>
                                                           
                                                               )):""
                                                         }
                                                        </select>
                                                        
 
                                                                    <div className="btn-box">
                                                                        {inputList.length !== 1 && 
                                                                        <img src="/images/icons/remove-minus.svg"  onClick={() => handleRemoveClick(i)} alt="upload"/>
                                                                        // <button
                                                                        // className="mr10"
                                                                        // onClick={() => handleRemoveClick(i)}>Remove</button>}
                                                                        }
                                                                        
                                                                        {inputList.length - 1 === i &&  <img src="/images/icons/add-plus.svg"  onClick={(e) =>handleAddClick(e, i)} />}
                                                                    </div>
                                                                   
                                                                    </div>
                                                                    
                                                   </div>
                                                                );
                                                                }):""}
                                                              
                                                 
                                    {/* </div>
                                </div>
                            </div> */}
      </>)
}

AddDynamicInspectionList.propTypes = {};

AddDynamicInspectionList.defaultProps = {};

export default AddDynamicInspectionList;