import React,{history,useEffect, useState, useMemo } from "react";
import Adddocs from "../../../../utility/Adddocs";
import { useNavigate ,useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import  $ from "jquery";
import {useLoading} from 'react-hook-loading';
import services from "../../../../services/services";
import Swal from "sweetalert2";
import { requestObject } from "../../../../utility/requestObject";
import AddInspectionName from "../../../../utility/addInspectionName";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const InternalVisual = () => {
  const { taskOrderId,cid, vid } = useParams();
  const [inputList, setInputList] = useState([{ }]);
  const [base64file, setBase64file] = useState('');
  const [loading, setLoading] = useLoading("Please wait...");
  const [ fileName, setfileName ] = useState("");
  const navigate = useNavigate();
  const [endDate, setEndDate] = useState(new Date());
  const [compartments, setcompartments] = useState();
  var compartmentReq;
  var jsonStr=[];
  const handleSubmit=(status)=>
  {

    if(status==="DRAFT")
    {

      return true;
       
    }

let form_is_valid = true;

var sec1corroded=$("input[name='sec1corroded']:checked").val();
var sec1Dents=$("input[name='sec1Dents']:checked").val();
var sec1Distortion=$("input[name='secinternalDistortion']:checked").val();
var sec1Welds=$("input[name='sec1Welds']:checked").val();
var sec1Kind=$("input[name='sec1Kind']:checked").val();
var sec1baffles=$("input[name='sec1baffles']:checked").val();
var sec1emergency=$("input[name='sec1emergency']:checked").val();
var sec1lining=$("input[name='sec1lining']:checked").val();
var sec1tube=$("input[name='sec1tube']:checked").val();
var sec1nozzles=$("input[name='sec1nozzles']:checked").val();
var sec1unsafe=$("input[name='sec1unsafe']:checked").val();
var sec1service=$("input[name='sec1service']:checked").val();

var sec2corroded=$("input[name='sec2corroded']:checked").val();
var sec2Dents=$("input[name='sec2Dents']:checked").val();
var sec2Distortion=$("input[name='secinternalDistortion2']:checked").val();
var sec2Welds=$("input[name='sec2Welds']:checked").val();
var sec2kind=$("input[name='sec2kind']:checked").val();
var sec2service=$("input[name='sec2service']:checked").val();
var sec2tank=$("input[name='sec2tank']:checked").val();

 
if(sec1corroded==undefined||sec1Dents==undefined||sec1Distortion==undefined||sec1Welds==undefined||sec1Kind==undefined||sec1baffles==undefined||sec1emergency==undefined||sec1lining==undefined||sec1tube==undefined||sec1nozzles==undefined||sec1unsafe==undefined||sec2corroded==undefined||sec2Dents==undefined||sec2Distortion==undefined||sec2Welds==undefined||sec2kind==undefined||sec2service==undefined||sec2tank==undefined){
  // $('html, body').animate({
  //   scrollTop: $("#inspectionType").offset().top
  // }, 500);
  $('#error_message').html("*Please choose one option for each question");
  $('html,body').animate({ scrollTop: "600px" }, 1000);
  form_is_valid= false;

}else{
  $('#error_message').html(''); 
}
// alert(form_is_valid);
return form_is_valid;

}
 useEffect(() =>
  {

           const compGallons=sessionStorage.getItem("compartmentCapacityInGallons");
            if(compGallons==null){
                compartmentReq=''  
            }else{
                compartmentReq= compGallons.toString().replace(/,(?=\s*$)/, '').split(',')
            }
             
  // compartmentReq = sessionStorage.getItem("compartmentCapacityInGallons").toString().replace(/,(?=\s*$)/, '').split(',');
  

  setcompartments(compartmentReq);
  
}, []);
var sum = 0;
$('#checkAll').click(function () 
{ 
    var checked = $(this).is(":checked");
   if(checked)  
   {
     sum=0;
    for (var i = 0; i < compartments.length; i++) 
    {
        $('input[name=sec'+i+'Distortion').prop('checked', this.checked); 
        var comp=$("#comp" + i + "gallons").val();
        sum+= parseFloat(comp);


    }  
       if(isNaN(Math.abs(sum)))
        {
            $("#allValues").val("")
        }else{
            $("#allValues").val(Math.abs(sum))
        }
   
}
else
{
   
     sum = 0;
    for (var i = 0; i < compartments.length; i++) 
    {
        $('input[name=sec'+i+'Distortion').prop('checked',checked); 
        
    } 
    $("#allValues").val("");
}

});
  const save=(status)=>
  {

  // console.log("results=="+JSON.stringify(inputList));
    for(var i=0;i<compartments.length;i++)
    {
      
      jsonStr.push({"working":$("input[name=sec"+(i)+"Distortion]:checked").val()==="1"?"1":"0",
        "safeFillAmountInGals":$("#comp"+(i)+"gallons").val()?$("#comp"+(i)+"gallons").val():"0",
      })
    }
    
    
  if(handleSubmit(status)==true){
      var documentList=[];
  
      try
      {
        for(var i=0;i<inputList.length;i++)
        {
            // console.log("filebase64=="+inputList[i].filetobase64);
            var extension="";
            var byteString="";
            if(inputList[i].filetobase64.match("data:application/pdf;base64,"))
            {
                extension="pdf";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/pdf;base64,", ""):"";
            }else if(inputList[i].filetobase64.match("data:image/jpeg;base64,"))
            {
                extension="jpg";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/jpeg;base64,", ""):"";
            }
            else if(inputList[i].filetobase64.match("data:image/png;base64,"))
            {
                extension="png";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/png;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/msword;base64,"))
              {
                  extension="word";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/msword;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:image/bmp;base64,"))
              {
                  extension="bmp";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/bmp;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
              {
                  extension="docx";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
              {
                  extension="docx";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
              {
                  extension="docx";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
              {
                  extension="docx";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
              {
                  extension="docx";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
              {
                  extension="docm";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,"))
              {
                  extension="docx";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
              {
                  extension="dotx";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
              {
                  extension="dotx";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,"))
              {
                  extension="dotm";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", ""):"";
              }
              else{
                 
              }
   
   
                documentList.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
          }
      }catch(exception)
      {
        // Swal.fire({  
        //     position: 'center',  
        //     icon: 'error',  
        //     title: "",  
        //     text:"Please attach document",
        //     showConfirmButton: true 
            
        //   }).then((result) => 
        //   {
                
        //         if(result.isConfirmed) 
        //         {
                  
                
        //         }
        //   });
        //   return false;
        documentList.push();
      }
      if($("#inspectedBy").val()==="")
      {
        Swal.fire({  
            position: 'center',  
            icon: 'error',  
            title: "",  
            text:"Please enter inspectedBy",
            showConfirmButton: true 
            
          }).then((result) => 
          {
                
                if(result.isConfirmed) 
                {
                  
                
                }
          });
          return false;
      }
        
        var requestObjectForInsp=
        {
          
          "type":"Internal Visual Inspection",
          "workOrderNumber":$("#workOrderNumber").val(),
          "notes":$("#notes").val(),
          "inspectedBy":  $("#inspectedBy").val(),
          "inspectedDate":$("#inspectedDate").val(),
          "verifiedBy": $("#verifiedBy").val(),
          "verifiedDate": $("#verifiedBy").val(),
          "status": status,
          "taskOrderId":taskOrderId,
          "inspectionType": {
            "id": sessionStorage.getItem("selectedinspectionId"),
            "name":sessionStorage.getItem("selectedinspectionType")
          },
          "result":
          {
            "approvedCertify": "0",
            "inspectionFailed": "0"
          },
          "dispositionOfVehicle":
          {
            "vehicleReturnedServices": "0",
            "vehicleWithdrawnFromService": "0",
            "vehicleSentToAnotherRepairFacility": "0"
          },
          "vehicleDetails":
          {
              "vinNumber": $("#vin").html(),
              "lisencePlateNumber": $("#lisencePlateNumber").html(),
              "id":localStorage.getItem("vehicleId"),
              "unitNumber": $("#lisencePlateNumber").html()
          },
          "internalVisualInspection": {
            "section1": {
              "corroded": $("input[name='sec1corroded']:checked").val()==="1"?"1":"0",
              "dents": $("input[name='sec1Dents']:checked").val()==="1"?"1":"0",
              "distortions": $("input[name='secinternalDistortion']:checked").val()==="1"?"1":"0",
              "defectsInWelds": $("input[name='sec1Welds']:checked").val()==="1"?"1":"0",
              "leaksOfAnyKind": $("input[name='sec1Kind']:checked").val()==="1"?"1":"0",
              "defectInBaffels":$("input[name='sec1baffles']:checked").val()==="1"?"1":"0",
              "defectInInterios": $("input[name='sec1emergency']:checked").val()==="1"?"1":"0",
              "defectInLining": $("input[name='sec1lining']:checked").val()==="1"?"1":"0",
              "defectIndiptube":$("input[name='sec1tube']:checked").val()==="1"?"1":"0",
              "defectInCleanoutPorts": $("input[name='sec1nozzles']:checked").val()==="1"?"1":"0",
              "anyConditionThatmightRender": $("input[name='sec1unsafe']:checked").val()==="1"?"1":"0",
              "transportationService": $("input[name='sec1service']:checked").val()==="1"?"1":"0"
            },
            "section2": {
              "corroded": $("input[name='sec2corroded']:checked").val()==="1"?"1":"0",
              "dents": $("input[name='sec2Dents']:checked").val()==="1"?"1":"0",
              "distortions": $("input[name='secinternalDistortion2']:checked").val()==="1"?"1":"0",
              "defectsInWelds": $("input[name='sec2Welds']:checked").val()==="1"?"1":"0",
              "leaksOfAnyKind": $("input[name='sec2kind']:checked").val()==="1"?"1":"0",
              "anyConditionThatmightRender": $("input[name='sec2service']:checked").val()==="1"?"1":"0",
              "tankMarking": $("input[name='sec2tank']:checked").val()==="1"?"1":"0"
              // "notes": $("input[name='notes']").val()!=""?$("input[name='notes']").val():""
            },
            "compartmentList":jsonStr,
          },
          
          "documentList": documentList
        
      }; 
      $("#loadingforsubmit").show();

      // setLoading(true);
      // const requestOptions = 
      // {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json'
      //             },
                  
      //       body: JSON.stringify(requestObject)
      // };
      var requestOptions=requestObject.RequestHeader(requestObjectForInsp);
      services.addInspection(requestOptions).then((res) => 
      {
        $("#loadingforsubmit").hide();
        // setLoading(false);
        Swal.fire({  
          position: 'center',  
          icon: 'success',  
          title: "",  
          text:res.message,
          showConfirmButton: true 
          
        }).then((result) => 
        {
              
              if(result.isConfirmed) 
              {
                navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));
              
              }
        });
      });
    }

  } 
  const NumericOnly= (e) => 
  { //angka only
      const reg = /^[0-9\b]+$/
      let preval=e.target.value
      if (e.target.value === '' || reg.test(e.target.value)) return true
      else e.target.value = preval.substring(0,(preval.length-1))
  }
  const redirect=(page)=>
  {

     navigate(page);
  
  }
  const handleChange=(e,index) => 
  {
      
      let isChecked = e.target.checked;
      $('#checkAll').prop('checked', false);
     
      if(isChecked)
      {
          $('input[name=sec'+index+'Distortion').prop('checked', isChecked);
          var comp=$("#comp" + index + "gallons").val();
          sum+= parseFloat(comp);
          $("#allValues").val(Math.abs(sum)); 
      }else{
          
          var comp=$("#comp" + index + "gallons").val();
          sum-= parseFloat(comp);
          $("#allValues").val(Math.abs(sum)); 
      }
    
      
     
  }
  const textChange=(e,index) => 
  {
      
    
          //$('input[name=sec'+index+'Distortion').prop('checked', isChecked);
         // var comp=$("#comp" + index + "gallons").val();
          
         // sum+= parseFloat(comp);
        //  $("#allValues").val(Math.abs(sum)); 
           sum=0;
          for (var i = 0; i < compartments.length; i++) 
          {
              
              var comp=$("#comp" + i + "gallons").val();
              sum+= parseFloat(comp);
  
  
          }  
          if(isNaN(Math.abs(sum)))
          {
              $("#allValues").val("")
          }else{
              $("#allValues").val(Math.abs(sum))
          }
         
      
     
  }
  return(
  <>
     {LoadingComponentForSubmit}
            
                 <div class="row mb-2">
                     <div class="col-lg-12 col-md-12 m-auto ">
                        <div class="card">

                            <div class="row mb-4">
                                <div class="w-100 px-3">
                                    <h4 class="form-inspection-title text-center ">Internal Visual Inspection Report</h4>
                                    <p id="error_message" class="center error"></p>
                                </div>
                               
                                <div class="col-lg-12"> 
                                <WorkOrderNumberDiv/>
                                </div>
                                <div class="w-100">
                                    <div class="inspection-wrap">
                                  
                                        <div class="table-2 p-3">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col" style={{ "width": "50%", lineClamp:"40px" }}>Compartment Tested/Inspected </th>
                                                    <th scope="col" style={{ "width": "40%", lineClamp:"40px" }}>Safe fill amount(GALS)</th>
                                                    <th scope="col" class="d-flex justify-content-center"> <input type="checkbox"    placeholder=""  onChange={NumericOnly} id="checkAll"   /><span class="mx-2 mt-2">ALL</span></th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    compartments ? compartments.map((data, index) => (
                                                        <tr>
                                                            <td>
                                                                <div class="row align-items-center">
                                                                    <div class="col-md-4">Compartment #{index + 1} </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="row align-items-center">
                                                                   
                                                                    <div class="col-md-4"><input type="text" class="form-control" id={"comp" + index + "gallons"}   placeholder="" defaultValue={data=="null"?0:data} onChange={NumericOnly} onChange={e => textChange(e,index)}  /></div>
                                                                  
                                                                </div>
                                                            </td>
                                                            <td class="text-center">  <input type="checkbox" aria-label="Radio button for following text input" name={"sec" + (index) + "Distortion"} value="1"    onChange={e => handleChange(e,index)} /></td>
                                                          
                                                        </tr>
                                                    )) : ""

                                                }
                                              
                                                
                                              <td>Total in Gallons</td> <td><input type="text" aria-label="Radio button for following text input" id="allValues"  onChange={NumericOnly} disabled style={{maxWidth:"129px"}} /></td>
                                            </tbody>
                                          
                                     
                                              </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                             
                                    <div class="inspection-wrap">

                                       <div class="table-2">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Section 1: Tank Shell/Heads are there any?</th>
                                                    <th scope="col" className="text-center">Yes</th>
                                                    <th scope="col"  className="text-center">No</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  
                                                    <tr>
                                                        <td>Corroded or abraded areas</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1corroded" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1corroded" value="0"/></td>
                                                       </tr>  

                                                       <tr>
                                                        <td>Dents</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1Dents" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1Dents" value="0"/></td>
                                                       </tr>  

                                                       <tr>
                                                        <td>Distortions</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="secinternalDistortion" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="secinternalDistortion" value="0"/></td>
                                                       </tr> 
                                                       
                                                       <tr>
                                                        <td>Defects in Welds</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1Welds" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1Welds" value="0"/></td>
                                                       </tr>

                                                       <tr>
                                                        <td>Leaks of any kind</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1Kind" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1Kind" value="0"/></td>
                                                       </tr>

                                                       <tr>
                                                        <td>Defects in baffles</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1baffles" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1baffles" value="0"/></td>
                                                       </tr>

                                                       <tr>
                                                        <td>Defects in interior emergency valves</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1emergency" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1emergency" value="0"/></td>
                                                       </tr>

                                                       <tr>
                                                        <td>Defects in lining</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1lining" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1lining" value="0"/></td>
                                                       </tr>

                                                       <tr>
                                                        <td>Defects in dip tube</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1tube" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1tube" value="0"/></td>
                                                       </tr>

                                                       <tr>
                                                        <td>Defects in cleanout ports/nozzles</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1nozzles" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1nozzles" value="0"/></td>
                                                       </tr>

                                                       <tr>
                                                        <td>Any condition that might render tank unsafe for transportation service</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1unsafe" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1unsafe" value="0"/></td>
                                                       </tr>

                                                       {/* <tr>
                                                        <td>transportation service</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1service" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec1service" value="0"/></td>
                                                       </tr> */}
                                                 </tbody>
                                              </table>
                                        </div>
                                
                                    </div>
                                </div>
                                        <br/>           
                                <div class="col-lg-6">
                                    <div class="inspection-wrap">

                                       <div class="table-2">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Section 2: Tank Shell/Head areas covered by the UC plate, are there any?</th>
                                                    <th scope="col"  className="text-center">Yes</th>
                                                    <th scope="col"  className="text-center">No</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  
                                                    <tr>
                                                        <td>Corroded or abraded areas</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec2corroded" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec2corroded" value="0"/></td>
                                                       </tr>  

                                                       <tr>
                                                        <td>Dents</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec2Dents" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec2Dents" value="0"/></td>
                                                       </tr>  

                                                       <tr>
                                                        <td>Distortions</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="secinternalDistortion2" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="secinternalDistortion2" value="0"/></td>
                                                       </tr> 
                                                       
                                                       <tr>
                                                        <td>Defects in Welds</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec2Welds" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec2Welds" value="0"/></td>
                                                       </tr>

                                                       <tr>
                                                        <td>Leaks of any kind</td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec2kind" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec2kind" value="0"/></td>
                                                       </tr>

                                                       <tr>
                                                        <td>Any condition that might render tank unsafe for transportation service                                                        </td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec2service" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec2service" value="0"/></td>
                                                       </tr>

                                                       <tr>
                                                        <td>"Tank marking: Date (month/Year) and service symbol after all defects are corrected. Did you update markings on tank                                                        </td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec2tank" value="1"/></td>
                                                         <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="sec2tank" value="0"/></td>
                                                       </tr>


                                                 </tbody>
                                              </table>
                                        </div>
                                       
                                    </div>
                                    
                                </div>
                                
                            </div>
                            
                            <div class="divider2"></div>
                            <div class="row">
                            <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName}/>
                            </div>

                            {/* <div class="divider"></div>
                            <div class="row p-3">
                                 <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Test Performed by <span class="star">*</span></label>
                                        <input type="text" class="form-control" id="inspectedBy" aria-describedby="emailHelp" placeholder="Enter Name"/>
                                      </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Date <span class="star">*</span></label>
                                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="inspectedDate" />
                                      </div>
                                </div>
                            </div> */}
                             <AddInspectionName setEndDate={setEndDate} endDate={endDate} />

                            <div class="divider2"></div>
                            <div class="row p-3 pr-4 d-flex justify-content-end" style={{marginBottom:"-20px"}}> 
                            <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
                                <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a>
                                <a href="#" class="btn btn-primary mr-1" onClick={() => save("APPROVED PENDING")}>Save</a>
                            </div>
                           </div>
                        </div>
                  </div>
                 
              
  </>
)};

InternalVisual.propTypes = {};

InternalVisual.defaultProps = {};

export default InternalVisual;
