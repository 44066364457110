import React,{history,useEffect, useState, useMemo } from "react";


import Keycloak from 'keycloak-js';
//import { keycloak } from './utility/keycloakConfig';
import { useNavigate,useParams} from "react-router-dom";

import $, { parseJSON } from 'jquery';
import services from "../services/services";
import { requestObject } from "./requestObject";
const Authentication=()=>
{
    const navigate = useNavigate();
    useEffect(() => 
    {
      
                 var customerListReq =
                  {
            
                  };
            
                  var requestOptions = requestObject.RequestHeader(customerListReq);
                  services.getAccountType(requestOptions).then((res) => 
                  {
                   
                     sessionStorage.setItem("accountType",res.rows.accountTypeName);
                     if(res.rows.accountTypeName=='garage')
                     {  
                      
                      window.location.href='/garage/dashboard';
                       // navigate('/garage/dashboard');

                     }if(res.rows.accountTypeName=='fleet')
                     {
                        window.location.href="/fleetowner/dashboard";
                        //navigate("/fleetowner/dashboard")
                     }

                  });

    }, []);
    return(<></>)
}

export default Authentication;