import React,{history,useEffect, useState, useMemo } from "react";
import Swal from "sweetalert2";
import { useNavigate ,useParams} from "react-router-dom";
import { requestObject } from "../../../../utility/requestObject";
import  $ from "jquery";
import services from "../../../../services/services";
import {useLoading} from 'react-hook-loading';
import DatePicker from "react-datepicker";
import Adddocs from "../../../../utility/Adddocs";
import CheckAuthentication from "../../../../utility/checkAuthentication";
import AddInspectionName from "../../../../utility/addInspectionName";
import WorkOrderNumberDiv from "../../../../utility/WorkorderNumberDiv";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
import { scrollTop } from "../../../../utility/ScrollTop";
const ExternalVisualSix = () => 
{
    const { taskOrderId,cid,id } = useParams();
    const [ fileName, setfileName ] = useState("")
    const [endDate, setEndDate] = useState(new Date());
    var customerid=localStorage.getItem("customerId");
    const [noOfRows, setNoOfRows] = useState(1);
    const [loading, setLoading] = useLoading("Please wait...");
    const [inputList, setInputList] = useState([{ }]);
    const navigate = useNavigate();
    const [base64file, setBase64file] = useState('');
    useEffect(() => 
    {
      scrollTop();
      getSection6();
      sectionSubmit();
    },[]);  
    const redirect=(page)=>
    {
    
       navigate(page);
    
    }
    function getSection6() {

      JSON.parse(sessionStorage.getItem("section6"));
  
      console.log("ddd" + JSON.parse(sessionStorage.getItem("section6")));
  
      var obj = JSON.parse(sessionStorage.getItem("section6"))
      console.log(obj == null ? '' : obj.lights);
  
      // setSection1(obj);
      // console.log("section Data" + JSON.stringify(section1));
  
      var lights = obj == null ? '' : obj.lights;
      var bolts = obj == null ? '' : obj.bolts;
      var ladder = obj == null ? '' : obj.ladder;
      var frame = obj == null ? '' : obj.frame;
      var plateAndPin = obj == null ? '' : obj.plateAndPin;
      var landingGear = obj == null ? '' : obj.landingGear;
      var airBags = obj == null ? '' : obj.airBags;
      var brakes = obj == null ? '' : obj.brakes;
      var airlines = obj == null ? '' : obj.airlines;
      var tires = obj == null ? '' : obj.tires;
      var tankIllegible = obj == null ? '' : obj.tankIllegible;
      var specsPlate = obj == null ? '' : obj.specsPlate;
      var Does5th = obj == null ? '' : obj.Does5th;
      var updatedonTank = obj == null ? '' : obj.updatedonTank;
      var inspected = obj == null ? '' : obj.inspected;
  
 

         $('input[name="step6Lights"][value="'+lights+'"]').trigger("click");
         $('input[name="step6bolts"][value="'+bolts+'"]').trigger("click");
        $('input[name="step6Ladder"][value="'+ladder+'"]').trigger("click");
         $('input[name="step6Frame"][value="'+frame+'"]').trigger("click");
         $('input[name="step6plateandpin"][value="'+plateAndPin+'"]').trigger("click");
         $('input[name="step6landingGear"][value="'+landingGear+'"]').trigger("click");
         $('input[name="step6bags"][value="'+airBags+'"]').trigger("click");
         $('input[name="step6Brakes"][value="'+brakes+'"]').trigger("click");
         $('input[name="step6AirLines"][value="'+airlines+'"]').trigger("click");
         $('input[name="step6tires"][value="'+tires+'"]').trigger("click");
         $('input[name="step6illegible"][value="'+tankIllegible+'"]').trigger("click");
         $('input[name="step6specsillegible"][value="'+specsPlate+'"]').trigger("click");
         $('input[name="step6Does5th"][value="'+Does5th+'"]').trigger("click");
         $('input[name="step6updated"][value="'+updatedonTank+'"]').trigger("click");
         $('input[name="step6inspected"][value="'+inspected+'"]').trigger("click");
  
    }

    const sectionSubmit = () => {
      var section6= {
        "lights": $("input[name='step6Lights']:checked").val()==="1"?"1":"0",
        "bolts":  $("input[name='step6bolts']:checked").val()==="1"?"1":"0",
        "ladder": $("input[name='step6Ladder']:checked").val()==="1"?"1":"0",
        "frame": $("input[name='step6Frame']:checked").val()==="1"?"1":"0",
        "plateAndPin": $("input[name='step6plateandpin']:checked").val()==="1"?"1":"0",
        "landingGear": $("input[name='step6landingGear']:checked").val()==="1"?"1":"0",
        "airBags": $("input[name='step6bags']:checked").val()==="1"?"1":"0",
        "brakes": $("input[name='step6Brakes']:checked").val()==="1"?"1":"0",
        "airlines": $("input[name='step6AirLines']:checked").val()==="1"?"1":"0",
        "tires": $("input[name='step6tires']:checked").val()==="1"?"1":"0",
        "tankIllegible": $("input[name='step6illegible']:checked").val()==="1"?"1":"0",
        "specsPlate": $("input[name='step6specsillegible']:checked").val()==="1"?"1":"0",
        "Does5th": $("input[name='step6Does5th']:checked").val()==="1"?"1":"0",
        "updatedonTank": $("input[name='step6updated']:checked").val()==="1"?"1":"0",
        "inspected": $("input[name='step6inspected']:checked").val()==="1"?"1":"0",
        "other": $("#notes").val()!=""?$("#notes").val():"",
      };
  
      console.log(JSON.stringify(section6));
  
      sessionStorage.setItem("section6",JSON.stringify(section6));
  
    }

    const handleSubmit=()=>
  {

let form_is_valid = true;

var step6Lights=$("input[name='step6Lights']:checked").val();
var step6bolts=$("input[name='step6bolts']:checked").val();
var step6Ladder=$("input[name='step6Ladder']:checked").val();
var step6Frame=$("input[name='step6Frame']:checked").val();
var step6plateandpin=$("input[name='step6plateandpin']:checked").val();
var step6landingGear=$("input[name='step6landingGear']:checked").val();
var step6bags=$("input[name='step6bags']:checked").val();
var step6Brakes=$("input[name='step6Brakes']:checked").val();
var step6AirLines=$("input[name='step6AirLines']:checked").val();
var step6tires=$("input[name='step6tires']:checked").val();
var step6illegible=$("input[name='step6illegible']:checked").val();
var step6specsillegible=$("input[name='step6specsillegible']:checked").val();
var step6Does5th=$("input[name='step6Does5th']:checked").val();
var step6updated=$("input[name='step6updated']:checked").val();
var step6inspected=$("input[name='step6inspected']:checked").val();

// var workOrderNumber=$('#workOrderNumber').val();

// if (!workOrderNumber) {
//   form_is_valid = false;
//   $("#workOrderNumber-message").html('Work order number  is required');
// }else{
//         $("#workOrderNumber-message").html('');
//         form_is_valid=true;
//   }

if(step6Lights==undefined||step6bolts==undefined||step6Ladder==undefined||step6Frame==undefined||step6plateandpin==undefined||step6landingGear==undefined||step6bags==undefined||step6Brakes==undefined||step6AirLines==undefined||step6tires==undefined||step6illegible==undefined||step6specsillegible==undefined||step6Does5th==undefined||step6updated==undefined||step6inspected==undefined){
  // $('html, body').animate({
  //   scrollTop: $("#inspectionType").offset().top
  // }, 500);
  $('html,body').animate({ scrollTop: "500px" }, 1000);
  $('#error_message').css("color", "#d33232").html("*Please choose one option for each question");
  form_is_valid= false;

}else{
  $('#error_message').html(''); 
}
// alert(form_is_valid);
return form_is_valid;

}
    
    const save=(status)=>
    {

      // var section5= {
      //   "linksMissing": $("input[name='step5missing']:checked").val()==="1"?"1":"0",
      //   "linksloose": $("input[name='step5loose']:checked").val()==="1"?"1":"0"
      // };

      // console.log(JSON.stringify(section5));

      // sessionStorage.setItem("section5",JSON.stringify(section5));

      if(status=='DRAFT'){

        var documentList=[];
  
        try
        {
          for(var i=0;i<inputList.length;i++)
          {
              // console.log("filebase64=="+inputList[i].filetobase64);
              var extension="";
              var byteString="";
              if(inputList[i].filetobase64.match("data:application/pdf;base64,"))
              {
                  extension="pdf";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/pdf;base64,", ""):"";
              }else if(inputList[i].filetobase64.match("data:image/jpeg;base64,"))
              {
                  extension="jpg";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/jpeg;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:image/png;base64,"))
              {
                  extension="png";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/png;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/msword;base64,"))
              {
                  extension="word";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/msword;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:image/bmp;base64,"))
              {
                  extension="bmp";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/bmp;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
              {
                  extension="docx";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
              {
                  extension="docx";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
              {
                  extension="docx";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
              {
                  extension="docx";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
              {
                  extension="docx";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
              {
                  extension="docm";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,"))
              {
                  extension="docx";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
              {
                  extension="dotx";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
              {
                  extension="dotx";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
              }
              else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,"))
              {
                  extension="dotm";
                  byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", ""):"";
              }
              else{
                 
              }
   
   
                documentList.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
          }
        }catch(exception)
        {
          // Swal.fire({  
          //     position: 'center',  
          //     icon: 'error',  
          //     title: "",  
          //     text:"Please attach document",
          //     showConfirmButton: true 
              
          //   }).then((result) => 
          //   {
                  
          //         if(result.isConfirmed) 
          //         {
                    
                  
          //         }
          //   });
          //   return false;
          documentList.push();
        }
        if($("#inspectedBy").val()==="")
        {
          Swal.fire({  
              position: 'center',  
              icon: 'error',  
              title: "",  
              text:"Please enter inspectedBy",
              showConfirmButton: true 
              
            }).then((result) => 
            {
                  
                  if(result.isConfirmed) 
                  {
                    
                  
                  }
            });
            return false;
        }
        
        var requestObjectForInsp=
        {
          
          "type":"External Visual Inspection",
          "inspectedBy":  $("#inspectedBy").val(),
          "workOrderNumber":$("#workOrderNumber").val(),
          "notes":$("#notes").val(),
          "inspectedDate":$("#inspectedDate").val(),
          "verifiedBy": $("#verifiedBy").val(),
          "verifiedDate": $("#verifiedBy").val(),
          "status": status,
          "taskOrderId":taskOrderId,
          "inspectionType":
          {
            "id": sessionStorage.getItem("selectedinspectionId"),
            "name":sessionStorage.getItem("selectedinspectionType")
          },
          "result":
          {
              "approvedCertify": "0",
              "inspectionFailed": "0"
          },
          "dispositionOfVehicle":
          {
              "vehicleReturnedServices": $("#vehicleReturnedServices").val(),
              "vehicleWithdrawnFromService":$("#vehicleWithdrawnFromService").val(),
              "vehicleSentToAnotherRepairFacility":$("#vehicleSentToAnotherRepairFacility").val()
          },
          "vehicleDetails":
          {
              "vinNumber": $("#vin").html(),
              "lisencePlateNumber": $("#lisencePlateNumber").html(),
              "id":localStorage.getItem("vehicleId"),
              "unitNumber": $("#lisencePlateNumber").html()
          },
          "externalVisualInspection":
           {
              "section1":JSON.parse(sessionStorage.getItem("section1")),
                "section2":JSON.parse(sessionStorage.getItem("section2")),
                "section3":JSON.parse(sessionStorage.getItem("section3")),
                "section4":JSON.parse(sessionStorage.getItem("section4")),
                "section5":JSON.parse(sessionStorage.getItem("section5")),  
                "section6":
                {
                  "lights": $("input[name='step6Lights']:checked").val()==="1"?"1":"0",
                  "bolts":  $("input[name='step6bolts']:checked").val()==="1"?"1":"0",
                  "ladder": $("input[name='step6Ladder']:checked").val()==="1"?"1":"0",
                  "frame": $("input[name='step6Frame']:checked").val()==="1"?"1":"0",
                  "plateAndPin": $("input[name='step6plateandpin']:checked").val()==="1"?"1":"0",
                  "landingGear": $("input[name='step6landingGear']:checked").val()==="1"?"1":"0",
                  "airBags": $("input[name='step6bags']:checked").val()==="1"?"1":"0",
                  "brakes": $("input[name='step6Brakes']:checked").val()==="1"?"1":"0",
                  "airlines": $("input[name='step6AirLines']:checked").val()==="1"?"1":"0",
                  "tires": $("input[name='step6tires']:checked").val()==="1"?"1":"0",
                  "tankIllegible": $("input[name='step6illegible']:checked").val()==="1"?"1":"0",
                  "specsPlate": $("input[name='step6specsillegible']:checked").val()==="1"?"1":"0",
                  "Does5th": $("input[name='step6Does5th']:checked").val()==="1"?"1":"0",
                  "updatedonTank": $("input[name='step6updated']:checked").val()==="1"?"1":"0",
                  "inspected": $("input[name='step6inspected']:checked").val()==="1"?"1":"0",
                  // "other": $("#notes").val()!=""?$("#notes").val():"",
                }
          },
          "documentList": documentList
         
      }; 
    
    
      $("#loadingforsubmit").show();
    //   const requestOptions = 
    //   {
    //      method: 'POST',
    //      headers: { 'Content-Type': 'application/json'
    //               },
                  
    //         body: JSON.stringify(requestObject)
    //   };
    var requestOptions=requestObject.RequestHeader(requestObjectForInsp);
      services.addInspection(requestOptions).then((res) => 
      {
        $("#loadingforsubmit").hide();
        Swal.fire({  
           position: 'center',  
           icon: 'success',  
           title: "",  
           text:res.message,
           showConfirmButton: true 
           
         }).then((result) => 
         {
              
               if(result.isConfirmed) 
               {
  
                sessionStorage.removeItem("section1");
                sessionStorage.removeItem("section2");
                sessionStorage.removeItem("section3");
                sessionStorage.removeItem("section4");
                sessionStorage.removeItem("section5");
  
                navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));
               
               }
         });
      });

      }else{

      if(handleSubmit()==true){
  
      var documentList=[];
  
      try
      {
        for(var i=0;i<inputList.length;i++)
        {
            // console.log("filebase64=="+inputList[i].filetobase64);
            var extension="";
            var byteString="";
            if(inputList[i].filetobase64.match("data:application/pdf;base64,"))
            {
                extension="pdf";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/pdf;base64,", ""):"";
            }else if(inputList[i].filetobase64.match("data:image/jpeg;base64,"))
            {
                extension="jpg";
                byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/jpeg;base64,", ""):"";
            }
            else if(inputList[i].filetobase64.match("data:image/png;base64,"))
           {
               extension="png";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/png;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/msword;base64,"))
           {
               extension="word";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/msword;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:image/bmp;base64,"))
           {
               extension="bmp";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:image/bmp;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/docx;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/docx;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/doc;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/doc;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.document.macroEnabled.12;base64,"))
           {
               extension="docm";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.document.macroEnabled.12;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,"))
           {
               extension="docx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
           {
               extension="dotx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,"))
           {
               extension="dotx";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,", ""):"";
           }
           else if(inputList[i].filetobase64.match("data:application/vnd.ms-word.template.macroEnabled.12;base64,"))
           {
               extension="dotm";
               byteString=inputList[i].filetobase64?inputList[i].filetobase64.replace("data:application/vnd.ms-word.template.macroEnabled.12;base64,", ""):"";
           }
           else{
              
           }


             documentList.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
        }
      }catch(exception)
      {
        documentList.push();
        // Swal.fire({  
        //     position: 'center',  
        //     icon: 'error',  
        //     title: "",  
        //     text:"Please attach document",
        //     showConfirmButton: true 
            
        //   }).then((result) => 
        //   {
                
        //         if(result.isConfirmed) 
        //         {
                  
                
        //         }
        //   });
        //   return false;
      }
      if($("#inspectedBy").val()==="")
      {
        Swal.fire({  
            position: 'center',  
            icon: 'error',  
            title: "",  
            text:"Please enter inspectedBy",
            showConfirmButton: true 
            
          }).then((result) => 
          {
                
                if(result.isConfirmed) 
                {
                  
                
                }
          });
          return false;
      }
      
      var requestObjectForInsp=
      {
        
        "type":"External Visual Inspection",
        "inspectedBy":  $("#inspectedBy").val(),
        "workOrderNumber":$("#workOrderNumber").val(),
        "notes":$("#notes").val(),
        "inspectedDate":$("#inspectedDate").val(),
        "verifiedBy": $("#verifiedBy").val(),
        "verifiedDate": $("#verifiedBy").val(),
        "status": status,
        "taskOrderId":taskOrderId,
        "inspectionType":
        {
          "id": sessionStorage.getItem("selectedinspectionId"),
          "name":sessionStorage.getItem("selectedinspectionType")
        },
        "result":
        {
            "approvedCertify": "0",
            "inspectionFailed": "0"
        },
        "dispositionOfVehicle":
        {
            "vehicleReturnedServices": $("#vehicleReturnedServices").val(),
            "vehicleWithdrawnFromService":$("#vehicleWithdrawnFromService").val(),
            "vehicleSentToAnotherRepairFacility":$("#vehicleSentToAnotherRepairFacility").val()
        },
        "vehicleDetails":
        {
            "vinNumber": $("#vin").html(),
            "lisencePlateNumber": $("#lisencePlateNumber").html(),
            "id":localStorage.getItem("vehicleId"),
            "unitNumber": $("#lisencePlateNumber").html()
        },
        "externalVisualInspection":
         {
            "section1":JSON.parse(sessionStorage.getItem("section1")),
              "section2":JSON.parse(sessionStorage.getItem("section2")),
              "section3":JSON.parse(sessionStorage.getItem("section3")),
              "section4":JSON.parse(sessionStorage.getItem("section4")),
              "section5":JSON.parse(sessionStorage.getItem("section5")),  
              "section6":
              {
                "lights": $("input[name='step6Lights']:checked").val()==="1"?"1":"0",
                "bolts":  $("input[name='step6bolts']:checked").val()==="1"?"1":"0",
                "ladder": $("input[name='step6Ladder']:checked").val()==="1"?"1":"0",
                "frame": $("input[name='step6Frame']:checked").val()==="1"?"1":"0",
                "plateAndPin": $("input[name='step6plateandpin']:checked").val()==="1"?"1":"0",
                "landingGear": $("input[name='step6landingGear']:checked").val()==="1"?"1":"0",
                "airBags": $("input[name='step6bags']:checked").val()==="1"?"1":"0",
                "brakes": $("input[name='step6Brakes']:checked").val()==="1"?"1":"0",
                "airlines": $("input[name='step6AirLines']:checked").val()==="1"?"1":"0",
                "tires": $("input[name='step6tires']:checked").val()==="1"?"1":"0",
                "tankIllegible": $("input[name='step6illegible']:checked").val()==="1"?"1":"0",
                "specsPlate": $("input[name='step6specsillegible']:checked").val()==="1"?"1":"0",
                "Does5th": $("input[name='step6Does5th']:checked").val()==="1"?"1":"0",
                "updatedonTank": $("input[name='step6updated']:checked").val()==="1"?"1":"0",
                "inspected": $("input[name='step6inspected']:checked").val()==="1"?"1":"0",
                // "other": $("#notes").val()!=""?$("#notes").val():"",
              }
        },
        "documentList": documentList
       
    }; 
  
  
    $("#loadingforsubmit").show();
  //   const requestOptions = 
  //   {
  //      method: 'POST',
  //      headers: { 'Content-Type': 'application/json'
  //               },
                
  //         body: JSON.stringify(requestObject)
  //   };
  var requestOptions=requestObject.RequestHeader(requestObjectForInsp);
    services.addInspection(requestOptions).then((res) => 
    {
      $("#loadingforsubmit").hide();
      Swal.fire({  
         position: 'center',  
         icon: 'success',  
         title: "",  
         text:res.message,
         showConfirmButton: true 
         
       }).then((result) => 
       {
            
             if(result.isConfirmed) 
             {

              sessionStorage.removeItem("section1");
              sessionStorage.removeItem("section2");
              sessionStorage.removeItem("section3");
              sessionStorage.removeItem("section4");
              sessionStorage.removeItem("section5");

              navigate("/vehicleDetails/"+localStorage.getItem("vehicleId"));
             
             }
       });
    });
  }
}
  
    }
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
   
  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
   
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, {}]);
  };
  const fileToBase64 = (file, cb) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        cb(null, reader.result)
      }
      reader.onerror = function (error) {
        cb(error, null)
      }
    }
  const onUploadFileChange = ( event,index) => 
  {
      
  
      var target=event.target;
      if (target.files < 1 || !target.validity.valid) {
        return
      }
      fileToBase64(target.files[0], (err, result) => {
        if (result) {
          const { name, value } = event.target;
          const list = [...inputList];
          list[index][name] = result;
          setBase64file(result);
         // setFile(result)
         setfileName(target.files[0]);
         // setFileName(target.files[0])
        }
      })
    }
  return(
  <>
 {LoadingComponentForSubmit}

                        <div class="card">

                          {/* <div class="divider"></div> */}
                            <div class="row px-3">
                                <div class="w-100">
                                  <h4 class="form-inspection-title text-center">External / Visual Inspection</h4>
                                  <p id="error_message"></p>
                                </div>
                                <div class="col-lg-12 px-0">
                                <WorkOrderNumberDiv/>
                                </div>
                                <div class="col-lg-12 px-0 mb-3">
                                  
                                    <div class="inspection-wrap">

                                        <div class="step-wrap p-3 mb-3">
                                             <div class="step-main d-flex">
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt=""/></div>
                                                     <div class="labl-section">Tank Shell</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 chk-tick "><img src="/images/icons/tick.svg" alt=""/></div>
                                                     <div class="labl-section">External Piping</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 chk-tick "><img src="/images/icons/tick.svg" alt=""/></div>
                                                     <div class="labl-section">Spill Collar</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt=""/></div>
                                                     <div class="labl-section">Emergency Devices</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt=""/></div>
                                                     <div class="labl-section">Bolts</div>
                                                 </div>
                                                 <div class="step-itm mr-3">
                                                     <div class="no-cl mr-2 current-tick">6</div>
                                                     <div class="labl-section">Vehicle Condition</div>
                                                 </div>
                                             </div>
                                        </div>

                                        <div class="table-2">
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">Section 6: General vehicle condition/Are any items not in good operating condition? :</th>
                                                    <th scope="col"  className="text-center">Yes</th>
                                                    <th scope="col"  className="text-center">No</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                   <td>Lights</td>
                                                    <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6Lights" value="1" onChange={() => sectionSubmit()}/></td>
                                                    <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6Lights" value="0" onChange={() => sectionSubmit()}/></td>
                                                  </tr>

                                                   <tr>
                                                    <td>"U" bolts</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6bolts" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6bolts" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>Ladder</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6Ladder" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6Ladder" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>Frame</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6Frame" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6Frame" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>5th wheel plate and pin</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6plateandpin" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6plateandpin" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>Landing gear and legs</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6landingGear" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6landingGear" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                   
                                                   <tr>
                                                    <td>Spring and air bags</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6bags" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6bags" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>    

                                                   <tr>
                                                    <td>Brakes</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6Brakes" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6Brakes" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>  

                                                   <tr>
                                                    <td>Air lines</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6AirLines" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6AirLines" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>  

                                                   <tr>
                                                    <td>Tires</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6tires" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6tires" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>  

                                                   <tr>
                                                    <td>Are any required markings on cargo tank illegible</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6illegible" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6illegible" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr> 

                                                   <tr>
                                                    <td>Spec plate illegible</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6specsillegible" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6specsillegible" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                   <tr>
                                                    <td>Does the 5th wheel assembly ,suspension system attachments and connecting structures show signs of corrosion or damage which might prevent safe operation</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6Does5th" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6Does5th" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr>

                                                   

                                                   <tr>
                                                    <td>Tank marking: date (month/year) and service symbol after all defects are corrected. Did markings get updated on tank
                                                    </td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6updated" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6updated" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr> 

                                                   <tr>
                                                    <td>5th wheel plate removed and inspected</td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6inspected" value="1" onChange={() => sectionSubmit()}/></td>
                                                     <td class="text-center">  <input type="radio" aria-label="Radio button for following text input" name="step6inspected" value="0" onChange={() => sectionSubmit()}/></td>
                                                   </tr> 
                                                 

                                                </tbody>
                                              </table>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div class="divider2"></div>
                            <div class="row p-0">
                              <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName}/>
                            </div>

                            {/* <div class="row p-3">
                                <div class="col-lg-12">
                                  
                                   <div class="form-group">
                                    <label for="exampleInputPassword1"><b>Additional Notes </b></label>
                                    <textarea class="form-control mb-2" name="notes" id="notes" id="exampleFormControlTextarea1" rows="3"></textarea>
                                  </div>
                                </div>
                            </div> */}

                            {/* <div class="divider"></div>
                            <div class="row p-3">
                                 <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Test Performed by<span class="star">*</span></label>
                                        <input type="text" class="form-control" id="inspectedBy" aria-describedby="emailHelp" placeholder="Enter Name"/>
                                      </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Date<span class="star">*</span></label>
                                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} class="form-control" id="inspectedDate" />
                                      </div>
                                </div>
                            </div> */}

                              <AddInspectionName setEndDate={setEndDate} endDate={endDate} />
                              


                            <div class="divider2"></div>
                            <div class="row p-3 pr-4 d-flex justify-content-end" style={{marginBottom:"-20px"}}>
                            <a id="section5" class="btn btn-secondary mr-1 ">Back</a>
                            <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a>
                                <button type="button" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </button>
                                <button type="button" class="btn btn-primary mr-1" onClick={() => save("APPROVED PENDING")}>Save</button>
                            </div>
                           </div>
           
                 
             
  </>
)};

ExternalVisualSix.propTypes = {};

ExternalVisualSix.defaultProps = {};

export default ExternalVisualSix;