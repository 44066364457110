import React, { history, useEffect, useState, useMemo } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { requestObject } from "../../../utility/requestObject";
import $ from "jquery";
import services from "../../../services/services";
import { useLoading } from 'react-hook-loading';
import DatePicker from "react-datepicker";
import Adddocs from "../../../utility/Adddocs";
import CheckAuthentication from "../../../utility/checkAuthentication";
import AddInspectionName from "../../../utility/addInspectionName";
import WorkOrderNumberDiv from "../../../utility/WorkorderNumberDiv";
const InspectionVaporTightnessCertificate2 = () => {

   const { cid, id } = useParams();
   const [fileName, setfileName] = useState("")
   const [endDate, setEndDate] = useState(new Date());
   var customerid = localStorage.getItem("customerId");
   const [noOfRows, setNoOfRows] = useState(1);
   const [loading, setLoading] = useLoading("Please wait...");
   const [inputList, setInputList] = useState([{}]);
   const navigate = useNavigate();
   const [base64file, setBase64file] = useState('');

   useEffect(() => {

      getSection2();
  
    }, []);
  
    function getSection2() {
 
      var run1 = JSON.parse(sessionStorage.getItem("section2VaporRun1"));

      var run1_pi = run1 == null ? '' : run1.pi;
      var run1_pf = run1 == null ? '' : run1.pf;
      var run1_a = run1 == null ? '' : run1.a;

      var run1_ti = run1 == null ? '' : run1.ti;
      var run1_tf = run1 == null ? '' : run1.tf;
      var run1_titf = run1 == null ? '' : run1.titf;
  
      $("input[name='run1_pi']").val(run1_pi);
      $("input[name='run1_pf']").val(run1_pf);
      $("input[name='run1_a']").val(run1_a);
      $("input[name='run1_ti']").val(run1_ti);
      $("input[name='run1_tf']").val(run1_tf);
      $("input[name='run1_titf']").val(run1_titf);

      var run2 = JSON.parse(sessionStorage.getItem("section2VaporRun2"));

      var run2_pi = run2 == null ? '' : run2.pi;
      var run2_pf = run2 == null ? '' : run2.pf;
      var run2_a = run2 == null ? '' : run2.a;
      var run2_ti = run2 == null ? '' : run2.ti;
      var run2_tf = run2 == null ? '' : run2.tf;
      var run2_titf = run2 == null ? '' : run2.titf;
  
      $("input[name='run2_pi']").val(run2_pi);
      $("input[name='run2_pf']").val(run2_pf);
      $("input[name='run2_a']").val(run2_a);
      $("input[name='run2_ti']").val(run2_ti);
      $("input[name='run2_tf']").val(run2_tf);
      $("input[name='run2_titf']").val(run2_titf);

      var run3 = JSON.parse(sessionStorage.getItem("section2VaporRun3"));

      var run3_pi = run3 == null ? '' : run3.pi;
      var run3_pf = run3 == null ? '' : run3.pf;
      var run3_a = run3 == null ? '' : run3.a;
      var run3_ti = run3 == null ? '' : run3.ti;
      var run3_tf = run3 == null ? '' : run3.tf;
      var run3_titf = run3 == null ? '' : run3.titf;
  
      $("input[name='run3_pi']").val(run3_pi);
      $("input[name='run3_pf']").val(run3_pf);
      $("input[name='run3_a']").val(run3_a);
      $("input[name='run3_ti']").val(run3_ti);
      $("input[name='run3_tf']").val(run3_tf);
      $("input[name='run3_titf']").val(run3_titf);
    
  
    }
    const sectionSubmit = () => {
      var section2VaporRun1 = {
        "pi": $("input[name='run1_pi']").val() != "" ? $("input[name='run1_pi']").val() : "",
        "pf": $("input[name='run1_pf']").val() != "" ? $("input[name='run1_pf']").val() : "",
        "a": $("input[name='run1_a']").val() != "" ? $("input[name='run1_a']").val() : "",

        "ti": $("input[name='run1_ti']").val() != "" ? $("input[name='run1_ti']").val() : "",
        "tf": $("input[name='run1_tf']").val() != "" ? $("input[name='run1_tf']").val() : "",
        "titf": $("input[name='run1_titf']").val() != "" ? $("input[name='run1_titf']").val() : "",
      };
      console.log(JSON.stringify(section2VaporRun1));
      sessionStorage.setItem("section2VaporRun1", JSON.stringify(section2VaporRun1));

      var section2VaporRun2 = {
         "pi": $("input[name='run2_pi']").val() != "" ? $("input[name='run2_pi']").val() : "",
         "pf": $("input[name='run2_pf']").val() != "" ? $("input[name='run2_pf']").val() : "",
         "a": $("input[name='run2_a']").val() != "" ? $("input[name='run2_a']").val() : "",
 
         "ti": $("input[name='run2_ti']").val() != "" ? $("input[name='run2_ti']").val() : "",
         "tf": $("input[name='run2_tf']").val() != "" ? $("input[name='run2_tf']").val() : "",
         "titf": $("input[name='run2_titf']").val() != "" ? $("input[name='run2_titf']").val() : "",
       };
       console.log(JSON.stringify(section2VaporRun2));
       sessionStorage.setItem("section2VaporRun2", JSON.stringify(section2VaporRun2));

       var section2VaporRun3 = {
         "pi": $("input[name='run3_pi']").val() != "" ? $("input[name='run3_pi']").val() : "",
         "pf": $("input[name='run3_pf']").val() != "" ? $("input[name='run3_pf']").val() : "",
         "a": $("input[name='run3_a']").val() != "" ? $("input[name='run3_a']").val() : "",
 
         "ti": $("input[name='run3_ti']").val() != "" ? $("input[name='run3_ti']").val() : "",
         "tf": $("input[name='run3_tf']").val() != "" ? $("input[name='run3_tf']").val() : "",
         "titf": $("input[name='run3_titf']").val() != "" ? $("input[name='run3_titf']").val() : "",
       };
       console.log(JSON.stringify(section2VaporRun3));
       sessionStorage.setItem("section2VaporRun3", JSON.stringify(section2VaporRun3));


    }


   $(document).ready(function () {
      $("input[name='run1_pi'],input[name='run1_pf'],input[name='run1_a'],input[name='run1_ti'],input[name='run1_tf'],input[name='run1_titf'],input[name='run2_pi'],input[name='run2_pf'],input[name='run2_a'],input[name='run2_ti'],input[name='run2_tf'],input[name='run2_titf'],input[name='run3_pi'],input[name='run3_pf'],input[name='run3_a'],input[name='run3_ti'],input[name='run3_tf'],input[name='run3_titf']").keypress(function (e) {
         if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
            return false;
         }
      });
   });

  
   return (
      <>

         <div class="row mb-2">
            <div class="col-lg-12 col-md-12 m-auto ">
               <div class="card">

                  {/* <div class="divider"></div>
                          
                           <div class="divider"></div> */}
                  <div class="row px-3 mb-3">
                     <div class="w-100">
                        <h4 class="form-inspection-title text-center">Vapor Tightness Certification Form</h4>
                     </div>
                    
                     <div class="w-100">
                        <div class="inspection-wrap mb-4">
                           <div class="step-wrap p-3 mb-3">
                              <div class="step-main d-flex">
                                 <div class="step-itm mr-3">
                                    {/* <!-- <div class="no-cl mr-2 current-tick">1</div> --> */}
                                    <div class="no-cl mr-2 chk-tick"><img src="/images/icons/tick.svg" alt="" /></div>
                                    <div class="labl-section">Annual Certification</div>
                                 </div>
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2 current-tick">2</div>
                                    <div class="labl-section">Pressure Test</div>
                                 </div>
                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2">3</div>
                                    <div class="labl-section">Internal Vapor Valve Test</div>
                                 </div>

                                 <div class="step-itm mr-3">
                                    <div class="no-cl mr-2">4</div>
                                    <div class="labl-section">Vaccum Test</div>
                                 </div>

                              </div>
                           </div>
                        </div>
                        <div class="text-center mb-5 mt-2">
                           <b>EPA METHOD 27 Pressure Test</b>
                           <div class="p-3 mb-4 mt-3 text-left" style={{ backgroundColor: "#ccc" }}>
                              <p>Increase pressure to a minimum of 18 inches (maximum of 26.6) Water Gauge (Manometer); Indicate starting pressure (Pi) and pressure (Pf) at the end of 5 minutes. Record initial (Ti) and final time (Tf) of test or duration if stop watch is used
                              </p>
                           </div>
                        </div>
                        <div class="row mt-3">
                           <div class="col-lg-12">
                              <div class="table-5">
                                 <table>
                                    <tbody>
                                       <tr>
                                          <td class="text-center">Run 1</td>
                                          <td style={{ verticalAlign:"baseline"}}>
                                             <div>
                                                <div class="border-bottom p-2" >Water Gauge
                                                   Readings (Inches)
                                                </div>
                                                <div class="d-flex justify-content-center border-bottom">
                                                   <div class="d-flex justify-content-center w-half m-auto p-2 border-right">9.00</div>
                                                   <div class="d-flex justify-content-center w-half m-auto p-2">9.00</div>
                                                </div>
                                             </div>
                                             <div class="p-2">
                                             </div>
                                          </td>
                                          <td>
                                             <div class="border-bottom">
                                                <div class="border-bottom p-2" >Total Inches Water</div>
                                                <div class="d-flex justify-content-center">
                                                   <div class="d-flex justify-content-center m-auto p-2 ">18.00</div>
                                                </div>
                                             </div>
                                             <div class="p-2 border-left">
                                                <div class="d-flex align-items-center mb-2">
                                                   <label for="" style={{ width: "40px" }}>Pi =</label>
                                                   <input type="input" name="run1_pi" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                   <label for="" style={{ width: "40px" }}>Pf =</label>
                                                   <input type="input" name="run1_pf" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                   <label for="" style={{ width: "40px" }}>a =</label>
                                                   <input type="input" name="run1_a" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                </div>
                                             </div>
                                          </td>
                                          <td>
                                             <div class="border-bottom">
                                                <div class="border-bottom p-2" >Start Time (Ti) Finishing Time(Tf)</div>
                                                <div class="d-flex justify-content-center">
                                                   <div class="d-flex justify-content-center m-auto p-2 ">0:00</div>
                                                </div>
                                             </div>
                                             <div class="p-2 border-left">
                                                <div class="d-flex align-items-center mb-2">
                                                   <label for="" style={{ width: "40px" }}>Ti =</label>
                                                   <input type="input" name="run1_ti" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                   <label for="" style={{ width: "40px" }}>Tf =</label>
                                                   <input type="input" name="run1_tf" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                   <label for="" style={{ width: "40px" }}>Ti-Tf =</label>
                                                   <input type="input" name="run1_titf" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                </div>
                                             </div>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                        <div class="row mt-3">
                           <div class="col-lg-12">
                              <div class="table-5">
                                 <table>
                                    <tbody>
                                       <tr>
                                          <td class="text-center">Run 2</td>
                                          <td style={{ verticalAlign:"baseline"}} >
                                             <div>
                                                <div class="border-bottom p-2"  >Water Gauge
                                                   Readings (Inches)
                                                </div>
                                                <div class="d-flex justify-content-center border-bottom">
                                                   <div class="d-flex justify-content-center w-half m-auto p-2 border-right">9.00</div>
                                                   <div class="d-flex justify-content-center w-half m-auto p-2">9.00</div>
                                                </div>
                                             </div>
                                             <div class="p-2">
                                             </div>
                                          </td>
                                          <td>
                                             <div class="border-bottom">
                                                <div class="border-bottom p-2" >Total Inches Water</div>
                                                <div class="d-flex justify-content-center">
                                                   <div class="d-flex justify-content-center m-auto p-2 ">18.00</div>
                                                </div>
                                             </div>
                                             <div class="p-2 border-left">
                                                <div class="d-flex align-items-center mb-2">
                                                   <label for="" style={{ width: "40px" }}>Pi =</label>
                                                   <input type="input" name="run2_pi" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                   <label for="" style={{ width: "40px" }}>Pf =</label>
                                                   <input type="input" name="run2_pf" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                   <label for="" style={{ width: "40px" }}>b =</label>
                                                   <input type="input" name="run2_a" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                </div>
                                             </div>
                                          </td>
                                          <td>
                                             <div class="border-bottom">
                                                <div class="border-bottom p-2" >Start Time (Ti) Finishing Time(Tf)</div>
                                                <div class="d-flex justify-content-center">
                                                   <div class="d-flex justify-content-center m-auto p-2 ">0:00</div>
                                                </div>
                                             </div>
                                             <div class="p-2 border-left">
                                                <div class="d-flex align-items-center mb-2">
                                                   <label for="" style={{ width: "40px" }}>Ti =</label>
                                                   <input type="input" name="run2_ti" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                   <label for="" style={{ width: "40px" }}>Tf =</label>
                                                   <input type="input" name="run2_tf" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                   <label for="" style={{ width: "40px" }}>Ti-Tf =</label>
                                                   <input type="input" name="run2_titf" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                </div>
                                             </div>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                        <div class="row mt-3">
                           <div class="col-lg-12">
                              <div class="table-5">
                                 <table>
                                    <tbody>
                                       <tr>
                                          <td class="text-center">Run 3</td>
                                          <td style={{ verticalAlign:"baseline"}}>
                                             <div>
                                                <div class="border-bottom p-2" >Water Gauge
                                                   Readings (Inches)
                                                </div>
                                                <div class="d-flex justify-content-center border-bottom">
                                                   <div class="d-flex justify-content-center w-half m-auto p-2 border-right">9.00</div>
                                                   <div class="d-flex justify-content-center w-half m-auto p-2">9.00</div>
                                                </div>
                                             </div>
                                             <div class="p-2">
                                             </div>
                                          </td>
                                          <td>
                                             <div class="border-bottom">
                                                <div class="border-bottom p-2">Total Inches Water</div>
                                                <div class="d-flex justify-content-center">
                                                   <div class="d-flex justify-content-center m-auto p-2 ">18.00</div>
                                                </div>
                                             </div>
                                             <div class="p-2 border-left">
                                                <div class="d-flex align-items-center mb-2">
                                                   <label for="" style={{ width: "40px" }}>Pi =</label>
                                                   <input type="input" name="run3_pi" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                   <label for="" style={{ width: "40px" }}>Pf =</label>
                                                   <input type="input" name="run3_pf" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                   <label for="" style={{ width: "40px" }}>c =</label>
                                                   <input type="input" name="run3_a" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                </div>
                                             </div>
                                          </td>
                                          <td>
                                             <div class="border-bottom">
                                                <div class="border-bottom p-2" style={{ minHeight: "55px;" }}>Start Time (Ti) Finishing Time(Tf)</div>
                                                <div class="d-flex justify-content-center">
                                                   <div class="d-flex justify-content-center m-auto p-2 ">0:00</div>
                                                </div>
                                             </div>
                                             <div class="p-2 border-left">
                                                <div class="d-flex align-items-center mb-2">
                                                   <label for="" style={{ width: "40px" }}>Ti =</label>
                                                   <input type="input" name="run3_ti" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                   <label for="" style={{ width: "40px" }}>Tf =</label>
                                                   <input type="input" name="run3_tf" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                </div>
                                                <div class="d-flex align-items-center mb-2">
                                                   <label for="" style={{ width: "40px" }}>Ti-Tf =</label>
                                                   <input type="input" name="run3_titf" class="form-control ml-3" id="abc" aria-describedby="emailHelp" style={{ width: "100px" }} onChange={() => sectionSubmit()}/>
                                                </div>
                                             </div>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                 <div class="divider2"></div>
                  {/* <div class="divider"></div> */}
                  {/* <div class="divider2"></div>
                  <div class="row p-3">
                     <Adddocs inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                  </div>

                  <AddInspectionName setEndDate={setEndDate} endDate={endDate} />
                  <div class="divider"></div> */}
                  <div class="row p-3 pr-4 d-flex justify-content-end" style={{ marginBottom: "-20px" }}>
                     {/* <a onClick={() => redirect("/vehicleDetails/" + localStorage.getItem("vehicleId"))} class="btn btn-secondary mr-1" >Cancel</a> */}
                     <a id="certificate1" class="btn btn-primary mr-1">Back</a>
                     <a id="certificate3" class="btn btn-primary mr-1">Next</a>
                     {/* <a href="#" class="btn btn-primary mr-1" onClick={() => save("DRAFT")}>Save as Draft </a>
                      <a href="#" class="btn btn-primary mr-1" onClick={() => save("APPROVED PENDING")}>Save</a> */}
                  </div>
               </div>
            </div>


         </div>
      </>
   )
};

InspectionVaporTightnessCertificate2.propTypes = {};

InspectionVaporTightnessCertificate2.defaultProps = {};

export default InspectionVaporTightnessCertificate2;
